<script>
import CircularScore from "@/components/widgets/circular-score";
import Utils from "@/js/utils";

export default {
  data() {
    return {};
  },
  props: ["data"],
  components: { CircularScore },
  computed: {
    roundedValue() {
      return {
        numerical: Utils.round(this.data?.numerical),
        sentiment: Utils.round(this.data?.sentiment)
      }
      // return Utils.round(this.data?.rating);
    }
  }
}
</script>

<template>
  <div id="popup">
    <h5 class="py-1 m-0 bg-primary px-2 font-size-16 rounded-top text-secondary">{{ data.name }}</h5>
    <div class="px-2 pb-1">
      <div class="d-flex align-items-center justify-content-between">
        <template v-for="t in ['numerical', 'sentiment']" :key="t">
          <div v-if="data[t]" class="mt-2 d-flex flex-column align-items-center justify-content-center me-2">
            <label class="m-0">{{ $t(`general.${t}`) }}</label>
            <CircularScore :size="80" :circleWidth="6" :value="parseFloat(data[t])" />
          </div>
        </template>
      </div>

      <template v-for="f in ['review_count', 'response_rate']" :key="f">
        <div v-if="data[f]">
          <label class="m-0">{{ $t(`general.${f}`) }}:</label>&nbsp;
          {{ data[f] }}
        </div>
      </template>
    </div>
  </div>
</template>
