<script>
import Multiselect from "vue-multiselect";
import Utils from "@/js/utils";
import { Field } from 'vee-validate';

export default {
	data() {
		return {
			maxType: {
        "multiple_select":  "selections",
        "open_answer":      "characters",
        "open_answer_long": "characters",
      },
			ratingIcons: ["star", "heart", "person", "circle","flag", "droplet", "check-square", "trophy", "cloud", "pencil", "award", "lightning", "tree"],
			companiesHash: {}
		};
	},
	props: ["survey", "question", "questionTypes", "questionHash", "topics", "companies"],
	components: { Multiselect, Field },
	created() {
		this.companiesHash = this.companies.reduce((res, curr) => {
			res[curr.id] = Utils.deepClone(curr);
			return res;
		}, {});
		this.survey.redirect_urls ??= {};
	},
	methods: {
		maxValueType(question_type) {
			return question_type == "rating" ? this.editingQuestion().max_value : this.editingQuestion().max;
		},
		isValidURL(url) {
      if(!url) return null;
      return Utils.validURL(url);
    },
	},
	computed: {
		properties() {
			return (this.survey?.company_ids || []).map(c => this.companiesHash[c]);
		},
		editingQuestion() {
			return this.question;
		},
		newQuestion() {
			return this.question.id.includes("new_question");
		}
	}
}
</script>

<template>
  <div v-if="question" class="px-1">
		<template v-if="question.id == 'thank_you'">
			<!-- Redirect URL -->
			<h5 class="mb-2">{{ $t("new_survey.settings.redirect_url") }}</h5>
			<div v-for="p in properties" :key="p.id" class="mb-2">
				<label class="me-2 mb-1 form-label">{{ p.name }}</label>
				<b-form-input type="url" v-model="survey.redirect_urls[p.id]" :placeholder="$t('new_survey.settings.url_help', { name: p.name })" :state="isValidURL(survey.redirect_urls[p.id])" @input="$emit('changeInput')"></b-form-input>
			</div>
		</template>

    <template v-else>
			<div class="question_type mt-1">
				<div class="d-flex align-items-center mb-1">
					<label class="me-2 my-0 form-label">{{ $t("new_survey.questions.right.type") }}</label>
					<span v-b-tooltip.hover :title="$t('new_survey.questions.right.type_tooltip')">
						<i class='mdi mdi-help-circle'></i>
					</span>
				</div>
				<multiselect class="question_type_multiselect" :multiple="false" v-model="editingQuestion.type" :options="editingQuestion.has_answers ? questionHash[editingQuestion.type].compatibles : questionTypes" :showLabels="false" :allow-empty="false" :placeholder="$t('new_survey.questions.right.type_help')" @select="$emit('changeInput', { update: 'question_type' })" @remove="$emit('changeInput', { update: 'question_type' })">
					<template v-slot:singleLabel="props">
						<i :class="questionHash[props.option] && questionHash[props.option].icon" class="me-1"></i>
						<span> {{ $t(`new_survey.questions.question_types.${props.option}`) }} </span>
					</template>

					<template v-slot:option="props">
						<i :class="questionHash[props.option].icon" class="me-1"></i>
						<span> {{ $t(`new_survey.questions.question_types.${props.option}`) }} </span>
					</template>
				</multiselect>
			</div>

			<!-- categorization -->
			<div class="categorization mt-4">
				<label class="me-2 mb-1 form-label">{{ $t("new_survey.questions.right.categorization") }}</label>
				<multiselect class="categorization_multiselect" :multiple="true" v-model="editingQuestion.topics" :options="topics" :custom-label="q => $t(`topics.${q}`)" :showLabels=false :placeholder="$t('new_survey.questions.right.categorization_help')" @select="$emit('changeInput')" @remove="$emit('changeInput')"></multiselect>
			</div>

			<!-- enable follow up -->
			<div class="enable_follow_up mt-3 ms-1" v-if="/rating|nps/.test(editingQuestion.type)">
				<div class="custom-control custom-checkbox custom-control-right">
					<input type="checkbox" class="custom-control-input" id="enable-checkbox" v-model="editingQuestion.enabled_followup" @input="$emit('changeInput')" />
					<label class="custom-control-label form-label" for="enable-checkbox">{{ $t("new_survey.questions.right.enable_follow_up") }}</label>
				</div>
			</div>

			<!-- required -->
			<div class="enable_required mt-3 ms-1">
				<div class="custom-control custom-checkbox custom-control-right">
					<input type="checkbox" class="custom-control-input" id="enable-required" v-model="editingQuestion.required" @input="$emit('changeInput')" />
					<label class="custom-control-label form-label" for="enable-required">{{ $t("new_survey.questions.right.required") }}</label>
				</div>
			</div>

			<!-- Rating -->
			<div class="mt-4 row" v-if="/rating/.test(editingQuestion.type)">
				<div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
					<label class="me-2 mb-1 form-label">{{ $t(`new_survey.questions.right.max_value`) }}</label>
					<Field rules="required|min_value:3|max_value:15" :name="$t(`new_survey.questions.right.max_value`)" v-slot="{ field, errors }" v-model="editingQuestion.max_value">
						<input type="number" class="form-control" :placeholder="$t(`new_survey.questions.right.max_value_placeholder`)" @input="$emit('changeInput')" v-bind="field" />
						<span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
					</Field>
				</div>

				<div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
					<label class="me-2 mb-1 form-label">{{ $t(`new_survey.questions.right.rating_icon`) }}</label>
					<multiselect :multiple="false" :allow-empty="false" v-model="editingQuestion.config.rating_icon" :options="ratingIcons" :showLabels="false" :placeholder="$t('new_survey.questions.right.rating_icon_help')" @select="$emit('changeInput')" @remove="$emit('changeInput')">
						<template v-slot:singleLabel="props">
							<i :class="`bi bi-${props.option}-fill`" class="me-2"></i>
							<span> {{ $t(`rating_icons.${props.option}`) }} </span>
						</template>

						<template v-slot:option="props">
							<i :class="`bi bi-${props.option}-fill`" class="me-2"></i>
							<span> {{ $t(`rating_icons.${props.option}`) }} </span>
						</template>
					</multiselect>
				</div>
			</div>

			<!-- max value -->
			<div class="mt-4" v-if="maxType[editingQuestion.type]">
				<label class="me-2 mb-1 form-label">{{ $t(`new_survey.questions.right.max_${maxType[editingQuestion.type]}`) }}</label>
				<b-form-input type="number" v-model="editingQuestion.max" @input="$emit('changeInput')"></b-form-input>
			</div>

			<!-- min value -->
			<div class="mt-4" v-if="maxType[editingQuestion.type]">
				<label class="me-2 mb-1 form-label">{{ $t(`new_survey.questions.right.min_${maxType[editingQuestion.type]}`) }}</label>
				<b-form-input type="number" v-model="editingQuestion.min" @input="$emit('changeInput')"></b-form-input>
			</div>

			<!-- slug -->
			<div class="mt-4">
				<label class="me-2 mb-1 form-label">{{ $t('new_survey.questions.right.slug') }}</label>
				<span v-b-tooltip.hover :title="$t('new_survey.questions.right.slug_tooltip')">
					<i class='mdi mdi-help-circle'></i>
				</span>
				<Field rules="alpha_dash" :name="$t(`new_survey.questions.right.slug`)" v-slot="{ field, errors }" v-model="editingQuestion.slug">
					<input class="form-control" @input="$emit('changeInput')" :placeholder="$t('new_survey.questions.right.slug_placeholder')" v-bind="field" />
					<span class="text-danger font-size-12">{{ errors[0] }}</span>
				</Field>
			</div>
		</template>
  </div>
</template>
