<script>
import draggable from 'vuedraggable';
import FeedbackUtils from "@/js/feedback-utils";

export default{
  data () {
    return {
      collapsedGroups: {}
    }
  },
  props: {
    value: {
      required: false,
      type: Array,
      default: null
    },
    list: {
      required: false,
      type: Array,
      default: null
    },
    questionHash: {type: Object},
    settings:     {type: Object},
    currentId:    {type: String},
    nested:       { required: true, type: Boolean, default: false }
  },
  components: { draggable },
  name: 'DraggableItem',
  methods: {
    ...FeedbackUtils,
    toggleCollapse (group) {
      this.collapsedGroups[group] = !this.collapsedGroups[group]
    },
    setCurrentQuestion (id) {
      this.$emit('setCurrentQuestion', id);
    },
    removeGroup(id) {
      this.$emit('removeGroup', id)
    },
    removeQuestion(id) {
      this.$emit('removeQuestion', id)
    },
    emitter(value) {
      this.$emit("input", value);
    },
    beforeDrop(value) {
      this.$emit("beforeDrop", value);
    },
    getQuestionText(question) {
      return question.texts?.question?.[this.settings.currentLanguage] || question.texts?.question?.[this.settings.defaultLanguage]
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    realList() {
      return this.value ? this.value : this.list;
    }
  }
}
</script>
<template>
  <draggable v-bind='dragOptions' tag='ul' class='p-0 w-100 item-container' item-key="id" :list='list' :value='value' @input="emitter" @start='beforeDrop'>

    <!-- Welcome page -->
    <template #header v-if="!nested">
      <li class="question-item d-flex align-items-center px-4 py-2 w-100" :class="{ 'active-list': currentId == 'welcome' }" @click="setCurrentQuestion('welcome')">
        <i v-if="questionHash['welcome']" class="font-size-18 me-2" :class="questionHash['welcome'].icon"></i>
        <span class="m-0 font-size-14"> {{ $t('new_survey.questions.left.welcome', settings.currentLanguage) }} </span>
      </li>
    </template>

    <template #item="{ element }">
      <li class="item-group question-item d-flex justify-content-between align-items-center px-4 py-2" :class="{ 'active-list': currentId == element.object.id }" @click="setCurrentQuestion(element.object.id)" v-if='element.type == "question"' >
        <div class="d-flex justify-content-center align-items-center me-1">
          <i v-if="questionHash[element.object.type]" class="font-size-18 me-2" :class="questionHash[element.object.type].icon"></i>
          <span class="m-0 font-size-14" v-html="tokenizeVariables(getQuestionText(element.object))"></span>
        </div>
        <div :title="$t('new_survey.questions.edit.remove')" class="remove-item align-self-center ms-2 font-size-13" :class="{ 'active-list': currentId == element.object.id }" @click="removeQuestion(element.object.id)" :style="currentId == element.object.id ? 'visibility: visible;' : ''">
          <i class="fa fa-trash"/>
        </div>
      </li>
    </template>

    <!-- Thank you page -->
    <template #footer v-if="!nested">
      <li class="question-item d-flex align-items-center px-4 py-2" :class="{ 'active-list': currentId == 'thank_you' }" @click="setCurrentQuestion('thank_you')">
        <i v-if="questionHash['thank_you']" class="font-size-18 me-2" :class="questionHash['thank_you'].icon"></i>
        <span class="m-0 font-size-14"> {{ $t('new_survey.questions.left.thank_you', settings.currentLanguage) }} </span>
      </li>
    </template>
  </draggable>
</template>
