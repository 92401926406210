import axios from "axios";

export const state   = { filterData: {}, filterDataPromise: {} };
export const getters = {};

export const mutations = {
  saveFilterData(state, { filterData, contractId }) {
    state.filterData[contractId] = filterData;
  },
  saveFilterDataPromise(state, { promise, contractId }) {
    state.filterDataPromise[contractId] = promise;
  },
};

export const actions = {
  async fetch({ commit }, params ) {
    const contractId = params.contract_id;
    if (state.filterData[contractId])        return state.filterData[contractId];
    if (state.filterDataPromise[contractId]) return state.filterDataPromise[contractId];

    let promise = axios.get("/v3/reputation/filter_data", { params: {
      subscription_ids: contractId,
      sub_context: "main"
    }}).then(response => {
      let filterData = {};
      ["compositions", "languages", "countries", "property_types"].forEach(b => {
        filterData[b] = response.data.data[b] || [];
      });
      commit("saveFilterData", { filterData, contractId });
      return filterData;
    })

    commit("saveFilterDataPromise", { promise, contractId });
    return promise;
  },
}
