if (!window.oly) window.oly = {}

window.oly.formUtils = {
  slugify(str) {
    return str.toString().toLowerCase().replace(/\s/g, "_");
  },
  createId(option = "", options = []) {
    let slug = this.slugify(option);
    let repeated = options.filter(c => c.id.includes(slug));
    return `${slug}_${repeated.length + 1}`;
  },
  valid: {
    max (q, a) {
      if (q.max && a !== null && a !== undefined) return q.max >= a.length;
      return true;
    },
    min (q, a) {
      if (q.min && a !== null && a !== undefined && a !== "") return q.min <= a.length;
      return true;
    },
    required (q, a) {
      if (!q.required) return true
      if (Array.isArray(a)) return a.length > 0
      if (typeof a == 'string') return a.trim() != ''
      return a !== "" && a !== null && a !== undefined;
    },
    all (q, a) {
      return this.max(q,a) && this.min(q,a) && this.required(q,a)
    }
  }
}
export default window.oly.formUtils;
