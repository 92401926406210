<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      tableData: [],
      cols: [
        { field: "rating",         label: "Rating"               },
        { field: "current_score",  label: "Current Month"        },
        { field: "previous_score", label: "Previous Month"       },
        { field: "last_year",      label: "Same Month Last Year" }
      ],
      rows: []
    };
  },

  components: { ExportExcel },
  props: ['title', 'company', 'identifier', 'release_date', 'index', 'offset', 'download'],

  mounted() {
    // this.$root.$on("sentiment-over-time-update", this.loadListener)
    window.addEventListener("sentiment-over-time-update", this.loadListener)
  },
  beforeUnmount() {
    window.removeEventListener("sentiment-over-time-update", this.loadListener)
  },

  async created() {
    if (this.index == 1) {
      let response = await axios.get("/v3/query/db/_", { params: {
        company_id:   this.company.id,
        identifier:   this.identifier,
        release_date: this.release_date,
        compset: false,
        format:  "hash"
      }});
      this.$emit("sentiment-over-time-update", { rows: response.data.data })
      const event = new CustomEvent("sentiment-over-time-update", { detail: { rows: response.data.data } });
      window.dispatchEvent(event);
    }
  },

  methods: {
    loadListener(data) {
      let rows = window.reports.rowsOffset(this, data.detail)
      this.formatData(rows)
    },

    topicLabel(rating, topic) {
      if(topic) return `${rating} - ${topic}`;
      return rating;
    },
    tdClass(value, field) {
      if (field != "rating") return "gray-" + Utils.ratings.toCss100(value);
      else return;
    },
    formatData(data) {
      data.forEach(row => {
        this.rows.push({
          rating:         this.topicLabel(row.rating, row.topic),
          current_score:  Utils.round(row.sentiment?.current?.score, 2)   || "-",
          previous_score: Utils.round(row.sentiment?.previous?.score, 2)  || "-",
          last_year:      Utils.round(row.sentiment?.last_year?.score, 2) || "-",
          isTopic:        row.topic
        });
      });
    }
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-bordered table-nowrap mb-0 text-center" style="table-layout: fixed;">
        <thead>
          <tr>
            <th class="property-name-column">{{ cols[0].label }}</th>
            <th v-for="col in cols.slice(1)" :key="col.field">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in rows" :key="row.field">
            <template v-for="col in cols" :key="col.field">
              <td :class="{
                [tdClass(10*row[col.field], col.field)]: true,
                'property-name-column': col.field == 'rating' && !row['isTopic'],
                'text-bold-600': col.field == 'rating' && !row['isTopic'],
                'ps-4 text-left': col.field == 'rating' && row['isTopic']
              }">
                {{ row[col.field] }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
