<script>
import Utils from "@/js/utils";
import Multiselect from "vue-multiselect";
import { Field } from 'vee-validate';

export default {
  data () {
    return {
      benchmarkTypes:  ['companies', 'groups'],
      groups:          [],
      companies:       [],
      surveys:         []
    }
  },
  created () {
    ["bench_group_ids", "bench_company_ids"].forEach(f => {
      if (this.settings[f])
        this.settings[f] = this.settings[f].filter(n => n);
      else
        this.settings[f] = [];
    });
  },
  mounted () {
    setTimeout(this.load, 1500);
  },
  props: ['fields', 'settings', 'contract', 'nBenchmarks', 'showInline'],
  components: { Multiselect, Field },
  methods: {
    load() {
      this.loadGroups();
      this.loadCompanies();
    },
    loadCompanies() {
      this.$store.dispatch("companies/fetch", { contract_id: this.contract.id }).then(companies => {
        this.companies = Utils.groupByUniq(companies, 'id');
      });
    },
    loadGroups() {
      this.$store.dispatch("groups/fetch", { contract_id: this.contract.id }).then(groups => {
        this.groups = Utils.groupByUniq(groups, 'id');
      });
    }
  },
  computed: {
    group_ids () {
      return Object.values(this.groups).map(g => g.id)
    },
    company_ids () {
      return Object.values(this.companies).map(g => g.id)
    },
    benchmarkOptions() {
      if (this.settings.benchmark_based_on == 'groups') {
        return this.groups
      } else {
        return this.companies
      }
    },
    benchmarkField () {
      if (this.settings.benchmark_based_on == 'groups') return this.settings.bench_group_ids
      if (this.settings.benchmark_based_on == 'companies') return this.settings.bench_company_ids
      return null
    },
    isSelectGroups() {
      return this.settings.benchmark_based_on == 'groups'
    }
  }
}
</script>
<template>
  <div class="mb-5">
    <div class="form-group row">
      <label class='form-label col-4'>{{ $t("reports.settings_modal.benchmark.use_custom") }}</label>
      <span class="col-8">
        <input type='checkbox' v-model="settings.use_custom_benchmark" class='form-check-input ms-0' />
      </span>
    </div>

    <template v-if='settings.use_custom_benchmark'>
      <div class="form-group row">
        <label class='form-label col-4'>{{ $t("reports.settings_modal.benchmark.based_on") }}</label>
        <span class="col-8 d-flex">
          <!-- <button @click="settings.benchmark_based_on = null">clear</button> -->
          <Field rules="required" :name="$t('reports.settings_modal.benchmark.based_on')" v-slot="{ errors }" v-model="settings.benchmark_based_on">
            <label class="ms-0 custom-label" v-for="bt in benchmarkTypes" :key="bt">
              <input v-model='settings.benchmark_based_on' class="custom-input" type="radio" name="benchmark_based_on" :id="bt" :value="bt" />
              <span class="custom-span">{{ $t(`reports.settings_modal.row_types.${bt}`) }}</span>
            </label>
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </Field>
        </span>
      </div>

      <div class="form-group row">
        <label class="form-label col-4">
          {{ isSelectGroups ? $t("reports.settings_modal.benchmark.groups") :
          $t("reports.settings_modal.benchmark.companies") }}
        </label>

        <div class="col-8">
          <div :class='{"col-sm-4": showInline(), "col-md-3": showInline()}' >
            <Field v-if="settings.benchmark_based_on == 'groups'" rules="required" :name="$t('reports.settings_modal.benchmark.groups')" v-slot="{ errors }" v-model="settings.bench_group_ids">
              <multiselect
                :multiple="true" v-model="settings.bench_group_ids" :options="group_ids" :searchable='true' :showLabels="false"
                :customLabel="id => benchmarkOptions[id] && benchmarkOptions[id].name" :placeholder="$t('filter.select_option')" :max="nBenchmarks"
              >
                <template v-slot:maxElements>{{ $t("general.max_opt", { n: nBenchmarks }) }}</template>
              </multiselect>
              <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
            </Field>

            <Field v-if="settings.benchmark_based_on == 'companies'" rules="required" :name="$t('reports.settings_modal.benchmark.companies')" v-slot="{ errors }" v-model="settings.bench_company_ids">
              <multiselect
                :multiple="true" v-model="settings.bench_company_ids" :options="company_ids" :searchable='true' :showLabels="false"
                :customLabel="id => benchmarkOptions[id] && benchmarkOptions[id].name" :placeholder="$t('filter.select_option')" :max="nBenchmarks"
              >
                <template v-slot:maxElements>{{ $t("general.max_opt", { n: nBenchmarks }) }}</template>
              </multiselect>
              <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
            </Field>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row" v-if="fields.keys.includes('select_surveys')">
        <label class="form-label col-2">{{ $t("reports.settings_modal.surveys") }}</label>
        <div class="col-10 w-75">
          <multiselect :multiple="true" v-model="settings.bench_surveys" :options="surveysOptions" :searchable="true" :customLabel="id => surveys[id] && surveys[id].name" />
        </div>
      </div> -->
    </template>
  </div>
</template>

