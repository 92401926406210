<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"

export default {
  data() {
    return {
      chartData: [],
      colors: [],
      pieSize: "80%",
      pieTop: "60%",
      chartHeight: 400,
      chartOptions: {
        tooltip: {
          show: true,
          trigger: "item",
          formatter: (params) => `${params.name}: ${params.data.value}%`,
        },
        legend: {
          type: 'plain',
          orient: 'vertical',
          left: 150,
          top: 100,
          textStyle: {
            fontWeight: "bold",
            fontSize: 14
          }
        },
      }
    };
  },
  props: ["title", "company", "identifier", "release_date", "source", "sourceColors", "mtd", "months_ago", "prev_months", "segment", "sourceHash"],
  created() {
    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      source:   this.source,
      compset: false,
      format:  "hash",
      months_ago: this.months_ago,
      prev_months: this.prev_months,
      segment: this.segment,
      mtd: this.mtd
    }}).then(response => {
      if (this.mtd) {
        this.pieSize = "65%";
        this.pieTop = "40%";
        this.chartHeight = 300;

        if (!response.data.data?.[0]?.segments) {
          this.chartData = [{ share: 100, channel: "No data" }];
        }
        else {
          this.chartData = response.data.data?.[0]?.segments.map(e => {
            return { share: e.share, channel: e.segment };
          });
        }

      } else this.chartData = response.data.data;

      this.chartData.sort((a, b) => {
        let sourceA = Object.keys(this.sourceColors).find(s => Utils.compareSources(s, a.channel));
        let sourceB = Object.keys(this.sourceColors).find(s => Utils.compareSources(s, b.channel));
        let indexA = Object.keys(this.sourceColors).indexOf(sourceA);
        let indexB = Object.keys(this.sourceColors).indexOf(sourceB);
        return indexA - indexB;
      });

      this.colors = Object.keys(this.sourceColors)
        .filter(s => this.chartData.find(t => Utils.compareSources(s, t.channel)))
        .map(s => this.sourceColors[s]);
    });
  },
  computed: {
    options() {
      return {
        ...this.chartOptions,
        color : this.colors,
        series: [
          {
            name: "series",
            type: "pie",
            radius: this.pieSize,
            center: ["50%", this.pieTop],
            data: this.chartData.map(el => {
              return { value: el.share, name: el.channel };
            }),
            color: this.chartData[0]?.channel != "No data" ? this.colors : "#657084",
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: this.chartData[0]?.channel != "No data",
              position: "outside",
              formatter: function (params){
                return params.value + "%\n"
              },
              fontSize: 14
              // distance : 10 // does not work
            },
            labelLine: {
              show: true
            }
          }
        ]
      }
    }
  }
}
</script>

<template>
  <div class="d-flex flex-column" id="chart-root">
    <h3 class="title mt-3"> {{ title }} </h3>
    <v-chart class="w-100 d-flex justify-content-center" :option="options" :style="`height: ${chartHeight}px;`" />
    <div class="position-relative mt-2">
      <h4 class="title position-absolute" style="left: calc(50% - calc(201.75px / 2));">Subscriber Property</h4>
    </div>
  </div>
</template>
