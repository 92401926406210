<script>
import {axios} from '@/js/reports'
import dayjs from "dayjs"
import Utils from "@/js/utils"
// import "echarts/lib/component/legend";
// import "echarts/lib/component/tooltip";

export default {
  data() {
    return {
      sources: [
        ["source", "sameMonthLastYear", "yearToDate"]
      ],
      colors: [],
      chartData: [],
      sameMonthLastYearData: [],
      yearToDateData: [],
      chartOptions: {
        tooltip: {
          show: true,
          trigger: "item",
          formatter: (params) => `${params.name}: ${params.data[params.seriesIndex + 1]}%`,
        },
        legend: {
          type: 'plain',
          orient: 'vertical',
          left: 50,
          top: 100,
          textStyle: {
            fontWeight: "bold",
            fontSize: 14
          }
        },
        dataset: {
          source: []
        }
      },
      seriesOptions: {
        type: "pie",
        radius: "80%",
        center: ["50%", "60%"],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        label: {
          show: true, position: "outside",
          formatter: function (params){
            return params.value[params.seriesIndex + 1] + "%\n"
          },
          fontSize: 14
        },
        labelLine: {
          show: true
        }
      }
    };
  },
  props: ["title", "company", "identifier", "release_date", "source", "sourceColors", "smly"],
  async created() {
    let release = this.release_date;
    if (this.smly) release = dayjs(this.release_date).subtract(1, "year").format("YYYY-MM-DD");
    let sameMonth = await axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: release,
      source:  this.source,
      compset: false,
      months_ago: 1,
      format: "hash"
    }});
    this.sameMonthLastYearData = sameMonth.data.data;

    let yearToDate = await axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      source: this.source,
      period: "merged",
      compset: false,
      ytd: "true",
      format: "hash"
    }});
    this.yearToDateData = yearToDate.data.data;

    let computedSources = this.sameMonthLastYearData.filter(data => data.source != null).map(data => data.source);
    this.yearToDateData.forEach(data => {
      if (data.source && !computedSources.includes(data.source)) computedSources.push(data.source);
    });

    computedSources.forEach(source => {
      let sameMonthLastYear = this.sameMonthLastYearData.find(c => c.source == source);
      let yearToDate = this.yearToDateData.find(c => c.source == source);
      this.sources.push([source, sameMonthLastYear?.share || 0, yearToDate?.share || 0]);
    });

    let sorted = this.sources.splice(1).sort((a, b) => {
      let sourceA = Object.keys(this.sourceColors).find(s => Utils.compareSources(s, a[0]));
      let sourceB = Object.keys(this.sourceColors).find(s => Utils.compareSources(s, b[0]));
      let indexA = Object.keys(this.sourceColors).indexOf(sourceA);
      let indexB = Object.keys(this.sourceColors).indexOf(sourceB);
      return indexA - indexB;
    });
    this.sources.splice(1, 0, ...sorted);

    this.colors = Object.keys(this.sourceColors)
      .filter(s => this.sources.find(t => Utils.compareSources(s, t[0])))
      .map(s => this.sourceColors[s]);
  },
  computed: {
    options() {
      return {
        ...this.chartOptions,
        dataset: { source: this.sources },
        color : this.colors,
        series: [
          {
            ...this.seriesOptions,
            center: ["35%", "60%"],
            encode: {
              itemName: "source",
              value: "sameMonthLastYear"
            }
          },
          {
            ...this.seriesOptions,
            center: ["78%", "60%"],
            encode: {
              itemName: "source",
              value: "yearToDate"
            }
          }
        ]
      }
    }
  }
}
</script>

<template>
  <div class="d-flex flex-column" id="chart-root">
    <h3 class="title mt-3"> {{ title }} </h3>
    <v-chart class="w-100 d-flex justify-content-center" style="width: 100%; height: 450px;" :option="options" />
    <div class="position-relative mt-4">
      <h4 class="title position-absolute" style="left: calc(35% - calc(223.52px / 2));">Same Month Last Year</h4>
      <h4 class="title position-absolute" style="left: calc(78% - calc(125.57px / 2));">Year To Date</h4>
    </div>
  </div>
</template>
