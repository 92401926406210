<script>
import Utils from "@/js/utils";

export default {
  data() {
    return {
      map: null
    };
  },
  props: ["initOpts", "defaults"],
  mounted() {
    this.initMap();
  },
  beforeUnmount() {
    this.map.remove();
  },
  watch: {
    "$i18n.locale": {
      handler: function () {
        this.setMapLanguage(this.map);
      },
      immediate: true
    }
  },
  methods: {
    ...Utils.map,
    initMap() {
      const options = {
        ...this.initOpts,
        container: "placeholder-map",
        center: [this.defaults.lng, this.defaults.lat],
        zoom:   2
      };
      this.map = new window.maplibregl.Map(options);
      this.map.on("load", async () => {
        this.setMapLanguage(this.map);
      });
    }
  }
}
</script>

<template>
  <div id="placeholder-map" class="map"></div>
</template>
