<script>
export default {
  page: {
    title: "Accounts",
  },
  data() {
    return {
      isDashboard: false,
    };
  },
};
</script>

<template>
    <router-view></router-view>
</template>