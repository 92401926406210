<script>
import {axios} from '@/js/reports'
// import dayjs from "dayjs"
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      tableData: {},
      cols: [
        { field: "name", label: "Property Name" },
      ],
      rows: []
    };
  },
  components: { ExportExcel },
  props: ["title", "identifier", "release_date", "group", "months_ago", "last_month", "polarity"],
  created() {
    axios.get("/v3/query/db/_", { params: {
      group_id:     this.group.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      months_ago:   this.months_ago,
      compset: false,
      format:  "hash"
    }}).then(response => {
      let properties = [];
      response.data.data.forEach(data => {
        if (!properties.includes(data.name)) properties.push(data.name);
      });

      properties.forEach(property => {
        this.tableData[property] = response.data.data.filter(d => d.name == property);
        this.tableData[property].sort((a, b) => b[this.polarity] - a[this.polarity]);
      });

      this.tableData[properties[0]].slice(0, 15).forEach(topic => this.cols.push({ field: Utils.slugify(topic.label), label: topic.label }));

      for (let key in this.tableData) {
        let row = {};
        row.name = key;
        this.cols.slice(1).forEach(col => {
          let data = this.tableData[key].find(d => d.label == col.label);
          if (!data) row[col.field] =  "-";
          else row[col.field] = data[this.polarity];
        });
        this.rows.push(row);
      }
    });
  },
  methods: {
    useValue() {

    }
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th class="property-name-column">{{ cols[0].label }}</th>
            <th v-for="col in cols.slice(1)" :key="col.label">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, indexRow) in rows" :key="indexRow">
            <td class="property-name-column">{{ row.name }}</td>
            <template v-for="(col, indexCol) in cols.slice(1)" :key="col.field + indexCol">
              <td>{{ row[col.field] }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
