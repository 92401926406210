import {axiosInit} from '@/js/axios';
import Utils from '@/js/utils';
import _axios from 'axios';
import axiosRetry from 'axios-retry';
import dayjs from "dayjs";
import en from '@/locales/en.json'
import quarterOfYear from "dayjs/plugin/quarterOfYear";
dayjs.extend(quarterOfYear);

// global used by reports-daemon
window.pageSettings = {
}

window.reports = {
  formatDateRange(start_date, end_date) {
    const months = Object.values(en.months)
    const startDateObj = dayjs(start_date);
    const endDateObj = dayjs(end_date);

    const startMonth = months[startDateObj.month()];
    const startYear = startDateObj.year();

    const endMonth = months[endDateObj.month()];
    const endYear = endDateObj.year();

    const monthDiff = (endYear - startYear) * 12 + (endMonth - startDateObj.month());

    if (monthDiff >= 12) {
      return `${startYear}`;
    } else if (monthDiff >= 3) {
      const quarter = Math.floor(startDateObj.month() / 3) + 1;
      return `Q${quarter} ${startYear}`;
    } else if (monthDiff >= 1) {
      return `${startMonth} ${startYear}`;
    } else {
      // Weekly & others
      const formattedStart = startDateObj.format('DD MMM YYYY');
      const formattedEnd = endDateObj.format('DD MMM YYYY');

      return `${formattedStart} - ${formattedEnd}`;
    }
  },
  format(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  },

  percentFormat: Utils.percent.format,
  percentDelta:  Utils.percent.delta,

  pivot(data, _ref) {
    var _ = require('lodash');
    var rowLabel = _ref.rowLabel;
    var rowAttr = _ref.rowAttr;
    var colAttr = _ref.colAttr;
    var valAttr = _ref.valAttr;

    var d;
    var rows = _.groupBy(data, rowAttr);
    var cols = _.groupBy(data, colAttr);
    var pairs = _.groupBy(data, function (v) {
      return v[rowAttr] + v[colAttr];
    });

    d = [[rowLabel].concat(_.keys(cols))];
    d = d.concat(_.map(rows, function (l, r) {
      return [r].concat(_.map(cols, function (l, c) {
        var v = pairs[r + c];
        if (v) return v[0][valAttr];
      }));
    }));
    return d;
  },

  axios: _axios.create(),

  init(cmp, landscape) {
    landscape ??= true // default to true
    window.pageSettings.landscape = landscape
    window.pageSettings.margin = {left: '0', right: '0', top: '0', bottom: '0'}

    cmp.download = cmp.$route.query.export

    this.loadAxios()
  },

  rowsOffset(cmp, data) {
    if (cmp.download) if (cmp.index == 1) return data.rows; else return []
    return data.rows.slice((cmp.index - 1) * cmp.offset, cmp.index * cmp.offset)
  },

  api: {
    activeRequests: 0,
  },

  loadAxios() {
    axiosInit(this.axios)

    this.axios.defaults.timeout = 3 * 60 * 1000
    axiosRetry(this.axios, { retries: 3, retryDelay: () => 5 * 1000 })
  },

  uniqueBenchmarkNames(tableData, index) {
    if (!index) index = 6
    let objs = tableData.flatMap(t => t[index]?.map(obj => obj.name)).filter(n => n);
    let uniqueNames = [...new Set(objs)];
    return uniqueNames;
  },

  templates: {
    componentsList: {
      kpi: {
        minW: 3, minH: 3, component: 'KPI', type: 'generic',
        settingsFields: {
          keys: ['show_trend', 'trend_range', 'segment', 'show_title', 'title', 'filters', 'use_low_rated', 'custom_labels'],
          options: {
            segments: ['reviews_count', 'mentions_count', 'responses_count', 'response_rate', 'numerical_rating', 'sentiment_rating', 'tes_rating', 'survey_responses_count', 'survey_numerical_rating', 'survey_sentiment_rating', 'survey_tes_rating'],
            labels: ['trend'],
            n_segments: 1,
            filterCols: ['source']
          }
        }
      },
      donut_kpi:     { minW: 3, minH: 6, component: 'DonutKPI', type: 'generic', settingsFields: {
        keys: ['show_trend', 'trend_range', 'segment', 'show_title', 'title', 'benchmark'],
        options: { segments: ['numerical_rating', 'sentiment_rating', 'tes_rating'], n_segments: 1 } }
      },
      generic_chart: { minW: 8, minH: 60,  component: 'GenericChart', type: 'generic', settingsFields: {
        keys: ['chart_data', 'period', 'n_periods', 'show_title', 'title', 'height', 'filters'],
        options: { segments: ['reviews_count', 'mentions_count', 'responses_count', 'numerical_rating', 'sentiment_rating', 'tes_rating', 'survey_responses_count', 'survey_numerical_rating', 'survey_sentiment_rating', 'survey_tes_rating'], filterCols: ['property_types', 'sources', 'compositions', 'countries', 'languages' ] } } },
      generic_table: { minW: 4, minH: 5, component: 'GenericTable', type: 'generic', settingsFields: { keys: ['row_data', 'columns', 'column_benchmark', 'show_title', 'title', 'limit', 'use_competitors', 'filters'], options: { segments: ['numerical_rating', 'sentiment_rating', 'reviews_count', 'mentions_count', 'tes_rating', 'subratings', 'composition', 'country', 'language', 'source'], filterCols: ['property_types', 'sources', 'compositions', 'countries', 'languages' ] } } },
      generic_donut_chart: { minW: 4, minH: 15, component: 'GenericDonutChart', type: 'generic', settingsFields: {
        keys: ['segment', 'show_title', 'title', 'height'],
        options: { segments: ['sentiment_count'], maxHeight: 800 } }
      },
      page_break:    { minW: 12, minH: 1, component: 'PageBreak', type: 'static', settingsFields: { keys: [] } }, // hr
      heading:       { minW: 2, minH: 6,  component: 'Heading', type: 'static', settingsFields: { keys: ['heading', 'text'] } },
      paragraph:     { minW: 2, minH: 6,  component: 'Paragraph', type: 'static', settingsFields: { keys: ['largetext'] } },
      html:          { minW: 6, minH: 10,  component: 'CustomHtml', type: 'static', settingsFields: { keys: ['html'] } },
      image:  { minW: 1, minH: 10,  component: 'Image', type: 'static', settingsFields: { keys: ['image_url', 'image_alt', 'width'] } },
      spacer:  { minW: 1, minH: 3,  component: 'spacer', type: 'static', settingsFields: { keys: ['height'], options: { requireHeight: true, minHeight: 3, maxHeight: 500 } } },

      bar_most_reviews_day: { minW: 8, minH: 5, component: 'BarMostReviewsDay', type: 'generic', settingsFields: { keys: ['font-size'] } },

      // advanced components
      table_top_properties: { minW: 4, minH: 5, component: 'TableTopProperties', type: 'advanced', settingsFields: {
        keys: ['benchmark', 'show_title', 'title', 'limit', 'custom_labels', 'min_reviews', 'select_columns', 'filters', 'order_by'],
        options: { segments: ['country', 'language', 'source'], labels: ['reviews_count', 'trend', 'numerical_rating', 'response_score', 'required_responses'], columns: ['reviews_count', 'reviews_count_trend', 'numerical_rating', 'numerical_rating_trend', 'response_score', 'response_score_trend', 'required_responses'], filterCols: ['property_type'] } }
      },
      table_top_segments: { minW: 4, minH: 5, component: 'TableTopSegments', type: 'advanced', settingsFields: {
        keys: ['benchmark', 'show_title', 'title', 'limit', 'custom_labels', 'select_columns', 'order_by', 'segment', 'filters'],
        options: { segments: ['composition', 'country', 'language', 'source'], labels: ['reviews_count', 'trend', 'numerical_rating', 'response_score'], columns: ['reviews_count', 'reviews_count_trend', 'numerical_rating', 'numerical_rating_trend', 'response_score', 'response_score_trend'], filterCols: ['property_type'] } }
      },
      table_top_segments_benchmark: { minW: 4, minH: 5, component: 'TableTopSegmentsBenchmark', type: 'advanced', settingsFields: {
        keys: ['benchmark', 'show_title', 'title', 'segment', 'limit', 'custom_labels'],
        options: { segments: ['composition', 'country', 'language', 'source'], labels: ['review_distribution', 'year_over_year'] } }
      },
      chart_good_avg_bad_properties: { minW: 4, minH: 60, component: 'ChartGoodAvgBadProperties', type: 'advanced', settingsFields: {
        keys: ['benchmark', 'show_title', 'title', 'segment'],
        options: { segments: ['rating', 'response_rate'] } },
      },
      table_kpi_groups_ratings_benchmark: { minW: 4, minH: 5, component: 'TableKpiGroupsRatingBenchmark', type: 'advanced', settingsFields: {
        keys: ['benchmark', 'show_title', 'title', 'custom_labels'],
        options: { labels: ['rating_quarter', 'year_over_year', 'last_12_months', 'previous_12_months'] },
      } },
      table_kpi_groups_reviews_benchmark: { minW: 4, minH: 5, component: 'TableKpiGroupsReviewsBenchmark', type: 'advanced', settingsFields: {
        keys: ['benchmark', 'show_title', 'title', 'custom_labels'],
        options: { labels: ['reviews_quarter', 'per_property', 'year_over_year', 'last_12_months', 'previous_12_months'] },
      } },
      table_kpi_groups_response_score: { minW: 4, minH: 5, component: 'TableKpiGroupsResponseScore', type: 'advanced', settingsFields: {
        keys: ['benchmark', 'show_title', 'title', 'custom_labels'],
        options: { labels: ['required_responses', 'score_quarter', 'year_over_year', 'last_12_months', 'previous_12_months'] },
      } },
      table_response_score_by_property_type: { minW: 4, minH: 5, component: 'TableResponseScoreByPropertyType', type: 'advanced', settingsFields: {
        keys: ['benchmark', 'show_title', 'title', 'custom_labels'],
        options: { labels: ['required_responses', 'response_score', 'year_over_year'] },
      } },
      table_property_type_distributions: { minW: 4, minH: 5, component: 'TablePropertyTypeDistributions', type: 'advanced', settingsFields: { keys: ['benchmark', 'show_title', 'title'] } },
      pie_chart_reviews_top_segments: { minW: 4, minH: 15, component: 'PieChartReviewsTopSegments', type: 'advanced', settingsFields: {
        keys: ['segment', 'show_title', 'limit', 'title', 'height'],
        options: { segments: ['composition', 'country', 'language', 'source'], minHeight: 200, maxHeight: 800 } }
      },
      table_benchmark_own_groups: { minW: 4, minH: 5, component: 'TableBenchmarkOwnGroups', type: 'advanced', settingsFields: {
        keys: ['show_title', 'title', 'benchmark', 'custom_labels'],
        options: { labels: ['numerical_rating', 'reviews_count', 'trend'], n_segments: 50 },
      }
      },
      table_survey_scores: { minW: 12, minH: 5, component: 'TableSurveyScores', type: 'feedback', settingsFields: {
        keys: ['show_title', 'title', 'show_questions_without_answers', 'order_by', 'limit', 'benchmark', 'segment', 'show_trend', 'trend_range', 'select_surveys', 'custom_labels'],
        options: {
          segments:   ['survey_numerical_rating', 'survey_sentiment_rating', 'survey_tes_rating'],
          n_segments: 1,
          columns:    ['question_title', 'answers_count', 'value', 'previous_period', 'benchmark_avg_rating'],
          labels:     ['question_title', 'answers_count', 'value', 'previous_period', 'benchmark_avg_rating']
        }
      } },
      trend_tracker: { minW: 4, minH: 4, component: 'TrendTracker', type: 'advanced', settingsFields: { keys: ['show_title', 'title', 'random'] } },
      table_source_ratings: { minW: 8, minH: 5, component: 'TableSourceRatings', type: 'advanced', settingsFields: {
        keys: ['show_title', 'title', 'period', 'source', 'filters'], options: { filterCols: ['source'] }
      }},
    },
    basedOn: ['companies', 'groups']
  },

  requests: {
    async getReports(identifier, ownBenchmark) {
      if (!ownBenchmark == undefined) ownBenchmark = true
      if(!this.company_id && !(this.group_id || this.group_ids)) return console.warn(`Missing ids for ${identifier}`)

      this.loading = true;
      this.error = false;
      let s = this.settings;
      let group_ids = this.group_ids
      if (s.bench_group_ids?.length) group_ids = s.bench_group_ids
      if (identifier == 'response_score_by_property_type')
      if (ownBenchmark && !group_ids.includes(this.group_id)) group_ids.push(this.group_id)


      try {
        const params = {
          identifier:         identifier,
          company_id:         this.company_id,
          group_id:           this.group_id,
          group_ids:          group_ids,
          segment:            this.segment || s.segment,
          start_date:         this.start_date,
          end_date:           this.end_date,
          min_reviews:        s.min_reviews == undefined ? this.min_reviews : s.min_reviews,
          property_type:      s.filters?.property_type,
          sort:               s.order_by,
          order:              s.order,
          periods:            { yoy: 1 },
          with_response_rate: this.with_response_rate,
          limit:              this.limit || s.limit || 5
        }
        const response = await _axios.get("/v3/query/db/_", { params: params })
        return response.data.data;
      } catch (error) {
        console.error('An error occurred:', error);
        this.error = true;
        this.loading = false;
      }
      this.loading = false;
    },
    translateSegment(segment, key, fallbackKey) {
      const segmentPaths = {
        'country': 'countries',
        'language': 'locales',
        'composition': 'travel_composition',
      }
      if (['country', 'language'].includes(segment)) {
        return this.$t(`${segmentPaths[segment]}.${key}`);
      }
      return fallbackKey || key
    }
  },
}

const axios = window.reports.axios
export {axios}

export default window.reports
