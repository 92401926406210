<script>
import PageHeader from "@/components/page-header";

export default {
  page: {
    title: "Social Sentiment",
  },
  components: { PageHeader },
  data() {
    return {
      iframe: ""
    };
  },
  created() {
    this.$store.dispatch('user/fetch').then(async (data) => {
      this.iframe = data.destination_contracts[0].social_sentiment_url
    })
  },
};
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('tci_social_sentiments.title')" />

    <template v-if="iframe">
      <div class="ratio ratio-4x3" style="padding-top: calc(75vh - 40vw);">
        <iframe
          :src="iframe+'?src=embed'"
          title="Data_Sentiment_test_Olery"
          width="100%"
          height="auto"
          scrolling="auto"
          frameborder="0"
          style="border: none; width: 100%;"
          allowfullscreen="allowfullscreen"
        >
        </iframe>
      </div>

      <div
        style="
          width: 100%;
          padding: 8px 0;
          font-family: Arial !important;
          font-size: 13px !important;
          line-height: 15px !important;
          text-align: center;
          border-top: 1px solid #dadada;
        "
      >
        <a
          :href="iframe"
          style="color: #989898 !important; text-decoration: none !important"
          target="_blank"
        >
          Data Sentiment
        </a>
        <br>
        <a
          lang="en"
          href="https://www.lesphinx.eu"
          style="color: #989898 !important; text-decoration: none !important"
          target="_blank"
          rel="noopener"
        >
          Powered by Sphinx
        </a>
      </div>
    </template>

    <template v-else>
      <div class="card mt-3 font-size-16">
        <div class="card-body d-flex justify-content-center">
          <span>{{ $t("tci_social_sentiments.no_url") }}</span>
        </div>
      </div>
    </template>
  </div>
</template>
