<script>
import Multiselect from "vue-multiselect";
import QuestionSettings from "./question-settings";
import axios from "axios";
import Swal from "sweetalert2";
import DraggableItem from "./draggable-item";

import { Field } from 'vee-validate';
import Utils from '@/js/utils'
import DataUtils from "@/js/data-utils";

import DateField from "@/components/feedback-form-inputs/date";
import DropdownField from "@/components/feedback-form-inputs/dropdown";
import EnumField from "@/components/feedback-form-inputs/enum";
import EmailNameField from "@/components/feedback-form-inputs/email-name";
import MultipleChoiceField from "@/components/feedback-form-inputs/multiple-choice";
import RatingField from "@/components/feedback-form-inputs/rating";
import OpenAnswerField from "@/components/feedback-form-inputs/open_answer";
import TripadvisorField from "@/components/feedback-form-inputs/tripadvisor-iframe";
import YesNoField from "@/components/feedback-form-inputs/yes-no";
import Welcome from "@/components/feedback-form-inputs/welcome";
import ThankYou from "@/components/feedback-form-inputs/tail";

export default {
  data() {
    return {
      newGlobalQuestion: {},
      settings: {
        bg:    null,
        color: null,
        logo:  null,
        defaultLanguage: "",
        currentLanguage: "en",
        availableLanguages: [],
      },
      questions_ids: [],
      welcome: {},
      thank_you: {},
      currentQuestionIndex: 'welcome',
      groupName: null,
      collapsedAddQuestions: true,
      collapsedAddGroup: true,
      loadingAction: false,
      nextUpdate: null,
      typingDelay: 700,
      // dinamically adding "trip_advisor_iframe"
      baseQuestionTypes: ["date", "drop_down", "enum", "multiple_select", "open_answer", "open_answer_long", "past_date", "rating", "yes_no", "nps"],
      missingTranslationsHash: {},
      dragDrop: null,
      modals: { addQuestion: false, addGroup: false }
    };
  },
  components: {
    Welcome,
    Multiselect,
    Field,
    QuestionSettings,
    DateField,
    DropdownField,
    EnumField,
    EmailNameField,
    MultipleChoiceField,
    RatingField,
    OpenAnswerField,
    TripadvisorField,
    YesNoField,
    DraggableItem,
    ThankYou
  },
  props: ["survey", "creation", "questionHash", "globalQuestions", "topics", "contract", "companies"],
  async mounted() {
    if(this.survey.id) {
      this.conditions     = this.survey.conditions;
      this.settings.color = this.survey.color_hex;
      if (this.settings.color && !/#/.test(this.settings.color)) this.settings.color = "#" + this.settings.color;
      else this.settings.color ||= "";
      this.settings.bg    = this.survey.bg_url;
      this.settings.logo  = this.survey.logo_url;

      this.settings.defaultLanguage = this.survey.default_locale;
      this.settings.currentLanguage = this.survey.default_locale;

      this.settings.availableLanguages = this.survey.languages;
      this.settings.ta_urls = this.survey.trip_advisor_iframe_urls;

      this.formatQuestions();
      this.questions.forEach(q => this.questions_ids.push(q.id));
      this.updateOptionsDefault();
      this.$i18n.locale = await this.$store.dispatch("locale/fetch");

      this.welcome = {
        id: 'welcome',
        title:        this.survey.translations[this.settings.currentLanguage]?.title,
        instructions: this.survey.translations[this.settings.currentLanguage]?.instructions,
        translations: this.survey.translations,
        type: "welcome"
      };

      this.thank_you = {
        id: 'thank_you',
        redirect_message: this.survey.translations[this.settings.currentLanguage]?.redirect_message || this.survey.redirect_message,
        redirect_url:     this.survey.redirect_url,
        translations:     this.survey.translations,
        type: "thank_you"
      };

      this.settings.availableLanguages.forEach((locale) => {
        if(!this.thank_you.translations[locale] || Object.keys(this.thank_you.translations[locale]).length == 0) {
          if (locale == this.settings.defaultLanguage) {
            this.thank_you.translations[locale] = { redirect_message: this.survey.redirect_message };
          } else this.thank_you.translations[locale] = { redirect_message: null };
        }
      });

      this.verifyTranslations();
      this.resize();
    }
  },
  updated() {
    this.resize();
  },
  computed: {
    questions () {
      let questions = this.survey.items.map(i => {
        if (i.type == 'question') return i.object
        if (i.type == 'group') return i.object.map(q => q.object )
      })
      questions = questions.flat().filter(n => n)
      return questions
    },
    questionsById () {
      return this.questions.reduce((map, q) => {
        map[q.id] = q
        return map
      }, { thank_you: this.thank_you })
    },
    draggableItems: {
      get() {
        return this.survey.items;
      },
      set(value) {
        this.updateItems(value);
      }
    },
    currentGroupIndex() {
      let index = null;
      this.survey.items.filter(i => i.type == "group").forEach(item => {
        if (Array.isArray(item.object)) item.object.forEach(q => {
          if (q.id == this.currentQuestionIndex) index = item.id;
        });
        else if (item.object.id == this.currentQuestionIndex) index = item.id;
      });
      return index;
    },
    hasCondition() {
      let id = this.currentGroupIndex || this.currentQuestionIndex;
      return this.survey.conditions.filter(c => c.target.id == id).length > 0;
    },
    hasTAQuestion() {
      return this.questions.reduce((res, question) => {
        if (question.type == "trip_advisor_iframe") res = true;
        return res;
      }, false);
    },
    questionTypes() {
      let types = [...this.baseQuestionTypes];
      if (this.hasTAQuestion) return types;
      types.push("trip_advisor_iframe");
      return types
    },
    computedGlobalQuestions() {
      return this.globalQuestions.filter(g => {
        return this.settings.availableLanguages.reduce((res, curr) => {
          if (g.texts.question[curr]) res = true;
          return res;
        }, false);
      });
    }
  },
  methods: {
    ...DataUtils,
    resize() {
      let textareas = document.querySelectorAll("#textarea-auto-resize");
      for (let i = 0; i < textareas.length; i++) {
        textareas[i].style.height = 0;
        textareas[i].style.height = (textareas[i].scrollHeight) + 15 + "px";
      }
    },
    formatQuestions() {
      this.survey.items.forEach(item => {
        if (item.type == 'question') this.formatQuestion(item.object);
        else if (item.type == 'group') item.object.forEach(q => this.formatQuestion(q.object))
      })
    },
    formatQuestion(question) {
      question.answer = "";
      if (!question.texts?.question)    question.texts.question    = {};
      if (!question.texts?.description) question.texts.description = {};
    },
    updateOptionsDefault() {
      this.questions.forEach(question => {
        question.options?.forEach(option => {
          option[this.settings.defaultLanguage] ||= option.default || option.text;
          option.default = option[this.settings.defaultLanguage];
        });
      });
    },
    forwardButtonLabel (question, questionId) {
      if (questionId == this.questions.at(-1)?.id) return this.$t("feedback-form.submit", this.settings.currentLanguage)
      else if (question.answer) return this.$t("feedback-form.next", this.settings.currentLanguage);
      else return this.$t("feedback-form.skip", this.settings.currentLanguage);
    },
    setCurrentQuestion(questionIndex) {
      this.currentQuestionIndex = questionIndex;
      if (window.scrollY != 0) {
        setTimeout(() => {
          window.scrollTo(0, 100);
        }, 10);
      }
    },
    async addQuestion(question, action) {
      let newQuestion;
      Utils.updatePageOpts({ statusMessage: { status: "saving", icon: "ion ion-md-sync" } });
      this.loadingAction = true;
      this.closeAddQuestionModal();

      if(action == 'duplicate' || action == 'global') {
        newQuestion = await axios.post(`/v3/feedback/questions/${question.id}/duplicate`, { subscription_id: this.contract.id });
      }
      if(action == 'from_scratch') {
        newQuestion = {
          question: this.$t("new_survey.questions.edit.default_question"),
          type: "multiple_select",
          options: [{
            id: this.$t("new_survey.questions.edit.default_option", this.settings.defaultLanguage).toLowerCase(),
            default: this.$t("new_survey.questions.edit.default_option", this.settings.defaultLanguage),
            [this.settings.defaultLanguage]: this.$t("new_survey.questions.edit.default_option", this.settings.defaultLanguage)
          }],
          texts: {
            question: {
              [this.settings.defaultLanguage]: this.$t("new_survey.questions.edit.default_question")
            },
            description: {
              [this.settings.defaultLanguage]: ""
            }
          },
          subscription_id: this.contract.id
        };

        newQuestion = await axios.put("/v3/feedback/questions", newQuestion);
      }

      newQuestion = newQuestion.data.data;
      newQuestion.options?.forEach(option => {
        if(!option[this.settings.defaultLanguage]) option[this.settings.defaultLanguage] = option.default || option.text;
      });

      this.questions_ids.push(newQuestion.id);
      this.survey.items.push({ type: 'question', object: newQuestion, id: newQuestion.id});
      this.survey.question_ids = this.questions_ids;

      this.currentQuestionIndex = newQuestion?.id || this.questions_ids[0]

      this.formatQuestions();
      this.verifyTranslations();

      await axios.post(`/v3/feedback/surveys/${this.survey.id}`, {
        question_ids: this.questions_ids,
        items:        this.survey.items
      });

      this.newGlobalQuestion = {};
      Utils.updatePageOpts({ statusMessage: { status: "saved", icon: "mdi mdi-check-bold" } });
      this.loadingAction = false;

      if (action != 'duplicate') {
        let listDiv = this.$refs["list-questions"];
        listDiv.scroll({ top: listDiv.scrollHeight, behavior: "smooth"});
      }
    },
    async deleteQuestion() {
      let confirmMessage = this.$t("new_survey.questions.edit.delete_confirm");
      let dependentConditions = this.findDependentConditions(this.currentQuestionIndex);
      if (dependentConditions.length) {
        confirmMessage += `\n${this.$tc("new_survey.questions.edit.remove_condition_dependent", dependentConditions.length > 1, { n: dependentConditions.length })}`;
      }
      if (confirm(confirmMessage)) this.removeQuestion(this.currentQuestionIndex, true, dependentConditions);
    },
    async removeQuestionCB(id) {
      let confirmMessage = this.$t("new_survey.questions.edit.remove_confirm");
      let dependentConditions = this.findDependentConditions(id);
      if (dependentConditions.length) {
        confirmMessage += `\n${this.$tc("new_survey.questions.edit.remove_condition_dependent", dependentConditions.length > 1, { n: dependentConditions.length })}`;
      }
      if(confirm(confirmMessage)) this.removeQuestion(id, false, dependentConditions);
    },
    findDependentConditions(questionId) {
      let question = this.questionsById[questionId];
      let conditions = this.survey.conditions.filter(condition => condition.source.id == question.id || condition.target.id == question.id);
      return conditions;
    },
    async removeQuestion(questionId, alsoDelete, dependentConditions = []) {
      try {
        this.questions_ids = this.questions_ids.filter(q_id => q_id != questionId);
        this.survey.items  = this.survey.items.filter(i => i.type == 'question' && i.id != questionId )
        this.survey.items.forEach(i => {
          if (i.type == 'group') i.object = i.object.filter(q => q.id != questionId )
        })
        this.survey.question_ids = this.questions_ids;

        Utils.updatePageOpts({ statusMessage: { status: "saving", icon: "ion ion-md-sync" } });
        this.loadingAction = true;

        if (dependentConditions.length) this.survey.conditions = this.survey.conditions.filter(c => ![c.source.id, c.target.id].includes(questionId));

        await axios.post(`/v3/feedback/surveys/${this.survey.id}`, {
          question_ids: this.questions_ids,
          items:        this.survey.items,
          ...(dependentConditions.length) && { conditions: this.survey.conditions }
        });
        if (alsoDelete) await axios.delete(`/v3/feedback/questions/${questionId}`);
        Utils.updatePageOpts({ statusMessage: { status: "saved", icon: "mdi mdi-check-bold" } });
        this.loadingAction = false;

        if (this.currentQuestionIndex == this.questions.length) this.currentQuestionIndex = this.questions[0].id;

        this.verifyTranslations();
      } catch(error) {
        this.error(error);
      }
    },
    async removeGroup (slug) {
      if(confirm(this.$t('new_survey.questions.edit.remove_group_confirm'))) {
        Utils.updatePageOpts({ statusMessage: { status: "saving", icon: "ion ion-md-sync" } });
        this.loadingAction = true;

        let i = this.survey.items.findIndex(i => i.id == slug)
        if (i >= 0) this.survey.items.splice(i, 1)

        this.questions_ids = this.questions.map(q => q.id)
        await axios.post(`/v3/feedback/surveys/${this.survey.id}`, {
          question_ids: this.questions_ids,
          items:        this.survey.items
        });
        Utils.updatePageOpts({ statusMessage: { status: "saved", icon: "mdi mdi-check-bold" } });
        this.loadingAction = true;
        this.verifyTranslations();
      }
    },
    error (error) {
      return Swal.fire(this.$t('general.error'), this.$t('general.contact_support') + JSON.stringify(error.response.data), 'error');
    },
    toggle(option) {
      this[option] = !this[option];
    },
    collapseClass(option) {
      if (this[option]) return 'collapse';
      return 'collapse.show';
    },
    updater(question_id, update_field) {
      this.nextUpdate = Date.now() + this.typingDelay;
      this.$forceUpdate();
      setTimeout(() => this.updateQuestions(question_id, update_field), this.typingDelay);
    },
    async updateQuestions(question_id, update_field) {
      if (this.nextUpdate > Date.now()) return;
      if (!this.validate(question_id)) {
        console.error(question_id, "Question not valid")
        return;
      }

      this.verifyTranslations();

      Utils.updatePageOpts({ statusMessage: { status: "saving", icon: "ion ion-md-sync" } });
      this.loadingAction = true;

      if(question_id == "welcome") {
        ["title", "instructions"].forEach(field => {
          this.survey.translations[this.settings.currentLanguage][field] = this.welcome.translations[this.settings.currentLanguage][field];
        });

        await axios.post(`/v3/feedback/surveys/${this.survey.id}`, this.survey);
        Utils.updatePageOpts({ statusMessage: { status: "saved", icon: "mdi mdi-check-bold" } });
        return;
      }
      else if (question_id == "thank_you") {
        if (this.settings.currentLanguage == this.settings.defaultLanguage)
          this.survey.redirect_message = this.thank_you.translations[this.settings.currentLanguage]?.redirect_message;

        this.survey.translations[this.settings.currentLanguage].redirect_message = this.thank_you.translations[this.settings.currentLanguage].redirect_message;
        if (this.thank_you.redirect_url?.length > 0 && Utils.validURL(this.thank_you.redirect_url)) this.survey.redirect_url = this.thank_you.redirect_url;

        await axios.post(`/v3/feedback/surveys/${this.survey.id}`, this.survey);
        Utils.updatePageOpts({ statusMessage: { status: "saved", icon: "mdi mdi-check-bold" } });
        return;
      }

      let question = this.questions.find(q => q.id == question_id);
      if (question.type == "nps" && update_field?.update && update_field.update == "question_type") {
        question.max_value = 10;
        this.settings.availableLanguages.forEach(locale => {
          question.texts.question[locale] = this.$t("new_survey.questions.edit.nps_title", locale);
        });
      }
      this.survey.questions = this.questions;

      await axios.post(`/v3/feedback/questions/${question.id}`, question);
      Utils.updatePageOpts({ statusMessage: { status: "saved", icon: "mdi mdi-check-bold" } });
      this.loadingAction = false;
    },
    updateItems(items) {
      this.survey.items = items;
      this.changeQuestionsOrder();
    },
    async changeQuestionsOrder() {
      Utils.updatePageOpts({ statusMessage: { status: "saving", icon: "ion ion-md-sync" } });
      this.loadingAction = true;

      this.questions_ids        = this.questions.map(q => q.id);
      this.survey.question_ids  = this.questions_ids;
      this.currentQuestionIndex = this.dragDrop?.id || this.questions[0].id;

      await axios.post(`/v3/feedback/surveys/${this.survey.id}`, {
        question_ids: this.questions_ids,
        items:        this.survey.items
      });
      Utils.updatePageOpts({ statusMessage: { status: "saved", icon: "mdi mdi-check-bold" } });
      this.loadingAction = false;
    },
    useTranslation(question) {
      if (!question.texts) return "";
      let locale = this.$i18n.locale == "es" ? "sp" : this.$i18n.locale;
      let result = question.texts.question[locale];
      if (result) return question.texts.question[locale];
      this.settings.availableLanguages.forEach(lang => {
        if (question.texts.question[lang] && !result) result = question.texts.question[lang];
      });
      return result;
    },
    placeholder(question, field) {
      return question.texts[field][this.settings.currentLanguage] ||
      (question.texts[field][this.settings.defaultLanguage] && question.texts[field][this.settings.defaultLanguage] + " " + this.$t("new_survey.questions.edit.needs_translation")) ||
      this.$t(`new_survey.questions.edit.${field}_placeholder`);
    },
    closeAddQuestionModal() {
      this.newGlobalQuestion = {};
      this.modals.addQuestion = false;
      if(!this.collapsedAddQuestions) this.toggle("collapsedAddQuestions");
    },
    closeAddGroupModal() {
      this.groupName = null;
      this.modals.addGroup = false;
    },
    validate(question_id) {
      if (question_id == "welcome") {
        if (this.welcome.translations[this.settings.currentLanguage].title.length == 0)
          return false;
      }
      else if (question_id != "thank_you") {
        let question = this.questions.filter(q => q.id == question_id)[0];
        if (question.texts.question[this.settings.currentLanguage]?.length == 0) return false;
      }
      return true;
    },
    verifyTranslations() {
      if (this.survey.languages.length < 2) return;
      let missingTranslations = false;
      let filterLocales = this.settings.availableLanguages.filter(l => l != this.settings.defaultLanguage);

      // Welcome page
      let welcomeTranslations = { title: null, instructions: null };
      this.missingTranslationsHash.welcome = {};
      ["title", "instructions"].forEach(field => {
        welcomeTranslations[field] = Object.keys(this.survey.translations).filter(l => this.survey.translations[l][field] != null && this.survey.translations[l][field]?.length > 0);
        let welcomeDiff = filterLocales.filter(l => !welcomeTranslations[field].includes(l));

        if(welcomeTranslations[field]?.length > 0 && welcomeDiff?.length > 0) {
          missingTranslations = true;
          this.missingTranslationsHash.welcome[field] = welcomeDiff;
        } else if (this.missingTranslationsHash.welcome?.[field]) this.missingTranslationsHash.welcome[field] = [];
      });

      // Thank you page
      let thankyouTranslations = Object.keys(this.survey.translations).filter(l => this.survey.translations[l].redirect_message != null && this.survey.translations[l].redirect_message?.length > 0);
      this.missingTranslationsHash.thank_you = { redirect_message: null };
      let thankyouDiff = filterLocales.filter(l => !thankyouTranslations.includes(l));

      if(thankyouTranslations?.length > 0 && thankyouDiff?.length > 0) {
        missingTranslations = true;
        this.missingTranslationsHash.thank_you.redirect_message = thankyouDiff;
      } else if (this.missingTranslationsHash.thank_you?.redirect_message) this.missingTranslationsHash.thank_you.redirect_message = [];

      // Questions
      this.questions.forEach(question => {
        var index = question.id

        // Question
        let questionTranslations = Object.keys(question.texts.question || {}).filter(q => question.texts.question[q]?.length > 0);
        let questionDiff = filterLocales.filter(l => !questionTranslations.includes(l));

        if(questionDiff?.length > 0) {
          missingTranslations = true;
          this.missingTranslationsHash[index] = { question: questionDiff };
        } else if (this.missingTranslationsHash[index]?.question) this.missingTranslationsHash[index].question = [];

        // Description
        let descriptionTranslations = Object.keys(question.texts.description || {}).filter(d => question.texts.description[d]?.length > 0);
        let descriptionDiff = filterLocales.filter(l => !descriptionTranslations.includes(l));

        // only validates if there is a description in some language
        if(descriptionTranslations?.length > 0 && descriptionDiff?.length > 0) {
          missingTranslations = true;
          this.missingTranslationsHash[index] = { ...this.missingTranslationsHash[index], description: descriptionDiff }
        } else if (this.missingTranslationsHash[index]?.description) this.missingTranslationsHash[index].description = [];

        // options
        if (/multiple_select|enum|drop_down/.test(question.type)) {
          question.options.forEach(option => {
            let test = Object.keys(option).filter(o => o != 'default' && o != 'id' && option[o]?.length > 0);
            let optionDiff = filterLocales.filter(l => !test.includes(l));
            if(optionDiff?.length > 0) {
              if(this.missingTranslationsHash[index]) this.missingTranslationsHash[index].options = { ...this.missingTranslationsHash[index].options };
              else this.missingTranslationsHash[index] = { options: { [option.id]: [] } };

              missingTranslations = true;
              this.missingTranslationsHash[index].options[option.id] = optionDiff;
            } else if (this.missingTranslationsHash[index]?.options?.[option.id]) this.missingTranslationsHash[index].options[option.id] = [];
          });
        }

        // rating's labels
        if (/rating/.test(question.type)) {
          let labelsTranslations = { best: [], worst: [] };

          question.config.edge_labels ||= { best: {}, worst: {} }
          labelsTranslations.best = Object.keys(question.config.edge_labels?.best)?.filter(e => e != 'default' && question.config.edge_labels?.best[e]?.length > 0);
          let bestDiff = filterLocales.filter(l => !labelsTranslations?.best.includes(l));

          labelsTranslations.worst = Object.keys(question.config.edge_labels.worst)?.filter(e => e != 'default' && question.config.edge_labels.worst[e]?.length > 0);
          let worstDiff = filterLocales.filter(l => !labelsTranslations.worst.includes(l));

          // only consider a missing translation if there is something in the default language
          if((labelsTranslations.best?.length > 0 && bestDiff?.length > 0) || (labelsTranslations.worst?.length > 0 && worstDiff?.length > 0)) {
            if(this.missingTranslationsHash[index]) this.missingTranslationsHash[index].edge_labels = {};
            else this.missingTranslationsHash[index] = {};

            missingTranslations = true;

            this.missingTranslationsHash[index].edge_labels = {
              worst: worstDiff,
              best:  bestDiff
            }
          } else if (this.missingTranslationsHash[index]?.edge_labels) this.missingTranslationsHash[index].edge_labels = {};
        }
      });

      this.missingTranslationsHash = this.filterMissingQuestions();

      this.$emit("verifyTranslations", missingTranslations);
    },
    filterMissingQuestions() {
      let res = {};

      let validIndexes = Object.keys(this.missingTranslationsHash).filter(index => Object.keys(this.missingTranslationsHash[index]).length > 0);

      validIndexes.forEach(index => {
        res[index] = {};

        // welcome
        ["title", "instructions"].forEach(field => {
          if (this.missingTranslationsHash[index][field]?.length > 0) res[index][field] = this.missingTranslationsHash[index][field];
        });

        // Thank you
        if (this.missingTranslationsHash[index]?.redirect_message?.length > 0) res[index].redirect_message = this.missingTranslationsHash[index].redirect_message;

        if (this.missingTranslationsHash[index].question?.length > 0) res[index].question = this.missingTranslationsHash[index].question;

        if (this.missingTranslationsHash[index].description?.length > 0) res[index].description = this.missingTranslationsHash[index].description;

        if (this.missingTranslationsHash[index].options) {
          Object.keys(this.missingTranslationsHash[index].options).forEach(id => {
            if (this.missingTranslationsHash[index].options[id].length > 0) {
              if (!res[index].options) res[index].options = {};
              res[index].options[id] = this.missingTranslationsHash[index].options[id]
            }
          });
        }

        if (this.missingTranslationsHash[index].edge_labels) {
          Object.keys(this.missingTranslationsHash[index].edge_labels).forEach(label => {
            if (this.missingTranslationsHash[index].edge_labels[label].length > 0) {
              if (!res[index].edge_labels) res[index].edge_labels = {};
              res[index].edge_labels[label] = this.missingTranslationsHash[index].edge_labels[label]
            }
          });
        }
      });

      Object.keys(res).forEach(k => {
        if (Object.keys(res[k]).length == 0) delete res[k];
      });

      return res;
    },
    pointMissingTranslation() {
      for(let index in this.missingTranslationsHash) {
        this.currentQuestionIndex = index;

        // welcome
        ["title", "instructions"].forEach(field => {
          if (this.missingTranslationsHash[index][field]) {
            this.missingTranslationsHash[index][field].forEach(async locale => {
              this.settings.currentLanguage = locale;
              await this.$nextTick();
              let element = this.$refs[index]?.$refs?.[`${field}_${index}`];
              element?.focus();
              return;
            });
            return;
          }
        });

        // thank you
        if (this.missingTranslationsHash[index]?.redirect_message) {
          this.missingTranslationsHash[index].redirect_message.forEach(async locale => {
            this.settings.currentLanguage = locale;
            await this.$nextTick();
            let element = this.$refs["thank_you"]?.$refs?.["redirect_message_thank_you"];
            element.focus();
            return;
          });
          return;
        }

        if (this.missingTranslationsHash[index].question) {
          this.missingTranslationsHash[index].question.forEach(async locale => {
            this.settings.currentLanguage = locale;
            await this.$nextTick();
            let element = this.$refs[`question_${index}`]?.[0];
            element.focus();
            return;
          });
          return;
        }

        if (this.missingTranslationsHash[index].description) {
          this.missingTranslationsHash[index].description.forEach(async locale => {
            this.settings.currentLanguage = locale;
            await this.$nextTick();
            let element = this.$refs[`description_${index}`]?.[0];
            element.focus();
            return;
          });
          return;
        }

        if (this.missingTranslationsHash[index].options) {
          Object.keys(this.missingTranslationsHash[index].options).forEach(id => {
            this.missingTranslationsHash[index].options[id].forEach(async locale => {
              this.settings.currentLanguage = locale;
              await this.$nextTick();
              let element = this.$refs[index][0].$refs[`${index}_option_${id}`][0];
              element.focus();
              return
            });
          });
          return;
        }

        if (this.missingTranslationsHash[index].edge_labels) {
          Object.keys(this.missingTranslationsHash[index].edge_labels).forEach(label => {
            this.missingTranslationsHash[index].edge_labels[label].forEach(async locale => {
              this.settings.currentLanguage = locale;
              await this.$nextTick();
              let element = this.$refs[index][0].$refs[`${index}_edge_label_${label}_${index}`];
              element.focus();
              return;
            });
          });
          return;
        }

        return;
      }
    },
    hasMissingTranslations(index, field, subfield) {
      if (!subfield) return this.missingTranslationsHash[index]?.[field]?.length > 0;
      else return this.missingTranslationsHash[index]?.[field]?.[subfield]?.length > 0
    },
    addGroup() {
      Utils.updatePageOpts({ statusMessage: { status: "saving", icon: "ion ion-md-sync" } });
      this.loadingAction = true;
      let group = {
        name: this.groupName,
        type: 'group',
        id: Utils.slugify(this.groupName),
        questions: [],
        object: []
      }
      //TODO: check if slug is repeated and change it
      this.survey.items.push(group)
      axios.post(`/v3/feedback/surveys/${this.survey.id}`, { items: this.survey.items });
      Utils.updatePageOpts({ statusMessage: { status: "saved", icon: "mdi mdi-check-bold" } });
      this.loadingAction = true;
      this.closeAddGroupModal();
    },
    beforeDrop() {
      this.dragDrop = this.questionsById[this.currentQuestionIndex];
    },
    async autotranslate() {
      let promises = [];
      let locales = this.settings.availableLanguages.filter(l => l != this.settings.defaultLanguage);
      this.loadingAction = true;

      // welcome and thank you pages
      locales.forEach(locale => {
        let request = { data: {}, locale: locale };
        ["title", "instructions", "redirect_message"].forEach(field => {
          if (this.survey.translations[this.settings.defaultLanguage][field] && !this.survey.translations[locale][field])
            request.data[field] = Utils.addEscapeTranslation(this.survey.translations[this.settings.defaultLanguage][field]);
        });

        if (Object.keys(request.data).length > 0) {
          promises.push(axios.post("/v3/translate", request).then(res => {
            let response = res.data.data;
            ["title", "instructions", "redirect_message"].forEach(field => {
              if (response[field]) {
                this.survey.translations[locale][field] = Utils.removeEscapeTranslation(response[field][0]);
                if (field == "redirect_message") this.updateQuestions("thank_you");
                else this.updateQuestions("welcome");
              }
            });
          }));
        }
      });

      this.questions.forEach(question => {
        locales.forEach(locale => {
          let request = { data: {}, locale: locale };

          ["question", "description"].forEach(field => {
            if (question.texts[field][this.settings.defaultLanguage] && !question.texts[field][locale])
              request.data[field] = Utils.addEscapeTranslation(question.texts[field][this.settings.defaultLanguage]);
          });

          ["best", "worst"].forEach(field => {
            if (question.config.edge_labels?.[field][this.settings.defaultLanguage] && !question.config.edge_labels?.[field][locale])
              request.data[field] = Utils.addEscapeTranslation(question.config.edge_labels[field][this.settings.defaultLanguage]);
          });

          question.options.forEach(option => {
            if (!option[locale] && option[this.settings.defaultLanguage]) request.data["option_" + (option.id || option.default)] = Utils.addEscapeTranslation(option[this.settings.defaultLanguage]);
          });

          if (Object.keys(request.data).length > 0) {
            promises.push(axios.post("/v3/translate", request).then(res => {
              let response = res.data.data;
              ["question", "description"].forEach(field => {
                if (response[field]) question.texts[field][locale] = Utils.removeEscapeTranslation(response[field][0]);
              });

              ["best", "worst"].forEach(field => {
                if (response[field]) question.config.edge_labels[field][locale] = Utils.removeEscapeTranslation(response[field][0]);
              });

              question.options.forEach(option => {
                if (response["option_" + option.id]) option[locale] = Utils.removeEscapeTranslation(response["option_" + option.id][0]);
              });

              this.updateQuestions(question.id);
            }));
          }
        });
      });

      await Promise.all(promises);
      this.loadingAction = false;
    },
    searchableQuestion(question) {
      return Object.values(question.texts?.question || {});
    },
    pointLogic() {
      let index = this.currentGroupIndex || this.currentQuestionIndex;
      this.$emit("changeTab", "logic");
      this.$emit("pointLogic", index);
    }
  }
};
</script>

<template>
  <div v-if="survey.id" class="root-questions" :class="{ 'cursor-loading': loadingAction }">
    <div class="row">
      <!-- Collapsable right menu -->
      <div class="show-side-menu w-100" v-if="currentQuestionIndex != 'welcome'">
        <button class="fixed-button btn btn-secondary" data-bs-toggle="offcanvas" data-bs-target="#config-sidebar" aria-controls="config-sidebar">
          <i class="ion ion-md-settings" />
        </button>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="config-sidebar">
          <div class="offcanvas-header d-flex justify-content-start pb-0 pt-4">
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
          </div>
          <div class="offcanvas-body">
            <QuestionSettings :survey="survey" :question="questionsById[currentQuestionIndex]" :questionTypes="questionTypes" :questionHash="questionHash" :topics="topics" @changeInput="updater(currentQuestionIndex, $event)" :companies="companies"/>
          </div>
        </div>
      </div>


      <!-- left column -->
      <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mb-3 d-flex flex-column align-items-center list-questions-wrapper" style="max-height: 100vh;">

        <div class="card h-100 w-100">
          <div class="card-body h-100 px-0 d-flex flex-column w-100">
            <div class="add_question_button d-flex flex-column justify-content-between align-items-center px-4 w-100">
              <div class="w-100 d-flex justify-content-between align-items-center">
                <p class="m-0 font-weight-bold"> {{ $t("new_survey.questions.left.questions") }} </p>
                <b-dropdown size="lg" variant="primary" dropleft toggle-class="text-decoration-none" no-caret text="+" class="mb-2">
                  <b-dropdown-item class="d-block p-0" @click="modals.addQuestion = true">
                    {{ "+ " + $t("new_survey.questions.left.question") }}
                  </b-dropdown-item>

                  <!-- <b-dropdown-item class="d-block p-0" @click="modals.addGroup = true">
                    {{ "+ " + $t("new_survey.questions.left.group_questions") }}
                  </b-dropdown-item> -->
                </b-dropdown>
              </div>
            </div>

            <b-modal v-model="modals.addQuestion" id="add-question" centered :title="$t('new_survey.questions.left.question_title')" title-class="font-18" hide-footer hide-header :size="collapsedAddQuestions ? 'md' : 'lg'" @hidden="closeAddQuestionModal">
							<div class="p-4 d-flex flex-column">
                <h3> {{ $t('new_survey.questions.left.question_title') }} </h3>

                <a href="javascript:;" class="py-3" v-show="collapsedAddQuestions == true" @click="addQuestion(null, 'from_scratch')">
                  <p class="font-weight-bold m-0">{{ $t('new_survey.questions.left.question_create_new') }}</p>
                </a>

                <div class="line" v-show="collapsedAddQuestions == true"></div>

                <a href="javascript:;" class="py-3" v-show="collapsedAddQuestions == true" @click="toggle('collapsedAddQuestions')">
                  <p class="font-weight-bold m-0">{{ $t("new_survey.questions.left.question_choose_existing") }}</p>
                </a>

                <div :class="collapseClass('collapsedAddQuestions')">
                  <p class="font-weight-bold my-3">{{ $t("new_survey.questions.left.question_choose_existing") }}</p>
                  <multiselect class="question_add_multiselect" v-model="newGlobalQuestion" :multiple="false" :options="computedGlobalQuestions" :customLabel="q => searchableQuestion(q)" :showLabels="false" :placeholder="$t('new_survey.questions.right.select_global_question')">
                    <template v-slot:singleLabel="props">
                      <i :class="questionHash[props.option.type] && questionHash[props.option.type].icon" class="me-1"></i>
                      <span> {{ useTranslation(props.option) }} </span>
                    </template>

                    <template v-slot:option="props">
                      <i :class="questionHash[props.option.type] && questionHash[props.option.type].icon" class="me-1"></i>
                      <span> {{ useTranslation(props.option) }} </span>
                    </template>
                  </multiselect>
                </div>

                <div class="d-flex justify-content-between align-items-center mt-4">
                  <button class="btn btn-outline-secondary" @click="closeAddQuestionModal"> {{ $t('new_survey.questions.left.cancel') }} </button>

                  <button class="btn btn-outline-primary" v-show="collapsedAddQuestions == false" @click="addQuestion(newGlobalQuestion, 'global')"> {{ $t('new_survey.questions.left.save') }} </button>
                </div>
							</div>
						</b-modal>

            <b-modal v-model="modals.addGroup" id="add-group-questions" centered :title="$t('new_survey.questions.left.group_title')" title-class="font-18" hide-footer hide-header size="md" >
							<div class="p-4 d-flex flex-column">
								<h3> {{ $t('new_survey.questions.left.group_title') }} </h3>

                <div class="group_name my-3">
                  <label class="mb-2 form-label">{{ $t("new_survey.questions.left.group_name") }}</label>
                  <b-form-input v-model="groupName" :placeholder="$t('new_survey.questions.left.group_name_help')"></b-form-input>
                </div>

                <div class="d-flex justify-content-between align-items-center mt-4">
                  <button class="btn btn-outline-secondary" @click="closeAddGroupModal"> {{ $t('new_survey.questions.left.cancel') }} </button>

                  <button class="btn btn-outline-primary align-self-end" @click="addGroup"> {{ $t('new_survey.questions.left.save') }} </button>
                </div>
							</div>
						</b-modal>

            <!-- List questions... -->
            <div class="list-questions mt-2 w-100" ref="list-questions">
              <!-- Questions -->
              <DraggableItem v-model='draggableItems' :questionHash='questionHash' :settings='settings' :currentId='currentQuestionIndex' @removeGroup='removeGroup' @removeQuestion='removeQuestionCB' @beforeDrop='beforeDrop' @setCurrentQuestion="setCurrentQuestion" :nested="false"/>
            </div>
          </div>
        </div>
      </div>

      <!-- edit question -->
      <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-6">
        <div class="card" :class="{ 'editor-bg': settings.bg }" :style="`background-image: url(${settings.bg})`">
          <div class="card-body d-flex flex-column">
            <!-- Actions -->
            <div class="d-flex justify-content-between mb-2 row">
              <div class="d-flex align-items-center col-8">
                <label class="me-2 form-label" :class="{ 'text-white': settings.bg }">
                  {{$t('new_survey.questions.edit.editing')}}
                </label>
                <multiselect v-model="settings.currentLanguage" :multiple="false" :options="settings.availableLanguages" :showLabels="false" :allow-empty="false" :placeholder="$t('new_survey.questions.edit.language')">
                  <template v-slot:singleLabel="props">
                    <div class="d-flex align-items-center">
                      <span :class="`flag flag-${locales(props.option, 'language').code}`" class="me-2"></span>
                      <span> {{ locales(props.option).name }} </span>
                    </div>
                  </template>

                  <template v-slot:option="props">
                    <div class="d-flex align-items-center">
                      <span :class="`flag flag-${locales(props.option, 'language').code}`" class="me-1"></span>
                      <span> {{ locales(props.option).name }} </span>
                    </div>
                  </template>
                </multiselect>
              </div>

              <div class="d-flex justify-content-end align-items-center col-4 mb-1">
                <a v-if="hasCondition" href="javascript:;" class="me-3" @click="pointLogic()" v-b-tooltip.hover :title="$t('new_survey.questions.edit.point_logic')">
                  <i class="fa fa-project-diagram text-primary"></i>
                </a>

                <b-dropdown class="px-0" v-show="currentQuestionIndex != 'welcome' &&  currentQuestionIndex != 'thank_you'" size="lg" variant="link" dropleft toggle-class="text-decoration-none p-0" no-caret>
                  <template #button-content>
                    <i class="mdi mdi-dots-vertical text-primary"></i>
                  </template>

                  <b-dropdown-text> {{ $t("new_survey.questions.edit.actions") }} </b-dropdown-text>

                  <b-dropdown-item class="d-block" @click="deleteQuestion">
                    <div class="d-flex align-items-center">
                      <i class="ion ion-md-trash font-size-18 me-1"></i>
                      <p class="m-0 font-size-14"> {{ $t("new_survey.questions.edit.delete") }} </p>
                    </div>
                  </b-dropdown-item>

                  <b-dropdown-item class="d-block" @click="addQuestion(questionsById[currentQuestionIndex], 'duplicate')">
                    <div class="d-flex align-items-center">
                      <i class="ion ion-ios-copy font-size-18 me-1"></i>
                      <p class="m-0 font-size-14"> {{ $t("new_survey.questions.edit.duplicate") }} </p>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

            <!-- Welcome Page -->
            <div class="new-survey" v-show="currentQuestionIndex == 'welcome'">
              <div class="container-fluid question-edit-modal d-flex flex-column justify-content-between align-items-center">
                <component ref="welcome" :is="questionHash['welcome'].component" :question="welcome" :settings="settings" :editing="true" @changeInput="updater(welcome.id)" />
              </div>
            </div>

            <template v-for="q in questions">
              <div class="new-survey" v-if="currentQuestionIndex == q.id && q.texts" :key="q.id + '_component'" :style="`--company-color: ${settings.color};`">
                <div class="container-fluid question-edit-modal d-flex flex-column justify-content-between align-items-center" :id="`question_modal_${q.id}`" style="max-height: unset !important;">
                  <img class="cover-logo mb-2" :src="settings.logo" alt="Company Logo" v-if="settings.logo"/>

                  <Field rules="required" :name="$t('new_survey.questions.left.question')" v-slot="{ field, errors }" v-model="q.texts.question[settings.currentLanguage]">
                    <div class="d-flex flex-column align-items-center w-100">
                      <textarea :ref="'question_' + q.id" class="title custom-textarea" v-bind="field"
                        id="textarea-auto-resize" :class="{ 'missing-translation': hasMissingTranslations(q.id, 'question') }"
                        :placeholder="placeholder(q, 'question')"
                        style="text-align: center;" @input="updater(q.id)"></textarea>
                      <span class="mt-2 text-danger font-size-14" id="error">{{ errors[0] }}</span>
                    </div>
                  </Field>

                  <textarea v-if='q.texts.description' :ref="'description_' + q.id" class="description custom-textarea mb-5" id="textarea-auto-resize" :class="{ 'missing-translation': hasMissingTranslations(q.id, 'description') }" v-model="q.texts.description[settings.currentLanguage]" :placeholder="$t('new_survey.questions.edit.description_placeholder')" style="text-align: center;" @input="updater(q.id)"></textarea>

                  <div class="links text-left w-100 mb-3 mt-2" v-if="q.links && q.links.length > 0">
                    <label class="form-label">Links</label>
                    <div class="" v-for='link in q.links' :key='(link[settings.currentLanguage] || link[settings.defaultLanguage]).title'>
                      <a :href="(link[settings.currentLanguage] || link[settings.defaultLanguage]).url" target='_blank'> {{ (link[settings.currentLanguage] || link[settings.defaultLanguage]).title }} </a>
                    </div>
                  </div>

                  <component :ref="q.id" :is="questionHash[q.type] && questionHash[q.type].component" :question="q" :settings="settings" :editing="true" @changeInput="updater(q.id)" :question_index="q.id" />

                  <div class="nav-bar d-flex justify-content-center mt-1">
                    <button type="button" class="btn btn-back btn-outline-secondary btn-lg shadow-sm me-2 cursor-default">
                      {{ $t("feedback-form.back", settings.currentLanguage) }}
                    </button>

                    <div>
                      <button type="button" class="btn btn-outline-primary btn-lg shadow-sm cursor-default">
                        {{ forwardButtonLabel (q, q.id) }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <!-- Thank you page -->
            <div class="new-survey" v-show="currentQuestionIndex == 'thank_you'">
              <component ref="thank_you" :is="questionHash['thank_you'].component" :question="thank_you" :settings="settings" :editing="true" @changeInput="updater(thank_you.id)" />
            </div>
          </div>
        </div>
      </div>

      <!-- right column -->
      <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 hide-side-menu" v-if="currentQuestionIndex != 'welcome'">
        <div class="card" style="max-height: 100vh; overflow-y: scroll;">
          <div class="card-body">
            <QuestionSettings :survey="survey" :question="questionsById[currentQuestionIndex]" :questionTypes="questionTypes" :questionHash="questionHash" :topics="topics" @changeInput="updater(currentQuestionIndex, $event)" :companies="companies"/>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
