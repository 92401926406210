<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      cols: [
        { field: "avg_review_score", label: "Avg. Review Score", colspan: 4 },
        { field: "reviews", label: "Number of Reviews", colspan: 4 },
        { field: "positive", label: "Positive Reviews", colspan: 4 },
        { field: "negative", label: "Negative Reviews", colspan: 4 }
      ],
      subCols: [
        { field: "name", label: "Property Name" }
      ],
      subColsFields: [
        { field: "this_month",  label: "This Month" },
        { field: "last_month",  label: "Last Month" },
        { field: "smly",        label: "Same Month Last Year" },
        { field: "change_smly", label: "% Change", width: 90 },
      ],
      rows: []
    };
  },
  components: { ExportExcel },
  props: ["title", "company", "identifier", "release_date"],
  created() {
    this.cols.forEach(() => this.subCols = this.subCols.concat(this.subColsFields));
    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      format:  "hash",
    }}).then(response => {
      this.rows = response.data.data;
      this.rows.forEach(row => {
        // Calculate relative values
        ["this_month", "last_month", "smly"].forEach(period => {
          row.avg_review_score[period] = Utils.round(row.avg_review_score[period], 2);
          ["positive", "negative"].forEach(polarity => {
            row[polarity][period] = Utils.round(100*row[polarity][period] / row.reviews[period], 2);
            if (row[polarity][period] != null && !isNaN(row[polarity][period])) row[polarity][period] = row[polarity][period] + "%";
            else row[polarity][period] = "-";
          });
        });

        // Calculate changes
        ["avg_review_score", "reviews", "positive", "negative"].forEach(field => {
          if (!row[field]["smly"])
            row[field]["change_smly"] = null;
          else if (/avg_review_score|reviews/.test(field))
            row[field]["change_smly"] = Utils.round(100*(parseFloat(row[field]["this_month"]) - parseFloat(row[field]["smly"]))/parseFloat(row[field]["smly"]), 2);
          else
            row[field]["change_smly"] = Utils.round(parseFloat(row[field]["this_month"]) - parseFloat(row[field]["smly"]), 2);

          if (!row[field]["change_smly"] && row[field]["change_smly"] != 0) row[field]["change_smly"] = null;
          if (row[field]["change_smly"] == "-") row[field]["change_smly"] = null;
          if (field == "negative") row[field]["change_smly"] = -1*row[field]["change_smly"];
        });
      });
    });
  },
  methods: {
    compareTrend(value) {
      return Number.parseFloat(value) >= 0;
    },
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th style="width: 350px;"></th>
            <th v-for="col in cols" :key="col.label" :colspan="col.colspan"> {{ col.label }} </th>
          </tr>
          <tr>
            <th class="property-name-column">{{ subCols[0].label }}</th>
            <th v-for="(subCol, index) in subCols.slice(1)" :key="subCol.field + index" :style="`width: ${subCol.width}px;`">{{ subCol.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, indexRow) in rows" :key="String(row.length) + indexRow">
            <td class="property-name-column">{{ row.name }}</td>
            <template v-for="col in cols">
              <template v-for="subCol in subColsFields" :key="col.label + subCol.field">
                <td>
                  <div :class="{ 'd-flex align-items-center justify-content-around': subCol.field == 'change' }">
                    <template v-if="subCol.field == 'change_smly' && row[col.field][subCol.field] != null">
                      <i class="arrow-trend" :class="{ 'mdi mdi-arrow-up text-success': compareTrend(row[col.field][subCol.field]), 'mdi mdi-arrow-down text-danger': !compareTrend(row[col.field][subCol.field]) }"></i>
                      <span>{{ row[col.field][subCol.field] + "%" }}</span>
                    </template>

                    <template v-else-if="!row[col.field][subCol.field] && row[col.field][subCol.field] != 0">
                      <span>-</span>
                    </template>

                    <template v-else>
                      <span>{{ row[col.field][subCol.field] }}</span>
                    </template>
                  </div>
                </td>
              </template>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
