<template>
  <div v-if="stripeLoaded" style="padding: 10px; margin: 50px 0;">
    <StripeElements v-slot="{ elements }" ref="elms" :stripe-key="stripeKey" :instance-options="instanceOptions"
        :elements-options="elementsOptions">
        <form @submit.prevent="handleSubmit" style="display: flex; flex-direction: column; gap: 15px; width: 100%; max-width: 400px;">
          <div class="row" style="display: flex; align-items: center; gap: 10px;">
            <label style="min-width: 120px;">
              {{ $t('signup.signup_to_paid.credit_card') }}
            </label>
            <StripeElement
              ref="card"
              :elements="elements"
              :options="{ showIcon: true }"
              type="cardNumber"
              style="flex-grow: 1;"
            />
          </div>
          <div class="row" style="display: flex; align-items: center; gap: 10px;">
            <label style="min-width: 120px;">
              {{ $t('signup.signup_to_paid.exp_date') }}
            </label>
            <StripeElement
              :elements="elements"
              :options="cardOptions"
              type="cardExpiry"
              style="flex-grow: 1;"
            />
          </div>
          <div class="row" style="display: flex; align-items: center; gap: 10px;">
            <label style="min-width: 120px;">
              {{ $t('signup.signup_to_paid.cvc') }}
            </label>
            <StripeElement
              :elements="elements"
              :options="cardOptions"
              type="cardCvc"
              style="flex-grow: 1;"
            />
          </div>
        </form>
      </StripeElements>
    <div v-if="errors.length > 0" style="margin: 10px 0;">
      <BAlert :model-value="true" variant="danger">
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </BAlert>
    </div>
  </div>
  <div v-else>
    {{ $t('signup.signup_to_paid.loading_stripe') }}
  </div>
</template>

<script>
import { StripeElements, StripeElement } from 'vue-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ref, onBeforeMount, defineComponent } from 'vue';

export default defineComponent({
  name: 'PaymentMethod',
  components: {
    StripeElements,
    StripeElement,
  },
  props: {
    handlePaymentMethodSaved: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const stripeKey = ref(process.env.VUE_APP_STRIPE_KEY);
    const instanceOptions = ref({});
    const elementsOptions = ref({});
    const cardOptions = ref({});
    const stripeLoaded = ref(false);
    const card = ref();
    const elms = ref();
    const errors = ref([]);

    onBeforeMount(() => {
      loadStripe(stripeKey.value).then((stripe) => {
        instanceOptions.value = { stripe };
        elementsOptions.value = { locale: 'en' };
        cardOptions.value = { style: { base: { fontSize: '16px' } } };
        stripeLoaded.value = true;
      });
    });

    const handleSubmit = async () => {
      try {
        const cardElement = card.value.stripeElement;
        const cardData = await elms.value.instance.createToken(cardElement);
        if (cardData.error) {
          errors.value = [cardData.error.message];
          return;
        }
        props.handlePaymentMethodSaved(cardData); 
      } catch (error) {
        console.error("Error in handleSubmit:", error);
      }
    };


    return {
      stripeKey,
      instanceOptions,
      elementsOptions,
      cardOptions,
      stripeLoaded,
      card,
      elms,
      errors,
      handleSubmit,
    };
  },
});
</script>
