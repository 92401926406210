import axios from "axios";

export const state   = { users: {}, usersPromise: {}, extensionSettings: null, extensionSettingsPromise: null };
export const getters = {
  users: (state) => (contract_id) => {
    return state.users[contract_id]
  },
  extensionSettings: (state) => state.extensionSettings
};

export const mutations = {
  saveUsers(state, { users, params }) {
    let contract_id = params.contract_id;
    if (state.users[contract_id]?.length) {
      state.users[contract_id] = [...state.users[contract_id], ...users];
      state.users[contract_id] = [...new Map(state.users[contract_id].map(u => [u.id, u])).values()];
    } else {
      state.users[contract_id] = users;
    }
  },
  saveUsersPromise(state, { promise, params }) {
    let contract_id = params.contract_id;
    state.usersPromise[contract_id] = promise;
  },
  saveExtensionSettings(state, { data }) {
    state.extensionSettings = { ...(state.extensionSettings || {}), ...(data || {}) };
  },
  saveExtensionSettingsPromise(state, { promise }) {
    state.extensionSettingsPromise = promise;
  }
};

export const actions = {
  async fetch({ commit }, params ) {
    if (state.users[params.contract_id]) return state.users[params.contract_id];

    let promise = state.usersPromise[params.contract_id];
    if (promise) return promise;

    let url = `/v3/contracts/${params.contract_id}/users`;
    promise = axios.get(url).then(response => {
      const users = response.data.data.sort((a, b) => a.full_name.localeCompare(b.name, undefined, { sensitivity: "base" }));
      commit("saveUsers", { users, params });
      return users;
    });

    commit("saveUsersPromise", { promise, params });
    return promise;
  },
  async extension_settings({ commit }) {
    if (state.extensionSettings)        return state.extensionSettings;
    if (state.extensionSettingsPromise) return state.extensionSettingsPromise;

    let promise = axios.get("/v3/users/extension_settings").then(response => {
      commit("saveExtensionSettings", { data: response.data.data });
      return response.data.data;
    })

    commit("saveExtensionSettingsPromise", { promise });
    return promise;
  }
}
