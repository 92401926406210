<script>
import DatePicker from "vue-datepicker-next";

export default {
  props: ["question", "settings", "editing"],
  components: {
    DatePicker,
  },
  data () {
    return {
      answer: '',
    }
  },
  methods: {
    notAfterToday(date) {
      if (this.question.type != 'past_date') return false
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date.getTime() > today.getTime();
    },
  },
};
</script>

<template>
  <div class="date-picker w-75 align-self-center">
    <date-picker class="mb-4" v-model:value="answer" append-to-body @change='$emit("answerChanged", answer)' :disabled-date='notAfterToday' :disabled="editing"></date-picker>
  </div>
</template>
