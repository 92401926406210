<script>
import ratings   from '@/js/ratings'
import Reports   from "@/js/reports";
import NotLoaded from "@/components/not-loaded";
import Loading   from "@/components/loading";

export default {
  data() {
    return {
      loading: true,
      error: false,
      tableData: [],
      ratings,
      reports: Reports,
    }
  },
  props: ['group_id', 'start_date', 'end_date', 'settings', 'group_ids'],
  components: { NotLoaded, Loading },
  methods: {
    ...Reports.requests,
    async load() {
      try {
        this.loading = true
        this.error = false
        if (!this.settings.use_custom_benchmark)
          this.settings.bench_group_ids = this.group_ids.slice(this.group_ids.length - 2)
        const response = await this.getReports('top_segments_benchmark')
        this.tableData = response?.slice(1, this.settings.limit + 1) || []
        let groups = this.tableData.flatMap(g => g[6].map(k => ({ id: k.id, name: k.name })))
        groups = [...new Map(groups.map(g => [g.id, g])).values()]

        const others = this.tableData.reduce((res, curr) => {
          res[4] = res[4] - curr[4]
          groups.forEach((g, i) => {
            res[6][i] ??= { ...g }
            const d = curr[6].find(c => c.id == g.id) || {}
            res[6][i] = {
              ...res[6][i],
              reviews_dist: (res[6][i].reviews_dist || 1) - (d.reviews_dist || 0)
            }
          })
          return res
        }, [this.$t("general.others"), "others", "-", "-", 1, "-", []])
        this.tableData.push(others)
      } catch {
        this.loading = false
        this.error = true
      }
    }
  },
  async mounted() {
    this.load()
  }
}
</script>
<template>
  <div>
    <table class="table table-bordered table-sm" v-if="tableData && tableData.length">
      <thead>
        <tr class="text-center">
          <th class="text-left">{{ $t(`reports.settings_modal.segment_options.${this.settings.segment}`) }}</th>
          <th>{{ settings.labels?.review_distribution ||  $t('reports.table.review_distribution') }}</th>
          <th>{{ settings.labels?.year_over_year ||  $t('reports.table.year_over_year') }}</th>
          <template v-for="benchName in reports.uniqueBenchmarkNames(tableData)" :key="benchName">
            <th>{{ benchName }}</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="row in tableData" :key="row[0]">
          <td class="text-left">{{ translateSegment(settings.segment, row[1], row[0]) }}</td>
          <td>{{ reports.percentFormat(row[4]) }}</td>
          <td :style="{ color: row[2] - row[5].reviews < 0 ? 'red' : 'green' }">
            {{ reports.percentDelta(row[2], row[5].reviews) }}
          </td>
          <template v-for="benchName in reports.uniqueBenchmarkNames(tableData)" :key="benchName">
            <td v-if="row[6].find(bench => bench.name === benchName)">
              {{ ratings.format(row[6].find(bench => bench.name === benchName).reviews_dist * 100) }} %
            </td>
            <td v-else>-</td>
          </template>
        </tr>
      </tbody>
    </table>

    <Loading v-else-if="loading" />
    <NotLoaded :data="tableData" :error="error" v-else-if="!loading" />
  </div>
</template>
