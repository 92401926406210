<script>
import axios from "axios";
import Stat from "@/components/widgets/stat";
import Utils from "@/js/utils";

export default {
  components: {
    Stat
  },
  data() {
    return {
      data: {
        current:  { total: 0, click_rate: 0, open_rate: 0, completed_rate: 0 },
        previous: { total: 0, click_rate: 0, open_rate: 0, completed_rate: 0 }
      },
      loading: false,
    }
  },
  props: ["cols", "filterParams"],
  computed: {
    statData() {
      let res = {};
      this.cols.forEach(col => {
        res[col] = {
          title: this.$t(`kpis.response_rate.${col}`),
          icon:  "ion ion-md-pulse",
          current: this.current(this.data.current[col], col),
          change:  this.change(this.data.current[col], this.data.previous[col])
        };
      });
      return res;
    }
  },
  watch: {
    filterParams: {
      handler: function () {
        if (this.filterParams.data) this.loadListener(this.filterParams);
      },
      immediate: true
    }
  },
  methods: {
    loadListener (params) {
      let p = Object.assign({headers: this.cols}, params.data);
      this.load(p);
    },
    async load (params) {
      Utils.setLoading.bind(this)(true);
      let response = await axios.get("/v3/feedback/responses/rate", { params: {
        start_date:      params.start_date,
        end_date:        params.end_date,
        subscription_id: params.subscription_ids,
        finished:        params.finished,
        company_ids:     params.company_ids,
        survey_id:       params.survey_id,
        has_sentiment:   params.has_sentiment,
        language:      params.response_language,
        question_type: params.question_type
      }});
      this.data = response.data.data;
      Utils.setLoading.bind(this)(false);
    },
    current(value, col) {
      if (value != null && col.includes("rate")) return Utils.round(value * 100);
      return value;
    },
    change (current, previous) {
      if (!current || !previous) return;
      return Utils.round((current / previous - 1) * 100);
    },
    changedStatus (change) {
      if (change == null) return ''
      if (change > 1) return 'success';
      if (change < 1) return 'danger'
      return 'text-muted'
    },
    valueSymbol(k) {
      if (k != "total") return "%";
    }
  }
};
</script>

<template>
  <div class="row" v-if="statData.total.current">
    <h5>{{ $t("feedback_analytics.mail_deliveries") }}</h5>
    <div v-for="k in cols" :key="k" class='col-lg-3 col-sm-6' :class="{'offset-lg-2': cols.length == 2}">
      <Stat
        :icon="statData[k].icon"
        :title="statData[k].title"
        :value="statData[k].current"
        :valueSymbol="valueSymbol(k)"
        :change="statData[k].change"
        :color="changedStatus(statData[k].change)"
        :class='{"loading": loading}'
      />
    </div>
  </div>
</template>
