<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      loading: false,
      cols: [
        { field: "segment",        label: "Source"         },
        { field: "reviews",        label: "Reviews"        },
        { field: "average_rating", label: "Average Rating" }
      ],
      tableData: [],
    };
  },
  components: { ExportExcel },
  props: ['title', 'company', 'identifier', 'release_date', 'source', 'wtd', 'mtd', 'last_30d'],
  created() {
    axios.get('/v3/query/db/_', {params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      segment:      'source',
      months_ago:   1,
      period:       'merged',
      compset:      false,
      wtd:          this.wtd,
      mtd:          this.mtd,
      last_30d:     this.last_30d,
      format:       'hash',
    }}).then(response => {
      this.tableData = response.data.data[0]?.segments || [];
      this.tableData.sort((a, b) => {
        if (a.reviews < b.reviews) return  1;
        if (a.reviews > b.reviews) return -1;
        return 0;
      });
    })
  },
  methods: {
    tdClass(value) {
      return "gray-" + Utils.ratings.toCss100(value);
    }
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
        <table border="1" class="table table-bordered table-nowrap mb-0 text-center">
          <thead>
            <tr>
              <th v-for="col in cols" :key="col.label" class="align-left"> {{ col.label }} </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, indexRow) in tableData" :key="JSON.stringify(row) + indexRow">
              <td class="align-left" style="width: 250px">
                {{ row["segment"] }}
              </td>
              <td>
                <div class="guest-data-share-grid">
                  <span class="px-0" style="text-align: right;">
                    {{`${row["reviews"]} (${row["share"]}%)`}}
                  </span>
                  <div class="progress me-">
                    <div class="progress-bar bg-replab" :style="`width: ${row.share}%`"></div>
                  </div>
                </div>
              </td>
              <td :class="tdClass(20*row['average_rating'])" style="width: 250px">
                {{ row["average_rating"] }}
              </td>
            </tr>
          </tbody>
        </table>
    </ExportExcel>
  </div>
</template>
