// language => country
export default {
  "ar": "ar-lang",
  "ca": "CT",
  "ab": "GE",
  "am": "ET",
  "an": "ES",
  "en": "GB",
  "as": "IN",
  "av": "RU",
  "da": "DK",
  "ae": "IR",
  "aa": "ET",
  "ak": "GH",
  "ay": "BO",
  "mi": "NZ",
  "oc": "FR",
  "qu": "PE",
  "rm": "CH",
  "sg": "CF",
  "sa": "IN",
  "ts": "ZA",
  "tn": "BW",
  "ve": "ZA",
  "wo": "SN",
  "za": "CN",
  "bi": "VU",
  "br": "FR",
  "ny": "MW",
  "co": "FR",
  "lg": "UG",
  "gn": "PY",
  "ik": "US",
  "kl": "GL",
  "rn": "BI",
  "ln": "CG",
  "gv": "GB",
  "na": "NR",
  "bm": "ML",
  "bh": "IN",
  "my": "MM",
  "ch": "GU",
  "cv": "RU",
  "kw": "GB",
  "cr": "CA",
  "dv": "MV",
  "dz": "BT",
  "ee": "GH",
  "ff": "SN",
  "ha": "NG",
  "hz": "NA",
  "ho": "PG",
  "ig": "NG",
  "iu": "CA",
  "kr": "NG",
  "ks": "IN",
  "km": "KH",
  "ki": "KE",
  "kv": "RU",
  "kg": "CD",
  "ku": "TR",
  "kj": "NA",
  "lld": "IT",
  "lo": "LA",
  "lu": "CD",
  "nv": "US",
  "nd": "ZW",
  "ng": "NA",
  "ii": "CN",
  "nr": "ZA",
  "oj": "CA",
  "cu": "RU",
  "or": "IN",
  "os": "GE",
  "pi": "IN",
  "sc": "IT",
  "sd": "PK",
  "sm": "WS",
  "gd": "GB",
  "sn": "ZW",
  "st": "LS",
  "bn": "BD",
  "hi": "IN",
  "ka": "GE",
  "eu": "ES",
  "ga": "IE",
  "gl": "ES",
  "gu": "IN",
  "kk": "KZ",
  "ky": "KG",
  "ko": "KR",
  "la": "VA",
  "pa": "IN",
  "ne": "NP",
  "nn": "NO",
  "ps": "AF",
  "si": "LK",
  "sq": "AL",
  "sr": "RS",
  "su": "ID",
  "sw": "TZ",
  "ti": "ET",
  "bo": "CN",
  "tk": "TM",
  "tw": "GH",
  "ty": "PF",
  "ug": "CN",
  "wa": "BE",
  "xh": "ZA",
  "yo": "NG",
  "af": "ZA",
  "ba": "RU",
  "be": "BY",
  "bs": "BA",
  "cs": "CZ",
  "ce": "RU",
  "cy": "GB",
  "et": "EE",
  "fy": "NL",
  "hy": "AM",
  "jv": "ID",
  "ja": "JP",
  "kn": "IN",
  "li": "NL",
  "lb": "LU",
  "ml": "IN",
  "mr": "IN",
  "ms": "MY",
  "zh": "CN",
  "nb": "NO",
  "fa": "IR",
  "he": "IL",
  "ta": "IN",
  "tt": "RU",
  "te": "IN",
  "tg": "TJ",
  "el": "GR",
  "sl": "SI",
  "tl": "PH",
  "uk": "UA",
  "ur": "PK",
  "vi": "VN",
  "zu": "ZA"
}