<script>
import DatePicker  from "vue-datepicker-next";
import Multiselect from "vue-multiselect";
import Swal        from "sweetalert2";

import Utils       from "@/js/utils";
import sources     from "@/data/sources";
import composition from "@/data/composition";
import countries   from "@/data/countries";
import locales     from "@/data/locales";
import DataUtils   from "@/js/data-utils";

import axios from 'axios'
import dayjs from 'dayjs'

/**
 * Advanced-form component
 */
export default {
  components: {
    DatePicker,
    Multiselect,
  },
  data() {
    return {
      dateShortcuts: Utils.dateShortcuts.call(this),
      arrayFields: { category: 'categories', regions: 'regions' },
      customCols: ['group', 'daterange'],
      groups: [],
      groupsCompanies: {},
      selectedGroupsCompanies: [],
      customerFilters: [],
      user: {},
      company: null,
      companies: [],
      companiesHash: {},
      companiesLimit: 500,
      locationConstraints: [],
      loadingFilterLocations: {},
      surveys: [],
      surveysList: [],
      survey_page: 1,
      surveyStatus: ['active', 'inactive', 'all'],
      survey_status: 'active',
      benchmarkEnabled: false,
      benchmark_type: 'selfBenchmark',
      collapsed: false,
      nGroups: 5,
      contexts: { data: 'main', benchmark: 'benchmark' },
      context: null,
      allSources: {},
      contractUsers: [],
      reportTemplates: [],
      users: null,
      report_templates: [],
      period: "month",
      periods: null,
      periodsOptions: [ 'day', 'week', 'month', 'quarter', 'year' ],
      allTopics: [],
      ratingRangeOptions: ["", "perfect", "higher_than_75", "higher_than_60", "lower_than_60", "lower_than_30"],
      year:  null,
      month: null,
      countriesByContinent: [],
      user_id: null,
      translatePath: {
        country:     "countries",
        composition: "travel_composition",
        language:    "locales"
      },
      data: {
        search: '',
        compositions: [],
        daterange: Utils.defaultDateRange(),
        property_type: [],
        property_types: {},
        continent: [],
        country: [],
        regions: [],
        city: null,
        category: [],
        reviewer_country: null,
        language: null,
        tripadvisor_rating: null,
        rating_range: null,
        travel_composition: null,
        group0: null,
        group1: null,
        group2: null,
        group3: null,
        group4: null,
        customerFilter: {},
        locations: {},
        languages: [],
        allRegions: {},
        allCategories: [],
        allCities:  [],
        contractSources: [],
        source: [],
        sources: [],
        countries: [],
        rating_type: null,
        time_period: null,
        response_language: [],
        question_type: [],
        sentimentOptions: ["positive", "neutral", "negative"],
        has_sentiment: [],
        topics: [],
        responsesOptions: ["with", "without", "all"],
        with_responses: 'all',
        userTypes: ["user", "report_recipient"],
        user_type: undefined,
        publishOptions: ["published", "unpublished", "all"],
        publish: "all",
        finishedOptions: ["completed", "uncompleted", "all"],
        finished: "all",
        distributionTypesOptions: [],
        distribution_types: [],
        sourceTypeOptions: ["review", "response", "all"],
        source_type: "review",
        source_exclusion: false
      },
      benchmark: {
        compositions: [],
        property_type: [],
        property_types: {},
        continent: [],
        country: [],
        regions: [],
        customerFilter: {},
        city: null,
        category: [],
        reviewer_country: null,
        language: null,
        tripadvisor_rating: null,
        rating_range: null,
        travel_composition: null,
        locations: {},
        languages: [],
        allRegions: {},
        allCategories: [],
        allCities:  [],
        contractSources: [],
        sources: [],
        countries: [],
        source_exclusion: false
      },
      allCompetitors: [],
      competitors: [],
      questionTypes: ["date", "drop_down", "enum", "multiple_select", "open_answer", "open_answer_long", "past_date", "rating", "trip_advisor_iframe", "yes_no", "nps"],
      currentPage: null,
      showTitleName: true,
      dataViewType: null,
      dataFormatType: null,
      areaType: null
    };
  },
  props: ["cols", "extraCols","showBenchmark", "notifyChange", "showCompetitors", "preventSubmit", "timePeriods", "initCollapsed", "hiddenTitle", "sidebar"],
  computed: {
    isCompanyOrGroupSelected() {
      if (!this.preventSubmit) return true
      return this.companies.length || this.selectedGroupsCompanies.length
    },
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    benchmarkContract() {
      return this.contract?.benchmark_contract;
    },
    ratings () {
      return [...Array(6).keys()]
    },
    benchmarkData () {
      return {
        'continentBenchmark': this.benchmark,
        'selfBenchmark':      this.data
      }[this.benchmark_type]
    },
    chosenBenchmark () {
     return {
       'continentBenchmark': this.benchmarkContract || {},
       'selfBenchmark':      this.contract
     }[this.benchmark_type]
    },
    blockName () {
     return {
       'continentBenchmark': 'benchmark',
       'selfBenchmark':      'data'
     }[this.benchmark_type]
    },
    surveysFilter() {
      let surveys = this.surveysList.filter(s => s.status == "active");
      let filterCompany = [];

      if (this.companies.length) filterCompany = this.companies;
      else if (this.company) filterCompany = [this.company].flat();

      if (filterCompany?.length > 0) surveys = filterCompany.map((c) => {
        return this.surveysList.filter(survey => survey.company_id == c.id || survey.company_ids.includes(c.id));
      }).flat().filter(Utils.uniq);

      return Utils.sortField(surveys, 'name');
    },
    companiesFilter() {
      if (!this.contract?.id || !this.companiesHash[this.contract?.id]) return [];
      let companies = this.companiesHash[this.contract.id];

      if (this.cols.includes('survey_filter') && this.surveys?.length) {
        companies = this.surveys.map(survey => {
          return (this.companiesHash[this.contract.id] || []).filter(company => survey.company_id == company.id || survey.company_ids.includes(company.id))
        }).flat().filter(Utils.uniq);
      }

      else if (this.context == "reputation" && this.selectedGroupsCompanies.length) {
        companies = this.selectedGroupsCompanies.flatMap(gc => gc.companies);
      }

      else if (this.currentPage == "destination_insights") {
        ["country", "regions", "city"].forEach((locationType) => {
          if (this.data[locationType]?.length) {
            companies = [];
            companies = (this.companiesHash[this.contract.id] || []).filter(c => {
              return this.data[locationType].filter(d => c[locationType]?.includes(d))?.length
            });
          }
        });
      }

      return Utils.uniqueFromSorted(Utils.sortField(companies, "name"), "id")
    },
    groupsCompaniesFilter() {
      if (!this.contract?.id || !this.groupsCompanies[this.contract?.id]) return []
      return this.groupsCompanies[this.contract.id]
    },
    enableCompanyFilter() {
      if (!this.currentPage) return false
      else if (this.currentPage == "destination_insights") {
        if (!this.companiesFilter.length) return false
        if (this.companiesFilter.length < this.companiesLimit) return true
        let isFilteredByLocation = this.locationConstraints.reduce((res, curr) => res + (this.data[curr]?.length || 0), 0);
        return isFilteredByLocation > 0
      }
      return true
    },
    competitorsFilter() {
      let cids = [];
      if (this.companies.length) cids = this.companies.map(c => c.id);
      else if (this.selectedGroupsCompanies.length) cids = this.selectedGroupsCompanies.flatMap(gc => gc.companies.map(c => c.id));
      cids = [...new Set(cids)];
      return this.allCompetitors.filter(m => cids.includes(m.id));
    },
    continents() {
      let continents = { main: [], benchmark: [] }
      let filters = this.contract?.filters
      if (!filters || filters == {}) return continents
      ;['main', 'benchmark'].forEach(b => {
        if (filters[b]) {
          let f = filters[b][this.$route.name] || filters[b]['*']
          if (f && Array.isArray(f.continent))
            continents[b] = f.continent
        }
      })
      return continents
    },
    surveyLanguages() {
      let res = []
      let surveys = []
      if (this.surveys?.length > 0) surveys = this.surveys
      else surveys = this.surveysList
      surveys.forEach(s => {
        s.languages.forEach(l => {
          if (!res.includes(l.code)) res.push(l.code)
        })
      })
      return res || []
    },
    contractAndUser() {
      return Number(this.contract?.id) + Number(this.user?.id);
    },
    topicsFilter() {
      let propertyTypes = this.companies.map(c => c.property_type).concat(this.selectedGroupsCompanies.flatMap(gc => gc.companies.map(c => c.property_type)));
      propertyTypes = [...new Set(propertyTypes.filter(a => a))];

      // filter by property types
      return this.allTopics.reduce((res, curr) => {
        let p = curr.children.filter(t => t.property_types?.filter(pt => (propertyTypes.includes(pt) || !propertyTypes.length))?.length);
        if (p.length || !propertyTypes.length) res.push({ key: curr.key, label: curr.label, children: p.map(c => c.topic) });
        return res;
      }, []);
    },
    periodOptions() {
      return {
        year:  Utils.getLastYears(5),
        month: Utils.getMonths.call(this, this.year)
      }
    }
  },
  watch: {
    contractAndUser: async function (loaded) {
      if (loaded) this.loadFilter();
    },
    "$i18n.locale": function () {
      this.dateShortcuts = Utils.dateShortcuts.call(this);
    }
  },
  async created() {
    this.context     = this.$route.path.split("/")[1];
    this.currentPage = this.baseParams(true).data.page_context;
    this.user        = await this.$store.dispatch("user/fetch");
    this.collapsed   = this.initCollapsed || false;
    this.showTitleName = this.hiddenTitle ? false : true;
  },
  methods: {
    ...DataUtils,
    ...Utils.filter,
    async loadFilter() {
      ["country", "regions", "city"].forEach(c => {
        if (this.cols.includes(c)) this.locationConstraints.push(c);
      });
      let promises = [];

      if (["country", "regions", "city"].some(c => this.cols.includes(c))) promises.push(this.loadLocations());

      if (["property_type", "category", "reviewer_country", "language", "sources", "travel_composition"].some(c => this.cols.includes(c))) {
        promises.push(this.loadData());
        this.loadAllSources();
      }

      if (this.cols.includes('group') || this.cols.includes('group_companies')) promises.push(this.loadGroups());
      if (this.cols.includes('property_type')) promises.push(this.loadFilters());
      if ((this.cols.includes('company') || this.cols.includes('companies')) && this.currentPage != "destination_insights") promises.push(this.loadCompanies());
      if (this.cols.includes('survey_filter')) promises.push(this.loadSurveys());
      if (this.cols.includes('users')) promises.push(this.loadUsers());
      if (this.cols.includes('report_templates')) promises.push(this.loadReportTemplates());
      if (this.cols.includes('topics')) promises.push(this.loadTopics());
      if (this.cols.includes('distribution_types')) promises.push(this.loadDistributions());

      if (this.showCompetitors) promises.push(this.loadCompetitors());

      await Promise.all(promises.flat());

      await this.readQuery();
      this.submit(null, "filter:created");
    },
    loadUsers () {
      return this.$store.dispatch("users/fetch", { contract_id: this.contract.id }).then(users => this.contractUsers = users);
    },
    loadReportTemplates () {
      return this.$store.dispatch("reportTemplates/fetch", { contract_id: this.contract.id }).then(rp => this.reportTemplates = rp);
    },
    loadData() {
      var baseParams = this.baseParams(true)
      let promises = []

      Object.keys(baseParams).forEach(k => {
        let filterDataURL = '/v3/destination/filter_data'
        if (this.context == 'reputation') filterDataURL = '/v3/reputation/filter_data'
        promises.push(axios.get(filterDataURL, { params: { subscription_ids: this.contract.id, ...baseParams[k] } }).then(response => {
          ['compositions', 'languages', 'countries', 'property_types'].forEach(j => {
            this[k][j] = response.data.data[j] || []
          })

          this[k].allCategories   = Utils.uniqSorted(Object.values(this[k].property_types).map(p => p.categories).flat().filter(n => n))
          this[k].contractSources = Utils.uniqSorted(Object.values(this[k].property_types).map(p => p.sources).flat().filter(n => n))
        }))
      })
      return promises
    },
    loadLocations () {
      var baseParams = this.baseParams(true)
      let promises = []

      Object.keys(baseParams).forEach(k => {
        let locationsURL = '/v3/destination/filter_locations'
        if (this.context == 'reputation' || this.currentPage == "destination_insights") locationsURL = '/v3/reputation/filter_locations'
        this.loadingFilterLocations[k] = true
        promises.push(axios.get(locationsURL, { params: { subscription_ids: this.contract.id, ...baseParams[k] } }).then(response => {
          this[k].locations  = response.data.data

          this[k].allRegions = this.flattenObjects(Object.values(this[k].locations))
          this[k].allCities  = Utils.uniqSorted(Object.values(this[k].allRegions).flat()).filter(n => n)
          if (this.currentPage == "destination_insights") {
            this.companiesHash[this.contract.id] = Utils.uniqSorted(this.deepFlattenCompanies(this[k].locations), 'name')
          }
          this.loadingFilterLocations[k] = false;
        }))
      })

      if (this.context == "destination") {
        promises.push(axios.get("/v3/countries").then(response => {
          this.countriesByContinent = response.data.data.reduce((res, curr) => {
            curr.hds_continents.forEach(continent => {
              if (res[continent]) res[continent] = [...new Set([...res[continent], curr.name])]
              else res[continent] = [curr.name]
            })
            return res
          }, {})
        }))
      }

      return promises
    },
    loadGroups () {
      return this.$store.dispatch("groups/fetch", { contract_id: this.contract.id }).then(groups => {
        this.groups = groups;
        this.groupsCompanies[this.contract.id] = groups;
        let ids, query = this.readParams(this.$route.query)
        ;['data', 'benchmark'].forEach(b => {
          if (!query[`${b}.group_ids`]) return
          if (Array.isArray(query[`${b}.group_ids`])) {
            for(var i = 0; i < this.nGroups; i++) {
              if (query[`${b}.group_ids`][i]) {
                if (Array.isArray(query[`${b}.group_ids`][i]))
                  ids = query[`${b}.group_ids`][i]
                else
                  ids = query[`${b}.group_ids`][i].split(',').map(i => parseInt(i))
                this[b]['group'+i] = this.groups.filter(g => ids.includes(g.id))
              }
            }
          } else
            this[b]['group0'] = this.groups.filter(g => g.id == query['data.group_ids'])
        })
      });
    },
    loadFilters() {
      return axios.get(`/v3/contracts/${this.contract.id}/customer_filters`).then(res => this.customerFilters = res.data.data);
    },
    loadCompanies () {
      if (this.companiesHash[this.contract.id]) return this.companiesHash[this.contract.id];
      return this.$store.dispatch("companies/fetch", { contract_id: this.contract.id }).then(companies => {
        this.companiesHash[this.contract.id] = Utils.deepClone(companies);
        if (this.$route.query.cid) this.company = this.companiesHash[this.contract.id].find(c => c.id == this.$route.query.cid);
      });
    },
    loadSurveys() {
      return this.$store.dispatch("surveys/fetch", { contract_id: this.contract.id })
        .then(surveys => this.surveysList = Utils.sortField(surveys, 'name'))
        .catch(err => console.error(err))
    },
    loadAllSources() {
      // this is just for labeling
      this.allSources = Utils.uniqSorted(Object.values(sources), "friendly_name").reduce((hash, curr) => {
        if (!curr.contract_id || curr.contract_id == this.contract?.id) hash[curr.name] = curr.friendly_name
        return hash
      }, {})
    },
    loadCompetitors() {
      return axios.get('v3/companies', { params: { subscription_ids: this.contract.id ,with_competitors: true } }).then(res => {
        this.allCompetitors = res.data.data
        this.allCompetitors.forEach(company => company.competitors.forEach(c => c.competitor = { name: company.name, id: company.id }))
      })
    },
    loadTopics() {
      return this.$store.dispatch("topics/fetch").then(topics => this.allTopics = this.topicsByParent(topics));
    },
    loadDistributions() {
      return this.$store.dispatch("distributions/fetch").then(distributions => {
        const dt = distributions.filter(d => {
          return Object.keys(d.mail_template_ids).length && d.mail_templates.find(mt => mt.language == d.survey.default_locale)
        }).map(d => d.type || "general");
        this.data.distributionTypesOptions = [...new Set(dt)];
      });
    },
    clear() {
      ['data', 'benchmark'].forEach(b => {
        this.cols.forEach(c => this[b][c] = [])
        this.cols.forEach(c => this[b][c] = [])
        for(var i = 0; i < this.nGroups; i++) {
          this[b]['group'+i] = []
        }
        this[b].daterange = Utils.defaultDateRange()
      })
      this.company = null
      this.companies = []
      this.surveys = null
      this.survey_page = 1
      this.data.finished = 'all'
      this.data.rating_type = null
      this.data.time_period = null;
      this.data.page = 1
      this.survey_status = 'all'
      this.selectedGroupsCompanies = []
      this.competitors = []
      this.data.source_type = "all"
      this.submit(null, 'filter:created')
    },
    async deleteCache() {
      if (this.context == "feedback") return;
      if (this.currentPage == "destination_executive_kpis") this.$emit("filter:delete_cache");

      Utils.setLoading.bind(this)(true);
      let baseParams = this.baseParams(true), promises = [];

      const contexts = [ ... new Set(Object.values(baseParams).map( bp => bp.sub_context )) ]
      contexts.forEach(sc => {
        ["filter-data", "filter-locations"].forEach(f => {
          promises.push(axios.delete("v3/cache", { params: {
            component: `${f}-${this.context}`,
            params: {
              subscription_ids: this.contract.id,
              sub_context: sc
            }
          }}));
        });
      });

      await Promise.all(promises);
      Utils.setLoading.bind(this)(false);
      this.$router.go();
    },

    property_types_options(b) { return Utils.uniqSorted(Object.keys(this[b].property_types)).filter(n => n) },
    categories(b, p) {
      if (!this[p].property_type || !this[p].property_type?.length) return this[b].allCategories;
      let pt = [this[p].property_type].flat();
      let categories = pt.map(c => {
        if (this[b].property_types[c]) return this[b].property_types[c].categories;
      }).filter(c => c);
      if (categories.length) return Utils.uniqSorted(categories.flat());
      return [];
    },
    sources(b, p) {
      let pt = [this[p].property_type].flat()
      let sources = pt.map(c => {
        if (this[b].property_types[c]) return this[b].property_types[c].sources
      }).filter(c => c)
      if (sources.length) return Utils.uniqSorted(sources.flat())
      return this[b].contractSources
    },
    countries(b, p) {
      let countries = [...new Set(Object.keys(this[b].locations).filter(n => n))]
      if (!this[p].continent.length) return countries;
      return countries.filter(country => this[p].continent.find(continent => this.countriesByContinent[continent].includes(country)));
    },
    regions(b, p) {
      let regions = this[p].country.flatMap(c => this[b].locations[c]).filter(n => n)
      if (regions.length) return Utils.uniqSorted(Object.keys(this.flattenObjects(regions))).filter(n => n)
      return Utils.uniqSorted(Object.keys(this[b].allRegions)).filter(n => n)
    },
    cities(b, p) {
      if (!this[p].regions) this[p].regions = []
      let regions = this[p].regions.flatMap(r => this[b].allRegions[r]).filter(n => n)
      if (regions.length) return regions

      if (this[p].country.length) {
        let countries = this[p].country.map(c => this[b].locations[c]).filter(n => n)
        return Utils.uniqSorted(Object.values(this.flattenObjects(Object.values(countries))).flat()).filter(n => n)
      }
      return this[b].allCities
    },
    scoresLabel(value, type) {
      let key, path = this.translatePath[type], res = value;
      if (type == "country")     key = countries[value];
      if (type == "composition") key = composition[value];
      if (type == "language")    key = locales[value];
      if (path && key) res = this.$t(`${path}.${key}`);
      return res;
    },
    flattenObjects (obj) {
      if (!obj) return null
      return obj.reduce((result, current) => {
        if (!current) return current
        Object.keys(current).forEach(k => {
          let curr = current[k]
          if (!Array.isArray(curr)) curr = Object.keys(curr)
          if (result[k]) result[k] = result[k].concat(curr)
          else result[k] = curr
        })
        return result
      }, {})
    },
    deepFlattenCompanies(obj) {
      if (!obj) return res
      let locations = ["country", "regions", "city"]
      let res = []
      Object.entries(obj).forEach(([country, cRegions]) => {
        Object.entries(cRegions).forEach(([regions, cities]) => {
          Object.entries(cities).forEach(([city, companies]) => {
            if (typeof(companies) == "string") return
            companies.forEach(company => {
              let find = res.find(c => c.id == company.id);
              if (find) locations.forEach(l => find[l].push(eval(l)))
              else res.push({ ...company, country: [country], regions: [regions], city: [city] })
            })
          })
        })
      })
      return res;
    },

    collapseToggle () {
      this.collapsed = !this.collapsed
    },
    collapseClass () {
      if (this.collapsed) return 'collapse'
      return 'collapse.show'
    },
    baseParams (forceBenchmark) {
      let params = { data: {sub_context: 'main', page_context: this.$route.name} }
      if (this.showBenchmark || this.showCompetitors)
        params['benchmark'] = {sub_context: !forceBenchmark && this.benchmark_type == 'selfBenchmark' ?  'main' : 'benchmark', page_context: this.$route.name}
      return params
    },
    submit(ev, eventName) {
      if (!this.isCompanyOrGroupSelected) {
        this.alertSelection(eventName)
        return
      }
      eventName = eventName || 'filter:update'
      var p = this.baseParams()
      let blocks = ['data']
      if ((this.showBenchmark && this.benchmarkEnabled) || this.showCompetitors) blocks.push('benchmark')
      p['benchmarkEnabled'] = this.benchmarkEnabled
      if (this.company?.id) {
        p[blocks[0]]['company_id'] = this.company.id
      }
      if (this.companies.length) {
        p[blocks[0]]['company_ids'] = this.companies.map(c => c.id)
      }
      if (this.cols.includes('page')) p[blocks[0]]['page'] = this.survey_page || 1
      if (this.surveys) p[blocks[0]]['survey_id'] = this.surveys.map(el => el.id)
      if (this.report_templates.length) p["report_templates"] = this.report_templates.map(r => r.id)

      ;['users', 'period', 'periods', 'survey_status', 'benchmark_type', 'year', 'month'].forEach(k => {
        if (this[k]) p[k] = this[k]
      })
      if (this.selectedGroupsCompanies.length > 0) p[blocks[0]]['group_ids'] = this.selectedGroupsCompanies.map(g => g.id)

      if (this.showCompetitors && this.competitors.length) p['competitors'] = this.competitors.map(c => c.id)
      if (this.showCompetitors) this.emitCompanyNames()

      blocks.forEach(b => {
        if (this.period == 'day') this.data.daterange[0] = new Date(dayjs(this.data.daterange[1]).subtract(1, 'month'))
        p[b]['start_date'] = this.formatDate(this.data.daterange[0])
        p[b]['end_date']   = this.formatDate(this.data.daterange[1])
        // change it later, maybe separate the 2 type of group filters
        if (!this.cols.includes('group_companies') || b != 'data') {
          if (this[b]['group0']) p[b]['group_ids'] = []
          for(var i = 0; i < this.nGroups; i++) {
            if (this[b]['group'+i] && this[b]['group'+i].length > 0) {
              p[b]['group_ids'].push(this[b]['group'+i].map(g => g.id))
            }
          }
          if (p[b]['group_ids']) {
            p[b]['group_ids'].filter(n => n)
            if (!p[b]['group_ids'].length) delete p[b]['group_ids']
          }
        }

        p[b].subscription_ids = [this.contract?.id]

        this.cols.concat(this.extraCols || []).forEach(c => {
          let k = this.arrayFields[c] || c
          if (!this.customCols.includes(c) && this[b][c]) p[b][k] = this[b][c]
        })

        if (this[b].source_exclusion != undefined) p[b].source_exclusion = this[b].source_exclusion
      })
      this.updateQuery(p)

      blocks.forEach(b => {
        if (Object.keys(this[b].customerFilter).length) {
          if (!p[b]['group_ids']) p[b]['group_ids'] = []
          p[b]['group_ids'] = p[b]['group_ids'].concat(Object.values(this.customerFilterGroupIds(b)))
        }
        if (p[b]['group_ids']) p[b]['group_ids'] = p[b]['group_ids'].map(g => JSON.stringify(g))
        if (this[b].source_exclusion != undefined) {
          p[b].source_exclusion = this[b].source_exclusion
          if (String(this[b].source_exclusion) == 'true' && this[b].sources.length) {
            p[b]['exclude_sources'] = this[b].sources
            delete p[b]['sources']
          }
        }
      })

      if (this.cols.includes("group_companies")) {
        if (this.companies.length) {
          p['groups_companies'] = this.companies;
        } else {
          p['groups_companies'] = this.selectedGroupsCompanies.reduce((res, curr) => {
            return res.concat(curr.companies)
          }, []);
        }
        p['groups_companies'] = [...new Map(p['groups_companies'].map(gc => [gc.id, gc])).values()]

        if (this.selectedGroupsCompanies.length && this.companies.length) delete p["data"]["group_ids"];
      }
      if (this.showCompetitors && this.competitors.length) p['competitors'] = this.competitors
      this.$emit(eventName, p)
    },
    filterChanged() {
      if (!this.notifyChange) return;
      this.submit(null, 'filter:change')
    },
    updateQuery (params) {
      let query = {...this.$route.query}
      query = this.readParams(query)
      let cols  = this.cols.concat([...(this.extraCols || []), 'start_date', 'end_date', 'group_ids', 'subscription_ids', 'company_id', 'company_ids', 'survey_id', 'page'])
      ;['data', 'benchmark'].forEach(b => {
        if ( b == 'benchmark' && !this.showBenchmark && !this.showCompetitors) return;
        cols.forEach(c => {
          const key = this.arrayFields[c] || c
          let val   = params[b][key]
          if ((!val || val.length == 0)) {
            if (!this.customCols.includes(c)) delete query[`${b}.${key}`]
          } else
            query[`${b}.${key}`] = val
        })
        let filterGroupIds = this.customerFilterGroupIds(b)
        Object.keys(filterGroupIds).forEach(k => {
          query[`${b}.customerFilter.${k}`] = filterGroupIds[k]
        })
        if (params[b].source_exclusion != undefined) query[`${b}.source_exclusion`] = params[b].source_exclusion
      })
      ;['benchmarkEnabled', 'benchmark_type', 'survey_status', 'competitors', 'users', 'report_templates', 'period', 'periods', 'year', 'month'].forEach((c) => {
        if (params[c]) query[c] = params[c];
        else delete query[c]
      })

      if (this.context == "reputation") this.saveToLocalStorage()
      query = this.convertParamsToURLFormat(query);
      query = this.checkParams(query);
      this.$router.push({ path: this.$route.path, query: query }).catch(()=>{})
    },
    async readQuery() {
      let query = this.readParamsFromContract();
      query = this.checkParams(query);
      query = this.readParams(query);

      if (this.context == "reputation") this.loadFromLocalStorage(query);
      if (query.benchmarkEnabled != undefined) this.benchmarkEnabled = query.benchmarkEnabled;

      if (query['competitors']) {
        this.competitors = this.allCompetitors.flatMap(c => c.competitors).filter(c => query['competitors'].includes(String(c.id)))
        this.competitors = [...new Map(this.competitors.map(c => [c.id, c])).values()]
      }

      if (query['data.start_date'])  this.data.daterange[0] = dayjs(query['data.start_date']).toDate();
      if (query['data.end_date'])    this.data.daterange[1] = dayjs(query['data.end_date']).toDate();
      if (this.data.daterange == [] || !(dayjs(this.data.daterange[0]).isValid() && dayjs(this.data.daterange[1]).isValid()))
        this.data.daterange = Utils.defaultDateRange()

      if (query['data.company_id'] && this.cols.includes('company')) {
        this.company = (this.companiesHash[this.contract.id] || []).find(el => el.id == query['data.company_id'])
      }

      if(query['data.company_ids'] && this.cols.includes('companies')) await this.readCompanies(query['data.company_ids']);

      if(query['data.survey_id']) this.surveys = this.surveysList.filter(el => el.id == query['data.survey_id'])
      if(query['data.page']) this.survey_page = query['data.page']
      if(query['benchmark_type']) this.benchmark_type = query['benchmark_type']
      if (query['survey_status']) this.survey_status = query['survey_status']
      if (query['data.group_ids']) this.selectedGroupsCompanies = (this.groupsCompanies[this.contract.id] || []).filter(g => query['data.group_ids'].includes(String(g.id)))
      if (query['report_templates']) this.report_templates = this.reportTemplates.filter(r => query['report_templates'].includes(String(r.id)))

      ;['period', 'periods', 'year', 'month'].forEach(f => {
        if (query[f]) this[f] = query[f]
      })

      ;['data', 'benchmark'].forEach(b => {
        this.setCustomerFilterFromQuery(b)

        this.cols.forEach(function(c) {
          const key = this.arrayFields[c] || c
          if (!this.customCols.includes(c) && query[`${b}.${key}`]) {
            if (Array.isArray(query[`${b}.${key}`]))
              this[b][c] = query[`${b}.${key}`]
            else
              this[b][c] = [query[`${b}.${key}`]]
          }
        }.bind(this))

        if (query[`${b}.source_exclusion`] != undefined) this[b].source_exclusion = query[`${b}.source_exclusion`];
      })

      if (this.data.topics) {
        this.data.topics = this.data.topics.flatMap(topic => {
          let find = this.allTopics.find(t => t.key == topic);
          if (find) return find.children.map(c => c.topic);
          return topic;
        })
      }

      ['time_period', 'rating_type', 'search', 'rating_range'].forEach(field => {
        if (query[`data.${field}`])
          this.data[field] = Array.isArray(query[`data.${field}`]) ? query[`data.${field}`][0] : query[`data.${field}`]
      })

    },
    async readCompanies(companies) {
      const companiesList = Array.isArray(companies) ? companies : [companies];
      let promises = [];
      companiesList.forEach(companyId => {
        if (!this.companies.find(c => String(c.id) == companyId)) {
          let company = (this.companiesHash[this.contract.id] || []).find(c => String(c.id) == companyId);
          if (company) this.companies.push(company);
          else if(this.user.admin) {
            promises.push(axios.get(`/v3/companies/${companyId}`).then(res => {
              this.companies.push(res.data.data);
              this.companiesHash[this.contract.id].push(res.data.data);
            }));
          }
        }
      });
      return Promise.all(promises);
    },

    hasFilledGroups(index) {
      let groups = Array.from({ length: this.nGroups - index }, (v, i) => { return this.data['group'+(i+index-1)]}).filter( n => n && n.length > 0 )
      return groups.length > 0
    },
    loadFromLocalStorage(query) {
      if (!navigator.cookieEnabled) return
      if (this.cols.includes('companies') && !query['data.company_ids']) {
        let ids = JSON.parse(window.localStorage.getItem('company_ids')) || []
        if (Array.isArray(ids)) this.companies = this.companies.concat((this.companiesHash[this.contract.id] || []).filter(c => ids.includes(c.id)))
        else this.companies = (this.companiesHash[this.contract.id] || []).filter(el => el.id == ids).filter(a => a)
      }

      if (this.cols.includes('company') && !query['data.company_id']) {
        let id = JSON.parse(window.localStorage.getItem('company_id'))
        this.company = (this.companiesHash[this.contract.id] || []).find(el => el.id == id)
      }

      if (this.cols.includes('group_companies') && !query['data.group_ids']) {
        let ids = JSON.parse(window.localStorage.getItem('group_ids')) || []
        this.selectedGroupsCompanies = (this.groupsCompanies[this.contract.id] || []).filter(g => ids.includes(g.id))
      }
    },
    saveToLocalStorage() {
      if (!navigator.cookieEnabled) return
      if (this.companies) window.localStorage.setItem('company_ids', JSON.stringify(this.companies.map(c => c.id)))
      if (this.company) window.localStorage.setItem('company_id', JSON.stringify(this.company.id))
      if (this.selectedGroupsCompanies) window.localStorage.setItem('group_ids', JSON.stringify(this.selectedGroupsCompanies.map(g => g.id)))
    },
    formatDate(d) {
      return dayjs(d).format('YYYY-MM-DD')
    },
    customerFilterGroupIds(block) {
      let filterObjs = {}
      Object.keys(this[block].customerFilter).forEach(k => {
        // map group objects to ids
        let arr = this[block].customerFilter[k]?.map(g => g?.id)
        if (arr && arr.length) filterObjs[k] = arr
      })
      return filterObjs
    },
    setCustomerFilterFromQuery(block) {
      let filters, query = this.readParams(this.$route.query)
      this[block].customerFilter = {}
      this.customerFilters.forEach(f => {
        filters = query[`${block}.customerFilter.${f.id}`]
        if (filters) {
          if (typeof filters === 'string') filters = filters.split(',').map(i => parseInt(i))
          filters = filters.map(i => this.groups.find(g => g.id == parseInt(i))).filter(n => n)
          this[block].customerFilter[f.id] = filters
        }
      })
    },
    onTimePeriodChange (event) {
      this.data.time_period = event.target.getAttribute('value')
      this.filterChanged(event)
    },
    onRatingTypeChange (event) {
      this.data.rating_type = event.target.getAttribute('value')
      this.filterChanged(event)
    },
    emitCompanyNames() {
      let nameHash = [...this.allCompetitors.map(c => c.competitors).flat(), ...this.companiesHash[this.contract.id] || [], ...this.groupsCompanies[this.contract.id] || []].reduce((res, curr) => {
        res[curr.id] = curr.name
        return res
      }, {})
      this.$emit('companyNames', nameHash)
    },
    alertSelection(eventName) {
      if (eventName != 'filter:created') Swal.fire(this.$t('general.warning'), this.$t('general.select_group_or_company'), 'info');
    },
    periodLabel(value, period) {
      if (period == "year")  return value;
      if (period == "month") return this.$t(`months.${dayjs().month(value).format("MMMM")}`);
    },
    countryLabel(l) {
      if (this.context == 'destination') return this.scoresLabel(l, 'country')
      return this.$t(`filter.countries.${l}`)
    },
    languageLabel(l) {
      if (this.context == 'destination') return this.scoresLabel(l, 'language')
      return this.$t(`filter.languages.${l}`)
    }
  }
};
</script>

<template>
  <div class='filter'>
    <div class="row">
      <div class="col-lg-12">
        <div :class="{ 'card': !sidebar }">
          <div :class="{ 'px-0': sidebar, 'card-body': !sidebar }">
            <div class="float-end">
              <a v-if="user.admin" href="#" class="me-3" @click="deleteCache">{{ $t("general.delete_cache") }}</a>
              <button class='btn btn-outline-secondary btn-sm btn-collapse' @click='collapseToggle'>
                <i class="mdi mdi-18px" :class="{
                  'mdi-unfold-more-horizontal':  collapsed,
                  'mdi-unfold-less-horizontal': !collapsed
                }"></i>
              </button>
            </div>
            <h4 v-if="showTitleName" class="card-title">{{ $t("filter.title") }}</h4>
            <div :class='collapseClass()'>
              <div class="row mx-0 w-100">
                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2 daterange" v-if="cols.includes('daterange')">
                  <label class="form-label">{{ $t("filter.date_range") }}</label>
                  <date-picker v-model:value="data.daterange" :shortcuts="dateShortcuts" range append-to-body confirm :clearable="false" @change="filterChanged"></date-picker>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('group_companies')">
                  <div class="navbar-header shadow-none">
                    <label class="form-label">{{ $t("filter.groups") }}</label>
                    <span v-b-tooltip.hover :title="$t('filter.group_help')">
                      <i class='mdi mdi-help-circle'></i>
                    </span>
                  </div>
                  <multiselect :multiple="true" v-model="selectedGroupsCompanies" :options="groupsCompaniesFilter" :searchable='true' label="name" track-by="name" :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'></multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('companies')">
                  <label class="form-label">{{ $t("filter.companies") }}</label>
                  <multiselect :optionsLimit="companiesFilter.length" :multiple="true" v-model="companies" :options="companiesFilter" label="name" track-by="id" :showLabels=false :placeholder="$t('filter.select_option')" style='min-width: 200px' @select='filterChanged' @remove='filterChanged' ></multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('users')">
                  <label class="form-label">{{ $t("filter.users") }}</label>
                  <multiselect :multiple="true" v-model="users" :options="contractUsers" label="full_name" track-by="id" :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged' ></multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('report_templates')">
                  <label class="form-label">{{ $t("filter.report_templates") }}</label>
                  <multiselect :multiple="true" v-model="report_templates" :options="reportTemplates" label="name" track-by="id" :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged' ></multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('periods')">
                  <label class="form-label">{{ $t("filter.periods") }}</label>
                  <multiselect :multiple="true" v-model="periods" :options="periodsOptions" :custom-label="p => $t(`reports.periods.${p}`)" :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged' ></multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('period')">
                  <label class="form-label">{{ $t("filter.period") }}</label>
                  <multiselect :multiple="false" v-model="period" :options="periodsOptions" :custom-label="p => $t(`reports.periods.${p}`)" :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged' ></multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('survey_filter')">
                  <label class="form-label">{{ $t("filter.survey_filter") }}</label>
                  <multiselect :multiple="true" v-model="surveys" :options="surveysFilter" label="name" track-by="id" :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged' ></multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('distribution_types')">
                  <label class="form-label">{{ $t("filter.distribution_types") }}</label>
                  <multiselect :optionsLimit="data.distributionTypesOptions.length" :multiple="true" v-model="data.distribution_types" :options="data.distributionTypesOptions" :showLabels="false" :custom-label="l => l == 'general' ? $t('new_survey.share.general') : l" :placeholder="$t('filter.select_option')" style='min-width: 200px' @select='filterChanged' @remove='filterChanged' ></multiselect>
                </div>

                <!-- Added: filter by completed responses -->
                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('finished')">
                  <label class="form-label">{{ $t("filter.state") }}</label>
                  <multiselect :multiple="false" v-model="data.finished" :options="data.finishedOptions" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" :customLabel="s => $t(`filter.${s}`)"  allow-empty @select='filterChanged' @remove='filterChanged'>
                    <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                  </multiselect>
                </div>

                <!-- Response language -->
                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('response_language')">
                  <label class="form-label">{{ $t("filter.response_language") }}</label>
                  <multiselect :multiple="true" v-model="data.response_language" :options="surveyLanguages" :custom-label="l => locales(l).name" :showLabels="false" :placeholder="$t('filter.select_option')" allow-empty @select='filterChanged' @remove='filterChanged'></multiselect>
                </div>

                <!-- Question Type -->
                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('question_type')">
                  <label class="form-label">{{ $t("filter.question_type") }}</label>
                  <multiselect :multiple="true" v-model="data.question_type" :options="questionTypes" :custom-label="t => $t(`new_survey.questions.question_types.${t}`)" :showLabels="false" :placeholder="$t('filter.select_option')" allow-empty @select='filterChanged' @remove='filterChanged'></multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('property_type')">
                  <label class="form-label">{{$t("filter.vertical")}}</label>
                  <multiselect :multiple="true" v-model="data.property_type" :options="property_types_options('data')" :showLabels=false :placeholder="$t('filter.select_option')" style='min-width: 200px' @select='filterChanged' @remove='filterChanged'></multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('category')">
                  <div class="navbar-header shadow-none">
                    <label class="form-label">{{ $t("filter.category") }}</label>
                    <span v-b-tooltip.hover :title="$t('filter.category_help')">
                      <i class='mdi mdi-help-circle'></i>
                    </span>
                  </div>

                  <multiselect :multiple="true" v-model="data.category" :options="categories('data', 'data')" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" :optionsLimit="100" @select='filterChanged' @remove='filterChanged'>
                    <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                  </multiselect>
                </div>

                <div class="form-group mb-3 col-md-3 col-lg-2" v-if="cols.includes('continent') && continents.main.length > 1">
                  <label class="form-label">{{ $t("filter.continent") }}</label>
                  <multiselect :multiple="true" v-model="data.continent" :options="continents.main" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'></multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('country') && countries('data', 'data').length > 0">
                  <label class="form-label">{{ $t("filter.country") }}</label>
                  <multiselect :multiple="true" v-model="data.country" :options="countries('data', 'data')" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" :customLabel="l => scoresLabel(l, 'country')" @select='filterChanged' @remove='filterChanged'></multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('regions') && regions('data', 'data').length">
                  <label class="form-label">{{ $t("filter.region") }}</label>
                  <multiselect :multiple="true" v-model="data.regions" :options="regions('data', 'data')" :optionsLimit="100" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" style='min-width: 200px' @select='filterChanged' @remove='filterChanged'></multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('city') && cities('data', 'data').length">
                  <label class="form-label">{{ $t("filter.city") }}</label>
                  <multiselect :multiple="true" v-model="data.city" :options="cities('data', 'data')" :optionsLimit="100" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" style='min-width: 200px' @select='filterChanged' @remove='filterChanged'>
                  <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                  </multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('reviewer_country')">
                  <label class="form-label">{{ $t("filter.nationality") }}</label>
                  <multiselect :multiple="true" v-model="data.reviewer_country" :options="data.countries" :custom-label="countryLabel" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'>
                    <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                  </multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('company') && enableCompanyFilter">
                  <label class="form-label">{{ $t("filter.company") }}</label>
                  <multiselect :optionsLimit="companiesFilter.length" v-model="company" :options="companiesFilter" label="name" track-by="id" :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged' ></multiselect>
                </div>

                <!-- Executive KPIs: time period -->
                <div class="form-group mb-3 col-12 col-sm-6 col-lg-3 col-xl-2" style="min-width: 320px;" v-if="cols.includes('time_period')">
                  <div class="navbar-header shadow-none d-flex justify-content-start align-items-center mb-2">
                    <label class='m-0'>{{ $t("filter.time_period") }}</label>
                    <span class='ms-1' v-b-tooltip.hover :title="$t('executive_kpis.help.time_period')">
                      <i class='mdi mdi-help-circle'></i>
                    </span>
                  </div>

                  <div class="d-flex">
                    <label class="custom-label" v-for="tp in Object.keys(timePeriods)" :key="tp">
                      <input class="custom-input" type="radio" name="time_period" :id="tp" :value="tp" @click="onTimePeriodChange" :checked="(data.time_period == tp) || timePeriods[tp].default"/>
                      <span class="custom-span">{{ $t(`filter.${tp}`) }}</span>
                    </label>
                  </div>
                </div>

                <!-- year / month selector -->
                <template v-if="cols.includes('period_date') && data.time_period && timePeriods[data.time_period].options.length">
                  <div v-for="period in timePeriods[data.time_period].options" :key="period" class="form-group mb-3 col-sm-6 col-md-3 col-lg-2">
                    <label class="form-label">{{ $t(`general.${period}`) }}</label>
                    <multiselect :multiple="false" v-model="$data[period]" :options="periodOptions[period]" :searchable="true" :showLabels="false" :placeholder="$t('filter.select_option')" @select="filterChanged" @remove="filterChanged" :custom-label="p => periodLabel(p, period)">
                      <template v-slot:noOptions>{{ $t("filter.no_options") }}</template>
                    </multiselect>
                  </div>
                </template>

                <!-- Executive KPIs: rating type -->
                <div class="form-group mb-3 col-12 col-sm-6 col-lg-3 col-xl-2" style="min-width: 320px;" v-if="cols.includes('rating_type')">
                  <div class="navbar-header shadow-none d-flex justify-content-start align-items-center mb-2">
                    <label class='m-0'>{{ $t("filter.rating_type") }}</label>
                    <span class='ms-1' v-b-tooltip.hover :title="$t('executive_kpis.help.rating_type')">
                      <i class='mdi mdi-help-circle'></i>
                    </span>
                  </div>

                  <div class="d-flex">
                    <label class="custom-label">
                      <input class="custom-input" type="radio" name="rating_type" id="numerical" value="numerical" @click="onRatingTypeChange" :checked="data.rating_type ? (data.rating_type == 'numerical' ? true : false) : false"/>
                      <span class="custom-span">{{ $t('filter.numerical') }}</span>
                    </label>

                    <label class="custom-label">
                      <input class="custom-input" type="radio" name="rating_type" id="tes" value="tes" @click="onRatingTypeChange" :checked="data.rating_type ? (data.rating_type == 'tes' ? true : false) : true"/>
                      <span class="custom-span" :title="$t(`executive_kpis.tes`)">{{ $t('filter.tes') }}</span>
                    </label>

                    <label class="custom-label">
                      <input class="custom-input" type="radio" name="rating_type" id="sentiment" value="sentiment" @click="onRatingTypeChange" :checked="data.rating_type ? (data.rating_type == 'sentiment' ? true : false) : false"/>
                      <span class="custom-span">{{ $t('filter.sentiment') }}</span>
                    </label>
                  </div>
                </div>

                <!-- filter surveys by status -->
                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('survey_status')">
                  <label class="form-label">{{ $t("filter.survey_status") }}</label>
                  <multiselect v-model="survey_status" :options="surveyStatus" :custom-label="a => $t(`filter.${a}`)" :showLabels="false" :placeholder="$t('filter.select_option')" :allow-empty="false" @select='filterChanged' @remove='filterChanged'></multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('user_type')">
                  <label class="form-label">{{ $t("filter.user_type") }}</label>
                  <multiselect v-model="data.user_type" :options="data.userTypes" :multiple="true" :custom-label="a => $t(`filter.${a}`)" :showLabels="false" :placeholder="$t('filter.select_option')" :allow-empty="true" @select='filterChanged' @remove='filterChanged'></multiselect>
                </div>

                <!-- Search filter... -->
                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('search')">
                  <label class="form-label">{{ $t("general.search") }}</label>
                  <div class="d-lg-block position-relative">
                    <input type="text" class="form-control" :placeholder="$t('general.search')" v-model='data.search' @input="filterChanged" style="padding-left: 30px;"/>
                    <i class="inner-icon fa fa-search"></i>
                  </div>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('language')">
                  <label class="form-label">{{ $t("filter.language_title") }}</label>
                  <multiselect :multiple="true" v-model="data.language" :options="data.languages" :custom-label="languageLabel" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'>
                    <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                  </multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('tripadvisor_rating')">
                  <label class="form-label">{{ $t("filter.rating") }}</label>
                  <multiselect :multiple="true" v-model="data.tripadvisor_rating" :options="ratings" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'>
                    <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                  </multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('rating_range')">
                  <label class="form-label">{{ $t("filter.rating") }}</label>
                  <multiselect :multiple="false" v-model="data.rating_range" :options="ratingRangeOptions" :searchable='true' :showLabels="false" :custom-label="l => $t(`filter.${l}`)" :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'>
                    <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                  </multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('sources')">
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="form-label">{{ $t("filter.sources") }}</label>
                    <div class="form-check" v-if="context == 'reputation'">
                      <input class="form-check-input" type="checkbox" v-model="data.source_exclusion" id="data.source_exclusion" @change='filterChanged'>
                      <label class="form-label fw-light font-size-12" for="data.source_exclusion">
                        {{ $t("general.exclusion") }}
                      </label>
                    </div>
                  </div>
                  <multiselect :multiple="true" v-model="data.sources" :options="sources('data', 'data')" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" :customLabel="s => allSources[s] || s" @select='filterChanged' @remove='filterChanged'>
                    <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                  </multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('source_type')">
                  <label class="form-label">{{ $t("filter.source_type") }}</label>
                  <multiselect :multiple="false" v-model="data.source_type" :options="data.sourceTypeOptions" :searchable="true" :showLabels="false" :placeholder="$t('filter.select_option')" :customLabel="s => $t(`filter.${s}`)" @select='filterChanged' @remove='filterChanged'>
                    <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                  </multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('has_sentiment')">
                  <div class="d-flex justify-content-between">
                    <label class="form-label">{{ $t("filter.sentiment") }}</label>
                    <span v-b-tooltip.hover :title="$t('filter.sentiment_help')">
                      <i class="mdi mdi-help-circle"></i>
                    </span>
                  </div>
                  <multiselect :multiple="true" v-model="data.has_sentiment" :options="data.sentimentOptions" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" :customLabel="s => $t(`filter.${s}`)" @select='filterChanged' @remove='filterChanged'>
                    <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                  </multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('topics')">
                  <label class="form-label">{{ $t("filter.mentioned_topics") }}</label>
                  <multiselect :multiple="true" v-model="data.topics" :options="topicsFilter" :searchable="true" group-values="children" group-label="label" :group-select="true" :showLabels="false" :placeholder="$t('filter.select_option')" :customLabel="s => $t(`ratings.rating_comparison.kpis.${s}`)" @select='filterChanged' @remove='filterChanged'>
                    <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                  </multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('with_responses')">
                  <label class="form-label">{{ $t("filter.responses") }}</label>
                  <multiselect :multiple="false" v-model="data.with_responses" :options="data.responsesOptions" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" :customLabel="s => $t(`filter.${s}`)" @select='filterChanged' @remove='filterChanged'>
                    <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                  </multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('publish')">
                  <label class="form-label">{{ $t("filter.publish") }}</label>
                  <multiselect :multiple="false" v-model="data.publish" :options="data.publishOptions" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" :customLabel="s => $t(`filter.${s}`)" @select='filterChanged' @remove='filterChanged'>
                    <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                  </multiselect>
                </div>

                <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('travel_composition')">
                  <label class="form-label">{{ $t("filter.travel_compositions") }}</label>
                  <multiselect :multiple="true" v-model="data.travel_composition" :options="data.compositions" :customLabel="s => $t(`travel_composition.${s}`)" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'></multiselect>
                </div>

                <template v-for='k,i in nGroups'>
                  <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('group') && (i == 0 || hasFilledGroups(i))" :key="'group'+i">
                    <div class="navbar-header shadow-none">
                      <label class="form-label">{{ $t("filter.groups") }}</label>
                      <span v-b-tooltip.hover :title="$t('filter.group_help')">
                        <i class='mdi mdi-help-circle'></i>
                      </span>
                    </div>
                    <multiselect :multiple="true" v-model="data['group'+i]" :options="groups" :searchable='true' label="name" track-by="name" :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'></multiselect>
                  </div>
                </template>

                <template v-for='filter in customerFilters'>
                  <div class="form-group mb-3 col-md-3 col-lg-3 col-xl-2" v-if="cols.includes('filters') && (filter.groups.length > 0)" :key="filter.id">
                    <div class="navbar-header shadow-none">
                      <label class="form-label">{{ filter.title }}</label>
                    </div>
                    <multiselect :multiple="true" v-model="data.customerFilter[filter.id]" :options="filter.groups" :searchable='true' label="name" track-by="name" :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'></multiselect>
                  </div>
                </template>

                <div class="form-group col-md-3 col-lg-2 align-self-center" v-if="showBenchmark">
                  <b-form-checkbox v-model="benchmarkEnabled" switch class="mb-3 pt-2" @change='filterChanged' id="enable-benchmark">
                    <label class="form-label" for="enable-benchmark">{{ $t("filter.enable_benchmark") }}</label>
                  </b-form-checkbox>
                </div>

                <div class="form-group mb-3 col-md-3 col-lg-4 col-xl-2" v-if="showBenchmark && benchmarkEnabled">
                  <label class="form-label">{{ $t("filter.benchmark_with") }}</label>
                  <div class="form-check">
                    <div>
                      <input class="form-check-input" type="radio" name="bench-with" value="selfBenchmark" v-model='benchmark_type' checked  @change='filterChanged' id="selfBenchmark"/>
                      <label class="form-check-label" for="selfBenchmark">{{ $t("filter.self_benchmark") }}</label>
                    </div>
                    <div class="form-check-right" v-if='benchmarkContract'>
                      <input class="form-check-input" type="radio" name="bench-with" v-model='benchmark_type' value="continentBenchmark" @change='filterChanged' id="continentBenchmark"/>
                      <label class="form-check-label" for="continentBenchmark">{{benchmarkContract.name}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group col-sm-6 col-md-3 col-lg-3 d-print-none" :class="sidebar && 'sticky-bottom m-0 bg-white pb-2 pt-3'" v-if="!showBenchmark && !showCompetitors && !notifyChange">
                  <template v-if="!sidebar">
                    <label class="form-label">&nbsp;</label><br>
                  </template>
                  <button v-if="sidebar" type="submit" class="btn btn-primary me-3 mb-2" @click='submit' data-bs-dismiss="offcanvas">{{ $t("filter.submit") }}</button>
                  <button v-else type="submit" class="btn btn-primary me-3 mb-2" @click='submit'>{{ $t("filter.submit") }}</button>
                  <button type="submit" class="btn btn-outline-primary mb-2" @click='clear'>{{ $t("filter.clear") }}</button>
                </div>
              </div>

              <!-- BENCHMARK AND COMPETITORS FIELDS -->
              <div class='benchmark-session mb-3' v-if='(showBenchmark && benchmarkEnabled) || showCompetitors'>
                <div class="row m-0">
                  <div class="form-group mb-3 col-md-3 col-lg-2" v-if="showBenchmark">
                    <h5>Benchmark ({{chosenBenchmark.name}})</h5>
                  </div>

                  <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="showCompetitors">
                    <div class="navbar-header shadow-none">
                      <label class="form-label">{{ $t("filter.competitors") }}</label>
                      <span v-b-tooltip.hover :title="$t('filter.competitors_help')">
                        <i class='mdi mdi-help-circle'></i>
                      </span>
                    </div>
                    <multiselect :multiple="true" :max="5" v-model="competitors" :options="competitorsFilter" group-values="competitors" group-label="name" :group-select="true" label="name" track-by="id" :showLabels="false" :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'></multiselect>
                  </div>

                  <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('property_type')">
                    <label class="form-label">{{ $t("filter.vertical") }}</label>
                    <multiselect :multiple="true" v-model="benchmark.property_type" :options="property_types_options(blockName)" :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'></multiselect>
                  </div>

                  <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('category') && benchmark.category">
                    <label class="form-label">{{ $t("filter.category") }}</label>
                    <multiselect :multiple="true" v-model="benchmark.category" :options="categories(blockName, 'benchmark')" :optionsLimit="100" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'>
                      <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                    </multiselect>
                  </div>

                  <div class="form-group mb-3 col-md-3 col-lg-2" v-if="cols.includes('continent') && continents[contexts[blockName]].length > 1">
                    <label class="form-label">{{ $t("filter.continent") }}</label>
                    <multiselect :multiple="true" v-model="benchmark.continent" :options="continents[contexts[blockName]]" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'></multiselect>
                  </div>

                  <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('country')">
                    <label class="form-label">{{ $t("filter.country") }}</label>
                    <multiselect :class="{ 'loading': loadingFilterLocations[blockName] }" :multiple="true" v-model="benchmark.country" :options="countries(blockName, 'benchmark')" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" :customLabel="l => scoresLabel(l, 'country')" @select='filterChanged' @remove='filterChanged'></multiselect>
                  </div>

                  <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('regions')">
                    <label class="form-label">{{ $t("filter.region") }}</label>
                    <multiselect :class="{ 'loading': loadingFilterLocations[blockName] }" :multiple="true" v-model="benchmark.regions" :options="regions(blockName, 'benchmark')" :optionsLimit="100" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'>
                      <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                    </multiselect>
                  </div>

                  <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('city')">
                    <label class="form-label">{{ $t("filter.city") }}</label>
                    <multiselect :class="{ 'loading': loadingFilterLocations[blockName] }" :optionsLimit="100" :multiple="true" v-model="benchmark.city" :options="cities(blockName, 'benchmark')" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'>
                    <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                    </multiselect>
                  </div>

                  <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('reviewer_country')">
                    <label class="form-label">{{ $t("filter.nationality") }}</label>
                    <multiselect :multiple="true" v-model="benchmark.reviewer_country" :options="benchmarkData.countries" :custom-label="countryLabel" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'>
                      <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                    </multiselect>
                  </div>

                  <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('language')">
                    <label class="form-label">{{ $t("filter.language_title") }}</label>
                    <multiselect :multiple="true" v-model="benchmark.language" :options="benchmarkData.languages" :custom-label="languageLabel"  :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'>
                      <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                    </multiselect>
                  </div>

                  <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('tripadvisor_rating')">
                    <label class="form-label">{{ $t("filter.rating") }}</label>
                    <multiselect :multiple="true" v-model="benchmark.tripadvisor_rating" :options="ratings" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'></multiselect>
                  </div>

                  <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('rating_range')">
                    <label class="form-label">{{ $t("filter.rating") }}</label>
                    <multiselect :multiple="false" v-model="benchmark.rating_range" :options="ratingRangeOptions" :searchable='true' :showLabels="false" :custom-label="l => $t(`filter.${l}`)" :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'>
                      <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                    </multiselect>
                  </div>

                  <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('sources')">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="form-label">{{ $t("filter.sources") }}</label>
                      <div class="form-check" v-if="context == 'reputation'">
                        <input class="form-check-input" type="checkbox" v-model="benchmark.source_exclusion" id="benchmark.source_exclusion">
                        <label class="form-label fw-light font-size-12" for="benchmark.source_exclusion">
                          {{ $t("general.exclusion") }}
                        </label>
                      </div>
                    </div>
                    <multiselect :multiple="true" v-model="benchmark.sources" :options="sources(blockName, 'benchmark')" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" :customLabel="s => allSources[s] || s" @select='filterChanged' @remove='filterChanged'>
                      <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                    </multiselect>
                  </div>

                  <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('travel_composition')">
                    <label class="form-label">{{ $t("filter.travel_compositions") }}</label>
                    <multiselect :multiple="true" v-model="benchmark.travel_composition" :customLabel="s => $t(`travel_composition.${s}`)" :options="benchmarkData.compositions" :searchable='true' :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'>
                      <template v-slot:noOptions>{{ $t('filter.no_options') }}</template>
                    </multiselect>
                  </div>

                  <template v-for='k,i in nGroups'>
                    <div class="form-group mb-3 col-sm-6 col-md-3 col-lg-2" v-if="cols.includes('group') && benchmark_type == 'selfBenchmark' && (i == 0 ||benchmark['group'+(i-1)] && benchmark['group'+(i-1)].length > 0)" :key="'group'+i">
                      <div class="navbar-header shadow-none">
                        <label class="form-label">{{ $t("filter.groups") }}</label>
                        <span v-b-tooltip.hover :title="$t('filter.group_help')">
                          <i class='mdi mdi-help-circle'></i>
                        </span>
                      </div>
                      <multiselect :multiple="true" v-model="benchmark['group'+i]" :options="groups" :searchable='true' label="name" track-by="name" :showLabels=false :placeholder="$t('filter.select_option')" @select='filterChanged' @remove='filterChanged'></multiselect>
                    </div>
                  </template>

                </div>
              </div>

              <div class="row m-0 d-print-none" v-if='(showBenchmark || showCompetitors) && !notifyChange'>
                <div class="form-group col-md-4">
                  <button type="submit" class="btn btn-primary" @click='submit'>{{ $t("filter.submit") }}</button>
                  <button type="submit" class="btn btn-outline-primary ms-3" @click='clear'>{{ $t("filter.clear") }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
