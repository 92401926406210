<script>
import Multiselect from "vue-multiselect";
import Utils from '@/js/form-utils';

export default {
  data() {
    return {
      answer: [],
      newOption: '',
      editOptions: false,
    }
  },
  props: ["question", "settings", "editing", "question_index"],
  components: {
    Multiselect,
  },
  methods: {
    isDefaultLanguage() {
      return this.settings.currentLanguage == this.settings.defaultLanguage;
    },
    answerChanged () {
      this.$emit('answerChanged', this.answer.id)
    },
    addOption() {
      if(this.newOption && this.newOption.length > 0) {
        let toPush = { id: Utils.createId(this.newOption, this.question.options), [this.settings.currentLanguage]: this.newOption };

        if (this.isDefaultLanguage()) toPush = {...toPush, default: this.newOption };

        this.question.options.push(toPush);

        this.newOption = ''
        this.$emit('changeInput')
      }
    },
    updateOption(option_id) {
      if (this.isDefaultLanguage()) {
        let index = this.question.options.findIndex(o => o.id == option_id)
        this.question.options[index].default = this.question.options[index][this.settings.currentLanguage]
        this.question.options[index].id = Utils.createId(this.question.options[index][this.settings.currentLanguage], this.question.options)
      }
      this.$emit('changeInput')
    },
    deleteOption(option_id) {
      this.question.options = this.question.options.filter(q => q.id != option_id)
      this.$emit('changeInput')
    },
    placeholder(option) {
      if (!option[this.settings.currentLanguage]) return this.$t("new_survey.questions.edit.needs_translation");
    },
    optionSublabel(option) {
      let res = { icon: null, label: null };
      if (!option[this.settings.defaultLanguage]) {
        res.label = option[Object.keys(option).filter(op => op != 'id')[0]];
        res.icon = Object.keys(option).filter(op => op != 'id')[0];
      } else {
        res.label = option[this.settings.defaultLanguage];
        res.icon = this.settings.defaultLanguage
      }
      return res;
    }
  },
  computed: {
    required () {
      return Utils.valid.required(this.question, this.answer)
    }
  }
};
</script>

<template>
  <div class="form-group dropdown-root w-100" :class="{ 'w-100': editing }">
    <div class="dropdown w-50 align-self-center" v-if="!editing">
      <multiselect
        :multiple="false"
        v-model="answer"
        :options="question.options"
        :customLabel="option => option[settings.currentLanguage] || option.default"
        :searchable="false"
        :showLabels="false"
        openDirection="bottom"
        label="text"
        track-by="id"
        @select="answerChanged"
        @remove="answerChanged"
        :allow-empty="false"
      >
      </multiselect>
    </div>

    <div class="w-100 d-flex flex-column align-items-center" v-else>
      <div v-if="!editOptions" class="d-flex w-75 mb-3 align-self-center align-items-center justify-content-between">
        <multiselect
          :multiple="false"
          v-model="question.answer"
          :options="question.options"
          :customLabel="option => option[settings.currentLanguage] || option.default"
          track-by="id"
          :searchable="false"
          :showLabels="false"
          openDirection="bottom"
        >
        </multiselect>
        <a href="javascript:void(0);" :title="$t('new_survey.questions.edit.edit_options')" class="ms-2" @click="editOptions = !editOptions"> <i class="font-size-24" :class="{ 'mdi mdi-playlist-edit': !editOptions, 'mdi mdi-check': editOptions }" /> </a>
      </div>

      <div v-if="editOptions" class="d-flex flex-column align-items-center w-100">
        <div class="row w-100">
          <div v-for="(option, index) in question.options" :key="index" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 d-flex flex-column mb-3">
            <div class="d-flex w-100 custom-control custom-control-inline custom-radio b-custom-control-lg me-0 px-0">
              <input :ref="question_index + '_option_' + option.id" class="custom-textarea font-size-14 font-weight-bold" :class="{ 'missing-translation': $parent.hasMissingTranslations(question_index, 'options', option.id) }" :placeholder="placeholder(option)" v-model="option[settings.currentLanguage]" @input="updateOption(option.id)"/>
              <a href="javascript:void(0);" :title="$t('new_survey.questions.edit.delete')" class="align-self-center ms-2 text-dark font-size-13" @click="deleteOption(option.id)"> <i class="ion ion-md-close" /> </a>
            </div>
            <div class="ps-2 d-flex align-items-center mt-1" v-if="!option[settings.currentLanguage]">
              <span class="flag-option-label" :class="`flag flag-${optionSublabel(option).icon}`"></span>
              <span class="font-size-12 ps-2 input-sublabel"> {{ optionSublabel(option).label }} </span>
            </div>
          </div>
        </div>
      </div>

      <!-- Add option field -->
      <div v-if="editing && editOptions && isDefaultLanguage()" class="d-flex custom-control custom-control-inline custom-checkbox b-custom-control-lg mt-3 mx-0 w-100">
        <input class="custom-textarea font-size-14 new-option ps-2" :placeholder="$t('new_survey.questions.edit.multiple_select_placeholder')" style="text-align: start;" v-model="newOption" />
        <a href="javascript:void(0);" class="align-self-center ms-4 btn btn-outline-primary" @click="addOption"> {{ $t("new_survey.questions.edit.add") }} </a>
      </div>

      <a href="javascript:void(0);" class="btn btn-primary mt-3" v-if="editOptions" @click="editOptions = !editOptions"> {{ $t("new_survey.questions.edit.preview") }} </a>
    </div>
  </div>
</template>
