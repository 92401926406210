<script>
import axios from 'axios'
import Utils from "@/js/utils";
import dayjs from "dayjs";
// import HeatMatrix from "./heat-matrix.vue"

export default {
  data() {
    return {
      dates: [],
      reviews: {numerical: [], sentiment: [], tes: []},
      ratings: {numerical: [], sentiment: [], tes: []},
      analyticsData: { numerical: [], sentiment: [], tes: [], count: [] },
      // needed to calculateTES using review_counts, not opinions_count
      sentiment_counts: [],
      loading: false,
      availableCols: {
        numerical_rating: {
          'name': () => this.$t('dashboard.overall_chart.numerical_rating'),
          'data': 'ratings.numerical',
          'type': 'line'
        },
        sentiment_rating: {
          'name': () => this.$t('dashboard.overall_chart.sentiment_rating'),
          'data': 'ratings.sentiment',
          'type': 'line'
        },
        tes_score: {
          'name': () => this.$t('dashboard.overall_chart.tes_score'),
          'data': 'ratings.tes',
          'type': 'line'
        },
        reviews_count: {
          'name': () => this.$t('dashboard.overall_chart.reviews_count'),
          'data': 'reviews.numerical',
          'type': 'column'
        },
        mentions_count: {
          'name': () => this.$t('dashboard.overall_chart.mentions_count'),
          'data': 'reviews.sentiment',
          'type': 'column'
        },
        analytics_numerical_rating: {
          'name': () => this.$t('dashboard.overall_chart.numerical_rating'),
          'data': 'analyticsData.numerical',
          'type': 'line'
        },
        analytics_sentiment_rating: {
          'name': () => this.$t('dashboard.overall_chart.sentiment_rating'),
          'data': 'analyticsData.sentiment',
          'type': 'line'
        },
        analytics_tes_score: {
          'name': () => this.$t('dashboard.overall_chart.tes_score'),
          'data': 'analyticsData.tes',
          'type': 'line'
        },
        response_count: {
          'name': () => this.$t('dashboard.overall_chart.responses_count'),
          'data': 'analyticsData.count',
          'type': 'column'
        }
      },
      toggle: true,
    }
  },
  props: ['type', 'cols', 'page', 'filterParams'],
  watch: {
    filterParams: {
      handler: function () {
        if (this.filterParams.data) this.loadListener(this.filterParams);
      },
      immediate: true
    },
    cols: {
      handler: function() {
        if (Object.keys(this.filterParams).length) this.loadListener(this.filterParams);
      },
      immediate: true
    }
  },
  computed: {
    countSlug () {
      if (this.type == 'sentiment') return 'mentions_count'
      return 'reviews_count'
    },
    series () {
      let res = []
      this.cols.forEach((col) => {
        let temp = {}
        if (this.availableCols[col]) {
          temp['name'] = this.availableCols[col]['name']()
          temp['data'] = this.availableCols[col]['data'].split('.').reduce((p, prop) => { return p[prop] }, this.$data)
          temp['data'] = temp['data'].map(d => d || null)
          temp['type'] = this.availableCols[col]['type']
        }
        let hasData = temp['data'].reduce((res, curr) => (!!res || curr || curr == 0), false);
        if (hasData) res.push(temp);
      })
      return res;
    },
    chartOptions () {
      return {
        chart: {
          toolbar: {
            show: true,
            export: {
              csv: {
                headerCategory: this.$t("general.date"),
                headerValue: "value",
                dateFormatter(timestamp) {
                  return dayjs(timestamp).format("MMMM YYYY");
                }
              }
            }
          }
        },
        tooltip: { y: { formatter: val => Utils.formatNumber(val) } },
        dataLabels: { enabled: false },
        plotOptions: { bar: { columnWidth: '50%' } },
        stroke: { curve: "smooth", width: [2, 2, 2, 2] },
        colors: ["#8b8", "#4090cb", '#ada', '#ead'],
        labels: [],
        xaxis: { type: 'category', categories: this.dates.map(d => dayjs(d).format("MMM YYYY")) },
        fill: {
            opacity: [1, 1],
            gradient: {
                opacityFrom: 1,
                opacityTo: 1,
                stops: [20, 1000]
            }
        },
        yaxis: [
          {
            axisTicks: { show: false, },
            axisBorder: {
              show: true,
              color: '#4090cb'
            },
            decimalsInFloat: false,
            labels: {
              style: { colors: '#4090cb' },
              formatter: val => Utils.formatNumber(val)
            },
            title: {
              text: this.type
              ? this.$t('overall_chart.'+this.countSlug)
              : this.$t(`overall_chart.${this.page}_x`),
              style: {
                color: '#4090cb',
              }
            },
            tooltip: { enabled: true }
          },
          {
            seriesName: this.$t(`overall_chart.${this.page}_opposite_y`),
            opposite: true,
            decimalsInFloat: true,
            axisTicks: { show: true, },
            axisBorder: { show: true, color: '#8b8' },
            labels: { style: { colors: '#8b8', }, formatter: val => Utils.formatNumber(val) },
            title: { text: this.$t(`overall_chart.${this.page}_opposite_y`), style: { color: '#8b8', } },
          },
          {
            floating: true,
            seriesName: this.$t(`overall_chart.${this.page}_opposite_y`),
            opposite: true,
            decimalsInFloat: true,
            axisTicks: { show: false },
            axisBorder: { show: false },
            labels: { show: false },
            title: { text: "" },
          },
          {
            floating: true,
            seriesName: this.$t(`overall_chart.${this.page}_opposite_y`),
            opposite: true,
            decimalsInFloat: true,
            axisTicks: { show: false, },
            axisBorder: { show: false },
            labels: { show: false },
            title: { text: "" },
          }
        ]
      }
    },
    showChart() {
      return this.series.length && this.series.reduce((res, curr) => res + curr.data.filter(n => n).length, 0);
    }
  },
  methods: {
    loadListener (params) {
      let p = { ...params.data, per: "month", source: params.data?.sources }
      this.load(p)
    },
    async load (params) {
      Utils.setLoading.bind(this)(true)
      let promises = []
      this.clearData()

      if (this.cols.includes('numerical_rating') || this.cols.includes('tes_score')) {
        if (this.page != "reputation_dashboard") {
          promises.push(axios.get("/v3/destination/stats", { params }).then(response => {
            let data               = response.data.data
            this.dates             = this.dates.concat(data.map(e => e.date))
            this.reviews.numerical = data.map(e => e.stats.review_count)
            this.ratings.numerical = data.map(e => Number((e.stats.overall_rating_average / 10).toFixed(1)))
            if (this.ratings.numerical.length > 0 && this.ratings.sentiment.length > 0) this.generateTES()
          }))
        } else {
          promises.push(axios.get("/v3/ratings/stats", { params }).then(response => {
            let data               = response.data.data
            this.dates             = this.dates.concat(data.map(e => e.date))
            data.map(e => e.rating = e.ratings.find(o => o.topic == 'overall'))
            this.reviews.numerical = data.map(e => e.rating?.review_count)
            this.ratings.numerical = data.map(e => Number((e.rating?.value / 10).toFixed(1)))
          }))
        }
      }

      if (this.cols.includes('sentiment_rating') || this.cols.includes('tes_score')) {
        if (this.page != "reputation_dashboard") {
          promises.push(axios.get("/v3/destination/sentiment", { params }).then(response => {
            let data               = response.data.data
            data.map(e => e.sentiment = e.sentiment.find(o => o.topic == 'overall'))
            this.dates             = this.dates.concat(data.map(e => e.date))
            this.sentiment_counts  = data.map(e => e.sentiment.review_count)
            this.reviews.sentiment = data.map(e => e.sentiment.opinions_count)
            this.ratings.sentiment = data.map(e => Number(e.sentiment.sentiment_score.toFixed(1)))
            if (this.ratings.numerical.length > 0 && this.ratings.sentiment.length > 0) this.generateTES()
          }))
        }
        else {
          promises.push(axios.get("/v3/sentiment/stats", { params }).then(response => {
            let data               = response.data.data
            data.map(e => e.sentiment = e.opinions.find(o => o.topic == 'overall'))
            this.dates             = this.dates.concat(data.map(e => e.date))
            // this.sentiment_counts  = data.map(e => e.sentiment.review_count)
            this.reviews.sentiment = data.map(e => e.sentiment?.review_count)
            this.ratings.sentiment = data.map(e => Number(e.sentiment?.sentiment_score.toFixed(1)))
          }))
        }
      }

      if (this.isAnalytics()) {
        promises.push(axios.get('/v3/feedback/analytics/per_period', { params: {
          start_date:    params.start_date,
          end_date:      params.end_date,
          finished:      params.finished,
          company_ids:   params.company_ids,
          group_ids:     params.group_ids,
          survey_id:     params.survey_id,
          has_sentiment: params.has_sentiment,
          language:      params.response_language,
          question_type: params.question_type,
          subscription_ids: params.subscription_ids,
          per:           'month'
        }}).then(response => {
          let res = response.data.data;
          this.analyticsData.count     = res.map(e => e.responses_count);
          this.analyticsData.numerical = res.map(e => this.toBase10(e.numerical.value));
          this.analyticsData.sentiment = res.map(e => this.toBase10(e.sentiment.value));
          this.analyticsData.tes       = res.map(e => this.toBase10(e.tes.value));
          this.dates = res.map(e => e.date.substring(0, 8) + "01");
        }))
      }

      await Promise.all(promises)
      this.dates = this.dates.filter(Utils.uniq).sort((a, b) => dayjs(a).diff(dayjs(b)));
      if (this.ratings.numerical.length > 0 && this.ratings.sentiment.length > 0)
        this.generateTES()

      Utils.setLoading.bind(this)(false)
    },
    generateTES() {
      this.ratings.tes = []
      for(var i = 0; i < this.ratings.numerical.length; i++) {
        if (this.ratings.sentiment[i] != undefined)
          this.ratings.tes[i] = Utils.calcTES(this.ratings.numerical[i], this.reviews.numerical[i], this.ratings.sentiment[i], this.reviews.sentiment[i])
      }
    },
    toBase10(number) { return Number.parseFloat(Utils.round((number / 10))) },
    isAnalytics() {
      return this.cols.find(element => {
        if (element.includes('analytics')) return true;
      });
    },
    changeToggle() {
      this.toggle = !this.toggle;
    },
    clearData() {
      this.dates   = [];
      this.reviews = { numerical: [], sentiment: [], tes: [] };
      this.ratings = { numerical: [], sentiment: [], tes: [] };
      this.analyticsData = { numerical: [], sentiment: [], tes: [], count: [] };
      this.sentiment_counts = [];
    }
  },
  // components: {
  //   HeatMatrix
  // }
};
</script>

<template>
  <div class="card" :class="{'loading': loading}">
    <div class="card-body d-flex flex-column" style="overflow-x: auto;">
      <div class="d-flex justify-content-start mb-4">
        <h4 class="card-title float-start m-0 mr-3">{{ toggle ? $t(`overall_chart.title_${page}`) : $t("heat_matrix.title") }}</h4>
        <!-- <b-form-checkbox switch style="margin-left: -25px;" @change="changeToggle"></b-form-checkbox> -->
        <span class='ms-1' v-b-tooltip.hover :title="$t(`overall_chart.${page}_help`)">
          <i class='mdi mdi-help-circle ms-2'></i>
        </span>
      </div>

      <div v-if="toggle">
        <apexchart
          v-if="showChart"
          class="apex-charts mb-4"
          type="line"
          dir="ltr"
          height="385"
          :series="series"
          :options="chartOptions"
          ref='reviewsOverall'
          style="min-width: 600px;"
        ></apexchart>

        <span v-if="!showChart">{{ $t("general.no_data") }}</span>
      </div>
      <div v-else-if="loading" class="loading" style="min-height: 200px;"></div>
      <!-- <div v-else>
        <HeatMatrix/>
      </div> -->
    </div>
  </div>
</template>
