<script>
import Multiselect from "vue-multiselect";
import PageHeader  from "@/components/page-header";
import Swal  from "sweetalert2";
import axios from "axios";
import FeedbackUtils from "@/js/feedback-utils";
import Utils from "@/js/utils";

export default {
  data() {
    return {
      actions: ["show", "hide", "jump"],
      condition_types: ["less_than", "less_or_equal_to", "is_equal_to", "is_not_equal_to", "more_or_equal_to", "more_than", "contains", "doesnt_contain", "begins_with", "ends_with"],
      cols: {
        "level":   { "width": 200, "tooltip": false },
        "source":  { "width": 300, "tooltip": true  },
        "type":    { "width": 160, "tooltip": false },
        "value":   { "width": 270, "tooltip": false },
        "action":  { "width": 100, "tooltip": true  },
        "target":  { "width": 300, "tooltip": true  },
        "status":  { "width": 120, "tooltip": false },
        "actions": { "width": 100, "tooltip": false },
      },
      compatibleConditions: {
        "date":                [ "less_than", "less_or_equal_to", "is_equal_to", "is_not_equal_to", "more_or_equal_to", "more_than" ],
        "drop_down":           [ "contains", "doesnt_contain" ],
        "enum":                [ "is_equal_to", "is_not_equal_to" ],
        "multiple_select":     [ "contains", "doesnt_contain" ],
        "open_answer":         [ "contains", "doesnt_contain", "begins_with", "ends_with" ],
        "open_answer_long":    [ "contains", "doesnt_contain", "begins_with", "ends_with" ],
        "past_date":           [ "less_than", "less_or_equal_to", "is_equal_to", "is_not_equal_to", "more_or_equal_to", "more_than" ],
        "rating":              [ "less_than", "less_or_equal_to", "is_equal_to", "is_not_equal_to", "more_or_equal_to", "more_than" ],
        "yes_no":              [ "is_equal_to", "is_not_equal_to" ],
        "trip_advisor_iframe": [ "is_equal_to", "is_not_equal_to" ],
        [undefined]:        []
        /*, "trip_advisor_iframe": */
      },
      levels: ["question", "group"],
      currentEditionIndex: null,
      loadingAction: false,
      isDirty: false,
      yesNoOptions: { true: 'yes', false: 'no' },
      humanizedView: false,
      highlightTarget: null
    };
  },
  props: ["survey", "questionHash", "pointLogicIndex"],
  components: { Multiselect, PageHeader },
  watch: {
    pointLogicIndex: function () {
      if (this.pointLogicIndex != null) this.setHighlightTarget(this.pointLogicIndex);
    }
  },
  created() {
    this.setEditionToFalse();
  },
  methods: {
    ...FeedbackUtils,
    setHighlightTarget(index) {
      this.highlightTarget = index;
      setTimeout(() => {
        this.highlightTarget = null;
      }, 3000);
    },
    setEditionToFalse() {
      if (this.survey.id) {
        this.survey?.conditions?.forEach(condition => {
          condition.editing = false;
        });
      }
    },
    addNewCondition() {
      const newCondition = {
        level: "question",
        editing: true,
        published: true,
        source: { action: "show", id: null, type: "is_equal_to", value: null },
        target: { id: null }
      };
      this.survey.conditions.push(newCondition);
      this.currentEditionIndex = this.survey.conditions.length - 1;
    },
    edit(index) {
      this.currentEditionIndex = index;
      this.survey.conditions[this.currentEditionIndex].editing = true;
    },
    save() {
      this.updateCondition(this.currentEditionIndex, 'update');
    },
    async updateCondition(index, action) {
      let proceed = true;

      if (action == 'delete') {
        proceed = confirm(this.$t('new_survey.logic.delete_confirm'));
        if (proceed) this.survey.conditions.splice(index, 1);
      }

      if(action == "toggleEnable" || (proceed && this.validateFields(action))) {
        try {
          this.loadingAction = true;
          Utils.updatePageOpts({ statusMessage: { status: "saving", icon: "ion ion-md-sync" } });

          if(action == 'update' && index != null) this.survey.conditions[index].editing = false;
          await axios.post(`/v3/feedback/surveys/${this.survey.id}`, { conditions: this.survey.conditions });

          this.currentEditionIndex = null;

          Utils.updatePageOpts({ statusMessage: { status: "saved", icon: "mdi mdi-check-bold" } });
          this.loadingAction = false;
          this.isDirty = false;
          this.$emit("dirtyUpdate", this.isDirty);
        } catch(error) {
          this.error(error);
        }
      }

      else if(proceed && !this.validateFields(action)) alert(this.$t("new_survey.logic.invalid_fields"));
    },
    validateFields(action) {
      let res = true;
      if (action == 'delete') return res;

      ['source', 'target', 'value'].forEach(field => {
        if (!this.isValid[field]) res = false;
      });
      return res;
    },
    useItem(id) {
      let item = this.survey.items?.find(i => i.id == id);
      return item || { object: {} };
    },
    useItemTranslation(id, level) {
      if (!id) return;
      if (id == "thank_you") return this.$t("new_survey.questions.left.thank_you");
      let item = this.useItem(id);
      if (level == "group") return item?.name;
      return item.object?.texts?.question[this.$i18n.locale] || item?.object?.texts?.question[this.survey.default_locale];
    },
    useStatus(condition) {
      return condition.published ? this.$t("new_survey.logic.enabled") : this.$t("new_survey.logic.disabled");
    },
    valueType(condition) {
      let type = null;
      let item = this.useItem(condition.source.id).object;
      if (/multiple_select|enum|drop_down/.test(item?.type)) type = "options";
      if (/open_answer|open_answer_long/.test(item?.type)) type = "text";
      if (/rating/.test(item?.type)) type = "rating";
      if (/date|past_date/.test(item?.type)) type = "date";
      if (/yes_no/.test(item?.type)) type = "yes_no"
      if (/trip_advisor_iframe/.test(item?.type)) type = "iframe";
      return type;
    },
    ratingsOptions(id) {
      let maxValue = this.useItem(id).object?.max_value;
      return Array.from({ length: maxValue }, (_, i) => i + 1);
    },
    useOptionsIds(id) {
      let item = this.useItem(id).object;
      return item?.options.map(o => o.id);
    },
    useTranslatedOption(condition, value) {
      let item = this.useItem(condition.source.id).object;
      return item?.options ? this.getAnswer(value, item?.type, false, item?.options) : condition.source.value;
    },
    targetList(condition) {
      if (condition.level == "group") return this.survey.items.filter(i => i.type == "group")?.map(i => i.id);
      let questions = this.survey.items.filter(i => i.type == "question");
      let index     = questions.findIndex(item => item.id == condition.source.id);
      questions     = questions.slice(index + 1).map(q => q.id);
      if (condition.source.action == "jump") questions.push("thank_you");
      return questions;
    },
    sourceList() {
      return this.survey.items.filter(i => i.type == "question")?.map(i => i.id);
    },
    useIcon(id, level) {
      if (level == "group") return "mdi mdi-format-list-group";
      let item = this.useItem(id).object;
      return this.questionHash[item?.type || id]?.icon || '';
    },
    setDirty() {
      this.isDirty = true;
      this.$emit("dirtyUpdate", this.isDirty);
      return true;
    },
    error (error) {
      return Swal.fire(this.$t('general.error'), this.$t('general.contact_support') + JSON.stringify(error.response.data), 'error');
    },
    viewLabel() {
      return this.humanizedView ? this.$t("new_survey.logic.edition_view") : this.$t("new_survey.logic.summary_view");
    },
    toggleHumanizedView() {
      this.humanizedView = !this.humanizedView;
    },
    toggleAllConditions() {
      let enable = true;
      if (this.isAllEnabled) enable = false;
      this.survey.conditions.forEach(condition => condition.published = enable);
      this.updateCondition(null, "toggleEnable");
    },
    allConditionsLabel() {
      if (this.isAllEnabled) return this.$t("new_survey.logic.disable_all");
      return this.$t("new_survey.logic.enable_all");
    },
    humanizedPhrase(action) {
      return action == "jump" ? "new_survey.logic.jump_phrase" : "new_survey.logic.phrase";
    }
  },
  // watch: {
  //   "survey.conditions": function() { this.setEditionToFalse() }
  // },
  computed: {
    isValid() {
      let condition = this.survey.conditions[this.currentEditionIndex];
      if (!condition) return {}
      return {
        source: condition.source.id,
        target: condition.target.id,
        value:  condition.source.value
      };
    },
    isAllEnabled() {
      let res = true;
      this.survey.conditions.forEach(condition => {
        if (!condition.published) res = false;
      });
      return res;
    }
  }
};
</script>

<template>
  <div v-if="survey.id" class="logic-root card" :class="{ 'cursor-loading': loadingAction }">
    <div class="card-body">
      <div class="d-sm-flex align-items-center justify-content-between">
        <PageHeader :title="$t('new_survey.logic.subtitle')" />
        <div class="d-flex justify-content-between">
          <button class="btn btn-outline-secondary" @click="toggleHumanizedView" :disabled="currentEditionIndex" :class="{ 'cursor-not-allowed': currentEditionIndex }"> {{ viewLabel() }} </button>
          <button v-if="!humanizedView" class="btn btn-primary ms-3" @click="addNewCondition" :disabled="currentEditionIndex" :class="{ 'cursor-not-allowed': currentEditionIndex }" :title="currentEditionIndex && $t('new_survey.logic.add_helper')"> {{ $t("new_survey.logic.add") }} </button>
        </div>
      </div>

      <div v-if="survey.questions.length > 0 && !humanizedView" class="table-responsive mt-3" style="min-height: 500px; overflow-y: hidden;">
        <table v-if="survey" class="table table-bordered table-nowrap mb-0 text-center" style="table-layout: fixed;">
          <thead class='thead-light'>
            <tr>
              <th v-for='(col, v) in cols' :key="v" :style="`width: ${col.width}px;`">
                {{ $t(`new_survey.logic.table_cols.${v}`) }}
                <span v-if="col.tooltip" class="ms-1" v-b-tooltip.hover :title="$t(`new_survey.logic.table_cols.${v}_tooltip`)">
                  <i class='mdi mdi-help-circle'></i>
                </span>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(condition, index) in survey.conditions" :key="index" :class="{ 'table-row-highlight': condition.editing, 'flash': highlightTarget == condition.target.id }">

              <!-- Level -->
              <td>
                <multiselect v-if="condition.editing" :options="levels" v-model="condition.level" :customLabel="l => $t(`new_survey.logic.levels.${l}`)" :showLabels="false" :placeholder="$t('new_survey.logic.new_level')" :allow-empty="false" @select="setDirty" @remove="setDirty" open-direction="bottom"></multiselect>
                <div v-show="!condition.editing">{{ $t(`new_survey.logic.levels.${condition.level}`) }}</div>
              </td>

              <!-- Source -->
              <td>
                <multiselect v-if="condition.editing" :options="sourceList()" v-model="condition.source.id" :showLabels="false" :placeholder="$t('new_survey.logic.new_source')" :allow-empty="false" :customLabel="q => useItemTranslation(q)" :class="{ 'valid': isValid.source, 'invalid': !isValid.source }" @select="setDirty" @remove="setDirty" open-direction="bottom">
                  <template v-slot:singleLabel="props">
                    <i :class="useIcon(props.option)" class="me-1"></i>
                    <span class="text-wrap" v-html="useItemTranslation(props.option)"></span>
                  </template>

                  <template v-slot:option="props">
                    <i :class="useIcon(props.option)" class="me-1"></i>
                    <span class="text-wrap" v-html="useItemTranslation(props.option)"></span>
                  </template>
                </multiselect>
                <div v-show="!condition.editing">
                  <i :class="useIcon(condition.source.id)" class="me-1"></i>
                  <span class="text-wrap" v-html="useItemTranslation(condition.source.id)"></span>
                </div>
              </td>

              <!-- Type -->
              <td>
                <multiselect v-if="condition.editing" :options="compatibleConditions[useItem(condition.source.id).object.type]" v-model="condition.source.type" :customLabel="c => $t(`new_survey.logic.condition_types.${c}`)" :showLabels="false" :placeholder="$t('new_survey.logic.new_condition_type')" :allow-empty="false" @select="setDirty" @remove="setDirty" open-direction="bottom"></multiselect>
                <div v-show="!condition.editing">{{ $t(`new_survey.logic.condition_types.${condition.source.type}`) }}</div>
              </td>

              <!-- Value -->
              <td>
                <template v-if="condition.editing">
                  <!-- drop_down, enum, multiple_select -->
                  <multiselect v-if="valueType(condition) == 'options'" :options="useOptionsIds(condition.source.id)" v-model="condition.source.value" :customLabel="c => useTranslatedOption(condition, c)" :showLabels="false" :placeholder="$t('new_survey.logic.new_value')" :allow-empty="false" :class="{ 'valid': isValid.value, 'invalid': !isValid.value }" @select="setDirty" @remove="setDirty" open-direction="bottom">
                    <template v-slot:singleLabel="props">
                      <span class="text-wrap" v-html="useTranslatedOption(condition, props.option)"></span>
                    </template>

                    <template v-slot:option="props">
                      <span class="text-wrap" v-html="useTranslatedOption(condition, props.option)"></span>
                    </template>
                  </multiselect>

                  <!-- yes_no -->
                  <multiselect v-else-if="/iframe|yes_no/.test(valueType(condition))" :options="['true', 'false']" v-model="condition.source.value" :customLabel="c => $t(`surveys_responses.${yesNoOptions[c]}`)" :showLabels="false" :placeholder="$t('new_survey.logic.new_value')" :allow-empty="false" :class="{ 'valid': isValid.value, 'invalid': !isValid.value }" @select="setDirty" @remove="setDirty" open-direction="bottom"></multiselect>

                  <!-- rating -->
                  <multiselect v-else-if="valueType(condition) == 'rating'" :options="ratingsOptions(condition.source.id)" v-model="condition.source.value" :showLabels="false" :placeholder="$t('new_survey.logic.new_value')" :allow-empty="false" :class="{ 'valid': isValid.value, 'invalid': !isValid.value }" @select="setDirty" @remove="setDirty" open-direction="bottom"></multiselect>

                  <!-- others -->
                  <b-form-input v-else :type="valueType(condition)" v-model="condition.source.value" :placeholder="$t('new_survey.logic.new_value')" :class="{ 'valid': isValid.value, 'invalid': !isValid.value }" @input="setDirty"></b-form-input>
                </template>

                <div class="text-wrap" v-show="!condition.editing" v-html="useTranslatedOption(condition, condition.source.value)"></div>
              </td>

              <!-- Action -->
              <td>
                <multiselect v-if="condition.editing" :options="actions" v-model="condition.source.action" :customLabel="a => $t(`new_survey.logic.actions_types.${a}`)" :showLabels="false" :placeholder="$t('new_survey.logic.new_action')" :allow-empty="false" @select="setDirty" @remove="setDirty" open-direction="bottom"></multiselect>
                <div v-show="!condition.editing">{{ $t(`new_survey.logic.actions_types.${condition.source.action}`) }}</div>
              </td>

              <!-- Target -->
              <td>
                <multiselect v-if="condition.editing" :options="targetList(condition)" v-model="condition.target.id" :showLabels="false" :placeholder="$t('new_survey.logic.new_target')" :allow-empty="false" :customLabel="q => useItemTranslation(q, condition.level)" :class="{ 'valid': isValid.target, 'invalid': !isValid.target }" @select="setDirty" @remove="setDirty" open-direction="bottom">
                  <template v-slot:singleLabel="props">
                    <i :class="useIcon(props.option, condition.level)" class="me-1"></i>
                    <span class="text-wrap" v-html="useItemTranslation(props.option, condition.level)"></span>
                  </template>

                  <template v-slot:option="props">
                    <i :class="useIcon(props.option, condition.level)" class="me-1"></i>
                    <span class="text-wrap" v-html="useItemTranslation(props.option, condition.level)"></span>
                  </template>
                </multiselect>
                <div v-show="!condition.editing">
                  <i :class="useIcon(condition.target.id, condition.level)" class="me-1"></i>
                  <span class="text-wrap" v-html="useItemTranslation(condition.target.id, condition.level)"></span>
                </div>
              </td>

              <!-- Status -->
              <td>
                <b-form-checkbox v-show="condition.editing" v-model="condition.published" switch class="ps-4 mt-2" @input="setDirty"></b-form-checkbox>
                <span v-show="!condition.editing"> {{ useStatus(condition) }} </span>
              </td>

              <!-- Actions -->
              <td>
                <div class="d-flex justify-content-center align-items-center">
                  <a href="javascript:void(0);" v-show="condition.editing" :title="$t('new_survey.logic.actions.save')" class="align-self-center text-dark font-size-18" @click="save">
                    <i class="mdi mdi-check-bold text-success" />
                  </a>

                  <a href="javascript:void(0);" v-show="!condition.editing && !currentEditionIndex" :title="$t('new_survey.logic.actions.edit')" class="align-self-center text-dark font-size-18" @click="edit(index)">
                    <i class="mdi mdi-pencil" />
                  </a>

                  <a href="javascript:void(0);" v-show="!currentEditionIndex || currentEditionIndex == index" :title="$t('new_survey.logic.actions.delete')" class="align-self-center ms-3 text-dark font-size-18" @click="updateCondition(index, 'delete')"> <i class="ion ion-md-trash" /></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <button v-if="!humanizedView && survey.conditions.length" class="btn btn-outline-primary mt-3 float-end" @click="toggleAllConditions" :disabled="currentEditionIndex" :class="{ 'cursor-not-allowed': currentEditionIndex }">
        {{ allConditionsLabel() }}
      </button>

      <div v-else-if="survey.questions.length > 0 && humanizedView" class="mt-3" style="min-height: 500px; overflow-y: hidden;">
        <ul class="list-group">
          <li v-for="(condition) in survey.conditions" :key="'humanized_' + condition.source.action + condition.source.id + condition.target.id + condition.source.value + condition.source.type" class="list-group-item">
            <i18n-t scope="global" :keypath="humanizedPhrase(condition.source.action)" tag="span">
              <template v-slot:source>
                "<strong>{{ useItemTranslation(condition.source.id, condition.level) }}</strong>"
              </template>

              <template v-slot:type>
                {{ $t(`new_survey.logic.condition_types.${condition.source.type}`).toLowerCase() }}
              </template>

              <template v-slot:value>
                <strong v-html="useTranslatedOption(condition, condition.source.value)"></strong>
              </template>

              <template v-slot:target>
                "<strong>{{ useItemTranslation(condition.target.id, condition.level) }}</strong>"
              </template>

              <template v-slot:action>
                <strong>{{ $t(`new_survey.logic.actions_types_past_participle.${condition.source.action}`) }}</strong>
              </template>
            </i18n-t>
          </li>
        </ul>
      </div>

      <div v-else-if="!survey.questions.length" class="my-3 d-flex align-items-center justify-content-center">
        <span class="font-size-16"> {{ $t("new_survey.logic.no_questions") }} </span>
      </div>
    </div>
  </div>
</template>
