<script>
import dayjs     from "dayjs";
import Utils     from "@/js/utils";
import DataUtils from "@/js/data-utils";

export default {
  data() {
    return {
      stars: {
        empty: 0,
        full:  0,
        half:  0
      },
      travelCompositionIcons: {
        "families":     "mdi mdi-human-male-female-child",
        "single":       "mdi mdi-account",
        "solo":         "mdi mdi-account",
        "couples":      "mdi mdi-human-male-female",
        "friends":      "mdi mdi-account-group",
        "group":        "mdi mdi-account-group",
        "business":     "mdi mdi-account-tie",
        "seniors":      "mdi mdi-human-walker",
        "young_adults": "mdi mdi-account-group",
        "other":        "mdi mdi-dots-horizontal",
      }
    };
  },
  props: ["review", "star_color"],
  created() {
    let relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime);

    this.stars.full = Math.floor(this.review.review_score / 20);
    this.stars.half = this.review.review_score % 20 != 0 ? 1 : 0;
    this.stars.empty = 5 - this.stars.half - this.stars.full;
  },
  computed: {
    reviewDate() {
      return "Reviewed " + dayjs().to(dayjs(this.review.review_date));
    }
  },
  methods: {
    ...DataUtils,
    sourceIcon: Utils.sources.iconUrl,

    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  }
}
</script>

<template>
  <div class="card mt-3 mb-0">
    <div class="card-body font-size-14">
      <div class="d-flex align-items-center mb-1">
        <div :style="`color: ${star_color}; font-size: 30px;`">
          <span v-for="i in stars.full"  class="ion ion-md-star me-2" :key="'full'+i"></span>
          <span v-for="i in stars.half"  class="ion ion-md-star-half me-2" :key="'half'+i"></span>
          <span v-for="i in stars.empty" class="ion ion-md-star-outline me-2" :key="'empty'+i"></span>
        </div>
        <span class="ms-4">{{ reviewDate }}</span>
      </div>

      <div v-for="(text, index) in review.review_text" :key="text + index" class="">
        <div class="font-size-16" style="font-weight: 600;">{{ capitalize(text.type) }}</div>
        <span class="limit-text-lines">{{ text.comment }}</span>
      </div>

      <div class="d-flex justify-content-start align-items-center mt-1" style="gap: 12px;">
        <img v-if="review.source.id" class="rounded" :src=sourceIcon(review.source.id) style="width: 30px;">
        <div>{{ review.source.title }}</div>

        <span v-if="review.reviewer_country.code" class="flag ms-2" :class="`flag-${locales(review.reviewer_country.code.toLowerCase(), 'country').code}`" style="width: 33px; height: 22.5px;"></span>
        <div>{{ review.reviewer_country.title }}</div>

        <span v-if="review.travel_composition.id" class="ms-2" :class="travelCompositionIcons[review.travel_composition.id]" style="font-size: 28px;"></span>
        <div>{{ review.travel_composition.title }}</div>
      </div>
    </div>
  </div>
</template>
