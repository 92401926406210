if (!window.oly) window.oly = {}
import dayjs from "dayjs";

window.oly.feedbackUtils = {
  getAnswer (value, type, translated, options) {
    if (value == null) return this.$t('feedback_analytics.unanswered');

    if (type == 'open_answer' || type == 'open_answer_long')
      return translated ? this.translations[this.$i18n.locale][value] : value;

    if (type == 'date' || type == 'past_date') {
      const val = new Date(value.replace(/ 00:00$/, ''));
      return val.getDate() + " " + val.toLocaleString(this.$i18n.locale, { month: "long" }) + " " + val.getFullYear();
    }

    if (type == 'yes_no' || type == 'trip_advisor_iframe') return this.$t("surveys_responses." + this.yesNoOptions[value]);

    if (type == 'nps') return this.$t("feedback_analytics." + value);
    return this.getFromOptions(value, options)
  },
  getFromOptions(value, options) {
    let newValue = options?.find(o => o.id == value)
    if (newValue) return newValue[this.$i18n.locale] || newValue.default || newValue.text
    return value
  },
  findAnswer(answer) {
    return answer.rating || answer.selection || answer.answer || answer.yes_no ;
  },
  npsColorHash() {
    return {
      "detractor": "#d54939",
      "passive":   "#f48e2f",
      "promoter":  "#54ca74",
      "undefined": "#c7c7c7"
    }
  },
  NPS(value) {
    if (value == null) return { label: "undefined", color: this.npsColorHash().undefined };
    let res;
    if (value <= 6)               res = "detractor";
    if (7 <= value && value <= 8) res = "passive";
    if (9 <= value)               res = "promoter";
    return { label: res, color: this.npsColorHash()[res] };
  },
  tokenizeVariables(text = "", prefix = "#", suffix = "#") {
    if (text.length <= 0) return;
    let title = this.$t('feedback_analytics.custom_field_title');
    const regex = new RegExp(`${prefix}([\\w\\s]+)${suffix}`, "ig");
    return text.replace(regex, `<span class="token-company" title="${title}">$1</span>`);
  },
  replaceCompanyName(text = "", company_name) {
    if (!company_name) return text;
    return text?.replace("#company_name#", company_name);
  },

  // responses
  revertTranslation(index) {
    this.cache[this.params.page][index].translated = false;
  },
  getMonth(created_at) {
    let res = this.$t(`months.${this.toDate(created_at).toLocaleString("en", { month: "long" })}`);

    if (res.length > 3) res = res.substring(0, 3) + ".";
    return res;
  },
  DOMParser(text) {
    return new DOMParser().parseFromString(text,'text/html').querySelector('html').textContent;
  },
  toDate(dateStr) {
    return dayjs(dateStr).toDate();
  },
  canShow(answer) {
    return answer.answer || answer.rating || typeof(answer.yes_no) == "boolean" || (answer.selection && answer.selection.length > 0);
  },
  moreInfoCardButtonLabel(page, index) {
    if (this.cache[page][index].showMoreInfo) return this.$t("surveys_responses.hide");
    return this.$t("surveys_responses.show");
  },
  toggleMoreInfo(page, index) {
    this.cache[page][index].showMoreInfo = !this.cache[page][index].showMoreInfo;
  },
  resetExpandButtons() {
    if (this.cache[this.params.page]) this.cache[this.params.page].forEach(r => r.showMoreInfo = false);
  }
}
export default window.oly.feedbackUtils;
