<script>
export default {
  props: ["backgroundImage", "slide", "questions", "bgColor"],
  computed: {
    companyColor() {
      if (this.bgColor) return `--company-color: ${this.bgColor};`;
      return "";
    }
  }
};
</script>

<template>
  <div class="outer-container">
    <div
      class="container-fluid root-form p-0"
      :style="`background-image: url(${backgroundImage}); ${companyColor}`"
    >
      <div class="progress-bar-up">
        <div
          class="content-bar"
          :style="{ width: `${(slide / questions.length) * 100}%` }"
        ></div>
      </div>
      <div class="carousel slide h-100" id="questions-carousel">
        <div class="carousel-inner">
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>
