<script>
import PageHeader from "@/components/page-header";
import Filters from "@/components/filter";
import SourceRatings from "@/components/source-ratings";

export default {
  data() {
    return {
      filterCols: ["group_companies", "companies", "daterange", "period", "sources"],
      fp: {},
      settings: null
    };
  },
  components: { PageHeader, Filters, SourceRatings },
  methods: {
    saveFilterParams (params) {
      this.fp          = params;
      this.settings    = {
        period: params.period || 'month',
        filters: { source: params.data.sources, exclude_sources: params.data.exclude_sources, source_exclusion: params.data.source_exclusion }
      }
      this.company_ids = params.groups_companies?.map(c => c.id) || [];
    }
  }
}
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('source_data.title')" :tooltip="$t('source_data.help')" />

    <Filters @filter:created="saveFilterParams" @filter:update="saveFilterParams" @filter:change="saveFilterParams" :cols="filterCols" :notifyChange="true" />

    <div v-if="!this.fp?.groups_companies?.length" class="card bg-light select-group-company">{{ $t("general.select_group_or_company") }}</div>

    <div v-else class="row">
      <div class="col-xl-12">
        <div style='min-height: 200px;' class="card">
          <div class="card-body">
            <source-ratings
              :start_date='fp.data.start_date' :end_date='fp.data.end_date'
              :settings='settings' :company_id='company_ids'
              showExport
              />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
