<script>
import PopUp from "./popup";
import Utils from "@/js/utils";

export default {
  data() {
    return {
      map: null,
      popupData: {},
      zoom: 5,
      zoomDelay: 300,
      nextZoomUpdate: null
    }
  },
  props: ["data", "initOpts", "ratingColors", "position", "dataFormatType", "heatColors", "reviewsTotal", "clusterQuadrants", "defaults"],
  components: { PopUp },
  watch: {
    data: {
      handler: function() {
        const points = {
          "type": "FeatureCollection",
          "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
          "features": this.generatePoints()
        }
        this.map.getSource("property-cluster")?.setData(points);
      },
      deep: true
    },
    dataFormatType: function () {
      this.updateClusterLayer();
    },
    "$i18n.locale": {
      handler: function () {
        this.setMapLanguage(this.map);
      },
      immediate: true
    }
  },
  async mounted() {
    await this.loadPoints();
  },
  beforeUnmount() {
    this.map.remove();
  },
  methods: {
    ...Utils.map,
    async loadPoints() {
      const points = {
        "type": "FeatureCollection",
        "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
        "features": this.generatePoints()
      };

      const options = {
        ...this.initOpts,
        container: "cluster-map",
        center: (this.position.lng && this.position.lat) ? [this.position.lng, this.position.lat] : this.getCenter(this.data.map(d => [d.longitude, d.latitude])),
        zoom: this.position.zoom || this.defaults.zoom
      };

      this.map = new window.maplibregl.Map(options);

      this.map.on("load", async () => {
        this.setMapLanguage(this.map);

        this.map.addControl(new window.maplibregl.NavigationControl(), "bottom-right");

        this.map.loadImage("https://dashboard-assets.olery.com/img/point.png", (error, image) => {
          if (error) console.error(error);
          this.map.addImage("point", image, { pixelRatio: 2 });
        });

        this.map.addSource("property-cluster", {
          type: "geojson",
          data: points,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50,
          clusterProperties: {
            weightedValueTotal: [["+", ["accumulated"], ["get", "weightedValueTotal"]], ["*", ["number", ["get", "rating"]], ["number", ["get", "review_count"]]]],
            reviewTotal:        [["+", ["accumulated"], ["get", "reviewTotal"]], ["get", "review_count"]]
          }
        });

        this.map.addLayer(this.clusterConfig);

        this.map.addLayer({
          id:     "cluster-count",
          type:   "symbol",
          source: "property-cluster",
          filter: ["has", "point_count"],
          paint:  { "text-color": "#000" },
          layout: {
            "text-allow-overlap": true,
            "text-font": ["Rubik", "sans-serif"],
            "text-field": [
              "number-format",
              ...this.circleColorExpression(),
              {
                ...(this.dataFormatType == "review_count" ? { style: "decimal" } : {}),
                "locale": this.$i18n.locale,
                "min-fraction-digits": 0,
                "max-fraction-digits": 2
              }
            ]
          }
        });

        this.map.addLayer({
          id:     "unclustered-point",
          type:   "symbol",
          source: "property-cluster",
          filter: ["!", ["has", "point_count"]],
          layout: {
            "icon-image": "point",
            "icon-size":   0.09,
            "icon-allow-overlap": true
          }
        });

        this.map.on("click", "property-point", (e) => {
          const features = this.map.queryRenderedFeatures(e.point, { layers: ["property-point"] });
          const clusterId = features[0].properties.cluster_id;

          this.map.getSource("property-cluster")?.getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
              if (err) return;
              this.map.easeTo({
                center: features[0].geometry.coordinates,
                zoom
              });
            }
          );
        });

        this.map.on("click", "unclustered-point", e => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const data = e.features[0].properties;
          this.popupData = { ...data };
          const popup = this.$refs.popup.$el;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          new window.maplibregl.Popup()
            .setLngLat(coordinates)
            .setDOMContent(popup)
            .addTo(this.map);
        });

        this.map.on("move", () => this.$emit("updatePosition", { center: this.map.getCenter(), zoom: this.map.getZoom() }));

        this.map.on("zoom", () => {
          this.nextZoomUpdate = Date.now() + this.moveDelay;
          setTimeout(async () => {
            this.zoom = this.map.getZoom();
            this.$emit("updateReviewsOnScreen", this.onScreenFn(this.reviewsTotal, this.zoom));
            await this.$nextTick();
            this.updateClusterLayer();
          }, this.zoomDelay);
        });

        ["property-point", "unclustered-point"].forEach(cid => {
          this.map.on("mouseenter", cid, () => {
            this.map.getCanvas().style.cursor = "pointer";
          });
          this.map.on("mouseleave", cid, () => {
            this.map.getCanvas().style.cursor = "";
          });
        });
      });
    },
    circleColorExpression() {
      if (this.dataFormatType == "review_count") return [["get", "reviewTotal"]];
      return [["/", ["get", "weightedValueTotal"], ["get", "reviewTotal"]]];
    },
    generateRatingPoints() {
      if (this.dataFormatType == "review_count")
        return Object.entries(this.heatColors).slice(1).flatMap(([,color], i) => [color, Math.floor(this.onScreenFn(this.reviewsTotal, this.zoom) * (i + 1) / this.clusterQuadrants)]).slice(0, -1);
      return Object.entries(this.ratingColors).flatMap(([k, v]) => ([v, parseInt(k)])).concat(["#54CA74"]);
    },
    generatePoints() {
      return this.data.map(d => ({
        type: "Feature",
        properties: {
          rating:       d.weight,
          review_count: d.quantity,
          name:         d.name,
          numerical:    d.numerical,
          sentiment:    d.sentiment,
          response_rate: d.response_rate
        },
        geometry: {
          type: "Point",
          coordinates: [d.longitude, d.latitude]
        }
      }));
    },
    getCenter(points) {
      if (points.length <= 1) return [this.defaults.lng, this.defaults.lat];
      let llb = new window.maplibregl.LngLatBounds(points);
      const { lng, lat } = llb.getCenter();
      return [lng, lat];
    },
    onScreenFn(reviews, zoom) {
      return reviews / (2 * zoom);
    },
    updateClusterLayer() {
      this.map.setPaintProperty("property-point", "circle-color", [
        "step",
        ...this.circleColorExpression(),
        ...this.generateRatingPoints()
      ]);

      this.map.setLayoutProperty("cluster-count", "text-field", [
        "number-format",
        ...this.circleColorExpression(),
        {
          ...(this.dataFormatType == "review_count" ? { style: "decimal" } : {}),
          "locale": this.$i18n.locale,
          "min-fraction-digits": 0,
          "max-fraction-digits": 2
        }
      ]);
    }
  },
  computed: {
    clusterConfig() {
      return {
        id:     "property-point",
        type:   "circle",
        source: "property-cluster",
        filter: ["has", "point_count"],
        paint:  {
          "circle-color": [
            "step",
            ...this.circleColorExpression(),
            ...this.generateRatingPoints()
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100, // in proportion to total (x% from all properties)
            30,
            750,
            40
          ]
        }
      };
    }
  }
}
</script>

<template>
  <div>
    <div id="cluster-map" class="map"></div>
    <PopUp ref="popup" :data="popupData" />
  </div>
</template>
