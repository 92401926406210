<script>
import Utils from "@/js/utils";

export default {
  data() {
    return {
      sortBy: "rating"
    };
  },
  props: ["data", "segment", "options"],
  methods: {
    cellClass(col) {
      if (/rating/.test(col)) return "h-inherit p-0";
      return "";
    },
    tdClass(value) {
      return Utils.ratings.toCss(value)
    },
    formatNumber(val) {
      return Utils.formatNumber(val);
    }
  },
  computed: {
    series() {
      return [{ name: this.data.label, data: this.data.values }];
    },
    rows() {
      return this.data.xaxis.reduce((res, curr, currIndex) => {
        let rating = this.data.values[currIndex] == null ? this.data.values[currIndex] : Number(this.data.values[currIndex]);
        res.push({ property: curr, rating, color: this.data.colors[currIndex], review_count: this.data.review_count[currIndex] });
        return res;
      }, []);
    },
    cols() {
      return [
        { value: "property", text: this.$t("source_data.property"), sortable: true },
        { value: "rating",   text: this.$t("filter.rating"),        sortable: true }
      ];
    },
    rowsPerPage() {
      this.$refs[`${this.segment}_${this.data.label}`]?.updateRowsPerPageActiveOption(this.rows.length);
      return this.rows.length;
    },
  }
}
</script>

<template>
  <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 d-flex flex-column">
    <h5>{{ data.label }}</h5>
    <data-table
      :ref="`${segment}_${data.label}`"
      class="thead-light"
      :items="rows"
      :headers="cols"
      :rows-per-page="rowsPerPage"
      header-text-direction="center"
      body-text-direction="center"
      :body-item-class-name="cellClass"
      theme-color="#90a0cb"
      :sort-by="sortBy"
      sort-type="desc"
      hide-footer
    >
      <template #item-property="item">
        <div :style="`color:${item.color};`">
          {{ item.property ?? "-" }}
        </div>
      </template>

      <template #item-rating="item">
        <div class="h-100 d-flex justify-content-center align-items-center" :class="tdClass(item.rating)">
          {{ formatNumber(item.rating) }} ({{ `${item.review_count ? formatNumber(item.review_count) : 0} ${$t("general.reviews")}` }})
        </div>
      </template>
    </data-table>
  </div>
</template>