<script>
import axios from 'axios'
import Utils from "@/js/utils";
import Multiselect from "vue-multiselect";
import OleryTable from "@/components/olery-table";

export default {
  data() {
    return {
      data: {numerical: {}, sentiment: {}, tes: {}},
      benchmark: {numerical: {}, sentiment: {}, tes: {}},
      show: false,
      chartTypeOptions: ["table", "pie"],
      chartType: "table",
      loading: false,
      pageOptions: [20, 50, 100, 200],
      sortBy: "count",
      sortDesc: true,
      dimensionObjs: {
        rating:             { currentPage: 1, perPage: 20},
        subrating:          { currentPage: 1, perPage: 20},
        travel_composition: { currentPage: 1, perPage: 20},
        reviewer_country:   { currentPage: 1, perPage: 20},
        language:           { currentPage: 1, perPage: 20},
        source:             { currentPage: 1, perPage: 20}
      },
      ratingCols: ["benchmark_rating", "rating", "orig_rating"],
      translateKeys: {
        travel_composition: "travel_composition",
        reviewer_country:   "countries",
        language:           "locales"
      }
    };
  },
  computed: {
    countSlug () {
      if (this.type == 'sentiment') return 'mentions_count'
      return 'reviews_count'
    },
    dimensionNames () {
      return {
        rating:             this.$t('ratings.rating_comparison.rating'),
        subrating:          this.$t('ratings.rating_comparison.subrating'),
        travel_composition: this.$t('ratings.rating_comparison.travel_composition'),
        reviewer_country:   this.$t('ratings.rating_comparison.reviewer_country'),
        language:           this.$t('ratings.rating_comparison.language'),
        source:             this.$t('ratings.rating_comparison.source')
      }
    },
    keys () {
      return {
        ranking:          this.$t('ratings.rating_comparison.ranking'),
        kpi:              this.$t('ratings.rating_comparison.kpi'),
        count:            this.$t('ratings.rating_comparison.'+this.countSlug),
        benchmark_count:  this.$t('ratings.rating_comparison.benchmark_count'),
        ratio:            this.$t('ratings.rating_comparison.ratio'),
        benchmark_ratio:  this.$t('ratings.rating_comparison.benchmark_ratio'),
        rating:           this.$t('ratings.rating_comparison.rating'),
        benchmark_rating: this.$t('ratings.rating_comparison.benchmark_rating')
      }
    },
    computedData() {
      Object.keys(this.data).forEach(rt => {
        Object.keys(this.data[rt]).forEach(d => {
          this.data[rt][d].forEach(s => {
            let label = s.kpi;
            if (d != "source" && label) {
              if (/^(rating|subrating)$/.test(d)) {
                if (label == "Food and Beverages") label = "fnb";
                label = this.$t(`ratings.rating_comparison.kpis.${label}`);
              }
              else label = this.$t(`${this.translateKeys[d]}.${Utils.standardizeScoreName(label, d)}`);
            }
            s.label = label;
          });
        });
      });
      return this.data;
    }
  },
  props: ['type', 'filterParams'],
  components: { Multiselect, OleryTable },
  watch: {
    filterParams: function () {
      this.loadListener(this.filterParams);
    },
    type: function () {
      if (Object.keys(this.filterParams).length) this.loadListener(this.filterParams);
    }
  },
  methods: {
    loadListener (params) {
      let p = {
        data:      { ...params.data, source: params.data?.sources },
        benchmark: { ...params.benchmark, source: params.benchmark?.sources },
      }
      this.benchmarkEnabled = params.benchmarkEnabled
      this.load(p)
    },
    columns(dimension, k, isExport) {
      let fields = [
        { [k]: "ranking",          text: this.$t('ratings.rating_comparison.ranking'),          sortable: true, type: "number" },
        { [k]: "label",            text: this.$t('ratings.rating_comparison.kpi'),              sortable: true, type: "text"   },
        { [k]: "count",            text: this.$t('ratings.rating_comparison.'+this.countSlug),  sortable: true, type: "number" },
        { [k]: "benchmark_count",  text: this.$t('ratings.rating_comparison.benchmark_count'),  sortable: true, type: "number" },
        { [k]: "ratio",            text: this.$t('ratings.rating_comparison.ratio'),            sortable: true, type: "text" },
        { [k]: "benchmark_ratio",  text: this.$t('ratings.rating_comparison.benchmark_ratio'),  sortable: true, type: "number" },
        { [k]: isExport ? "orig_rating" : "rating", text: this.$t('ratings.rating_comparison.rating'), sortable: true, type: "number" },
        { [k]: "benchmark_rating", text: this.$t('ratings.rating_comparison.benchmark_rating'), sortable: true, type: "number" },
      ]
      if (!this.benchmarkEnabled) {
        fields.forEach((f,i) => { if (f[k].indexOf('benchmark_') >=0) fields.splice(i, 1) })
      }
      return fields;
    },
    async load (params) {
      Utils.setLoading.bind(this)(true)
      var p, promises = [];
      [ 'data', 'benchmark' ].forEach(block => {
        if (block == 'benchmark' && !this.benchmarkEnabled) return
        Object.keys(this.dimensionNames).forEach(d => {
          if (d == 'subrating' && this.type != 'sentiment') return;

            this[block][this.type][d] = [];

          if (['numerical', 'tes'].includes(this.type)) {
            if (d == 'rating') {
              p = Object.assign({group_by: 'ratings'}, params[block])
              promises.push(axios.get('/v3/destination/ratings', { params: p }).then(this.setRatingFromRatings.bind(null, block)))
            }
            else {
              p = Object.assign({segment: d}, params[block])
              promises.push(axios.get('/v3/destination/stats', { params: p }).then(this.setRatingFromStats.bind(null, block, d)))
            }
          }
          if (['sentiment', 'tes'].includes(this.type)) {
            if (d == 'rating') {
              p = Object.assign({group_by: 'ratings'}, params[block])
              promises.push(axios.get('/v3/destination/sentiment', { params: p }).then(this.setSentiment.bind(null, block, 'rating')))
            }
            else {
              p = params[block]
              if (d != 'subrating') p = Object.assign({segment: d}, p)
              promises.push(axios.get('/v3/destination/sentiment', { params: p }).then(this.setSentiment.bind(null, block, d)))
            }
          }
        });
      })
      await Promise.all(promises);

      if (this.type == 'tes') this.generateTES()

      this.joinData();

      Utils.setLoading.bind(this)(false)
    },

    setRatingFromRatings (block, response) {
      var rows   = []
      if (response.data.data[0]) {
        var total = response.data.data[0].ratings.reduce((partial, r) => {
          return partial + r.review_count}
          , 0);
        response.data.data[0].ratings.forEach((rating,i) => {
          rows.push({
            dimension:     'rating',
            ranking:       i + 1,
            kpi:           rating.topic,
            count:         rating.review_count,
            rating:        Number(Utils.round(rating.value / 10)),
            orig_rating:   rating.value / 10,
            ratio:         Utils.round(rating.review_count / total * 100),
          })
        })
      }
      this[block].numerical['rating'] = rows;
    },

    setRatingFromStats (block, d, response) {
      var rows  = []
      if (response.data.data[0]) {
        var total = response.data.data.reduce((partial, r) => { return partial + r.stats.review_count }, 0);
        response.data.data.forEach((rating,i) => {
          rows.push({
            dimension:   d,
            ranking:     i + 1,
            kpi:         rating[d],
            count:       rating.stats.review_count,
            rating:      Number(Utils.round(rating.stats.overall_rating_average / 10)),
            orig_rating: rating.stats.overall_rating_average / 10,
            ratio:       Utils.round(rating.stats.review_count / total * 100),
          })
        })
      }
      this[block].numerical[d] = rows;
    },

    setSentiment (block, d, response) {
      if (!response.data.data[0]) return

      var rows = [], total
      if (['rating', 'subrating'].includes(d)) {
        total = response.data.data[0].sentiment.reduce((partial, s) => {
          if (d == 'subrating' && s.topic == 'overall') return partial
          return partial + s.opinions_count
        }, 0);
        let i = 1;
        response.data.data[0].sentiment.forEach((s) => {
          if (d == 'subrating' && s.topic == 'overall') return
          rows.push({
            dimension:     d,
            ranking:       i++,
            kpi:           s.topic,
            count:         s.opinions_count,
            reviews_count: s.review_count,
            rating:        Number(Utils.round(s.sentiment_score)),
            orig_rating:   s.sentiment_score,
            ratio:         Utils.round(s.opinions_count / total * 100),
          })
        })
      } else {
        total = response.data.data.reduce((partial, r) => {
          var sentiment = r.sentiment.find(r => r.topic == 'overall')
          return partial + (sentiment ? sentiment.opinions_count || 0 : 0 )
        }, 0);
        response.data.data.forEach((rating,i) => {
          var sentiment = rating.sentiment.find(r => r.topic == 'overall')
          if (!sentiment) return;
          rows.push({
            dimension:     d,
            ranking:       i + 1,
            kpi:           rating[d],
            reviews_count: sentiment.review_count,
            count:         sentiment.opinions_count,
            rating:        Number(Utils.round(sentiment.sentiment_score)),
            orig_rating:   sentiment.sentiment_score,
            ratio:         Utils.round(sentiment.opinions_count / total * 100),
          })
        })
      }
      this[block]['sentiment'][d] = rows;
    },

    generateTES() {
      [ 'data', 'benchmark' ].forEach(block => {
        if (block == 'benchmark' && !this.benchmarkEnabled) return
        Object.keys(this.dimensionObjs).forEach(dimension => {
          if (dimension == 'subrating' && this.type != 'sentiment') return;
          this[block].tes[dimension] = []
          let tes_index = 0
          if (this[block].numerical[dimension]) {
            for(var i = 0; i < this[block].numerical[dimension].length; i++) {
              let numerical = this[block].numerical[dimension][i]
              let r_kpi = numerical.kpi
              var s = this[block].sentiment[dimension].find(n => n.kpi == r_kpi)
              if (!s) continue;

              this[block].tes[dimension][tes_index]        = { dimension: dimension }
              this[block].tes[dimension][tes_index].kpi    = r_kpi

              this[block].tes[dimension][tes_index].count  = Math.max(s.reviews_count, numerical.count)
              this[block].tes[dimension][tes_index].rating = Utils.calcTES(numerical.rating, numerical.count, s.rating, s.reviews_count)
              this[block].tes[dimension][tes_index].ratio  = s.reviews_count > numerical.count ? s.ratio : numerical.ratio
              tes_index++
            }
            this[block].tes[dimension] = this[block].tes[dimension].sort( t => t.ratio )
            this[block].tes[dimension].forEach((t,i) => t['ranking'] = i + 1)
          }
        })
      })
    },

    joinData () {
      if (!this.benchmarkEnabled) return

      var types
      if (this.type == 'tes') types = ['numerical', 'sentiment', 'tes']
      else types = [this.type]

      Object.keys(this.dimensionObjs).forEach(d => {
        if (d == 'subrating' && this.type != 'sentiment') return;

        types.forEach(t => {
          if (this.data[t][d])
            this.data[t][d].forEach(r => {
              var b = this.benchmark[t][d]?.find(b => b.kpi == r.kpi)
              if (!b) return;
              r.benchmark_count  = b.count
              r.benchmark_rating = b.rating
              r.benchmark_ratio  = b.ratio
            })
        })
      })
    },

    series(d) {
      let othersLabel = "others";

      if (!this.computedData[this.type][d]) return {};

      let series = { [othersLabel]: { count: 0, label: this.$t("ratings.rating_comparison.kpis.others") } };
      this.computedData[this.type][d].forEach((v, i) => {
        if (i < 9)
          series[v.kpi] = { count: parseInt(v.count), label: v.label };
        else
          series[othersLabel].count += parseInt(v.count);
      })
      if (series[othersLabel]?.count == 0) delete series[othersLabel];
      return series;
    },
    chartOptions (d) {
      return {
        labels: this.translatedSeries(d),
        colors: ["#a3cae0", "#e74c5e", "#47bd9a", "#964b00", "#4090cb", "#f9d570", "#2a8251", "#f408a9", "#26e6ee", "#ffa500", "#0841ab", "#02610d"],
        tooltip: { y: { formatter: val => Utils.formatNumber(val) } },
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
          verticalAlign: 'middle',
          floating: false,
          fontSize: '14px',
          offsetX: 0,
          offsetY: -10
        },
        responsive: [{
          breakpoint: 600,
          options: {
            chart: { height: 240 },
            legend: { show: false },
          }
        }],
        dataLabels: {
          formatter: val => Utils.formatNumber(Utils.round(val)) + "%"
        }
      }
    },
    translatedSeries(d) {
      return Object.values(this.series(d)).map(s => s.label);
    },
    seriesValues(d) {
      return Object.values(this.series(d)).map(s => s.count).filter(n => n);
    },
    fileName(dimension) {
      return this.$t("general.export_file_name", {
        title: `${this.$t('ratings.rating_comparison.title')}-${this.$t('ratings.rating_comparison.'+dimension)}`,
        sd: this.filterParams.data.start_date,
        ed: this.filterParams.data.end_date
      });
    },
    formatNumber(val) {
      return Utils.formatNumber(val);
    },
    tooltip(d) {
      const vType = this.$t(`ratings.rating_comparison.${this.chartType}`).toLowerCase();
      if (d == "rating") return this.$t("ratings.rating_comparison.rating_tooltip", { vType });
      return this.$t("ratings.rating_comparison.others_tooltip", { vType });
    }
  }
};
</script>

<template>
  <div style='min-height: 200px;' class="card" :class="{'loading': loading}">
    <div class="card-body">
      <div class="navbar-header shadow-none row gx-0 mb-3 p-0">
        <div class="d-flex col-12 col-md-7">
          <h4 class="card-title">{{ $t('ratings.rating_comparison.title') }}</h4>
          <span class='ms-2' v-b-tooltip.hover :title="$t('ratings.rating_comparison.help')">
            <i class='mdi mdi-help-circle'></i>
          </span>
        </div>
        <div class="showPie d-flex align-items-center col-12 col-md-5 col-xl-4">
          <label class="form-label mb-1 text-nowrap">{{ $t("daily_operations.analytics.chart_type") }}</label>
          <multiselect class="ms-2" :multiple="false" v-model="chartType" :options="chartTypeOptions" :showLabels="false" :custom-label="l => $t(`ratings.rating_comparison.${l}`)" :allow-empty="false" style="min-width: 200px !important;"></multiselect>
        </div>
      </div>

      <template v-if="chartType == 'table' && show">
        <template v-for='obj, dimension in dimensionNames' :key='dimension'>
          <!-- Table -->
          <OleryTable
            v-if="computedData[type][dimension] && (dimension != 'subrating' || type == 'sentiment')"
            :title="dimensionNames[dimension]"
            titleClass="m-0 font-size-21"
            :tooltip="tooltip(dimension)"
            :btnText="$t('general.export_excel')"
            btnClass="btn btn-secondary text-nowrap"
            :ratingCols="ratingCols"
            :exportCols="columns(dimension, 'value', true)"
            :fileName="fileName(dimension)"
            :dataTable="{
              tableHeaderClass:    'thead-light',
              items:               computedData[type][dimension],
              headers:             columns(dimension, 'value', false),
              headerTextDirection: 'center',
              bodyTextDirection:   'center',
              sortBy:              sortBy,
              sortType:            'desc',
              pagination:          true
            }"
          >
            <template #item-ratio="{ item }">
              {{ formatNumber(Number(item.ratio)) + "%" }}
            </template>

            <template #item-benchmark_ratio="{ item }">
              {{ formatNumber(Number(item.benchmark_ratio)) + "%" }}
            </template>
          </OleryTable>
        </template>
      </template>

      <template v-if="!loading && chartType == 'pie'">
        <div class="row">
          <div class="col-sm-6 col-md-4" v-for='label, dimension in dimensionNames' :key='dimension'>
            <div v-if="dimension != 'subrating' || type == 'sentiment'">
              <div class="d-flex align-items-center">
                <label>{{ label }}</label>
                <span class="ms-2" v-b-tooltip.hover :title="tooltip(dimension)">
                  <i class="mdi mdi-help-circle"></i>
                </span>
              </div>

              <apexchart
                v-if="seriesValues(dimension).length"
                class="apex-charts"
                height="300"
                type="pie"
                dir="ltr"
                :series="seriesValues(dimension)"
                :options="chartOptions(dimension)"
              ></apexchart>
              <span v-else><br>{{ $t("general.no_data") }}<br></span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
