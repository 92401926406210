<script>
import PageHeader  from "@/components/page-header";
import Filters     from "@/components/filter";
import DonutKPI    from "@/components/donut-kpi";
import TopSegments from "@/components/top-segments-chart";

import Utils     from "@/js/utils";
import axios     from "axios";
import dayjs     from "dayjs";
import DataUtils from "@/js/data-utils";

export default {
  data() {
    return {
      loading: true,
			filterCols: ["time_period", "rating_type"],
      timePeriods: { month: { default: false }, quarter: { default: true }, year: { default: false } },
			timePeriod: "quarter",
			ratingType: "numerical",
      startDate: null,
      contextNames: { data: "main", benchmark: "benchmark" },
      filterParams: {},
      scores: ["overall", "sustainable_travel", "sanitary_safety", "health_precautions"],
      ratings: ["fnb", "service", "cleanliness", "facilities", "value", "location", "ambience"],
      kpis: {
        scores:       [],
        verticals:    ["accommodation", "attraction", "restaurant"],
        ratings:      [],
        top_segments: ["top_regions", "top_cities", "top_nationalities", "top_compositions"]
      },
    };
  },
  components: { PageHeader, Filters, DonutKPI, TopSegments },
  watch: {
    filterParams: async function () {
      if (this.contract) {
        await this.loadTopics();
        this.loadListener(this.filterParams);
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["user/user"]() || {};
    },
    filterTitle() {
      let filters        = this.contract?.filters
      let places = {}
      let levels = ["city", "regions", "country", "continent"];
      if (!filters || !filters["main"]) return "";

      Object.values(this.contextNames).map(block => {
        if (filters[block]) {
          ["*", "executive_kpis"].forEach(context => {
            if (filters[block][context])
              places[block] = levels.map(l => filters[block][context][l]).filter(n => n).join(" / ")
          })
        }
      })
      return places
    },
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    settings() {
      return {
        context:    "dashboard",
        ratingType: this.ratingType,
        redirect:   true,
        segment:    "ratings",
        show_trend: true,
        subscription_ids: this.contract?.id,
        period:           this.timePeriod,
        start_date:       this.startDate,
        page_context:     this.$route.name
      };
    }
  },
  methods: {
    ...DataUtils,
    async loadListener(params) {
      this.loading = true;
      this.ratingType = params.data.rating_type || "tes";
      this.timePeriod = Array.isArray(params.data.time_period) ? params.data.time_period[0] : params.data.time_period || "quarter";
      this.startDate  = this.formatDate(dayjs().add(-1, this.timePeriod).startOf(this.timePeriod).toDate());
      await this.$nextTick();
      this.loading = false;
		},
    formatDate(d) {
      return dayjs(d).format("YYYY-MM-DD");
    },
    async loadTopics() {
      return this.$store.dispatch("topics/fetch").then(topics => {
        const byParent = this.topicsByParent(topics);
        const scores   = (byParent.find(p => p.key == "scores")?.children || []).map(s => s.topic);
        this.kpis.scores  = this.scores.filter(s => scores.includes(s));
        this.kpis.ratings = this.ratings.filter(r => byParent.find(b => b.key == r));
        this.kpis.scores.unshift("overall");
      });
    },
    async deleteCache() {
      Utils.setLoading.bind(this)(true);
      let promises = [];

      Object.keys(this.kpis).forEach(section => {
        this.kpis[section].forEach(kpi => {
          ["data", "benchmark"].forEach(block => {
            if (block == "benchmark" && kpi.startsWith("top_")) return;

            promises.push(axios.delete("v3/cache", { params: {
              component: "executive-kpis",
              params: {
                subscription_ids: this.contract.id,
                start_date:       this.startDate,
                period:           this.timePeriod,
                segments:         kpi,
                sub_context:      this.contextNames[block],
              }
            }}));
          });
        });
      });

      await Promise.all(promises);
      Utils.setLoading.bind(this)(false);
    }
	},
};
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('executive_kpis.title')">
      <template v-slot:subtitle v-if="contract && contract.filters">
        <span class="float-end mt-2 mt-sm-0">
          <h5 class="d-inline me-3 text-dark">{{ $t("general.data_scope") }}:</h5>
          <strong>{{ $t("general.destination") }}:</strong>
          {{ filterTitle.main }}
          &nbsp; — &nbsp;
          <strong>{{ $t("general.benchmark") }}:</strong>
          {{ filterTitle.benchmark }}
        </span>
      </template>
    </PageHeader>

    <div class="row">
      <div class="col-lg-12">
        <Filters @filter:created="saveFilterParams" @filter:update="saveFilterParams" @filter:delete_cache="deleteCache" :cols="filterCols" :timePeriods="timePeriods" />

        <div class="row" v-if="contract && contract.id">
          <div class="col-12" v-for="kpisArray, section in kpis" :key="section">
            <h3 class="mb-2 mt-4">{{ $t(`executive_kpis.titles.${section}`) }}</h3>

            <div class="row" v-if="!loading">
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-6" v-for="kpi in kpisArray" :key="kpi">
                <TopSegments v-if="section == 'top_segments'" :settings="{ ...settings, segmentOption: kpi }" />
                <DonutKPI v-else :settings="{ ...settings, segmentOption: kpi }" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
</template>
