import axios from "axios";

export const state = { data: {}, dataPromises: {} };

export const getters = {};

export const mutations = {
  saveData(state, { data, params }) {
    state.data[params] = data;
  },
  saveDataPromise(state, { promise, params }) {
    state.dataPromises[params] = promise;
  },
};

export const actions = {
  async analytics({ commit }, params) {
    let p = window.oly.utils.slice(params, ["start_date", "end_date", "subscription_ids", "company_ids", "group_ids", "trends", "finished", "survey_id", "has_sentiment", "language", "question_type", "sources"]);
    let stringParams = JSON.stringify({ ...p, ctx: "analytics" });

    if (state.data[stringParams])         return state.data[stringParams];
    if (state.dataPromises[stringParams]) return state.dataPromises[stringParams];

    let promise = axios.get("/v3/feedback/analytics/kpis", { params: { ...p } }).then(res => {
      commit("saveData", { data: res.data.data, params: stringParams });
      return res.data.data;
    });

    commit("saveDataPromise", { promise, params: stringParams });

    return promise;
  },
  async per_question({ commit }, params) {
    let p = window.oly.utils.slice(params, ["start_date", "end_date", "subscription_ids", "company_ids", "group_ids", "trends", "finished", "survey_id", "language", "has_sentiment", "question_type"])
    let stringParams = JSON.stringify({ ...p, ctx: "per_question" });

    if (state.data[stringParams])         return state.data[stringParams];
    if (state.dataPromises[stringParams]) return state.dataPromises[stringParams];

    let promise = axios.get("/v3/feedback/analytics/per_question", { params: { ...p } }).then(res => {
      commit("saveData", { data: res.data.data, params: stringParams });
      return res.data.data;
    });

    commit("saveDataPromise", { promise, params: stringParams });

    return promise;
  }
}
