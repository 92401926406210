<script>
import Utils         from "@/js/utils";
import FeedbackUtils from "@/js/feedback-utils";
import NotLoaded     from "@/components/not-loaded";
import Loading       from "@/components/loading";

export default {
  data() {
    return {
      loading:     true,
      error:       false,
      tableData:   [],
      ratingTypes: ["numerical", "sentiment", "tes"],
      paramsCache: {},
      surveys:     {}
    }
  },
  props: ["start_date", "end_date", "group_id", "company_id", "settings", "company_name", "formatNumbers"],
  components: { NotLoaded, Loading },
  created() {
    this.settings.labels ??= {};
  },
  methods: {
    ...FeedbackUtils,
    async getData() {
      await this.loadSurveys(this.settings.surveys);
      this.paramsCache = Utils.deepClone(this.params);
      let promises = [];
      this.loading = true;
      this.error = false;

      // main data
      promises.push(this.$store.dispatch("feedback/per_question", this.params).then(data => {
        this.setData(data);
        this.setTrends(this.params);
			}).catch(() => this.error = true));

      if (this.settings.use_custom_benchmark) {
        // benchmark data
        let benchmark = Utils.deepClone(this.params), id;
        delete benchmark.company_ids;
        delete benchmark.group_ids;
        if (this.settings.benchmark_based_on == "companies") {
          id = this.settings.bench_company_ids;
          benchmark.company_ids = Array.isArray(id) ? id[0] : id;
        } else {
          id = this.settings.bench_group_ids;
          benchmark.group_ids = Array.isArray(id) ? id[0] : id;
        }

        promises.push(this.$store.dispatch("feedback/per_question", { ...benchmark }).then(data => {
          this.setBenchmarkData(data);
        }).catch(() => this.error = true));
      }

      await Promise.all(promises);
      this.loading = false;
    },
    setData(data) {
      this.tableData = Utils.deepClone(data);
      this.tableData = this.tableData.filter(a => this.settings.show_questions_without_answers ? true : a[this.rt].answers_count);
      const locale   = this.$i18n.locale;

      this.tableData.forEach(r => {
        r.question_title = this.replaceCompanyName(r.question.title[locale] || Object.values(r.question.title)[0], this.company_name);
        r.question_title = this.tokenizeVariables(r.question_title);
        r.id             = r.question.id;
        this.ratingTypes.forEach(rt => {
          r.answers_count = { ...r.answers_count, [rt]: r[rt].answers_count };
          r[rt] = Utils.round(r[rt].value ? r[rt].value / 10 : null);
        });
      });
    },
    setBenchmarkData(data) {
      const benchmarkData = Utils.deepClone(data);
      this.tableData.forEach(r => {
        this.ratingTypes.forEach(rt => {
          const val = benchmarkData.find(b => b.question.id == r.question.id)?.[rt]?.value;
          r.benchmark = { ...r.benchmark, [rt]: Utils.round(val ? val / 10 : null) };
        });
      });
    },
    setTrends(params) {
      if (!this.settings.show_trend) return;
      const tp = Utils.timePeriods.previous(this.start_date, this.end_date, this.settings.trend_range);
      let p = { ...params, ...tp };
      this.$store.dispatch("feedback/per_question", p).then(data => {
        this.tableData.forEach(row => this.calculateTrend(row, data));
      }).catch(() => this.error = true);
    },
    calculateTrend(row, data) {
      this.ratingTypes.forEach(rt => {
        let curr = row[rt];
        let prev = data.find(d => d.question.id == row.question.id)?.[rt]?.value;
        prev = Utils.round(prev ? prev / 10 : null);
        row.trends = { ...row.trends, [rt]: Utils.calcTrend(curr, prev) };
      });
    },
    formatNumber(val, field) {
      if (!this.formatNumbers || field == "question_title") return val;
      return Utils.formatNumber(val);
    },
    trendColor(trend) {
      if (trend > 0) return 'trend-green'
      if (trend < 0) return 'trend-red'
      return 'trend-grey'
    },
    async loadSurveys(ids = []) {
      let promises = [];
      ids.forEach(id => {
        promises.push(this.$store.dispatch("survey/fetch", { survey_id: id }).then(survey => {
          this.surveys[id] = survey.question_ids;
        }).catch(() => this.error = true));
      });
      await Promise.all(promises);
    }
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    params() {
      return {
        start_date:    this.start_date,
				end_date:      this.end_date,
				company_ids:   this.company_id,
        group_ids:     this.group_id,
        finished:      this.settings.dashboard ? this.settings.finished : true,
        survey_id:     this.settings.surveys,
        ...(this.settings.dashboard && {
          language:         this.settings.response_language,
          has_sentiment:    this.settings.has_sentiment,
          question_type:    this.settings.question_type,
          subscription_ids: this.contract?.id,
        })
      };
    },
    rt() {
      return this.settings.segment.split("_")[1];
    },
    cols() {
      let res = [
        { field: "question_title", title: this.$t("reports.table.question")          },
        { field: "answers_count",  title: this.$t("reports.table.number_of_answers") },
        { field: "value",          title: this.$t("reports.table.average_rating")    }
      ];
      if (this.settings.show_trend) res.push({ field: "previous_period", title: this.$t("reports.table.previous_period") });
      if (this.settings.use_custom_benchmark) res.push({ field: "benchmark_avg_rating", title: this.$t("reports.table.benchmark_avg_rating") });
      return res;
    },
    dataByAnalytics() {
      return this.tableData.map(r => ({
          ...r,
          value:                r[this.rt],
          answers_count:        r.answers_count?.[this.rt],
          benchmark_avg_rating: r.benchmark?.[this.rt],
          previous_period:      r.trends?.[this.rt]
        })).filter(r => r.value && r.value != "-");
    },
    dataBySurveyOrder() {
      const res = [];
      Object.keys(this.surveys).slice().reverse().map(sid => {
        const questionsOrder = this.surveys[sid].slice().reverse();
        questionsOrder.forEach(qid => {
          const data = this.tableData.find(d => d.question.id == qid);
          if (data) {
            res.push({
              ...data,
              value:                data[this.rt],
              answers_count:        data.answers_count?.[this.rt],
              benchmark_avg_rating: data.benchmark?.[this.rt],
              previous_period:      data.trends?.[this.rt]
            });
          }
        })
      });
      return res;
    },
    computedData() {
      if (!Object.keys(this.surveys).length) return this.dataByAnalytics;
      return this.dataBySurveyOrder;
    },
    filteredRows() {
      let data = [...new Map(this.computedData.map(s => [s.id, s])).values()];

      if (this.settings.order_by) {
        const field = this.settings.order_by;
        if (field != "question_title") {
          data = data.sort((a, b) => {
            if (a[field] == "-") return -1;
            if (b[field] == "-") return  1;
            return a[field] - b[field];
          });
        }
      }
      if (this.settings.order == "desc") data = data.reverse();

      if (this.settings.limit) data = data.slice(0, this.settings.limit);

      return data;
    }
  },
  watch: {
    $props: {
      handler: function () {
        const change = Object.keys(this.params).reduce((res, key) => {
          if (String(this.paramsCache[key]) != String(this.params[key])) return res + 1;
          return res;
        }, 0);
        if (change) this.getData();
      },
      immediate: true,
      deep: true
    }
  }
}

</script>
<template>
  <div>
    <table class="table table-bordered table-sm" v-if="tableData && tableData.length && !loading">
      <thead>
        <tr class="text-center">
          <th v-for="(col, i) in cols" :key="col" :class="{ 'text-left': i == 0 }" class="align-middle">
            {{ settings?.labels?.[col.field] || col.title }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr class="text-center" v-for="(row, i) in filteredRows" :key="`row_${i}`">
          <td v-for="(col, j) in cols" :key="`row_${i}_col_${j}`" :class="{ 'text-left': j == 0 }">
            <div
              v-if="col.field == 'previous_period' && row.previous_period != '-'"
              :class="trendColor(row.previous_period)"
            >
              {{ formatNumber(row.previous_period, col.field) }}%
              <i class="arrow-trend" :class="{ 'mdi mdi-arrow-up': row.previous_period > 0, 'mdi mdi-arrow-down': row.previous_period < 0 }"></i>
            </div>
            <div v-else v-html="formatNumber(row[col.field], col.field)"></div>
          </td>
        </tr>
      </tbody>
    </table>

    <Loading v-else-if="loading" />
    <NotLoaded :data="tableData" :error="error" v-else-if="!loading" />
  </div>
</template>
