<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      tableData: {
        current: [],
        previous: []
      },
      cols: [
        { field: "name",     label: "Property Name" },
        { field: "subfield", label: "" }
      ],
      fields: [
        { field: "reviews",             label: "Number of Reviews" },
        { field: "reviews_var",         label: "Qty. Variance to Last Month (%)" },
        { field: "average_rating",      label: "Avg. Review Score" },
        { field: "average_rating_var",  label: "Score Variance to Last Month (%)" },
      ],
      rows: []
    };
  },

  components: { ExportExcel },
  props: ['title', 'group', 'identifier', 'release_date', 'source', 'index', 'offset', 'mtd', 'sourceHash', 'download'],

  mounted() {
    window.addEventListener("OTA-summary-update", this.loadListener)
  },
  beforeUnmount() {
    window.removeEventListener("OTA-summary-update", this.loadListener)
  },
  created() {
    if (this.index == 1) {
      axios.get("/v3/query/db/_", { params: {
        group_id:     this.group.id,
        identifier:   this.identifier,
        release_date: this.release_date,
        source: this.source,
        segment: "source",
        months_ago: 1,
        prev_months: 1,
        compset: false,
        format:  "hash",
        mtd: this.mtd
      }}).then(response => {
        ["current", "previous"].forEach(period => {
          this.tableData[period] = response.data.data.filter(d => d.period == period);
        });
        this.formatData();
      });
    }
  },
  methods: {
    loadListener(data) {
      this.rows = window.reports.rowsOffset(this, data.detail)
      this.cols = data.detail.cols;
    },

    formatData() {
      let seenCompany = [];
      let sources = this.source.concat("others");
      sources.forEach(source => {
        this.cols.push({ field: this.sourceHash[source], label: this.sourceHash[source] });
      });
      this.tableData.current.forEach(data => {
        if (!seenCompany.includes(data.name)) {
          this.rows.push({ name: data.name, subfield: "reviews" });
          this.rows.push({ name: "", subfield: "reviews_var" });
          this.rows.push({ name: "", subfield: "average_rating" });
          this.rows.push({ name: "", subfield: "average_rating_var" });
          seenCompany.push(data.name);
        }
      });

      let currentCompany = "";
      this.rows.forEach((row, index) => {
        let element = {};
        if (row.name.length > 0) {
          currentCompany = row.name;
          element.name = currentCompany;
        }

        let company = this.tableData.current?.find(e => e.name == currentCompany);
        this.cols.slice(1).forEach(col => {
          if(col.field) {
            let value = company.segments?.find(c => c.segment == col.field);

            if (!value && value != 0) element[col.field] = "-";
            else if (/average_rating_var|reviews_var/.test(row.subfield)) {
              let prevValue = this.tableData.previous?.find(e => e.name == currentCompany)?.segments?.find(c => c.segment == col.field);
              let field = row.subfield.slice(0, -4);
              if (!prevValue?.[field] || !value?.[field]) element[col.field] = "-";
              else element[col.field] = Utils.round(100*(value[field] - prevValue[field]) / prevValue[field], 2) + "%";
            }
            else element[col.field] = value[row.subfield];
            element["subfield"] = this.fields?.find(f => f.field == row.subfield)?.label;
          }
        });
        this.rows[index] = element;
      });
      this.$forceUpdate();
      const event = new CustomEvent("OTA-summary-update", { detail: { rows: this.rows, cols: this.cols } });
      window.dispatchEvent(event);
      this.rows = this.rows.slice(0, this.offset);
    },
    compare(field, value) {
      if (/Variance/.test(field)) return parseFloat(value) < 0;
      return false;
    }
  },
  computed: {
    computedTitle() {
      let title = this.title;
      if (this.index && this.index != 1) return title += " (Continued)";
      return title;
    }
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${computedTitle}.xls`" :title="computedTitle">
      <table border="1" class="table table-striped-quarter table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th class="property-name-column">{{ cols[0].label }}</th>
            <th>{{ cols[1].label }}</th>
            <th v-for="col in cols.slice(2)" :key="col.field" style="width: 110px;">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, indexRow) in rows" :key="indexRow">
            <td class="property-name-column">{{ row[cols[0].field] }}</td>
            <template v-for="(col, indexCol) in cols.slice(1)" :key="col.field + indexCol">
              <td :class="{ 'text-danger': compare(row.subfield, row[col.field]) }">{{ row[col.field] }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
