<script>
import PageHeader from "@/components/page-header";
import Utils from "@/js/utils";
import axios from "axios";
import Swal  from "sweetalert2";

export default {
  data() {
    return {
      loading: true,
      notifications: [],
      user: {},
      checkboxesCols: ["daily_summary", "weekly_update", "note_alert"],
      sortBy: "company",
    };
  },
  components: { PageHeader },
  async created() {
    this.user = await this.$store.dispatch("user/fetch");
    this.load();
  },
  methods: {
    async load() {
      Utils.setLoading.bind(this)(true);
      let response = await axios.get(`/v2/user-settings/${this.user.id}/notifications`);
      this.notifications = response.data.data;
      this.notifications = this.notifications.map(n => ({ ...n, ...n.notifications }));
      Utils.setLoading.bind(this)(false);
    },
    async saveSettings() {
      Utils.setLoading.bind(this)(true);
      let params = { object: {} };
      this.notifications.forEach(notification => {
        this.checkboxesCols.forEach(col => {
          if (notification[col] && !params.object[col]) params.object[col] = [];
          if (notification[col]) params.object[col].push(notification.company_id);
        });
      });

      try {
        await axios.put(`/v2/user-settings/${this.user.id}/notifications`, params);
        this.success();
      } catch (err) { this.error(err); }
      Utils.setLoading.bind(this)(false);
    },
    columns(k) {
      let fields = [
        { [k]: "company_name",  text: this.$t("email_notifications.company"),       sortable: true  },
        { [k]: "daily_summary", text: this.$t("email_notifications.daily_summary"), sortable: false },
        { [k]: "weekly_update", text: this.$t("email_notifications.weekly_update"), sortable: false },
        { [k]: "note_alert",    text: this.$t("email_notifications.note_alert"),    sortable: false },
      ]
      return fields;
    },
    updateValue(value, col, item) {
      if (!item) {  //check/uncheck all the column
        this.notifications.forEach(notification => notification[col] = value);
      } else {
        let notification = this.notifications.find(n => n.company_id == item.company_id);
        notification[col] = value;
      }
    },
    label(value) {
      if (!value) return this.$t("email_notifications.disable");
      return this.$t("email_notifications.enable");
    },
    checked(col) {
      //check if the entire col is selected
      return this.notifications.reduce((res, curr) => {
        if (!curr[col]) res = false;
        return res;
      }, true);
    },
    success() {
			Swal.fire(this.$t("general.success"), this.$t("email_notifications.save_success"), "success");
		},
    error(error) {
      Swal.fire(this.$t("general.error"), this.$t("general.contact_support") + JSON.stringify(error.response?.data), "error");
      Utils.setLoading.bind(this)(false);
    },
  }
}
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('email_notifications.title')" />

    <div class="row">
      <div class="col-xl-12">
        <div style='min-height: 200px;' class="card" :class="{'loading': loading}">
          <div class="card-body">
            <data-table
              v-if="!loading"
              class="thead-light"
              :items="notifications"
              :rows-per-page="notifications.length"
              :headers="columns('value')"
              header-text-direction="center"
              body-text-direction="center"
              :sort-by="sortBy"
              sort-type="desc"
              hide-footer
            >
              <!-- header -->
              <template #header-company_name>
                <div class="text-start w-100 ps-2">{{ $t("email_notifications.company") }}</div>
              </template>

              <template v-for="col in checkboxesCols" #[`header-${col}`]="header" :key="col">
                <b-form-checkbox :id="`header_${col}`" :checked="checked(col)" @change="updateValue($event, col)">
                  <label class="form-label m-0" :for="`header_${col}`">{{ header.text }}</label>
                </b-form-checkbox>
              </template>

              <!-- body -->
              <template #item-company_name="item">
                <div class="text-start w-100 ps-2">{{ item.company_name }}</div>
              </template>

              <template v-for="col in checkboxesCols" #[`item-${col}`]="item" :key="col">
                <b-form-checkbox v-model="item[col]" :id="`${col}_${item.company_name}`" @change="updateValue($event, col, item)">
                  <label class="form-label" :for="`${col}_${item.company_name}`">{{ label(item[col]) }}</label>
                </b-form-checkbox>
              </template>
            </data-table>

            <div class="row mt-3">
              <div class="col">
                <button class="btn btn-primary float-end" @click="saveSettings">{{ $t("email_notifications.save") }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>