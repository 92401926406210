<script>
import { defineComponent, onMounted, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';

export default defineComponent({
  name: 'PlanSelection',
  props: {
    handleAcceptedTerms: {
      type: Function,
      required: true,
    },
    handleSelectedPlans: {
      type: Function,
      required: true,
    },
    handleSelectedProduct: {
      type: Function,
      required: true,
    },
    handleSelectedProductOpts: {
      type: Function,
      required: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const subscriptionProductSlug = ref('');
    const plans = ref([]);
    const loading = ref(true);
    const selectedPlans = ref([]);
    const selectedProductOptions = ref([]);
    const subscriptionProduct = ref({});

    const getSubscriptionPlans = async () => {
      try {
        const response = await axios.get('/v3/subscription_products/', { params: { slug: subscriptionProductSlug.value }});
        subscriptionProduct.value = response.data[0];
        props.handleSelectedProduct(subscriptionProduct.value);
        plans.value = response.data[0].specs.features.list;

        if (plans.value.length === 1) {
          selectedPlans.value = [plans.value[0].slug];
        }
        setProductOpts();
        loading.value = false;
      } catch (error) {
        props.handleSelectedProduct({});
        loading.value = false;
      }
    };
    
    const termsAccepted = ref(false);
    onMounted(async () => {
      subscriptionProductSlug.value = route.query.sps;
      await getSubscriptionPlans();
    });

    const setProductOpts = () => {
      if(subscriptionProduct.value.specs.options && subscriptionProduct.value.specs.options.list.length > 0) {
        const is_full_data = subscriptionProduct.value.specs.options.list.find(option => option.name === 'full_data');
        if(is_full_data) {
          selectedProductOptions.value.push({
            name: 'full_data', label: 'Full Data', type: 'checkbox', required: true, multiple: false, selected_value: true
          });
        }

        subscriptionProduct.value.specs.options.list.forEach(option => {
          if(option.name === 'company_id') {
            selectedProductOptions.value.push({
              name: 'company_id', label: 'Company ID', type: 'text', required: true, multiple: false, selected_value: [route.query.spo]
            });
          }
        })
        props.handleSelectedProductOpts(selectedProductOptions.value);
      }
    }

    const total = computed(() => {
      return selectedPlans.value.reduce((acc, slug) => {
        const plan = plans.value.find(plan => plan.slug === slug);
        return acc + (plan ? plan.price : 0);
      }, 0);
    });

    const discounts = computed(() => {
      const discountList = subscriptionProduct.value.specs.discounts.list;
      return discountList.map(discount => {
        const initialPrice = total.value;
        const finalPrice = initialPrice * (100 - discount.percent) / 100;
        return {
          ...discount,
          initialPrice,
          finalPrice,
        };
      });
    });

    const selectedPlanDetails = computed(() => {
      const selectedDetails = plans.value.filter(plan => selectedPlans.value.includes(plan.slug));
      props.handleSelectedPlans(selectedDetails);
      return selectedDetails;
    });

    return {
      plans,
      loading,
      selectedPlans,
      selectedPlanDetails,
      total,
      discounts,
      termsAccepted,
      subscriptionProduct,
    };
  },
});
</script>

<template>
  <b-container v-if="!loading">
    <b-row class="d-flex justify-content-center text-center">
      <b-col cols="auto">
        <h1>{{ subscriptionProduct.name }}</h1>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center text-center">
      <b-col cols="auto">
        <span>{{ $t('subscription_products_descriptions.' + subscriptionProduct.slug) }}</span>
      </b-col>
    </b-row>
    <b-row v-if="plans.length > 1">
      <b-col v-for="feature in plans" :key="feature.slug" class="mb-4" cols='col-xs-12 col-md-4'>
        <b-card class="d-flex justify-content-center text-center">
          <b-card-title>{{ feature.label }}</b-card-title>
          <b-card-text>{{ feature.price }} {{ $t('signup.signup_to_paid.euro_month') }}</b-card-text>
          <b-form-checkbox
            :name="feature.slug"
            :value="feature.slug"
            switch
            v-model="selectedPlans"
          >Select</b-form-checkbox>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <b-card class="d-flex justify-content-center text-center">
          <b-card-title>{{ plans[0].label }}</b-card-title>
          <b-card-text>{{ plans[0].price }} {{ $t('signup.signup_to_paid.euro_month') }}</b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center text-center mt-4">
      <h2>{{ $t('signup.signup_to_paid.summary') }}</h2>
      <b-col cols="auto" class="d-flex justify-content-center text-center">
        <b-card>
          <b-card-text>
            <div v-for="feature in selectedPlanDetails" :key="feature.slug">
              {{ feature.label }}: {{ feature.price }} {{ $t('signup.signup_to_paid.euro_month') }}
            </div>
            <div>{{ $t('signup.signup_to_paid.total') }}: {{ total }} {{ $t('signup.signup_to_paid.euro_month') }}</div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center text-center mt-4">
      <b-col cols="auto" class="d-flex justify-content-center text-center">
        <b-card>
          <b-card-title>{{$t('signup.signup_to_paid.discounts_applied')}}</b-card-title>
          <b-card-text>
            <div v-for="discount in discounts" :key="discount.period.label">
              {{ discount.period.label }}: {{ discount.percent }}% off - 
              <span class="initial-price">{{ discount.initialPrice.toFixed(2) }}</span> 
              <span class="final-price">&nbsp;{{ discount.finalPrice.toFixed(2) }}&nbsp;</span>
              <span>{{ $t('signup.signup_to_paid.euro_month') }}</span>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col class="d-flex justify-content-center text-center">
        <object
          v-if="subscriptionProduct.toc_pdf_url"
          :data="subscriptionProduct.toc_pdf_url"
          type="application/pdf"
          width="100%"
          height="500px"
        >
          <p><a :href="subscriptionProduct.toc_pdf_url">{{$t('signup.signup_to_paid.terms_and_conditions_pdf')}}.</a></p>
        </object>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col class="d-flex justify-content-center text-center">
        <b-form-checkbox v-model="termsAccepted" @change="handleAcceptedTerms(termsAccepted)">{{ $t('signup.signup_to_paid.accept_terms') }}</b-form-checkbox>
      </b-col>
    </b-row>
  </b-container>
  <b-container v-else>
    <b-row>
      <b-col>
        <b-spinner label="Loading..."></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>
.initial-price {
  text-decoration: line-through;
  color: red;
}
.final-price {
  font-weight: 500;
  font-size: 1.1em;
}
</style>
