<script>
require('@/js/reports')
import Replab from '@/mixins/reports/replab'

import Page from "@/components/reports/replab/page";
import PageHeader from "@/components/reports/replab/page-header";
import PageSummary from "@/components/reports/replab/month-end/page-summary";
import PageThankYou from "@/components/reports/replab/page-thank-you";

import TableSummary from "@/components/reports/replab/month-end/tables/table-summary";
import TableOTA from "@/components/reports/replab/month-end/tables/table-ota";
import TableReviewResponse from "@/components/reports/replab/month-end/tables/table-review-response";
import TablePropertyDetails from "@/components/reports/replab/month-end/tables/table-property-details";
import TableTrend from "@/components/reports/replab/month-end/tables/table-trend-current-month";
import TableReviewScore from "@/components/reports/replab/month-end/tables/table-review-score";
import TableSourceReviewSummary from "@/components/reports/replab/month-end/tables/table-source-review-summary";
import TableTripAdvisorPerformanceComparison from "@/components/reports/replab/month-end/tables/table-tripadvisor-performance-comparison";
import TableTopTrendTopicsByChannel from "@/components/reports/replab/month-end/tables/table-top-trend-topics-by-channel";
import TableOverallTrends from "@/components/reports/replab/month-end/tables/table-overall-trends";
import TableOverallReviewMetrics from "@/components/reports/replab/month-end/tables/table-overall-review-metrics";
import TableSentimentOverTime from "@/components/reports/replab/month-end/tables/table-sentiment-over-time";
import TableGuestData from "@/components/reports/replab/month-end/tables/table-guest-data";
import TableSources from "@/components/reports/replab/month-end/tables/table-sources";

import ColumnChartTripAdvisorCompSet from "@/components/reports/replab/month-end/charts/chart-column-tripadvisor-compset";
import PieChart from "@/components/reports/replab/month-end/charts/chart-pie";
import ColumnChartNumberReviews from "@/components/reports/replab/month-end/charts/chart-column-number-reviews";
import StackedChartReviewDistribution from "@/components/reports/replab/month-end/charts/chart-review-distribution-traveler-type";
import ColumnChartReviewDistribution from "@/components/reports/replab/month-end/charts/chart-column-review-distribution-traveler-type";
import LineChartTripAdvisorWeeklyRank from "@/components/reports/replab/month-end/charts/chart-line-tripadvisor-weekly-rank";
import PieChartReviewDistribution from "@/components/reports/replab/month-end/charts/chart-pie-review-distribution";
import PieChartReviewLandscape from "@/components/reports/replab/month-end/charts/chart-pie-review-landscape";
import PieChartReviewLandscapeCompSet from "@/components/reports/replab/month-end/charts/chart-pie-review-landscape-compset";
import ColumnChartAvgReviewPropertyDetail from "@/components/reports/replab/month-end/charts/chart-column-avg-review-property-detail";
import PulseScoreByPropertyDetail from "@/components/reports/replab/month-end/charts/pulse-score-by-property-detail";
import ColumnChartAvgReviewScoreByPropertyDetailTA from "@/components/reports/replab/month-end/charts/chart-column-avg-review-score-by-property-detail-TA";
import ColumnChartDayByDay from "@/components/reports/replab/month-end/charts/chart-column-day-by-day";

export default {
  mixins: [ Replab ],

  data() {
    return {
      previousMonth: true,
      offset:        18,
    }
  },

  components: {
    PageHeader,
    PageSummary,
    Page,
    PageThankYou,

    TableSummary,
    TableOTA,
    TableReviewResponse,
    TablePropertyDetails,
    TableTrend,
    TableReviewScore,
    TableSourceReviewSummary,
    TableTripAdvisorPerformanceComparison,
    TableTopTrendTopicsByChannel,
    TableOverallTrends,
    TableOverallReviewMetrics,
    TableSentimentOverTime,
    TableGuestData,
    TableSources,

    ColumnChartTripAdvisorCompSet,
    PieChart,
    ColumnChartNumberReviews,
    StackedChartReviewDistribution,
    ColumnChartReviewDistribution,
    LineChartTripAdvisorWeeklyRank,
    PieChartReviewDistribution,
    PieChartReviewLandscape,
    PieChartReviewLandscapeCompSet,
    ColumnChartAvgReviewPropertyDetail,
    PulseScoreByPropertyDetail,
    ColumnChartAvgReviewScoreByPropertyDetailTA,
    ColumnChartDayByDay,
  },

  async created() {
    await this.replabInit()
  },

  computed: {
    otaTitle() {
      return this.company.property_type == "restaurant" ? "Channel Summary" : "OTA Summary";
    }
  }
}
</script>

<template>
  <div class="replab" v-if='release_date && company' >
    <PageHeader :title="yearEnd ? 'Year-End-Report' : 'Month-End-Report'" :data="{ company, month, year, yearEnd, showDate: true }"></PageHeader>

    <PageSummary :data="{ company, month, year, sources }" />

    <Page title="Executive Summary" :data="{ company, month, year, type: 'month_end' }">
      <TableSummary :title="`${leadingSource.label} Summary`" :company=company identifier=replab_source_summary :release_date=release_date :source=leadingSource.name />

      <ColumnChartTripAdvisorCompSet :title="`${leadingSource.label} Comp-Set Bubble Barometer`" :company=company identifier=replab_source_summary_compset_chart :release_date=release_date :colors=colors :source=leadingSource.name />
    </Page>

    <Page title="Executive Summary" :data="{ company, month, year, type: 'month_end' }">
      <TableOTA :title="otaTitle" :company="company" identifier="replab_ota_summary" :release_date="release_date"/>

      <PieChart title="Review Landscape - Major Channels" :company="company" identifier="replab_review_landscape_major_channels" :source="sources" :release_date="release_date" :sourceColors="sourceColors" :mtd="false" :months_ago="1" :sourceHash="sourceHash" />
    </Page>

    <Page title="Executive Summary" :data="{ company, month, year, type: 'month_end' }">
      <TableSources title="Ratings by Source" :company="company" identifier="replab_segmented" :release_date="release_date" :source="sources"/>
    </Page>

    <Page title="Executive Summary" :data="{ company, month, year, type: 'month_end' }">
      <ColumnChartDayByDay title="Day-by-Day Review Summary" :company="company" identifier="replab_segmented" :source="sources" :release_date="release_date" :colors="colors" />
    </Page>

    <Page title="Executive Summary" :data="{ company, month, year, type: 'month_end' }">
      <TableReviewResponse title="Review Response Coverage by Channel" :company="company" identifier="replab_review_response_coverage_by_channel" :source="sources" :release_date="release_date" :sourceHash="sourceHash"/>

      <TablePropertyDetails title="Property Details - All Channels" :company=company identifier=replab_property_details :release_date=release_date :sentimentRatings=sentimentRatings />
    </Page>

    <Page v-for="index in 2" :key=index :index=index title="Executive Summary" :data="{ company, month, year, type: 'month_end' }">
      <TableSentimentOverTime :index=index :offset=offset :download=download title="Sentiment Over Time" :company="company" identifier="replab_sentiment_overtime" :release_date="release_date" />
    </Page>

    <Page title="Executive Summary" :data="{ company, month, year, type: 'month_end' }">
      <TableGuestData title="Review Demographics" :company="company" identifier="replab_segmented" :release_date="release_date" :tables="['composition']" />
    </Page>

    <Page title="Executive Summary" :data="{ company, month, year, type: 'month_end' }">
      <TableGuestData title="Review Demographics" :company="company" identifier="replab_segmented" :release_date="release_date" :tables="['country']" :offset="offset" />
    </Page>

    <Page title="Executive Summary" :data="{ company, month, year, type: 'month_end' }">
      <h3 class="title mt-3">Trending Topics Current Month</h3>
      <TableTrend title="Top 10 Topics" :company="company" identifier="replab_trending_topics" :release_date="release_date" :months_ago="1"/>
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <TableSourceReviewSummary title="Perfect Review Summary - All Channels" :company=company identifier=replab_source_review_summary :last_12m=false :release_date=release_date :perfect_score=true :last_12m_col=true />
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <TableReviewScore :title="`${leadingSource.label} Review Score Distribution`" :company="company" identifier="replab_source_review_score_distribution" :release_date="release_date" :source=leadingSource.name />
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <TableSourceReviewSummary :title="`${leadingSource.label} Review Summary`" :company="company" identifier="replab_source_review_summary" :source=leadingSource.name :last_12m="false" :release_date="release_date"/>
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <TableSourceReviewSummary :title="`${leadingSource.label} Review Summary Last Year`" :company="company" identifier="replab_source_review_summary" :source="leadingSource.name" :last_12m="true" :release_date="release_date"/>
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <ColumnChartNumberReviews :title="`${leadingSource.label} Review Count`" :company=company identifier=replab_source_review_summary :source=leadingSource.name :last_12m=false :next_3m=false :release_date=release_date review_or_avg=reviews :colors=colors :months_ago=5 />
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <ColumnChartNumberReviews :title="`${leadingSource.label} Review Count - Next 90 Days - Last Year Data`" :company=company identifier=replab_source_review_summary :source=leadingSource.name :last_12m=true :next_3m=true :release_date=release_date review_or_avg=reviews :colors=colors />
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <ColumnChartNumberReviews :title="`${leadingSource.label} Avg. Review Score`" :company=company identifier=replab_source_review_summary :source=leadingSource.name :last_12m=false :next_3m=false :release_date=release_date review_or_avg=average_rating :colors=colors :months_ago=5 />
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <ColumnChartNumberReviews :title="`${leadingSource.label}  Avg. Review Score Next 90 Days - Last Year Data`" :company=company identifier=replab_source_review_summary :source=leadingSource.name :last_12m=true :next_3m=true :release_date=release_date review_or_avg=average_rating :colors=colors />
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <StackedChartReviewDistribution title="TripAdvisor Review Distribution by Traveler Type - Last 90 Days - Primary + Secondary Comp-Set" :company="company" identifier="replab_segmented" :source="['trip_advisor']" :last_12m="false" :next_3m="false" :release_date="release_date" :colors="colors"/>
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <ColumnChartReviewDistribution title="TripAdvisor Avg. Review Score by Traveler Type - Last 90 Days" :company="company" identifier="replab_segmented" :source="['trip_advisor']" :last_3m="true" :release_date="release_date" :colors="colors"/>
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <LineChartTripAdvisorWeeklyRank title="TripAdvisor Weekly Rank" :company="company" identifier="replab_source_weekly_rank" :source="['trip_advisor']" :release_date="release_date" :colors="colors"/>
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <TableTripAdvisorPerformanceComparison title="TripAdvisor Review Frequency Performance Comparison" :company="company" identifier="replab_source_performance_comparison" :source="['trip_advisor']" :release_date="release_date"/>
    </Page>

    <template v-for="source in sources.slice(1)" :key="source">
      <Page :data="{ company, month, year, type: 'month_end' }">
        <TableSourceReviewSummary :title="`${sourceHash[source]} Review Summary`" :company="company" identifier="replab_source_review_summary" :source="[source]" :last_12m="false" :release_date="release_date"/>
      </Page>

      <Page :data="{ company, month, year, type: 'month_end' }">
        <TableSourceReviewSummary :title="`Previous 12-Month ${sourceHash[source]} Review Summary`" :company="company" identifier="replab_source_review_summary" :source="[source]" :last_12m="true" :release_date="release_date"/>
      </Page>
    </template>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <PieChartReviewDistribution title="Review Distribution Details - This Month vs. Last Month" :company="company" identifier="replab_review_response_coverage_by_channel" :source="sources" :release_date="release_date" :compset="false" :last_12m="false" :sourceColors="sourceColors" />
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <PieChartReviewLandscape title="Review Landscape History - Subscriber Hotel" :company="company" identifier="replab_review_response_coverage_by_channel" :release_date="release_date" :compset="false" :source="sources" :sourceColors="sourceColors" :smly="true" />
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <PieChartReviewLandscapeCompSet title="Review Landscape Comp-Set Comparison - Current Month" :company="company" identifier="replab_review_response_coverage_by_channel" :source="sources" :release_date="release_date" :compset="false" property="merged" :sourceColors="sourceColors" />
    </Page>

    <Page title="Top Three Positive Trending Topics by Channel - Last 90 Days" :data="{ company, month, year, type: 'month_end' }">
      <TableTopTrendTopicsByChannel :company=company identifier=replab_trending_topics :release_date=release_date :last_3m=true polarity=positive :source=sources :sourceHash=sourceHash />
    </Page>

    <Page title="Top Three Negative Trending Topics by Channel - Last 90 Days" :data="{ company, month, year, type: 'month_end' }">
      <TableTopTrendTopicsByChannel :company=company identifier=replab_trending_topics :release_date=release_date :last_3m=true polarity=negative :source=sources :sourceHash=sourceHash />
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <ColumnChartAvgReviewScoreByPropertyDetailTA :title="`Avg. Review Score by Property Detail for ${leadingSource.label} - Last 90 Days`" :company="company" identifier="replab_property_numratings" :release_date="release_date" compset="primary" :source=leadingSource.name :colors="colors" />
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <ColumnChartAvgReviewPropertyDetail title="Avg. Review Score by Property Detail for All Channels - Current Month" :company=company identifier=replab_property_details :release_date=release_date compset=primary :source=null :colors=colors :sentimentRatings=sentimentRatings />
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <TableOverallTrends title="Overall Trends" :company="company" identifier="replab_overall_trends" :release_date="release_date" compset="primary" />
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <!-- Pulse Score by Property Detail – All Channels (Current Month vs Previous Month) and Review Overview ­ All Reviews -->
      <PulseScoreByPropertyDetail :company=company identifier=replab_property_details :release_date=release_date :colors=colors :sentimentRatings=sentimentRatings />
    </Page>

    <Page :data="{ company, month, year, type: 'month_end' }">
      <TableOverallReviewMetrics title="Overall Review Metrics" :company=company identifier=replab_overall_review_metrics :source=sources :sourceHash=sourceHash :release_date=release_date compset=primary />
    </Page>

    <PageThankYou />

  </div>
</template>
