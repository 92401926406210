<script>
export default {
  props: ["question", "settings", "editing"],
  created() {
    if (!this.question["answer"]) this.question.answer = "";
    if (!this.question.answer["name"]) this.question.answer.name = "";
    if (!this.question.answer["email"]) this.question.answer.email = "";
  },
};
</script>

<template>
  <div class="form-group email-name-root">
    <div class="email-name w-50 align-self-center">
      <b-form-input
        class="mb-2"
        placeholder="Name"
        v-model="question.answer.name"
      ></b-form-input>
      <b-form-input
        placeholder="E-mail"
        v-model="question.answer.email"
      ></b-form-input>
    </div>
  </div>
</template>
