<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      tableData: {
        current: [],
        previous: []
      },
      cols: [
        { field: "name", label: "Property Name" },
        { field: "ranking", realField: ["ranking", "position"], label: "Current Rank" },
        { field: "out_of",  realField: ["ranking", "out_of"],   label: "Out of How many Hotels" },
        { field: "market_percent", label: "Percentage in Market (%)" },
        { field: "ranking_change", label: "Change Since Last Month" },
      ],
      rows: []
    };
  },
  components: { ExportExcel },
  props: ["title", "group", "identifier", "source", "release_date"],
  created() {
    if (!this.pagination || this.index == 1) {
      axios.get("/v3/query/db/_", { params: {
        group_id:     this.group.id,
        identifier:   this.identifier,
        source:       this.source,
        release_date: this.release_date,
        months_ago: 2,
        compset: false,
        format:  "hash"
      }}).then(response => {
        ["current", "previous"].forEach(period => {
          this.tableData[period] = response.data.data.filter(d => d.period == period);
        });
        this.tableData.current.forEach(data => {
          let row = {};
          let previousData = this.tableData.previous.find(d => d.name == data.name);
          this.cols.forEach(col => {
            if (col.realField) {
              row[col.field] = data[col.realField[0]][col.realField[1]];
            } else if (col.field == "market_percent") {
              if (data.ranking.position != null && data.ranking.out_of != null)
                row[col.field] = Utils.round(100*data.ranking.position / data.ranking.out_of, 2) + "%";
              else row[col.field] = "-";
            } else if (col.field == "ranking_change") {
              if (previousData.ranking.position != null && data.ranking.position != null)
                row[col.field] = data.ranking.position - previousData.ranking.position;
              else row[col.field] = "-";
            } else {
              row[col.field] = data[col.field];
            }
            if (row[col.field] == null) row[col.field] = "-";
            else if (col.field == "ranking") row[col.field] = "#" + row[col.field];
          });
          this.rows.push(row);
        });
      });
    }
  }
}
</script>

<template>
  <div class="table-responsive mx-auto" style="width: 80%;">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th class="property-name-column">{{ cols[0].label }}</th>
            <th v-for="col in cols.slice(1)" :key="col.field">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, indexRow) in rows" :key="JSON.stringify(row) + indexRow">
            <td class="property-name-column">{{ row.name }}</td>
            <template v-for="col in cols.slice(1)" :key="JSON.stringify(row) + col.field">
              <td>{{ row[col.field] }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
