<script>
import axios from 'axios';
import Stat from "@/components/widgets/stat";
import Utils from "@/js/utils";

/**
 * kpis component
 */
export default {
  components: {
    Stat
  },
  data() {
    return {
      data: {
        rating:           { current: 0, change: null },
        sentiment:        { current: 0, change: null },
        tes:              { current: 0, change: null },
        review_count:     { current: 0, change: null },
        response_count:   null,
        numerical_rating: null,
        sentiment_rating: null,
        tes_score:        null,
        previous: { numerical_rating: 0, sentiment_rating: 0, tes: 0 }
      },
      loading: false,
    }
  },
  props: ['cols', 'countslug', 'dashboard_type', 'filterParams', 'kpiSize'],
  watch: {
    filterParams: {
      handler: function () {
        if (this.filterParams.data) this.loadListener(this.filterParams);
      },
      immediate: true
    },
    cols: {
      handler: function () {
        if (Object.keys(this.filterParams).length) this.loadListener(this.filterParams);
      },
      immediate: true
    }
  },
  computed: {
    statData() {
      let res = {};
      if (/destination|reputation/.test(this.dashboard_type)) {
        res['rating'] = {
          title: this.$t('kpis.numerical_overall'),
          icon: "ion ion-md-star",
          current: Utils.round(this.data.rating.current),
          change:  Utils.round(this.data.rating.change),
        };
        res['sentiment'] = {
          title: this.$t('kpis.sentiment_overall'),
          icon: "ion ion-md-chatboxes",
          current: Utils.round(this.data.sentiment.current),
          change:  Utils.round(this.data.sentiment.change),
        };
        res['tes'] = {
          title: this.$t('kpis.tes_score'),
          icon: "ion ion-md-podium",
          current: Utils.round(this.data.tes.current),
          change:  Utils.round(this.data.tes.change),
        };
        res['review_count'] = {
          title: this.$t('kpis.'+(this.countslug || 'number_reviews')),
          icon: "ion ion-md-pulse",
          current: this.countPath?.toString(),
          change:  Utils.round(this.countChangePath),
        };
      }
      if (this.dashboard_type == 'feedback') {
        res['response_count'] = {
          title:   this.$t('kpis.number_responses'),
          icon:    "ion ion-md-pulse",
          current: this.data.response_count || '-',
          change:  this.change(this.data.response_count, this.data.previous.responses_count),
        };
        res['numerical_rating'] = {
          title:   this.$t('kpis.numerical'),
          icon:    "ion ion-md-pulse",
          current: Utils.round(this.data.numerical_rating),
          change:  this.change(this.data.numerical_rating, this.data.previous.numerical_rating),
        };
        res['sentiment_rating'] = {
          title:   this.$t('kpis.sentiment_overall'),
          icon:    "ion ion-md-pulse",
          current: Utils.round(this.data.sentiment_rating),
          change:  this.change(this.data.sentiment_rating, this.data.previous.sentiment_rating),
        };
        res['tes_score'] = {
          title:   this.$t('kpis.tes_score'),
          icon:    "ion ion-md-pulse",
          current: Utils.round(this.data.tes_score),
          change:  this.change(this.data.tes_score, this.data.previous.tes_score),
        };
      }
      return res;
    },
    countPath () {
      if (this.countslug == 'number_reviews') return this.data.review_count.current
      return this.data.sentiment.current_opinions_count
    },
    countChangePath () {
      if (this.countslug == 'number_reviews') return this.data.review_count.change
      return this.data.sentiment.change_opinions_count
    }
  },
  methods: {
    loadListener (params) {
      let p = { ...params.data, headers: this.cols, source: params.data?.sources }
      this.load(p)
    },
    async load (params) {
      Utils.setLoading.bind(this)(true)
      if (this.dashboard_type == 'destination') {
        let response = await axios.get('/v3/destination/kpis', { params })
        this.data = response.data.data;
      }
      if (this.dashboard_type == 'feedback') {
        let response = await this.$store.dispatch("feedback/analytics", {
          start_date:       params.start_date,
          end_date:         params.end_date,
          finished:         params.finished,
          subscription_ids: params.subscription_ids,
          company_ids:      params.company_ids,
          survey_id:        params.survey_id,
          has_sentiment:    params.has_sentiment,
          language:         params.response_language,
          question_type:    params.question_type,
          trends:           'yoy'
        });

        let data                         = Utils.deepClone(response);
        this.data.response_count         = data.responses_count;

        this.data.numerical_rating       = data.numerical.value / 10;
        data.previous.numerical_rating   = data.previous.numerical.value / 10

        this.data.sentiment_rating       = data.sentiment.value / 10;
        data.previous.sentiment_rating   = data.previous.sentiment.value / 10

        this.data.tes_score              = data.tes.value / 10
        data.previous.tes_score          = data.previous.tes.value / 10

        this.data.previous               = data.previous
      }
      if (this.dashboard_type == 'reputation') {
        let p = {
          company_ids: params.company_ids,
          group_ids:   params.group_ids,
          start_date:  params.start_date,
          end_date:    params.end_date,
          ...Utils.slice(params, ["reviewer_country", "language", "sources", "exclude_sources", "travel_composition", "subscription_ids"])
        }
        // numerical
        let numerical = await axios.get('v3/ratings/kpi', { params: p });
        numerical = numerical.data.data;
        this.data.rating.current = numerical.overall?.current?.value/10;
        this.data.rating.change  = this.change(numerical.overall?.current?.value, numerical.overall?.previous?.value);

        // sentiment
        let sentiment = await axios.get('v3/sentiment/kpi', { params: p });
        sentiment = sentiment.data.data;
        this.data.sentiment.current = sentiment.overall?.current?.value;
        this.data.sentiment.change  = this.change(sentiment.overall?.current?.value, sentiment.overall?.previous?.value);
        this.data.sentiment.current_opinions_count = sentiment.overall?.current?.review_count;
        this.data.sentiment.change_opinions_count = this.change(sentiment.overall?.current?.review_count, sentiment.overall?.previous?.review_count);

        // tes
        let tes = await axios.get('v3/tes/kpi', { params: p });
        tes = tes.data.data;
        this.data.tes.current          = tes.overall?.current?.value;
        this.data.review_count.current = tes.overall?.current?.review_count;
        this.data.tes.change           = this.change(tes.overall?.current?.value, tes.overall?.previous?.value);
        this.data.review_count.change  = this.change(tes.overall?.current?.review_count, tes.overall?.previous?.review_count);
      }
      Utils.setLoading.bind(this)(false)
    },
    change (current, previous) {
      if (!current || !previous) return
      return Utils.round((current / previous - 1) * 100)
    },
    changedStatus (change) {
      if (change == null) return ''
      if (change > 1) return 'success';
      if (change < 1) return 'danger'
      return 'text-muted'
    }
  }
};
</script>

<template>
  <div class="row">
    <div v-for="k in cols" :key="k" class='col-sm-6' :class="{'offset-lg-2': cols.length == 2, 'col-lg-3': kpiSize != 'md', 'col-lg-6': kpiSize == 'md' }">
      <Stat v-if="statData[k] && statData[k].current"
        :icon="statData[k].icon"
        :title="statData[k].title"
        :value="statData[k].current"
        :change="statData[k].change"
        :color="changedStatus(statData[k].change)"
        :class='{"loading": loading}'
      />
    </div>
  </div>
</template>
