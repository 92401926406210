<script>
export default {
  data() { return { answer: "" } },
  props: ["question", "settings", "editing"],
  created() {
    if (this.question["answer"] != null) this.answer = this.question["answer"];
  }
};
</script>

<template>
  <div class="form-group yes-no-root">
    <div class="yes-no w-100 d-flex flex-column text-left justify-content-around mb-3" >
      <div class="d-flex w-100 custom-control custom-control-inline custom-radio b-custom-control-lg me-0">
        <input type="radio" :name='"question_"+question.id' v-model='answer' value="true" class="custom-control-input" :id="`${question.id}_yes`" :disabled='editing' @change="$emit('answerChanged', answer)" />
        <label class="custom-control-label active" :for="`${question.id}_yes`">
          {{ $t('feedback-form.yes', settings.currentLanguage) }}
        </label>
      </div>

      <div class="d-flex w-100 custom-control custom-control-inline custom-radio b-custom-control-lg me-0">
        <input type="radio" :name='"question_"+question.id' v-model='answer' value="false" class="custom-control-input" :id="`${question.id}_no`" :disabled='editing' @change="$emit('answerChanged', answer)" />
        <label class="custom-control-label active" :for="`${question.id}_no`">
          {{ $t('feedback-form.no', settings.currentLanguage) }}
        </label>
      </div>
    </div>
  </div>
</template>
