<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      tableData: [],
      cols: [
        { field: "name", label: "Hotel" },
        { field: "reviews", label: "Total Reviews Earned" },
        { field: "5star_count", label: "Total Reviews with Perfect Score" },
        { field: "5star_percent", label: "Percentage of Reviews with Perfect Score" }
      ],
      rows: []
    };
  },

  components: { ExportExcel },
  props: ['title', 'company', 'identifier', 'release_date', 'group', 'source', 'mtd'],

  created() {
    axios.get("/v3/query/db/_", { params: {
      group_id:     this.group.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      source:       this.source,
      months_ago:   1,
      mtd:          this.mtd,
      compset:      false,
      format:       'hash',
    }}).then(response => {
      this.rows = response.data.data.filter(d => d.date_label != "cumulative");
      this.rows.forEach(row => {
        this.cols.forEach(col => {
          if (row[col.field] == null) row[col.field] = "-";
          else if (col.field == "5star_percent")
            row[col.field] = Utils.round(row[col.field], 2) + "%";
        });
      });
    });
  },
  methods: {
    compare(value, field) {
      if (field == "5star_percent") return parseFloat(value) < 50;
      return false;
    }
  }
}
</script>

<template>
  <div class="table-responsive mx-auto" style="width: 80%;">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th v-for="col in cols" :key="col.label">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, indexRow) in rows" :key="indexRow">
            <td class="property-name-column">{{ row.name }}</td>
            <template v-for="(col, indexCol) in cols.slice(1)" :key="col.label + indexCol">
              <td :class="{ 'text-danger': compare(row[col.field], col.field) }">
                {{ row[col.field] }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
