<script>
export default {
  data() {
    return {
      localPageNumber: null,
      pages: [
        // The interval key defines the section's number of pages
        { section: "Executive Summary", interval: 10 },
        { section: "Perfect Review Summary - All Channels", interval: 1 },
        { section: "Review Score Distribution", interval: 1 },
        { section: "Review Summary", interval: 1 },
        { section: "Review Summary Last Year", interval: 1 },
        { section: "Review Count", interval: 1 },
        { section: "Review Count - Next 90 Days - Last Year Data", interval: 1 },
        { section: "Avg. Review Score", interval: 1 },
        { section: "Avg. Review Score - Next 90 Days - Last Year Data", interval: 1 },
        { section: "TripAdvisor Review Distribution by Traveler Type - Last 90 Days - Primary + Secondary Comp-Set", interval: 1 },
        { section: "TripAdvisor Avg. Review Score by Traveler Type - Last 90 Days", interval: 1 },
        { section: "TripAdvisor Weekly Rank", interval: 1 },
        { section: "TripAdvisor Review Frequency Performance Comparison", interval: 1 },
        { section: "Review Distribution Details/Pie Graphs", interval: 3 },
        { section: "Top Three Positive Trending Topics by Channel - Last 90 Days", interval: 1 },
        { section: "Top Three Negative Trending Topics by Channel - Last 90 Days", interval: 1 },
        { section: "Avg. Review Score by Property Detail for #source - Last 90 Days", interval: 1 },
        { section: "Avg. Review Score by Property Detail for All Channels - Current Month", interval: 1 },
        { section: "Overall Trends", interval: 1 },
        { section: "Pulse Score By Property Detail - All Channels (Current Month, Previous Month & Same Month Last Year)", interval: 1 },
        { section: "Overall Review Metrics", interval: 1 }
      ],
    };
  },
  props: ["data"],
  created() {
    this.$parent.pageNumber++;
    this.localPageNumber = this.$parent.pageNumber;

    let index = this.pages.findIndex(p => p.section == "TripAdvisor Review Frequency Performance Comparison") + 1
    this.data.sources.slice(1).forEach(source => {
      this.pages.splice(index, 0, { section: `${this.$parent.sourceHash[source]} Review Summary Tables`, interval: 2 })
      index++
    })

    let leadingSource = this.$parent.leadingSource
    this.pages.forEach(p => { if (p.section == "Review Score Distribution") p.section = `${leadingSource.label} ${p.section}` }) + 1
    this.pages.forEach(p => { if (p.section == "Review Summary") p.section = `${leadingSource.label} ${p.section}` }) + 1
    this.pages.forEach(p => { if (p.section == "Review Summary Last Year") p.section = `${leadingSource.label} ${p.section}` }) + 1

    this.pages.forEach(p => { if (p.section == "Review Count") p.section = `${leadingSource.label} ${p.section}` }) + 1
    this.pages.forEach(p => { if (p.section == "Review Count - Next 90 Days - Last Year Data") p.section = `${leadingSource.label} ${p.section}` }) + 1
    this.pages.forEach(p => { if (p.section == "Avg. Review Score") p.section = `${leadingSource.label} ${p.section}` }) + 1
    this.pages.forEach(p => { if (p.section == "Avg. Review Score - Next 90 Days - Last Year Data") p.section = `${leadingSource.label} ${p.section}` }) + 1

    this.pages.forEach(p => { if (p.section == "Avg. Review Score by Property Detail for #source - Last 90 Days") p.section = p.section.replace('#source', leadingSource.label) }) + 1

    //this.$parent.$children.forEach(comp => {
      //comp._componentTag
    //})

    this.computeIndexes();
  },
  methods: {
    computeIndexes() {
      this.pages[0].page = [3, 3 + this.pages[0].interval - 1];
      for (let i = 1; i < this.pages.length; i++) {
        let lowerLimit = this.pages[i-1].page[1] + 1
        let upperLimit = lowerLimit + this.pages[i].interval - 1;
        this.pages[i].page = [lowerLimit, upperLimit];
      }
    },
    pageLabel(data) {
      if (data[0] != data[1]) return `Pages ${data[0]}-${data[1]}`;
      return `Page ${data[0]}`;
    }
  }
}
</script>

<template>
  <div class="page summary">
    <div class="content d-flex flex-column align-items-center">
      <h2 class="mb-4">Table of Contents</h2>

      <ol class="toc-list" role="list">
        <li v-for="page in pages" :key="page.section">
          <a :href="`#${page.page[0]}`">
            <span class="title-list">
              {{ page.section }}
              <span class="leaders" aria-hidden="true"></span>
            </span>
            <span :data-href="`#${page.page[0]}`" class="page-list">
              <span class="visually-hidden">&nbsp;</span>
              {{ pageLabel(page.page) }}
            </span>
          </a>
        </li>
      </ol>

      <div class="footer d-flex justify-content-between align-items-center py-2 mx-3">
        <img src="@/assets/images/reports/replab/footerlogo.png" style="height: 30px;">

        <span> {{ `${data.company.name}_RepLab_MonthEnd_${data.month} ${data.year}` }} </span>

        <div>
          <img src="@/assets/images/reports/replab/footerlogo.png" style="height: 30px; visibility: hidden;">
          <span> {{ localPageNumber }} </span>
        </div>
      </div>
    </div>
  </div>
</template>
