<script>
import Utils from "@/js/utils";

export default {
  data() {
    return {
      circleColor:   "#e0e0e0",
      progressShape: "round" // round or butt
    };
  },
  props: {
    value:       { type: Number },
    valueSymbol: { type: String },
    size:        { type: Number,  default: 200   },
    circleWidth: { type: Number,  default: 16    },
    exponential: { type: Boolean, default: false }
  },
  computed: {
    progressColor() {
      return Utils.ratings.colorHash[Utils.ratings.toCss(this.value)];
    },
    circumference() {
      const radius = (this.size / 2) - 10;
      return radius * Math.PI * 2;
    },
    percentage() {
      return this.circumference * ((100 - (10 * this.value)) / 100);
    },
    roundedValue() {
      return Utils.round(this.value);
    },
    text() {
      const radius = (this.size / 2) - 10;
      return {
        x: this.circleWidth + radius - (this.size / 7),
        y: -(radius + 3)
      };
    }
  }
}
</script>

<template>
  <!-- :viewBox="`-${size*0.125} -${size*0.125} ${size*1.25} ${size*1.25}`"  -->
  <svg class="circular-score" :width="size" :height="size" :viewBox="`-${size*0.125} 0 ${size*1.25} ${size*1.25}`" style="transform:rotate(-90deg)" :style="`--circle-size: ${circumference}`">
    <circle :r="(size/2) - 10" :cx="size/2" :cy="size/2" fill="transparent" :stroke="circleColor" :stroke-width="`${circleWidth}px`" :stroke-dasharray="circumference" :stroke-dashoffset="0"></circle>
    <circle class="progress" :r="(size/2) - 10" :cx="size/2" :cy="size/2" :stroke="progressColor" :stroke-width="`${circleWidth}px`" :stroke-linecap="progressShape" :stroke-dashoffset="`${percentage}px`" fill="transparent" :stroke-dasharray="circumference"></circle>
    <text :x="`${text.x}px`" :y="`${text.y}px`" :fill="progressColor" :font-size="`${size/3.5}px`" font-weight="bold" :style="`transform:rotate(90deg)`">
      {{ `${ roundedValue }${ valueSymbol || "" }` }}
    </text>
  </svg>
</template>
