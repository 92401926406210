<template v-slot:default>
  <div>
    <h3>{{ $t('signup.signup_to_paid.email') }}</h3>
    <b-form-input
      ref="inputRef"
      v-model="localFormData.email"
      @keydown.enter="nextStep()"
      placeholder="" 
      @input="emitFormData"
      :class="{'is-invalid': !isEmailValid && localFormData.email.length > 0}" 
    ></b-form-input>
    <div v-if="!isEmailValid && localFormData.email.length > 0" class="invalid-feedback">
      {{ $t('signup.signup_to_paid.email_validation_error') }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData', 'nextStep', 'prevStep', 'checkExistingUser'],
  data() {
    return {
      localFormData: { ...this.formData },
    };
  },
  watch: {
    formData: {
      handler(newFormData) {
        this.localFormData = { ...newFormData };
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    isEmailValid() {
      return this.validateEmail(this.localFormData.email);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.inputRef.focus();
    });
  },
  methods: {
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    emitFormData() {
      this.$emit('update:formData', this.localFormData);
    },
  },
};
</script>

<style>
.is-invalid {
  border-color: red;
}
.invalid-feedback {
  color: red;
  font-size: 0.875em;
  margin-top: 0.25rem;
}
</style>
