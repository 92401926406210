<script>
import Utils from "@/js/utils";
import dayjs from "dayjs";

export default {
  props: ["series", "comparisonType", "labels", "colors", "competitorsColors"],
  methods: {
    toggleSeries(topic) {
      this.series[topic].hidden = !this.series[topic].hidden;
    }
  },
  computed: {
    chartOptions() {
      return {
        colors: this.computedColors,
        chart: {
          type: "line",
          toolbar: {
            show: true,
            export: {
              csv: {
                headerCategory: this.$t("general.date"),
                headerValue: "value",
                dateFormatter(timestamp) {
                  return dayjs(timestamp).format("MMMM YYYY");
                }
              }
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            if (val == undefined || isNaN(val)) return;
            return Utils.formatNumber(val);
          },
          offsetY: -7,
          offsetX: 0,
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            colors: ["#313131"]
          },
          background: { enabled: false }
        },
        stroke: { curve: "smooth" },
        grid: { borderColor: "#fff" },
        fill: { opacity: 1 },
        markers: { size: 6, strokeWidth: 0 },
        yaxis: {
          forceNiceScale: true,
          labels: {
            formatter: (value) => Utils.formatNumber(Utils.round(value)),
            style: {
              colors: ["#000"],
              fontSize: "14px",
              fontWeight: 500,
            },
          }
        },
        xaxis: {
          type: "category",
          categories: this.labels
        },
        legend: {
          show: this.comparisonType == "competitors",
          position: "bottom",
          fontWeight: 500,
          offsetY: 5,
          fontSize: "12px",
          labels: { colors: ["#000"] }
        },
        tooltip: {
          y: {
            formatter: function(value, { seriesIndex, dataPointIndex}) {
              return `${Utils.formatNumber(value) || "-"} (${Utils.formatNumber(this.computedSeries[seriesIndex].review_count[dataPointIndex]) || 0} ${this.$t("general.reviews")})`
            }.bind(this)
          }
        }
      }
    },
    computedSeries() {
      return Object.keys(this.series).flatMap(topic => {
        let filtered = [];
        if (this.comparisonType == "competitors") filtered = Object.keys(this.series[topic]).filter(p => !/hidden|color/.test(p) && topic == "overall");
        else filtered = Object.keys(this.series[topic]).filter(p => !/hidden|color/.test(p) && !this.series[topic]?.hidden && this.series[topic][p].belongsTo == "own");

        return filtered.map(property => {
          let data = this.series[topic][property].values;
          if (!data.length) data = Array.from({ length: this.labels.length }, () => null);
          return {
            name: this.series[topic][property].name,
            review_count: this.series[topic][property].review_count,
            data,
            colors: { competitors: this.competitorsColors[this.series[topic][property].belongsTo], ratings: this.series[topic].color }
          };
        });
      });
    },
    computedColors() {
      return this.computedSeries.map(s => s.colors[this.comparisonType]);
    },
  }
}
</script>

<template>
  <div style="overflow-x: auto; overflow-y: hidden;">
    <apexchart
      class="mt-5"
      height="400"
      type="line"
      dir="ltr"
      :series="computedSeries"
      :options="chartOptions"
      ref="chartComparison"
      style="min-width: 600px;"
    ></apexchart>
    <div class="d-flex justify-content-around flex-wrap" v-if="comparisonType != 'competitors'">
      <template v-for="(s, topic) in series" :key="topic">
        <a href="javascript:void(0);" class="d-flex align-items-center mt-1 me-2" :class="{ 'text-secondary text-opacity-25': s.hidden }" @click="toggleSeries(topic)">
          <div class="rounded-circle me-2" :style="`background-color:${s.color};height:13px;width:13px`"></div>
          <span class="mt-1">{{ $t(`ratings.rating_comparison.kpis.${topic}`) }}</span>
        </a>
      </template>
    </div>
  </div>
</template>