<script>
import Utils from "@/js/utils";

export default {
  data() {
    return {
      Utils,
      reports: window.reports,
      localPageNumber: null,
      oleryLogoURL: "https://s3.eu-west-1.amazonaws.com/guest-experience-images/contract-1762-logo-a827e3b777072f0df9fa1ea515568c04.png",
    };
  },
  props: [ 'template', 'contract', 'propertyNames', 'period', 'pageBreak' ],
  computed: {
    logo() {
      const logoURL = this.template?.dashboard_template?.styles?.logo_url;
      if (logoURL && Utils.validURL(logoURL)) return logoURL;
      if (this.contract?.id && this.contract.whitelabel) {
        return this.contract.whitelabel_styles?.logo_url;
      }
      return this.oleryLogoURL;
    },
  },
  created() {
    this.$parent.pageNumber++;
    this.localPageNumber = this.$parent.pageNumber;
  }
}
</script>

<template>
  <div class="page-frame" :id="'page-'+localPageNumber">
    <!-- report header -->
    <template v-if='!pageBreak || !pageBreak.settings.hide_header'>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <img class="report-logo me-5" :src="logo" alt="report logo">
        <span class='d-flex  align-items-center'>
          <span class='me-2'>{{ $t('reports.composed_for') }}</span> <h2>{{ propertyNames }}</h2>
        </span>
      </div>
      <div class="d-flex mb-2 justify-content-between">
        <h6 class="m-0 report-title"><span v-if='!pageBreak || !pageBreak.settings.hide_title'>{{ template.name }}</span></h6>

        <div v-if="period">
          <h6 class="float-end">{{ reports.formatDateRange(period.start_date, period.end_date) }}</h6>
        </div>
      </div>
    </template>

    <slot />

    <template v-if='!pageBreak || !pageBreak.settings.hide_footer'>
      <footer class="d-flex justify-content-between align-items-center py-2 mx-3 w-100">
        <img :src="logo" style="height: 30px;">

        <div class='float-right'> <span class='me-4'> {{ localPageNumber }} </span> </div>
      </footer>
    </template>
  </div>
</template>
