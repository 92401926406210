<script>
import dayjs from "dayjs";
import Stat  from "@/components/widgets/stat";
import Utils from "@/js/utils";

export default {
  data() {
    return {
      loading: true,
      data: {
        numerical:        { current: 0, change: null },
        sentiment:        { current: 0, change: null },
        tes:              { current: 0, change: null },
        review_count:     { current: 0, change: null },
      },

    }
  },
  props: ["settings", "start_date", "end_date", "company_id", "group_id"],
  components: { Stat },
  watch: {
    contract: {
      handler: function () {
        if (this.contract?.id) this.load();
      },
      immediate: true
    }
  },
  methods: {
    async load() {
      Utils.setLoading.bind(this)(true);
      const segment   = this.computedSettings.segment;
      const segOption = this.computedSettings.segmentOption;
      let data = {};

      if (/survey/.test(segment)) {
        data = await this.$store.dispatch("feedback/analytics", { ...this.computedSettings, finished: true });
        let current  = data.responses_count;
        let previous = data.previous?.responses_count;

        if (segment != "survey_responses_count") {
          let rt   = segment.split("_")[1];
          current  = Utils.round(data[rt].value ? data[rt].value / 10 : null);
          previous = data.previous[rt]?.value ? data.previous[rt].value / 10 : null
        }

        this.data.current = current;
        this.data.change = this.change(this.data.current, previous);
      }
      else if (/responses_count|response_rate/.test(segment)) {
        data = await this.$store.dispatch("kpis/response_rate", { ...this.computedSettings, segment: null });
        data = { ...data } // avoid changing the main data
        if (this.settings.use_low_rated) {
          data.current  = data.current?.low_rated
          data.previous = data.previous?.low_rated
        }
        if (segment == "responses_count") {
          this.data.current = `${data?.current?.with_responses || 0}/${data?.current?.respondable || 0}`
        }
        else {
          this.data.current = Utils.round(data?.current?.rate * 100);
        }
        this.data.change  = this.change(data.current?.rate, data.previous?.rate);
      }
      else {
        data = await this.$store.dispatch("kpis/kpi", { ...this.computedSettings });
        const valueField = /reviews_count|mentions_count/.test(segment) ? "review_count" : "value";
        this.data.current = data[segOption]?.current?.[valueField];
        if (segment == "numerical_rating") this.data.current /= 10;
        this.data.current = !/reviews_count|mentions_count/.test(segment) ? Utils.round(this.data.current) : this.data.current;
        this.data.change  = this.change(data[segOption]?.current?.[valueField], data[segOption]?.previous?.[valueField]);
      }
      Utils.setLoading.bind(this)(false);
    },
    change(current, previous) {
      if (!current || !previous) return;
      return Utils.round((current / previous - 1) * 100);
    },
    changedStatus(change) {
      if (change == null) return "";
      if (change > 1) return "success";
      if (change < 1) return "danger";
      return "text-muted";
    },
    title() {
      let title = this.$t(`reports.settings_modal.segment_options.${this.computedSettings.segment}`);
      if (this.computedSettings.segmentOption)
        title = `${title} — ${this.$t(`executive_kpis.${this.computedSettings.segmentOption}`)}`;
      return title
      //if (/responses_count|response_rate/.test(segment)) return this.$t(`reports.settings_modal.segment_options.${segment}`);
      //if (/numerical_rating|sentiment_rating|tes_rating/.test(segment)) return this.$t(`kpis.${segment}`)
      //return this.$t(`executive_kpis.${this.computedSettings.segmentOption}`);
    }
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    computedSettings() {
      let company_id = this.company_id, group_ids = this.group_id;
      if (this.settings.use_custom_benchmark) {
        if (this.settings.benchmark_based_on == 'companies') {
          company_id = this.settings.bench_company_ids
          group_ids = null;
        } else {
          group_ids = this.settings.bench_group_ids;
          company_id = null
        }
      }

      return {
        ratingType:       this.settings.segment,
        subscription_ids: this.contract?.id,
        start_date:       this.start_date ? dayjs(this.start_date).format("YYYY-MM-DD") : undefined,
        end_date:         this.end_date   ? dayjs(this.end_date).format("YYYY-MM-DD")   : undefined,
        trends:           this.settings.trend_range || "yoy",
        sources:          this.settings.filters?.source,
        company_ids:      company_id,
        group_ids,
        ...this.settings
      };
    },
    statData() {
      return {
        title:   this.computedSettings.show_title ? undefined : this.title(),
        icon:   "ion ion-md-star",
        current: this.data.current,
        change:  this.computedSettings.show_trend && this.data.change ? Utils.round(this.data.change) : null
      };
    }
  },
};
</script>

<template>
  <Stat v-if="statData && statData.current"
    :icon="statData.icon"
    :title="statData.title"
    :value="statData.current"
    :valueSymbol="computedSettings.segment == 'response_rate' ? '%' : null"
    :change="statData.change"
    :color="changedStatus(statData.change)"
    :class="{ loading }"
    :trend='settings.trend_range'
    :trendLabel='settings.labels?.trend'
  />
</template>
