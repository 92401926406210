export default {
  "snn_medallia_csv": {
      "name": "snn_medallia_csv",
      "friendly_name": "Medallia",
      "contract_id": 1610
  },
  "zomato": {
      "name": "zomato",
      "friendly_name": "Zomato",
      "contract_id": null
  },
  "yelp": {
      "name": "yelp",
      "friendly_name": "Yelp",
      "contract_id": null
  },
  "staycation": {
      "name": "staycation",
      "friendly_name": "Staycation",
      "contract_id": null
  },
  "agoda": {
      "name": "agoda",
      "friendly_name": "Agoda",
      "contract_id": null
  },
  "weekendjeweg_nl": {
      "name": "weekendjeweg_nl",
      "friendly_name": "Weekendjeweg.nl",
      "contract_id": null
  },
  "meetingroom_review": {
      "name": "meetingroom_review",
      "friendly_name": "MeetingReview",
      "contract_id": null
  },
  "traveloka": {
      "name": "traveloka",
      "friendly_name": "Traveloka",
      "contract_id": null
  },
  "airbnb": {
      "name": "airbnb",
      "friendly_name": "AirBnB",
      "contract_id": null
  },
  "booking_com": {
      "name": "booking_com",
      "friendly_name": "Booking.com",
      "contract_id": null
  },
  "snn_brand_csv": {
      "name": "snn_brand_csv",
      "friendly_name": "Madallia Reviews",
      "contract_id": 1610
  },
  "musement": {
      "name": "musement",
      "friendly_name": "Musement",
      "contract_id": null
  },
  "cheaptickets": {
      "name": "cheaptickets",
      "friendly_name": "CheapTickets",
      "contract_id": null
  },
  "zoover": {
      "name": "zoover",
      "friendly_name": "Zoover",
      "contract_id": null
  },
  "travelocity": {
      "name": "travelocity",
      "friendly_name": "Travelocity",
      "contract_id": null
  },
  "wotif": {
      "name": "wotif",
      "friendly_name": "Wotif",
      "contract_id": null
  },
  "ebookers": {
      "name": "ebookers",
      "friendly_name": "Ebookers",
      "contract_id": null
  },
  "hotels_com": {
      "name": "hotels_com",
      "friendly_name": "Hotels.com",
      "contract_id": null
  },
  "bookvisit_csv": {
      "name": "bookvisit_csv",
      "friendly_name": "Book Visit Reviews",
      "contract_id": 1776
  },
  "hotels_nl": {
      "name": "hotels_nl",
      "friendly_name": "Hotels.nl",
      "contract_id": null
  },
  "csv": {
      "name": "csv",
      "friendly_name": "CSV",
      "contract_id": null
  },
  "ctrip": {
      "name": "ctrip",
      "friendly_name": "Ctrip",
      "contract_id": null
  },
  "facebook_pages": {
      "name": "facebook_pages",
      "friendly_name": "Facebook",
      "contract_id": null
  },
  "getyourguide": {
      "name": "getyourguide",
      "friendly_name": "GetYourGuide",
      "contract_id": null
  },
  "expedia": {
      "name": "expedia",
      "friendly_name": "Expedia.com",
      "contract_id": null
  },
  "airlinequality": {
      "name": "airlinequality",
      "friendly_name": "Skytrax",
      "contract_id": null
  },
  "google": {
      "name": "google",
      "friendly_name": "Google",
      "contract_id": null
  },
  "camping_info": {
      "name": "camping_info",
      "friendly_name": "Camping.info",
      "contract_id": null
  },
  "bungalowspecials": {
      "name": "bungalowspecials",
      "friendly_name": "BungalowSpecials",
      "contract_id": null
  },
  "makemytrip": {
      "name": "makemytrip",
      "friendly_name": "MakeMyTrip",
      "contract_id": null
  },
  "goibibo": {
      "name": "goibibo",
      "friendly_name": "Goibibo.com",
      "contract_id": null
  },
  "hotel_specials": {
      "name": "hotel_specials",
      "friendly_name": "HotelSpecials.nl",
      "contract_id": null
  },
  "holiday_check": {
      "name": "holiday_check",
      "friendly_name": "Holidaycheck.de",
      "contract_id": null
  },
  "camping2be": {
      "name": "camping2be",
      "friendly_name": "Camping2be",
      "contract_id": null
  },
  "couverts": {
      "name": "couverts",
      "friendly_name": "Couverts",
      "contract_id": null
  },
  "hostelworld": {
      "name": "hostelworld",
      "friendly_name": "Hostelworld",
      "contract_id": null
  },
  "hrs": {
      "name": "hrs",
      "friendly_name": "HRS",
      "contract_id": null
  },
  "lastminute": {
      "name": "lastminute",
      "friendly_name": "Lastminute.com.au",
      "contract_id": null
  },
  "orbitz": {
      "name": "orbitz",
      "friendly_name": "Orbitz",
      "contract_id": null
  },
  "rooms_for_africa": {
      "name": "rooms_for_africa",
      "friendly_name": "Rooms for Africa",
      "contract_id": null
  },
  "takeaway_com": {
      "name": "takeaway_com",
      "friendly_name": "Takeaway.com",
      "contract_id": null
  },
  "trustpilot": {
      "name": "trustpilot",
      "friendly_name": "Trustpilot",
      "contract_id": null
  },
  "parkvakanties": {
      "name": "parkvakanties",
      "friendly_name": "Parkvakanties",
      "contract_id": null
  },
  "thefork": {
      "name": "thefork",
      "friendly_name": "TheFork",
      "contract_id": null
  },
  "open_table": {
      "name": "open_table",
      "friendly_name": "Open Table",
      "contract_id": null
  },
  "trip_advisor": {
      "name": "trip_advisor",
      "friendly_name": "TripAdvisor",
      "contract_id": null
  },
  "viator": {
      "name": "viator",
      "friendly_name": "Viator",
      "contract_id": null
  },
  "otelpuan": {
      "name": "otelpuan",
      "friendly_name": "Otelpuan",
      "contract_id": null
  },
  "table_online": {
      "name": "table_online",
      "friendly_name": "TableOnline",
      "contract_id": null
  },
  "dagjeweg": {
      "name": "dagjeweg",
      "friendly_name": "Dagjeweg",
      "contract_id": null
  },
  "klook": {
      "name": "klook",
      "friendly_name": "Klook",
      "contract_id": null
  },
  "hotels_de": {
    "name": "hotels_de",
    "friendly_name": "Hotel.de",
    "contract_id": null
  },
  "headout": {
    "name": "headout",
    "friendly_name": "Headout",
    "contract_id": null
  },
  "civitatis": {
    "name": "civitatis",
    "friendly_name": "Civitatis",
    "contract_id": null
  },
  "tiqets": {
    "name": "tiqets",
    "friendly_name": "Tiqets",
    "contract_id": null
  },
  "travel_ground": {
    "name": "travel_ground",
    "friendly_name": "TravelGround",
    "contract_id": null
  },
  "citybreak_traveller_rating": {
    "name": "citybreak_traveller_rating",
    "friendly_name": "Traveller Rating",
    "contract_id": 1776
  },
  "campings": {
    "name": "campings",
    "friendly_name": "Campings",
    "contract_id": null
  },
  "resengo": {
    "name": "resengo",
    "friendly_name": "Resengo",
    "contract_id": null
  },
  "top_hotels": {
    "name": "top_hotels",
    "friendly_name": "TopHotels",
    "contract_id": null
  },
  "halal_booking": {
    "name": "halal_booking",
    "friendly_name": "HalalBooking",
    "contract_id": null
  },
  "anwb": {
    "name": "anwb",
    "friendly_name": "Anwb",
    "contract_id": null
  },
  "gathern": {
    "name": "gathern",
    "friendly_name": "Gathern",
    "contract_id": null
  },
  "cruise_critic": {
    "name": "cruise_critic",
    "friendly_name": "Cruise Critic",
    "contract_id": null
  },
  "camper_contact": {
    "name": "camper_contact",
    "friendly_name": "Camper Contact",
    "contract_id": null
  },
  "pincamp": {
    "name": "pincamp",
    "friendly_name": "Pincamp",
    "contract_id": null
  },
  "eat": {
    "name": "eat",
    "friendly_name": "EAT",
    "contract_id": null
  }
}
