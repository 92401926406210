<script>
import Excel from '@/components/excel-2003'
export default {
  data() {
    return {
      excel:   null,
      loading: null,
    }
  },

  props: ['report'],
  components: {
    Excel,
  },
  mounted() {
    this.excel = this.$refs.excel

    if (this.$route.query.export) {
      this.loading = true
      var interval = setInterval(() => {
        if (window.reports.api.activeRequests > 0) return

        clearInterval(interval)
        this.saveContent()
      }, 1*1000)
    }
  },

  methods: {
    saveContent() {
      let pages = [...this.report.children].filter(c => c.className.includes("page") && !c.className.includes("summary"))
      this.excel.worksheets = pages.map(p => {
        let exports = p.getElementsByClassName("table-responsive")
        if (exports?.length > 0) {
          let tables  = [...exports].map(e => { return { title: e.querySelector("h3.title")?.innerHTML, element: e.getElementsByTagName("table")?.[0] } })
          tables = tables.filter(t => t.element?.rows.length > 1) // remove empty tables ("continued" cases)
          if (tables.length == 0) return //only charts

          let title = p.querySelector("h2.title")?.innerHTML;
          let name = tables.length > 2 ? title : tables[0].title || title

          return {
            name:   name,
            tables: tables,
          }
        }
      }).filter(w => w)
      this.excel.prepare()

      this.$nextTick(() => {
        this.excel.xlsDownload(this.$parent.filename)
        this.excel.reset()
        this.report.download = null
        this.loading = false
      })
    },

    linkQuery() {
      let query = {...this.$route.query}
      delete query['auth_token']
      if (process.env.NODE_ENV == 'production') delete query['token']
      delete query['override_user']
      query.export = true
      return query
    },

  }
}
</script>

<template>
  <div>
    <div v-if=loading>
      Generating report...
    </div>
    <router-link v-if=!loading @click=saveContent() :to="{ name: this.$route.name, query: linkQuery() }" class='text-white' >
      Download data as Excel
    </router-link>

    <Excel ref=excel />
  </div>
</template>
