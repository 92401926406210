<script>
export default {
  data() {
    return {
      selection: []
    };
  },
  props: ["modelValue", "options", "type"],
  mounted() {
    this.loadFromLocalStorage();
    this.updateValue();
  },
  methods: {
    updateValue() {
      this.$emit("update:modelValue", this.selection.map(s => s.replace(/^option_/, "")));
      this.saveToLocalStorage();
    },
    loadFromLocalStorage() {
      if (!navigator.cookieEnabled) return;
      this.selection = JSON.parse(window.localStorage.getItem(this.computedType)) || [];
      this.selection = this.selection.filter(s => /^option_/.test(s) && this.options.includes(s.split("_").slice(1).join("_")));
    },
    saveToLocalStorage() {
      if (!navigator.cookieEnabled) return;
      window.localStorage.setItem(this.computedType, JSON.stringify(this.selection));
    },
  },
  computed: {
    computedType() {
      return this.type || "scores";
    }
  }
}
</script>

<template>
  <div class="d-flex flex-column flex-sm-row mb-2">
    <label class="form-label me-3">{{ $t(`general.select_${computedType}`) }}:</label>
    <div v-for="option in options" :key="option" class="d-flex me-3">
      <b-form-checkbox :id="`option_${option}`" :value="`option_${option}`" v-model="selection" @change="updateValue">
        <span class="fw-normal">{{ $t(`ratings.rating_comparison.kpis.${option}`) }}</span>
      </b-form-checkbox>
    </div>
  </div>
</template>