<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"

export default {
  data() {
    return {
      chartHeight: 550,
      legendOffsetY: 200,
      labels: [],
      tableData: [],
      chartOptions: {
        chart: { toolbar: { show: false } },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "70%",
            dataLabels: {
              position: 'top',
            }
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
          offsetY: -22,
          offsetX: 0,
          style: {
            fontSize: "16px",
            colors: ["#313131"]
          },
        },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        grid: { borderColor: "#fff" },
        fill: { opacity: 1 },
        yaxis: {
          tickAmount: 15,
          forceNiceScale: true,
          labels: {
            formatter: (value) => String(value),
            style: {
              colors: ["#000"],
              fontSize: '14px',
              fontWeight: 500,
            },
          }
        },
        xaxis: {
          labels: {
            style: {
              colors: ["#000"],
              fontSize: '14px',
              fontWeight: 500,
            },
          }
        },
        legend: {
          show: true,
          position: "right",
          fontWeight: 500,
          // offsetY: 200,
          fontSize: "12px",
          labels: { colors: ["#000"] }
        }
      }
    };
  },
  props: ["title", "company", "identifier", "source", "last_12m", "next_3m", "release_date", "review_or_avg", "colors", "mtd", "prev_months", "months_ago"],
  created() {
    if (this.mtd) this.chartHeight = 330;
    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      source:       this.source,
      last_12m:     this.last_12m,
      next_3m:      this.next_3m,
      release_date: this.release_date,
      months_ago:   this.months_ago,
      compset:      "primary",
      format:       "hash",
      mtd:          this.mtd,
      prev_months:  this.prev_months
    }}).then(response => {
      this.tableData = response.data.data;

      let seen = [];
      this.tableData.forEach(data => {
        if (!seen.includes(data.date_label) && data.date_label != "cumulative") {
          this.labels.push(data.date_label);
          seen.push(data.date_label);
        }
      });

      if (this.mtd) this.legendOffsetY = 100;

      if (this.$refs["column-chart"])
        this.$refs["column-chart"].updateOptions({
          labels: this.labels, colors: this.colors,
          yaxis:  { max: function (max) { return max + 1 } },
          legend: { offsetY: this.legendOffsetY }
        });
    });
  },
  computed: {
    series() {
      let res = [];
      this.tableData.forEach(data => {
        if (data.date_label != "cumulative") {
          let series = res.find(e => e.name == data.name);
          let value = data[this.review_or_avg] || 0;
          if (this.review_or_avg == "average_rating" && value != null) value = Utils.round(value, 1);

          if (!series) res.push({ name: data.name, data: [value] });
          else series.data.push(value);
        }
      });
      return res;
    }
  }
}
</script>

<template>
  <div>
    <h3 class="title mt-3"> {{ title }} </h3>
    <apexchart
      class="mt-5"
      :height="chartHeight"
      type="bar"
      dir="ltr"
      :series="series"
      :options="chartOptions"
      ref="column-chart"
    ></apexchart>
  </div>
</template>
