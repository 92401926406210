<script>
import axios from "axios";
import Utils from "@/js/utils";
import dayjs from "dayjs";

import DataUtils  from "@/js/data-utils";
import sources    from "@/data/sources"
import Background from "@/components/bg-pattern";

import Trends from "@/components/trend-tracker";

export default {
  components: { Trends, Background },
  data() {
    return {
      loading: false,
      offset: 5,
      cols: {
        recent_reviews:        { data: [], comp: null, icon: "mdi mdi-message-draw", show: true, },
        outstanding_responses: { data: [], comp: null, icon: "ion ion-md-list-box",  show: true, },
        review_mentions:       { data: [], comp: null, icon: "mdi mdi-comment",      show: true, },
        current_ota_ranking:   { data: [], comp: null, icon: "mdi mdi-podium",       show: true, },
        trends:                { comp: Trends, show: false, settings: { random: true, showAdviceButton: true, hideBg: true } },
      },
      start: {
        oneDay: null,
        oneMonth: null
      },
      end: null,
      tableHeaders: ["label", "ranking", "numeric"],
    };
  },
  props: ["filterParams"],
  watch: {
    filterParams: function () {
      if (!this.loading) this.loadListener(this.filterParams);
    }
  },
  async created() {
    this.user = await this.$store.dispatch('user/fetch')
  },
  methods: {
    ...DataUtils,
    async loadListener(params) {
      this.start.oneDay   = dayjs().add(-1,  "day").startOf("hour").toISOString();
      this.start.oneMonth = dayjs().add(-30, "day").startOf("hour").toISOString();
      this.end = dayjs().startOf("hour").toISOString();
      let p = { company_ids: params.data.company_ids, group_ids: Utils.flattenGroupIds(params.data.group_ids), end_date: this.end };

      this.cols.trends.show = this.user.admin ||
        this.user.contract_permissions.find(p => p.component == "reputation" && /trends|index/.test(p.permission_type))

      await this.load(p)
    },
    async load(params) {
      Utils.setLoading.bind(this)(true);

      let response = await axios.get("/v3/reviews/recent", { params: { ...params, start_date: this.start.oneDay } });
      this.cols.recent_reviews.data = response.data.data?.slice(0, this.offset);

      response = await axios.get("/v3/reviews/recent", { params: { ...params, start_date: this.start.oneMonth, with_responses: false } });
      this.cols.outstanding_responses.data = response.data.data?.slice(0, this.offset);

      ["recent_reviews", "outstanding_responses"].forEach(kpi => {
        this.cols[kpi].data = this.cols[kpi].data.map(rv => ({ label: sources[rv.name].friendly_name, source: rv.name, numeric: rv.count }));
      });

      response = await axios.get("/v3/sentiment/kpi", { params: { ...params, start_date: dayjs().add(-1, "month").format("YYYY-MM-DD"), end_date: dayjs().format("YYYY-MM-DD") } });
      this.cols.review_mentions.data = Object.keys(response.data.data)?.map(s => ({
        label: this.$t(`topics.${s}`),
        numeric: response.data.data[s].current.review_count,
        sentiment_sub_rating: s
      }));
      this.cols.review_mentions.data = Utils.sortField(this.cols.review_mentions.data, "numeric").reverse().slice(0, this.offset);

      if (params.company_ids?.length == 1) {
        response = await axios.get(`/v3/companies/${params.company_ids[0]}/source_data`, { params: { ...params, start_date: this.start.oneDay } });
        this.cols.current_ota_ranking.data = response.data.data?.slice(0, this.offset)?.map(r => ({
          label: r.friendly_name,
          numeric: r.per_day?.[0]?.displayed_rating,
          ...(typeof(r.per_day?.[0]?.ranking) == "number") && { ranking: this.$t("general.position", { pos: r.per_day?.[0]?.ranking }) },
          ...(typeof(r.per_day?.[0]?.ranking) == "object") && { ranking: `${r.per_day?.[0]?.ranking?.position} / ${r.per_day?.[0]?.ranking?.properties_count}` }
        }));
      }

      Utils.setLoading.bind(this)(false)
    },

    handleClick(kpi, row) {
      this.redirect(kpi, row)
    },

    redirect(kpi, row) {
      let query = {
        "sd":    this.start.oneDay,
        "ed":    this.end,
        "cids": this.filterParams.data.company_ids,
        "gid":  Utils.flattenGroupIds(this.filterParams.data.group_ids)
      }

      if (/review_mentions|outstanding_responses/.test(kpi)) query["sd"] = this.start.oneMonth;

      if (row.source) query["srcs"] = row.source;
      if (row.sentiment_sub_rating) query["t"] = row.sentiment_sub_rating;
      if (kpi == "outstanding_responses") query["wr"] = "without";

      let authParams = Utils.slice(this.$route.query, Utils.authParams);
      this.$router.push({ name: "reputation_reviews", query: { ...authParams, ...query } });
    },
    label(kpi, col) {
      if (col == "label" && /review_mentions/.test(kpi)) return 2;
      if (col == "numeric" && /current_ota_ranking/.test(kpi)) return 2;
      return 1;
    },
    formatNumber(val) {
      return Utils.formatNumber(val);
    }
  },
  computed: {
    trendTrackerProps() {
      return {
        start_date: dayjs().add(-1, "month").format("YYYY-MM-DD"),
        end_date:   dayjs().format("YYYY-MM-DD"),
        company_id: this.filterParams?.data?.company_ids?.[0]
      };
    }
  }
}
</script>

<template>
  <div class="row">
    <template v-for="(opts, k) in cols" :key=k >
      <div class="col-xs-12 col-sm-6 mb-4 col-xxl-4" v-if="opts.show && (loading || opts.comp || opts.data.length > 0)">
        <div class="card h-100" :class="{ 'loading': loading }">
          <Background />
          <div class=card-body >
            <div class="d-flex justify-content-between align-items-start">
              <div class="d-flex align-items-center">
                <h6 class="text-uppercase my-0 me-2">{{ $t(`daily_operations.dashboard.${k}`) }}</h6>
                <span v-b-tooltip.hover :title="$t(`daily_operations.dashboard.${k}_tooltip`)" class="me-2">
                  <i class="mdi mdi-help-circle"></i>
                </span>
              </div>
              <i :class="`${opts.icon} text-primary h4 mb-0`"></i>
            </div>

            <component v-if="opts.comp" :is="{ ...opts.comp }" :params="filterParams" v-bind="trendTrackerProps" :settings="{ ...opts.settings }" />

            <div v-if=!opts.comp class="table-responsive">
              <table class="table table-borderless table-nowrap">
                <thead>
                  <tr>
                    <th class="px-0 py-1">#</th>
                    <template v-for="col in tableHeaders" :key="col">
                      <th class="px-0 py-1" :class="{ 'text-start': col != 'numeric', 'text-end': col == 'numeric' }" v-if="col != 'ranking' || k == 'current_ota_ranking'">{{ $tc(`daily_operations.dashboard.${col}`, label(k, col)) }}</th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in opts.data" :key="`${row.label}_${index}`">
                    <td class="text-start py-1 px-0">{{ index + 1 }}.</td>
                    <td class="text-start py-1 px-0">
                      <a class="text-wrap" href="#" :title="$t('daily_operations.dashboard.redirect')" @click="handleClick(k, row)">
                        <span v-html="row.label"></span>
                        <i class="font-size-12 fa fa-external-link-alt mb-1 ms-1"></i>
                      </a>
                    </td>
                    <td v-if="row.ranking" class="text-start py-1 px-0">{{ row.ranking }}</td>
                    <td class="text-end py-1 px-0">{{ formatNumber(row.numeric) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </template>
  </div>
</template>
