if (!window.oly) window.oly = {}

window.oly.reputationUtils = {
  forward(review) {
    let body = `
Check out this review: ${this.reviewLink(review)}

Title: ${review.title || this.$t("daily_operations.reviews.no_title")}

Date: ${review.creation_date}

Rating: ${window.oly.utils.round(review.ratings?.find(r => r.category == "overall")?.rating / 10)}
`;

    const link = `mailto:?subject=${encodeURIComponent(this.$t("daily_operations.reviews.forward_subject"))}&body=${encodeURIComponent(body)}`

    return link;
  },

  useCompanies(user) {
    if (!user) return [];
    let res = [...user.assignments.companies];
    res     = res.concat(user.assignments.groups?.flatMap(g => g.companies))

    return res.filter((v, i, self) => i === self.findIndex(c => c.id == v.id ) );
  },

  reviewLink(review) {
    return `${location.origin}/reputation/review?rid=${review.review_id}&cid=${review.company.id}`;
  },

  async loadManagerPermissions() {
    let manager = { ...window.oly.utils.deepClone(this.user), assignments: { groups: [], companies: [] } };
    // get groups
    let response = await this.$store.dispatch("groups/fetch", { contract_id: this.contract.id });
    manager.assignments.groups = response;
    let companiesFromGroups = response.flatMap(g => g.companies.map(c => c.id));

    // get companies
    response = await this.$store.dispatch("companies/fetch", { contract_id: this.contract.id });
    manager.assignments.companies = response.filter(c => !companiesFromGroups.includes(c.id));

    return manager;
  },
}

export default window.oly.reputationUtils;
