<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      sources: [],
      cols: [
        { field: "", label: "Topics"   },
        { field: "", label: "Mentions" },
        { field: "", label: "Score"    }
      ],
      rows: {
      }
    };
  },

  components: { ExportExcel },
  props: ['title', 'company', 'identifier', 'release_date', 'polarity', 'compset', 'last_3m', 'source', 'sourceHash'],

  async created() {
    let promises = []

    this.source.forEach(s => this.sources.push({field: s, label: this.sourceHash[s]}))

    this.sources.forEach(source => {
      promises.push(axios.get("/v3/query/db/_", { params: {
        company_id:   this.company.id,
        identifier:   this.identifier,
        release_date: this.release_date,
        compset:      false,
        last_3m:      this.last_3m,
        source:       source.field,
        format:       'hash',
      }}).then(response => {
        let data = response.data.data.slice(0, 3)
        data.forEach(data => {
          if (!data.score && data.score != 0) data.score = "-"
          else data.score = Utils.round(data.score, 2)
        })
        this.rows[source.field] = data
      }));
    });

    await Promise.all(promises);
  }
}
</script>

<template>
  <div class="d-flex justify-content-between">
    <div class="d-grid-2-col mt-4">
      <div class="table-responsive" v-for="source in sources" :key="source.field">
        <ExportExcel :file_name="`${title} - ${source.label}.xls`" :title="source.label">
          <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center" style="table-layout: fixed;">
            <thead>
              <tr>
                <th v-for="col in cols" :key="col.label"> {{ col.label }} </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, indexRow) in rows[source.field]" :key="String(row.length) + indexRow">
                <td> {{ row.label }} </td>
                <td> {{ row[polarity] }} </td>
                <td> {{ row.score }} </td>
              </tr>
            </tbody>
          </table>
        </ExportExcel>
      </div>
    </div>
  </div>
</template>
