import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import store from '../state/store'

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,

  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

store.commit('SAVE_TOKEN', { ep: window.ep_token, code: window.ep_code })

// Before each route evaluates...
router.beforeEach((to, from, next) => {
  store.commit('SAVE_TOKEN', { token: to.query.token, ep: to.query.ep, code: to.query.code, auth_token: to.query.auth_token, overr_user: to.query.override_user })
  if (to.query.lang) store.commit('locale/saveLocale', to.query.lang)
  if(!hasQueryParams(to) && hasQueryParams(from)) {
    next({name: to.name, query: from.query});
  } else {
    next()
  }

  if (process.env.NODE_ENV != "development" && hasQueryParams(from)) {
    window.Intercom('trackEvent', to.name, from.query);
  }
})

export default router
