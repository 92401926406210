<script>
import Utils     from "@/js/utils";
import NotLoaded from "@/components/not-loaded";
import Loading   from "@/components/loading";

export default {
  data() {
    return {
      loading:     true,
      error:       false,
      chartData:   [],
      polarities:  ["positive", "neutral", "negative"]
    }
  },
  props: ["group_ids", "start_date", "end_date", "group_id", "settings", "company_id", "template"],
  components: { NotLoaded, Loading },
  methods: {
    async load() {
      this.loading = true;
      this.error = false;
      let promises = [];
      let params = {
        [this.paramsKey]: this[this.propertyKey],
        ratingType:       this.settings.segment,
        start_date:       this.start_date,
        end_date:         this.end_date,
        ctx:              "stats",
      };

      promises.push(this.$store.dispatch("kpis/fetch", params).then(response => {
        const data  = response.find(r => r.topic == "overall");
        const total = data?.opinions_count;
        if (!data) return;

        this.polarities.forEach(pol => {
          const polVal = data[`${pol}_opinions`];
          this.chartData.push({ pol, value: total ? polVal * 100 / total : 0 });
        });
      }).catch(() => this.error = true));

      await Promise.all(promises);
      this.loading = false;
    }
  },
  computed: {
    paramsKey() {
      if (this.template.based_on == "companies") return "company_ids";
      else return "group_ids";
    },
    propertyKey() {
      if (this.template.based_on == "companies") return "company_id";
      else return "group_id";
    },
    series() {
      return this.chartData.map(c => c.value);
    },
    chartOptions() {
      return {
        labels: this.chartData.map(c => this.$t(`filter.${c.pol}`)),
        colors: ["#5c9d4e", "#e2c04f", "#bf4545"],
        dataLabels: {
          enabled: true,
          formatter: (val) => Utils.round(val, 1) + "%"
        },
        tooltip: { y: { formatter: val => Utils.round(val, 1) + "%" } },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          offsetX: 0,
          offsetY: 5,
          formatter: (name, o) => `${name}: ${Utils.round(o.w.globals.series[o.seriesIndex])}%`
        },
        responsive: [{
          breakpoint: 600,
          options: {
            chart:  { height: 240 },
            legend: { show: false },
          }
        }]
      };
    }
  },
  mounted() {
    this.load()
  }
}
</script>
<template>
  <div :style="`height:${this.settings.height || 300}px;`">
    <apexchart v-if="!loading && chartData && chartData.length" class="apex-charts" height="100%" type="donut" dir="ltr" :series="series" :options="chartOptions"></apexchart>

    <NotLoaded :data="chartData" :error="error" v-else-if="!loading" />
    <Loading v-else-if="loading" />
  </div>
</template>
