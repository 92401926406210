<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      loading: false,
      periods: [
        { field: "current", label: "Current Month" },
        { field: "previous", label: "Last 90 Days" }
      ],
      cols: [
        { field: "segment",        label: "Type"           },
        { field: "reviews",        label: "Reviews"        },
        { field: "average_rating", label: "Overall Rating" },
      ],
      tableData: {
        composition: {},
        country: {}
      },
      segments: [
        { field: "composition", label: "Ratings by Traveler Type" },
        { field: "country",     label: "Ratings by Country"       }
      ],
    };
  },
  components: { ExportExcel },
  props: ["title", "company", "identifier", "release_date", "tables", "offset"],
  async created() {
    let promises = [];
    this.segments = this.segments.filter(s => this.tables.includes(s.field));
    Utils.setLoading.bind(this)(true);

    this.segments.forEach(segment => {
      promises.push(axios.get("/v3/query/db/_", {params: {
        company_id:   this.company.id,
        identifier:   this.identifier,
        release_date: this.release_date,
        segment:    segment.field,
        months_ago: 1,
        period:     'month',
        compset:    false,
        format:     'hash',
      }}).then(response => {
        this.tableData[segment.field].current = response.data.data[0]
      }));

      promises.push(axios.get("/v3/query/db/_", {params: {
        company_id:   this.company.id,
        identifier:   this.identifier,
        release_date: this.release_date,
        segment: segment.field,
        period:  'merged',
        compset: false,
        last_3m: true,
        format:  'hash',
      }}).then(response => {
        this.tableData[segment.field].previous = response.data.data[0]
      }));
    });

    await Promise.all(promises);
    ["current", "previous"].forEach(period => {
      this.segments.forEach(segment => {
        let segments = this.tableData[segment.field][period].segments ||= []

        segments.sort((a, b) => {
          if (a.reviews < b.reviews) return 1;
          if (a.reviews > b.reviews) return -1;
          return 0;
        });

        this.tableData[segment.field][period].segments = segments.slice(0, this.offset)
      });
    });

    Utils.setLoading.bind(this)(false);
  },
  methods: {
    tdClass(value) {
      return "gray-" + Utils.ratings.toCss100(value);
    }
  }
}
</script>

<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="title mt-3"> {{ title }} </h3>
    </div>

    <div class="d-grid-2-col mt" v-if="!loading">
      <template v-for="segment in segments">
        <template v-for="period in periods" :key="segment.field + period.field">
          <div class="table-responsive">
            <ExportExcel :file_name="`${title}_${segment.label}_${period.field}.xls`" :title="segment.label + ` - ${period.label}`">
              <table border="1" class="table table-bordered table-nowrap mb-0 text-center">
                <thead>
                  <tr>
                    <th v-for="col in cols" :key="col.label" class="align-left"> {{ col.label }} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, indexRow) in tableData[segment.field][period.field].segments" :key="JSON.stringify(row) + indexRow">
                    <td class="align-left" style="width: 120px">
                      {{ row["segment"] }}
                    </td>
                    <td>
                      <div class="guest-data-share-grid" style="grid-template-columns: 25% 80%;">
                        <span class="px-0">
                          {{`${row["reviews"]} (${row["share"]}%)`}}
                        </span>
                        <div class="progress me-3">
                          <div class="progress-bar bg-replab" :style="`width: ${row.share}%`"></div>
                        </div>
                      </div>
                    </td>
                    <td :class="tdClass(20*row['average_rating'])" style="width: 150px">
                      {{ row["average_rating"] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ExportExcel>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
