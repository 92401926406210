<script>
import axios         from "axios";
import Utils         from "@/js/utils";
import dayjs         from "dayjs";
import FeedbackUtils from "@/js/feedback-utils";
import ExportXLSX    from "@/components/export-xlsx";

export default {
  data() {
    return {
			questions: [],
			beta: process.env.NODE_ENV !== 'production',
			items: [],
			loading: true,
			allCompanies: [],
			companies: [],
			display: {
				"date": false,
				"drop_down": true,
				"enum": true,
				"location": true,
				"multiple_select": true,
				"open_answer": false,
				"open_answer_long": false,
				"past_date": false,
				"rating": true,
				"trip_advisor_iframe": true,
				"yes_no": true,
				"nps": true
			},
			yesNoOptions: { true: 'yes', false: 'no' },
			params: {},
			cachedFollowups: {},
			loadingFollowups: false,
			ratingTypes: ["numerical", "sentiment", "tes"]
		};
  },
	props: ["filterParams", "rt"],
	components: { ExportXLSX },
	watch: {
    filterParams: {
			handler: function () {
				if (this.filterParams.data) this.loadListener(this.filterParams);
			},
			immediate: true
		}
  },
	methods: {
		...FeedbackUtils,
    loadListener (params) {
      var p = Object.assign({per: 'month'}, params.data)
			this.params = p
      this.load(p)
    },
    async load (params) {
      Utils.setLoading.bind(this)(true)
      let promises = []

			promises.push(this.$store.dispatch("feedback/per_question", params).then(response => {
				this.questions = Utils.deepClone(response);
        this.questions = this.questions.filter(el => this.display[el.question.type]);
				this.items = [];
				if (params.question_type?.length > 0) this.questions = this.questions.filter(q => params.question_type.includes(q.question.type));
				this.questions.forEach(el => {
					el.answers = this.formatAnswers(el);
					this.ratingTypes.forEach(rt => {
						el[rt] = Utils.round(el[rt].value ? el[rt].value / 10 : null);
					});
					el.isPieChart = false;
					this.itemize(el);
				});
			}));

			promises.push(
				// logic for getting the companies names...
        this.$store.dispatch("user/fetch").then(async (data) => {
          let contracts = data.feedback_contracts;
          contracts.forEach(async (c) => {
            await this.$store.dispatch("companies/fetch", { contract_id: c.id }).then(data => this.allCompanies = this.allCompanies.concat(data));
          });
      }));

      await Promise.all(promises);

			let hashCompany = {};
			const companyName = this.allCompanies.filter(obj => obj.id == params.company_ids)[0];
			if (companyName) {
				hashCompany.id = params.company_ids;
				hashCompany.name = companyName.name;
				this.companies.push(hashCompany);
			}
      Utils.setLoading.bind(this)(false)
    },
		calculateTrend(answer) {
			return answer.previous?.count ? Utils.round(((answer.count / answer.previous.count) - 1) * 100) : 'none_before';
		},
		formatAnswers(question) {
			if (question.question.type == "rating") {
				//fill
				let maxValue = question.question.max_value || 5;
				let fill = Array.from({ length: maxValue }, (_, i) => i + 1);

				question.answers.forEach(answer => {
					if (answer.value) answer.value = Number.parseFloat(answer.value);
					let index = fill.indexOf(answer.value);
					if (index > -1) fill.splice(index, 1);
				});

				fill.forEach(number => question.answers.push({ value: number, count: 0, follow_ups: 'none' }));
				question.answers = this.reSortAnswer(question.answers);
				return question.answers;
			}

			return question.answers;
		},
		reSortAnswer(answers) {
			let res = [];
			answers.forEach((el) => {
				let index = res.findIndex(e => e.value == el.value)
				if (index != -1) res[index].count = res[index].count + el.count;
				else res.push(el);
			})
			res.sort((a, b) => {
				if (a.value < b.value) return -1;
				if (a.value > b.value) return 1;
				return 0;
			});
			return res;
		},
		itemize (question) {
			let items = [];
			let item  = {};
			let answers = question.answers;
			let npsCount = 0;
			if (question.question.type == "nps") {
				answers = this.mergeNPS(question);
				npsCount = answers.reduce((res, curr) => {
					if (curr.value) res += curr.count;
					return res;
				}, 0);
			}

			answers.forEach(ans => {
				item = {};
				item['value']        = ans.value;
				item['ratio']        = Utils.round((ans.count / question.responses_count) * 100).toString();
				item['quantity']     = ans.count;
				item['type']         = question.question.type;
				item['options']      = question.question.options;
				item['_showDetails'] = false;
				item['trend']        = this.calculateTrend(ans);

				if (question.question.type == 'rating') item['follow_ups'] = ans.follow_ups;
				if (question.question.type == 'nps') {
					item['color'] = this.npsColorHash()[item['value']];
					if (npsCount > 0 && item['value'] != null) item['recommendation_ratio'] = Utils.round(100 * item['quantity'] / npsCount);
					else item['recommendation_ratio'] = "-";
				}

				items.push(item);
			});

			this.items.push(items);
		},
		mergeNPS(question) {
			let merged = question.answers.reduce((res, curr) => {
				if (curr.value != null) {
					let npsType = this.NPS(curr.value).label;
					let index   = res.findIndex(r => r.value == npsType);
					if (index >= 0) {
						res[index] = {
							...res[index],
							count:      res[index].count + curr.count,
							follow_ups: res[index].follow_ups + curr.follow_ups,
							previous: {
								count:      res[index].previous.count + curr.previous.count,
								follow_ups: res[index].previous.follow_ups + curr.previous.follow_ups,
							}
						}
					} else res.push({ ...curr, value: npsType });
				} else res.push(curr);
				return res;
			}, []);
			let fill = { count: 0, follow_ups: 0, previous: { count: null, follow_ups: null } };
			["promoter", "passive", "detractor"].forEach(nps => {
				if (merged.findIndex(m => m.value == nps) == -1) merged.push({ ...fill, value: nps })
			});
			return merged;
		},
    selectTitle(title) {
      if (!title) return ""
      return title[this.$i18n.locale] || Object.values(title)[0]
    },
		getSheetName(question) {
			return Utils.slugify(question).substring(0, 30);
		},
		getData(data, questionType) {
			return data.map(el => {
				let array = this.fields(questionType).map(f => f.field).filter(k => k != 'follow_ups').map(c => {
					if (c == 'value') return { [c]: el.value || this.$t('feedback_analytics.unanswered') };
					return { [c]: el[c] };
				})
				return Object.assign({}, ...array);
			});
		},
		randomKeyGenerator(k) { return Math.random(k).toString(36).replace(/[^a-z]+/g, '') },
		tdClass(question) {
			if (question.question.type == "nps") {
				if (question.nps.value == null) return "nps-undefined";
				return `nps-${this.NPS(Utils.round(question.nps.value/10, 0)).label}`;
			}
      return Utils.ratings.toCss(this.ratingToShow(question));
    },
		async loadFollowups(question) {
			if (!this.cachedFollowups[question.id] || !Utils.compareObjects(this.cachedFollowups[question.id]?.params, this.filteredParams)) {
				this.loadingFollowups = true;
				let response = await axios.get(`/v3/feedback/answers/follow_ups?question_id=${question.id}`, { params: this.filteredParams });
				this.loadingFollowups = false;

				// Organize by rating
				let data = {};
				response.data.data.forEach(el => {
					data[el.rating] ||= [];
					data[el.rating].push({ ...el, created_at: dayjs(el.created_at).format("YYYY-MM-DD") });
				});

				this.cachedFollowups[question.id] = { params: this.filteredParams, data: data };
			}
		},
		customSort(a, b, key) {
			if (key == 'value') {
        a = this.getAnswer(a.value, a.type, false, a.options);
				b = this.getAnswer(b.value, b.type, false, b.options);
      }
			if (key == 'ratio') {
				a = Number.parseFloat(a.ratio);
				b = Number.parseFloat(b.ratio);
			}
			if (key == 'quantity') {
				a = a.quantity;
				b = b.quantity;
			}
			if (a < b)  return -1;
			if (a > b)  return  1;
			if (a == b) return  0;
		},
		toggleFollowups(question) {
			this.loadFollowups(question);
		},
		hasFollowup(index) {
			let count = 0;
			this.items[index].forEach(item => count += item.follow_ups);
			return count > 0 ? true : false;
		},
		formatNumber(val) {
			return Utils.formatNumber(val, 1);
		},
		ratingToShow(question) {
			if (question.question.type == "nps") return Utils.round(question.nps?.value, 0);
			return question[this.rt];
		},
		calcFontSize(value) {
			return (-2)*value.length + 26;
		},
		fields(questionType) {
			let valueLabel = questionType == "nps"
				? this.$t("feedback_analytics.question_labels.type")
				: this.$t("feedback_analytics.question_labels.value");
			let res = [
				{
					value: "value", field: "value", sortable: true,
					text: valueLabel, label: valueLabel
				},
				{
					value: "recommendation_ratio", field: "recommendation_ratio", sortable: true,
					text: this.$t("feedback_analytics.question_labels.recommendation_ratio"),
					label: this.$t("feedback_analytics.question_labels.recommendation_ratio")
				},
				{
					value: "ratio", field: "ratio", sortable: true,
					text: this.$t("feedback_analytics.question_labels.total_ratio"),
					label: this.$t("feedback_analytics.question_labels.total_ratio")
				},
				{
					value: "quantity", field: "quantity", sortable: true,
					text: this.$t("feedback_analytics.question_labels.quantity"),
					label: this.$t("feedback_analytics.question_labels.quantity")
				}
			];
			if (questionType != "nps") res.splice(1, 1);
			return res;
		},
		showPieSwitch(type) {
			return /rating|nps|yes_no|enum|drop_down/.test(type);
		},
		chartOptions(items, type) {
			let colors = ["#a3cae0", "#4090cb", "#e74c5e", "#f9d570", "#47bd9a", "#d19aec"];
			if (type == "nps") colors = items.map(i => i.color || this.npsColorHash().undefined);
			return {
        labels: items.map(i => this.getAnswer(i.value, i.type, false, i.options)), colors,
				dataLabels: {
					formatter: function(value, { seriesIndex, w }) {
						return `${Utils.formatNumber(Utils.round(value, 2))}% (${Utils.formatNumber(w.config.series[seriesIndex])})`;
					}
				},
				tooltip: { y: { formatter: val => Utils.formatNumber(val) } },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10
        },
        responsive: [{
          breakpoint: 600,
          options: {
            chart:  { height: 240 },
            legend: { show: false },
          }
        }]
      };
		},
		series(items) {
			return items.map(i => i.quantity);
		},
		fileName(question) {
			return this.$t("general.export_file_name", {
        title: `${this.$t('feedback_analytics.title')}-${this.selectTitle(question.question.title)}`,
        sd: this.filterParams.data.start_date,
        ed: this.filterParams.data.end_date
      }).replace(/[^a-zA-Z0-9-_]/g, "-").toLowerCase();
		}
  },
	computed: {
		filteredParams() {
			return {
				start_date:  this.params.start_date,
				end_date:    this.params.end_date,
				company_ids: this.params.company_ids,
				survey_id:   this.params.survey_id,
				finished:    this.params.finished,
				language:    this.params.response_language,
				subscription_ids: this.params.subscription_ids
			}
		},
		followupFields() {
			return [
				{ value: "follow_up",  text: this.$t("feedback_analytics.follow_up"),  sortable: true },
				{ value: "created_at", text: this.$t("feedback_analytics.created_at"), sortable: true }
			]
		}
	}
};
</script>

<template>
	<div>
		<div v-if="!loading" class="d-flex flex-column mb-5" :class="{ 'cursor-loading': loadingFollowups }">
			<template v-for="(question, index) in questions" :key="question.question.id">
				<div class="question-wrapper mb-3 d-block d-sm-flex">
					<div class="mb-2 mb-sm-0 me-2">
						<div class="rating-score d-flex justify-content-center align-items-center" :class="tdClass(question)" v-if="ratingToShow(question)">
							<h4 class="m-0" :style="`font-size: ${calcFontSize(ratingToShow(question))}px;`"> {{ formatNumber(ratingToShow(question)) }} </h4>
						</div>
						<div class="rating-score d-none d-sm-flex justify-content-center align-items-center" v-else></div>
					</div>

					<div class="w-100">
						<div class="card" :class="{ 'loading': loading }" >
							<div class="card-body py-0">
								<div class="navbar-header shadow-none d-flex align-items-center">
									<a class="card-title font-weight-bold py-4 m-0" v-html="tokenizeVariables(selectTitle(question.question.title))" style="cursor: pointer; flex-grow: 1;" data-bs-toggle="collapse" :href="`#question_${question.question.id}`" aria-expanded="false" :aria-controls="`question_${question.question.id}`"></a>
								</div>

								<div class="collapse table-responsive" :id="`question_${question.question.id}`" v-if="items[index] && items[index].length > 0">
									<ExportXLSX
										v-if="items[index]"
										:fileName="fileName(question)"
										:target="`question_${question.question.id}`"
										:btnText="$t('general.export_excel')"
										btnClass="btn btn-secondary text-nowrap"
										:hasData="true"
									>
										<template v-slot:header>
											<b-form-checkbox v-model="question.isPieChart" switch class="mt-1" size="lg">
												<label class="form-label m-0 font-size-14">{{ $t("feedback_analytics.show_pie") }}</label>
											</b-form-checkbox>
										</template>

										<data-table
											v-show="!question.isPieChart"
											:items="items[index]"
											:headers="fields(question.question.type)"
											:clickRowToExpand="true"
											class="border border-0"
											header-text-direction="center"
											header-item-class-name="center"
											body-text-direction="center"
											:sort-compare="customSort"
											sort-by="value"
											sort-type="desc"
											hide-footer
											@expand-row="row => hasFollowup(index) && toggleFollowups(question.question)"
										>
											<template #item-value="row">
												<div v-if="row.color" class="d-flex justify-content-center align-items-center" :style="`color: ${row.color};`">
													<span v-html="getAnswer(row.value, row.type, false, row.options)"></span>
												</div>

												<div v-else class="d-flex justify-content-center align-items-center">
													<span v-html="getAnswer(row.value, row.type, false, row.options)"></span>
												</div>
											</template>

											<template #item-recommendation_ratio="row">
												<div v-if="row.recommendation_ratio" class="d-flex justify-content-center align-items-center">
													{{ formatNumber(row.recommendation_ratio) }}%
												</div>
											</template>

											<template #item-ratio="row">
												<div class="d-flex justify-content-center align-items-center">
													{{ formatNumber(row.ratio) }}%
												</div>
											</template>

											<template #item-quantity="row">
												<div class="d-flex justify-content-center align-items-center">
													<div class="value">{{ formatNumber(row.quantity) }}</div>&nbsp;

													<div class="trend d-flex justify-content-center align-items-center" v-if="row.trend == 'none_before'">
														<p class="m-0"> ({{ $t('feedback_analytics.none_before') }}) </p>
													</div>

													<div class="trend d-flex justify-content-center align-items-center" v-else-if="row.trend == 0">
														<p class="m-0"> (0%) </p>
													</div>

													<div class="trend d-flex justify-content-center align-items-center"
													:class="`text-${row.trend >= 0 ? 'success' : 'danger'}`" v-else>
														<i class="arrow-trend" :class="{ 'mdi mdi-arrow-up': row.trend >= 0, 'mdi mdi-arrow-down': row.trend < 0 }"></i>
														<p class="m-0"> {{ `${formatNumber(row.trend)}%` }} </p>
													</div>
												</div>
											</template>

											<template #expand="row" v-if="question.question.type == 'rating' && hasFollowup(index)">
												<b-card class="bg-gray-100" :class="{ 'loading': loadingFollowups }">
													<template v-if="cachedFollowups[question.question.id] && cachedFollowups[question.question.id].data[row.value]">
														<div class="table-responsive">
															<data-table
																:headers="followupFields"
																:items="cachedFollowups[question.question.id].data[row.value]"
																class="border border-0 thead-light"
																header-text-direction="center"
																body-text-direction="center"
															></data-table>
														</div>
													</template>
													<template v-else-if="cachedFollowups[question.question.id] && !cachedFollowups[question.question.id].data[row.value]">
														{{ $t("feedback_analytics.no_follow_ups") }}
													</template>
												</b-card>
											</template>
										</data-table>
									</ExportXLSX>

									<!-- Pie Chart -->
									<template v-if="question.isPieChart">
										<!-- v-if="Object.values(series(items[index])).filter(n => n).length" -->
										<apexchart
											class="apex-charts mt-3"
											height="300"
											type="pie"
											dir="ltr"
											:series="series(items[index])"
											:options="chartOptions(items[index], question.question.type)"
										></apexchart>
										<!-- <span v-else><br>{{ $t("general.no_data") }}<br></span> -->
									</template>

									<div v-if="questions.length == 0">
										{{ $t('general.no_data') }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>

		<div v-else style='min-height: 200px;' class="card" :class="{'loading': loading}"></div>
	</div>
</template>
