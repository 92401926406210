<script>
import PageHeader from "@/components/page-header";
import Filters from "@/components/filter";
import Utils from "@/js/utils";
import DataUtils from "@/js/data-utils";

import TemplateCard from "./list-template-card";
import NewTemplateModal from "@/components/new-template-modal";

export default {
	data() {
		return {
			loading: false,
			filterCols: ["survey_filter", "companies", "search", "distribution_types"],
      templates: {},
      distributions: [],
      searchString: "",
      filter: {
        company_ids: [],
        survey_ids: [],
        distribution_types: []
      },
      filterParams: {},
      modal: { newTemplateModal: false }
		};
	},
	components: { PageHeader, Filters, TemplateCard, NewTemplateModal },
  watch: {
    filterParams: function () {
      this.searchTemplate(this.filterParams);
    },
    contract: {
      handler: function () {
        if (this.contract?.id) this.loadTemplates()
      },
      immediate: true
    }
  },
	methods: {
    ...DataUtils,
    async loadTemplates() {
      Utils.setLoading.bind(this)(true);
      this.distributions = await this.$store.dispatch("distributions/fetch");

      this.distributions.forEach(d => {
        if (Object.keys(d.mail_template_ids).length) {
          const defaultLocale = d.survey.default_locale;
          const template = d.mail_templates.find(mt => mt.language == defaultLocale);
          if (template?.id) {
            let dt = { ...d, type: d.type || "general" };
            if (!this.templates[template.id]) this.templates[template.id] = { template, distributions: [dt] };
            else this.templates[template.id].distributions.push(dt);
          }
        }
      });
      Utils.setLoading.bind(this)(false);
    },
    searchTemplate(params) {
      this.searchString = (params?.data.search || "").toUpperCase();
      ["company_id", "survey_id", "distribution_type"].forEach(field => this.filter[field+"s"] = params?.data[field] || params?.data[field+"s"] || []);
    },
    updateList(template) {
      if (template?.id) delete this.templates[template.id];
    }
	},
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    templatesToShow() {
      let result = this.templates;

      if (this.searchString.length) {
        result = Object.entries(result).reduce((res, [id, template]) => {
          if (template.distributions.some(t => `${t.company.name}|${t.survey.name}|${t.type}`.toUpperCase().includes(this.searchString))) res[id] = template;
          return res;
        }, {});
      }

      if (this.filter["company_ids"].length) {
        result = Object.entries(result).reduce((res, [id, template]) => {
          if (template.distributions.some(t => this.filter.company_ids.includes(t.company.id))) res[id] = template;
          return res;
        }, {});
      }

      if (this.filter["survey_ids"].length) {
        result = Object.entries(result).reduce((res, [id, template]) => {
          if (template.distributions.some(t => this.filter.survey_ids.includes(t.survey.id))) res[id] = template;
          return res;
        }, {});
      }

      if (this.filter["distribution_types"].length) {
        result = Object.entries(result).reduce((res, [id, template]) => {
          if (template.distributions.some(t => this.filter.distribution_types.includes(t.type))) res[id] = template;
          return res;
        }, {});
      }

      return result;
    }
  }
}
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('mail_template.list.title')" :tooltip="$t('mail_template.list.help')">
      <template v-slot:subtitle>
        <button class="btn btn-primary" @click="modal.newTemplateModal = !modal.newTemplateModal">
          {{ $t("mail_template.list.new_template") }}
        </button>
      </template>
    </PageHeader>

    <Filters @filter:created="saveFilterParams" @filter:change="saveFilterParams" :cols="filterCols" :notifyChange="true" />

    <div class="row" v-if="!loading">
      <template v-for="t in templatesToShow" :key="t.template.id">
        <TemplateCard v-if="t.template.id" @list_templates:update_list="updateList" :template="t.template" :distributions="t.distributions" />
      </template>
    </div>

    <div class="row" v-else><div class="col"><div class="loading" style="min-height: 280px;"></div></div></div>

    <b-modal v-model="modal.newTemplateModal" centered title-class="font-18" hide-footer hide-header size="lg">
      <NewTemplateModal v-if="modal.newTemplateModal" :modal="modal" :allDistributions="distributions" :contract="contract"/>
    </b-modal>
  </div>
</template>
