<script>
export default {
  data() {
    return {};
  },
  props: []
}
</script>

<template>
  <div class="page">
    <img class="thank-you-image" src="@/assets/images/reports/replab/thankyou.png">
  </div>
</template>
