<script>
import PageHeader    from "@/components/page-header";
import Filters       from "@/components/filter";
import Response      from "@/components/response";
import axios         from "axios";
import Utils         from "@/js/utils";
import FeedbackUtils from "@/js/feedback-utils";
import DataUtils     from "@/js/data-utils";
import dayjs         from "dayjs";

export default {
  data() {
    return {
      filterCols: ["daterange", "companies", "survey_filter", "finished", "page", "response_language", "question_type", "has_sentiment", "publish"],
      params: { page: 1 },
      loading: false,
      surveys: [],
      totalResponses: 0,
      responsesPerPage: 20,
      totalPages: 1,
      cache: {},
      api_permissions: [],
      loadingAction: false,
      yesNoOptions: { true: 'yes', false: 'no' },
      translations: { [this.$i18n.locale]: {} },
      filterParams: {},
      currentPageParams: {},
      user: {}
    };
  },
  components: { PageHeader, Filters, Response },
  async created() {
    this.user = await this.$store.dispatch("user/fetch");
    this.api_permissions = this.user.api_permissions;
  },
  mounted() {
    window.addEventListener("scroll", this.scrollBtnDisplay);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrollBtnDisplay);
  },
  watch: {
    "$i18n.locale": function () {
      if (!this.translations[this.$i18n.locale]) Object.values(this.cache).forEach(responses => responses.forEach(r => r.translated = false))
    },
    filterParams: function () {
      this.loadListener(this.filterParams);
    },
    contract: {
      handler: function () {
        if (this.contract?.id) {
          this.$store.dispatch("surveys/fetch", { contract_id: this.contract.id }).then(surveys => this.surveys = surveys);
        }
      },
      immediate: true
    }
  },
  methods: {
    ...FeedbackUtils,
    ...DataUtils,
    async loadListener(params) {
      this.cache = {};
      this.params = {
        page: Array.isArray(params.data.page) ? parseInt(params.data.page[0] || 1) : parseInt(params.data.page || 1),
        ...Utils.slice(params.data, ["company_ids", "survey_id", "start_date", "end_date", "response_language", "subscription_ids", "has_sentiment"]),
        ...(params.data.publish  != "all" && { published: params.data.publish  == "published" }),
        ...(params.data.finished != "all" && { finished:  params.data.finished == "completed" }),
        limit: this.responsesPerPage
      };

      await this.loadResponses();
    },
    async loadResponses() {
      Utils.setLoading.bind(this)(true);

      this.cache[this.params.page] = [];
      this.currentPageParams = Utils.deepClone(this.params);

      let res = await axios.get("/v3/feedback/responses", { params: this.params });

      let tempResponses = res.data.data;
      tempResponses.forEach(tempResponse => {
        tempResponse.manager_response_date = dayjs(tempResponse.manager_response_date).format("DD MMM YYYY");
        ["translated", "showMoreInfo"].forEach(field => {
          tempResponse[field] = false;
        });
      });

      this.cache[this.params.page] = tempResponses;
      this.totalResponses = res.data.count;
      this.totalPages = Math.ceil(this.totalResponses / this.responsesPerPage);

      Utils.setLoading.bind(this)(false);

      return this.cache[this.params.page];
    },
    async pageChange() {
      await this.$router.push({ path: this.$route.path, query: { ...this.$route.query, "pg": this.params.page } });
      await this.changePage();
      this.$nextTick(() => window.scrollTo(0, 0));
    },
    async changePage() {
      if (!this.cache[this.params.page]) await this.loadResponses();
      this.resetExpandButtons();
    },
    scrollBtnDisplay() {
      let scrollBtn = document.querySelector("#scroll-btn")
      window.scrollY > window.innerHeight
        ? scrollBtn?.classList.add("show")
        : scrollBtn?.classList.remove("show");
    },
    scrollWindow() {
      if (window.scrollY != 0) {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 10);
      }
    },
    async downloadXLSX() {
      Utils.setLoading.bind(this)(true);
      let response = await axios.get("/v3/feedback/responses.xlsx", { params: { ...this.currentPageParams, limit: null, page: null }, responseType: "blob" });
      let blob = new Blob([response.data], { type: "" });
      let link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${this.$t("surveys_responses.file_name")}.xlsx`;
      link.click();
      URL.revokeObjectURL(link.href);
      Utils.setLoading.bind(this)(false);
    }
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    showDownloadButton() {
      return this.totalResponses;
    }
  }
};
</script>

<template>
  <div class="w-100" :class="{ 'cursor-loading': loadingAction }">
    <PageHeader :title="$t('sidebar.feedback_responses')" />

    <div class="row">
      <div class="col-lg-12">
        <!-- Filters -->

        <Filters @filter:created="saveFilterParams" @filter:update="saveFilterParams" :cols="filterCols" />

        <!-- Time line -->
        <div class="card">
          <div class="card-body">
            <button v-if="showDownloadButton" class="btn btn-secondary float-end" @click="downloadXLSX" v-b-tooltip.hover :title="$t('surveys_responses.download_tooltip')">{{ $t("general.export_excel") }}</button>
            <div class="row justify-content-center mt-5 mb-5">
              <div class="col-lg-10">
                <ul class="verti-timeline list-unstyled" dir="ltr" id="responses-pages" v-if="!loading && surveys.length">
                  <template v-for="(response, respIndex) in cache[params.page]" :key="response.id">
                    <Response :response="response" :responseIndex="respIndex" :cache="cache" :params="params" :translations="translations" :api_permissions="api_permissions" :contract="contract" :surveys="surveys" :user="user" />
                  </template>
                </ul>

                <div style='min-height: 200px;' class="loading" v-if="loading"></div>

                <div class="d-flex align-items-center justify-content-center" v-if="!loading && totalResponses == 0"> <span class="font-size-16"> {{ $t("general.no_data") }} </span> </div>

                <b-pagination
                  v-if="totalResponses / responsesPerPage > 0"
                  class="d-flex justify-content-center mt-5"
                  v-model="params.page"
                  :total-rows="totalResponses"
                  :per-page="responsesPerPage"
                  aria-controls="responses-pages"
                  @update:modelValue="pageChange"
                ></b-pagination>

                <button id="scroll-btn" @click="scrollWindow()"> &uarr; </button>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
  </div>
</template>
