<script>
import PageHeader from "@/components/page-header";
import Settings   from "./settings/index";
import Questions  from "./questions/index";
import Logic      from "./logic/index";
import Share      from "./share/index";
import axios      from "axios";
import Utils      from "@/js/utils";
import DataUtils  from "@/js/data-utils";

export default {
  data() {
    return {
      loading: false,
      currentTab: 'settings',
      companies: [],
      allTopics: {},
      survey: {
        name: '',
        languages: [],
        default_locale: null,
        company_ids: [],
        questions: [],
        redirect_url: null,
        redirect_message: null,
        color_hex: '#20a0cb'
      },
      globalQuestions: [],
      isDirty: false,
      confirmChangeTab: false,
      missingTranslations: true,
      findTranslationButtonText: "translate_manually",
      questionHash: {
        "date":                { component: "DateField", icon: "ion ion-md-calendar", compatibles: ["date"] },
        "drop_down":           { component: "DropdownField", icon: "mdi mdi-text-box-check-outline", compatibles: ["drop_down", "enum"] },
        "enum":                { component: "EnumField", icon: "mdi mdi-radiobox-marked", compatibles: ["enum", "drop_down", "open_answer", "open_answer_long"] },
        "location":            { component: "OpenAnswerField", icon: "mdi mdi-map-marker-outline", compatibles: ["location", "open_answer", "open_answer_long"] },
        "multiple_select":     { component: "MultipleChoiceField", icon: "mdi mdi-checkbox-marked-outline", compatibles: ["multiple_select"] },
        "open_answer":         { component: "OpenAnswerField", icon: "mdi mdi-text-short", compatibles: ["open_answer", "open_answer_long"] },
        "open_answer_long":    { component: "OpenAnswerField", icon: "mdi mdi-card-text-outline", compatibles: ["open_answer_long", "open_answer"] },
        "past_date":           { component: "DateField", icon: "ion ion-md-calendar", compatibles: ["past_date", "date"] },
        "rating":              { component: "RatingField", icon: "mdi mdi-star", compatibles: ["rating"] },
        "trip_advisor_iframe": { component: "TripadvisorField", icon: "fab fa-tripadvisor", compatibles: ["trip_advisor_iframe"] },
        "yes_no":              { component: "YesNoField", icon: "mdi mdi-check-bold", compatibles: ["yes_no"] },
        "nps":                 { component: "RatingField", icon: "mdi mdi-gauge", compatibles: ["nps", "rating"] },
        "welcome":             { component: "Welcome", icon: "mdi mdi-home-circle-outline" },
        "thank_you":           { component: "ThankYou", icon: "far fa-smile-beam" }
      },
      pointLogicIndex: null
    };
  },
  components: {
    PageHeader,
    Settings,
    Questions,
    Logic,
    Share
  },
  async created() {
    if(!this.$route.query['tab']) this.$router.push({ query: {...this.$route.query, tab: this.currentTab } });
    else if(!this.creation) this.currentTab = this.$route.query['tab'];

    if(this.$route.params.id) await this.loadSurvey(this.$route.params.id);
  },
  mounted() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
  methods: {
    ...DataUtils,
    async loadSurvey(survey_id) {
      Utils.setLoading.bind(this)(true);
      let promises = [];

      promises.push(axios.get(`/v3/feedback/surveys/${survey_id}`)
      .then(response => {
        this.survey = response.data.data;
        if (!this.survey.color_hex) this.survey.color_hex = '#20a0cb'
      }));

      await Promise.all(promises);

      Utils.setLoading.bind(this)(false);
    },
    getGlobalQuestions() {
      axios.get("/v3/feedback/questions/global", { params: {
        subscription_id: this.contract.id,
      }}).then(response => {
        this.globalQuestions = response.data.data;
      });
    },
    changeTab(page) {
      if (!this.confirmChangeTab && this.isDirty && this.confirmStayInDirtyForm()) return;
      this.confirmChangeTab = true;
      this.currentTab = page;
      this.$router.push({ query: {...this.$route.query, tab: page } });
    },
    confirmStayInDirtyForm() {
      return !window.confirm(this.$t("new_survey.confirm_leave"));
    },
    beforeWindowUnload(event) {
      if (this.isDirty && this.confirmStayInDirtyForm()) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    dirtyUpdate(val) {
      this.isDirty = val;
      if (this.isDirty) this.confirmChangeTab = false;
    },
    saveLocally(newSurvey) {
      this.survey = newSurvey;
      this.$forceUpdate();
    },
    saveChanges() {
      this.isDirty = false;
      this.changeTab("questions");
      if (window.scrollY != 0) {
        setTimeout(() => {
          window.scrollTo(0, 50);
        }, 10);
      }
    },
    updateMissingTranslations(val) {
      if (val && !this.missingTranslations) this.findTranslationButtonText = "translate_manually";
      this.missingTranslations = val;
    },
    findMissingTranslations() {
      this.findTranslationButtonText = "next";
      this.$refs.questions_tab.pointMissingTranslation();
    },
    autotranslate() {
      this.$refs.questions_tab.autotranslate();
    },
    pointLogic(index) {
      this.pointLogicIndex = index;
      setTimeout(() => {
        this.pointLogicIndex = null;
      }, 1000);
    }
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    creation() {
      return this.$route.name == "feedback_new_survey";
    },
    canShowMissingTranslationsWarning() {
      return this.currentTab == 'questions' && this.survey.questions.length && this.survey.languages.length > 1;
    },
    manualTranslationLabel() {
      return this.$t(`new_survey.questions.${this.findTranslationButtonText}`);
    },
    pageTitle() {
      if (this.creation) return this.$t("new_survey.title");
      return this.$t("new_survey.edit_title")  + (this.loading ? "" : ` - ${this.survey.name}`);
    },
    topics() {
      let companiesHash = this.companies.filter(c => this.survey?.company_ids?.includes(String(c.id)));
      let topics = [];

      companiesHash?.forEach(company => {
        if (company.property_type && company.property_type.length > 0) {
          this.allTopics[company.property_type]?.forEach(topic => {
            if (!topics.includes(topic)) topics.push(topic);
          });
        }
      });
      return topics;
    },
  },
  watch: {
    contract: {
      handler: async function () {
        if (this.contract?.id) {
          this.companies = await this.$store.dispatch("companies/fetch", { contract_id: this.contract.id });
          const topics   = await this.$store.dispatch("topics/fetch");
          this.allTopics = this.topicsByPropertyType(topics);
          this.getGlobalQuestions();
        }
      },
      immediate: true
    }
  }
};
</script>

<template>
  <div class="w-100">
    <!-- Navigation... -->
    <div class="d-flex flex-column mb-4">
      <div class="d-flex justify-content-between align-items-center row" style="min-height: 95px;">
        <PageHeader class="col-12 col-md-6" :title="pageTitle" />

        <!-- autotranslate -->
        <div :class="{ 'fade-out-5': !missingTranslations }" class="col-12 col-md-6" v-if="canShowMissingTranslationsWarning" id="not-published-alert" :visible="true">
          <div class="d-flex justify-content-center mt-sm-3 float-end">
            <div :class="{ 'alert-warning': missingTranslations, 'alert-success': !missingTranslations }" class="alert d-flex alert-box align-items-center" role="alert">
              <div class="row" v-if="missingTranslations">
                <div class="d-flex align-items-center col-12 col-sm-6">
                  <i class="mdi mdi-alert-outline font-size-24 me-2"></i>
                  <span class="font-size-14 mb-0 text-center"> {{ $t(`new_survey.questions.missing_translations`) }} </span>
                </div>
                <div class="col-6 col-sm-3 mt-2 mt-sm-0 d-flex justify-content-center px-0">
                  <button class="btn btn-secondary" @click="findMissingTranslations" :title="$t('new_survey.questions.translate_manually_tooltip')"> {{ manualTranslationLabel }} </button>
                </div>
                <div class="col-6 col-sm-3 mt-2 mt-sm-0 d-flex justify-content-center px-0">
                  <button class="btn btn-secondary" @click="autotranslate" :title="$t('new_survey.questions.autotranslate_tooltip')"> {{ $t("new_survey.questions.autotranslate") }} </button>
                </div>
              </div>

              <div v-else class="d-flex align-items-center">
                <i class="mdi mdi-check-all font-size-24 me-2"></i>
                <span class="font-size-14 mb-0"> {{ $t(`new_survey.questions.ok_translations`) }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-wrapper shadow-bottom pb-3">
        <div class="anchor-wrapper">
          <a class="button-tab" :class="{ 'selected-tab': currentTab == 'settings' }" @click="changeTab('settings')">
            {{ $t("new_survey.settings.title") }}
          </a>
          <div class="line" v-if="currentTab == 'settings'"></div>
        </div>

        <div class="anchor-wrapper">
          <a class="button-tab" :class="{ 'selected-tab': currentTab == 'questions' }" @click="changeTab('questions')" v-if='survey.id'>
            {{ $t("new_survey.questions.title") }}
          </a>
          <div class="line" v-if="currentTab == 'questions'"></div>
        </div>

        <div class="anchor-wrapper">
          <a class="button-tab" :class="{ 'selected-tab': currentTab == 'logic' }" @click="changeTab('logic')" v-if='survey.id'>
            {{ $t("new_survey.logic.title") }}
          </a>
          <div class="line" v-if="currentTab == 'logic'"></div>
        </div>

        <div class="anchor-wrapper">
          <a class="button-tab" :class="{ 'selected-tab': currentTab == 'share' }" @click="changeTab('share')" v-if='survey.id'>
            {{ $t("new_survey.share.title") }}
          </a>
          <div class="line" v-if="currentTab == 'share'"></div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12" v-if="!loading">
        <Settings v-show="currentTab == 'settings'"  @dirtyUpdate="dirtyUpdate" @savedChanges="saveChanges" :creation="creation" :companies="companies" :survey="survey" :class="{'loading': loading}" @updateSurvey="saveLocally"/>
        <Questions v-if="currentTab == 'questions'" :creation="creation" :survey="survey" :class="{'loading': loading}" :questionHash="questionHash" :topics="topics" :globalQuestions="globalQuestions" @verifyTranslations="updateMissingTranslations" @changeTab="changeTab" @pointLogic="pointLogic" ref="questions_tab" :contract="contract" :companies="companies"/>
        <Logic v-show="currentTab == 'logic'" :survey="survey" :class="{'loading': loading}" :questionHash="questionHash" @dirtyUpdate="dirtyUpdate" :pointLogicIndex="pointLogicIndex" />
        <Share v-show="currentTab == 'share'" :survey="survey" :companies="companies" :contract="contract" :class="{'loading': loading }" />
      </div>
    </div>
  </div>
</template>
