<script>
import Utils      from "@/js/utils";
import OleryTable from "@/components/olery-table";

export default {
  data() {
    return {
      sortBy: "property"
    };
  },
  props: ["series", "comparisonType", "keys", "labels"],
  components: { OleryTable },
  methods: {
    cellClass(col) {
      if (!/property|rating/.test(col)) return "h-inherit p-0";
      return "";
    },
    tdClass(value) {
      return Utils.ratings.toCss(value);
    }
  },
  computed: {
    rows() {
      return Object.keys(this.series).flatMap(topic => {
        let filtered = [];
        if (this.comparisonType == "competitors") filtered = Object.keys(this.series[topic]).filter(p => !/hidden|color/.test(p) && topic == "overall");
        else filtered = Object.keys(this.series[topic]).filter(p => !/hidden|color/.test(p) && this.series[topic][p].belongsTo == "own");
        return filtered.map(property => {
          let data = this.series[topic][property].values;
          if (!data.length) data = Array.from({ length: this.keys.length }, () => null);
          return {
            property: this.series[topic][property].company.name,
            rating:   this.$t(`ratings.rating_comparison.kpis.${this.series[topic][property].topic}`),
            ...data.reduce((res, curr, i) => {
              res[this.keys[i]] = curr ? Number(curr) : curr;
              res[`${this.keys[i]}_review_count`] = this.series[topic][property].review_count[i];
              return res;
            }, {})
          };
        });
      });
    },
    cols() {
      const baseCols = [
        { value: "property", text: this.$t("daily_operations.analytics.property_group"), sortable: true },
        { value: "rating",   text: this.$t("filter.rating"),                             sortable: true }
      ];
      return baseCols.concat(this.ratingCols);
    },
    ratingCols() {
      return this.keys.map((l, i) => ({ value: l, text: this.labels[i], sortable: true, type: "number" }));
    }
  }
}
</script>

<template>
  <OleryTable
    class="mt-3"
    :ratingCols="ratingCols.map(r => r.value)"
    :hideExport="true"
    fileName="reputation-analytics-ratings-comparison"
    :dataTable="{
      tableHeaderClass:    'thead-light',
      items:               rows,
      headers:             cols,
      headerTextDirection: 'center',
      bodyTextDirection:   'center',
      sortBy:              sortBy,
      sortType:            'desc',
      pagination:          false
    }"
  />
</template>