<script>
import axios from 'axios'
import Filters from "@/components/filter";
import Swal from "sweetalert2";
import DataUtils from "@/js/data-utils";

export default{
  data() {
    return {
      filter: { property_type: null, regions: null, city: null, search: "" },
      filters: [],
      selectedCompanies: [],
      editedGroup: { companies: [] },
      toDelete: [],
      toAdd: [],
      filterParams: {},
      saving: false
    }
  },
  props: ['group', 'groups', 'allCompanies', 'contractId'],
  components: { Filters },
  created () {
    this.editedGroup = Object.assign({}, this.group)
  },
  computed: {
    computedCompanies() {
      return this.allCompanies.filter(n => {
        if (this.groupCompanies[n.id]) return false
        return !Object.keys(this.filter).map(f => {
          if (f == 'search' && this.filter[f]?.length) return `${n.id} + ${(n.name || "").toUpperCase()}`.includes(this.filter[f].toUpperCase())
          return !this.filter[f] || this.filter[f].length == 0 || !n[f] || (typeof(n[f])=='object'
          ? this.filter[f].filter(c => n[f].includes(c)).length > 0
          : this.filter[f].includes(n[f]))
        }).includes(false)
      })
    },
    groupCompanies() {
      let companies = {}
      this.editedGroup.companies.forEach(c => {
        if (!this.toDelete.includes(c))
          companies[c.id] = c
      })
      this.toAdd.forEach(c => companies[c.id] = c)

      return companies
    }
  },
  watch: {
    filterParams: function () {
      this.loadListener(this.filterParams);
    }
  },
  methods: {
    ...DataUtils,
    async save() {
      this.saving = true;
      if (!/\S/.test(this.editedGroup.name)) {
        return Swal.fire(this.$t('customer_filters.warning'), this.$t('customer_filters.give_a_group_name'), "warning");
      }
      if (this.editedGroup.id) {
        let group    = this.groups.find(g => g.id == this.editedGroup.id)
        if (this.editedGroup.name != group.name) {
          let response = await axios.post(`/v3/groups/${this.editedGroup.id}`, { name: this.editedGroup.name, contract_id: this.contractId }).catch(this.error)
          if (!response || !response.data) return
          group.name = response.data.data.name
        }
      }
      else {
        let response = await axios.put(
          '/v3/groups', { name: this.editedGroup.name, subscription_id: this.contractId }
        ).catch(this.error)
        if (!response || !response.data) return

        this.editedGroup = Object.assign(this.editedGroup, response.data.data)
        this.editedGroup.companies = []
      }
      this.$emit('groupChanged', this.editedGroup)

      await this.saveCompanies();
      Swal.fire(this.$t('customer_filters.done'), this.$t('customer_filters.group_successfully_created'), "success");
      this.stopEdition();
      this.saving = false;
    },
    async saveCompanies() {
      let toDeleteIds = this.toDelete.map(c => c.id)
      let toAddIds    = this.toAdd.map(c => c.id)

      let promises = []
      if (toDeleteIds.length)
        promises.push(axios.delete(`/v3/groups/${this.editedGroup.id}/companies/${toDeleteIds.join(',')}`).catch(this.error))

      if (toAddIds.length)
        promises.push(axios.post(`/v3/groups/${this.editedGroup.id}/companies`, { company_ids: toAddIds, subscription_id: this.contractId }).catch(this.error))

      await Promise.all(promises)
      this.updateCompanies()
    },
    updateCompanies() {
      this.toDelete.forEach(c => {
        let i = this.editedGroup.companies.findIndex(ce => ce.id == c.id)
        if (i >= 0) this.editedGroup.companies.splice(i, 1)
      })
      // Empty toAdd to avoid conflict with groupCompanies
      let companies = this.toAdd
      this.toAdd    = []
      companies.forEach(c => this.editedGroup.companies.push(c))
    },
    addCompanies() {
      let companies = this.selectedCompanies.map(l => {
        let id = Number(l.split(' - ')[0])
        return this.allCompanies.find(c => c.id == id)
      })
      companies.forEach(company => {
        let i = this.toDelete.findIndex(c => c.id == company.id)
        if (i >= 0) this.toDelete.splice(i, 1)
        else this.toAdd.push(company)
      })
    },
    removeCompany(company) {
      let i = this.toAdd.findIndex(c => c.id == company.id)
      if (i >= 0) this.toAdd.splice(i, 1)
      else this.toDelete.push(company)
    },
    stopEdition() {
      this.$emit('stopEdition');
      this.toDelete = []
      this.toAdd    = []
    },
    loadListener(params) {
      Object.keys(this.filter).forEach(f => this.filter[f] = params.data[f])
    },
  }
}
</script>

<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h3>{{ $t('customer_filters.editing_a_group') }}</h3>
        <div class="row mt-4">
          <div class="col-12 col-md-7 mb-3">
            <b-input-group>
              <b-form-input class="pe-2 border-0 rounded font-size-20 position-relative" v-model="editedGroup.name" :placeholder="$t('customer_filters.group_name_placeholder')"></b-form-input>
              <i class="mt-1 mdi mdi-pencil mdi-24px position-absolute" style="top:0;right:7px;"></i>
            </b-input-group>
          </div>

          <div class="col-12 col-md-5 d-flex align-items-center mb-3">
            <button type="submit" class="btn btn-primary" :disabled="saving" @click='save'>{{ $t('customer_filters.save_group') }}</button>
            <button type="submit" class="btn btn-secondary ms-3" @click='stopEdition'>{{ $t('customer_filters.cancel_edition') }}</button>
          </div>

          <div class="col-md-6 all-companies px-3">
            <h4>{{ $t('customer_filters.companies_to_be_added') }}</h4>
            <Filters @filter:created="saveFilterParams" @filter:update="saveFilterParams" @filter:change="saveFilterParams" :cols='Object.keys(filter)' notifyChange="true" class='embedded'/>
            <label class='mt-4 form-label' >{{ $t('customer_filters.select_and_add') }}</label>
            <select style='min-height: 500px; width: calc(100% - 50px); outline: none;' class='float-start text-body border' v-model="selectedCompanies" multiple>
              <option class='pt-2 pb-2 ps-2 border-light' v-for='c in computedCompanies' :key='c.id'>
              {{c.id}} - {{c.name}} - {{c.city}} - {{c.regions.join(', ')}}
              </option>
            </select>
            <button @click='addCompanies' class='float-start ms-3 btn-primary border-0 rounded py-1 px-2' style='margin-top: 220px;'> >> </button>
          </div>

          <div class="col-md-6 group-companies ps-3 mt-4 mt-md-0">
            <div class='mb-4' v-if='toDelete.length || toAdd.length'>
              <h4>{{ $t('customer_filters.added') }}</h4>
              <div style='height: 200px; overflow-y: auto' class=" alert alert-warning p-3">
                <div class='mb-3' v-if="toDelete.length">
                  <label class="form-label">{{ $t('customer_filters.removed') }}</label>
                  <div v-for='c in toDelete' :key='c.id'>
                    {{c.name}}
                  </div>
                </div>
                <div class='mb-3' v-if="toAdd.length">
                  <label class="form-label">{{ $t('customer_filters.added') }}</label>
                  <div v-for='c in toAdd' :key='c.id'>
                    {{c.name}}
                  </div>
                </div>
              </div>
            </div>

            <h4>{{ $t('customer_filters.companies_in_the_group') }}</h4>
            <div class='border-light bb-1 ps-2' v-for='company in Object.values(groupCompanies)' :key='company.id'>
              <strong style='display: inline-block; min-width: 60px'>{{company.id}}</strong> - {{company.name}}
              <button style='font-size: 17px;' class='btn' @click='removeCompany(company)'><i class='mdi mdi-delete text-right'></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
