<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      tableData: [],
      tableRows: { "review_count": "Number of Reviews", "average_rating": "Avg. Review Score", "ranking": "Rank", "5star_percent": "%5 Star Reviews" },
      previous: {},
      cols: [],
      rows: []
    };
  },
  components: { ExportExcel },
  props: ["title", "company", "identifier", "release_date", "source"],
  created() {
    if (this.source != 'trip_advisor') delete this.tableRows['ranking']

    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      compset: false,
      source:  this.source,
      format:  'hash',
    }}).then(response => {
      this.tableData = response.data.data;
      this.previous  = this.tableData.find(e => e.month_label == "previous");
      this.tableData = this.tableData.filter(e => e.month_label != "previous");

      this.cols = [{ label: "", field: "field" }, { label: "Previous 12 Months", field: "previous" }];
      this.tableData.forEach(el => {
        this.cols.push({ label: el.month_label, field: el.month_label });
      });

      Object.keys(this.tableRows).forEach(row => {
        let obj = {};
        this.cols.forEach(col => {
          let data = this.tableData.find(e => e.month_label == col.field);
          if (data != null) {
            if (data[row] == null) obj[col.field] = "-";
            else obj[col.field] = data[row];
            if (row == "ranking" && obj[col.field] != "-") obj[col.field] = "#" + obj[col.field];
            else if (row != "review_count") obj[col.field] = Utils.round(Number(obj[col.field]), 2);
            if (row == "5star_percent" && obj[col.field] != "-") obj[col.field] += "%";
          }
          else if (this.tableRows[row] == null) obj["field"] = "-";
          else obj["field"] = this.tableRows[row];
        });
        if (this.previous?.[row] == null) obj["previous"] =  "-";
        else if (!/review_count|ranking/.test(row)) obj["previous"] = Utils.round(Number(this.previous[row]), 2);
        else if (row == "ranking") obj["previous"] = `#${this.previous[row]}`;
        else obj["previous"] = this.previous[row];

        if(row == "5star_percent") obj["previous"] += "%";
        this.rows.push(obj);
      });
    });
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th v-for="col in cols" :key="col.field">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in rows" :key="row.field">
            <template v-for="col in cols" :key="col.field">
              <td>{{ row[col.field] }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
