var ratings = {

  mod4: (value) => {
    return Math.floor((value - 1) / 4) * 4
  },

  format: (value) => {
    if (!value && value != '0') return '-'
    value = parseFloat(value).toFixed(1)
    return value == 10 ? '10' : value
  },

  toCss: (value10) => {
    return ratings.toCss100(parseFloat(value10) * 10)
  },

  toCss100: (value) => {
    if (!value && value != '0') return 'rating-unknown'

    value = parseFloat(value)
    if (value <= 4)  return 'rating0-4'
    if (value >= 97) return 'rating97-100'

    return `rating${ratings.mod4(value) + 1}-${ratings.mod4(value) + 4}`
  },

  sentScore: (pos, neg, scale) => {
    if (!scale) scale = 10
    var pScale    = scale/2 
    var nominator = pos - neg

    var total = nominator > 0 ? pos : neg
    var score = pScale + (total ? pScale * nominator / total : 0)
    return score
  },
}
export default ratings