<script>
import axios from 'axios'

import appConfig from "@/app.config";

import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import EditGroup from "./edit-group";
import EditFilter from "./edit-filter";

/**
 * Customised filters component
 */
export default {
  page: {
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    PageHeader,
    Multiselect,
    EditFilter,
    EditGroup
  },
  data() {
    return {
      title: this.$t('customer_filters.title'),
      type: 'numerical',
      filters: [],
      groups: [],
      allCompanies: [],
      selectedGroup: null,
      selectedFilter: null,
      editedGroup: { companies: [] },
      editedFilter: null,
      editMode: false,
      groupEditMode: false,
      modals: {
        editFilter:  false,
        groupFilter: false
      }
    };
  },
  computed: {
    filterColumns () {
      return [
        { value: 'icon',    text: ' ',                                     sortable: false },
        { value: 'title',   text: this.$t('customer_filters.filter_name'), sortable: true  },
        { value: 'actions', text: this.$t('customer_filters.actions'),     sortable: false },
      ]
    },
    customerFilters () {
      let filters       = [...this.filters]
      let presentGroups = this.filters.map(f => f.groups).flat()
      let groups        = this.groups.filter(g => !presentGroups.find(ga => ga.id == g.id))
      if (groups.length > 0)
        filters.push({title: this.$t('customer_filters.groups_without_filter'), groups: groups})
      return filters
    },
    contract() {
      return this.$store.getters["contract/currentContract"];
    }
  },
  watch: {
    contract: {
      handler: function () {
        if (this.contract?.id) this.loadFields()
      },
      immediate: true
    }
  },
  async created () {
    this.$store.dispatch('user/fetch').then(async (data) => {
      if (!data.destination_contracts) return
      if (this.contract) this.loadFields()
    })
  },
  methods: {
    async loadFields() {
      await this.loadFilters()
      await this.loadGroups()
      await this.loadCompanies()
    },
    filterRow(f, g) {
      if (!f) f = {}
      if (!g) g = {}
      return { filter_id: f.id, filter_name: f.name, group_id: g.id, group_name: g.name, companies: g.companies }
    },
    async loadFilters () {
      return axios.get(`/v3/contracts/${this.contract.id}/customer_filters`).then(response => {
        this.filters = response.data.data
      })
    },
    async loadGroups () {
      return axios.get("/v3/groups", { params: { from_user: true, companies: true, subscription_id: this.contract.id  } }).then(response => {
        this.groups = response.data.data
      })
    },
    async loadCompanies () {
      return axios.get(`/v3/contracts/${this.contract.id}/companies`, {}).then(response => {
        this.allCompanies = response.data.data
      })
    },
    editFilter (filter) {
      this.editedFilter = filter
      this.modals.editFilter = true;
    },
    updateGroup(group) {
      let index = this.groups.findIndex(g => g.id == group.id)
      if (index >= 0)
        this.groups[index] = group
      else
        this.groups.push(group)

      this.filters.forEach(f => {
        let index = f.groups.findIndex(g => g.id == group.id)
        if (index >= 0) f.groups[index] = group
      })
    },
    updateFilter(filter) {
      let index = this.filters.findIndex(f => f.id == filter.id)
      if (index >= 0)
        this.filters[index] = filter
      else
        this.filters.push(filter)
      this.selectedFilter = filter
    },
    createGroup () {
      this.editMode    = true
      this.editedGroup = {name: '', companies: []}
    },
    editGroup (group) {
      this.editMode    = true
      let fullGroup    = this.groups.find(g => g.id == group.id)
      this.editedGroup = fullGroup
    },
    async removeGroupFromFilter(group, filter) {
      if (!this.updateGroupFilter(group, null)) return
      let index = filter.groups.findIndex(g => g.id == group.id)
      if (index >= 0) filter.groups.splice(index, 1)
    },
    async addGroupToFilter() {
      if (!this.updateGroupFilter(this.editedGroup, this.selectedFilter.id)) return
      this.editedGroup.filter_id = this.selectedFilter.id
      this.selectedFilter.groups.push(this.editedGroup)
      this.modals.groupFilter = false;
    },
    async updateGroupFilter(group, value) {
      let response = await axios.post(`/v3/groups/${group.id}`, { filter_id: value }).catch(this.error)
      if (!response || !response.data) return false
      Swal.fire(this.$t('customer_filters.done'), this.$t('customer_filters.group_successfully_saved'), "success");
      return true
    },
    async deleteGroup (group) {
      if (confirm(this.$t('customer_filters.want_to_delete_group'))) {
        let response = await axios.delete(`/v3/groups/${group.id}`).catch(this.error)
        if (!response || !response.data) return
        Swal.fire(this.$t('customer_filters.done'), this.$t('customer_filters.group_successfully_deleted'), "success");
        let i = this.groups.findIndex(g => g.id == group.id)
        this.groups.splice(i, 1)
        this.filters.forEach(f => {
          let i = f.groups.findIndex(g => g.id == group.id)
          if (i>=0) f.groups.splice(i, 1)
        })
      }
    },

    openAddToFilterModal (group) {
      this.editedGroup  = group
      this.modals.groupFilter = true;
    },
    createFilter () {
      this.editFilter({ title: '', groups: [] })
    },
    stopEdition () {
      this.editMode = false
    },
    error (error) {
      return Swal.fire(this.$t('general.error'), this.$t('general.contact_support') + JSON.stringify(error.response.data), 'error')
    },
  }
};
</script>

<template>
  <div class='customised-groups w-100'>
    <div class="w-100">
      <PageHeader :title="$t('customer_filters.title')" :tooltip="$t('customer_filters.help')" />

      <div class="row">
        <div class="col-lg-12">
          <div class="card" v-if='!editMode'>
            <div class="card-body">
              <div class="row">
                <div class="form-group mb-3 col-12 col-md-8 col-xl-5" v-if="customerFilters && customerFilters.length > 0">
                  <label class="form-label">{{ $t('customer_filters.filters_and_groups') }}</label>
                  <data-table
                    :items="customerFilters"
                    :headers="filterColumns"
                    hide-footer
                    :clickRowToExpand="true"
                    table-class-name="border border-0"
                  >
                    <template #item-title="row">
                      <div class='btn text-start w-100 h-100 p-3' :class='{"font-weight-bold": !row.id}'>
                      {{ row.title || row.key }}
                      </div>
                    </template>

                    <template #item-actions="row">
                      <div @click="editFilter(row)" class="me-2 btn p-3" :title="$t('customer_filters.edit_filter')" v-if='row.id'>
                        <i class='mdi mdi-pencil'></i>
                      </div>
                    </template>

                    <template #expand="row">
                      <div style="padding: 15px">
                        <div class='border-top'>
                          <b-row class="pb-1 pt-1 border-bottom d-flex align-items-center" v-if='row.groups.length == 0' style='background: #fafafa; line-height: 20px'>
                            <b-col sm="12" class='text-center p-2'>{{ $t('customer_filters.no_groups') }}</b-col>
                          </b-row>
                          <b-row class="pb-1 pt-1 border-bottom d-flex align-items-center" v-for='group in row.groups' :key='group.id' style='background: #fafafa; line-height: 20px'>
                            <b-col sm="1"></b-col>
                            <b-col sm="8">{{ group.name }}</b-col>
                            <b-col sm="3" class='text-right font-size-18 d-flex'>
                              <i class='mdi mdi-cancel text-danger ps-2 pe-2' :title="$t('customer_filters.group_not_editable')" v-if='!group.from_user'></i>
                              <span @click="editGroup(group)" class="btn d-inline p-2" :title="$t('customer_filters.edit_group')" v-if='group.from_user'>
                                <i class='mdi mdi-pencil'></i>
                              </span>
                              <span @click="deleteGroup(group)" class="btn d-inline p-2" :title="$t('customer_filters.delete_group')" v-if='group.from_user'>
                                <i class='mdi mdi-delete' ></i>
                              </span>
                              <span @click="removeGroupFromFilter(group, row)" class="btn d-inline p-2" :title="$t('customer_filters.remove_group_from_filter')" v-if='row.id'>
                                <i class='mdi mdi-close-box-outline' ></i>
                              </span>
                              <span @click="openAddToFilterModal(group)" class="btn d-inline p-2" :title="$t('customer_filters.add_group_to_filter')" v-if='!row.id'>
                                <i class='mdi mdi-arrow-up' ></i>
                              </span>
                            </b-col>
                          </b-row>

                          <!-- <b-button size="sm" class='mt-2 mb-2 float-end' @click="row.toggleDetails">
                            <i class='mdi mdi-close'></i>
                            {{ $t('customer_filters.hide_groups') }}
                          </b-button> -->
                        </div>
                      </div>
                    </template>

                    <template #empty-message>
                      <h4>{{ $t('customer_filters.no_options') }}</h4>
                    </template>
                  </data-table>
                </div>
                <div class="form-group col-12 col-md-7 d-print-none pt-1">
                  <button type="submit" class="btn btn-secondary ms-3 mt-4" @click='createGroup'>{{ $t('customer_filters.create_group') }}</button>
                </div>
              </div>
            </div>
          </div>

          <!-- Edition Mode -->
          <EditGroup @stopEdition="stopEdition" @groupChanged="updateGroup" :group='editedGroup' :groups='groups' :allCompanies='allCompanies' :contractId='contract.id' v-if='editMode && editedGroup && contract'/>

          <b-modal v-model="modals.groupFilter" id="group-filter-modal" :title="$t('customer_filters.add_group_to_filter')">
            <h4 class='mb-4'>{{ $t('customer_filters.group_name') }}&nbsp;{{editedGroup.name}}</h4>
            <label class="form-label">{{ $t('customer_filters.select_a_filter_to_set') }}</label>
            <multiselect v-model="selectedFilter" :options="filters" label="title" track-by="title" :showLabels='false' :placeholder="$t('customer_filters.select_a_filter_to_set')">
              <template v-slot:noOptions>{{ $t('customer_filters.no_filter_options') }}</template>
            </multiselect>
            <template #footer>
              <button type="submit" class="btn btn-primary mt-2 me-auto" @click='createFilter'>{{ $t('customer_filters.create_filter') }}</button>
              <b-button @click="modals.groupFilter = false">{{ $t("general.cancel") }}</b-button>
              <b-button class='btn btn-primary' @click="addGroupToFilter" :disabled="!selectedFilter">{{ $t("general.save") }}</b-button>
            </template>
          </b-modal>

          <b-modal v-model="modals.editFilter" id="filter-modal" :title="$t('customer_filters.edit_filter')" hide-footer header-class="border-0 pb-0 ps-4" body-class="p-2" centered>
            <EditFilter @filterChanged="updateFilter" :modals="modals" :filter='editedFilter' :contract_id='contract.id' v-if="contract"/>
          </b-modal>
        </div>
      </div>

    </div>
  </div>
</template>
