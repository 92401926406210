<script>
import Utils      from "@/js/utils";
import Background from "@/components/bg-pattern";

export default {
  props: ['title', 'value', 'icon', 'change', 'color', 'trend', 'trendLabel', 'valueSymbol'],
  components: { Background },
  computed: {
    periodMessage () {
      if (!this.change) return ''
      if (this.trendLabel) return this.trendLabel

      if (this.trend == 'pop') return this.$t('stat.from_prev_period')
      return this.$t('stat.from_prev_year')
    }
  },
  methods: {
    formatNumber(v) {
      if (isNaN(v)) return v
      return Utils.formatNumber(v);
    }
  }
};
</script>


<template>
  <div class="card mini-stat b-pattern position-relative">
    <Background />
    <div class="card-body mini-stat-img">
      <div class="mini-stat-icon">
        <i :class="`${icon} text-primary float-end h4`"></i>
      </div>
      <h6 v-if="title" class="text-uppercase mb-3 mt-0">{{title}}</h6>
      <h5 class="mb-2 font-size-22">{{ formatNumber(value) + (valueSymbol || "") }}</h5>
      <p class="text-muted mb-0">
        <span :class="`text-${color} me-2`">
          {{change ? formatNumber(change) + '%' : ''}}
          <i
            :class="{
              'mdi mdi-arrow-up': `${color}` === 'success',
              'mdi mdi-arrow-down': `${color}` === 'danger'
          }"
          ></i>
        </span> {{ periodMessage }}
      </p>
    </div>
  </div>
</template>
