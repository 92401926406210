<script>
export default {
  props: ["title", "subtitle", "tooltip"]
}
</script>

<template>
  <div class="page-title-box d-flex align-items-center justify-content-between flex-wrap" style="row-gap: 10px;">
    <div class="d-flex align-items-center">
      <h4 class="font-size-20 m-0">
        <template v-if="title">{{ title }}&nbsp;</template>
        <slot name="html"></slot>
      </h4>

      <span v-if="tooltip" v-b-tooltip.hover :title="tooltip">
        <i class="ms-2 pt-2 mdi mdi-help-circle font-size-18"></i>
      </span>
    </div>

    <slot name="subtitle"></slot>
  </div>
</template>
