<script>
import ratings   from '@/js/ratings'
import Utils     from '@/js/utils'
import Reports   from "@/js/reports";
import NotLoaded from "@/components/not-loaded";
import Loading   from "@/components/loading";

export default {
  data() {
    return {
      loading: true,
      error: false,
      tableData: [],
      ratings,
      Utils,
      reports: Reports,
      min_reviews: 15
    }
  },
  props: ['start_date', 'end_date', 'settings', 'group_id', 'group_ids'],
  components: { NotLoaded, Loading },
  methods: {
    ...Reports.requests,
    async load() {
      try {
        this.loading = true
        this.error = false
        if (!this.settings.use_custom_benchmark)
          this.settings.bench_group_ids = this.group_ids.slice(this.group_ids.length - 2)
        let r          = await this.getReports('top_properties')
        this.tableData = r?.slice(1)
      } catch {
        this.loading = false
        this.error = true
      }
    },
    label(key) {
      return this.settings.labels?.[key] || this.$t(`reports.table.${key}`)
    }
  },
  async mounted() {
    this.load()
  }
}
</script>
<template>
  <div>
    <table class="table table-bordered table-sm" v-if="tableData && tableData.length">
      <thead>
        <tr class="text-center">
          <th>{{ $t('reports.ranking') }}</th>
          <th class="text-left">{{ settings.filters?.property_type?.length ? $t(`property_types.${settings.filters.property_type}`) : $t('reports.table.property') }}</th>
          <th v-if='!settings.shownColumns || settings.shownColumns.reviews_count'>{{ label('reviews_count') }}</th>
          <th v-if='!settings.shownColumns || settings.shownColumns.reviews_count_trend'>{{ settings.labels?.trend || $t('reports.table.trend_yoy') }}</th>
          <th v-if='!settings.shownColumns || settings.shownColumns.numerical_rating'>{{ label('numerical_rating') }}</th>
          <th v-if='!settings.shownColumns || settings.shownColumns.numerical_rating_trend'>{{ settings.labels?.trend || $t('reports.table.trend_yoy') }}</th>
          <th v-if='!settings.shownColumns || settings.shownColumns.required_responses'>{{ label('required_responses') }}</th>
          <th v-if='!settings.shownColumns || settings.shownColumns.response_score'>{{ label('response_score') }}</th>
          <th v-if='!settings.shownColumns || settings.shownColumns.response_score_trend'>{{ settings.labels?.trend || $t('reports.table.trend_yoy') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="(row, index) in tableData" :key="row">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-left">{{ row[1] }}</td>

          <!-- reviews_count -->
          <td v-if='!settings.shownColumns || settings.shownColumns.reviews_count'>{{ row[3] }}</td>
          <!-- reviews_count yoy -->
          <td v-if='!settings.shownColumns || settings.shownColumns.reviews_count_trend' :style="{ color: row[3] - row[4] < 0 ? 'red' : 'green' }">
            {{ reports.percentDelta(row[3], row[4]) }}
          </td>

          <!-- numerical_rating -->
          <td v-if='!settings.shownColumns || settings.shownColumns.numerical_rating' :class="Utils.ratings.toCss100(row[5])">
            {{ ratings.format(row[5] / 10) }}
          </td>
          <!-- ratings yoy -->
          <td v-if='!settings.shownColumns || settings.shownColumns.numerical_rating_trend' :style="{ color: row[5] - row[6] < 0 ? 'red' : 'green' }">
            {{ reports.percentDelta(row[5], row[6]) }}
          </td>

          <!-- required_responses -->
          <td v-if='!settings.shownColumns || settings.shownColumns.required_responses'>
            {{ row[9] }}
          </td>
          <!-- response_score -->
          <td v-if='!settings.shownColumns || settings.shownColumns.response_score'>
            {{ reports.percentFormat(row[7]) }}
          </td>
          <!-- response_score yoy -->
          <td v-if='!settings.shownColumns || settings.shownColumns.response_score_trend' :style="{ color: row[7] - row[8] < 0 ? 'red' : 'green' }">
            {{ reports.percentDelta(row[7], row[8]) }}
          </td>
        </tr>
      </tbody>
    </table>

    <Loading v-else-if="loading" />
    <NotLoaded :data="tableData" :error="error" v-else-if="!loading" />
  </div>
</template>
