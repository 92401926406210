import Utils from '@/js/utils'

export const state     = { locale: 'en' }
export const getters   = { }
export const mutations = {
  saveLocale(state, locale) {
    state.locale = locale
    Utils.saveState('locale', locale)
  },
}
export const actions   = {

  async fetch() {
    let locale = Utils.loadState('locale')
    if (locale.toString() == '') locale = null
    return locale || navigator.language.split('-')[0] || this.$i18n.locale || 'en'
  },
}
