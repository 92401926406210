<script>
import Answer        from "@/components/answer";
import axios         from "axios";
import FeedbackUtils from "@/js/feedback-utils";
import Utils         from "@/js/utils";
import DataUtils     from "@/js/data-utils";
import Swal          from "sweetalert2";
import dayjs         from "dayjs";

export default {
  data() {
    return {
      loading: false,
      additionalInfo: [
        { field: "additional_info", subField: "guest-name", i18nPath: "name" },
        { field: "duration", i18nPath: "duration" },
        { field: "language", subField: "code", i18nPath: "language" },
        { field: "created_at", i18nPath: "date" },
        { field: "additional_info", subField: "guest-arrival_date", i18nPath: "arrival" },
        { field: "additional_info", subField: "guest-departure_date", i18nPath: "departure" },
        { field: "additional_info", subField: "guest-booking-number", i18nPath: "booking_number" },
        { field: "additional_info", subField: "guest-email", i18nPath: "email" },
        { field: "additional_info", subField: "guest-hostel", i18nPath: "hostel" },
        { field: "additional_info", subField: "guest-market", i18nPath: "market" },
        { field: "additional_info", subField: "guest-region", i18nPath: "region" },
        { field: "additional_info", subField: "guest-reservation_type", i18nPath: "reservation_type" },
        { field: "survey", subField: "name", i18nPath: "survey" },
        { field: "company", subField: "name", i18nPath: "company" }
      ],
      replyModal: false,
      reply: "",
      polaritiesToShow: ["positive", /*"neutral",*/ "negative"]
    };
  },
  props: ["response", "responseIndex", "cache", "params", "translations", "api_permissions", "contract", "surveys", "user"],
  components: { Answer },
  created() {
    if (this.response) this.mergeSentiment();
    if (this.response && this.surveys?.length) this.response.survey = this.surveys.find(s => s.id == this.response.survey_id);
  },
  methods: {
    ...DataUtils,
    ...FeedbackUtils,
    async getTranslation(response, index) {
      this.translations[this.$i18n.locale] ||= {};
      this.loading = true;
      let promises = [];

      response.answers.forEach(answer => {
        let answerIndex = this.findAnswer(answer);
        if (answer.follow_up) answerIndex = answer.id;

        if (this.translations[this.$i18n.locale][answerIndex]) return;

        if ((!/rating|nps/.test(answer.type) || !answer.follow_up) && answer.type != 'open_answer' && answer.type != 'open_answer_long') return;

        let question_id = answer.question.id;
        let value = answer.follow_up ? answer.follow_up : answer.answer;

        promises.push(axios.post("/v3/translate", { data: { [question_id]: value }, locale: this.$i18n.locale }).then(res => {
          this.translations[this.$i18n.locale][answerIndex] = this.DOMParser(res.data.data[question_id][0]);
          this.cache[this.params.page][this.responseIndex].translatedFrom = res.data.language;
        }));
      });

      await Promise.all(promises);
      this.cache[this.params.page][index].translated = true;
      this.loading = false;
      this.$forceUpdate();
    },
    mergeSentiment() {
      this.response.answers?.forEach(answer => {
        if (answer.sentiment) {
          let merged = [];
          answer.sentiment.opinions.forEach(obj => {
            if (!this.polaritiesToShow.includes(obj.polarity)) return;
            if(!merged.find(m => m.polarity == obj.polarity)) merged.push(obj);
            else {
              merged.forEach(m => {
                if (m.polarity == obj.polarity) {
                  obj.properties.forEach(property => {
                    if (!m.properties.includes(property)) m.properties.push(property);
                  });
                }
              });
            }
          });
          answer.sentiment.filteredOpinions = merged;
        }
      });
    },
    formatRating(rating) {
      if (rating) return Utils.formatNumber(Utils.round(rating / 10));
      return null;
    },
    tdClass(value) {
      return Utils.ratings.toCss100(value);
    },
    showTranslateButton(response) {
      if (this.$i18n.locale == response.language?.code) return false;
      return response.answers.reduce((res, curr) => {
        if (/open_answer|open_answer_long/.test(curr.question.type) || curr.follow_up) res = true;
        return res;
      }, false);
    },
    showMoreInfoCard(page, index, response) {
      return this.cache[page][index].showMoreInfo || response.answers.length <= 2;
    },
    additionalInfos(response, field, subField) {
      if (subField) {
        if (response[field]?.[subField] != null) {
          if (/guest-arrival_date|guest-departure_date/.test(subField)) return this.getAnswer(response[field][subField], "date");
          if (subField == "code") return this.locales(response[field][subField]).name;
        }
        return response[field]?.[subField];
      }

      if (response[field] != null) {
        if (field == "duration") return Utils.formatNumber(response[field]) + " " + this.$t("surveys_responses.seconds");
        if (field == "created_at") return this.getAnswer(response[field], "date");
      }
      return response[field];
    },
    publishResponse(page, index) {
      if (this.api_permissions.includes("publish_feedback_response")) {
        this.loading = true;
        let response = this.cache[page][index];
        axios.post(`v3/feedback/responses/${response.id}`, { published: !this.publishedStatus }).then(response => {
          this.cache[page][index].published = response.data.data.published;
          this.$forceUpdate();
          this.loading = false;
        });
      }
    },
    translatedFrom() {
      if (this.cache[this.params.page][this.responseIndex].language)
        return `${this.$t("surveys_responses.translated_from")} ${this.locales(this.cache[this.params.page][this.responseIndex].language.code).name}.`;
      return `${this.$t("surveys_responses.translated_from")} ${this.cache[this.params.page][this.responseIndex].translatedFrom}.`;
    },
    openReplyModal() {
      this.replyModal = true;
    },
    cleanReply() {
      this.reply = "";
    },
    async sendReply() {
      this.loading = true;
      try {
        await axios.post(`/v3/feedback/responses/${this.response.id}/reply`, {
          manager_response: this.reply,
          subscription_id:  this.contract.id,
          from:             this.from
        });
        this.success();
      } catch (err) { this.error(err) }
      this.replyModal = false;
      this.response.manager_response = this.reply;
      this.response.manager_response_date = dayjs().format("DD MMM YYYY");
      this.loading = false;
    },
    success() {
			Swal.fire(this.$t("general.success"), this.$t("daily_operations.reviews.reply_sent_success"), "success");
		},
    error(error) {
      return Swal.fire(this.$t("general.error"), this.$t("general.contact_support") + JSON.stringify(error.response?.data), "error");
    },
  },
  computed: {
    moreInfoLabel() {
      if (this.cache[this.params.page][this.responseIndex].showMoreInfo) return this.$t("general.collapse");
      return this.$t("general.expand");
    },
    publishedStatus() {
      if (this.response) {
        let published = this.cache[this.params.page][this.responseIndex].published;
        if (published == null && this.contract?.id) return this.contract.default_publishing;
        return published;
      }
      return null;
    },
    from() {
      if (/@stayokay.com/.test(this.user.email)) return this.user.email;
      return null;
    }
  }
}
</script>

<template>
  <li class="event-list">
    <div
      class="timeline-icon rounded-circle"
      :class="tdClass(response.overall_rating)"
    >
      <h4 :class="{ 'no-rating': !response.overall_rating }">
        {{ !response.overall_rating ? "-" : formatRating(response.overall_rating)}}
      </h4>
    </div>

    <div class="event-content p-4">
      <div class="response-header d-flex align-items-start mt-0 mb-3 font-18 pb-3">
        <h5 class="mb-0 me-3">
          {{ (response.guest ? response.guest.name : null) || $t("surveys_responses.no_name") }}
        </h5>

        <a :href="`mailto:${response.guest.email}`" class="text-primary" v-if="response.guest">
          {{ response.guest.email }}
        </a>
      </div>

      <div class="answers">
        <!-- First 3 answers -->
        <template v-for="answer in response.answers.slice(0, 3)">
          <Answer :key="answer.id" v-if="canShow(answer)" :answer="answer" :response="response" :params="params" :cache="cache" :translations="translations" :translated="cache[params.page][responseIndex].translated" :polaritiesToShow="polaritiesToShow" />
        </template>

        <!-- Rest of the answers - start collapsed -->
        <div class="collapse" :id="`response_${response.id}_outer`">
          <template v-for="answer in response.answers.slice(3)">
            <Answer :key="answer.id" v-if="canShow(answer)" :answer="answer" :response="response" :params="params" :cache="cache" :translations="translations" :translated="cache[params.page][responseIndex].translated" :polaritiesToShow="polaritiesToShow" />
          </template>

          <h4 class="question-title">{{ $t("surveys_responses.additional_info") }}</h4>
          <div class="row mt-2">
            <div class="table-responsive m-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6" v-for='i in [1,0]' :key="i">
              <table class='w-100'>
                <template v-for="(row, index) in additionalInfo">
                  <tr v-if="index % 2 == i && additionalInfos(response, row.field, row.subField)" :key='index'>
                    <td class='py-2'>
                      <label class="me-2 mb-0">{{ $t(`surveys_responses.additional_info_fields.${row.i18nPath}`) }}</label>
                    </td>
                    <td class="justify-content-start">
                      <router-link v-if="row.field == 'survey'" class="link-primary" :to="{ name: 'feedback_edit_survey', params: { id: response.survey_id }, query: { ...$route.query } }">
                        {{ additionalInfos(response, row.field, row.subField) }}
                      </router-link>
                      <template v-else>{{ additionalInfos(response, row.field, row.subField) }}</template>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </div>

          <div v-if="response.manager_response" class="card-body rounded bg-light mt-3">
            <div class="pb-2 mb-2 border-bottom border-secondary d-flex align-items-center justify-content-between">
              <h4>{{ $t("daily_operations.reviews.comment_type.manager") }}</h4>
              <span>{{ response.manager_response_date }}</span>
            </div>
            <span class="white-space-pre">{{ response.manager_response }}</span>
          </div>
        </div>
      </div>

      <div class="bottom-navbar mt-5" @click.stop>
        <button class="btn btn-outline-primary me-5 col-12 col-sm-auto mb-2 mb-sm-0" @click="toggleMoreInfo(params.page, responseIndex)" data-bs-toggle="collapse" :data-bs-target="`#response_${response.id}_outer`" aria-expanded="false" :aria-controls="`response_${response.id}_outer`">
          {{ moreInfoLabel }}
        </button>

        <button v-if="showTranslateButton(cache[params.page][responseIndex])" class="btn btn-outline-primary me-4 col-12 col-sm-auto mb-2 mb-sm-0" @click="cache[params.page][responseIndex].translated ? revertTranslation(responseIndex) : getTranslation(response, responseIndex)">
          {{ cache[params.page][responseIndex].translated
              ? $t("surveys_responses.revert")
              : $t("surveys_responses.translate") }}
        </button>

        <button v-if="api_permissions.includes('publish_feedback_response')" class="btn btn-outline-primary me-4 col-12 col-sm-auto mb-2 mb-sm-0" @click="publishResponse(params.page, responseIndex)">
          {{ publishedStatus ? $t("surveys_responses.unpublish") : $t("surveys_responses.publish") }}
        </button>

        <button v-if="response.guest && response.guest.email" :class="{ 'disabled': response.manager_response, 'btn-outline-primary': !response.manager_response }" class="btn me-4 col-12 col-sm-auto" @click="openReplyModal">
          {{ $t("daily_operations.reviews.reply") }}
        </button>

      </div>

      <div class="translated-from mt-2" v-if="cache[params.page][responseIndex].translated" style="font-size: 13px;" >
        {{ translatedFrom() }}
      </div>
    </div>

    <div class="event-date">
      <h5 class="mt-0 mb-0">{{ getMonth(response.created_at) }}&nbsp;</h5>
      <h5 class="mt-0 mb-0">{{ toDate(response.created_at).getDate() }}&nbsp;</h5>
      <p class="mb-0">
        <strong>{{ toDate(response.created_at).getFullYear() }}&nbsp;</strong>
      </p>
    </div>

    <!-- reply modal -->
    <b-modal v-model="replyModal" centered hide-footer header-class="border-0 pb-0" body-class="pt-1" :title="$t('surveys_responses.reply_response')" size="xl" @hidden="cleanReply">
      <div class="pb-2 pt-3 d-flex flex-column">
        <textarea class="w-100 reply-textarea" v-model="reply" rows="7" :placeholder="$t('surveys_responses.reply_response_placeholder')"></textarea>
      </div>

      <div class="footer-nav d-flex justify-content-between mt-3 mb-1">
        <button class="btn btn-outline-secondary" @click="replyModal = false">
          {{ $t("surveys_list.cancel") }}
        </button>

        <button class="btn btn-primary" :disabled="reply.length <= 0" @click="sendReply">
          {{ $t("daily_operations.reviews.send_reply") }}
        </button>
      </div>
    </b-modal>
  </li>
</template>
