<script>
export default {
  data() {
    return {
      timer: 6,
      redirectDelay: 6000
    }
  },
  props: ['question', 'settings', 'completed', 'editing'],
  methods: {
    async setTimer() {
      let loop = setInterval(() => {
        this.timer--;
        if (this.timer == 0) clearInterval(loop);
      }, 1000);
    },
    placeholder(field) {
      return this.question.translations?.[this.settings.currentLanguage]?.[field] ||
      (this.$t("new_survey.questions.edit.description") + " " + this.$t("new_survey.questions.edit.needs_translation")) || this.$t(`new_survey.questions.edit.${field}_placeholder`);
    },
    hasMissingTranslations(field) {
      return !this.question.translations?.[this.settings.currentLanguage]?.[field];
    }
  },
  computed: {
    willRedirect() {
      return this.settings.redirect_message && this.settings.redirect_url;
    },
    description () {
      return this.willRedirect ? this.settings.redirect_message : this.$t('feedback-form.thank_you_desc', this.settings.currentLanguage);
    },
    thankyou() {
      return this.question.translations?.[this.settings.currentLanguage] || {};
    }
  },
  watch: {
    completed: function () {
      if (this.willRedirect) {
        this.setTimer();
        setTimeout(() => {
          window.location = this.settings.redirect_url;
        }, this.redirectDelay);
      }
    }
  }
}
</script>

<template>
  <div class="container-fluid" :class="{ 'question-modal': !editing, 'question-edit-modal': editing }">
    <nav class="nav nav-pills nav-fill">
      <img class="cover-logo" :src="settings.logo" alt="Company Logo" v-if='settings.logo'/>
    </nav>

    <div class="title"> {{ $t('feedback-form.thank_you', this.settings.currentLanguage) }} </div>

    <div v-if="!editing" class="description" style='white-space: break-spaces' > {{ description }} </div>
    <textarea v-else :ref="'redirect_message_' + question.id" id="textarea-auto-resize" class="description custom-textarea" :class="{ 'missing-translation': hasMissingTranslations('redirect_message') }" @input="$emit('changeInput')" v-model="thankyou.redirect_message" style="text-align: center;" :placeholder="placeholder('redirect_message')"></textarea>

    <div v-if="!editing && willRedirect" class="timer">
      <i18n-t scope="global" keypath="feedback-form.redirect_timer" tag="span">
        {{ timer }}
      </i18n-t>
    </div>

    <br />

  </div>
</template>
