<script>
import PageHeader from "@/components/page-header";
import Review from "@/components/review.vue";
import axios from "axios";
import Utils from "@/js/utils";
import sources from "@/data/sources";

export default {
  data() {
    return {
      loading: false,
      company_id: null,
      review_id: null,
      translations: { [this.$i18n.locale]: {} },
      replyPermission: false,
      cache: { review: [] },
      sources: {},
      user: {}
    };
  },
  components: { PageHeader, Review },
  async created() {
    this.sources = sources;
    this.user = await this.$store.dispatch("user/fetch");
    this.company_id = this.$route.query.cid || this.$route.query.company_id;
    this.review_id  = this.$route.query.rid || this.$route.query.review_id;
    if (this.company_id && this.review_id) this.loadReview();
  },
  methods: {
    async loadReview() {
      Utils.setLoading.bind(this)(true);
      this.cache.review = [];

      let response = await axios.get(`/v3/companies/${this.company_id}/reviews/${this.review_id}`);
      ["translated"].forEach(field => response.data.data[field] = false);

      this.cache.review = [response.data.data];
      await this.loadReplyPermission();

      Utils.setLoading.bind(this)(false);
    },
    async loadReplyPermission() {
      if (Utils.permittedReviewSources.includes(this.cache.review[0]?.source)) {
        let response = await axios.get(`/v3/oauth/credentials/${this.company_id}/active`);
        this.replyPermission = response.data.data?.length > 0;
      }
    },
    goToFeed() {
      this.$router.push({ name: "reputation_reviews" });
    }
  },
}
</script>

<template>
  <div class="w-100" :class="{ 'cursor-loading': loading }">
    <PageHeader :title="$t('daily_operations.reviews.review')" />
    <div class="row">
      <div class="col-lg-12">
        <!-- Time line -->
        <div class="card">
          <div class="card-body mt-5 mb-5">
            <div class="row justify-content-center">
              <div class="col-lg-10 d-flex flex-column">
                <template v-if="cache.review[0] && !loading">
                  <ul class="verti-timeline list-unstyled border-0" dir="ltr" v-if="!loading">
                    <Review :review="cache.review[0]" :reviewIndex="0" :cache="cache" :params="{ page: 'review' }" :translations="translations" :permittedReply="replyPermission" :sources="sources" :user="user" />
                  </ul>
                  <button @click="goToFeed" class="btn btn-primary mt-5 align-self-center">
                    {{ $t("daily_operations.reviews.go_to_feed") }}
                  </button>
                </template>

                <div style='min-height: 200px;' class="loading" v-if="loading"></div>

                <div class="d-flex align-items-center justify-content-center" v-if="!loading && !cache.review[0]">
                  <span class="font-size-16">{{ $t("general.no_data") }}</span>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
  </div>
</template>
