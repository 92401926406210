<script>
import {axios} from '@/js/reports'
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      tableData: {
        current:  [],
        previous: []
      },
      cols: [
        { label: "", colspan: 1 }
      ],
      subCols: [
        { field: "source", label: "Property Name" }
      ],
      subColsFields: [],
      rows: []
    };
  },
  components: { ExportExcel },
  props: ["title", "company", "identifier", "release_date", "source", "group", "sourceHash"],
  created() {
    axios.get("/v3/query/db/_", { params: {
      ...(this.company) && { company_id: this.company.id },
      ...(this.group)   && { group_id:   this.group.id   },
      identifier:   this.identifier,
      release_date: this.release_date,
      source: this.source,
      compset: "primary",
      format:  "hash",
      months_ago: 2,
    }}).then(response => {
      let seenCompany = [];
      ["current", "previous"].forEach(period => {
        this.tableData[period] = response.data.data.filter(d => d.period == period);
        this.tableData[period].forEach(data => {
          if (!seenCompany.includes(data.name)) seenCompany.push(data.name);
        });
      });

      let sources = this.source.concat("others");
      ["current", "previous"].forEach(period => {
        sources.forEach(source => {
          this.subColsFields.push({ source: this.sourceHash[source], field: `${this.sourceHash[source]}_${period}`, period: period, label: this.sourceHash[source] });
        });
      });

      ["current", "previous"].forEach(period => {
        this.cols.push({ label: this.tableData[period][0]?.month, colspan: this.calculateColspan(period) });
      });

      seenCompany.forEach(company => {
        let row = {};
        row.name = company;
        this.subColsFields.forEach(subCol => {
          let data = this.tableData[subCol.period].find(d => d.name == company && d.source == subCol.source);
          if (!data || data.response_rate == null) row[subCol.field] = "-";
          else row[subCol.field] = data.response_rate + "%";
        });
        this.rows.push(row);
      });
      this.subCols = this.subCols.concat(this.subColsFields);
    });
  },
  methods: {
    calculateColspan(period) {
      return this.subColsFields.filter(s => s.period == period).length;
    }
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th v-for="col in cols" :key="col.label" :colspan="col.colspan"> {{ col.label }} </th>
          </tr>
          <tr>
            <th class="property-name-column">{{ subCols[0].label }}</th>
            <th v-for="(subCol, index) in subCols.slice(1)" :key="subCol.field + index">{{ subCol.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, indexRow) in rows" :key="String(row.length) + indexRow">
            <td class="property-name-column">{{ row.name }}</td>
            <template v-for="(subCol, indexSubCol) in subColsFields" :key="subCol.field + indexSubCol">
              <td>{{ row[subCol.field] }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
