<script>
import axios      from "axios";
import Utils      from "@/js/utils";
import DataUtils  from "@/js/data-utils";

export default {
  data() {
    return {
      user:  {},
      data:  {},
      loading: false,
      modal: { open: false, data: {}, loading: false },
      travelCompositionIconClasses: Utils.travelCompositionIconClasses,
      sentimentIconClasses:         Utils.sentimentIconClasses,
      consulting: null,
      topics: [],
      topicsEquivalence: {
        covid:          "covid_mentions",
        food_beverages: "fnb"
      }
    }
  },
  props: ["start_date", "end_date", "company_id", "settings", 'dashboard_type'],
  watch: {
    settings: {
      handler: function () {
        if (this.settings) this.load();
      },
      immediate: true
    }
  },
  async mounted() {
    this.user = await this.$store.dispatch("user/fetch");
    this.$store.dispatch("topics/fetch").then(topics => this.topics = topics);
  },
  methods: {
    ...DataUtils,
    sourceIcon: Utils.sources.iconUrl,
    percent:    Utils.percent.format,
    async load() {
      let params;
      if (this.dashboard_type == 'destination')
        params = { ...this.settings.data, context: this.dashboard_type }
      else
        params = {
          start_date: this.start_date,
          end_date:   this.end_date,
          company_id: this.company_id,
          limit:      5,
          random:     this.settings.random,
          //expire:     true,
          //metric:     'rankings',
          threshold:  1
        }

      Utils.setLoading.bind(this)(true);
      const response = await this.$store.dispatch("trends/fetch", params);
      Utils.setLoading.bind(this)(false);
      this.data = response.map(t => {
        if (t.metric == 'rankings') t.direction = t.direction == 'upwards' ? 'downwards' : 'upwards'
        t.company_id = this.company_id
        return t
      })
    },
    translateSegment(td) {
      if (td.metric == "numerical_ratings") return this.$t(`ratings.${td.segment}`)
      if (td.metric == "sentiment_ratings") return this.$t(`topics.${td.segment}`)
      if (td.metric == "nationalities") return this.$t(`countries.${td.segment}`)
      //if (td.metric == "languages") return this.$t(`locales.${td.segment}`) // FIXME: currently segment is the country_code
      if (td.metric == "composition") return this.$t(`travel_composition.${td.segment}`)
      return td.segment_title
    },
    async openModal(row) {
      this.modal.open    = true
      this.modal.loading = true

      let response = await axios.get(`v3/trends/consulting/${row.id}`, { params: {
        company_id:    row.company_id,
        segment:       row.segment,
        segment_title: row.segment_title,
        direction:     row.direction,
        data:          row.data,
        period:        row.period.prompt,
        period_comp:   row.period.comp,
        lang:          this.$i18n.locale,
      }})

      this.consulting = response.data.data.output.split("\n")
      this.modal.data = row
      this.modal.loading = false
    },
    formatNumber(val) {
      return Utils.formatNumber(Utils.round(val));
    },
    useSentimentIcon(segment) {
      let key = this.topicsEquivalence[segment] || segment;
      if (this.sentimentIconClasses[key]) return this.sentimentIconClasses[key];
      key = this.topics.find(t => t.topic == key)?.rating?.key || key;
      return this.sentimentIconClasses[key] || key;
    }
  },
  computed: {
    companyIdLength() {
      if (!this.company_id) return 0;
      if (Array.isArray(this.company_id)) return this.company_id.length;
      return 1;
    }
  }
}
</script>

<template>
  <div class="table-responsive mt-1 mb-0 h-100" :class="{ 'pb-1 mt-0 px-2': !settings.hideBg, loading: loading }">
    <div v-if="companyIdLength != 1 && dashboard_type != 'destination'">
      {{$t("trends.select_one_company")}}
    </div>

    <table v-else class="table table-centered table-sm">
      <thead>
      </thead>
      <tbody>
        <tr v-for="(row, index) in data" :key="`${row.slug}_${index}`" >
          <td>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center justify-content-center me-1 font-size-15" style="width:25px;">
                <i v-if="/overall|numerical_ratings/.test(row.metric)" class="bi bi-star-fill" />
                <!-- <i v-if="row.metric == 'sentiment_ratings'" class="bi bi-chat-left-text-fill" /> -->
                <i v-if="row.metric == 'sentiment_ratings'" :class="`${useSentimentIcon(row.segment)}`" />
                <i v-if="row.metric == 'response_rate'" class="bi bi-reply-fill" />
                <i v-if="row.metric == 'composition'" :class="`${travelCompositionIconClasses[row.segment]}`" />
                <span v-if="/nationalities|languages/.test(row.metric)" :class="`flag flag-${locales(row.segment.toLowerCase(), 'country').code}`" />
                <img v-if="row.metric == 'sources'" :src=sourceIcon(row.segment) style='width:20px;' />
                <img v-if="row.metric == 'rankings'" :src=sourceIcon(row.segment) style='width:20px;' />
              </div>

              <span v-if="row.segment" class="align-middle">{{ this.translateSegment(row) }}</span>
              <span v-else class="align-middle">{{ $t(`trends.metrics.${row.metric}`) }}</span>
            </div>

            <small class="text-start subtitle text-muted" style="padding-left:29px;">
              <span v-if="row.data == 'rating' && row.metric == 'sentiment_ratings'" >
                {{$t("trends.data.sentiment_rating")}} </span>
              <span v-else-if="row.data == 'reviews' && row.metric == 'sentiment_ratings'" >
                {{$t("trends.data.sentiment_reviews")}} </span>
              <span v-else-if="row.metric == 'rankings'" >
                {{$t("trends.data.ranking")}} </span>
              <span v-else > {{$t(`trends.data.${row.data}`)}} </span>
            </small>
          </td>

          <td class="text-start px-1">
            <div v-if="row.direction == 'upwards'" class='bi bi-arrow-up' style='color: green'>
              <span v-if='row.metric == "rankings"'> &nbsp;{{ Math.abs(row.delta) }} </span>
              <span v-else> &nbsp;{{ formatNumber(row.diff) + "%" }} </span>
            </div>
            <div v-else class='bi bi-arrow-down' style='color: red'>
              <span v-if='row.metric == "rankings"'> &nbsp;{{ Math.abs(row.delta) }} </span>
              <span v-else> &nbsp;{{ formatNumber(row.diff) + "%" }} </span>
            </div>

            <small class="text-start px-1 text-muted">{{ $t(`trends.periods.${row.period.comp}`) }}</small>
          </td>

          <td v-if="settings.showAdviceButton" class="py-0 ps-1 pe-0">
            <button @click=openModal(row) class="btn btn-primary btn-sm">
              <i class="mdi mdi-lightbulb-on-outline"></i>
              {{ $t("trends.get_advice") }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <b-modal v-if="settings.showAdviceButton" v-model=modal.open centered hide-footer header-class="border-0 pb-0" body-class=pt-1 size=xl >
      <div v-if=modal.loading class="loading cursor-loading" style="min-height:200px" >
      </div>
        <div v-else class="m-3">
          <h3 class="mb-3" >
            {{$t("trends.modalTitle")}}
          </h3>

          <template v-for="row in consulting" :key=row >
            <p v-if="row.match('^-')" style="font-size: 16px" >
            <i class="mdi mdi-check text-primary mb-2"></i>{{ row.replace(/^-/, "") }}
            </p>
          </template>
        </div>
    </b-modal>
  </div>
</template>

