import axios from "axios";

export const state = { distributions: {}, distributionsPromise: {} };

export const getters = {
  distributions: (state) => (contract_id) => state.distributions[contract_id]
};

export const mutations = {
  saveDistributions(state, { distributions, contractId }) {
    let contract_id = contractId || "all";
    if (state.distributions[contract_id]?.length) {
      state.distributions[contract_id] = [...state.distributions[contract_id], ...distributions];
      state.distributions[contract_id] = [...new Map(state.distributions[contract_id].map(d => [d.id, d])).values()];
    } else {
      state.distributions[contract_id] = distributions;
    }
  },
  saveDistributionsPromise(state, { promise, contractId }) {
    let contract_id = contractId || "all";
    state.distributionsPromise[contract_id] = promise;
  },
};

export const actions = {
  async fetch({ commit, rootState }) {
    if (!rootState.contract.currentContract) return [];
    const contractId = rootState.contract.currentContract.id;

    if (contractId) {
      if (state.distributions[contractId])        return state.distributions[contractId];
      if (state.distributionsPromise[contractId]) return state.distributionsPromise[contractId];
    }
    else {
      if (state.distributions["all"])        return state.distributions["all"];
      if (state.distributionsPromise["all"]) return state.distributionsPromise["all"];
    }

    let promise = axios.get("/v3/feedback/distributions", { params: { subscription_ids: contractId } }).then(response => {
      const distributions = response.data.data;
      commit("saveDistributions", { distributions, contractId });
      return distributions;
    });

    commit("saveDistributionsPromise", { promise, contractId });
    return promise;
  },
}
