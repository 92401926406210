<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      cols: [
        { field: "", label: "No. of Reviews", colspan: 4 },
        { field: "", label: "Pulse Score",    colspan: 6 }
      ],
      subCols: [
        { field: "name", label: "Property Name" },
        { field: "total", label: "Total" },
        { field: "positive", label: "Positive" },
        { field: "neutral", label: "Neutral" },
        { field: "negative", label: "Negative" },
      ],
      rows: []
    };
  },
  components: { ExportExcel },

  props: ['title', 'company', 'identifier', 'release_date', 'group', 'mtd', 'sentimentRatings'],

  created() {
    this.sentimentRatings.forEach(_r => {
      let r = Object.assign({}, _r)
      r.group = 'pulse'
      this.subCols.push(r)
    })

    axios.get("/v3/query/db/_", { params: {
      ...(this.company) && { company_id: this.company.id },
      ...(this.group) && { group_id: this.group.id },
      identifier:   this.identifier,
      release_date: this.release_date,
      compset: "primary",
      format:  "hash",
      mtd: this.mtd
    }}).then(response => {
      this.rows = response.data.data;
    });
  },
  methods: {
    round(value) {
      if(isNaN(value) || Number.isInteger(value)) return value;
      return Utils.round(value, 2);
    },
    compare(indexRow, field, group) {
      if(this.group && group == "pulse" && this.rows[indexRow][field] != null)
        return this.rows[indexRow][field] < this.rows[indexRow]["overall_score"];
      return false;
    }
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th></th>
            <th v-for="col in cols" :key="col.label" :colspan="col.colspan">{{ col.label }}</th>
          </tr>
          <tr>
            <th class="property-name-column">{{ subCols[0].label }}</th>
            <th v-for="(subCol, index) in subCols.slice(1)" :key="subCol.field + index" >{{ subCol.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, indexRow) in rows" :key="String(row.length) + indexRow">
            <td class="property-name-column">{{ row[subCols[0].field] }}</td>
            <template v-for="subCol in subCols.slice(1)" :key="subCol.label">
              <td :class="{ 'text-danger': compare(indexRow, subCol.field, subCol.group) }">
                {{ round(row[subCol.field]) }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
