<script>
require('@/js/reports')
import Replab from '@/mixins/reports/replab'

import Page from "@/components/reports/replab/page";
import PageHeader from "@/components/reports/replab/page-header";
import PageThankYou from "@/components/reports/replab/page-thank-you";

import TableTripAdvisorPerformance from "@/components/reports/replab/weekly/tables/table-tripadvisor-performance";
import TableOTAPerformance from "@/components/reports/replab/weekly/tables/table-ota-performance";
import TableTrend from "@/components/reports/replab/month-end/tables/table-trend-current-month";
import TableResponseRatio from "@/components/reports/replab/weekly/tables/table-response-ratio";
import TableSources from "@/components/reports/replab/month-end/tables/table-sources";
import TableSentimentRatings from "@/components/reports/replab/weekly/tables/table-sentiment-ratings";
import TableNumericalRatings from "@/components/reports/replab/weekly/tables/table-numerical-ratings";

import PieChart from "@/components/reports/replab/month-end/charts/chart-pie";
import ColumnChartNumberReviews from "@/components/reports/replab/month-end/charts/chart-column-number-reviews";
import ColumnChartDayByDay from "@/components/reports/replab/month-end/charts/chart-column-day-by-day";
import RecentReviews from "@/components/reports/replab/recent-reviews";

export default {
  mixins: [ Replab ],

  data() {
    return {
    }
  },

  components: {
    PageHeader,
    Page,
    PageThankYou,

    TableTripAdvisorPerformance,
    TableOTAPerformance,
    TableTrend,
    TableResponseRatio,
    TableSources,
    TableSentimentRatings,
    TableNumericalRatings,

    PieChart,
    ColumnChartNumberReviews,
    ColumnChartDayByDay,
    RecentReviews,
  },

  async created() {
    await this.replabInit()
  },

  computed: {
    otaTitle() {
      return this.company.property_type == "restaurant" ? "Channel Performance MTD" : "OTA Performance MTD";
    }
  }
}
</script>

<template>
  <div class="replab" v-if='release_date && company' >
    <PageHeader title="Weekly Summary Report" :data="{ company, month, year, showDate: false }"></PageHeader>

    <Page :data="{ company, day, month, year, type: 'weekly' }" >
      <ColumnChartDayByDay title="Day-by-Day - Last Week" :company="company" identifier="replab_segmented" :source="sources" :release_date="release_date" :colors="colors" :wtd="true" />
    </Page>

    <Page :data="{ company, day, month, year, type: 'weekly' }">
      <TableTripAdvisorPerformance title="MTD TripAdvisor Performance" :company="company" identifier="replab_mtd_performance" :release_date="release_date"/>

      <TableOTAPerformance :title="otaTitle" :company="company" identifier="replab_mtd_performance" :release_date="release_date" />
    </Page>

    <Page :data="{ company, day, month, year, type: 'weekly' }">
      <PieChart title="Review Landscape (MTD) - Major Channels" :company="company" identifier="replab_mtd_performance" :source="sources" :release_date="release_date" :sourceColors="sourceColors" :mtd="true" segment="source" />

      <TableResponseRatio title="MTD Review Response Ratio" :company="company" identifier="replab_mtd_performance" :source="sources" :release_date="release_date" :mtd="true" :prev_months="1"/>
    </Page>

    <Page :data="{ company, day, month, year, type: 'weekly' }">
      <TableSources title="Review Distribution - Last 7 Days" :company="company" identifier="replab_segmented" :release_date="release_date" :source="sources" :wtd="true"/>

      <TableSources title="Review Distribution - Last 30 Days" :company="company" identifier="replab_segmented" :release_date="release_date" :source="sources" :last_30d="true"/>
    </Page>

    <Page :data="{ company, day, month, year, type: 'weekly' }">
      <h3 class="title mt-3">Trending Topics Last 30 Days</h3>
      <TableTrend title="Trending Topics Last 30 Days" :company="company" identifier="replab_trending_topics" :release_date="release_date" :last_30d="true"/>
    </Page>

    <Page :data="{ company, day, month, year, type: 'weekly' }">
      <TableSentimentRatings title="Sentiment Ratings MTD" :company="company" identifier="replab_property_details" :release_date="release_date" :mtd="true"/>
    </Page>

    <Page :data="{ company, day, month, year, type: 'weekly' }">
      <TableNumericalRatings title="Numerical Ratings MTD" :company="company" identifier="replab_property_numratings" :release_date="release_date" :mtd="true"/>
    </Page>

    <Page :data="{ company, day, month, year, type: 'weekly' }">
      <ColumnChartNumberReviews title="TripAdvisor Review Frequency Summary" :company="company" identifier="replab_source_review_summary" :source="['trip_advisor']" :last_12m="false" :next_3m="false" :release_date="release_date" review_or_avg="reviews" :colors="colors" :mtd="true" :prev_months="4"/>

      <ColumnChartNumberReviews title="TripAdvisor Review Score Summary" :company="company" identifier="replab_source_review_summary" :source="['trip_advisor']" :last_12m="false" :next_3m="false" :release_date="release_date" review_or_avg="average_rating" :colors="colors" :mtd="true" :prev_months="4"/>
    </Page>

    <Page title="Recent Reviews" :data="{ company, day, month, year, type: 'weekly' }">
      <RecentReviews :company="company" identifier="replab_weekly_reviews" :release_date="release_date" :source="sources" :wtd="true"/>
    </Page>

    <PageThankYou />
  </div>
</template>
