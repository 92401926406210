<script>
import Utils from "@/js/utils";

export default {
  data() {
    return {
      labels: [],
      paramsHash: {
        ratings: "t",
        composition: "tc",
        country: "cn",
        language: "l",
        source: "srcs"
      },
      chartOptions: {
        colors: [],
        chart: { toolbar: { show: false } },
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: false,
            columnWidth: "45%",
            dataLabels: { position: "top" }
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
          formatter: (value) => Utils.formatNumber(Utils.round(value)),
          offsetY: -22,
          offsetX: 0,
          style: {
            fontSize: "16px",
            colors: ["#313131"]
          },
        },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        grid: { borderColor: "#fff" },
        fill: { opacity: 1 },
        yaxis: {
          show: false,
          max: 11,
          forceNiceScale: false,
          labels: {
            formatter: (value) => Utils.formatNumber(value),
            style: {
              colors: ["#000"],
              fontSize: "14px",
              fontWeight: 500,
            },
          }
        },
        xaxis: {
          type: "category",
          categories: [],
          labels: {
            hideOverlappingLabels: false,
            trim: true,
            rotate: 0,
            style: {
              colors: ["#000"],
              fontSize: "14px",
              fontWeight: 500,
            },
          }
        },
        legend: { show: false },
        tooltip: {
          y: {
            formatter: function(value, { dataPointIndex }) {
              return `${Utils.formatNumber(value)} (${Utils.formatNumber(this.data.review_count[dataPointIndex])} ${this.$t("general.reviews")})`
            }.bind(this)
          }
        }
      }
    };
  },
  props: ["data", "segment", "options"],
  mounted() {
    this.chartOptions.xaxis.categories = this.data.xaxis;
    this.chartOptions.colors = this.data.colors;
  },
  methods: {
    redirect(property) {
      let query = { [`data.${property.level}`]: property.id, [this.paramsHash[this.segment]]: this.data.topic };
      if (this.segment == "ratings") query["t"] = this.data.topic;

      this.$router.push({ name: "reputation_reviews", query: { ...Utils.slice(this.$route.query, ["sd", "ed", "sid", ...Utils.authParams]), ...query } });
    }
  },
  computed: {
    series() {
      return [{ name: this.data.label, data: this.data.values }];
    }
  }
}
</script>

<template>
  <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 d-flex flex-column">
    <h5>{{ data.label }}</h5>
    <div class="d-flex justify-content-around mt-2">
      <div v-for="p in data.properties" :key="data.label + JSON.stringify(p)">
        <a v-if="p.type == 'own'" href="javascript:void(0)" @click="redirect(p)">
          <span class="d-flex align-items-center" v-b-tooltip.hover :title="$t('daily_operations.analytics.go_to_reviews')">
						<i class="font-size-16 fa fa-external-link-alt"></i>
					</span>
        </a>
      </div>
    </div>
    <apexchart
      type="bar"
      dir="ltr"
      :series="series"
      :options="chartOptions"
      ref="column-chart"
    ></apexchart>
  </div>
</template>