<script>
import PageHeader  from "@/components/page-header";
import Multiselect from "vue-multiselect";
import Swal        from "sweetalert2";
import Utils       from "@/js/utils";
import axios       from "axios";
import DataUtils   from "@/js/data-utils";

export default {
  data() {
    return {
      loading: false,
      user: null,
      distributions: [],
      surveys: {},
      survey: false,
      companies: {},
      company: null,
      locale: null,
      templateHash: {},
      template: null,
      emails: "",
      variables: {},
      emailListOffset: 10
    }
  },
  components: { PageHeader, Multiselect },
  async created() {
    this.user = await this.$store.dispatch("user/fetch");
    await this.loadDistributions();
  },
  methods: {
    ...DataUtils,
    async loadDistributions() {
      Utils.setLoading.bind(this)(true);
      this.distributions = await this.$store.dispatch("distributions/fetch");
      this.distributions = this.distributions.filter(d => Object.keys(d.mail_template_ids).length);

      this.distributions.forEach(d => {
        this.companies[d.company.id] = d.company.name
        this.surveys[d.survey.id]    = d.survey.name;
      });

      Utils.setLoading.bind(this)(false);
    },
    async loadTemplate() {
      let id = this.distribution.mail_template_ids[this.locale];
      if (id) {
        if (this.templateHash[id]) {
          this.template = Utils.deepClone(this.templateHash[id]);
        }
        else {
          Utils.setLoading.bind(this)(true);
          let response  = await axios.get(`/v3/mail_templates/${id}`);
          this.template = response.data.data;
          this.templateHash[id] = Utils.deepClone(this.template);
          Utils.setLoading.bind(this)(false);
        }
        this.loadVariables(this.template.html_body);
      }
    },
    async sendEmail() {
      if (this.isEmptyEmailList) return Swal.fire(this.$t("general.warning"), this.$t("send_email.empty_mail_list"), "warning");
      try {
        let response = await Swal.fire({
          title: this.$t("general.are_you_sure"),
          html:  this.confirmSendText,
          icon: "question",
          showCancelButton:  true,
          showConfirmButton: true,
          allowOutsideClick: false
        });
        if (!response.isConfirmed) return;

				Utils.setLoading.bind(this)(true);
        await axios.post("/v3/feedback/distributions/send_mail", {
          key:      this.distribution.key,
          language: this.locale,
          to:       this.emailList,
          data:     this.variables,
          subscription_id: this.contract.id
        });
        this.success();
      } catch (err) { this.error(err); }
      Utils.setLoading.bind(this)(false);
    },
    loadVariables(text = "") {
      this.variables = {};
      let regex   = new RegExp("{{([A-Za-z0-9. _]*)?}}", "g");
      let matches = text.match(regex) || [];
      matches.forEach(match => {
        let cleanValue = match.replace(/[^a-zA-Z0-9._]/g, "");
        if (!this.variables[cleanValue]) this.variables[cleanValue] = null;
      });
    },
    clearSelection(field) {
      let fields = ["company", "survey", "locale"];
      let index  = fields.indexOf(field);
      fields.slice(index + 1).forEach(f => this[f] = null);
      this.variables = {};
    },
    success() {
			Swal.fire(this.$t("general.success"), this.$t("send_email.send_success"), "success");
		},
    error(error) {
      return Swal.fire(this.$t("general.error"), this.$t("general.contact_support") + JSON.stringify(error?.response?.data), "error");
    },
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    surveysFilter() {
      let surveys = Object.keys(this.surveys);
      if (this.company) surveys = this.distributions.filter(d => d.company.id == this.company)?.map(d => d.survey.id);
      return [...new Set(surveys)];
    },
    distribution() {
      return this.distributions.find(d => d.survey.id == this.survey && d.company.id == this.company);
    },
    computedLocales() {
      if (this.distribution) return Object.keys(this.distribution.mail_template_ids || {});
      return [];
    },
    emailList() {
      return this.emails.replace(/,\s+|,|;\s+|;|\n|\s+/g,";").split(";");
    },
    isEmptyEmailList() {
      return this.emailList.length <= 0 || (this.emailList[0] == "");
    },
    showVariablesSection() {
      return Object.keys(this.variables).length > 0;
    },
    showEmailSection() {
      return this.showVariablesSection || (this.survey && this.company && this.locale);
    },
    emptyVariables() {
      return Object.keys(this.variables).reduce((res, curr) => {
        if ((this.variables[curr] == null) || this.variables[curr]?.length == 0) res.push(curr);
        return res;
      }, []);
    },
    isEmptyDistributionOrTemplates() {
      return this.survey && this.company && (!this.distribution || Object.keys(this.distribution || {}).length == 0 || this.computedLocales.length == 0);
    },
    confirmSendText() {
      let warning = this.$t("send_email.send_confirm");
      if (this.variables && this.emptyVariables.length > 0) {
        warning = `
          <span class='alert alert-warning d-flex alert-box align-items-center px-4'>
            ${this.$tc("send_email.empty_variables_warning", this.emptyVariables.length , { vars: this.emptyVariables.join(", ") })}
          </span>
          ${warning}`;
      }
      let emails   = "<br>" + this.emailList.slice(0, this.emailListOffset).join("<br>");
      let quantity = this.emailList.length;
      if (quantity > this.emailListOffset) emails = `${emails}<br>${this.$t("send_email.more_others", { number: quantity - this.emailListOffset })}`;
      return warning + emails;
    }
  }
}

</script>

<template>
  <div class="w-100" :class="{ 'loading': loading }">
    <PageHeader :title="$t('send_email.title')" />

    <div class="card">
      <div class="card-body py-5">
        <!-- Company -->
        <div class="row">
          <div class="label-settings col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <label class="me-2 mb-0">{{ $t("send_email.company") }}</label>
          </div>
          <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 d-flex flex-column justify-content-center">
            <multiselect class="companies" @select="clearSelection('company')" @remove="clearSelection('company')" :multiple="false" v-model="company" :options="Object.keys(companies)" :custom-label="c => companies[c]" :showLabels="false" :allow-empty="false" :placeholder="$t('send_email.company_help')" :class="{ 'valid': company, 'invalid': !company }"></multiselect>
          </div>
        </div>

        <!-- Survey -->
        <div class="row mt-4" v-if="company">
          <div class="label-settings col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <label class="me-2 mb-0">{{ $t("send_email.survey") }}</label>
          </div>
          <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 d-flex flex-column justify-content-center">
            <multiselect class="surveys" @select="clearSelection('survey')" @remove="clearSelection('survey')" :multiple="false" v-model="survey" :options="surveysFilter" :custom-label="s => surveys[s]" :showLabels="false" :allow-empty="false" :placeholder="$t('send_email.survey_help')" :class="{ 'valid': survey, 'invalid': !survey }"></multiselect>
          </div>
        </div>

        <!-- Language -->
        <div class="row mt-4" v-if="company && computedLocales.length > 0">
          <div class="label-settings col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <label class="me-2 mb-0">{{ $t("send_email.locale") }}</label>
          </div>
          <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 d-flex flex-column justify-content-center">
            <multiselect class="default_language" @select="loadTemplate()" @remove="loadTemplate()" :multiple="false" v-model="locale" :options="computedLocales" :custom-label="l => locales(l).name" :showLabels="false" :placeholder="$t('send_email.locale_help')" :allow-empty="false" :searchable="true" :class="{ 'valid': locale, 'invalid': !locale }">
              <template v-slot:singleLabel="props">
                <div class="d-flex align-items-center">
                  <span :class="`flag flag-${locales(props.option, 'language').code}`" class="me-2"></span>
                  <span> {{ locales(props.option).name }} </span>
                </div>
              </template>

              <template v-slot:option="props">
                <div class="d-flex align-items-center">
                  <span :class="`flag flag-${locales(props.option, 'language').code}`" class="me-1"></span>
                  <span> {{ locales(props.option).name }} </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>

        <!-- Variables -->
        <template v-if="showVariablesSection">
          <div class="row mt-5">
            <div class="mt-3 d-flex justify-content-between align-items-center">
              <h4 class="font-size-20 ms-0 ms-sm-4 mb-0"> {{ $t('send_email.variables') }} </h4>
            </div>
          </div>

          <div v-for="(_, variable) in variables" class="row mt-4" :key="variable">
            <div class="label-settings col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <label class="me-2 mb-0">{{ variable }}</label>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 d-flex flex-column justify-content-center">
              <input class="form-control" type="text" v-model="variables[variable]" :placeholder="$t('send_email.variable_help', { var: variable })" />
            </div>
          </div>
        </template>

        <template v-if="showEmailSection">
          <!-- Mail list -->
          <div class="row mt-5">
            <div class="mt-3 ms-0 ms-sm-4 d-flex flex-column justify-content-between align-items-start">
              <h4 class="font-size-20 m-0"> {{ $t("send_email.mail_list") }} </h4>
              <header class="mt-3">
                {{ $t("send_email.recipient_explanation") }}
              </header>
            </div>
          </div>


          <div class="row mt-4">
            <div class="label-settings col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <label class="me-2 mb-0"> {{ $t("send_email.emails") }} </label>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 d-flex flex-column justify-content-center">
              <textarea
                v-model="emails"
                class="form-control"
                rows="7"
                :placeholder="$t('send_email.emails_help')"
              ></textarea>
            </div>
          </div>

          <div class="row mb-4 mt-5">
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"></div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 d-flex justify-content-end">
              <button @click="sendEmail()" class="btn btn-primary w-25"> {{ $t("send_email.send") }} </button>
            </div>
          </div>
        </template>

        <div v-if="isEmptyDistributionOrTemplates" class="mt-4 d-flex justify-content-center">
          <div class="alert alert-warning d-flex alert-box align-items-center justify-content-center px-5 w-75">
            {{ $t("send_email.no_template_warning") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
