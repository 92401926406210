<script>
export default {
  data() {
    return {
      orderType: ["static", "generic", "advanced", "feedback"]
    };
  },
  props: ["list"],
  computed: {
    componentsList() {
      return this.orderType.reduce((res, type) => {
        res[type] = Object.entries(this.list).filter(([,c]) => c.type == type);
        return res;
      }, {});
    }
  }
}
</script>

<template>
  <div class="overflow-auto components-list overflow-hidden">
    <div class="card h-100">
      <div class="card-body h-100 d-flex flex-column overflow-hidden">
        <div class="d-flex align-items-center">
          <i class="mdi mdi-arrow-all font-size-18 me-2"></i>
          <h5 class="m-0">{{ $t("reports.components") }}</h5>
        </div>

        <ul class="p-0 w-100 item-container mt-3" style="overflow-y: scroll;">
          <div v-for="(list, type) in componentsList" :key="type" class="mb-3">
            <h6 class="border-bottom border-2 pb-1">{{ $t(`reports.component_type.${type}`) }}</h6>

            <div v-for="(_, index) in list" :key="index">
              <div v-if="index % 2 == 0" class="d-flex" style="gap:5px;">
                <template v-for="i in [0, 1]" :key="i" >
                  <div class="new-widget w-50" :data-id="list[index+i][0]" v-if="list[index+i]">
                    <div class="grid-stack-item" >
                      <div class="mb-2 border rounded component-list-item bg-white grid-stack-item-content p-1" :class="list[index+i][0]" :data-id="list[index+i][0]">
                        <span class="mt-1 ms-1 align-sef-start fw-semibold">{{ $t(`reports.components_name.${list[index+i][0]}`) }}</span>
                        <img class="d-flex justify-content-center align-self-center mw-100" style="max-height:80px;" :src="list[index+i][1].icon" :alt="list[index+i][0]">
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>
