import axios from "axios";

export const state   = { reportTemplates: {}, reportTemplatesPromise: {} };
export const getters = {
  reportTemplates: (state) => (contract_id) => state.reportTemplates[contract_id]
};

export const mutations = {
  saveReportTemplates(state, { reportTemplates, params }) {
    let contract_id = params.contract_id || "all";
    state.reportTemplates[contract_id] = reportTemplates;
  },
  saveReportTemplatesPromise(state, { promise, params }) {
    let contract_id = params.contract_id || "all";
    state.reportTemplatesPromise[contract_id] = promise;
  },
};

export const actions = {
  async fetch({ commit }, params ) {
    if (params.contract_id && state.reportTemplatesPromise[params.contract_id]) return state.reportTemplatesPromise[params.contract_id];
    if (!params.contract_id && state.reportTemplatesPromise["all"]) return state.reportTemplatesPromise["all"];

    let url = "/v3/reports/templates";
    let promise = axios.get(url, { params: { subscription_ids: params.contract_id } }).then(response => {
      const reportTemplates = response.data.data.filter(rt => rt.period);
      commit("saveReportTemplates", { reportTemplates, params });
      return reportTemplates;
    });

    commit("saveReportTemplatesPromise", { promise, params });
    return promise;
  },
}
