<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      tableData: [],
      cols: [
        { field: "name",     label: "Property Name" },
        { field: "subfield", label: "" }
      ],
      fields: [
        { field: "reviews",        label: "Total Reviews" },
        { field: "average_rating", label: "Avg. Review Score" },
        { field: "5star_percent",  label: "% of Five-Star Reviews" },
        { field: "rank",           label: "Rank" },
      ],
      rows: []
    };
  },

  components: { ExportExcel },
  props: ['title', 'group', 'identifier', 'release_date', 'source', 'index', 'offset', 'download'],

  mounted() {
    window.addEventListener("TA-executive-summary-update", this.loadListener)
  },
  beforeUnmount() {
    window.removeEventListener("TA-executive-summary-update", this.loadListener)
  },
  created() {
    if (this.index == 1) {
      axios.get("/v3/query/db/_", { params: {
        group_id:     this.group.id,
        identifier:   this.identifier,
        release_date: this.release_date,
        source: this.source,
        compset: false,
        format:  "hash",
        fill_12m: true
      }}).then(response => {
        this.tableData = response.data.data.filter(d => d.period == "current" && d.date_label != "cumulative");
        this.formatData();
      });
    }
  },
  methods: {
    loadListener(data) {
      this.rows = window.reports.rowsOffset(this, data.detail)
      this.cols = data.detail.cols;
    },
    formatData() {
      let seenDate = [];
      let seenCompany = [];
      this.tableData.forEach(data => {
        if (!seenDate.includes(data.date_label)) {
          this.cols.push({ field: data.date_label, date: data.date, label: data.date_label });
          seenDate.push(data.date_label);
        }

        if (!seenCompany.includes(data.name)) {
          this.rows.push({ name: data.name, subfield: "reviews" });
          this.rows.push({ name: "", subfield: "average_rating" });
          this.rows.push({ name: "", subfield: "5star_percent" });
          this.rows.push({ name: "", subfield: "rank" });
          seenCompany.push(data.name);
        }
      });

      let currentCompany = "";
      this.rows.forEach((row, index) => {
        let element = {};
        if (row.name.length > 0) {
          currentCompany = row.name;
          element.name = currentCompany;
          element.rowspan = 2;
        }

        let company = this.tableData.filter(e => e.name == currentCompany);
        this.cols.forEach(col => {
          if(col.date) {
            let value = company.find(c => c.date_label == col.field);
            if (row.subfield == "rank" && value.ranking.position != null) value = "#" + value.ranking.position;
            else value = value?.[row.subfield];

            if (!value && value != 0) element[col.field] = "-";
            else if (row.subfield == "average_rating") element[col.field] = Utils.round(value, 2);
            else if (row.subfield == "5star_percent") element[col.field] = value + "%";
            else element[col.field] = value;
            element["subfield"] = this.fields.find(f => f.field == row.subfield)?.label;
          }
        });
        this.rows[index] = element;
      });
      this.$forceUpdate();
      const event = new CustomEvent("TA-executive-summary-update", { detail: { rows: this.rows, cols: this.cols } });
      window.dispatchEvent(event);
      this.rows = this.rows.slice(0, this.offset);
    }
  },
  computed: {
    computedTitle() {
      let title = this.title;
      if (this.index && this.index != 1) return title += " (Continued)";
      return title;
    }
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${computedTitle}.xls`" :title="computedTitle">
      <table border="1" class="table table-striped-quarter table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th class="property-name-column">{{ cols[0].label }}</th>
            <th v-for="col in cols.slice(1)" :key="col.field">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, indexRow) in rows" :key="JSON.stringify(row) + indexRow">
            <td class="property-name-column">{{ row[cols[0].field] }}</td>
            <template v-for="col in cols.slice(1)" :key="JSON.stringify(row) + col.field">
              <td>{{ row[col.field] }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
