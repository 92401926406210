<script>
import Multiselect from "vue-multiselect";
import axios       from 'axios'

export default{
  data () {
    return {
      data: {
        contract: null,
        user:     null,
      },
      contracts: [],
    }
  },
  props:      ['contract', 'user'],
  components: {Multiselect},
  mounted () {
    axios.get(`/v3/admin/contracts?active=true&include_users=true&limit=3000`).then((response) => {
      this.contracts     = response.data.data
      this.data.contract = this.contracts.find(c => c.id == Number(this.contract.id))
      this.data.user     = this.users.find(c => c.id == this.user.id)
    })
  },
  computed: {
    users () {
      return this.data.contract?.users || []
    }
  },
  methods: {
    emitEvent() {
      this.$emit('duplicate', this.data.contract.id, this.data.user.id )
    }
  }
}
</script>

<template>
  <div>
    <label class='mt-3'>{{$t('general.select_subscription')}}</label>
    <multiselect :multiple="false" v-model="data.contract" :options="contracts" :showLabels="false" track-by='id' label=name :customLabel='({id, name}) => { return `${id} (${name})` }' ></multiselect>

    <label class='mt-3'>{{$t('reports.select_user')}}</label>
    <multiselect :multiple="false" v-model="data.user" :options="users" :showLabels="false" track-by='id' :customLabel='({name, email}) => { return `${name || ""} (${email})` }' ></multiselect>

    <div class="footer-nav d-flex flex-column flex-sm-row justify-content-between mt-4" style="gap:10px;">
      <button class="btn btn-outline-secondary me-2" @click="() => { $emit('hide') }">{{ $t("surveys_list.cancel") }}</button>
      <button @click="emitEvent" class="btn btn-primary me-2" >{{ $t("reports.save") }}</button>
    </div>
  </div>
</template>
