<script>
import Reports   from "@/js/reports";
import NotLoaded from "@/components/not-loaded";
import Loading   from "@/components/loading";

export default {
  data() {
    return {
      loading: true,
      error: false,
      tableData: [],
      reports: Reports
    }
  },
  props: ['group_ids', 'group_id', 'start_date', 'end_date', 'settings'],
  components: { NotLoaded, Loading },
  methods: {
    ...Reports.requests,
    async load() {
      try {
        this.loading = true
        this.error = false
        if (!this.settings.use_custom_benchmark)
          this.settings.bench_group_ids = [ ...new Set(this.group_ids)]

        // remove group_id as it is already being added
        const i = this.settings.bench_group_ids.indexOf(this.group_id.toString())
        if (i >= 0) this.settings.bench_group_ids.splice(i, 1)

        let r          = await this.getReports('groups_response_score', false)
        this.tableData = r?.slice(1)
      } catch {
        this.loading = false
        this.error = true
      }
    },
    label(key) {
      return this.settings.labels?.[key] || this.$t(`reports.table.${key}`)
    }
  },
  async mounted() {
    this.load()
  }
}
</script>
<template>
  <div>
    <table class="table table-xs" v-if="tableData && tableData.length">
      <thead>
        <tr class="text-center">
          <th class="text-left">{{ $t('reports.table.destination') }}</th>
          <th>{{ label('response_ratio') }}</th>
          <th>{{ label('score_quarter') }}</th>
          <th class="right-bordered-cell">{{ label('year_over_year') }}</th>
          <th>{{ label('last_12_months') }}</th>
          <th>{{ label('previous_12_months') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="row in tableData" :key="row[2]">
          <td class="text-left">{{ row[2] }}</td>
          <td>{{ row[4] }} / {{ row[3] }}</td>
          <td>{{ reports.percentFormat(row[5]) }}</td>
          <td :style="{ color: row[5] <= 0 ? 'red' : 'green' }" class="right-bordered-cell"> {{ reports.percentFormat(row[6]) }} </td>
          <td>{{ reports.percentFormat(row[7]) }}</td>
          <td :style="{ color: row[8] <= 0 ? 'red' : 'green' }"> {{ reports.percentFormat(row[10]) }} </td>
        </tr>
      </tbody>
    </table>

    <Loading v-else-if="loading" />
    <NotLoaded :data="tableData" :error="error" v-else-if="!loading" />
  </div>
</template>
