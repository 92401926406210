<script>
import Excel from '@/components/excel-2003'
export default {
  data() {
    return {
      excel: null,
    }
  },

  props: ['title', 'titleClass', 'tooltip', 'btnClass', 'btnText', 'target', 'fileName', 'format'],
  components: { Excel },
  mounted() {
    this.excel = this.$refs.excel
  },
  methods: {
    async saveContent() {
      this.$emit("exportExcel", "init")
      await this.$nextTick()
      this.excel.worksheets[0].name   = (this.fileName || this.title).slice(0, 31).replace(/\?/g, " ") // to avoid the 31 chars worksheet name limitation
      this.excel.worksheets[0].tables = [{ element: this.table }]
      this.excel.prepare()

      await this.$nextTick()
      if (this.format == "xlsx") this.excel.xlsxDownload(this.fileName || this.title)
      else this.excel.xlsDownload(this.fileName || this.title)
      this.excel.reset()
      this.$emit("exportExcel", "end")
    }
  },
  computed: {
    computedTitleClass() {
      if (!this.titleClass) return "mt-3"
      return this.titleClass
    },
    table() {
      if (this.target) return document.querySelector(`#${this.target} table`)
      return this.$refs[this.title || this.fileName]?.firstElementChild
    }
  }
}
</script>

<template>
  <div >
    <div class="d-flex justify-content-between align-items-center" id="export-excel">
      <div :class="tooltip && 'd-flex align-items-center'">
        <h3 class="title" :class="computedTitleClass"> {{ title }} </h3>
        <span v-if="tooltip" class="ms-2" v-b-tooltip.hover :title="tooltip">
          <i class="mdi mdi-help-circle"></i>
        </span>
      </div>
      <div class="d-flex align-items-center">
        <slot name="header"></slot>
        <a @click=saveContent() class="btn btn-replab hide-print" :class="btnClass">{{ btnText ?? "Export Excel" }}</a>
      </div>
    </div>
    <div :ref="title || fileName"><slot></slot></div>

    <Excel ref=excel />
  </div>
</template>
