<template v-slot:default>
    <div>
      <h3>{{ $t('signup.signup_to_paid.personal_details_title') }}</h3>
      <b-form-input
        ref="inputRef"
        class="mb-2"
        v-model="formData.first_name"
        placeholder="First Name"></b-form-input>
      <b-form-input
        class="mb-2"
        v-model="formData.surname"
        @keydown.enter="handleNextStep()"
        placeholder="Surname">
      </b-form-input>
    </div>
</template>

<script>
export default {
  props: ['formData', 'nextStep', 'prevStep'],
  mounted() {
    this.$nextTick(() => {
      this.$refs.inputRef.focus();
    });
  },
  methods: {
    handleNextStep() {
      if (this.formData.first_name && this.formData.surname) {
        this.nextStep();
      } else {
        alert('Please enter your first name and surname');
      }
    },
  },
};
</script>
