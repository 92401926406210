import axios from "axios";

export const state = {
  trends: {}, trendsPromise: {}
};

export const getters = {};

export const mutations = {
  saveTrends(state, { trends, params }) {
    state.trends[params] = trends;
  },
  saveTrendsPromise(state, { promise, params }) {
    state.trendsPromise[params] = promise;
  }
};

const paramsKeys = ['start_date', 'end_date', 'company_id',
  'limit', 'threshold', 'random', 'expire', 'metric',
  'subscription_ids', 'group_ids', 'property_type', 
  'continent', 'country', 'regions', 'city', 'categories', 'sub_context'
];

export const actions = {
  async fetch({ commit }, params) {
    const p            = paramsKeys.reduce((h, k) => { if (params[k]) h[k] = params[k]; return h }, {});
    const stringParams = JSON.stringify({ ...p });

    if (state.trends[stringParams])        return state.trends[stringParams];
    if (state.trendsPromise[stringParams]) return state.trendsPromise[stringParams];

    let path;
    if (params.context == 'destination')
      path = '/v3/destination/trends'
    else
      path = '/v3/trends/listing'

    let promise = axios.get(path, { params: { ...p } }).then(response => {
      const trends = response?.data?.data || [];
      commit("saveTrends", { trends, params: stringParams });
      return trends;
    });

    commit("saveTrendsPromise", { promise, params: stringParams });
    return promise;
  }
}
