<script>
import Utils from "@/js/utils";
import Swal from "sweetalert2";
import axios from "axios";

export default {
	data() {
		return {
			loading: false,
			popperConfig: {
				modifiers: [{ name: "flip", enabled: false }],
			},
			cardFields: ["companies", "surveys", "distributions"]
		};
	},
	props: ["template", "distributions"],
	methods: {
		async deleteTemplate(ev) {
			ev.preventDefault();
      try {
        let response = await Swal.fire({
          title: this.$t("general.are_you_sure"),
          text:  this.$t("mail_template.list.delete_template"),
          icon: "question",
          showCancelButton:  true,
          showConfirmButton: true,
          allowOutsideClick: false
        });
        if (!response.isConfirmed) return;
				Utils.setLoading.bind(this)(true);
        await axios.delete(`/v3/mail_templates/${this.template.id}`);
				this.$emit("list_templates:update_list", this.template);
        this.success();
      } catch (err) { this.error(err); }
      Utils.setLoading.bind(this)(false);
		},
		success() {
			Swal.fire(this.$t("general.success"), this.$t("mail_template.list.delete_template_success"), "success");
		},
    error(error) {
      return Swal.fire(this.$t("general.error"), this.$t("general.contact_support") + JSON.stringify(error?.response?.data), "error");
    },
	},
	computed: {
		fields() {
			return {
				companies: [...new Set(this.distributions.map(d => d.company.name))].join(", "),
				surveys:   [...new Set(this.distributions.map(d => d.survey.name))].join(", "),
				distributions: [...new Set(this.distributions.map(d => {
					if (d.type == "general") this.$t("new_survey.share.general");
					return d.type;
				}))].join(", ")
			}
		}
	}
}
</script>

<template>
	<router-link class="py-2 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3" :to="{ name: 'feedback_edit_template', params: { id: template.id }, query: { ...$route.query } }">
    <div class="custom-card custom-card__bg text-white d-flex flex-column justify-content-between">
			<div class="d-flex justify-content-end p-0" >
				<div class="side-footer-menu">
					<b-dropdown size="lg" menu-class="dropdown-menu-right" variant="link" toggle-class="text-decoration-none" no-caret :popper-opts="popperConfig">
						<template #button-content>
							<i class="mdi mdi-dots-vertical text-white"></i>
						</template>

						<b-dropdown-item :to="{ name: 'feedback_edit_template', params: { id: template.id }, query: { ...this.$route.query } }">
							{{ $t("surveys_list.edit") }}
						</b-dropdown-item>

						<b-dropdown-item @click="deleteTemplate" href="#">
							{{ $t("surveys_list.delete") }}
						</b-dropdown-item>
					</b-dropdown>
				</div>
			</div>

			<div v-for="f in cardFields" :key="f" class="overflow-hidden text-nowrap text-truncate">
				<label>{{ $t(`mail_template.list.${f}`) }}:</label>&nbsp;
				<span :title="fields[f]">{{ fields[f] }}</span><br>
			</div><br>

      <div class="mb-3"> {{ $t("mail_template.list.edit") }} <i class="fas fa-arrow-right"></i> </div>
    </div>
  </router-link>
</template>
