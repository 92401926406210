<script>
export default {};
</script>

<template>
  <div class="stat-svg">
    <svg
      width="128mm"
      height="40mm"
      viewBox="0 0 128 40"
      version="1.1"
      id="svg1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
      <defs id="defs1" />
      <g id="layer1">
        <path
          id="path2"
          style="opacity:0.3;fill:currentColor;stroke:none;stroke-width:0.210992;stroke-linejoin:round;stroke-miterlimit:2"
          d="m 127.45759,27.725171 c -0.69896,9.30895 0.12967,11.57061 0.12967,11.57061 L 0,40.214871 c 0,0 15.785308,-9.14388 35.777851,-11.62174 29.885181,-3.70394 38.190541,-7.70854 55.300721,-11.21432 11.988488,-2.45638 36.458858,-4.59876 36.451738,-3.2642 l -0.0726,13.61056 z" />
        <path
          id="path2-5"
          style="opacity:0.3;fill:currentColor;stroke:none;stroke-width:0.210992;stroke-linejoin:round;stroke-miterlimit:2"
          d="m 127.58048,27.546931 c -0.69896,9.30895 0.12967,11.57061 0.12967,11.57061 l -112.440667,0.96604 c 0,0 19.651853,-9.57119 32.287844,-12.09138 33.412085,-6.66389 26.940555,-11.02947 43.644135,-16.13234 C 104.72554,7.7283116 127.73214,5.4463316 127.72299,6.7808616 l -0.14244,20.7660694 z" />
        <path
          id="path2-5-2"
          style="opacity:0.3;fill:currentColor;stroke:none;stroke-width:0.210992;stroke-linejoin:round;stroke-miterlimit:2"
          d="m 127.66374,27.610451 c -0.69896,9.30895 0.12967,11.57061 0.12967,11.57061 l -99.806882,0.30854 c 0,0 10.489424,-6.33652 25.936061,-10.11887 12.748413,-3.12165 16.876603,-10.75936 35.268123,-22.2157194 13.139268,-8.18465 38.842428,-7.73370004 38.824948,-6.39920002 L 127.66381,27.610451 Z" />
      </g>
    </svg>
  </div>
</template>
