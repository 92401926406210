<script>
import Utils from "@/js/utils";
import dayjs from 'dayjs'
import DataUtils from "@/js/data-utils";
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
dayjs.extend(quarterOfYear)

export default {
  data() {
    const self = this;
    return {
      clicked: false,
      mobileClick: -1,
      hovering: -1,
      travelCompositionIcons: {
        "families":     "mdi mdi-human-male-female-child",
        "single":       "mdi mdi-account",
        "solo":         "mdi mdi-account",
        "couples":      "mdi mdi-human-male-female",
        "friends":      "mdi mdi-account-group",
        "group":        "mdi mdi-account-group",
        "business":     "mdi mdi-account-tie",
        "seniors":      "mdi mdi-human-walker",
        "young_adults": "mdi mdi-account-group",
        "other":        "mdi mdi-dots-horizontal",
      },
      donutChartOptions: {
        chart: {
          events: {
            dataPointMouseEnter: this.mouseEnter,
            dataPointMouseLeave: this.mouseLeave,
          },
          animations: {
            enabled: true,
            easing: 'linear',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 1500
            }
          },
          dropShadow: {
            enabled: true,
            enabledOnSeries: [1, 2],
            top: -8,
            left: 8,
            blur: 10,
            color: '#000',
            opacity: 0.1
          }
        },
        grid: { padding: { top: -50, bottom: -40, left: -50, right: -50 } },
        tooltip: {
          enabled: true,
          highlightDataSeries: true,
          custom: this.customTooltip,
        },
        labels: ['rating'],
        colors: [({ value }) => self.tdClass(value / 10), ({ value }) => self.tdClass(value / 10) ],
        legend: { show: false },
        dataLabels: { enabled: false },
        plotOptions: {
          radialBar: {
            inverseOrder: false,
            startAngle: 0,
            endAngle: 360,
            hollow: {
              margin: 0,
              size: "60%",
              background: "transparent",
            },
            track: {
              show: true,
              background: "#f2f2f2",
              strokeWidth: "97%",
              opacity: 1,
              margin: 5,
              dropShadow: {
                enabled: false,
                top: -10,
                left: 10,
                blur: 8,
                opacity: 0.1
              }
            },
            dataLabels: { show: false },
          },
        },
        states: { hover: { filter: { type: "darken", value: 0.45 } } },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'round',
          width: 10,
          dashArray: 0,
        }
      },
      maximumChartHeight: 10,
      chartData: []
    };
  },
  props: ["Kpi", "data", "ratingType", "period"],
  mounted() {
    this.chartData = this.data.data;
    if (Array.isArray(this.chartData)) {
      this.chartData = this.chartData.filter(d => d.current);
      this.chartData = this.chartData?.sort((a, b) => this.topKpi(b).review_count - this.topKpi(a).review_count).slice(0, 3);
      let scores = this.chartData.map(d => Utils.round(this.topKpi(d).review_count, 2));
      this.maximumChartHeight = scores[0];
    }
  },
  computed: {
    labelHash () {
      return {
        'data': this.$t("executive_kpis.rating"),
        'benchmark': this.$t("executive_kpis.benchmark")
      }
    },
    label () {
      return Object.keys(this.labelHash).map(k => {
        return this.data[k]?.current[this.ratingType] ? this.labelHash[k] : null
      }).filter(n => n)
    },
    series () {
      return [
        this.chartData.current[this.ratingType]?.value * 10,
        this.data.benchmark.current[this.ratingType]?.value * 10
      ].filter(n => n)
    },
    computedData() {
      return this.chartData.current?.[this.ratingType] || {};
    },
    computedBenchmark() {
      return this.data.benchmark.current?.[this.ratingType] || {};
    },
    noData() {
      if (!Array.isArray(this.chartData)) return !(this.computedData?.value && this.computedData.value != 0);
      let hasReviews = this.chartData.reduce((res, curr) => {
        res += this.topKpi(curr).review_count || 0;
        return res;
      }, 0);
      return !(this.chartData.length && hasReviews);
    }
  },
  methods: {
    ...DataUtils,
    sourceIcon: Utils.sources.iconUrl,

    topKpi (kpi) {
      return kpi.current[this.ratingType] || {}
    },
    topLabel (kpi) {
      if (this.Kpi == 'top_compositions') return this.$t(`executive_kpis.labels.${kpi.key}`)
      if (this.Kpi == 'top_sources')      return kpi.title
      return kpi.key
    },
    customTooltip ({series, seriesIndex}) {
      return '<div class="arrow_box p-1 '+Utils.ratings.toCss100(series[seriesIndex])+'">' +
        '<span>' + this.label[seriesIndex] + ': '+ Utils.round(series[seriesIndex]/10)+'</span>' +
        '</div>'
    },
    tdClass(value) {
      return Utils.ratings.toHex(value);
    },
    calculateHeight(kpi) {
      let value = Utils.round(this.topKpi(kpi).review_count, 1);
      return 100 * value/this.maximumChartHeight + "%";
    },
    round(value) {
      return Utils.formatNumber(Utils.round(value));
    },
    redirectRatings() {
      let query = {
        "sd": dayjs().add(-1, this.period).startOf(this.period).format("YYYY-MM-DD"),
        "ed": dayjs().add(-1, this.period).endOf(this.period).format("YYYY-MM-DD")
      };

      if      (this.Kpi == "top_nationalities") query["rcn"]  = this.chartData.map(d => d.title);
      else if (this.Kpi == "top_compositions")  query["tc"]   = this.chartData.map(d => d.title);
      else if (this.Kpi == "top_sources")       query["srcs"] = this.chartData.map(d => d.key);
      else query["t"] = this.Kpi;

      this.$router.push({ name: "reputation_reviews", query: { ...this.$route.query, ...query } });
    }
  }
};
</script>

<template>
  <!-- Donut KPIs -->
	<div class="executive-kpi col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-6 d-flex flex-column">

		<div class="title-indicator" @click="redirectRatings">
			<div class="card text-center m-0 custom-shadow">
				<div class="card-body d-flex justify-content-center align-items-center" :title="$t('executive_kpis.see_in_more_detail')">
					<h5 class="card-title m-0">{{ $t(`executive_kpis.${Kpi}`) }}</h5>
					<span class="ms-2 mt-1" v-b-tooltip.hover :title="$t(`executive_kpis.help.${Kpi}`)">
						<i class="mdi mdi-information-outline"></i>
					</span>
				</div>
			</div>
		</div>

    <a href='#' :title="$t('executive_kpis.see_in_more_detail')" class='text-end mt-3 me-2' @click='redirectRatings'>
      <i class='font-size-20 fa fa-external-link-alt'></i>
    </a>

    <div v-if="noData" class="d-flex align-items-center justify-content-center h-100">
      <div class="alert alert-warning d-flex alert-box align-items-center px-4" role="alert">
        <div class="d-flex align-items-center">
          <i class="mdi mdi-magnify-remove-outline font-size-24 me-2"></i>
          <span class="font-size-16 mb-0">{{ $t("general.no_data") }}</span>
        </div>
      </div>
    </div>

		<div class="visualization-donut" v-else-if="!Array.isArray(chartData)">

      <div class="numerical-data mt-2 d-flex flex-column justify-content-center align-items-center">
        <div class="current mb-1 d-flex flex-column justify-content-center align-items-center" :class="{ 'rating-label-active': hovering == 0, 'rating-label-inactive': hovering == 1 }" v-if='chartData'>
          <p class="m-0"> {{ $t("executive_kpis.rating") }} </p>
          <div class="d-flex justify-content-center align-items-center">
            <h2 class="mb-0 me-3" :style="`color: ${tdClass(computedData.value)};`" > {{ round(computedData.value) }} </h2>
            <div class="trend d-flex flex-column justify-content-center align-items-center"
            :class="`text-${computedData.trend >= 0 ? 'success' : 'danger'}`">
              <i class="arrow-trend" :class="{ 'mdi mdi-arrow-up': computedData.trend >= 0, 'mdi mdi-arrow-down': computedData.trend < 0 }"></i>
              <p class="m-0"> {{ round(computedData.trend) + "%" }} </p>
            </div>
          </div>
        </div>

        <div class="divider border-bottom border-dark"></div>

        <div class="benchmark_current mt-1 d-flex flex-column justify-content-center align-items-center" :class="{ 'benchmark-label-active': hovering == 1, 'benchmark-label-inactive': hovering == 0 }" v-if="data.benchmark">
          <p class="m-0"> {{ $t("executive_kpis.benchmark") }} </p>
          <div class="d-flex justify-content-center align-items-center">
            <h2 class="mb-0 me-3" :style="`color: ${tdClass(computedBenchmark.value)};`" > {{ round(computedBenchmark.value) }} </h2>
            <div class="trend d-flex flex-column justify-content-center align-items-center"
            :class="`text-${computedBenchmark.trend >= 0 ? 'success' : 'danger'}`">
              <i class="arrow-trend" :class="{ 'mdi mdi-arrow-up': computedBenchmark.trend >= 0, 'mdi mdi-arrow-down': computedBenchmark.trend < 0 }"></i>
              <p class="m-0"> {{ round(computedBenchmark.trend) + "%" }} </p>
            </div>
          </div>
        </div>
      </div>

			<apexchart class="apex-charts rotate-90" type="radialBar" :series="series" :options="donutChartOptions" :ref="`${Kpi}_chart`"></apexchart>
		</div>

    <!-- Podium charts -->

    <div class="visualization-bar justify-content-center" v-else >

      <template v-for="(kpi, index) in chartData" :key="kpi.key">
        <div class="single-bar">

          <div class="w-100">
            <div class="single-data">
              <p class="mb-0 me-2 rubik-font font-size-18"> {{ round(topKpi(kpi).review_count) }} </p>
              <div class="trend d-flex flex-column justify-content-center align-items-center"
              :class="`text-${topKpi(kpi).trend >= 0 ? 'success' : 'danger'}`">
                <i class="arrow-trend" :class="{ 'mdi mdi-arrow-up': topKpi(kpi).trend >= 0, 'mdi mdi-arrow-down': topKpi(kpi).trend < 0 }"></i>
                <p class="m-0 rubik-font"> {{ round(topKpi(kpi).trend) + "%" }} </p>
              </div>
            </div>

            <!-- Divider -->
            <div class="divider-title">
              <div class="border-bottom border-dark" :style="`width: calc(100% - 20px);`"></div>
              <div class="name mt-2"> {{ topLabel(kpi) }} </div>
            </div>
          </div>

          <div class="single-bar w-100 justify-content-end">
            <!-- Bar -->
            <div class="top-wrapper" :style="`height: ${calculateHeight(kpi)};`">
              <div class="podium-bar d-flex justify-content-center align-items-end" :class="`top-${index + 1}`"></div>
              <div class="bigger">
                <span v-if="Kpi == 'top_nationalities'" class="flag-shadow flag w-100 h-100" :class="`flag-${locales(kpi.key.toLowerCase(), 'country').code}`"></span>
                <img v-else-if="Kpi == 'top_sources'" class="rounded" :src=sourceIcon(kpi.key) style="width: 30px;">
                <span v-else class="travel-composition-icon" :class="travelCompositionIcons[kpi.key]"></span>
              </div>
            </div>
          </div>

        </div>
      </template>
    </div>
  </div>
</template>
