<script>
import {axios} from '@/js/reports'
import Review from "@/components/reports/review"

export default {
  data() {
    return {
      pthld: 80,
      nthld: 70,
      best:  [],
      worst: [],
    };
  },
  components: { Review },
  props: ["title", "company", "identifier", "release_date", "source", "wtd"],
  created() {
    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      compset: false,
      wtd: this.wtd,
      format: "hash"
    }}).then(response => {
      this.tableData = response.data.data.filter(d => d.review_text?.length > 0);
      this.tableData.sort((a, b) => {
        if (a.review_date < b.review_date) return  1;
        if (a.review_date > b.review_date) return -1;
        return 0;
      })
      this.best  = this.tableData.filter(d => d.review_score >= this.pthld).slice(0, 3)
      this.worst = this.tableData.filter(d => d.review_score < this.nthld).slice(-3)
    });
  }
}
</script>

<template>
  <div class="d-grid-2-col" style="--column-gap: 25px;">
    <!-- Top Positive Reviews -->
    <div>
      <h3 class="title my-0">Most Recent Positive Reviews</h3>
      <template v-for="review in best" :key="'best_' + JSON.stringify(review)">
        <Review :review="review" star_color="#2f2a95" />
      </template>
    </div>

    <!-- Top Negative Reviews -->
    <div>
      <h3 class="title my-0">Most Recent Negative Reviews</h3>
      <template v-for="review in worst" :key="'worst_' + JSON.stringify(review)">
        <Review :review="review" star_color="#2f2a95" />
      </template>
    </div>
  </div>
</template>
