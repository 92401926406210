<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils";

export default {
  data() {
    return {
      labels: [],
      tableData: [],
      chartOptions: {
        chart: { toolbar: { show: false } },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "70%",
            dataLabels: {
              position: 'top',
            }
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
          offsetY: -22,
          offsetX: 0,
          style: {
            fontSize: "16px",
            colors: ["#313131"]
          },
        },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        grid: { borderColor: "#fff" },
        fill: { opacity: 1 },
        yaxis: {
          labels: {
            formatter: (value) => String(value),
            style: {
              colors: ["#000"],
              fontSize: '14px',
              fontWeight: 500,
            },
          }
        },
        xaxis: {
          labels: {
            style: {
              colors: ["#000"],
              fontSize: '14px',
              fontWeight: 500,
            },
          }
        },
        legend: {
          show: true,
          position: "left",
          fontWeight: 500,
          offsetY: 200,
          offsetX: -30,
          fontSize: "12px",
          labels: { colors: ["#000"] }
        }
      }
    };
  },
  props: ["title", "company", "identifier", "source", "release_date", "colors"],
  created() {
    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      source:       this.source,
      release_date: this.release_date,
      compset: "primary",
      last_3m: true,
      period:  "merged",
      format:  "hash",
    }}).then(response => {
      this.tableData = response.data.data || [];
      this.labels = this.tableData?.[0]?.ratings.map(r => r.rating) || [];

      if (this.$refs["column-chart"])
        this.$refs["column-chart"].updateOptions({
          labels: this.labels,
          colors: this.colors,
          yaxis: { max: function (max) { return max + 1 }, forceNiceScale: true }
        });
    });
  },
  computed: {
    series() {
      let res = [];
      this.tableData.forEach(data => {
        res.push({ name: data.name, data: data.ratings.map(r => Utils.round(r.value, 1)) });
      });
      return res;
    }
  }
}
</script>

<template>
  <div>
    <h3 class="title mt-3"> {{ title }} </h3>
    <apexchart
      class="mt-5"
      height="500"
      type="bar"
      dir="ltr"
      :series="series"
      :options="chartOptions"
      ref="column-chart"
    ></apexchart>
  </div>
</template>
