<script>
import axios from "axios";
import Utils from "@/js/utils";
import PageHeader from "@/components/page-header";
import OleryTable from "@/components/olery-table";

export default {
	data() {
		return {
			loading: false,
      counts:   { numerical: 0, sentiment: 0},
      response: { numerical: [], sentiment: [] },
			collapsed: false,
      ratingCols: ["average"]
		};
	},
  props: ["filterParams"],
  components: { PageHeader, OleryTable },
	watch: {
    filterParams: {
      handler: function () {
        if (this.filterParams.data) this.loadListener(this.filterParams);
      },
      immediate: true
    }
  },
	methods: {
		loadListener (params) {
      var p = Object.assign({per: 'month'}, params.data)
      this.load(p)
    },
    async load (params) {
      Utils.setLoading.bind(this)(true)

			let response = await axios.get('/v3/feedback/analytics/per_rating', { params: {
				start_date:    params.start_date,
				end_date:      params.end_date,
				finished:      params.finished,
				company_ids:   params.company_ids,
				survey_id:     params.survey_id,
        language:      params.response_language,
        has_sentiment: params.has_sentiment,
        question_type: params.question_type,
        subscription_ids: params.subscription_ids
			}})

      this.response = response.data.data;
      ['numerical', 'sentiment'].forEach(type => {
        this.counts[type] = this.response[type].reduce((partial, el) => partial + el.answers_count, 0);
        this.sort(this.response[type]);
      })

      Utils.setLoading.bind(this)(false)
    },
    toBase10(number) { return Number.parseFloat(Utils.round((number / 10))) },
		sort(array) {
			array.sort((a, b) => {
				if (a.count > b.count) return -1;
				if (a.count < b.count) return 1;
				return 0;
			});
			let rank = 1;
			return array.forEach((el) => { el.ranking = rank; rank++; });
		},
		collapseToggle () {
      this.collapsed = !this.collapsed;
    },
    collapseClass () {
      if (this.collapsed) return 'collapse';
      return 'collapse.show';
    },
    tdClass(value) {
      return Utils.ratings.toCss(value);
    },
    cellClass(col) {
      if (/average/.test(col)) return "h-inherit p-0";
      return "";
    },
    fileName(type) {
      return this.$t("general.export_file_name", {
        title: `${this.$t('feedback_analytics.title')}-${this.$t(`feedback_analytics.${type}_ratings`)}`,
        sd: this.filterParams.data.start_date,
        ed: this.filterParams.data.end_date
      });
    },
    formatNumber(val) {
      return Utils.formatNumber(val);
    }
	},
	computed: {
		items() {
      let items = { numerical: [], sentiment: [] };
      ['numerical', 'sentiment'].forEach(type => {
        this.response[type].forEach((a) => {
          let item = {};
          item["ranking"]   = a.ranking;
          item["subrating"] = a.topic ? this.$t(`ratings.rating_comparison.kpis.${a.topic}`) : this.$t("feedback_analytics.without_rating");
          item["quantity"]  = a.answers_count;
          item["ratio"]     = Utils.round((a.answers_count / this.counts[type]) * 100).toString();
          item["average"]   = this.toBase10(a.value);
          items[type].push(item);
        });
      })
			return items;
		},
		fields() {
			return [
				{ value: "ranking",   text: this.$t("feedback_analytics.ranking"),   sortable: true, type: "string" },
				{ value: "subrating", text: this.$t("feedback_analytics.subrating"), sortable: true, type: "string" },
				{ value: "quantity",  text: this.$t("feedback_analytics.quantity"),  sortable: true, type: "number" },
				{ value: "ratio",     text: this.$t("feedback_analytics.ratio"),     sortable: true, type: "number" },
				{ value: "average",   text: this.$t("feedback_analytics.average"),   sortable: true, type: "number" }
			];
		},
		excelColumns() {
			return [
				{
					label: this.$t("feedback_analytics.ranking"),
					field: "ranking"
				},
				{
					label: this.$t("feedback_analytics.subrating"),
					field: "subrating"
				},
				{
					label: this.$t("feedback_analytics.quantity"),
					field: "quantity"
				},
				{
					label: this.$t("feedback_analytics.ratio"),
					field: "ratio"
				},
				{
					label: this.$t("feedback_analytics.average"),
					field: "average"
				}
			]
		}
	}
}
</script>

<template>
	<div>
    <PageHeader :title="$t('feedback_analytics.ratings')">
      <template v-slot:subtitle>
        <button class='btn btn-primary float-end' @click='collapseToggle'>
          <span :class="{
              'mdi mdi-unfold-more-horizontal': collapsed,
              'mdi mdi-unfold-less-horizontal': !collapsed
            }" >
            &nbsp;{{ !collapsed ? $t("general.collapse") : $t("general.expand") }}
          </span>
        </button>
      </template>
    </PageHeader>

    <div class="d-flex flex-column mb-5 mt-3" >
			<div class="card" :class="{'loading': loading}">
				<div class="card-body">
          <div class="mb-5" :class="collapseClass()" v-for="type in Object.keys(response)" :key="type">
            <!-- Table -->
            <OleryTable
              v-if="response[type].length"
              :title="$t(`feedback_analytics.${type}_ratings`)"
              :fileName="fileName(type)"
              titleClass="m-0 font-size-21"
              :btnText="$t('general.export_excel')"
              btnClass="btn btn-secondary text-nowrap"
              :ratingCols="ratingCols"
              :dataTable="{
                tableHeaderClass:    'border border-0',
                items:               items[type],
                headers:             fields,
                headerTextDirection: 'center',
                bodyTextDirection:   'center',
                sortBy:              'review_count',
                sortType:            'desc',
                pagination:          false
              }"
            >
              <template #item-ratio="{ item }">
                {{ formatNumber(item.ratio) }}%
              </template>
            </OleryTable>
          </div>
        </div>
			</div>
		</div>
	</div>
</template>
