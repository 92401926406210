<script>
import {axios} from '@/js/reports'
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      cols: [
        { field: "segment", label: "Channel" },
        { field: "reviews", label: "Number of Reviews MTD" },
        { field: "reviews_last_month", label: "Last Month Total" },
        { field: "average_rating", label: "Avg. Review Score MTD" },
        { field: "average_rating_last_month", label: "Last Month Average Score" },
      ],
      sourceHash: {
        booking_com:  "Booking.com",
        expedia:      "Expedia.com",
        hotels_com:   "Hotels.com",
        google:       "Google",
        orbitz:       "Orbitz",
        open_table:   "Open Table",
        trip_advisor: "TripAdvisor",
        yelp:         "Yelp",
        others:       "Others"
      },
      rows: [],
      sources: {
        accommodation: ['booking_com', 'expedia', 'hotels_com', 'google', 'orbitz'],
        restaurant: ['open_table', 'trip_advisor', 'google', 'yelp']
      }
    };
  },
  components: { ExportExcel },
  props: ["title", "company", "identifier", "release_date"],
  created() {
    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      segment:  "source",
      source: this.sources[this.company.property_type],
      property: "grouped",
      compset: false,
      format:  "hash",
      mtd: true,
      prev_months: 1
    }}).then(response => {
      let computedSources = this.sources[this.company.property_type];
      let currentMonth  = response.data.data?.find(d => d.period == "current");
      let previousMonth = response.data.data?.find(d => d.period == "previous");
      if (currentMonth?.segments?.find(s => s.segment == "Others")) computedSources.push("others");
      computedSources.forEach(source => {
        let currentData  = currentMonth?.segments?.find(s => s.segment == this.sourceHash[source]);
        let previousData = previousMonth?.segments?.find(s => s.segment == this.sourceHash[source]);
        let data = {
          segment: this.sourceHash[source],
          reviews: currentData?.reviews || "-",
          average_rating: currentData?.average_rating || "-",
          reviews_last_month:        previousData?.reviews || "-",
          average_rating_last_month: previousData?.average_rating || "-"
        };
        this.rows.push(data);
      });
    });
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th v-for="col in cols" :key="col.field">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in rows" :key="row.segment">
            <template v-for="col in cols" :key="col.field">
              <td>{{ row[col.field] }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
