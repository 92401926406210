<script>
import {axios} from '@/js/reports'

export default {
  data() {
    return {
      labels: [],
      tableData: [],
      chartOptions: {
        chart: { toolbar: { show: false } },
        dataLabels: {
          enabled: true,
          offsetY: -7,
          offsetX: 0,
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            colors: ["#313131"]
          },
          background: { enabled: false }
        },
        stroke: { curve: "smooth" },
        grid: { borderColor: "#fff" },
        fill: { opacity: 1 },
        markers: { size: 6, strokeWidth: 0 },
        yaxis: {
          labels: {
            formatter: (value) => Math.round(value),
            style: {
              colors: ["#000"],
              fontSize: '14px',
              fontWeight: 500,
            },
          }
        },
        xaxis: {
          labels: {
            style: {
              colors: ["#000"],
              fontSize: '14px',
              fontWeight: 500,
            },
          }
        },
        legend: {
          show: true,
          position: "right",
          fontWeight: 500,
          offsetY: 200,
          offsetX: -30,
          fontSize: "12px",
          labels: { colors: ["#000"] }
        }
      }
    };
  },
  props: ["title", "company", "identifier", "source", "release_date", "last_3m", "colors"],
  created() {
    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      source:       this.source,
      last_3m: this.last_3m,
      period: "week",
      months_ago: 2,
      compset: "primary",
      format:  "hash"
    }}).then(response => {
      this.tableData = response.data.data;

      let seen = [];
      this.tableData.forEach(data => {
        if (!seen.includes(data.week)) {
          this.labels.push(data.week);
          seen.push(data.week);
        }
      });

      if (this.$refs["column-chart"])
        this.$refs["column-chart"].updateOptions({ labels: this.labels.map(l => `Week ${l}`), colors: this.colors });
    });
  },
  computed: {
    series() {
      let res = [];
      let seenCompanies = [];
      this.tableData.forEach(data => {
        if (!seenCompanies.includes(data.name)) {
          let filtered = this.tableData.filter(t => t.name == data.name);
          let aggregatedData = [];
          this.labels.forEach(label => {
            aggregatedData.push(filtered.find(f => f.week == label)?.ranking)
          });
          seenCompanies.push(data.name);
          res.push({ name: data.name, data: aggregatedData })
        }
      });
      return res;
    }
  }
}
</script>

<template>
  <div>
    <h3 class="title mt-3"> {{ title }} </h3>
    <apexchart
      class="mt-5"
      height="550"
      type="line"
      dir="ltr"
      :series="series"
      :options="chartOptions"
      ref="column-chart"
    ></apexchart>
  </div>
</template>
