<script>
import {axios} from '@/js/reports'
import dayjs from "dayjs"
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      tableData: { current: [], previous: [] },
      dates:     { current: [], previous: [] },
      properties: [],
      cols: [
        { field: "name", label: "Property Name" },
        { field: "subRow", label: "" },
      ],
      subRows: [
        { field: "average_rating", period: "current", label: "Avg. Review Score" },
        { field: "average_rating", period: "previous", label: "Avg. Review Score Last Year" },
        { field: "reviews", period: "current", label: "Number of Reviews" },
        { field: "reviews", period: "previous", label: "Number of Reviews Last Year" }
      ],
      rows: []
    };
  },

  components: { ExportExcel },
  props: ['title', 'group', 'identifier', 'release_date', 'index', 'offset', 'download'],

  mounted() {
    window.addEventListener("overall-trends-update", this.loadListener)
  },
  beforeUnmount() {
    window.removeEventListener("overall-trends-update", this.loadListener)
  },
  created() {
    if (this.index == 1) {
      axios.get("/v3/query/db/_", { params: {
        group_id:     this.group.id,
        identifier:   this.identifier,
        release_date: this.release_date,
        compset: "primary",
        format:  "hash",
        prev_12m: true,
        fill_12m: true,
      }}).then(response => {
        this.formatData(response.data.data);
        const event = new CustomEvent("overall-trends-update", { detail: { rows: this.rows, cols: this.cols } });
        window.dispatchEvent(event);
      });
    }
  },

  methods: {
    loadListener(data) {
      this.rows = window.reports.rowsOffset(this, data.detail)
      this.cols = data.detail.cols;
    },

    formatData(data) {
      ["previous", "current"].forEach(period => {
        this.tableData[period] = data.filter(d => d.period == period);
        this.tableData[period].forEach(data => {
          if (!this.properties.includes(data.name)) this.properties.push(data.name);
          if (!this.dates[period].find(e => e.label == data.date_label)) {
            let field = data.date_label == "cumulative" ? "cumulative" : data.date;
            this.dates[period].push({ field: field, label: data.date_label });
          }
        });
      });

      this.dates.current.splice(12, 1);
      this.cols.splice(2, 0, ...this.dates.current);

      this.properties.forEach(property => {
        this.subRows.forEach((subRow, index) => {
          let row = {};
          if (index == 0) row.name = property;
          else row.name = "";
          row.subRow = subRow.label;
          row.period = subRow.period;
          this.dates[subRow.period].forEach(date => {
            let res = this.tableData[subRow.period].find(p => p.name == property && p.date_label == date.label);
            let field = date.field;
            if(subRow.period == "previous" && date.field != "cumulative")
                field = dayjs(date.field).add(1, "year").format("YYYY-MM-DD");
            if (res?.[subRow.field] != null) {
              if (subRow.field != "reviews") row[field] = Utils.round(res[subRow.field], 2);
              else row[field] = res[subRow.field];
            }
            else row[field] = "-";
          });
          this.rows.push(row);
        });
      });
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    compare(indexRow, field) {
      let row = this.rows[indexRow];
      if (row.period == "current" && !/cumulative|subRow/.test(field) && row[field] != "-") {
        let prevRow = this.rows[indexRow + 1];
        return row[field] < prevRow[field];
      }
      return false;
    }
  },
  computed: {
    computedTitle() {
      let title = this.title;
      if (this.index && this.index != 1) return title += " (Continued)";
      return title;
    }
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${computedTitle}.xls`" :title="computedTitle">
      <table border="1" class="table table-striped-quarter table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th class="property-name-column">{{ cols[0].label }}</th>
            <th v-for="col in cols.slice(1)" :key="col.field"> {{ capitalize(col.label) }} </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, indexRow) in rows" :key="indexRow">
            <td class="property-name-column">{{ row.name }}</td>
            <td v-for="(col, indexCol) in cols.slice(1)" :key="col.field + indexCol" :class="{ 'text-danger': compare(indexRow, col.field) }">
              {{ row[col.field] }}
            </td>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
