export const state     = { auth_token: null, token: null, ep: null, code: null, overr_user: null }
export const getters   = {
  token:      state => () => state.token,
  ep:         state => () => state.ep,
  code:       state => () => state.code,
  auth_token: state => () => state.auth_token,
  overr_user: state => () => state.overr_user,
  has_token:  state => () => state.ep || state.token || state.auth_token
}
export const mutations = {
  SAVE_TOKEN(state, { auth_token, token, ep, code, overr_user }) {
    if (token) state.token = token
    if (ep)    state.ep    = ep
    if (code)  state.code  = code
    if (auth_token) state.auth_token = auth_token
    if (overr_user) state.overr_user = overr_user
  }
}
export const actions   = { }
