<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"

export default {
  data() {
    return {
      sources: [
        ["source", "currentMonth", "previousMonth"]
      ],
      chartData: [],
      colors: [],
      currentMonth: null,
      previousMonth: null,
      currentMonthData: [],
      previousMonthData: [],
      chartOptions: {
        tooltip: {
          show: true,
          trigger: "item",
          formatter: (params) => `${params.data[0]}: ${params.data[params.seriesIndex + 1]}%`,
        },
        legend: {
          type: 'plain',
          orient: 'vertical',
          left: 50,
          top: 100,
          textStyle: {
            fontWeight: "bold",
            fontSize: 14
          }
        },
        dataset: {
          source: []
        }
      },
      seriesOptions: {
        type: "pie",
        radius: "80%",
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        label: {
          show: true, position: "outside",
          formatter: function (params) {
            return params.value[params.seriesIndex + 1] + "%\n"
          },
          fontSize: 14
        },
        labelLine: {
          show: true
        }
      }
    };
  },
  props: ["title", "company", "identifier", "release_date", "compset", "source", "sourceColors"],
  created() {
    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      source: this.source,
      compset: this.compset,
      months_ago: 2,
      format:  "hash"
    }}).then(response => {
      this.chartData = response.data.data.filter(d => d.hid == this.company.id);

      this.currentMonthData  = this.chartData.filter(c => c.period == "current");
      this.previousMonthData = this.chartData.filter(c => c.period == "previous");

      let computedSources = this.previousMonthData.map(data => data.source);
      this.currentMonthData.forEach(data => {
        if (data.source && !computedSources.includes(data.source)) computedSources.push(data.source);
      });

      computedSources.forEach(source => {
        let current = this.currentMonthData.find(c => c.source == source);
        let previous = this.previousMonthData.find(c => c.source == source);
        this.sources.push([source, current?.share || 0, previous?.share || 0]);
      });

      let sorted = this.sources.splice(1).sort((a, b) => {
        let sourceA = Object.keys(this.sourceColors).find(s => Utils.compareSources(s, a[0]));
        let sourceB = Object.keys(this.sourceColors).find(s => Utils.compareSources(s, b[0]));
        let indexA = Object.keys(this.sourceColors).indexOf(sourceA);
        let indexB = Object.keys(this.sourceColors).indexOf(sourceB);
        return indexA - indexB;
      });
      this.sources.splice(1, 0, ...sorted);

      this.colors = Object.keys(this.sourceColors)
        .filter(s => this.sources.find(t => Utils.compareSources(s, t[0])))
        .map(s => this.sourceColors[s]);
    });
  },
  computed: {
    options() {
      return {
        ...this.chartOptions,
        dataset: { source: this.sources },
        color : this.colors,
        series: [
          {
            ...this.seriesOptions,
            center: ["35%", "60%"],
            encode: {
              itemName: "source",
              value: "currentMonth"
            }
          },
          {
            ...this.seriesOptions,
            center: ["78%", "60%"],
            encode: {
              itemName: "source",
              value: "previousMonth"
            }
          }
        ]
      }
    }
  }
}
</script>

<template>
  <div class="d-flex flex-column" id="chart-root">
    <h3 class="title mt-3"> {{ title }} </h3>
    <v-chart class="w-100 d-flex justify-content-center" style="width: 100%; height: 450px;" :option="options" />
    <div class="position-relative mt-4">
      <h4 class="title position-absolute" style="left: calc(35% - calc(144.533px / 2));">Current Month</h4>
      <h4 class="title position-absolute" style="left: calc(78% - calc(157.4px / 2));">Previous Month</h4>
    </div>
  </div>
</template>
