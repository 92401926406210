<script>
import Multiselect from "vue-multiselect";
import { ColorPicker } from "vue3-colorpicker";
import axios from "axios";
import Utils from "@/js/utils";
import DataUtils from "@/js/data-utils";
import { ref } from "vue";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      loading: false,
      isDirty: false,
      isValid: true,
      fields: ["name", "languages", "default_locale", "company_ids"],
      name: null,
      availableLocales: ["en", "nl", "de", "fr", "it", "ar", "ru", "sk", "nb", "pt", "cs", "pl","el", "zh-TW", "zh-CN", "ja", "tr", "es", "id", "da", "fi", "sv"],
      logo: null,
      bg: null,
      colorRef: null,
      fileSizeLimit: 4 //MB
    };
  },
  props: ["creation", "companies", "survey"],
  components: { Multiselect, ColorPicker },
  created() {
    if (this.survey.color_hex) this.colorRef = ref(this.survey.color_hex);
  },
  methods: {
    ...DataUtils,
    async updateSurveySettings() {
      if (!this.checkValidity()) return;
      Utils.setLoading.bind(this)(true);
      Utils.updatePageOpts({ statusMessage: { status: "saving", icon: "ion ion-md-sync" } });

      // Handle file upload
      let formData = new FormData();
      ['logo', 'bg'].forEach(field => {
        if (this.survey[field]) formData.append(field, this.survey[field])
      });

      let payload = Utils.slice(this.survey, ["name", "languages", "company_ids", "default_locale", "color_hex"]);

      // Update or create survey
      let survey = {};
      if (this.creation) {
        survey = await axios.put('/v3/feedback/surveys', {
          ...payload,
          translations: {
            [this.survey.default_locale]: {
              title:        this.$t("new_survey.settings.default_title", this.survey.default_locale),
              instructions: this.$t("new_survey.settings.default_instructions", this.survey.default_locale)
            }
          }
        });
      } else survey = await axios.post(`/v3/feedback/surveys/${this.survey.id}`, payload);

      if (formData.get("logo") || formData.get("bg")) {
        survey = await axios.post(`/v3/feedback/surveys/${survey.data.data.id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          transformRequest: () => formData
        });
      }

      this.$emit("updateSurvey", survey.data.data);

      Utils.setLoading.bind(this)(false);
      Utils.updatePageOpts({ statusMessage: { status: "saved", icon: "mdi mdi-check-bold" } });
      this.isDirty = false;
      this.$emit("savedChanges");
      if (this.creation) this.$router.push({ name: 'feedback_edit_survey', query: this.$route.query, params: { id: survey.data.data.id } });
    },
    checkValidity() {
      this.isValid = true;
      if (!this.isValidName || !this.isValidLanguages || !this.isValidDefaultLanguage || !this.isValidCompanies) this.isValid = false;
      return this.isValid;
    },
    setDirty(field) {
      if (this.survey[field]?.length > 0) this.isDirty = true;
      else this.isDirty = this.fields.reduce((s, a) => s + a.length, 0) == 0;

      this.$emit("dirtyUpdate", this.isDirty);
    },
    updateDefaultLanguage() {
      if(this.survey.languages?.length == 1) this.survey.default_locale = this.survey.languages[0];
      else if(!this.survey.languages.includes(this.survey.default_locale)) this.survey.default_locale = null;
    },
    async handleFileUpload(event, field) {
      const file = event.target.files[0];
      if (!file) return;
      const size = file.size / 10**6; // size in MB
      if (size > this.fileSizeLimit) {
        Swal.fire({ text: this.$t("general.image_too_large", { size: Utils.round(size), sizeLimit: this.fileSizeLimit }), icon: "info", showCancelButton: false, showConfirmButton: true, allowOutsideClick: true, backdrop: true });
        event.target.value = null;
        this.survey[field] = null;
        this[field]        = null;
        return;
      }
      this.survey[field] = file;
      this[field] = URL.createObjectURL(this.survey[field]);
    },
    findCompanyName(id) {
      return this.companies.find(c => c.id == id)?.name;
    }
  },
  computed: {
    isValidName() {
      if (this.survey.name && this.survey.name.length != 0) return true;
      return false;
    },
    isValidLanguages() {
      this.updateDefaultLanguage();
      if (this.survey.languages && this.survey.languages.length != 0) return true;
      return false;
    },
    isValidDefaultLanguage() {
      if (this.survey.default_locale && this.survey.default_locale.length != 0) return true;
      return false;
    },
    isValidCompanies() {
      if (this.survey.company_ids && this.survey.company_ids.length != 0) return true;
      return false;
    },
    isValidImage() {
      return {
        logo: !!this["logo"] || !!this.survey["logo_url"],
        bg:   !!this["bg"]   || !!this.survey["bg_url"]
      }
    },
    orderedLocales() {
      let orderLocales = [];
      this.availableLocales.forEach(l => orderLocales.push({ label: this.locales(l).name, code: l }));
      orderLocales = orderLocales.sort((a, b) => a.label.localeCompare(b.label));
      return orderLocales.map(l => l.code);
    },
    color: {
      get() {
        return this.colorRef;
      },
      set(value) {
        this.survey.color_hex = value;
      }
    },
    companiesOptions () {
      return this.companies.map(c => String(c.id)).filter( id => !this.survey.company_ids.includes(Number(id)) )
    }
  },
};
</script>

<template>
  <div class="root-settings" :class="{ 'cursor-loading': loading }">
    <div class="card">
      <div class="card-body">
        <h4 class="ms-0 ms-sm-4 mt-3">{{ $t("new_survey.settings.subtitle") }}</h4>

        <div>
          <!-- Name -->
          <div class="row mt-4">
            <div class="label-settings col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <label class="me-2 mb-0 form-label">{{ $t("new_survey.settings.name") }}</label>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 d-flex flex-column justify-content-center">
              <input class="form-control" type="text" v-model="survey.name" :placeholder="$t('new_survey.settings.name_help')" @input="setDirty('name')" :class="{ 'valid': isValidName, 'invalid': !isValidName }"/>
            </div>
          </div>

          <!-- Languages -->
          <div class="row mt-4">
            <div class="label-settings col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <label class="me-2 mb-0 form-label">{{ $t("new_survey.settings.languages") }}</label>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 d-flex flex-column justify-content-center">
              <multiselect class="languages" :multiple="true" v-model="survey.languages" :options="orderedLocales" :custom-label="l => locales(l).name" :showLabels="false" :placeholder="$t('new_survey.settings.languages_help')" @select="setDirty('languages')" @remove="setDirty('languages')" :class="{ 'valid': isValidLanguages, 'invalid': !isValidLanguages }">
                <template v-slot:option="props">
                  <div class="d-flex align-items-center">
                    <span :class="`flag flag-${locales(props.option, 'language').code}`" class="me-2"></span>
                    <span> {{ locales(props.option).name }} </span>
                  </div>
                </template>
              </multiselect>
            </div>
          </div>

          <!-- Default language -->
          <div class="row mt-4">
            <div class="label-settings col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <label class="me-2 mb-0 form-label">{{ $t("new_survey.settings.default_language") }}</label>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 d-flex flex-column justify-content-center">
              <multiselect class="default_language" :multiple="false" v-model="survey.default_locale" :options="survey.languages" :custom-label="l => locales(l).name" :showLabels="false" :placeholder="$t('new_survey.settings.default_language_help')" @select="setDirty('default_locale')" @remove="setDirty('default_locale')" :allow-empty="false" :searchable="false" :class="{ 'valid': isValidDefaultLanguage, 'invalid': !isValidDefaultLanguage }">
                <template v-slot:singleLabel="props">
                  <div class="d-flex align-items-center">
                    <span :class="`flag flag-${locales(props.option, 'language').code}`" class="me-2"></span>
                    <span> {{ locales(props.option).name }} </span>
                  </div>
                </template>

                <template v-slot:option="props">
                  <div class="d-flex align-items-center">
                    <span :class="`flag flag-${locales(props.option, 'language').code}`" class="me-2"></span>
                    <span> {{ locales(props.option).name }} </span>
                  </div>
                </template>
              </multiselect>
            </div>
          </div>

          <!-- Companies -->
          <div class="row mt-4">
            <div class="label-settings col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <label class="me-2 mb-0 form-label">{{ $t("new_survey.settings.companies") }}</label>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 d-flex flex-column justify-content-center">
              <multiselect class="companies" :multiple="true" v-model="survey.company_ids" :options="companiesOptions" :custom-label="c => findCompanyName(c)" :showLabels="false" :placeholder="$t('new_survey.settings.companies_help')" @select="setDirty('company_ids')" @remove="setDirty('company_ids')" :class="{ 'valid': isValidCompanies, 'invalid': !isValidCompanies }"></multiselect>
            </div>
          </div>

          <!-- Design -->
          <h4 class="ms-0 ms-sm-4 mt-5">{{ $t("new_survey.settings.design") }}</h4>

          <!-- Color -->
          <div class="row mt-4">
            <div class="label-settings col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <label class="me-2 mb-0 form-label" for="colorInput">{{ $t("new_survey.settings.color") }}</label>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 d-flex flex-column justify-content-center">
              <ColorPicker v-model:pureColor="color" useType="pure" format="hex" pickerType="chrome" lang="En" />
            </div>
          </div>

          <!-- Logo -->
          <div class="row mt-5" :class="{ 'align-items-center': survey.logo_url }">
            <div class="label-settings col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <div class="d-flex">
                <label class="me-2 mb-0 form-label" for="logoInput">{{ $t("new_survey.settings.logo") }}</label>
                <span v-b-tooltip.hover :title="$t('general.size_limit_tooltip', { limit: fileSizeLimit })">
                  <i class="mdi mdi-help-circle font-size-16"></i>
                </span>
              </div>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-4 d-flex flex-column justify-content-center">
              <input
                class="form-control"
                :class="{ 'valid': isValidImage.logo }"
                type="file"
                id="logoInput"
                :placeholder="$t('new_survey.settings.file_placeholder')"
                accept="image/*"
                @change="handleFileUpload($event, 'logo')"
              >
              <!-- <div v-if="!isValidImage.logo" class="mt-1">{{ $t("new_survey.settings.upload_image") }}</div> -->
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 d-flex d-xl-block justify-content-center mt-4 mt-xl-0">
              <img class="resize-img" v-if="logo || survey.logo_url" :src="logo || survey.logo_url" />
            </div>
          </div>

          <!-- Background -->
          <div class="row mt-5" :class="{ 'align-items-center': survey.bg_url }">
            <div class="label-settings col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <div class="d-flex">
                <label class="me-2 mb-0 form-label" for="bgInput">{{ $t("new_survey.settings.background") }}</label>
                <span v-b-tooltip.hover :title="$t('general.size_limit_tooltip', { limit: fileSizeLimit })">
                  <i class="mdi mdi-help-circle font-size-16"></i>
                </span>
              </div>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-4 d-flex flex-column justify-content-center">
              <input
                class="form-control"
                :class="{ 'valid': isValidImage.bg }"
                type="file"
                id="bgInput"
                :placeholder="$t('new_survey.settings.file_placeholder')"
                accept="image/*"
                @change="handleFileUpload($event, 'bg')"
              >
              <!-- <div v-if="!isValidImage.bg" class="mt-1">{{ $t("new_survey.settings.upload_image") }}</div> -->
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 d-flex d-xl-block justify-content-center mt-4 mt-xl-0">
              <img class="resize-img" v-if="bg || survey.bg_url" :src="bg || survey.bg_url" />
            </div>
          </div>

          <div class="row mb-4 mt-5">
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"></div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 d-flex justify-content-end">
              <button class="btn btn-primary w-25" @click="updateSurveySettings"> {{ $t("new_survey.settings.save") }} </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
