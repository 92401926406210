<script>
import Utils from '@/js/form-utils';

export default {
  data() {
    return {
      newOption: ''
    }
  },
  props: ["question", "settings", "editing", "question_index"],
  methods: {
    isDefaultLanguage() {
      return this.settings.currentLanguage == this.settings.defaultLanguage;
    },
    addOption() {
      if(this.newOption && this.newOption.length > 0) {
        let toPush = { id: Utils.createId(this.newOption, this.question.options), [this.settings.currentLanguage]: this.newOption };

        if (this.isDefaultLanguage()) toPush = {...toPush, default: this.newOption };

        this.question.options.push(toPush);

        this.newOption = ''
        this.$emit('changeInput')
      }
    },
    updateOption(option_id) {
      if (this.isDefaultLanguage()) {
        let index = this.question.options.findIndex(o => o.id == option_id)
        this.question.options[index].default = this.question.options[index][this.settings.currentLanguage]
        this.question.options[index].id = Utils.createId(this.question.options[index][this.settings.currentLanguage], this.question.options)
      }
      this.$emit('changeInput')
    },
    deleteOption(option_id) {
      this.question.options = this.question.options.filter(q => q.id != option_id)
      this.$emit('changeInput')
    },
    placeholder(option) {
      if (!option[this.settings.currentLanguage]) return this.$t("new_survey.questions.edit.needs_translation");
    },
    optionSublabel(option) {
      let res = { icon: null, label: null };
      if (!option[this.settings.defaultLanguage]) {
        res.label = option[Object.keys(option).filter(op => op != 'id')[0]];
        res.icon = Object.keys(option).filter(op => op != 'id')[0];
      } else {
        res.label = option[this.settings.defaultLanguage];
        res.icon = this.settings.defaultLanguage
      }
      return res;
    },
    getAnswer (o) {
      return o[this.settings.currentLanguage] || o.default || o.text
    }
  }
};
</script>

<template>
  <div class="form-group enum-root">
    <div class="enum text-left align-self-center">
      <div class="w-100 d-flex flex-column">
        <div v-for="(option, index) in question.options" :key="`${question.id}_${option.id}_${index}`" class="d-flex flex-column mb-3">
          <div class="d-flex w-100 custom-control custom-control-inline custom-radio b-custom-control-lg me-0">
            <input type="radio" :name='"question_"+question.id' v-model='question.answer' :value="option.id" class="custom-control-input" :id="`${question.id}_${option.id}_${index}`" :disabled='editing' @change="$emit('answerChanged', question.answer)" />
            <label class="custom-control-label active" :for="`${question.id}_${option.id}_${index}`">
              <template v-if='!editing'>
                {{ getAnswer(option) }}
              </template>
              <template v-else>
                <input :ref="question_index + '_option_' + option.id" class="custom-textarea font-size-14 font-weight-bold" :class="{ 'missing-translation': editing && $parent.hasMissingTranslations(question_index, 'options', option.id) }" :placeholder="placeholder(option)" v-model="option[settings.currentLanguage]" @input="updateOption(option.id)" />
              </template>
            </label>
            <a href="javascript:void(0);" :title="$t('new_survey.questions.edit.delete')" class="align-self-center ms-1 text-dark font-size-13" @click="deleteOption(option.id)" v-if='editing'>
              <i class="ion ion-md-close"/>
            </a>
          </div>
          <div class="ps-2 d-flex align-items-center mt-1" v-if="editing && !option[settings.currentLanguage]">
            <span class="flag-option-label" :class="`flag flag-${optionSublabel(option).icon}`"></span>
            <span class="font-size-12 ps-2 input-sublabel"> {{ optionSublabel(option).label }} </span>
          </div>
        </div>

        <!-- Add option field -->
        <div v-if="editing && isDefaultLanguage()" class="d-flex custom-control custom-control-inline custom-checkbox b-custom-control-lg mt-3 mx-0 w-100">
          <input class="custom-textarea font-size-14 new-option ps-2" :placeholder="$t('new_survey.questions.edit.multiple_select_placeholder')" style="text-align: start;" v-model="newOption" />
          <a href="javascript:void(0);" class="align-self-center ms-4 btn btn-outline-primary" @click="addOption"> {{ $t("new_survey.questions.edit.add") }} </a>
        </div>
      </div>
    </div>
  </div>
</template>
