<script>
export default {
  data() {
    return {
      localPageNumber: null,
      pages: [
        // The interval key defines the section's number of pages
        { section: "Overall Trends", interval: 3 },
        { section: "Property Details - All Channels", interval: 1 },
        { section: "Perfect Score Summary", interval: 1 },
        { section: "Review Response Coverage by Channel", interval: 1 },
        { section: "Review Landscape Current Month", interval: 1 },
        { section: "Review Landscape Same Month Last Year", interval: 1 },
        { section: "Review Landscape Previous Month", interval: 1 },
        { section: "Positive Trending Topics - Current Month", interval: 1 },
        { section: "Negative Trending Topics - Current Month", interval: 1 },
        { section: "TripAdvisor Review Summary", interval: 2 },
        { section: "TripAdvisor Review Summary Last Year", interval: 2 },
        { section: "TripAdvisor Performance Comparison", interval: 1 },
        { section: "Booking.com Review Summary", interval: 2 },
        { section: "Booking.com Review Summary Last Year", interval: 2 },
        { section: "Expedia Review Summary", interval: 2 },
        { section: "Expedia Review Summary Last Year", interval: 2 },
        { section: "Hotels.com Review Summary", interval: 2 },
        { section: "Hotels.com Review Summary Last Year", interval: 2 },
        { section: "Google Review Summary", interval: 2 },
        { section: "Google Review Summary Last Year", interval: 2 },
        { section: "TripAdvisor Executive Summary", interval: 3 },
        { section: "OTA Summary", interval: 3 },
      ],
    };
  },
  props: ["data"],
  created() {
    this.$parent.pageNumber++;
    this.localPageNumber = this.$parent.pageNumber;
    this.computeIndexes();
  },
  methods: {
    computeIndexes() {
      this.pages[0].page = [3, 3 + this.pages[0].interval - 1];
      for (let i = 1; i < this.pages.length; i++) {
        let lowerLimit = this.pages[i-1].page[1] + 1
        let upperLimit = lowerLimit + this.pages[i].interval - 1;
        this.pages[i].page = [lowerLimit, upperLimit];
      }
    },
    pageLabel(data) {
      if (data[0] != data[1]) return `Pages ${data[0]}-${data[1]}`;
      return `Page ${data[0]}`;
    }
  }
}
</script>

<template>
  <div class="page summary">
    <div class="content d-flex flex-column align-items-center">
      <h2 class="mb-4">Table of Contents</h2>

      <ol class="toc-list" role="list">
        <li v-for="page in pages" :key="page.section">
          <a :href="`#${page.page[0]}`">
            <span class="title-list">
              {{ page.section }}
              <span class="leaders" aria-hidden="true"></span>
            </span>
            <span :data-href="`#${page.page[0]}`" class="page-list">
              <span class="visually-hidden">&nbsp;</span>
              {{ pageLabel(page.page) }}
            </span>
          </a>
        </li>
      </ol>

      <div class="footer d-flex justify-content-between align-items-center py-2 mx-3">
        <img src="@/assets/images/reports/replab/footerlogo.png" style="height: 30px;">

        <span> {{ `${data.company.name} Enterprise_MonthEnd_${data.month} ${data.year}` }} </span>

        <div>
          <img src="@/assets/images/reports/replab/footerlogo.png" style="height: 30px; visibility: hidden;">
          <span> {{ localPageNumber }} </span>
        </div>
      </div>
    </div>
  </div>
</template>