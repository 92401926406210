<script>
import Filters     from "@/components/filter";
import DataUtils   from "@/js/data-utils";
import Multiselect from "vue-multiselect";

import ConcentrationMap from "./concentration-map";
import ClusterMap       from "./cluster-map";
import PerLocationMap   from "./per-location-map";
import PlaceholderMap   from "./placeholder-map";

import ratings from "@/js/ratings";
import Utils   from "@/js/utils";
require("@/assets/images/point.png");

export default {
  data() {
    return {
      user: {},
      dataFormatType: "overall_rating_average",
      dataFormatOptions: ["review_count", "overall_rating_average", "sentiment"],
      dataViewOptions: ["cluster", "concentration", "per_location"],
      dataViewType: "cluster",
      prevDataViewType: null,
      areaOptions: [],
      areaType: null,
      loading: true,
      data: [],
      filterParams: {},
      cols: ["daterange", "property_type", "continent", "country", "regions", "city", "category", "group", "reviewer_country", "language", "tripadvisor_rating", "sources", "travel_composition", "filters"],
      params: null,
      ratingColors: {
        "0":  "#D54939",
        "1":  "#EA5C39",
        "2":  "#F48E2F",
        "3":  "#F4A42C",
        "4":  "#F4C02C",
        "5":  "#F4CD2C",
        "6":  "#DDCE36",
        "7":  "#B8CE46",
        "8":  "#96CE56",
        "9":  "#75CE65",
        "10": "#54CA74"
      },
      maplibregl: {
        initOpts: {
          container: "map",
          style:     "https://api.maptiler.com/maps/basic/style.json?key=K5RSaWcE1fHnQnfzOndl",
          zoom:      5,
        }
      },
      nextPositionUpdate: null,
      moveDelay: 200,
      position: { lng: null, lat: null, zoom: null },
      urlParams: {
        v:  "dataViewType",
        dp: "dataFormatType",
        at: "areaType"
      },
      heatColors: {
        "0.0": "rgba(0, 0, 0, 0)",
        "0.2": "#62a1db",
        "0.4": "#e7d87d",
        "0.6": "#dd9f40",
        "0.8": "#be4f2a",
        "1.0": "#b01111"
      },
      clusterQuadrants: 5,
      reviewsTotal: 0,
      reviewsOnScreen: 0,
      allAreaTypes: ["borough", "city", "city_block", "city_district", "country", "county", "district", "hamlet", "municipality", "neighbourhood", "plot", "province", "quarter", "region", "state", "state_district", "suburb", "town", "village"],
      defaults: { lng: 14.7, lat: 46.7, zoom: 3.7 },
      libLoaded: false,
      locationLoading: true,
      clusterData:  false,
      locationData: false
    }
  },
  components: { Filters, Multiselect, PerLocationMap, ConcentrationMap, ClusterMap, PlaceholderMap },
  async created() {
    Utils.updatePageOpts({ no_padding: true, no_footer: true, ignore_container_flex: true });
    this.user = await this.$store.dispatch("user/fetch");
    this.readURL();
    await this.loadMapLibre();
    this.libLoaded = true;
  },
  methods: {
    ...DataUtils,
    async loadMapLibre() {
      return new Promise((resolve, reject) => {
        const mapLibreLink   = document.createElement("link");
        const mapLibreScript = document.createElement("script");

        mapLibreLink.setAttribute("href", "https://unpkg.com/maplibre-gl@3.5.2/dist/maplibre-gl.css");
        mapLibreLink.setAttribute("rel", "stylesheet");

        document.head.appendChild(mapLibreLink);

        mapLibreScript.setAttribute("type", "text/javascript");
        mapLibreScript.setAttribute("src", "https://unpkg.com/maplibre-gl@3.5.2/dist/maplibre-gl.js");
        mapLibreScript.setAttribute("async", "");
        mapLibreScript.setAttribute("defer", "");

        document.head.appendChild(mapLibreScript);

        mapLibreScript.onerror = reject;

        mapLibreScript.onload = () => resolve();
      })
      .catch(err => {
        console.error("Error API Google: ", err);
      })
    },
    async init(params) {
      this.params = params.data;
      this.buildAreaOptions();
      if (this.dataViewType == "per_location") {
        this.loading = false;
        return;
      }
      this.loading = true;
      this.data = await this.$store.dispatch("heatmap/fetch", params.data);
      this.load();
    },
    async load() {
      this.data = this.selectData(this.dataFormatType, this.data);
      this.clusterData = true;
      this.loading = false;
    },
    selectData(dataFormatType, data) {
      return data.map(d => {
        const rating = dataFormatType == "overall_rating_average" ? d.heatmap?.[dataFormatType] / 10 : d.heatmap?.[dataFormatType] || 0;
        this.reviewsTotal += d.heatmap?.review_count;
        return {
          latitude:  d.latitude || 0,
          longitude: d.longitude || 0,
          weight:    rating,
          name:      d.name || "",
          quantity:  d.heatmap?.review_count || 0,
          rating:    ratings.format(rating),
          numerical: Utils.round((d.heatmap?.overall_rating_average || 0) / 10),
          sentiment: d.heatmap?.sentiment || 0,
          response_rate: Utils.round((d.heatmap?.response_rate || 0) * 100) + "%"
        };
      });
    },
    buildAreaOptions() {
      let areas = Utils.deepClone(this.contract.areas || []).sort((a, b) => b.adm_level - a.adm_level);
      if (this.user.admin) areas = this.allAreaTypes;
      this.areaOptions = [...new Set(areas.map(a => a.type || a))];
      if (!this.areaType) this.areaType = this.areaOptions.find(a => a == "city") || this.areaOptions[0];
    },
    positionUpdater(ev) {
      this.nextPositionUpdate = Date.now() + this.moveDelay;
      setTimeout(() => this.updatePosition(ev), this.moveDelay);
    },
    updatePosition(p) {
      if (this.nextPositionUpdate > Date.now()) return;
      const center  = p.center;
      const zoom    = p.zoom;
      this.position = { lng: center?.lng, lat: center?.lat, zoom };
    },
    readURL() {
      const query = this.$route.query;
      Object.entries(this.urlParams).forEach(([k, v]) => {
        if (query[k]) this[v] = query[k];
      });
    },
    updateURL(param, value) {
      return this.$router.push({ name: this.$route.name, query: { ...this.$route.query, [param]: value } });
    },
    updateReviewsOnScreen(reviews) {
      this.reviewsOnScreen = reviews;
    },
    updateReviewsTotal(reviews) {
      this.reviewsTotal = reviews;
    },
    legendValue(i) {
      if (this.dataFormatType == "review_count") return Math.floor((this.reviewsOnScreen || this.reviewsTotal) * i / this.clusterQuadrants);
      return i;
    },
    legendGradient(i) {
      let c2 = i + 1;
      if (this.dataFormatType == "review_count") c2 = i;
      return `linear-gradient(90deg, ${this.legendColors[i]}, ${this.legendColors[c2]})`
    },
    updateLocationLoading(v) {
      this.locationLoading = v;
      if (!v) this.locationData = true;
    }
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    legendColors() {
      if (this.dataFormatType == "review_count") return Object.values(this.heatColors).slice(1);
      return Object.values(this.ratingColors);
    },
    legendColorsToShow() {
      if (this.dataFormatType == "review_count") return this.legendColors;
      return this.legendColors.slice(0, 10);
    },
    legendWidth() {
      if (this.dataFormatType == "review_count") return 100 / this.clusterQuadrants;
      return 100 / 10;
    },
    showPlaceholderMap() {
      return !this.clusterData && !this.locationData;
    }
  },
  watch: {
    "filterParams.data": {
      handler: function () {
        if (this.contract?.id && this.filterParams?.data) this.init(this.filterParams);
      },
      deep: true
    },
    dataViewType: function (_, prev) {
      if (this.contract?.id && this.filterParams?.data) {
        this.prevDataViewType = prev;
        this.init(this.filterParams);
      }
    },
    dataFormatType: function () {
      if (this.contract?.id && this.filterParams?.data) this.init(this.filterParams);
    },
  }
}
</script>

<template>
  <div class="map-container">
    <!-- Collapsable right menu -->
    <div class="w-100">
      <button :disabled="(dataViewType == 'per_location' && locationLoading) || loading" class="btn menu" data-bs-toggle="offcanvas" data-bs-target="#config-sidebar" aria-controls="config-sidebar">
        <span v-b-tooltip.hover :title="$t('general.menu')">
          <i class="mdi mdi-filter-cog" />
        </span>
      </button>

      <div class="offcanvas offcanvas-start" tabindex="-1" data-bs-backdrop="false" id="config-sidebar">
        <div class="offcanvas-header d-flex justify-content-end pb-0 pt-3">
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
        </div>

        <div class="offcanvas-body pb-0 pt-1 px-4">
          <!-- Map View -->
          <label class="form-label">{{ $t(`map.view`) }}</label><br>
          <div class="d-flex">
            <label v-for="type in dataViewOptions" :key="type" class="custom-label mx-0 mb-1">
              <input class="custom-input" v-model="dataViewType" type="radio" name="view_mode" :id="type" :value="type" @change="updateURL('v', dataViewType)" />
              <span class="custom-span">{{ $t(`map.filter.${type}`) }}</span>
            </label>
          </div>

          <div class="mt-3" v-if="dataViewType === 'per_location' && areaOptions && areaOptions.length">
            <label class="form-label">{{ $t("map.area_type") }}</label>
            <div style="min-width:200px">
              <multiselect v-model="areaType" :options="areaOptions" :showLabels="false" :customLabel="l => $t(`map.filter.${l}`)" :allow-empty="false" @select="() => updateURL('at', areaType)" @remove="updateURL('at', areaType)"></multiselect>
            </div>
          </div>

          <!-- Data Point -->
          <div class="mt-3" v-if="dataViewType != 'concentration'">
            <label class="form-label">{{ $t(`map.data_point`) }}</label>
            <label class="custom-label m-0 mb-1" v-for="df in dataFormatOptions" :key="df">
              <input class="custom-input" v-model="dataFormatType" type="radio" name="data_type" :id="df" :value="df" @input="updateURL('dp', df)"/>
              <span class="custom-span">{{ $t(`map.filter.${df}`) }}</span>
            </label>
          </div>

          <Filters class="mt-5" @filter:created="saveFilterParams" @filter:change="saveFilterParams" @filter:update="saveFilterParams" :cols="cols" :initCollapsed="false" :sidebar="true" :notifyChange="true"/>
        </div>
      </div>
    </div>

    <div style="height: calc(100vh - 70px); min-width: 750px; margin-top: 70px" :class="{ 'cursor-loading': loading }" v-if="libLoaded">
      <template v-if="showPlaceholderMap">
        <PlaceholderMap :initOpts="maplibregl.initOpts" :defaults="defaults" />
      </template>

      <template v-if="filterParams && filterParams.data">
        <template v-if="dataViewType === 'per_location' || (prevDataViewType == 'per_location' && !clusterData && locationData)">
          <PerLocationMap v-show="locationData" :initOpts="maplibregl.initOpts" :params="params" :ratingColors="ratingColors" :dataFormatType="dataFormatType" :position="position" @updatePosition="positionUpdater" :areaType="areaType" :heatColors="heatColors" :reviewsTotal="reviewsTotal" :clusterQuadrants="clusterQuadrants" @updateReviewsOnScreen="updateReviewsOnScreen" @updateReviewsTotal="updateReviewsTotal" :defaults="defaults" @locationLoading="updateLocationLoading" />
        </template>
        <ConcentrationMap v-if="dataViewType == 'concentration' || (prevDataViewType == 'concentration' && dataViewType === 'per_location' && clusterData && !locationData)" :initOpts="maplibregl.initOpts" :data="data" :position="position" @updatePosition="positionUpdater" :heatColors="heatColors" :defaults="defaults" />
        <ClusterMap v-if="(dataViewType === 'cluster' && clusterData) || (prevDataViewType == 'cluster' && dataViewType === 'per_location' && clusterData && !locationData)" :initOpts="maplibregl.initOpts" :data="data" :ratingColors="ratingColors" :position="position" @updatePosition="positionUpdater" :dataFormatType="dataFormatType" :heatColors="heatColors" :reviewsTotal="reviewsTotal" :clusterQuadrants="clusterQuadrants" @updateReviewsOnScreen="updateReviewsOnScreen" :defaults="defaults" />
      </template>
    </div>

    <div class="progress-container">
      <div class="position-relative">
        <div class="progress" v-if="(!loading && dataViewType == 'cluster') || (dataViewType == 'per_location' && !locationLoading)">
          <div v-for="(color, i) in legendColorsToShow" :key="color"
            class="progress-bar"
            :style="{
              background: legendGradient(i),
              width:     `${legendWidth}%`
            }"
            role="progressbar" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"
          >
            <span class="marker number" :style="i != 0 && 'transform:translateX(-50%);'">
              {{ legendValue(i) }}
            </span>
            <span v-if="i != 0" class="marker">|</span>
            <span
              v-if="i == legendColorsToShow.length - 1"
              class="marker number last-number"
              :style="`transform:translateX(${dataFormatType != 'review_count' ? 0 : '-50%'});`"
            >
              {{ legendValue(i + 1) }}
            </span>
          </div>
        </div>
      </div>

      <div v-if="(dataViewType == 'per_location' && locationLoading) || loading" class="position-absolute" style="right:0;" :style="(!loading && dataViewType == 'cluster') || (dataViewType == 'per_location' && !locationLoading) && 'top:35px;'">
        <span class="spinner-border text-primary" aria-hidden="true" style="width: 2.5rem; height: 2.5rem; --bs-spinner-border-width: 0.4em;"></span>
      </div>
    </div>
  </div>
</template>
