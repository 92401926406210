<script>
import axios     from 'axios'
import Utils     from "@/js/utils";
import dayjs     from "dayjs";
import NotLoaded from "@/components/not-loaded";
import Loading   from "@/components/loading";

import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

export default {
  data() {
    return {
      loading: true,
      error: false,
      dates: [],
      page: 'reports',
      colors: ["#8b8", "#4090cb", '#744', '#ead'],
      reviews: { numerical: [], sentiment: [], tes: [] },
      ratings: { numerical: [], sentiment: [], tes: [] },
      analyticsData: { numerical: [], sentiment: [], tes: [], count: [] },
      // needed to calculateTES using review_counts, not opinions_count
      sentiment_counts: [],
      dataPoints: [],
      cols:       [],
      availableCols: {
        numerical_rating: {
          'slug': 'numerical_rating',
          'data': 'ratings.numerical',
        },
        sentiment_rating: {
          'slug': 'sentiment_rating',
          'data': 'ratings.sentiment',
        },
        tes_rating: {
          'slug': 'tes_score',
          'data': 'ratings.tes',
        },
        reviews_count: {
          'slug': 'reviews_count',
          'data': 'reviews.numerical',
        },
        mentions_count: {
          'slug': 'mentions_count',
          'data': 'reviews.sentiment',
        },
        responses_count: {
          'slug': 'responses_count',
          'data': 'analyticsData.count',
        },
        survey_responses_count: {
          'slug': 'survey_responses_count',
          'data': 'analyticsData.count',
        },
        survey_numerical_rating: {
          'slug': 'survey_numerical_rating',
          'data': 'analyticsData.numerical',
        },
        survey_sentiment_rating: {
          'slug': 'survey_sentiment_rating',
          'data': 'analyticsData.sentiment',
        },
        survey_tes_rating: {
          'slug': 'survey_tes_score',
          'data': 'analyticsData.tes',
        }
      },
      surveyCountPer: {
        day:     "day",
        week:    "day",
        month:   "day",
        quarter: "month",
        year:    "month"
      },
      dateFormatTemplates: {
        day:     "DD",
        week:    "DD MMM YY",
        month:   "MMM YYYY",
        quarter: "[Q]Q YYYY",
        year:    "YYYY"
      }
    }
  },
  props: ['settings', 'start_date', 'end_date','company_id', "group_id"],
  components: { NotLoaded, Loading },
  async mounted () {
    this.dataPoints  = this.settings.chart_data.filter(d => d.data_type && d.type);
    this.cols        = this.dataPoints.map(c => c.data_type)
    const end_date   = dayjs(this.end_date).endOf(this.settings.period).format("YYYY-MM-DD")
    const start_date = dayjs(end_date).add(-1 * ((this.settings.n_periods || 6)-1), this.settings.period).startOf(this.settings.period).format("YYYY-MM-DD")
    let p = { per: this.settings.period, start_date, end_date, group_ids: this.group_id, company_ids: this.company_id, ...this.settings.filters }
    await this.load(p)
    this.loading = false
  },
  computed: {
    series () {
      let res = []
      this.dataPoints.forEach((column) => {
        let temp   = {}
        let config = this.availableCols[column.data_type]
        if (!config) return

        temp['name'] = this.translate(config.slug, column.label)
        temp['data'] = config.data.split('.').reduce((p, prop) => { return p[prop] }, this.$data)
        temp['data'] = temp['data'].map(d => d || null)
        temp['type'] = column.type

        let hasData = temp['data'].reduce((res, curr) => (!!res || curr || curr == 0), false);
        if (hasData) res.push(temp);
      })
      return res;
    },
    chartOptions() {
      let opts = {
        chart: { toolbar: { show: false } },
        dataLabels: { enabled: false },
        plotOptions: { bar: { columnWidth: '50%' } },
        stroke: { curve: "smooth", width: [2, 2, 2, 2] },
        colors: this.colors,
        labels: [],
        markers: { size: 5, strokeWidth: 0 },
        xaxis: {
          type: "category",
          categories: this.dates.map(this.dateFormatter)
        },
        fill: {
            opacity: [1, 1],
            gradient: {
                opacityFrom: 1,
                opacityTo: 1,
                stops: [20, 1000]
            }
        },
        tooltip: { y: { formatter: value => value } },
        legend: { position: "top", horizontalAlign: "right" },
        yaxis: [
          {
            seriesName: this.translate(this.cols[0], this.dataPoints[0].label),
            axisTicks: { show: false, },
            axisBorder: { show: true, color: this.colors[0] },
            decimalsInFloat: false,
            labels: { style: { colors: this.colors[0] } },
            title: {
              text: this.translate(this.cols[0], this.dataPoints[0].label),
              style: { color: this.colors[0] }
            },
            tooltip: { enabled: true },
            ...(/rating/.test(this.cols[0]) && {
              min: 0,
              max: 10.1,
              tickAmount: 9,
              forceNiceScale: false
            })
          }
        ],
      }
      for (let i = 1; i < this.cols.length; i++) {
        opts.yaxis.push(
          {
            floating: i > 1,
            seriesName: this.translate(this.cols[i], this.dataPoints[i].label),
            opposite: true,
            decimalsInFloat: true,
            axisTicks: { show: true, },
            axisBorder: { show: true, color: this.colors[i] },
            labels: { style: { colors: this.colors[i] } },
            title: { text: this.translate(this.cols[i], this.dataPoints[i].label), style: { color: this.colors[i] } },
            ...(/rating/.test(this.cols[i]) && {
              min: 0,
              max: 10.1,
              tickAmount: 9,
              forceNiceScale: false
            })
          }
        )
      }
      return opts
    },
    showChart() {
      return this.series.length && this.series.reduce((res, curr) => res + curr.data.filter(n => n).length, 0);
    }
  },
  methods: {
    async load (params) {
      let promises = []
      this.error = false

      if (this.cols.includes('survey_responses_count') || this.cols.includes('survey_numerical_rating') || this.cols.includes('survey_sentiment_rating') || this.cols.includes('survey_tes_rating')) {
        promises.push(axios.get('/v3/feedback/analytics/per_period', { params: {
          ...params,
          start_date:       this.start_date,
          end_date:         this.end_date,
          finished:         true,
          per:              this.surveyCountPer[params.per]
          // survey_id:     params.survey_id,
          // has_sentiment: params.has_sentiment,
        }}).then(response => {
          const data = response.data.data;
          this.analyticsData.count     = data.map(e => e.responses_count || 0);
          this.analyticsData.numerical = data.map(e => this.toBase10(e.numerical.value, 10));
          this.analyticsData.sentiment = data.map(e => this.toBase10(e.sentiment.value, 10));
          this.analyticsData.tes       = data.map(e => this.toBase10(e.tes.value, 10));
          this.dates                   = data.map(d => d.date);
        }).catch(() => this.error = true));
      }

      if (this.cols.includes('numerical_rating') || this.cols.includes('reviews_count') || this.cols.includes('tes_rating')) {
        if (this.page == "destination_dashboard") {
          promises.push(axios.get("/v3/destination/stats", { params }).then(response => {
            let data               = response.data.data
            this.dates             = data.map(e => e.date)
            this.reviews.numerical = data.map(e => e.stats.review_count)
            this.ratings.numerical = data.map(e => this.toBase10(e.stats.overall_rating_average, 10))
            if (this.ratings.numerical.length > 0 && this.ratings.sentiment.length > 0) this.generateTES()
          }).catch(() => this.error = true))
        } else {
          promises.push(axios.get("/v3/ratings/stats", { params }).then(response => {
            let data               = response.data.data
            this.dates             = data.map(e => e.date)
            data.map(e => e.rating = e.ratings.find(o => o.topic == 'overall'))
            this.reviews.numerical = data.map(e => e.rating?.review_count)
            this.ratings.numerical = data.map(e => this.toBase10(e.rating?.value, 10))
          }).catch(() => this.error = true))
        }
      }

      if (this.cols.includes('sentiment_rating') || this.cols.includes('mentions_count') || this.cols.includes('tes_rating') ) {
        if (this.page == "destination_dashboard") {
          promises.push(axios.get("/v3/destination/sentiment", { params }).then(response => {
            let data               = response.data.data
            data.map(e => e.sentiment = e.sentiment.find(o => o.topic == 'overall'))
            this.dates             = data.map(e => e.date)
            this.sentiment_counts  = data.map(e => e.sentiment.review_count)
            this.reviews.sentiment = data.map(e => e.sentiment.opinions_count)
            this.ratings.sentiment = data.map(e => this.toBase10(e.sentiment.sentiment_score))
            if (this.ratings.numerical.length > 0 && this.ratings.sentiment.length > 0) this.generateTES()
          }).catch(() => this.error = true))
        }
        else {
          promises.push(axios.get("/v3/sentiment/stats", { params }).then(response => {
            let data               = response.data.data
            data.map(e => e.sentiment = e.opinions.find(o => o.topic == 'overall'))
            this.dates             = data.map(e => e.date)
            // this.sentiment_counts  = data.map(e => e.sentiment.review_count)
            this.reviews.sentiment = data.map(e => e.sentiment?.review_count)
            this.ratings.sentiment = data.map(e => this.toBase10(e.sentiment?.sentiment_score))
          }).catch(() => this.error = true))
        }
      }

      await Promise.all(promises)
      if (this.ratings.numerical.length > 0 && this.ratings.sentiment.length > 0)
        this.generateTES()

      Utils.charts.loadApexGrids();
    },
    generateTES() {
      this.ratings.tes = []
      for(var i = 0; i < this.ratings.numerical.length; i++) {
        if (this.ratings.sentiment[i] != undefined)
          this.ratings.tes[i] = Utils.calcTES(this.ratings.numerical[i], this.reviews.numerical[i], this.ratings.sentiment[i], this.reviews.sentiment[i])
      }
    },
    toBase10(number, divisor = 1) {
      if (isNaN(number)) return 0;
      return Number.parseFloat(Utils.round((number / divisor)))
    },
    dateFormatter(timestamp) {
      if (this.cols.includes("survey_responses_count") && this.settings.period == "month") return dayjs(timestamp).format("DD");
      return dayjs(timestamp).format(this.dateFormatTemplates[this.settings.period]);
    },
    translate(slug, defaultLabel) {
      if (defaultLabel && defaultLabel.trim().length > 0) return defaultLabel
      return this.$t(`overall_chart.${slug}`)
    }
  }
};
</script>

<template>
  <div>
    <div style="overflow-x: auto; overflow-y: hidden;" v-if="showChart">
      <div v-if="!settings.show_title" class="d-flex align-items-center mb-4">
        <h4 class="card-title float-start m-0">{{ $t(`overall_chart.title_reports`, { names: cols.map(c => $t('overall_chart.'+c)).join(', '), period: $t('reports.periods.'+this.settings.period) }) }}</h4>
      </div>

      <apexchart
        class="apex-charts"
        dir="ltr"
        :height="this.settings.height || 200"
        :series="series"
        :options="chartOptions"
        style="min-width: 400px;"
      ></apexchart>
    </div>

    <NotLoaded :data="series.flatMap(s => s.data)" :error="error" v-else-if="!loading" />
    <Loading v-else-if="loading" />
  </div>
</template>
