<script>
import Editor from '@tinymce/tinymce-vue';

export default {
  components: {
    'tinymce-editor': Editor
  },
  props: ['settings', 'template'],
  data() {
    return {
      computedTemplate: this.settings.html,
      tinymceScriptLoaded: false,
      editingVariable: null,
      basedOnMap: { groups: 'group', companies: 'company' },
      editor: {
        content_css: false,
        promotion: false,
        branding: false,
        codeeditor_wrap_mode: true,
        codeeditor_font_size: 14,
        font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt 64pt 72pt',
        plugins: "advlist anchor codesample help image lists link media searchreplace table template visualblocks emoticons codeeditor",
        toolbar1: "codeeditor insertVariable | undo redo | fontfamily fontsize lineheight blocks | bold italic underline strikethrough subscript superscript | backcolor forecolor removeformat | alignleft aligncenter alignright alignjustify | bullist numlist searchreplace | link anchor image table codesample emoticons",
        menubar: "file edit view insert format tools table help",
        content_style: '.bold { font-weight: 600; } ',
        formats: {
          bold: { inline: 'span', classes: 'bold', styles: { fontWeight: '600' } },
        },
        setup: (editor) => {
          editor.ui.registry.addToggleButton("insertVariable", {
            icon: "plus",
            text: this.$t('reports.settings_html.insert_variable'),
            onAction: () => {
              editor.windowManager.open({
                title: this.$t('reports.settings_html.insert_variable'),
                body: {
                  type: 'panel',
                  name: 'variable_selector',
                  items: [{
                    type: 'listbox',
                    name: 'variables_selector',
                    label: this.$t('reports.settings_html.modal_label'),
                    enabled: true,
                    items: this.variables,
                  }]
                },
                buttons: [{
                  type: 'cancel',
                  name: 'cancel',
                  text: this.$t('reports.settings_html.cancel')
                },
                {
                  type: 'submit',
                  name: 'add',
                  text: this.$t('reports.settings_html.add_variable'),
                  primary: true
                }],
                initialData: {
                  variables_selector: this.variables ? this.variables[0].value : null
                },
                onSubmit: (api) => {
                  const data = api.getData();
                  const variable = data.variables_selector;
                  editor.insertContent(`{{${variable}}}`);
                  api.close();
                }
              })
            },
            onSetup: function (api) {
              const nodeChangeHandler = (ev) => {
                if (ev.isVariableClick) this.editingVariable = ev.id;
                else this.editingVariable = null;
                return api.setActive(ev.isVariableClick);
              }
              editor.on("variableClick", nodeChangeHandler);
            }.bind(this)
          });
        },
      }
    };
  },
  computed: {
    variables() {
      const nameKey = `${this.basedOnMap[this.template.based_on]}_name`
      const idKey   = `${this.basedOnMap[this.template.based_on]}_id`
      return [nameKey, idKey, 'report_period', 'generation_date'].map(k => {
       return { text: this.$t(`reports.settings_html.variables.${k}`), value: k }
      })
    }
  },
  async created() {
    this.loadTinyMCESCript();
    setTimeout(() => {
      this.tinymceScriptLoaded = true;
    }, 1000);
  },
  methods: {
    async loadTinyMCESCript() {
      let url = "https://dashboard-assets.olery.com/js/tinymce/tinymce.min.js"
      if (process.env.NODE_ENV == "development") url = "/js/tinymce/tinymce.min.js"
      let script = document.createElement('script')
      if (document.querySelector(`script[src="${url}"]`)) return;

      script.src = url
      script.type = 'text/javascript'
      script.async = false;
      document.head.appendChild(script)
    },
  }
};
</script>
<template>
  <div>
    <tinymce-editor v-if="tinymceScriptLoaded" v-model="settings.html" :init="editor" />
  </div>
</template>
