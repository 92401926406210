<script>
import axios from 'axios'
import NotLoaded from "@/components/not-loaded";
import Loading   from "@/components/loading";

export default {
  data() {
    return {
      loading:   true,
      error:     false,
      tableData: [],
      property_types: []
    }
  },
  props: ['group_id', 'group_ids', 'start_date', 'end_date', 'settings'],
  components: { NotLoaded, Loading },
  methods: {
    async getReport() {
      this.loading = true
      this.error = false
      axios.get("/v3/query/db/_", { params: this.params }).then(response => {
        response.data.data.shift()
        this.tableData = window.reports.pivot((response.data.data),
          {
            rowLabel: 'Destination',
            rowAttr: 2,
            colAttr: 3,
            valAttr: 4,
          })
        this.property_types = [ ...new Set(response.data.data.map(d => d[3]))]
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.error = true
      })
    },
    percentFormat(v) {
      if (!v) v = 0;
      return (v * 100).toFixed(2) + '%';
    },
  },
  computed: {
    params () {
      let p = {
        identifier: "property_type_distribution",
        start_date: this.start_date,
        end_date:   this.end_date,
        group_id:   this.group_id
      }
      p['group_ids'] = this.settings.use_custom_benchmark ? this.settings.bench_group_ids : this.group_ids
      return p
    }
  },
  mounted() {
    this.getReport()
  }
}
</script>
<template>
  <div>
    <table class="table table-sm" v-if="tableData && tableData.length">
      <thead>
        <tr class='text-center'>
          <th class='text-left'>{{ $t('reports.table.destination') }}</th>
          <th v-for='pt in property_types' :key='pt'>{{ $t('reports.table.'+pt) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class='text-center' v-for="row in tableData.slice(1)" :key="row[0]">
          <td scope="row" class='text-left'>{{ row[0] }}</td>
          <td v-for='(pt,i) in property_types' :key='pt'>{{ percentFormat(row[i+1]) }}</td>
        </tr>
      </tbody>
    </table>

    <Loading v-else-if="loading" />
    <NotLoaded :data="tableData" :error="error" v-else-if="!loading" />
  </div>
</template>
