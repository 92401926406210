<script>
export default {
  props: ["question", "settings", "editing", "question_index"],
  data() {
    return { answer: null, fontSize: 20 }
  },
  watch: {
    answer() {
      this.question.answer = this.answer
      this.$emit('answerChanged', this.question.answer)
    },
    max_value() {
      this.calcFontSize();
    }
  },
  created() {
    if (!this.question.config.edge_labels || Object.keys(this.question.config?.edge_labels)?.length == 0) this.question.config.edge_labels = { worst: {}, best: {} }
  },
  mounted() {
    this.calcFontSize();
    window.addEventListener("resize", this.calcFontSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcFontSize);
  },
  methods: {
    updateRatingLabel(labelType) {
      let updatedRating = this.question.config.edge_labels?.[labelType] || {}

      updatedRating[this.settings.currentLanguage] = this.ratingLabel[labelType]

      if (this.settings.currentLanguage == this.settings.defaultLanguage)
        updatedRating['default'] = this.ratingLabel[labelType]

      this.question.config.edge_labels[labelType] = updatedRating

      this.$emit('changeInput')
    },
    isFull(index) {
      if (this.answer || this.answer == 0) return index <= this.answer;
      return false;
    },
    iconClass(index) {
      if (this.isFull(index)) return `bi bi-${this.icon}-fill`;
      return `bi bi-${this.icon}`;
    },
    calcFontSize() {
      let element = document.querySelector(`#question_modal_${this.question.id}`);
      if (element) {
        let computedStyle = getComputedStyle(element);
        let elementWidth = element.clientWidth;
        if (!element.parentElement.classList.contains("active")) {
          element.parentElement.classList.add("active");
          elementWidth = element.clientWidth;
          element.parentElement.classList.remove("active");
        }
        elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
        let res = (elementWidth - 5*this.max_value) / this.max_value;
        if (res <= 50 && res > 0) this.fontSize = res;
        else this.fontSize = 50;
      }
    }
  },
  computed: {
    max_value() {
      let parsedMaxValue = parseInt(this.question.max_value);
      if (!parsedMaxValue)     return 5;
      if (parsedMaxValue < 3)  return 3;
      if (parsedMaxValue > 15) return 15;
      return parsedMaxValue || 5;
    },
    ratingLabel() {
      return {
        worst: this.question.config.edge_labels?.worst?.[this.settings.currentLanguage],
        best:  this.question.config.edge_labels?.best?.[this.settings.currentLanguage]
      }
    },
    ratingLabelWidth() {
      return {
        worst: this.question.config?.edge_labels?.worst?.[this.settings.currentLanguage]?.length > 3 ? this.question.config?.edge_labels?.worst?.[this.settings.currentLanguage]?.length : 5,
        best: this.question.config?.edge_labels?.worst?.[this.settings.currentLanguage]?.length > 3 ? this.question.config?.edge_labels?.worst?.[this.settings.currentLanguage]?.length : 5
      }
    },
    icon() {
      return this.question.config?.rating_icon || "star";
    },
    isNPS() {
      return this.question.type == "nps";
    }
  }
};
</script>

<template>
  <div class="form-group rating-root w-100">
    <div class="rating mt-4 d-flex flex-column align-items-center">
      <div v-if="!isNPS" class="w-100 d-flex justify-content-between mb-2">
        <div v-for="index in max_value" :key="index" class="rating-icon" :class="{ 'pointer-events-none': editing }" @click="answer = index">
          <i :class="iconClass(index)" :style="`font-size: ${fontSize}px;`"></i>
        </div>
      </div>

      <div v-else class="w-100 d-flex justify-content-between mb-3">
        <div v-for="index in 11" :key="index" class="nps-box" :class="{ 'rating-full': isFull(index - 1), 'pointer-events-none': editing }" @click="answer = index - 1">
          {{ index - 1 }}
        </div>
      </div>

      <p class="m-0 d-flex" v-if="question.config.custom_labels">
        {{ answer ? question.config.custom_labels[answer-1].default : null }}
      </p>

      <div class="edit-labels d-flex justify-content-between w-100 p-0" v-if="!(answer && question.config.custom_labels) && !editing && !isNPS">
        <p class="m-0"> {{ ratingLabel.worst ? ratingLabel.worst : $t("feedback-form.rating.worst")}} </p>
        <p class="m-0"> {{ ratingLabel.best  ? ratingLabel.best  : $t("feedback-form.rating.best") }} </p>
      </div>

      <div class="edit-labels d-flex justify-content-between w-100 p-0" v-else-if="isNPS">
        <p class="m-0"> {{ $t("feedback-form.rating.nps_worst")}} </p>
        <p class="m-0"> {{ $t("feedback-form.rating.nps_best") }} </p>
      </div>

      <div class="d-flex justify-content-between align-items-center w-100" v-else>
        <input :ref="question_index + '_edge_label_worst_' + question.id" class="custom-textarea font-size-18 m-0 text-left" :class="{ 'missing-translation': $parent.hasMissingTranslations(question_index, 'edge_labels', 'worst') }" @input="updateRatingLabel('worst')" :title="$t('new_survey.questions.edit.change_rating_label')" v-model="ratingLabel.worst" :style="`width: ${ratingLabelWidth.worst}ch;`" :placeholder="$t('new_survey.questions.edit.worst_label_placeholder')" />
        <input :ref="question_index + '_edge_label_best_' + question.id" class="custom-textarea font-size-18 m-0 text-right" :class="{ 'missing-translation': $parent.hasMissingTranslations(question_index, 'edge_labels', 'best') }" @input="updateRatingLabel('best')" :title="$t('new_survey.questions.edit.change_rating_label')" v-model="ratingLabel.best" :style="`width: ${ratingLabelWidth.best}ch;`" :placeholder="$t('new_survey.questions.edit.best_label_placeholder')" />
      </div>
    </div>
  </div>
</template>
