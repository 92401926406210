<script>
import GeneralExport from '@/components/reports/general-export'

export default {
  data() {
    return {
      imageLoaded: true,
      companyName: null,
      period:      null,
      parent:      []
    };
  },
  components: {
    GeneralExport,
  },
  props: ["title", "data"],

  mounted() {
    this.companyName = this.data.company.alias || this.data.company.name
    this.period      = this.data.yearEnd ? this.data.year : `${this.data.month} - ${this.data.year}`
    this.filename    = `${this.companyName} - ${this.period} - ${this.title}`
    document.title   = this.filename
    this.parent      = document.querySelector("#header")?.parentElement
  },

  methods: {
    imageError() {
      this.imageLoaded = false;
    },
  }
}
</script>

<template>
  <div class="page header" id="header">
    <div id="header-title">
      <div id="header-report">{{ title }}</div>
      <div id="header-company">{{ companyName }}</div>
      <div id="header-month" :class="{ hide: !data.showDate }"> {{ period }} </div>

      <GeneralExport :report=parent />
    </div>

    <div id="header-logo">
      <img src="@/assets/images/reports/replab/headerlogo.png" style="width: 130px;"/>
    </div>

    <div v-if="imageLoaded" class="company-photo" :style="`background: url(https://thereputationlab.s3.eu-west-1.amazonaws.com/${data.company.id}.jpg)`" @error="imageError"></div>

    <img >
  </div>
</template>
