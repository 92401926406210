<script>
import PageHeader        from "@/components/page-header";
import Filters           from "@/components/filter";
import Kpis              from "@/components/kpis";
import RatingsComparison from "./ratings-comparison/ratings-comparison";
import SegmentCharts     from "./segment-charts/segment-charts";

import OverallChart      from "@/components/overall-chart";
import AnswerAnalysis    from "@/components/feedback/answer-analysis";
import SubRatings        from "@/components/feedback/subratings";
import ResponseRateKPIs  from "@/components/feedback/response-rate-kpi";
import TableSurveyScores from "@/components/reports/table-survey-scores";

import Multiselect       from "vue-multiselect";
import DataUtils         from "@/js/data-utils";
import Utils             from "@/js/utils";

export default {
  data() {
    return {
			reputationCols:    ["group_companies", "companies", "daterange", "sources", "reviewer_country", "language", "travel_composition"],
      surveyCols:        ["daterange", "survey_filter", "companies", "finished", "response_language", "question_type", "has_sentiment"],
      extraCols:         ["rating_type"],
      responseChartCols: ["response_count", "analytics_numerical_rating", "analytics_sentiment_rating", "analytics_tes_score"],
      responseKpiCols:   ["numerical_rating", "sentiment_rating", "tes_score", "response_count"],
      responseRateCols:  ["total", "open_rate", "click_rate", "completed_rate"],
      ratingTypes:       ["numerical", "sentiment", "tes"],
      sourceTypes:       ["response", "review"],
      ratingType:        this.$route.query["rt"] || this.$route.query["data.rating_type"] || "numerical",
      sourceType:        this.$route.query["st"] || this.$route.query["data.source_type"] || "review",
      filterParams:      {},
      companyNames:      {},
      typeCache:         {},
      kpiHash:           { numerical: "rating" },
      colors:            { own: "#038d82", competitors: "#ac8309" },
      viewTypeOptions:   ["overview", "detailed"],
      viewType:          "overview"
    };
  },
  components: { PageHeader, Filters, Multiselect, Kpis, SegmentCharts, RatingsComparison, OverallChart, AnswerAnalysis, SubRatings, ResponseRateKPIs, TableSurveyScores },
  watch: {
    hasFeedbackAccess: {
      handler: function () {
        if (this.contract && !this.hasFeedbackAccess) this.sourceType = "review";
      },
      immediate: true
    }
  },
  methods: {
    ...DataUtils,
    onTypeChange(type, value) {
      const queryParam = type == "rating" ? "rt": "st";
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, [queryParam]: value } });
      this.updateParams(this.filterParams, type, value);
    },
    saveCompanyNames(hash) {
      this.companyNames = hash;
    },
    updateParams(params, type, value) {
      if (!type && !value) return this.initParams(params);
      const typeParam = `${type}_type`;
      if (
        !this.typeCache[value]
        || !Utils.compareObjects({ ...params, [typeParam]: null }, { ...this.typeCache[value], [typeParam]: null })
      ) {
        this.typeCache[value] = Utils.deepClone(params);
        this.saveFilterParams({ ...params, [typeParam]: value });
      }
    },
    initParams(params) {
      [{ type: "rating", value: this.ratingType }, { type: "source", value: this.sourceType }].forEach(t => this.updateParams(params, t.type, t.value));
    }
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    computedParams() {
      return {
        ...this.filterParams,
        data: {
          ...this.filterParams.data,
          finished: this.filterParams.data?.finished != "all" ? this.filterParams.data?.finished == "completed" : undefined
        }
      };
    },
    hasFeedbackAccess() {
      return this.contract?.products?.includes("feedback");
    },
    showCompetitors() {
      return this.sourceType == "review";
    },
    countSlug() {
      if (this.ratingType == "sentiment") return "number_mentions";
      return "number_reviews";
    },
    kpiCols() {
      if (this.sourceType == "response") return this.responseKpiCols;
      return [this.kpiHash[this.ratingType] || this.ratingType, "review_count"];
    },
    filterCols() {
      if (this.sourceType == "response") return this.surveyCols;
      return this.reputationCols;
    },
    tableSurveyScoresParams() {
      return {
        ...Utils.slice(this.computedParams.data, ["start_date", "end_date", "company_ids", "group_ids"]),
        company_id: this.computedParams.data?.company_ids,
        settings: {
          dashboard: true,
          show_questions_without_answers: true,
          segment: `survey_${this.ratingType}`,
          surveys: [this.computedParams.data.survey_id].flat(),
          ...Utils.slice(this.computedParams.data, ["finished", "response_language", "has_sentiment"]),
        }
      }
    }
  }
}
</script>

<template>
  <div class="w-100">
    <div class="card mt-3">
      <div class="card-body">
        <!-- Source Type -->
        <div class="row mb-1" v-if="hasFeedbackAccess">
          <label class="col-sm-2 mt-2 form-label">{{ $t("ratings.source_type") }}</label>
          <div class="col-sm-4">
            <multiselect v-model="sourceType" :options="sourceTypes" :custom-label="r => $t(`filter.${r}`)" :allow-empty="false" @select="onTypeChange('source', $event)" @remove="onTypeChange('source', $event)"></multiselect>
          </div>
        </div>

        <!-- Rating Type -->
        <div class="row">
          <label class="col-sm-2 mt-2 form-label">{{ $t("ratings.rating_type") }}</label>
          <div class="col-sm-4">
            <multiselect v-model="ratingType" :options="ratingTypes" :custom-label="r => $t(`ratings.${r}`)" :allow-empty="false" @select="onTypeChange('rating', $event)" @remove="onTypeChange('rating', $event)"></multiselect>
          </div>
        </div>
      </div>
    </div>

    <PageHeader :title="$t('daily_operations.analytics.title')" />

    <Filters @filter:created="updateParams" @filter:update="updateParams" @companyNames="saveCompanyNames" :cols="filterCols" :showCompetitors="showCompetitors" :preventSubmit="true" :extraCols="extraCols" />

    <div v-if="!filterParams.data" class="card bg-light select-group-company">{{ $t("general.select_group_or_company") }}</div>

    <template v-else>
      <template v-if="sourceType == 'review'">
        <!-- Review tab -->
        <RatingsComparison :filterParams="computedParams" :companyNames="companyNames" :competitorsColors="colors" :ratingType="ratingType" />

        <Kpis :filterParams="computedParams" :cols="kpiCols" :countslug="countSlug" dashboard_type="reputation" />

        <SegmentCharts :filterParams="computedParams" :companyNames="companyNames" :colors="colors" :ratingType="ratingType" />
      </template>

      <template v-if="sourceType == 'response'">
        <!-- Survey tab -->
        <Kpis :filterParams="computedParams" :cols="kpiCols" dashboard_type="feedback" />

        <ResponseRateKPIs :filterParams="computedParams" :cols="responseRateCols" />

        <OverallChart :filterParams="computedParams" :cols="responseChartCols" page="feedback_analytics"/>

        <PageHeader :title="$t('feedback_analytics.answer_analysis')">
          <template v-slot:subtitle>
            <div class="showPie d-flex align-items-center col-12 col-md-5 col-xl-4">
              <label class="form-label mb-1 text-nowrap">{{ $t("general.view_type") }}</label>
              <multiselect class="ms-2" :multiple="false" v-model="viewType" :options="viewTypeOptions" :showLabels="false" :custom-label="l => $t(`general.${l}`)" :allow-empty="false" style="min-width: 200px !important;"></multiselect>
            </div>
          </template>
        </PageHeader>

        <div v-if="viewType == 'overview' && filterParams.data" class="card">
          <div class="card-body">
            <TableSurveyScores class="thead-light" v-bind="tableSurveyScoresParams" />
          </div>
        </div>
        <AnswerAnalysis v-else :filterParams="computedParams" :rt="ratingType" />

        <SubRatings :filterParams="computedParams" />
      </template>
    </template>
  </div>
</template>