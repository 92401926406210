export default {
  "Business": "business",
  "Couples": "couples",
  "Families": "families",
  "Friends": "friends",
  "Group": "group",
  "Other": "other",
  "Seniors": "seniors",
  "Solo": "solo",
  "Young Adults": "young_adults"
};
