<script>
import UserManagement from "@/components/user-management";

export default {
  data() {
    return {
      filterCols:       ["companies", "user_type", "search"],
      tableCols:        ["full_name", "type", "email", "last_seen", "assignments_str", "actions"],
      ignoreProperties: false
    };
  },
  components: { UserManagement }
}
</script>

<template>
  <UserManagement v-bind="$data" />
</template>