<template v-slot:default>
  <div>
    <h3>{{ $t('signup.signup_to_paid.company_name') }}</h3>
    <b-form-group>
      <b-form-input
        ref="inputRef"
        v-model="localFormData.company_name" 
        placeholder="Company Name" 
        @input="emitFormData"
        @keydown.enter="nextStep()"
      ></b-form-input>
      <span class="form-label">
        {{ $t('signup.signup_to_paid.company_country') }}
      </span>
      <multiselect
        v-model="localFormData.company_country"
        :options="worldCountries"
        placeholder="Country"
        @input="emitFormData"
      ></multiselect>
      <div v-if="europeanCountries.includes(localFormData.company_country)">
        <span class="form-label">
          {{ $t('signup.signup_to_paid.vat_number') }}
        </span>
        <b-form-input
          v-model="localFormData.vat_number"
          placeholder="VAT Number"
          @input="emitFormData"
          @keydown.enter="nextStep()"
        ></b-form-input>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { europeanCountries, worldCountries } from '@/js/constants';
import Multiselect from 'vue-multiselect';
export default {
  props: ['formData', 'nextStep'],
  components: {
    Multiselect,
  },
  data() {
    return {
      localFormData: { ...this.formData },
      europeanCountries,
      worldCountries,
    };
  },
  watch: {
    formData: {
      handler(newFormData) {
        this.localFormData = { ...newFormData };
        if (!europeanCountries.includes(this.localFormData.company_country)) {
          this.localFormData.vat_number = '';
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.inputRef.focus();
    });
  },
  methods: {
    emitFormData() {
      this.$emit('update:formData', this.localFormData);
    },
  },
};
</script>
<style scoped>
.form-label {
  font-size: 1rem;
  padding: 10px 0;
  margin: 10px 0;
}

</style>