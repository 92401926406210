<script>
import PopUp from "./popup";
import Utils from "@/js/utils";

export default {
  data() {
    return {
      loading: true,
      map: null,
      popupData: {},
      zoom: 5,
      zoomDelay: 300,
      nextZoomUpdate: null
    };
  },
  props: ["data", "initOpts", "params", "dataFormatType", "position", "areaType", "ratingColors", "heatColors", "reviewsTotal", "clusterQuadrants", "defaults"],
  components: { PopUp },
  watch: {
    areaType: function () {
      this.loadPoints();
    },
    dataFormatType: function () {
      this.updateLayer();
    },
    "params.subscription_ids": function () {
      this.loadPoints();
    },
    "$i18n.locale": {
      handler: function () {
        this.setMapLanguage(this.map);
      },
      immediate: true
    }
  },
  async mounted() {
    await this.loadPoints();
  },
  beforeUnmount() {
    this.removeMap();
  },
  methods: {
    ...Utils.map,
    async loadPoints() {
      this.loading = true;
      this.$emit("locationLoading", true);
      const areas_info = {};

      const response_area = await this.$store.dispatch("heatmap/fetch", { ...this.params, segment: "area_ids" });
      const area_ids = response_area.map(ra => {
        areas_info[`${ra.area_ids}`] = ra.heatmap;
        return ra.area_ids;
      })

      const polygon_points = await this.$store.dispatch("heatmap/area_ids", { ...this.params, area_ids, type: this.areaType });

      this.loading = false;
      await this.$nextTick();
      this.removeMap();

      if (!document.querySelector("#per-location-map")) return;

      let reviewsTotal = 0;
      const points = polygon_points.map(point => {
        reviewsTotal += areas_info[`${point.id}`]?.review_count || 0;
        return {
          id:       point.id,
          type:     "Feature",
          geometry: point.shape,
          properties: {
            id:    point.id,
            name:  point.names[this.$i18n.locale] || point.names["en"] || point.name,
            overall_rating_average: (areas_info[`${point.id}`]?.overall_rating_average || 0) / 10,
            numerical: (areas_info[`${point.id}`]?.overall_rating_average || 0) / 10,
            sentiment: areas_info[`${point.id}`]?.sentiment || 0,
            review_count: areas_info[`${point.id}`]?.review_count || 0,
            response_rate: Utils.round(areas_info[`${point.id}`]?.response_rate * 100) + "%"
          }
        }
      });

      this.$emit("updateReviewsTotal", reviewsTotal);

      const data = {
        type:     "FeatureCollection",
        features: points
      }

      const coordinates = polygon_points.flatMap(p => p.shape.type == "MultiPolygon" ? (p.shape?.coordinates || []).flat() : (p.shape?.coordinates || [])).flat();

      const options = {
        ...this.initOpts,
        container: "per-location-map",
        center: (this.position.lng && this.position.lat) ? [this.position.lng, this.position.lat] : this.getCenter(coordinates),
        zoom: this.position.zoom || this.defaults.zoom
      };

      this.map = new window.maplibregl.Map(options);

      let hoveredStateId = null;

      this.map.on("load", () => {
        this.setMapLanguage(this.map);

        this.map.addControl(new window.maplibregl.NavigationControl(), "bottom-right");

        this.map.addSource("areas", {
          type: "geojson",
          data: data
        });

        this.map.addLayer({
          id: "areas-fills",
          type: "fill",
          source: "areas",
          paint: {
            "fill-color": [
              "step",
              ["get", this.dataFormatType],
              ...this.generateRatingPoints()
            ],
            "fill-opacity": [
              "case",
              ["boolean", ["feature-state", "hover"], false],
              1,
              0.75
            ]
          }
        });

        this.map.addLayer({
          id: "areas-borders",
          type: "line",
          source: "areas",
          layout: {},
          paint: {
            "line-color": "#444",
            "line-width": 1
          }
        });

        this.map.addLayer({
          id: "debug",
          type: "symbol",
          source: "areas",
          minzoom: 9,
          layout: {
            "text-field": ["get", "name"],
            "text-size": 15
          },
        });

        this.map.on("mousemove", "areas-fills", (e) => {
          if (e.features.length) {
            if (hoveredStateId) {
              this.map.setFeatureState(
                { source: "areas", id: hoveredStateId },
                { hover: false }
              );
            }
            hoveredStateId = e.features[0].id;
            this.map.setFeatureState(
              { source: "areas", id: hoveredStateId },
              { hover: true }
            );
          }
        });

        this.map.on("mouseleave", "areas-fills", () => {
          if (hoveredStateId) {
            this.map.setFeatureState(
              { source: "areas", id: hoveredStateId },
              { hover: false }
            );
          }
          hoveredStateId = null;
          this.map.getCanvas().style.cursor = "";
        });

        this.map.on("click", "areas-fills", (e) => {
          const data = e.features[0].properties;
          this.popupData = { ...data };
          const popup = this.$refs.popup.$el;
          new window.maplibregl.Popup()
            .setLngLat(e.lngLat)
            .setDOMContent(popup)
            .addTo(this.map)
        });

        this.map.on("mouseenter", "areas-fills", () => {
          this.map.getCanvas().style.cursor = "pointer";
        });

        this.map.on("move", () => this.$emit("updatePosition", { center: this.map.getCenter(), zoom: this.map.getZoom() }));

        this.map.on("zoom", () => {
          this.nextZoomUpdate = Date.now() + this.moveDelay;
          setTimeout(async () => {
            this.zoom = this.map.getZoom();
            this.$emit("updateReviewsOnScreen", this.onScreenFn(this.reviewsTotal, this.zoom));
            this.updateLayer();
          }, this.zoomDelay);
        });

        this.$emit("locationLoading", false);
      });
    },
    generateValue(point, areas_info) {
      if (this.dataFormatType == "overall_rating_average") {
        return (areas_info?.[`${point.id}`]?.[`${this.dataFormatType}`] || 0) / 10;
      }
      return areas_info?.[`${point.id}`]?.[`${this.dataFormatType}`] || 0;
    },
    generateRatingPoints() {
      if (this.dataFormatType == "review_count") return Object.entries(this.heatColors).slice(1).flatMap(([,color], i) => [color, Math.floor(this.onScreenFn(this.reviewsTotal, this.zoom) * (i + 1) / this.clusterQuadrants)]).slice(0, -1);
      return Object.entries(this.ratingColors).flatMap(([k, v]) => ([v, parseInt(k)])).concat(["#54CA74"]);
    },
    getCenter(points) {
      if (points.length <= 1) return [this.defaults.lng, this.defaults.lat];
      let llb = new window.maplibregl.LngLatBounds(points);
      const { lng, lat } = llb.getCenter();
      return [lng, lat];
    },
    async updateLayer() {
      await this.$nextTick();
      this.map.setPaintProperty("areas-fills", "fill-color", [
        "step",
        ["get", this.dataFormatType],
        ...this.generateRatingPoints()
      ]);
    },
    onScreenFn(reviews, zoom) {
      return reviews / (2 * zoom);
    },
    removeMap() {
      if (this.map) {
        this.map.remove();
        this.map = null;
      }
    }
  }
}
</script>

<template>
  <div>
    <div id="per-location-map" class="map"></div>
    <PopUp ref="popup" :data="popupData" />
  </div>
</template>
