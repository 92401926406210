<script>
import PageHeader from "@/components/page-header";
import Filters    from "@/components/filter";

import axios      from 'axios'
import Utils      from "@/js/utils";
import DataUtils  from "@/js/data-utils";
import Sources    from "@/data/sources";
import ExportXLSX from "@/components/export-xlsx";
//const stars = Object.values(import.meta.glob("@/assets/images/stars/{1,2,3,4,5}_star.png", { eager: true, as: 'url' }));
const r = require.context("@/assets/images/stars/", false, /(\d)_star.png$/)
const stars = r.keys().reduce((h,i) => {
  h[i.replace(/.\/(\d)_star.png$/, "$1")] = r(i)
  return h
}, {});

export default{
  data () {
    return {
      filterParams: null,
      filterCols: ["group_companies", "companies", "daterange", "sources"],
      sortBy: "count",
      sortDesc: true,
      columns: ["company", "source", "rate"],
      data: null,
      filename: 'review-responses',
      loading: false,
      Utils: Utils,
      stars: stars,
      exportingXLSX: false,
      cols: [
        { value: "company",        text: this.$t("review-responses.company"), sortable: true },
        { value: "source",         text: this.$t("review-responses.source"),  sortable: true },
        { value: "response_count", text: this.$t("review-responses.response_count"), sortable: true },
        { value: "rate",           text: this.$t("review-responses.rate"),  sortable: true },
        { value: "rating_1",       text: this.$t("review-responses.rating_1"),  sortable: true },
        { value: "rating_2",       text: this.$t("review-responses.rating_2"),  sortable: true },
        { value: "rating_3",       text: this.$t("review-responses.rating_3"),  sortable: true },
        { value: "rating_4",       text: this.$t("review-responses.rating_4"),  sortable: true },
        { value: "rating_5",       text: this.$t("review-responses.rating_5"),  sortable: true },
      ],
    }
  },
  props:      [],
  components: { PageHeader, Filters, ExportXLSX },
  watch: {
    filterParams: {
      handler: function () {
        this.load()
      },
      immediate: true
    },
  },
  methods: {
    ...DataUtils,
    async load() {
      if (!this.filterParams) return
      let p = {
        company_ids:     this.filterParams.data.company_ids,
        group_ids:       this.filterParams.data.group_ids,
        start_date:      this.filterParams.data.start_date,
        end_date:        this.filterParams.data.end_date,
        sources:         this.filterParams.data.sources,
        exclude_sources: this.filterParams.data.exclude_sources,
        segment:         'source',
        group_by:        'company',
        by_rating:       true,
      }
      Utils.setLoading.bind(this)(true)
      let responses = (await axios.get('/v3/response_rate', { params: p })).data.data;
      responses     = responses.filter( r => r.company )
      this.data     = responses.flatMap(r => {
        return r.response_rate.filter(rr => rr.segment).map(rr => {
          let by_rating = [1,2,3,4,5].reduce((h,i) => {
            if (!rr.current.by_rating) rr.current.by_rating = {}
            h[`rating_${i}`] = rr.current.by_rating[i] || {}
            return h
          }, {})
          return {
            company:        r.company.name,
            source:         Sources[rr.segment]?.friendly_name,
            response_count: rr.current.with_responses,
            rate:           Utils.percent.format(rr.current.rate || 0),
            ... by_rating
          }
        })
      })

      Utils.setLoading.bind(this)(false)
    },
    formatResponse(rating) {
      if (!rating.respondable) return '-'
      return this.Utils.percent.format((rating.with_responses || 0) * 1.0 / rating.respondable) + ` (${rating.with_responses} of ${rating.respondable})`
    },
    toggleExportXLSX () {
      this.exportingXLSX = !this.exportingXLSX
    }
  },
}
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('review-responses.title')" />

    <Filters @filter:created="saveFilterParams" @filter:update="saveFilterParams" @filter:change="saveFilterParams" :cols="filterCols" :notifyChange="true" />

    <div class="card" :class="{'loading': loading}">
      <div class="card-body" style='min-height: 300px;'>
        <div class="table-responsive" id='review-responses'>
          <ExportXLSX v-if='data' :fileName="filename" @ExportXLSX=toggleExportXLSX :btnText="$t('general.export_excel')" btnClass="btn btn-secondary text-nowrap mb-1" target='review-responses' :hasData="true" >

            <data-table :items=data :headers=cols headerTextDirection='center' bodyTextDirection='center' :sortBy=sortBy sortType='desc' hideFooter :rows-per-page=data.length >
              <template v-for='i in [1,2,3,4,5]' #[`header-rating_${i}`]=header :key=i>
                <span class="xlsx text-center" v-if='exportingXLSX' >{{ header.text }}</span>
                <img class="text-center" :src='stars[i]' width=100 v-else> </template>
              <template v-for='i in [1,2,3,4,5]' #[`item-rating_${i}`]="item" :key=i> {{ formatResponse(item[`rating_${i}`]) }} </template>
            </data-table>

          </ExportXLSX>
        </div>
      </div>
    </div>
  </div>
</template>
