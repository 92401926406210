<script>
// import router  from "@/router";
import NavBar  from "@/components/nav-bar";
import SideBar from "@/components/side-bar";
import Footer  from "@/components/footer";
import Utils   from "@/js/utils";

export default {
  props: {
    no_padding: {
      type: Boolean,
      default: false
    },
    no_footer: {
      type: Boolean,
      default: false
    },
    ignore_container_flex: {
      type: Boolean,
      default: false
    },
    statusMessage: {
      type: Object,
      default: () => ({ status: "", icon: "" })
    },
    routes: {
      type: Array,
      default: () => []
    }
  },
  components: { NavBar, SideBar, Footer },
  data() {
    return {
      isMenuCondensed: false,
      defaultdate: "",
      user: { destination_contracts: [] },
      loading: true,
      outOfProductsPermittedPages: ["reports", "documentation", "white-label", "user-settings", "email-notifications"]
    };
  },
  async created () {
    document.body.classList.remove("bg-primary");
    if (Utils.products.includes(this.context) || this.outOfProductsPermittedPages.includes(this.context)) {
      this.loading = true;
      this.user = await this.$store.dispatch("user/fetch");
      this.loading = false;
    }
  },
  computed: {
    has_token () {
      return this.$store.getters.has_token();
    },
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    context() {
      return this.$route.path.split("/")[1];
    },
    computedUser: {
      get() {
        return this.$store.getters["user/user"]();
      },
      set(value) {
        this.user = value;
      }
    },
  },
  methods: {
    toggleMenu() {
      this.isMenuCondensed = !this.isMenuCondensed;
    },
  },
  watch: {
    contract: {
      handler: function () {
        if (this.contract?.id) Utils.setDashboardStyles(this.contract);
      },
      immediate: true
    },
    isMenuCondensed: function () {
      document.body.classList.toggle("vertical-collpsed");
    }
  }
};
</script>

<template>
  <div id="layout-wrapper">
    <NavBar v-if="computedUser && computedUser.id" :user='computedUser' :no_shadow="no_padding" :statusMessage="statusMessage"/>
    <SideBar v-if="computedUser && computedUser.id" :user='computedUser' :isCondensed="isMenuCondensed" :routes="routes" />
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="main-content" :class="{ 'px-sm-5 mx-md-5': !computedUser.id }">
      <div class="page-content" :class="{
        'position-relative': no_padding,
        'px-0': context == 'documentation' || no_padding,
        'py-0': context == 'signup' || no_padding
      }">
        <!-- Start Content-->
        <div class="container-fluid" :class="{ 'px-0': context == 'documentation' || no_padding, 'd-flex': !ignore_container_flex }">
          <div class='alert-danger alert mt-3' role='alert' v-if='!has_token'>You need to pass the token parameter.</div>
          <slot v-if='has_token' :routes="routes" />
        </div>
      </div>
      <Footer v-if="!loading && contract && !contract.whitelabel && !no_footer" />
    </div>
  </div>
</template>
