export default {
  "Abkhaz": "ab",
  "Afar": "aa",
  "Afrikaans": "af",
  "Akan": "ak",
  "Albanian": "sq",
  "Amharic": "am",
  "Arabic": "ar",
  "Aragonese": "an",
  "Armenian": "hy",
  "Assamese": "as",
  "Avaric": "av",
  "Avestan": "ae",
  "Aymara": "ay",
  "Azerbaijani": "az",
  "Bambara": "bm",
  "Bashkir": "ba",
  "Basque": "eu",
  "Belarusian": "be",
  "Bengali": "bn",
  "Bihari": "bh",
  "Bislama": "bi",
  "Bosnian": "bs",
  "Breton": "br",
  "Bulgarian": "bg",
  "Burmese": "my",
  "Catalan": "ca",
  "Chamorro": "ch",
  "Chechen": "ce",
  "Chichewa": "ny",
  "Chinese Taiwanese": "zh-tw",
  "Chinese": "zh",
  "Chuvash": "cv",
  "Cornish": "kw",
  "Corsican": "co",
  "Cree": "cr",
  "Croatian": "hr",
  "Czech": "cs",
  "Danish": "da",
  "Divehi": "dv",
  "Dutch": "nl",
  "Dzongkha": "dz",
  "English": "en",
  "Esperanto": "eo",
  "Estonian": "et",
  "Ewe": "ee",
  "Faroese": "fo",
  "Fijian": "fj",
  "Filipino": "tl",
  "Finnish": "fi",
  "French": "fr",
  "Fula": "ff",
  "Galician": "gl",
  "Ganda": "lg",
  "Georgian": "ka",
  "German": "de",
  "Greek": "el",
  "Guaran": "gn",
  "Gujarati": "gu",
  "Haitian": "ht",
  "Hausa": "ha",
  "Hebrew": "he",
  "Herero": "hz",
  "Hindi": "hi",
  "Hiri Motu": "ho",
  "Hungarian": "hu",
  "Icelandic": "is",
  "Ido": "io",
  "Igbo": "ig",
  "Indonesian": "id",
  "Interlingua": "ia",
  "Interlingue": "ie",
  "Inuktitut": "iu",
  "Inupiaq": "ik",
  "Irish": "ga",
  "Italian": "it",
  "Japanese": "ja",
  "Javanese": "jv",
  "Kalaallisut": "kl",
  "Kannada": "kn",
  "Kanuri": "kr",
  "Kashmiri": "ks",
  "Kazakh": "kk",
  "Khmer": "km",
  "Kikuyu": "ki",
  "Kinyarwanda": "rw",
  "Kirundi": "rn",
  "Komi": "kv",
  "Kongo": "kg",
  "Korean": "ko",
  "Kurdish": "ku",
  "Kwanyama": "kj",
  "Kyrgyz": "ky",
  "Ladin": "lld",
  "Lao": "lo",
  "Latin": "la",
  "Latvian": "lv",
  "Limburgish": "li",
  "Lingala": "ln",
  "Lithuanian": "lt",
  "Luba-Katanga": "lu",
  "Luxembourgish": "lb",
  "Macedonian": "mk",
  "Malagasy": "mg",
  "Malay": "ms",
  "Malayalam": "ml",
  "Maltese": "mt",
  "Manx": "gv",
  "Marathi": "mr",
  "Marshallese": "mh",
  "Mongolian": "mn",
  "Mori": "mi",
  "Nauru": "na",
  "Navajo Navaho": "nv",
  "Ndonga": "ng",
  "Nepali": "ne",
  "Northern Ndebele": "nd",
  "Northern Sami": "se",
  "Norwegian Bokml": "nb",
  "Norwegian Nynorsk": "nn",
  "Norwegian": "no",
  "Nuosu": "ii",
  "Occitan": "oc",
  "Ojibwe": "oj",
  "Old Bulgarian": "cu",
  "Oriya": "or",
  "Oromo": "om",
  "Ossetian": "os",
  "Pashto": "ps",
  "Persian": "fa",
  "Pli": "pi",
  "Polish": "pl",
  "Portuguese": "pt",
  "Punjabi": "pa",
  "Quechua": "qu",
  "Romanian": "ro",
  "Romansh": "rm",
  "Russian": "ru",
  "Samoan": "sm",
  "Sango": "sg",
  "Sanskrit": "sa",
  "Sardinian": "sc",
  "Scottish Gaelic": "gd",
  "Serbian": "sr",
  "Shona": "sn",
  "Simplified Chinese": "zh-cn",
  "Sindhi": "sd",
  "Sinhala": "si",
  "Slovak": "sk",
  "Slovenian": "sl",
  "Somali": "so",
  "Southern Ndebele": "nr",
  "Southern Sotho": "st",
  "Spanish": "es",
  "Sundanese": "su",
  "Swahili": "sw",
  "Swati": "ss",
  "Swedish": "sv",
  "Tahitian": "ty",
  "Tajik": "tg",
  "Tamil": "ta",
  "Tatar": "tt",
  "Telugu": "te",
  "Thai": "th",
  "Tibetan Standard": "bo",
  "Tigrinya": "ti",
  "Tonga": "to",
  "Tsonga": "ts",
  "Tswana": "tn",
  "Turkish": "tr",
  "Turkmen": "tk",
  "Twi": "tw",
  "Ukrainian": "uk",
  "Urdu": "ur",
  "Uyghur": "ug",
  "Uzbek": "uz",
  "Venda": "ve",
  "Vietnamese": "vi",
  "Volapuk": "vo",
  "Walloon": "wa",
  "Welsh": "cy",
  "Western Frisian": "fy",
  "Wolof": "wo",
  "Xhosa": "xh",
  "Yiddish": "yi",
  "Yoruba": "yo",
  "Zhuang": "za",
  "Zulu": "zu"
};
