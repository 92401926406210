<script>
import {axios} from '@/js/reports'
import dayjs from "dayjs"
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      tableData: [],
      cols: [
        { field: "name", label: "" },
      ],
      rows: []
    };
  },
  components: { ExportExcel },
  props: ["title", "identifier", "release_date", "group", "months_ago", "smly", "last_month", "source"],
  created() {
    let release = this.release_date;
    if (this.smly)
      release = dayjs(this.release_date).subtract(1, "year").format("YYYY-MM-DD");
    axios.get("/v3/query/db/_", { params: {
      group_id:     this.group.id,
      identifier:   this.identifier,
      release_date: release,
      months_ago:   this.months_ago,
      source:       this.source,
      compset: false,
      format:  "hash"
    }}).then(response => {
      if (this.last_month) this.tableData = response.data.data.filter(d => d.period == "previous");
      else this.tableData = response.data.data;

      let properties = [];
      this.tableData.forEach(data => {
        if (!properties.includes(data.name)) properties.push(data.name);
      });

      let sources = this.source.concat(["others"]);
      sources.forEach(source => {
        let found = this.tableData.find(t => Utils.compareSources(source, t.source));
        if (found)
          this.cols.push({ field: Utils.slugify(found.source), label: found.source });
      });

      // Fallback: put 'Others' at the end
      let index = this.cols.map(c => c.label).indexOf("Others");
      this.cols.splice(this.cols.length - 1, 0, this.cols.splice(index, 1)[0]);

      properties.forEach(property => {
        let row = {};
        row.name = property;
        this.cols.slice(1, this.cols.length).forEach(col => {
          let data = this.tableData.find(d => d.name == property && d.source == col.label)?.share;
          if (data == null) row[col.field] = "-";
          else row[col.field] = data + "%";
        });
        this.rows.push(row);
      });
    });
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th class="property-name-column">{{ cols[0].label }}</th>
            <th v-for="col in cols.slice(1)" :key="col.label">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, indexRow) in rows" :key="indexRow">
            <td class="property-name-column">{{ row.name }}</td>
            <template v-for="(col, indexCol) in cols.slice(1)" :key="col.field + indexCol">
              <td>{{ row[col.field] }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
