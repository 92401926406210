<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      loading: false,
      tableData: [],
      cols: [
        { field: "name", label: "Hotel" },
        { field: "ranking", label: "Current Rank" },
        { field: "ranking_change", label: "Change Since Last Month" },
        { field: "room_number", label: "Total Rooms" },
        { field: "days", label: "Total Days in Period" },
        { field: "rn", label: "Total R/N in Period" },
        { field: "reviews", label: "Number of Reviews" },
        { field: "reviews_change", label: "Percentage Change in Reviews Quantity from Last Month" },
        { field: "frequency", label: "Frequency (Rooms Per Review)" },
        // { field: "", label: "Per Room Change to Last Month" },
        { field: "average_rating", label: "Avg. Review Score" },
        { field: "average_rating_change", label: "Change Since Last Month" },
      ],
      rows: []
    };
  },
  components: { ExportExcel },
  props: ["title", "company", "identifier", "release_date", "source"],
  created() {
    this.loading = true;
    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      source:  this.source,
      format:  "hash"
    }}).then(response => {
      this.tableData = response.data.data;
      this.tableData.forEach(data => {
        let row = {};
        this.cols.forEach(col => {
          if (col.field == "average_rating" && data[col.field] != null)
            row[col.field] = Utils.round(data[col.field], 2)
          else if (col.field == "ranking" && data[col.field] != null)
            row[col.field] = "#" + data[col.field]
          else if (col.field == 'rn' && data[col.field])
            row[col.field] = data[col.field].toLocaleString()
          else row[col.field] = data[col.field]
        });
        this.rows.push(row);
      });
      this.loading = false;
    });
  },
  methods: {
    compareTrend(string) {
      return Number.parseFloat(string) >= 0;
    }
  }
}
</script>

<template>
  <div class="table-responsive h-100">
    <div v-if="!loading && tableData.length == 0" class="h-100 d-flex flex-column justify-content-between">
      <h3 class="title mt-3"> {{ title }} </h3>
      <div class="mb-5 font-size-22 font-weight-normal text-justify">The data on this page is provided for those hotel partners that are enrolled in our elevated Reputation Enhancement and Management Program (REM). If the data in this page is blank, your hotel is engaged in only our Review Response Service. Please contact The Reputation Lab if there is any interest in exploring an upgrade.</div>
    </div>

    <ExportExcel v-else :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th v-for="col in cols" :key="col.field">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in rows" :key="JSON.stringify(row)">
            <td class="property-name-column">{{ row[cols[0].field] }}</td>
            <template v-for="col in cols.slice(1)" :key="JSON.stringify(row) + col.field">
              <td>
                <div :class="{ 'd-flex align-items-center justify-content-center': col.field.includes('change') }">
                  <template v-if="col.field != 'ranking_change' && col.field.includes('change') && (row[col.field] || row[col.field] == 0)">
                    <i class="arrow-trend" :class="{ 'mdi mdi-arrow-up text-success': compareTrend(row[col.field]), 'mdi mdi-arrow-down text-danger': !compareTrend(row[col.field]) }"></i>
                    <span>{{ row[col.field] }}%</span>
                  </template>

                  <template v-else-if="!row[col.field] && row[col.field] != 0">
                    <span>-</span>
                  </template>

                  <template v-else>
                    <span>{{ row[col.field] }}</span>
                  </template>
                </div>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
