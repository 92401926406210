<script>
import PageHeader from "@/components/page-header";
import Utils from "@/js/utils";
import axios from "axios";
import { ColorPicker } from "vue3-colorpicker";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      loading: false,
      contract: null,
      logo: null,
      colors: {},
      defaultColors: {
        primary_color:            "#20a0cb",
        secondary_color:          "#afdede",
        sidebar_background_color: "#383c40",
        sidebar_text_color:       "#7a8590",
        topbar_background_color:  "#ffffff",
        topbar_text_color:        "#555b6d"
      },
      fieldsBySection: {
        topbar:  ["topbar_background_color", "topbar_text_color"],
        sidebar: ["sidebar_background_color", "sidebar_text_color"],
        general: ["primary_color", "secondary_color"]
      },
      originalStyles: {},
      logoDimensions: { logo_width: 70, logo_margin_top: 10 },
      fileSizeLimit: 1
    };
  },
  components: { PageHeader, ColorPicker },
  async created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
    Object.keys(this.defaultColors).forEach(c => this.colors[c] = this.defaultColors[c]);
    this.contract = Utils.deepClone(this.currentContract);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  methods: {
    handleFileUpload(event) {
      if (!this.contract || !event.target.files[0]) return;
      let size = event.target.files[0].size / 10**6; // size in MB
      if (size > this.fileSizeLimit) {
        Swal.fire({ text: this.$t("general.image_too_large", { size: Utils.round(size), sizeLimit: this.fileSizeLimit }), icon: "info", showCancelButton: false, showConfirmButton: true, allowOutsideClick: true, backdrop: true });
        event.target.value = null;
        return;
      }
      this.contract.logo = event.target.files[0];
      this.logo = URL.createObjectURL(event.target.files[0]);
      this.updateStyles();
    },
    async saveSettings() {
      if (!this.contract) return;
      this.loading = true;

      let payload = new FormData();
      payload.append("whitelabel_styles[logo_url]", this.logo || "");
      if (this.contract.logo) payload.append("logo", this.contract.logo);

      Object.entries({ ...this.colors, ...this.logoDimensions }).forEach(([field, value]) => {
        if (value) payload.append(`whitelabel_styles[${field}]`, value);
      });

      let response = await axios.post(`/v3/contracts/${this.contract.id}`, payload, {
        headers: { "Content-Type": "multipart/form-data" },
        transformRequest: () => payload
      });
      this.contract = { ...this.contract, whitelabel_styles: response.data.data.whitelabel_styles };
      delete this.contract.logo;
      this.$store.commit("contract/updateContract", { contract: this.contract });

      this.loading = false;
    },
    beforeWindowUnload(event) {
      if (this.isDirty && this.confirmStayInDirtyForm()) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    confirmStayInDirtyForm() {
      return !window.confirm(this.$t("new_survey.confirm_leave"));
    },
    updateStyles() {
      Utils.setDashboardStyles({ ...this.contract, whitelabel_styles: { ...this.colors, logo_url: this.logo, ...this.logoDimensions }});
    },
    removeLogoImage() {
      if (!this.contract) return;
      this.contract.logo = null;
      this.logo = null;
    },
    showTooltip(field) {
      return /primary_color|secondary_color/.test(field);
    }
  },
  computed: {
    validFields() {
      return this.contract?.id;
    },
    isDirty() {
      if (!this.contract?.id) return false;
      let diff = Object.entries({ ...this.colors, ...this.logoDimensions }).reduce((res, [field, value]) => {
        if (value != this.originalStyles[field]) res[field] = value;
        return res;
      }, {});
      if (this.logo != this.originalStyles.logo) diff.logo = this.logo;
      return Object.keys(diff).length;
    },
    currentContract() {
      return this.$store.getters["contract/currentContract"];
    }
  },
  watch: {
    currentContract: function () {
      this.contract = Utils.deepClone(this.currentContract);
    },
    contract: function () {
      if (!this.contract) return;
      this.logo = Utils.deepClone(this.contract.whitelabel_styles?.logo_url);
      this.originalStyles.logo = Utils.deepClone(this.contract.whitelabel_styles?.logo_url);

      Object.keys(this.logoDimensions).forEach(field => {
        this.originalStyles[field] = Utils.deepClone(this.contract.whitelabel_styles?.[field]);
        this.logoDimensions[field] = this.contract.whitelabel_styles?.[field];
      });

      Object.keys(this.colors).forEach(field => {
        this.originalStyles[field] = Utils.deepClone(this.contract.whitelabel_styles?.[field]);
        this.colors[field] = this.contract.whitelabel_styles?.[field] || this.defaultColors[field];
      });
    }
  }
}
</script>

<template>
  <div class="w-100" :class="{ 'cursor-loading': loading }">
    <PageHeader :title="$t('white_label.title')" />

    <div class="card" :class="{ 'loading': loading }">
      <div class="card-body">
        <label class="ms-sm-4 mt-2 mb-5 font-size-15" v-if="contract && contract.id">{{ $t("white_label.help", { sub_name: contract.name, sub_id: contract.id }) }}</label>

        <!-- Logo -->
        <div class="d-flex">
          <h4 class="font-size-20 ms-sm-4 mb-0">{{ $t("white_label.logo") }}</h4>
          <span v-b-tooltip.hover :title="$t('white_label.size_limit_tooltip', { limit: fileSizeLimit })" class="ms-2">
            <i class="mdi mdi-help-circle font-size-16"></i>
          </span>
        </div>

        <div class="form-group px-sm-5 mx-sm-5 mt-5">
          <div class="d-flex flex-column align-items-center flex-md-row">
            <div class="d-flex w-100">
              <input
                class="form-control"
                type="file"
                id="logoInput"
                :placeholder="$t('new_survey.settings.file_placeholder')"
                accept="image/*"
                @change="handleFileUpload"
              >
              <a v-if="logo" href="#" @click="removeLogoImage" class="ms-3" v-b-tooltip.hover :title="$t('white_label.remove_logo_image')">
                <i class="font-size-24 mdi mdi-close text-danger"></i>
              </a>
            </div>
            <img v-if="logo" class="resize-img ms-md-5 my-3 my-md-0" :src="logo" />
          </div>

          <div class="d-flex mt-3" style="gap:20px;">
            <div class="form-group w-100">
              <label for="logoWidth" class="form-label m-0">{{ $t("white_label.logo_width") }}</label>
              <input type="range" class="form-range" id="logoWidth" min="0" max="100" v-model="logoDimensions.logo_width" @input="updateStyles">
              <span>{{ logoDimensions.logo_width }}%</span>
            </div>

            <div class="form-group w-100">
              <label for="logoHeight" class="form-label m-0">{{ $t("white_label.logo_margin_top") }}</label>
              <input type="range" class="form-range" id="logoHeight" min="0" max="50" v-model="logoDimensions.logo_margin_top" @input="updateStyles">
              <span>{{ logoDimensions.logo_margin_top }}px</span>
            </div>
          </div>
        </div>

        <!-- Colors -->
        <h4 class="font-size-20 ms-sm-4 mb-0 mt-4">{{ $t("white_label.colors") }}</h4>
        <div class="mt-5 mb-3 px-sm-5 mx-sm-5 d-xl-flex justify-content-between">
          <div v-for="section in Object.keys(fieldsBySection)" :key="section">
            <h4 class="font-size-20 mb-2">{{ $t(`white_label.${section}`) }}</h4>
            <div class="d-flex">
              <div class="form-group d-flex flex-column me-4" v-for="field in fieldsBySection[section]" :key="field">
                <div class="d-flex">
                  <label class="form-label">{{ $t(`white_label.${field}`) }}</label>
                  <span v-if="showTooltip(field)" v-b-tooltip.hover :title="$t(`white_label.${field}_help`)" class="ms-2">
                    <i class="mdi mdi-help-circle font-size-16"></i>
                  </span>
                </div>
                <ColorPicker v-model:pureColor="colors[field]" @update:pureColor="updateStyles" useType="pure" format="hex" pickerType="chrome" lang="En" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button class="btn btn-primary mb-4 px-5 py-2 float-end" :disabled="!validFields" @click="saveSettings"> {{ $t("white_label.save") }} </button>
  </div>
</template>
