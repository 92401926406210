<script>
import Mustache from 'mustache';
import Reports from '@/js/reports';

export default {
  props: ['settings', 'start_date', 'end_date', 'contract', 'company_id', 'group_id', 'based_on', 'company_name', 'group_name'],
  data() {
    return {
      html: null
    };
  },
  watch: {
    "settings.html": {
      handler: function () {
        this.$nextTick(() => {
          const data = {
            report_period:   Reports.formatDateRange(this.start_date, this.end_date),
            generation_date: new Date().toLocaleDateString(),
            group_name:      this.group_name,
            company_name:    this.company_name,
            company_id:      this.company_id,
          };
          this.html = Mustache.render(this.settings.html || "", data);
        })
      },
      immediate: true
    }
  }
};
</script>
<template>
  <div v-html="html"></div>
</template>
