<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils";
import ExportExcel from "@/components/reports/export-excel";

export default {
  data() {
    return {
      tableData: [],
      cols: [
        { field: "channel", label: "Channel" },
        { field: "review_count", label: "Number of Reviews"},
        { field: "review_count_var", label: "Quantity Variance to Last Month" },
        { field: "rating", label: "Avg. Review Score" },
        { field: "rating_var", label: "Score Variance to Last Month" }
      ],
      rows: [],
      sources: {
        accommodation: ['booking_com', 'expedia', 'hotels_com', 'google', 'orbitz'],
        restaurant: ['open_table', 'trip_advisor', 'google', 'yelp']
      }
    };
  },
  components: { ExportExcel },
  props: ["title", "company", "identifier", "release_date"],
  created() {
    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      source:       this.sources[this.company.property_type],
      compset: false,
      format:  "hash"
    }}).then(response => {
      this.rows = response.data.data;
      this.rows.forEach(row => {
        this.cols.forEach(col => {
          if (!row[col.field] && row[col.field] != 0) { row[col.field] = "-"; return; }
          if (col.field == "rating") row[col.field] = Utils.round(row[col.field], 2);
          if (col.field.includes("var")) row[col.field] += "%";
        });
      });
    });
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th v-for="col in cols" :key="col.field">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in rows" :key="row.field">
            <template v-for="col in cols" :key="col.field">
              <td>{{ row[col.field] }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
