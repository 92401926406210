<script>
import PageHeader        from "@/components/page-header";
import Filters           from "@/components/filter";
import Kpis              from "@/components/kpis";
import OverallChart      from "@/components/overall-chart";
import AnswerAnalysis    from "@/components/feedback/answer-analysis";
import TableSurveyScores from "@/components/reports/table-survey-scores";
import SubRatings        from "@/components/feedback/subratings";
import ResponseRateKPIs  from "@/components/feedback/response-rate-kpi";
import DataUtils         from "@/js/data-utils";
import Utils             from "@/js/utils";
import Multiselect       from "vue-multiselect";

export default {
  data() {
    return {
      filterCols: ["daterange", "survey_filter", "companies", "finished", "response_language", "question_type", "has_sentiment"],
      extraCols:  ["rating_type"],
      kpiCols: ["numerical_rating", 'sentiment_rating', 'tes_score', "response_count"],
      chartCols: ["response_count", "analytics_numerical_rating", 'analytics_sentiment_rating', 'analytics_tes_score'],
      responseRateCols: ["total", "open_rate", "click_rate", "completed_rate"],
      data: {},
      filterParams: {},
      viewTypeOptions: ["overview", "detailed"],
      viewType: "overview",
      ratingTypes: ["numerical", "sentiment", "tes"],
      ratingType: "numerical"
    };
  },
  components: { PageHeader, Filters, Kpis, OverallChart, AnswerAnalysis, TableSurveyScores, SubRatings, ResponseRateKPIs, Multiselect },
  mounted() {
    window.addEventListener("scroll", this.scrollBtnDisplay)
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrollBtnDisplay)
  },
  methods: {
    ...DataUtils,
    scrollBtnDisplay() {
      let scrollBtn = document.querySelector("#scroll-btn")
      window.scrollY > window.innerHeight
        ? scrollBtn.classList.add("show")
        : scrollBtn.classList.remove("show");
    },
    scrollWindow() {
      if (window.scrollY != 0) {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 10);
      }
    }
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    computedParams() {
      return {
        ...this.filterParams,
        data: {
          ...this.filterParams.data,
          subscription_ids: this.contract?.id,
          finished: this.filterParams.data?.finished != "all" ? this.filterParams.data?.finished == "completed" : undefined
        }
      };
    },
    tableSurveyScoresParams() {
      return {
        ...Utils.slice(this.computedParams.data, ["start_date", "end_date"]),
        company_id: this.computedParams.data?.company_ids,
        formatNumbers: true,
        settings: {
          dashboard: true,
          show_questions_without_answers: true,
          segment: `survey_${this.ratingType}_rating`,
          surveys: [this.computedParams.data.survey_id].flat(),
          ...Utils.slice(this.computedParams.data, ["finished", "response_language", "has_sentiment", "question_type"]),
        }
      };
    }
  }
};
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('feedback_analytics.title')" />
    <div class="row" v-if="contract && contract.id">
      <div class="col-lg-12">
        <Filters @filter:created="saveFilterParams" @filter:update="saveFilterParams" :cols="filterCols" :extraCols="extraCols" />

        <template v-if="Object.keys(filterParams.data || {}).length && contract && contract.id">
          <Kpis :filterParams="computedParams" :cols="kpiCols" dashboard_type="feedback" />

          <ResponseRateKPIs :filterParams="computedParams" :cols="responseRateCols" />

          <div class="row">
            <div class="col-lg-12">
              <OverallChart :filterParams="computedParams" :cols="chartCols" page='feedback_analytics'/>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-12">
              <PageHeader :title="$t('feedback_analytics.answer_analysis')">
                <template v-slot:subtitle>
                  <div class="d-sm-flex align-items-center justify-content-end w-md-unset" style="width:100%;">
                    <!-- Rating Type -->
                    <div class="d-flex align-items-center">
                      <label class="form-label mb-1 text-nowrap">{{ $t("ratings.rating_type") }}</label>
                      <multiselect class="ms-2" :multiple="false" v-model="ratingType" :options="ratingTypes" :showLabels="false" :custom-label="r => $t(`ratings.${r}`)" :allow-empty="false" style="min-width: 200px !important;"></multiselect>
                    </div>

                    <!-- View Type -->
                    <div class="d-flex align-items-center ms-sm-3">
                      <label class="form-label mb-1 text-nowrap">{{ $t("general.view_type") }}</label>
                      <multiselect class="ms-2" :multiple="false" v-model="viewType" :options="viewTypeOptions" :showLabels="false" :custom-label="l => $t(`general.${l}`)" :allow-empty="false" style="min-width: 200px !important;"></multiselect>
                    </div>
                  </div>
                </template>
              </PageHeader>

              <div v-if="viewType == 'overview'" class="card">
                <div class="card-body">
                  <TableSurveyScores class="thead-light" v-bind="tableSurveyScoresParams" />
                </div>
              </div>
              <AnswerAnalysis v-else :filterParams="computedParams" :rt="ratingType" />

              <SubRatings :filterParams="computedParams" />
            </div>
          </div>
        </template>
      </div>
    </div>

    <button id="scroll-btn" @click="scrollWindow()"> &uarr; </button>
  </div>
</template>
