<template>
  <div class="d-flex flex-column align-items-center vh-100">
    <div ref="animationContainer" class="lottie-animation mb-3"></div>
    <div v-if="subscriptionData" class="text-center">
      <h1>{{ $t('signup.signup_to_paid.headerText') }}</h1>
      <div class="subscription-details mt-4">
        <h2>{{ $t('signup.signup_to_paid.order', { order: subscriptionData.order_number }) }}</h2>
        <p class="onboarding-p">{{ $t('signup.signup_to_paid.congratulations', { name: userData.full_name }) }}</p>
        <p class="onboarding-p">{{ $t('signup.signup_to_paid.success_message', { productName }) }}</p>
        <p class="onboarding-p">{{ $t('signup.signup_to_paid.email_received') }}</p>
        <p class="onboarding-p">{{ $t('signup.signup_to_paid.billing_info', { billingDay: subscriptionDayToCharge }) }}
        </p>
        <p class="onboarding-p" v-if="isApiUser">{{ $t('signup.signup_to_paid.api_info') }}</p>
        <b-button v-if="isApiUser" variant="primary" :href="documentationUrl">{{
      $t('signup.signup_to_paid.access_documentation') }}</b-button>
        <p class="onboarding-p mt-2" v-if="isApiUser">
          {{ $t('signup.signup_to_paid.api_token') }}
          <span class="api-token" @click="copyToClipboard(userAPIToken)" title="Click to copy">
            {{ userAPIToken }}
          </span>
          <b-button variant="link" @click="copyToClipboard(userAPIToken)">
            {{ $t('signup.signup_to_paid.copy') }}
          </b-button>
        </p>
        <b-button variant="secondary" :href="dashboardUrl" class="mt-3">{{ $t('signup.signup_to_paid.access_dashboard')
          }}</b-button>
      </div>
      <p class="onboarding-p mt-4">
        {{ $t('signup.signup_to_paid.need_assistance') }}
        <a :href="'mailto:' + supportEmail" class="support-link">{{ supportEmail }}</a>
      </p>
    </div>
  </div>
</template>


<script>
import lottie from 'lottie-web';
import animationData from './subscribe_animation.json';

export default {
  props: {
    subscriptionData: {
      type: Object,
      required: true
    },
    userData: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.initLottieAnimation();
    this.forceUserAuthentication();
  },
  methods: {
    async forceUserAuthentication() {
      const url = `${location.protocol}//${location.host}/reputation?auth_token=${this.userAuthToken}`;

      await fetch(url);
    },
    initLottieAnimation() {
      lottie.loadAnimation({
        container: this.$refs.animationContainer,
        animationData: animationData,
        renderer: 'svg',
        loop: true,
        autoplay: true
      });
    },
    copyToClipboard(token) {
      const el = document.createElement('textarea');
      el.value = token;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      alert('API token copied to clipboard!');
    }
  },
  computed: {
    userAuthToken() {
      return this.userData.authentication_token;
    },
    userAPIToken() {
      return this.userData.api_token;
    },
    subscriptionDayToCharge() {
      const subscriptionDate = new Date(this.subscriptionData.subscribed_product.subscribed_at);
      return subscriptionDate.getDate();
    },
    isApiUser() {
      return this.userData['is_api?'];
    },
    productName() {
      const snakeToCamelSpaced = (text) => {
        return text
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      };
      return snakeToCamelSpaced(this.subscriptionData.subscribed_product.slug);
    },
    documentationUrl() {
      return this.subscriptionData.whitelabel_support_url;
    },
    dashboardUrl() {
      return `${location.protocol}//${location.host}/reputation`;
    },
    supportEmail() {
      return this.subscriptionData.whitelabel_support_email;
    }
  }
}
</script>

<style scoped>
.lottie-animation {
  width: 100px;
  height: 100px;
}

.subscription-details {
  max-width: 600px;
  margin: auto;
}

.onboarding-p {
  margin-top: 1rem;
  font-size: 18px;
}

.bold .onboarding-p {
  font-weight: bold;
}

.api-token {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  user-select: text;
  /* Allows the token to be selected for copying */
}

.api-token:hover {
  text-decoration: underline;
}
</style>
