<template>
  <div id="app" :class="['container mt-5', { loading: loading }]">
    <b-progress :value="progress" class="mb-4"></b-progress>
    <div v-if="currentStepComponent === 'LoginStep'">
      <component :is="currentStepComponent" :formData="formData" @update:formData="updateFormData" @nextStep="nextStep"
        :loginUser="loginUser" />
    </div>
    <div v-else>
      <component :is="currentStepComponent" :formData="formData" @update:formData="updateFormData" :nextStep="nextStep"
        @prevStep="prevStep" />
    </div>
    <div class="d-flex justify-content-between mt-4">
      <b-button @click="prevStep" v-if="currentStep > 1">
        {{ $t('signup.signup_to_paid.previous') }}
      </b-button>
      <b-button @click="nextStep" :disabled="!isCurrentStepValid" class="ms-auto">
        {{ currentStep === 4 ? $t('signup.signup_to_paid.submit') : $t('signup.signup_to_paid.next') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BProgress, BButton } from 'bootstrap-vue-next';
import axios from 'axios';
import Step1 from './Steps/EmailStep.vue';
import Step2 from './Steps/CompanyNameStep.vue';
import Step3 from './Steps/NameStep.vue';
import Step4 from './Steps/PasswordStep.vue';
import Step5 from './Steps/LoginStep.vue';

export default {
  components: {
    BProgress,
    BButton,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },
  props: ['handlePersonalDetailsCompleted', 'setPreSignup'],
  data() {
    return {
      loading: false,
      pre_signup: null,
      userLoggedIn: false,
      query_params: null,
      currentStep: 1,
      user: null,
      formData: {
        company_name: '',
        email: '',
        first_name: '',
        surname: '',
        password: '',
        confirmPassword: '',
      },
      stepConfig: {
        1: { validate: this.validateEmail, action: this.handleEmail },
        2: { validate: this.validateCompany, action: this.handleCompany },
        3: { validate: this.validateUserName, action: this.handleUserName },
        4: { validate: this.validatePassword, action: this.handleSetPassword },
        5: { validate: this.validateAuthentication, action: this.handleAuthentication },
      },
    };
  },
  mounted() {
    this.query_params = new URLSearchParams(window.location.search);
    if (this.query_params.has('email')) {
      this.formData.email = this.query_params.get('email');
    }
  },
  computed: {
    currentStepComponent() {
      return `Step${this.currentStep}`;
    },
    progress() {
      return (this.currentStep / 4) * 100;
    },
    isCurrentStepValid() {
      const config = this.stepConfig[this.currentStep];
      return config ? config.validate() : false;
    },
  },
  methods: {
    async checkForCompany(company_id) {
      try {
        const response = await axios.get(`/v3/subscription_products/check_company/${company_id}`);
        return response.data.company;
      } catch (error) {
        return false;
      }
    },
    async loginUser(email, password) {
      try {
        this.loading = true;
        const response = await axios.post('/v3/subscription_products/authenticate', { email, password, company_name: this.formData.company_name });
        const { user, pre_signup } = response.data;
        this.setPreSignup(pre_signup);
        this.loading = false;
        return user;
      } catch (error) {
        this.loading = false;
        return false;
      }
    },
    async isExistingUser(email) {
      try {
        const response = await axios.get(`/v3/subscription_products/check_user/${email}`);
        const { user, pre_signup } = response.data;
        this.user = user;
        this.setPreSignup(pre_signup);
        this.pre_signup = pre_signup;
        return user;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.user = null;
          return null;
        } else {
          throw error;
        }
      }
    },
    async updateExistingUserPassword(password, confirmPassword) {
      try {
        this.loading = true;
        const response = await axios.post(`/v3/subscription_products/set_user_password/${this.user.id}`, { password, confirm_password: confirmPassword });
        this.loading = false;
        return response.data;
      } catch (error) {
        this.loading = false;
        return false;
      }
    },
    nextStep() {
      const config = this.stepConfig[this.currentStep];
      if (config && !config.validate()) {
        return;
      }
      if (config) {
        config.action();
      }
    },
    handleEmail() {
      this.isExistingUser(this.formData.email).then((existing_user) => {
        if (existing_user) {
          if (this.pre_signup === null || this.pre_signup.company_name === null) {
            this.currentStep = 2;
          } else if (existing_user.user_management_type === 'Report Recipient') {
              this.currentStep = 4;  
          } else {
              this.currentStep = 5;
          }
        } else {
          this.currentStep++;
        }
      });
    },
    handleCompany() {
      if ((this.pre_signup === null || this.pre_signup.company_name === null) && this.user != null) {
        this.currentStep = 5;
      } else {
        this.currentStep++;
      }
    },
    handleUserName() {
      if(this.user != null){
        this.currentStep = 5;
      } else {
        this.currentStep++;
      }
    },
    handleSetPassword() {
      if(this.user && this.user.user_management_type === 'Report Recipient') {
        this.updateExistingUserPassword(this.formData.password, this.formData.confirmPassword).then(() => {
          this.handlePersonalDetailsCompleted();
        });
      } else {
        this.submitForm();
      }
    },
    handleAuthentication() {
      this.loginUser(this.formData.email, this.formData.password).then((user) => {
        if (user) {
          this.handlePersonalDetailsCompleted();
        } else {
          alert('Email or password incorrect');
          this.currentStep = 5;
        }
      });
    },
    prevStep() {
      if (this.currentStep === 5) {
        this.currentStep = 1;
      } else if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    submitForm() {
      axios.post('/v3/subscription_products/personal_details', { personal: this.formData }).then((response) => {
        const { pre_signup } = response.data;
        if (pre_signup) {
          this.setPreSignup(pre_signup);
          this.handlePersonalDetailsCompleted(this.formData);
        }
      });
    },
    updateFormData(updatedData) {
      this.formData = { ...updatedData };
    },
    validateEmail() {
      const re = /\S+@\S+\.\S+/;
      return this.formData.email.trim() && this.formData.email.match(re);
    },
    validateCompany() {
      return this.formData.company_name.trim();
    },
    validateUserName() {
      return (this.formData.first_name && this.formData.surname).trim();
    },
    validatePassword() {
      return this.formData.password && this.formData.confirmPassword && (this.formData.password === this.formData.confirmPassword);
    },
    validateAuthentication() {
      return this.formData.password;
    },
  },
};
</script>