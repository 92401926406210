if (!window.oly) window.oly = {}
import languagesCountries from "@/data/languages-countries";

window.oly.data_utils = {
  eqLanguages() {
    return {
      "sp": "es",
      "se": "sv"
    };
  },
  saveFilterParams(params) {
    this.filterParams = params;
  },
  saveStatusMessage(message) {
    this.statusMessage = message;
  },
  locales(locale, mode = "language") {
    let nameCode = locale;
    let flagCode = locale;
    let path = mode == "country" ? "countries" : "locales";

    if (mode == "language") {
      flagCode = nameCode = locale.split("-").flatMap(s => s.split("_"))[0] || locale;
      if (languagesCountries[flagCode]) flagCode = languagesCountries[flagCode];
      nameCode = (this.eqLanguages()[nameCode] || nameCode).toLowerCase();
    } else { // mode country
      flagCode = nameCode = flagCode.toUpperCase();
    }

    return {
      name: this.$t(`${path}.${nameCode}`),
      code: flagCode.toLowerCase()
    };
  },
  topicsByPropertyType(topics = []) {
    let result = {};
    topics.forEach(topic => {
      topic.property_types?.forEach(propertyType => {
        if (!result[propertyType]) result[propertyType] = [topic.topic];
        else if (!result[propertyType].includes(topic.topic)) result[propertyType].push(topic.topic);
      });
    });
    return result;
  },
  topicsByParent(topics = []) {
    let result = [];
    topics.forEach(topic => {
      let topicKey = topic.rating.key || "scores"
      let find = result.find(r => r.key == topicKey);
      if (find) find.children.push(topic);
      else result.push({ key: topicKey, label: this.$t(`ratings.rating_comparison.kpis.${topicKey}`), children: [topic] });
    });
    return result;
  }
}

export default window.oly.data_utils;
