<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      tableData: {
        current:  [],
        previous: []
      },
      cols: [
        { field: "rating",         label: "Rating"               },
        { field: "reviews",        label: "Reviews",  width: 150 },
        { field: "current_score",  label: "Rating MTD"           },
        { field: "previous_score", label: "Rating Last Month"    }
      ],
      rows: []
    };
  },
  components: { ExportExcel },
  props: ['title', 'company', 'identifier', 'release_date', 'mtd'],
  created() {
    axios.get("/v3/query/db/_", {params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      compset:      false,
      mtd:          this.mtd,
      prev_months:  1,
      months_ago:   2,
      format:       "hash",
    }}).then(response => {
      ["previous", "current"].forEach(period => {
        this.tableData[period] = response.data.data?.find(d => d.period == period) || [];
        this.tableData[period].ratings?.sort((a, b) => {
          if (a.reviews < b.reviews) return  1;
          if (a.reviews > b.reviews) return -1;
          return 0;
        });
      });

      this.tableData.current.ratings?.forEach(row => {
        let previous = this.tableData.previous.ratings.find(d => d.rating == row.rating)
        this.rows.push({
          rating:         row.rating,
          reviews:        row.reviews || "-",
          current_score:  Utils.round(row.value, 2)        || "-",
          previous_score: Utils.round(previous?.value, 2)  || "-",
        });
      });
    });
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-bordered table-nowrap mb-0 text-center" style="table-layout: fixed;">
        <thead>
          <tr>
            <th class="property-name-column">{{ cols[0].label }}</th>
            <th v-for="col in cols.slice(1)" :key="col.field" :style="`width: ${col.width}px;`">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in rows" :key="JSON.stringify(row)">
            <td class="property-name-column">{{ row.rating }}</td>
            <template v-for="col in cols.slice(1)">
              <td v-if="!/score/.test(col.field)" :key="col.label">
                {{ row[col.field] }}
              </td>

              <td v-else :key="JSON.stringify(row) + col.field">
                <div class="guest-data-share-grid" style="grid-template-columns: 20% 85%;">
                  <span class="px-0">
                    {{ row[col.field] }}
                  </span>
                  <div class="progress me-4">
                    <div class="progress-bar bg-replab" :style="`width: ${10*row[col.field]}%`"></div>
                  </div>
                </div>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
