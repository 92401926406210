<script>
import {axios} from '@/js/reports'
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      tableData: [],
      cols: [
        { field: 'total',    label: 'Total',    },
        { field: 'positive', label: 'Positive', },
        { field: 'negative', label: 'Negative', },
        { field: 'neutral',  label: 'Neutral',  },
      ],
      subCols: [
        { field: "source",   label: "Property Name", },
      ],
      subColsFields: [
        { field: "responses",   label: "Responses"   },
        { field: "respondable", label: "Respondable" }
      ],
      rows: [],
    };
  },
  components: { ExportExcel },
  props: ["title", "group", "identifier", "source", "release_date", "prev_months"],
  created() {
    this.cols.forEach(() => this.subCols = this.subCols.concat(this.subColsFields));
    axios.get("/v3/query/db/_", { params: {
      group_id:     this.group.id,
      identifier:   this.identifier,
      source:       this.source,
      release_date: this.release_date,
      compset: "primary",
      prev_months: 1,
      format: "hash",
      mtd: true
    }}).then(response => {
      this.tableData = response.data.data?.filter(d => d.period == "current") || [];
    });
  }
}
</script>

<template>
  <div class="table-responsive mt-4">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th></th>
            <th v-for="col in cols" :key="col.label" :colspan="2"> {{ col.label }} </th>
          </tr>
          <tr>
            <th class="property-name-column">{{ subCols[0].label }}</th>
            <th v-for="(subCol, index) in subCols.slice(1)" :key="subCol.field + index">{{ subCol.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, indexRow) in tableData" :key="String(row.length) + indexRow">
            <td class="property-name-column">{{ row.name }}</td>
            <template v-for="col in cols">
              <template v-for="subCol in subColsFields" :key="col.label + subCol.field">
                <td>{{ row.responses[col.field][subCol.field] }}</td>
              </template>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
