<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      cols: [
        { field: "", label: "Topics" },
        { field: "", label: "Mentions" },
        { field: "", label: "Score" }
      ],
      rows: {
        negative: [],
        positive: []
      }
    };
  },
  components: { ExportExcel },
  props: ['title', 'company', 'identifier', 'release_date', 'mtd', 'last_30d', 'months_ago'],
  created() {
    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      compset:      false,
      format:       "hash",
      mtd:          this.mtd,
      last_30d:     this.last_30d,
      months_ago:   this.months_ago,
    }}).then(response => {
      this.rows.negative = this.rows.positive = response.data.data;

      ["positive", "negative"].forEach(polarity => {
        this.rows[polarity].sort((a, b) => b[polarity] - a[polarity]);
        this.rows[polarity] = this.rows[polarity].slice(0, 10);
        this.rows[polarity].forEach(data => {
          if (!data.score && data.score != 0) data.score = "-";
          else data.score = Utils.round(data.score, 2);
        });
      });

    });
  },
  methods: {
    sortFields(data, polarity) {
      return data.sort((a, b) => b[polarity] - a[polarity]);
    }
  }
}
</script>

<template>
  <div class="d-flex justify-content-between mt-3" style="gap: 10%;">
    <!-- Positive -->
    <div class="table-responsive w-100">
      <ExportExcel :file_name="`${title}_positive.xls`" title="Top 10 Positive Topics">
        <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
          <thead>
            <tr>
              <th v-for="col in cols" :key="col.label"> {{ col.label }} </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, indexRow) in rows.positive" :key="String(row.length) + indexRow">
              <td> {{ row["label"] }} </td>
              <td> {{ row["positive"] }} </td>
              <td> {{ row["score"] }} </td>
            </tr>
          </tbody>
        </table>
      </ExportExcel>
    </div>

    <!-- Negative -->
    <div class="table-responsive w-100">
      <ExportExcel :file_name="`${title}_negative.xls`" title="Top 10 Negative Topics">
        <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
          <thead>
            <tr>
              <th v-for="col in cols" :key="col.label">{{ col.label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, indexRow) in rows.negative" :key="String(row.length) + indexRow">
              <td>{{ row["label"] }}</td>
              <td>{{ row["negative"] }}</td>
              <td>{{ row["score"] }}</td>
            </tr>
          </tbody>
        </table>
      </ExportExcel>
    </div>
  </div>
</template>
