<script>
import PageHeader from "@/components/page-header";
import Filters from "@/components/filter";
import SurveyCard from "./list-survey-card";
import Utils from "@/js/utils";
import DataUtils from "@/js/data-utils";

export default {
	data() {
		return {
			loading: false,
			filterCols: ['companies', 'search', 'survey_status'],
      surveys: [],
      allCompanies: [],
      companies: [],
      searchString: '',
      company_ids: [],
      surveyStatus: null,
      filterParams: {},
      user: null
		};
	},
	components: { PageHeader, Filters, SurveyCard },
  watch: {
    filterParams: function () {
      this.filterSurveys(this.filterParams);
    },
    contract: {
      handler: async function () {
        if (this.contract?.id) {
          this.allCompanies = await this.$store.dispatch("companies/fetch", { contract_id: this.contract.id });
          this.load();
        }
      },
      immediate: true
    }
  },
	methods: {
    ...DataUtils,
    async load() {
      Utils.setLoading.bind(this)(true);
      this.surveys = Utils.deepClone(await this.$store.dispatch("surveys/fetch", { contract_id: this.contract.id }));
      Utils.setLoading.bind(this)(false);
    },
    filterSurveys(params) {
      if (params.survey_status != "all") this.surveyStatus = params.survey_status;
      else this.surveyStatus = null;
      this.searchString = (params?.data.search || "").toUpperCase();
      this.company_ids  = params?.data.company_ids || [];
    },
    async updateList(event) {
      let surveys = [];
      if (event.action == "duplicate") surveys.push(event.survey);
      if (event.action == "delete")    surveys = this.surveys.filter(el => el.id != event.survey.id);

      await this.$store.commit("surveys/saveSurveys", { surveys, contractId: this.contract.id });
      this.surveys = this.$store.getters["surveys/surveys"](this.contract.id);
    }
	},
  computed: {
    filteredSurveys() {
      return this.surveys.filter(survey => {
        if (this.surveyStatus != null && survey.status != this.surveyStatus) return false;
        if (this.searchString.length && !survey.name.toUpperCase().includes(this.searchString)) return false;
        if (this.company_ids.length && survey.company_ids.filter(v => this.company_ids.includes(v)).length == 0) return false;
        return true;
      });
    },
    surveysToShow() {
      return Utils.sortField(this.filteredSurveys, "name");
    },
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
  }
}
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('surveys_list.surveys_list')">
      <template v-slot:subtitle>
        <router-link :to="{ name: 'feedback_new_survey', query: { ...$route.query, tab: 'settings' } }" class="btn btn-primary">
          {{ $t("surveys_list.new_survey") }}
        </router-link>
      </template>
    </PageHeader>

    <Filters @filter:created="saveFilterParams" @filter:change="saveFilterParams" :cols="filterCols" :notifyChange='true' />

    <div class="row surveys mb-5 pb-2" v-if="!loading">
      <template v-for="survey in surveysToShow" :key="survey.id">
        <SurveyCard @list_surveys:update_list="updateList" :survey="survey" :companies="allCompanies" />
      </template>
    </div>

    <div class="row" v-else><div class="col"><div class="loading" style="min-height: 280px;"></div></div></div>
  </div>
</template>
