import axios from "axios";

export const state   = { companies: {}, companiesPromise: {} };
export const getters = {};

export const mutations = {
  saveCompanies(state, { companies, params }) {
    state.companies[params] = companies;
  },
  saveCompaniesPromise(state, { promise, params }) {
    state.companiesPromise[params] = promise;
  }
};

export const actions = {
  async fetch({ commit }, params ) {
    const contract_id  = params.contract_id || "all";
    const stringParams = JSON.stringify({ ...params, contract_id });

    if (state.companies[stringParams])        return state.companies[stringParams];
    if (state.companiesPromise[stringParams]) return state.companiesPromise[stringParams];

    let url = contract_id ? `/v3/contracts/${contract_id}/companies` : `/v3/users/${params.user_id}/companies`;
    if (params.with_competitors) url = "v3/companies";

    let promise = axios.get(url, { params }).then(response => {
      const companies = response.data.data.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: "base" }));
      commit("saveCompanies", { companies, params: stringParams });
      return companies;
    });

    commit("saveCompaniesPromise", { promise, params: stringParams });
    return promise;
  }
}
