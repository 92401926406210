<script>
import axios                from "axios";
import Utils                from "@/js/utils";
import ReportsJS            from "@/js/reports";
import Swal                 from "sweetalert2";
import PageHeader           from "@/components/page-header";
import SettingsModal        from "@/components/reports/settings-modal";
import TemplateSettings     from "../template-settings";
import ComponentsList       from "./components-list";
import Preview              from "../preview";

export default {
  data() {
    return {
      loading: true,
      showSettingsModal: false,
      template: {},
      dt: {},
      componentsList: ReportsJS.templates.componentsList,
      // Settings Options: ['period', 'chart_type', 'n_periods', 'trend_range', 'order_by', 'limit',
      //'filters', 'columns', 'show_ranking', 'row_data', 'columns', 'show_benchmark', 'show_trend'],
      currentComponent: null,
      groups:      {},
      companies:   {},
      group_ids:   this.$route.query.gids ? Array(this.$route.query.gids) : [],
      company_ids: this.$route.query.cids ? Array(this.$route.query.cids) : [],
      start_date:  null,
      end_date:    null,
      settings:    {}
    };
  },
  components: { PageHeader, SettingsModal, TemplateSettings, ComponentsList, Preview },
  mounted() {
    this.importImages()
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  methods: {
    importImages() {
      const r = require.context("@/assets/images/reports/thumbnails", false, /\.(png|jpe?g|svg)$/)
      for (const key of Object.keys(this.componentsList)) {
        this.componentsList[key].icon = r(`./${key}.png`)
      }
    },
    async loadTemplate(id) {
      Utils.setLoading.bind(this)(true);
      const response = await axios.get(`/v3/reports/templates/${id}`, { params: { subscription_id: this.contract.id } });
      this.template = response.data.data
      this.dt = this.template.dashboard_template
      Utils.setLoading.bind(this)(false);
    },
    openSettingsModal(item) {
      if (item) {
        this.currentComponent = item
        item.settings ??= {}
        this.settings = Utils.deepClone(item.settings)
      }

      if (this.componentsList[item.name].settingsFields.keys.length) {
        this.settingsFields    = this.componentsList[item.name].settingsFields
        this.showSettingsModal = true;
      } else {
        this.currentComponent.settings = {};
        setTimeout(() => { this.currentComponent = null },300)
      }
    },
    onSettingsModalClose() {
      if (!Object.keys(this.currentComponent.settings).length) this.$refs.editor?.removeItem(this.currentComponent.id, true);
      this.settings = {};
      setTimeout(() => { this.currentComponent = null },300)
    },
    saveSettings() {
      this.currentComponent.settings = Utils.deepClone(this.settings);
      setTimeout(() => { this.currentComponent = null },300)
    },
    confirmStayInDirtyForm() {
      return !window.confirm(this.$t("new_survey.confirm_leave"));
    },
    beforeWindowUnload(event) {
      if (this.isDirty && this.confirmStayInDirtyForm()) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    save() {
      axios.put(`/v3/dashboard_templates/${this.template.dashboard_template_id}`, this.dt).then(async () => {
        await this.updateReportTemplate();
        this.loadReportParams();
        Swal.fire(this.$t('reports.success'), this.$t('reports.template_saved'), 'info');
      }).catch(() => {
        Swal.fire(this.$t('reports.error'), this.$t('reports.template_not_saved'), 'warning');
      })
    },
    async updateReportTemplate() {
      await axios.put(`/v3/reports/templates/${this.template.id}`, Utils.slice(this.template, ["name", "period", "based_on"]));
    },
    loadCompanies () {
      return this.$store.dispatch("companies/fetch", { contract_id: this.contract.id }).then(companies => {
        this.companies = Utils.groupByUniq(companies, 'id');
      });
    },
    loadGroups () {
      return this.$store.dispatch("groups/fetch", { contract_id: this.contract.id }).then(groups => {
        this.groups = Utils.groupByUniq(groups, "id");
      });
    },
    loadReportParams() {
      if (this.template.based_on == "groups")    this.loadGroups();
      if (this.template.based_on == "companies") this.loadCompanies();
    }
  },
  computed: {
    user() {
      return this.$store.getters["user/user"]() || {};
    },
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    settingsModalTitle() {
      if (this.currentComponent?.name)
        return this.$t("reports.settings_modal.settings_for") + this.$t(("reports.components_name." + this.currentComponent.name));
      return "";
    },
    isDirty() {
      // let diff = this.loadDiff(["html_body", "attachments", "subject"]);
      let diff = {};
      return Object.keys(diff).length > 0;
    },
    groupIds () {
      return Object.keys(this.groups || {})
    },
    companyIds () {
      return Object.keys(this.companies || {})
    }
  },
  watch: {
    contract: {
      handler: async function () {
        const templateId = this.$route.params.id;
        if (templateId && this.contract?.id) {
          Utils.setDashboardStyles(this.contract);
          await this.loadTemplate(templateId);
          this.loadReportParams();
        }
      },
      immediate: true
    }
  }
}
</script>

<template>
  <div class="w-100">
    <div class="reports" v-if="contract && contract.id && !loading">
      <PageHeader :title="$t('reports.edit_report_template')" class="editor editor-fixed-bar">
        <template v-slot:subtitle>
          <div>
            <button type="submit" class="btn btn-primary me-3" @click='save'>{{ $t("reports.save") }}</button>
            <router-link :to="{ name: 'reports_show_template', query: $route.query }" class="btn btn-outline-primary" target="_blank">
              {{ $t("reports.preview") }}&nbsp; &nbsp; <i class="fa fa-external-link-alt"></i>
            </router-link>
          </div>
        </template>
      </PageHeader>

      <!-- Collapsable right component list for tiny screens -->
      <div class="show-side-menu w-100">
        <button class="fixed-button btn btn-secondary font-size-11" data-bs-toggle="offcanvas" data-bs-target="#components-list" aria-controls="components-list">
          <i class="mdi mdi-menu" />&nbsp;{{ $t("reports.components_list") }}
        </button>

        <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="components-list">
          <div class="offcanvas-header d-flex justify-content-start pb-0 pt-4">
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
          </div>
          <div class="offcanvas-body">
            <ComponentsList :list="componentsList" />
          </div>
        </div>
      </div>

      <div class="card editor">
        <div class="card-body py-0">
          <div class="navbar-header shadow-none p-0 px-1">
            <div class="py-3 w-100">
              <h5 class="m-0">{{ $t("reports.template_settings") }}</h5>
              <h6 class="m-0 mt-1">{{ template.name }}</h6>
            </div>
            <button class="btn btn-outline-primary btn-sm float-end chevron" data-bs-toggle="collapse" data-bs-target="#template_settings" aria-expanded="false" aria-controls="template_settings">
              <i class="mdi mdi-18px mdi-cog"></i>
            </button>
          </div>

          <div class="collapse table-responsive overflow-x-hidden" id="template_settings">
            <TemplateSettings v-if="template.id" :template="template" :dt="dt" class="px-1" :showDesignSection="true" />
          </div>
        </div>
      </div>

      <span>{{ $t("reports.edit_report_template_help") }}</span>

      <Preview
        v-if="template && template.dashboard_template"
        ref="editor"
        class="mt-4"
        :editor="true"
        :contract="contract"
        :template="template"
        :dt="dt"
        :componentsList="componentsList"
        :settingsModalOpen="showSettingsModal"
        @openSettingsModal="openSettingsModal"
        :edittedComponent='currentComponent'
        selectorClass="editor mb-4"
      />

      <ComponentsList :list="componentsList" class="hide-side-menu" />
    </div>

    <b-modal centered static hide-footer v-model="showSettingsModal" size="xl" header-bg-variant="light" :title="settingsModalTitle" @hidden="onSettingsModalClose">
      <SettingsModal v-if="showSettingsModal && currentComponent" v-model="showSettingsModal" :settings="settings" :fields="settingsFields" :template='template' @save="saveSettings" />
    </b-modal>
  </div>
</template>
