<script>
import axios from "axios";
import Swal  from "sweetalert2";
import Utils from "@/js/utils";

export default {
	data() {
		return {
			newName: "",
			scrollXOffset: 0,
			modal: false,
			isOverflowing: false,
			popperConfig: {
				modifiers: [{ name: "flip", enabled: false }],
			}
		};
	},
	props: ["survey", "companies"],
	mounted() {
		this.newName = this.survey.name;
	},
	methods: {
		getCompanyName(company_id) {
      let company = this.companies.find(obj => obj.id == company_id);
      return company?.name;
    },
		getNumberResponses(responses_count) {
      let str = this.$t("surveys_list.responses") + ":";
      if (!responses_count?.length) return (str += ` ${this.$t("surveys_list.none")}.`);

      responses_count.forEach((obj) => {
        str += ` ${this.getCompanyName(obj.company_id)} (${Utils.formatNumber(obj.count)})`;
        str += responses_count.indexOf(obj) + 1 === responses_count.length ? "." : ",";
      });
      return str;
    },
		parse(str) {
			let args = [].slice.call(arguments, 1), i = 0;
			return str.replace(/%s/g, () => args[i++]);
		},
		getTotalNumberResponses() {
			let result = 0;
			this.survey.responses_count.forEach(c => result += c.count);
			return result;
		},
		updateStatus(ev) {
			ev.preventDefault();
			let newStatus = this.survey.status == "active" ? "inactive" : "active";
			axios.post(`/v3/feedback/surveys/${this.survey.id}`, {
				status: newStatus,
			})
			.then(res => {
				this.survey.status = res.data.data.status;
				this.success(newStatus);
			})
			.catch(this.error);
		},
		updateName(survey_id) {
      axios.post(`/v3/feedback/surveys/${survey_id}`, {
				name: this.newName,
			})
			.then(() => {
				this.survey.name = this.newName;
				this.success('rename');
			})
			.catch(this.error);
    },
		duplicateSurvey(ev) {
			ev.preventDefault();
      const contract = this.$store.getters["contract/currentContract"]
      axios.post(`v3/feedback/surveys/duplicate/${this.survey.id}`, { subscription_id: contract.id })
			.then(response => {
				this.success('duplicate');
				this.$emit('list_surveys:update_list', { action: 'duplicate', survey: response.data.data });
			})
			.catch(this.error);
		},
		deleteSurvey(ev) {
			ev.preventDefault();
			if(confirm(this.parse(this.$t('surveys_list.delete_confirm'), this.getTotalNumberResponses()))) {
				axios.delete(`v3/feedback/surveys/${this.survey.id}`)
				.then(() => {
					this.success('delete');
					this.$emit('list_surveys:update_list', { action: 'delete', survey: this.survey });
				})
				.catch(this.error);
			}
		},
		openModal(ev) {
			ev.preventDefault();
			this.modal = true;
		},
		success(action) {
			Swal.fire(this.$t('general.success'), this.$t(`surveys_list.${action}_success`), 'success');
			this.modal = false;
		},
    error (error) {
      console.error(error)
      return Swal.fire(this.$t('general.error'), this.$t('general.contact_support') + JSON.stringify(error.message), 'error');
    },
		async overflow() {
			await this.$nextTick();
			let element = this.$refs[this.survey.id];
			this.scrollXOffset = element?.scrollWidth + 20;
			this.isOverflowing = element?.offsetWidth < element?.scrollWidth;
    }
	},
	computed: {
		color() {
			return this.survey.color_hex;
		},
		updateStatusLabel() {
			return this.$t(`surveys_list.update_from_${this.survey.status}`);
		}
	}
}
</script>

<template>
	<router-link v-if="survey.id" class="py-2 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 text-white" :to="{ name: 'feedback_edit_survey', params: { id: survey.id }, query: { ...$route.query } }">
		<div class="custom-card custom-card__bg" :style="color && `--color-hex: ${color};`">
			<div class="d-flex justify-content-end p-0" >
				<div class="side-footer-menu">
					<!-- Floating survey-card's menu -->
					<b-dropdown size="lg" menu-class="dropdown-menu-right" variant="link" toggle-class="text-decoration-none" no-caret :popper-opts="popperConfig">
						<template #button-content>
							<i class="mdi mdi-dots-vertical text-white p-2"></i>
						</template>
						<b-dropdown-item @click="updateStatus">
								{{ updateStatusLabel }}
						</b-dropdown-item>
						<b-dropdown-item :to="{ name: 'feedback_edit_survey', params: { id: survey.id }, query: { ...this.$route.query } }">
							{{ $t("surveys_list.edit") }}
						</b-dropdown-item>
						<b-dropdown-item @click="openModal">
								{{ $t("surveys_list.rename") }}
						</b-dropdown-item>
						<b-modal v-model="modal" :id="`modal.${survey.id}`" centered :title="survey.id" title-class="font-18" hide-footer hide-header size="md" >
							<div class="p-4 d-flex flex-column">
								<h3> {{ $t("surveys_list.rename_modal") }} </h3>

								<div class="form-group mt-4 mb-4">
									<label>{{ $t("surveys_list.name") }}</label>
									<form>
										<div class="position-relative">
											<input type="text" class="form-control" :placeholder="$t('surveys_list.rename_place_holder')" v-model="newName"/>
										</div>
									</form>
								</div>

								<div class="footer-nav d-flex justify-content-between mt-3">
									<button class="btn btn-primary" @click="updateName(survey.id)">
										{{ $t("surveys_list.submit") }}
									</button>

									<button class="btn btn-outline-secondary" @click="modal = false">
										{{ $t("surveys_list.cancel") }}
									</button>
								</div>
							</div>
						</b-modal>

						<b-dropdown-item @click="duplicateSurvey">
								{{ $t("surveys_list.duplicate") }}
						</b-dropdown-item>
						<b-dropdown-item :to="{ name: 'feedback_responses', query: {...$route.query, 'pg': 1, 'dvid': survey.id } }">
								{{ $t("surveys_list.responses") }}
						</b-dropdown-item>
						<b-dropdown-item @click="deleteSurvey">
								{{ $t("surveys_list.delete") }}
						</b-dropdown-item>
					</b-dropdown>
				</div>
			</div>
			<div class="pt-0 pb-4">
				<div class="marquee" :style="`--XOffset: -${scrollXOffset}px;`">
					<h2 :class="{ 'text': overflow() && isOverflowing }" :ref="survey.id">{{ survey.name }}</h2>
					<h2 v-if="overflow() && isOverflowing" class="text" :style="`left: ${scrollXOffset}px;`">{{ survey.name }}</h2>
				</div>
				<p class="font-size-14"> {{ survey.title }} </p>
			</div>
			<div>
				<div class="responses-count">
					<span :title="getNumberResponses(survey.responses_count)">
						{{ getNumberResponses(survey.responses_count) }}
					</span>
				</div>
			</div>
		</div>
	</router-link>
</template>
