<script>
require('@/js/reports')
import Replab from '@/mixins/reports/replab'

import Page from "@/components/reports/replab/page";
import PageHeader from "@/components/reports/replab/page-header";
import PageThankYou from "@/components/reports/replab/page-thank-you";

import TableTripAdvisorPerformance from "@/components/reports/replab/weekly/tables/table-tripadvisor-performance";
import TableOTASummary from "@/components/reports/replab/enterprise-monthly/tables/table-OTA-summary";
import TableReviewResponseRatio from "@/components/reports/replab/enterprise-weekly/tables/table-review-response-ratio";
import TablePerfectScore from "@/components/reports/replab/enterprise-monthly/tables/table-perfect-score-summary";
import TablePropertyDetails from "@/components/reports/replab/month-end/tables/table-property-details";

export default {
  mixins: [ Replab ],

  data() {
    return {
      offset: 20,
    }
  },

  components: {
    PageHeader,
    Page,
    PageThankYou,

    TableTripAdvisorPerformance,
    TableOTASummary,
    TableReviewResponseRatio,
    TablePerfectScore,
    TablePropertyDetails,
  },

  async created() {
    await this.replabInit()
  }
}
</script>

<template>
  <div class="replab" v-if='release_date && group' >
    <PageHeader title="Enterprise Weekly Summary Report" :data="{ company: group, month, year, showDate: true }"></PageHeader>

    <Page :data="{ company: group, day, month, year, type: 'weekly' }">
      <TableTripAdvisorPerformance title="MTD TripAdvisor Performance" :group="group" identifier="replab_mtd_performance" :release_date="release_date"/>
    </Page>

    <Page v-for="index in 3" :key="'OTAS'+index" :index="index" :data="{ company: group, day, month, year, type: 'weekly' }">
      <TableOTASummary :index=index :offset=offset :download=download title="MTD OTA Performance" :group="group" identifier="replab_segmented" :source="sources" :release_date="release_date" :mtd="true" :sourceHash="sourceHash"/>
    </Page>

    <Page :data="{ company: group, day, month, year, type: 'weekly' }">
      <TableReviewResponseRatio title="MTD Review Response Ratio - All Channels" :group="group" identifier="replab_mtd_performance" :source="sources" :release_date="release_date" :mtd="true" :prev_months="1"/>
    </Page>

    <Page :data="{ company: group, day, month, year, type: 'weekly' }">
      <TablePerfectScore title="MTD Percentage of Perfect Score Summary - All Channels" :group="group" identifier="replab_source_review_summary" :release_date="release_date" :source="sources" :mtd="true"/>
    </Page>

    <Page :data="{ company: group, day, month, year, type: 'weekly' }">
      <TablePropertyDetails title="MTD Property Details - All Channels" :group=group identifier=replab_property_details :release_date=release_date :mtd=true :sentimentRatings=sentimentRatings />
    </Page>

    <PageThankYou />
  </div>
</template>
