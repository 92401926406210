<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import Utils from "@/js/utils";
import Swal  from "sweetalert2";

export default {
  data() {
    return {
      loading: false,
      template: {
        company: null,
        survey: null,
        distribution: null,
        predefinedTemplate: null
      },
      attachments: [],
      html_body: null,
      subject: null,
      customerInvitationId: 44,
      distributions: [],
      surveys:   [],
      companies: []
    };
  },
  props: ["modal", "allDistributions", "contract", "distribution"],
  components: { Multiselect },
  created() {
    if (this.distribution) {
      this.template.company = this.distribution.company;
      this.template.survey  = this.distribution.survey;
      this.template.distribution = this.distribution;
    }
  },
  mounted() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeUnmount () {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  watch: {
    allDistributions: {
      handler: function () {
        if (this.allDistributions?.length) {
          this.distributions = this.allDistributions.filter(d => {
            if (Object.keys(d.mail_template_ids).length) return false;
            this.surveys.push(d.survey);
            this.companies.push(d.company);
            return true;
          });
          this.surveys   = [...new Map(this.surveys.map(s => [s.id, s])).values()];
          this.companies = [...new Map(this.companies.map(c => [c.id, c])).values()];
        }
      },
      immediate: true
    }
  },
  methods: {
    async createTemplate() {
      Utils.setLoading.bind(this)(true);
      try {
        let defLocale = this.template.survey.default_locale;
        // load the customer invitation template or use from scratch
        if (/from_scratch/.test(this.template.predefinedTemplate)) {
          this.html_body = "<html><body><h1>Title</h1><p>Content</p></body></html>"
          this.subject   = this.$t("mail_template.list.subject_placeholder", defLocale);
        }
        else {
          let customerInvitation = await axios.get(`/v3/mail_templates/${this.customerInvitationId}`);
          this.attachments = customerInvitation.data.data.attachments;
          this.html_body   = customerInvitation.data.data.html_body;
          this.subject     = customerInvitation.data.data.subject;
        }

        let distribution = this.useDistribution();
        let payload = {
          name:            this.$t("mail_template.list.create_name", defLocale) + ` "${this.template.survey.name}"`,
          language:        distribution?.survey?.default_locale || "en",
          subscription_id: this.contract.id,
          subject:         this.subject,
          html_body:       this.html_body,
          attachments:     this.attachments
        };

        // create the template
        let template = await axios.put("/v3/mail_templates", payload);
        template = template.data.data;

        // associate it to the distribution
        let response = await axios.post(`/v3/feedback/distributions/${distribution.id}`, { mail_template_ids: {
          ...distribution.mail_template_ids,
          [distribution?.survey?.default_locale || "en"]: template.id
        }});

        // save modified distribution to vuex
        await this.$store.commit("distributions/saveDistributions", { distributions: [response.data.data], contractId: this.contract.id });

        // redirect to template edition
        this.$router.push({ name: "feedback_edit_template", query: this.$route.query, params: { id: template.id } });
      } catch (err) { this.error(err); }
      Utils.setLoading.bind(this)(false);
    },
    useDistribution() {
      if (this.distributionsFilter.length == 1) return this.distributionsFilter[0];
      let surveyId  = this.template.survey?.id;
      let companyId = this.template.company?.id;
      return this.distributions.find(d => d.survey.id == surveyId && d.company.id == companyId);
    },
    setPredefinedTemplate(id) {
      this.template.predefinedTemplate = id;
    },
    confirmStayInDirtyForm() {
      return !window.confirm(this.$t("new_survey.confirm_leave"));
    },
    beforeWindowUnload(event) {
      if (this.isDirty && this.confirmStayInDirtyForm()) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    error(error) {
      Swal.fire(this.$t("general.error"), this.$t("general.contact_support") + JSON.stringify(error.response.data), "error");
      Utils.setLoading.bind(this)(false);
    },
    distributionType(distribution) {
      if (distribution) return distribution.type || this.$t("new_survey.share.general");
      return;
    }
  },
  computed: {
    surveysFilter() {
      if (!this.template.company) return this.surveys;

      let surveys = [this.template.company].map(company => {
        return this.surveys.filter(survey => {
          if (this.distributions.find(d => d.survey.id == survey.id && d.company.id == company.id)) return true;
          return false;
        });
      }).flat().filter(Utils.uniq);

      return Utils.sortField(surveys, "name");
    },
    companiesFilter() {
      if (!this.template.survey) return this.companies;

      let companies = [this.template.survey].map(survey => {
        return this.companies.filter(company => {
          if (this.distributions.find(d => d.survey.id == survey.id && d.company.id == company.id)) return true;
          return false;
        });
      }).flat().filter(Utils.uniq);

      return Utils.sortField(companies, "name");
    },
    distributionsFilter() {
      let surveyId  = this.template.survey?.id;
      let companyId = this.template.company?.id;
      return this.distributions.filter(d => d.survey.id == surveyId && d.company.id == companyId);
    },
    validCreateFields() {
      let res = true;
      ["company", "survey", "predefinedTemplate"].forEach(field => {
        if (!this.template[field]) res = false;
      });
      if (!this.useDistribution()) res = false;
      return res;
    },
    isDirty() {
      let res = ["company", "survey"].reduce((res, field) => {
        return res || !!this.template[field];
      }, false);
      if (this.useDistribution()) res = false
      return res;
    }
  }
}
</script>

<template>
  <div class="p-4 d-flex flex-column">
    <h3> {{ $t("mail_template.list.create_modal_title") }} </h3>

    <!-- Company -->
    <div class="mt-3">
      <label class="form-label">{{ $t("mail_template.list.company") }}</label>
      <multiselect v-if="!distribution" :allow-empty="false" :multiple="false" v-model="template.company" :options="companiesFilter" label="name" track-by="name" :showLabels="false" :placeholder="$t('mail_template.list.company_help')" :class="{ 'valid': template.company, 'invalid': !template.company }">
      </multiselect>
      <span v-else><br>{{ template.company.name }}</span>
    </div>

    <!-- Survey -->
    <div class="mt-3">
      <label class="form-label">{{ $t("mail_template.list.survey") }}</label>
      <multiselect v-if="!distribution" :allow-empty="false" :multiple="false" v-model="template.survey" :options="surveysFilter" label="name" track-by="id" :showLabels="false" :placeholder="$t('mail_template.list.survey_help')" :class="{ 'valid': template.survey, 'invalid': !template.survey }">
      </multiselect>
      <span v-else><br>{{ template.survey.name }}</span>
    </div>

    <!-- Distribution -->
    <div class="mt-3" v-if="template.survey && template.company">
      <label class="form-label me-2">{{ $t("mail_template.list.distribution") }}</label>
      <span v-b-tooltip.hover :title="$t('mail_template.list.distribution_tooltip')" class="mt-1">
        <i class="mdi mdi-help-circle"></i>
      </span>
      <template v-if="distributionsFilter.length > 1">
        <multiselect :allow-empty="false" :multiple="false" v-model="template.distribution" :options="distributionsFilter" :custom-label="distributionType" track-by="id" :showLabels="false" :placeholder="$t('mail_template.list.distribution_help')" :class="{ 'valid': template.distribution, 'invalid': !template.distribution }">
        </multiselect>
      </template>
      <span v-else-if="distributionsFilter.length == 1">
        <br>
        {{ distributionType(distributionsFilter[0]) }}
      </span>
    </div>

    <!-- Template -->
    <div class="mt-3">
      <label class="form-label">{{ $t("mail_template.list.template") }}</label>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-1 mb-3">
          <div class="p-3 h-100 border rounded transform-scale d-flex align-items-center justify-content-between" :class="{ 'outline-primary': template.predefinedTemplate == 'from_scratch' }" @click="setPredefinedTemplate('from_scratch')" style="cursor: pointer;">
            <img src="@/assets/images/thumbnails/thumb-empty.png" class="img-thumbnail">
            <div class="ms-3">
              <h5>{{ $t("mail_template.list.create_own") }}</h5>
              <p>{{ $t("mail_template.list.create_own_help") }}</p>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-1 mb-3">
          <div class="p-3 h-100 border rounded transform-scale d-flex align-items-center justify-content-between" :class="{ 'outline-primary': template.predefinedTemplate == 'customer_invitation' }" @click="setPredefinedTemplate('customer_invitation')" style="cursor: pointer;">
            <img src="@/assets/images/thumbnails/thumb.png" class="img-thumbnail">
            <div class="ms-3">
              <h5>{{ $t("mail_template.list.customer_invitation") }}</h5>
              <p>{{ $t("mail_template.list.customer_invitation_help") }}</p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="footer-nav d-flex justify-content-between mt-3">
      <button class="btn btn-outline-secondary" @click="modal.newTemplateModal = false">
        {{ $t("mail_template.list.cancel") }}
      </button>

      <button class="btn btn-primary" :disabled="!validCreateFields" @click="createTemplate">
        {{ $t("mail_template.list.create") }}
      </button>
    </div>
  </div>
</template>