<script>
import ratings   from '@/js/ratings'
import Utils     from '@/js/utils'
import Reports   from "@/js/reports";
import NotLoaded from "@/components/not-loaded";
import Loading   from "@/components/loading";

export default {
  data() {
    return {
      loading: true,
      error: false,
      tableData: [],
      ratings,
      Utils,
      reports: Reports,
      with_response_rate: true
    }
  },
  props: ['start_date', 'end_date', 'settings', 'group_id', 'group_ids', 'company_id'],
  components: { NotLoaded, Loading },
  methods: {
    ...Reports.requests,
    async load() {
      try {
        this.loading = true
        this.error = false
        if (!this.settings.use_custom_benchmark)
          this.settings.bench_group_ids = this.group_ids
        let r          = await this.getReports('generic')
        this.tableData = r?.slice(1)
        this.loading = false
      } catch {
        this.loading = false
        this.error = true
      }
    }
  },
  async mounted() {
    this.load()
  }
}
</script>
<template>
  <div>
    <table class="table table-bordered table-sm" v-if="tableData && tableData.length">
      <thead>
        <tr class="text-center">
          <th>{{ $t('reports.ranking') }}</th>
          <th class="text-left">{{ $t(`reports.settings_modal.segment_options.${this.settings.segment}`) }}</th>
          <th v-if='!settings.shownColumns || settings.shownColumns.reviews_count'>{{ settings.labels?.reviews_count || $t('reports.table.reviews_count') }}</th>
          <th v-if='!settings.shownColumns || settings.shownColumns.reviews_count_trend'>{{ settings.labels?.trend || $t('reports.table.trend_yoy') }}</th>
          <th v-if='!settings.shownColumns || settings.shownColumns.numerical_rating'>{{ settings.labels?.numerical_rating || $t('reports.table.numerical_rating') }}</th>
          <th v-if='!settings.shownColumns || settings.shownColumns.numerical_rating_trend'>{{ settings.labels?.trend || $t('reports.table.trend_yoy') }}</th>
          <th v-if='!settings.shownColumns || settings.shownColumns.response_score'>{{ settings.labels?.response_score || $t('reports.table.response_score') }}</th>
          <th v-if='!settings.shownColumns || settings.shownColumns.response_score_trend'>{{ settings.labels?.trend || $t('reports.table.trend_yoy') }}</th>
          <template v-if='settings.bench_group_ids' >
            <template v-for="benchName in reports.uniqueBenchmarkNames(tableData, 4)" :key="benchName">
              <th>{{ benchName }}</th>
            </template>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="(row, index) in tableData" :key="row">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-left">{{ row[1] }}</td>

          <!-- reviews_count -->
          <td v-if='!settings.shownColumns || settings.shownColumns.reviews_count'>{{ row[3]?.current?.reviews || '-' }}</td>
          <!-- reviews_count yoy -->
          <td v-if='row[3] && row[3].current && (!settings.shownColumns || settings.shownColumns.reviews_count_trend)' :style="{ color: row[3].current.reviews < (row[3]?.yoy?.reviews || 0) ? 'red' : 'green' }">
            {{ reports.percentDelta(row[3].current.reviews, row[3]?.yoy?.reviews || 0) }}
          </td>
          <td v-else-if='(!settings.shownColumns || settings.shownColumns.reviews_count_trend)'>-</td>

          <!-- numerical_rating -->
          <td v-if='row[3]  && row[3].current && (!settings.shownColumns || settings.shownColumns.numerical_rating)' :class="Utils.ratings.toCss100(row[3].current.overall_rating)">
            {{ ratings.format(row[3].current.overall_rating / 10) }}
          </td>
          <td v-else-if='(!settings.shownColumns || settings.shownColumns.numerical_rating)'>-</td>
          <!-- ratings yoy -->
          <td v-if='row[3] && row[3].current && (!settings.shownColumns || settings.shownColumns.numerical_rating_trend)' :style="{ color: row[3].current.overall_rating < (row[3]?.yoy?.overall_rating || 0) ? 'red' : 'green' }">
            {{ reports.percentDelta(row[3].current.overall_rating, (row[3]?.yoy?.overall_rating || 0)) }}
          </td>
          <td v-else-if='(!settings.shownColumns || settings.shownColumns.numerical_rating_trend)'>-</td>

          <!-- response_score -->
          <td v-if='row[3] && row[3].current && (!settings.shownColumns || settings.shownColumns.response_score)'>
            {{ reports.percentFormat(row[3].current.response_rate) }}
          </td>
          <td v-else-if='(!settings.shownColumns || settings.shownColumns.response_score)'>-</td>
          <!-- response_score yoy -->
          <td v-if='row[3] && row[3].current && (!settings.shownColumns || settings.shownColumns.response_score_trend)' :style="{ color: row[3].current.response_rate < (row[3]?.yoy?.response_rate || 0) ? 'red' : 'green' }">
            {{ reports.percentDelta(row[3].current.response_rate, row[3]?.yoy?.response_rate || 0) }}
          </td>
          <td v-else-if='(!settings.shownColumns || settings.shownColumns.response_score_trend)'>-</td>

          <template v-if='settings.bench_group_ids' >
            <template v-for="benchName in reports.uniqueBenchmarkNames(tableData, 4)" :key="benchName">
              <td v-if="row[4]?.find(bench => bench.name === benchName)">
                {{ ratings.format(row[4].find(bench => bench.name === benchName)?.data?.current?.overall_rating / 10) }}
              </td>
              <td v-else>-</td>
            </template>
          </template>
        </tr>
      </tbody>
    </table>

    <Loading v-else-if="loading" />
    <NotLoaded :data="tableData" :error="error" v-else-if="!loading" />
  </div>
</template>
