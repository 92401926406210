<script>
export default {
  props: ['settings'],
  data() {
    return {
      imageLoaded: false,
      dynamicWidth: 'auto',
      dynamicHeight: 'auto',
    };
  },
  watch: {
    settings: 'updateImageSize',
  },
  methods: {
    updateImageSize() {
      if (this.settings.width && this.settings.height) {
        console.warn('Both width and height are provided. Aspect ratio may not be maintained.');
        this.dynamicWidth = 'auto';
        this.dynamicHeight = 'auto';
      } else {
        this.$nextTick(() => {
          if (this.imageLoaded) {
            if (this.settings.width) {
              this.dynamicWidth = `${this.settings.width}px`;
            }
            else {
              this.dynamicWidth = 'auto';
            }

            if (typeof this.settings.height === 'number') {
              this.dynamicHeight = `${this.settings.height}px`;
            } else {
              this.dynamicHeight = 'auto';
            }
          }
        });
      }
    },
    onImageLoad() {
      this.imageLoaded = true;
      this.updateImageSize();
    },
  },
};
</script>

<template>
  <img
    ref="image"
    v-if="settings.image_url"
    :src="settings.image_url"
    :style="{ width: dynamicWidth, height: dynamicHeight, maxWidth: '991px' }"
    :alt="settings.image_alt"
    @load="onImageLoad"
  />
</template>
