<script>
import Utils from "@/js/utils";
import dayjs from "dayjs";

export default {
  data() {
    return {
      loading: true,
      data: {}
    };
  },
  props: ["settings", "start_date", "end_date", "company_id", "group_id", "group_ids"],
  watch: {
    contract: {
      handler: function () {
        if (this.contract?.id) this.load();
      },
      immediate: true
    }
  },
  methods: {
    ...Utils.kpis,
    async load() {
      Utils.setLoading.bind(this)(true);

      await Utils.asyncForEach(["data", "benchmark"], async sub_context => {
        let params = { ...Utils.deepClone(this.computedSettings), sub_context };
        let fetchFrom = "executive";

        if (this.computedSettings.context != "dashboard") {
          if (sub_context == "benchmark") params = { ...params, ...params.benchmark };
          fetchFrom = "kpi";
        }

        let response = await this.$store.dispatch(`kpis/${fetchFrom}`, params);
        this.data[sub_context] = this.saveData(response, this.computedSettings);
      });

      Utils.setLoading.bind(this)(false);
    },
    tdClass(value) {
      return Utils.ratings.toHex(value);
    },
    truncate(value) {
      return Utils.formatNumber(Utils.round(value))
    },
    customTooltip({ series, seriesIndex }) {
      return `<div class="arrow_box p-1 ${Utils.ratings.toCss100(series[seriesIndex])}">
                <span>${this.label[seriesIndex]}: ${Utils.round(series[seriesIndex]/10)}</span>
              </div>`;
    },
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    computedSettings() {
      let benchmark = {}
      if (this.settings.benchmark_based_on == "companies") {
        benchmark.company_ids = this.settings.bench_company_ids
      } else {
        if (this.settings.use_custom_benchmark)
          benchmark.group_ids = this.settings.bench_group_ids
        else
          benchmark.group_ids = this.group_ids
      }


      return {
        ratingType:       this.settings.segment,
        subscription_ids: this.contract?.id,
        start_date:       this.start_date ? dayjs(this.start_date).format("YYYY-MM-DD") : undefined,
        end_date:         this.end_date   ? dayjs(this.end_date).format("YYYY-MM-DD")   : undefined,
        company_ids:      this.company_id,
        group_ids:        this.group_id,
        trends:           this.settings.trend_range || "yoy",
        benchmark,
        ...this.settings
      };
    },
    chartOptions() {
      return {
        chart: {
          animations: {
            enabled: true,
            easing: "linear",
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 1500
            }
          },
          dropShadow: {
            enabled: true,
            enabledOnSeries: [1, 2],
            top: -8,
            left: 8,
            blur: 10,
            color: "#000",
            opacity: 0.1
          }
        },
        grid: { padding: { top: -50, bottom: -40, left: -50, right: -50 } },
        tooltip: {
          enabled: true,
          highlightDataSeries: true,
          custom: this.customTooltip,
        },
        labels: ["rating", "benchmark"],
        colors: [({ value }) => this.tdClass(value / 10), ({ value }) => this.tdClass(value / 10)],
        legend: { show: false },
        dataLabels: { enabled: false },
        plotOptions: {
          radialBar: {
            inverseOrder: false,
            startAngle: 0,
            endAngle: 360,
            hollow: {
              margin: 0,
              size: "60%",
              background: "transparent",
            },
            track: {
              show: true,
              background: "#f2f2f2",
              strokeWidth: "97%",
              opacity: 1,
              margin: 5,
              dropShadow: {
                enabled: false,
                top: -10,
                left: 10,
                blur: 8,
                opacity: 0.1
              }
            },
            dataLabels: { show: false },
          },
        },
        states: { hover: { filter: { type: "darken", value: 0.45 } } },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "round",
          width: 10,
          dashArray: 0,
        }
      };
    },
    computedData() {
      const rp = this.computedSettings.ratingType;
      return {
        data:      this.data.data.current[rp]      || {},
        benchmark: this.data.benchmark.current[rp] || {}
      };
    },
    series() {
      return [
        this.computedData.data.value * 10,
        this.computedData.benchmark.value * 10
      ].filter(n => n);
    },
    labelHash() {
      return {
        data:      this.$t("executive_kpis.rating"),
        benchmark: this.$t("executive_kpis.benchmark")
      }
    },
    label() {
      return Object.keys(this.labelHash).map(k => this.computedData[k] ? this.labelHash[k] : null).filter(n => n);
    },
    fontColor() {
      return {
        data:      this.tdClass(this.computedData.data.value),
        benchmark: this.tdClass(this.computedData.benchmark.value)
      }
    },
    noData() {
      return !this.loading && !this.computedData.data?.value && !this.computedData.benchmark?.value;
    }
  }
}
</script>

<template>
  <div class="executive-kpi d-flex flex-column h-100" :class="{ loading }">
    <!-- Card with KPI title and tooltip -->
    <div class="title-indicator" @click="redirect(computedSettings, data.data)">
			<div class="card text-center m-0 custom-shadow">
				<div class="card-body d-flex justify-content-center align-items-center" :title="$t('executive_kpis.see_in_more_detail')">
					<h5 class="card-title m-0">{{ $t(`executive_kpis.${computedSettings.segmentOption}`) }}</h5>
					<span class="ms-2 mt-1" v-b-tooltip.hover :title="$t(`executive_kpis.help.${computedSettings.segmentOption}`)">
						<i class="mdi mdi-information-outline"></i>
					</span>
				</div>
			</div>
		</div>

    <a href="#" v-if="computedSettings.redirect" :title="$t('executive_kpis.see_in_more_detail')" class="text-end mt-3 me-2" @click="redirect(computedSettings, data.data)">
      <i class="font-size-20 fa fa-external-link-alt"></i>
    </a>

    <div v-if="noData" class="d-flex align-items-center justify-content-center h-100">
      <div class="alert alert-warning d-flex alert-box align-items-center px-4" role="alert">
        <div class="d-flex align-items-center">
          <i class="mdi mdi-magnify-remove-outline font-size-24 me-2"></i>
          <span class="font-size-16 mb-0">{{ $t("general.no_data") }}</span>
        </div>
      </div>
    </div>

		<div class="visualization-donut" v-else-if="!loading">
      <div class="numerical-data mt-2 d-flex flex-column justify-content-center align-items-center">
        <div class="current mb-1 d-flex flex-column justify-content-center align-items-center" v-if="data.data">
          <p class="m-0">{{ $t("executive_kpis.rating") }}</p>
          <div class="d-flex justify-content-center align-items-center">
            <h2 class="mb-0 me-3" :style="`color: ${fontColor.data};`">{{ truncate(computedData.data.value) }}</h2>
            <!-- trend arrow -->
            <template v-if="computedSettings.show_trend">
              <div v-if="computedData.data.trend" class="trend d-flex flex-column justify-content-center align-items-center"
              :class="`text-${computedData.data.trend >= 0 ? 'success' : 'danger'}`">
                <i
                  class="arrow-trend"
                  :class="{ 'mdi mdi-arrow-up': computedData.data.trend >= 0, 'mdi mdi-arrow-down': computedData.data.trend < 0 }"
                ></i>
                <p class="m-0"> {{ truncate(computedData.data.trend) + "%" }} </p>
              </div>
              <div v-else>-</div>
            </template>
          </div>
        </div>

        <div class="divider border-bottom border-dark"></div>

        <div class="benchmark_current mt-1 d-flex flex-column justify-content-center align-items-center" v-if="data.benchmark">
          <p class="m-0"> {{ $t("executive_kpis.benchmark") }} </p>
          <div class="d-flex justify-content-center align-items-center">
            <h2 class="mb-0 me-3" :style="`color: ${fontColor.benchmark};`">{{ truncate(computedData.benchmark.value) }}</h2>
            <!-- trend arrow -->
            <template v-if="computedSettings.show_trend">
              <div v-if="computedData.benchmark.trend" class="trend d-flex flex-column justify-content-center align-items-center"
              :class="`text-${computedData.benchmark.trend >= 0 ? 'success' : 'danger'}`">
                <i
                  class="arrow-trend"
                  :class="{ 'mdi mdi-arrow-up': computedData.benchmark.trend >= 0, 'mdi mdi-arrow-down': computedData.benchmark.trend < 0 }"
                ></i>
                <p class="m-0"> {{ truncate(computedData.benchmark.trend) + "%" }} </p>
              </div>
              <div v-else>-</div>
            </template>
          </div>
        </div>
      </div>

			<apexchart
        :ref="`${computedSettings.segmentOption}_chart`"
        class="apex-charts rotate-90"
        type="radialBar"
        :series="series"
        :options="chartOptions"
      ></apexchart>
		</div>
  </div>
</template>
