<script>
require('@/js/reports')
import Replab from '@/mixins/reports/replab'

import Page from "@/components/reports/replab/page";
import PageHeader from "@/components/reports/replab/page-header";
import PageSummary from "@/components/reports/replab/enterprise-monthly/page-summary";
import PageThankYou from "@/components/reports/replab/page-thank-you";

import TableOverallTrends from "@/components/reports/replab/enterprise-monthly/tables/table-overall-trends";
import TablePropertyDetails from "@/components/reports/replab/month-end/tables/table-property-details";
import TablePerfectScore from "@/components/reports/replab/enterprise-monthly/tables/table-perfect-score-summary";
import TableReviewResponse from "@/components/reports/replab/month-end/tables/table-review-response";
import TableReviewLandscape from "@/components/reports/replab/enterprise-monthly/tables/table-review-landscape";
import TableTrendingTopics from "@/components/reports/replab/enterprise-monthly/tables/table-trending-topics";
import TableSourceReviewSummary from "@/components/reports/replab/month-end/tables/table-source-review-summary";
import TableTAPerformanceComparison from "@/components/reports/replab/enterprise-monthly/tables/table-TA-performance-comparison";
import TableTAExecutiveSummary from "@/components/reports/replab/enterprise-monthly/tables/table-TA-executive-summary";
import TableOTASummary from "@/components/reports/replab/enterprise-monthly/tables/table-OTA-summary";

export default {
  mixins: [ Replab ],

  data() {
    return {
      previousMonth: true,
      offset:        20,
    }
  },

  components: {
    PageHeader,
    PageSummary,
    Page,
    PageThankYou,

    TableOverallTrends,
    TablePropertyDetails,
    TablePerfectScore,
    TableReviewResponse,
    TableReviewLandscape,
    TableTrendingTopics,
    TableSourceReviewSummary,
    TableTAPerformanceComparison,
    TableTAExecutiveSummary,
    TableOTASummary,
  },

  async created() {
    await this.replabInit()
  }
}
</script>

<template>
  <div class="replab" v-if='release_date && group' >
    <PageHeader title="Enterprise-Month-End-Report" :data="{ company: group, month, year, showDate: true }"></PageHeader>

    <PageSummary :data="{ company: group, month, year }" />

    <Page v-for="index in 3" :key="'OT'+index" :index="index" :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableOverallTrends :index=index :offset=offset :download=download title="Overall Trends" :group="group" identifier="replab_source_review_summary" :release_date="release_date" compset="primary" />
    </Page>

    <Page :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TablePropertyDetails title="Property Details - All Channels" :group=group identifier=replab_property_details :release_date=release_date :sentimentRatings=sentimentRatings />
    </Page>

    <Page :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TablePerfectScore title="Perfect Score Summary" :group=group identifier=replab_source_review_summary :release_date=release_date />
    </Page>

    <Page :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableReviewResponse title="Review Response Coverage by Channel" :group="group" identifier="replab_review_response_coverage_by_channel" :source="sources" :release_date="release_date" :sourceHash="sourceHash"/>
    </Page>

    <Page :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableReviewLandscape title="Review Landscape Current Month" :group="group" identifier="replab_review_response_coverage_by_channel" :source="sources" :release_date="release_date" :months_ago="1" :smly="false" :last_month="false" />
    </Page>

    <Page :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableReviewLandscape title="Review Landscape Same Month Last Year" :group="group" identifier="replab_review_response_coverage_by_channel" :source="sources" :release_date="release_date" :months_ago="1" :smly="true" :last_month="false" />
    </Page>

    <Page :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableReviewLandscape title="Review Landscape Previous Month" :group="group" identifier="replab_review_response_coverage_by_channel" :source="sources" :release_date="release_date" :months_ago="2" :smly="false" :last_month="true" />
    </Page>

    <Page :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableTrendingTopics title="Positive Trending Topics - Current Month" :group="group" identifier="replab_trending_topics" :source="sources" :release_date="release_date" :months_ago="1" :last_month="false" polarity="positive" />
    </Page>

    <Page :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableTrendingTopics title="Negative Trending Topics - Current Month" :group="group" identifier="replab_trending_topics" :source="sources" :release_date="release_date" :months_ago="1" :last_month="false" polarity="negative" />
    </Page>

    <Page v-for="index in 2" :key="'TARS'+index" :index="index" :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableSourceReviewSummary :index=index :offset=offset :download=download title="TripAdvisor Review Summary" :group="group" identifier="replab_source_review_summary" :source="['trip_advisor']" :last_12m="false" :release_date="release_date" :last_12m_col="true" :pagination="true"/>
    </Page>

    <Page v-for="index in 2" :key="'TARSLY'+index" :index="index" :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableSourceReviewSummary :index=index :offset=offset :download=download title="TripAdvisor Review Summary Last Year" :group="group" identifier="replab_source_review_summary" :source="['trip_advisor']" :last_12m="true" :release_date="release_date" :last_12m_col="false" :pagination="true"/>
    </Page>

    <Page :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableTAPerformanceComparison title="TripAdvisor Performance Comparison" :group="group" identifier="replab_overall_review_metrics" :source="['trip_advisor']" :release_date="release_date"/>
    </Page>

    <Page v-for="index in 2" :key="'BRS'+index" :index="index" :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableSourceReviewSummary :index=index :offset=offset :download=download title="Booking.com Review Summary" :group="group" identifier="replab_source_review_summary" :source="['booking_com']" :last_12m="false" :release_date="release_date" :last_12m_col="true" :pagination="true"/>
    </Page>

    <Page v-for="index in 2" :key="'BRSLY'+index" :index="index" :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableSourceReviewSummary :index=index :offset=offset :download=download title="Booking.com Review Summary Last Year" :group="group" identifier="replab_source_review_summary" :source="['booking_com']" :last_12m="true" :release_date="release_date" :last_12m_col="false" :pagination="true"/>
    </Page>

    <Page v-for="index in 2" :key="'ERS'+index" :index="index" :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableSourceReviewSummary :index=index :offset=offset :download=download title="Expedia Review Summary" :group="group" identifier="replab_source_review_summary" :source="['expedia']" :last_12m="false" :release_date="release_date" :last_12m_col="true" :pagination="true"/>
    </Page>

    <Page v-for="index in 2" :key="'ERSLY'+index" :index="index" :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableSourceReviewSummary :index=index :offset=offset :download=download title="Expedia Review Summary Last Year" :group="group" identifier="replab_source_review_summary" :source="['expedia']" :last_12m="true" :release_date="release_date" :last_12m_col="false" :pagination="true"/>
    </Page>

    <Page v-for="index in 2" :key="'HRS'+index" :index="index" :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableSourceReviewSummary :index=index :offset=offset :download=download title="Hotels.com Review Summary" :group="group" identifier="replab_source_review_summary" :source="['hotels_com']" :last_12m="false" :release_date="release_date" :last_12m_col="true" :pagination="true"/>
    </Page>

    <Page v-for="index in 2" :key="'HRSLY'+index" :index="index" :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableSourceReviewSummary :index=index :offset=offset :download=download title="Hotels.com Review Summary Last Year" :group="group" identifier="replab_source_review_summary" :source="['hotels_com']" :last_12m="true" :release_date="release_date" :last_12m_col="false" :pagination="true"/>
    </Page>

    <Page v-for="index in 2" :key="'GRS'+index" :index="index" :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableSourceReviewSummary :index=index :offset=offset :download=download title="Google Review Summary" :group="group" identifier="replab_source_review_summary" :source="['google']" :last_12m="false" :release_date="release_date" :last_12m_col="true" :pagination="true"/>
    </Page>

    <Page v-for="index in 2" :key="'GRSLY'+index" :index="index" :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableSourceReviewSummary :index=index :offset=offset :download=download title="Google Review Summary Last Year" :group="group" identifier="replab_source_review_summary" :source="['google']" :last_12m="true" :release_date="release_date" :last_12m_col="false" :pagination="true"/>
    </Page>

    <Page v-for="index in 3" :key="'TAES'+index" :index="index" :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableTAExecutiveSummary :index=index :offset=offset :download=download title="TripAdvisor Executive Summary" :group="group" identifier="replab_source_review_summary" :source="['trip_advisor']" :release_date="release_date"/>
    </Page>

    <Page v-for="index in 3" :key="'OTAS'+index" :index="index" :data="{ company: group, day, month, year, type: 'enterprise_monthly' }">
      <TableOTASummary :index=index :offset=offset :download=download title="OTA Summary" :group="group" identifier="replab_segmented" :source="sources" :release_date="release_date" :sourceHash="sourceHash"/>
    </Page>

    <PageThankYou />
  </div>
</template>
