<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      tableData: [],
      cols: [
        { field: "name",     label: "Property Name" },
        { field: "subfield", label: "" }
      ],
      fields: [
        { field: "average_rating", label: "Avg. Review Score" },
        { field: "reviews",        label: "Number of Reviews" },
        { field: "5star_percent",  label: "Perfect Reviews" },
      ],
      rows: []
    };
  },
  components: { ExportExcel },
  props: ["title", "company", "group", "identifier", "source", "last_12m", "release_date", "perfect_score", "last_12m_col", "pagination", "index", "offset", "mtd"],

  mounted() {
    window.addEventListener(`source-review-summary-update-${this.title}`, this.loadListener)
  },
  beforeUnmount() {
    window.removeEventListener(`source-review-summary-update-${this.title}`, this.loadListener)
  },

  created() {
    if (!this.pagination || this.index == 1) {
      axios.get("/v3/query/db/_", { params: {
        ...(this.company) && { company_id: this.company.id },
        ...(this.group) && { group_id: this.group.id },
        identifier:   this.identifier,
        source:       this.source,
        last_12m:     this.last_12m,
        release_date: this.release_date,
        fill_12m: true,
        compset:  "primary",
        format:   "hash",
        mtd:      this.mtd,
      }}).then(response => {
        this.tableData = response.data.data;
        this.formatData();
      });
    }
  },
  methods: {
    loadListener(data) {
      this.rows = window.reports.rowsOffset(this, data.detail)
      this.cols = data.detail.cols;
    },

    async formatData() {
      let seenDate = [];
      let seenCompany = [];
      this.tableData.forEach(data => {
        if (!seenDate.includes(data.date_label)) {
          if(data.date_label == "cumulative") this.cols.push({ field: data.date_label, date: "cumulative", label: "Cumulative" });
          else if(this.cols.length < 14) this.cols.push({ field: data.date_label, date: data.date, label: data.date_label });
          seenDate.push(data.date_label)
        }

        if (!seenCompany.includes(data.name)) {
          this.rows.push({ name: data.name, subfield: "average_rating" });
          this.rows.push({ name: "", subfield: "reviews" });
          if(this.perfect_score) this.rows.push({ name: "", subfield: "5star_percent" });
          seenCompany.push(data.name);
        }
      });
      if (this.last_12m_col) {
        this.cols.splice(2, 0, { field: "last_12m", date: "last_12m", label: "Previous 12 Months" });
        let previous12m = await axios.get("/v3/query/db/_", { params: {
          ...(this.company) && { company_id: this.company.id },
          ...(this.group)   && { group_id:   this.group.id },
          identifier:   this.identifier,
          source:       this.source,
          last_12m:     true,
          release_date: this.release_date,
          fill_12m: true,
          period:   "merged",
          compset:  "primary",
          format:   "hash",
          mtd:      this.mtd
        }});
        previous12m = previous12m.data.data.filter(d => d.date_label != "cumulative").map(d => {
          return { ...d, date_label: "last_12m", date: "last_12m" };
        });
        this.tableData = this.tableData.concat(previous12m);
      }

      let currentCompany = "";
      this.rows.forEach((row, index) => {
        let element = {};
        if (row.name.length > 0) {
          currentCompany = row.name;
          element.name = currentCompany;
          element.rowspan = 2;
        }

        let company = this.tableData.filter(e => e.name == currentCompany);
        this.cols.forEach(col => {
          if(col.date) {
            let value = company.find(c => c.date_label == col.field)?.[row.subfield];
            if (!value && value != 0) element[col.field] = "-";
            else if (row.subfield == "average_rating") element[col.field] = Utils.round(value, 2);
            else if (row.subfield == "5star_percent") element[col.field] = value + "%";
            else element[col.field] = value;
            element["subfield"] = this.fields.find(f => f.field == row.subfield)?.label;
          }
        });
        this.rows[index] = element;
      });
      this.$forceUpdate();
      if (this.pagination) {
        const event = new CustomEvent(`source-review-summary-update-${this.title}`, { detail: { rows: this.rows, cols: this.cols } });
        window.dispatchEvent(event);
      }
      this.rows = this.rows.slice(0, this.offset);
    }
  },
  computed: {
    computedTitle() {
      let title = this.title;
      if (this.index && this.index != 1) return title += " (Continued)";
      return title;
    }
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${computedTitle}.xls`" :title="computedTitle">
      <table border="1" class="table table-bordered table-nowrap mb-0 text-center" :class="{ 'table-striped-triple': perfect_score, 'table-striped-pair': !perfect_score }">
        <thead>
          <tr>
            <th class="property-name-column">{{ cols[0].label }}</th>
            <th v-for="col in cols.slice(1)" :key="col.field">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, indexRow) in rows" :key="JSON.stringify(row) + indexRow">
            <td class="property-name-column">{{ row[cols[0].field] }}</td>
            <template v-for="col in cols.slice(1)" :key="JSON.stringify(row) + col.field">
              <td>{{ row[col.field] }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
