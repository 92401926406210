<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      tableData: [],
      cols: [
        { field: "row_name", label: ""          },
        { field: "12months", label: "Previous 12 months" }
      ],
      fields: [
        { field: "average_rating", label: "Avg. Review Score" },
        { field: "avg_rating_last_year", realField: "average_rating", label: "Avg. Review Score Last Year" },
        { field: "reviews_5star", label: "5-Star Reviews" },
        { field: "percent_5star", label: "Percent" },
        { field: "reviews_4star", label: "4-Star Reviews" },
        { field: "percent_4star", label: "Percent" },
        { field: "reviews_3star", label: "3-Star Reviews" },
        { field: "percent_3star", label: "Percent" },
        { field: "reviews_2star", label: "2-Star Reviews" },
        { field: "percent_2star", label: "Percent" },
        { field: "reviews_1star", label: "1-Star Reviews" },
        { field: "percent_1star", label: "Percent" },
        { field: "reviews", label: "Total Reviews" },
        { field: "reviews_last_year", realField: "reviews", label: "Total Reviews Last Year" },
      ],
      rows: []
    };
  },
  components: { ExportExcel },
  props: ["title", "company", "identifier", "release_date", "source"],
  created() {
    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      source:  this.source,
      compset: false,
      format:  'hash',
    }}).then(response => {
      this.tableData = response.data.data;

      if (this.source == 'trip_advisor')
        this.fields = this.fields.concat([
          { field: "ranking", label: "Rank" },
          { field: "ranking_last_year", realField: "ranking", label: "Ranking Last Year" },
        ])

      let seen = [];
      this.tableData.forEach(data => {
        if (data.period != "previous" && !seen.includes(data.date_label)) {
          let label = data.date_label == "cumulative" ? "Cumulative" : data.date_label;
          this.cols.push({ field: data.date_label, label: label });
          seen.push(data.date_label)
        }
      });

      this.fields.forEach(rowField => {
        let row = { [rowField.field]: rowField.label };
        this.cols.forEach(col => {
          let data = null;
          if (rowField.realField) {
            data = this.tableData.find(t => t.date_label.includes(col.field.substring(0, 3)) && t.period == "previous");
            if (data?.[rowField.realField] != null) {
              if (/ranking/.test(rowField.realField) && col.field == "12months")
                data = "";
              else if (/average_rating/.test(rowField.realField))
                data = Utils.round(data[rowField.realField], 2);
              else if (rowField.realField == "ranking") data = "#" + data[rowField.realField];
              else if (/percent/.test(rowField.realField)) {
                data = Utils.round(data[rowField.realField], 2);
                data = data + "%";
              }
              else data = data[rowField.realField];
            } else data = "-";
          }
          else {
            data = this.tableData.find(t => t.date_label == col.field);
            if (data?.[rowField.field] != null) {
              if (/average_rating|^reviews$|ranking/.test(rowField.field) && col.field == "12months")
                data = "";
              else if (/average_rating/.test(rowField.field))
                data = Utils.round(data[rowField.field], 2);
              else if (rowField.field == "ranking") data = "#" + data[rowField.field];
              else if (/percent/.test(rowField.field)) {
                data = Utils.round(data[rowField.field], 2);
                data = data + "%";
              }
              else data = data[rowField.field];
            } else data = "-";
          }

          if (col.field == "row_name") row[col.field] = rowField.label;
          else row[col.field] = data;
        });
        this.rows.push(row);
      });
    });
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-striped-pair table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th v-for="col in cols" :key="col.field">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, indexRow) in rows" :key="JSON.stringify(row) + indexRow">
            <template v-for="col in cols" :key="JSON.stringify(row) + col.field">
              <td>{{ row[col.field] }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
