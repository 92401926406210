<script>
export default {
  data() {
    return {};
  }
}
</script>

<template>
  <div>
    <div class="mb-3">
      <h4>Welcome to Reply to Your Review</h4>
      This panel allows you to customise a prompt sent to OpenAI for generating a response to your review. By pressing the "Generate AI Response" button in the bottom right, the pre-selected settings will be used to formulate a response for the review. Please find the explanation section below.
    </div>

    <div class="accordion">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#section-1" aria-expanded="true" aria-controls="section-1">
            Review
          </button>
        </h2>

        <div id="section-1" class="accordion-collapse collapse">
          <div class="accordion-body">
            In this section, the review content is displayed. This includes the source and other information as available from the source. This section is not interactive.
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#section-2" aria-expanded="false" aria-controls="section-2">
            Reply Text
          </button>
        </h2>
        <div id="section-2" class="accordion-collapse collapse">
          <div class="accordion-body">
            This section displays the management response. From this section, a response to the review can either be posted directly or copied and redirected to the appropriate source for authentication. Perform this action via the button in the bottom right. This section is interactive with the "Saved Responses" section to either publish saved responses or, alternatively, save generated responses. Use the "Save as Template" option or the 'forward text' icon from the "Saved Responses" section to perform these actions.
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#section-3" aria-expanded="false" aria-controls="section-3">
            Response Preferences
          </button>
        </h2>
        <div id="section-3" class="accordion-collapse collapse">
          <div class="accordion-body">
            These preferences affect the prompt used. The following options to alter the prompt are available: <br><br>
            <ul>
              <li>
                <strong>Language:</strong> The language of the posted review is automatically selected as the preferred language to generate the response in. If you would like to select a different language for the response generation, any language can be selected through the dropdown menu next to the automatically determined language.
              </li>
              <li>
                <strong>Tonality:</strong> This section defines the tonality in which the response will be generated. "Professional" and "Friendly" are two pre-selected options. If a unique tonality is desired, this can be entered in the text field next to the pre-selected options. If this field is populated, it will be used instead.
              </li>
              <li>
                <strong>Mention Property Name:</strong> This field is used to populate the end of the signature in the response. The automatically populated name is taken from the name as it appears on Tripadvisor. If a different name is preferred, you may edit this field. If the edited version is preferable for future responses, the alias can be saved by clicking the disk icon.
              </li>
              <li>
                <strong>Sign with Name:</strong> This field is used to populate the end of the signature in the response. It is automatically populated with the name available through your user account on this platform. If a different name is preferred, you may edit this field. If the edited version is preferable for future responses, the alias can be saved by clicking the disk icon.
              </li>
              <li>
                <strong>Limit Response Size:</strong> To avoid long and windy answers, this field can be used to limit the number of words used in the management response. The number of words can be altered at will.
              </li>
              <li>
                <strong>Mention These Words:</strong> These keywords will be included in the prompt for the AI to use in the response. Type a word and press enter; this saves the keyword in a list associated with your user. Words can be removed from the list by using the trash symbol next to the word in the dropdown menu of the field.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#section-4" aria-expanded="false" aria-controls="section-4">
            Saved Responses
          </button>
        </h2>
        <div id="section-4" class="accordion-collapse collapse">
          <div class="accordion-body">
            This section can be used to save any number of pre-written responses for quick usage. This can be useful for providing a quick, standardised response to reviews without comments, for example. <br><br>
            <ul>
              <li>The initial dropdown menu allows you to label the response; this field can be edited.</li>
              <li>When an AI response is generated, the saved responses field can be populated if this response would be desirable to save by pressing the "Save as Template" button over the reply section. It will then replace names and other automatically populated fields in the template response with a placeholder that looks like %{sender_signature}, for example. This allows the field to be automatically populated in future responses.</li>
            </ul>
            To keep a format for saved responses, an example could look like this: <br><br>
            <div class="bg-light rounded-3 py-2 px-3 d-flex align-items-center">
              Dear %{reviewer_name},<br>

              Enter custom response <br><br>

              Kind regards, <br>
              %{sender_signature} <br>
              %{hotel_name}
            </div><br>

            You save a response by pressing the disk icon when it is accompanied by an exclamation mark, if there is a checked mark the template is saved. To delete a saved icon, press the trash icon next to its name in the dropdown.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
