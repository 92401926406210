<script>
import simplebar from "simplebar-vue";

import SideNav from "./side-nav";

export default {
  components: { simplebar, SideNav },
  props: ["user", "isCondensed", "routes"],
  data() {
    return {
      settings: {
        minScrollbarLength: 60
      }
    };
  },
  methods: {}
};
</script>

<template>
  <div class="vertical-menu">
    <simplebar :settings="settings" class="h-100">
      <SideNav :user="user" :routes="routes" :isCondensed="isCondensed" />
    </simplebar>
  </div>
</template>

