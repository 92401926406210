<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import Filters from "@/components/filter";
import Watchlist from "@/components/watchlist";
import DataUtils from "@/js/data-utils";

/**
 * Watchlist component
 */
export default {
  page: {
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    PageHeader,
    Filters,
    Watchlist,
  },
  data() {
    return {
      filterCols: ['daterange', 'property_type', 'continent', 'country', 'regions', 'city', 'category', 'group', 'reviewer_country', 'language', 'tripadvisor_rating', 'sources', 'travel_composition', 'filters' ],
      filterParams: {}
    };
  },
  methods: { ...DataUtils }
};
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('watchlist.title')" />

    <Filters @filter:created="saveFilterParams" @filter:update="saveFilterParams" :cols='filterCols'/>

    <div class="row">
      <div class="col-xl-12">
        <Watchlist :filterParams="filterParams" />
      </div>
    </div>
  </div>
</template>
