<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"

export default {
  data() {
    return {
      labels: [],
      tableData: [],
      segments: [],
      chartOptions: {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          toolbar: { show: false }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "60%",
            // dataLabels: {
            //   hideOverflowingLabels: false,
            // }
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (value) => value + "%",
          }
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => Utils.round(value, 0),
          style: {
            fontSize: "16px",
            colors: ["#fff"]
          },
          offsetY: -1,
          offsetX: 0
        },
        grid: { borderColor: "#fff" },
        fill: { opacity: 1 },
        yaxis: {
          labels: {
            formatter: (value) => String(value),
            style: {
              colors: ["#000"],
              fontSize: '14px',
              fontWeight: 500,
            },
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#000",
            width: 6,
            offsetX: 0,
            offsetY: 0
          },
        },
        xaxis: {
          labels: {
            style: {
              colors: ["#000"],
              fontSize: '14px',
              fontWeight: 500,
            },
            formatter: function(value) {
              return value + "%"
            }
          }
        },
        legend: {
          show: true,
          position: "bottom",
          fontWeight: 500,
          fontSize: "16px",
          labels: { colors: ["#000"] }
        }
      }
    };
  },
  props: ["title", "company", "identifier", "source", "last_12m", "next_3m", "release_date", "colors"],
  created() {
    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      source:       this.source,
      last_12m:     this.last_12m,
      next_3m:      this.next_3m,
      release_date: this.release_date,
      months_ago: 3,
      period:  "merged",
      format:  "hash"
    }}).then(response => {
      this.tableData = response.data.data;

      let seen = [];
      this.tableData.forEach(data => {
        if (!seen.includes(data.name)) {
          this.labels.push(data.name);
          seen.push(data.name);
        }
      });
      this.tableData.forEach(data => {
        data.segments?.forEach(segment => {
          if (!this.segments.includes(segment.segment)) this.segments.push(segment.segment);
        });
      });
      if (this.$refs["column-chart"])
        this.$refs["column-chart"].updateOptions({ xaxis: { categories: this.labels }, colors: this.colors });
    });
  },
  computed: {
    series() {
      let res = [];
      this.segments.forEach(segment => {
        let segmentData = [];
        this.tableData.forEach(data => {
          let toPush = data.segments?.find(s => s.segment == segment)?.share || 0;
          toPush = Utils.round(toPush, 1);
          segmentData.push(toPush);
        });
        res.push({ name: segment, data: segmentData })
      });
      return res;
    }
  }
}
</script>

<template>
  <div>
    <h3 class="title mt-3"> {{ title }} </h3>
    <apexchart
      class="mt-5"
      height="600"
      dir="ltr"
      :series="series"
      :options="chartOptions"
      ref="column-chart"
    ></apexchart>
  </div>
</template>
