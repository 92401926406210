<script>
import Multiselect from "vue-multiselect";
import { Field } from 'vee-validate';

export default {
  props: ["question", "settings", "editing"],
  components: {
    Multiselect,
    Field
  },
  data() {
    return {
      languageHash: {
        "en": "English",
        "nl": "Niederländisch",
        "de": "Deutsch",
        "fr": "Français",
        "it": "Italiano",
        "sp": "Español",
        "pt": "Português",
        "ar-ae": "العربية (الإمارات العربية المتحدة)",
        "ru": "Русский",
        "sk": "Slovenský",
        "nb": "Norsk (Bokmål)",
        "pt-PT": "Português (Portugal)",
        "cs": "Čeština",
        "pl": "Polski",
        "el": "Ελληνικά",
        "zh-TW": "中文（台灣）",
        "zh-CN": "中文（中國）",
        "pt-BR": "Português (Brasil)",
        "fr_CA": "Français",
        "ja": "日本",
        "tr": "Türk",
        "es": "Español",
        "id": "Bahasa Indonesia",
        "da": "Dansk",
      },
    }
  },
  methods: {
    placeholder(field) {
      return this.question.translations?.[this.settings.currentLanguage]?.[field] ||
      ((this.question.translations?.[this.settings.defaultLanguage]?.[field] || '') + " " + this.$t("new_survey.questions.edit.needs_translation")) || this.$t(`new_survey.questions.edit.${field}_placeholder`);
    },
    hasMissingTranslations(field) {
      return !this.question.translations?.[this.settings.currentLanguage]?.[field];
    }
  },
  computed: {
    welcome() {
      return this.question.translations?.[this.settings.currentLanguage] || {};
    },
    showLanguageSelector() {
      return this.question.type == "welcome" && this.settings.availableLanguages?.length > 1;
    },
    companyColor() {
      if (this.settings.color) return `--company-color: ${this.settings.color};`
      return "";
    }
  }
};
</script>

<template>
  <div class="flex-grow-1 d-flex flex-column justify-content-between w-100" :style="companyColor">
    <nav class="nav nav-pills nav-fill mb-5">
      <img class="cover-logo" :src="settings.logo" alt="Company Logo" v-if="settings.logo"/>

      <div v-if="showLanguageSelector" class="language-selector m-0 d-flex flex-column justify-content-center" style="pointer-events: none;">
        <label>{{ $t("feedback-form.languages.selector", settings.currentLanguage) }}</label>
        <br />
        <multiselect
          :multiple="false"
          :options="settings.availableLanguages"
          :searchable="false"
          :showLabels="false"
          openDirection="bottom"
          :customLabel="(option) => languageHash[option]"
        ></multiselect>
      </div>
    </nav>

    <Field rules="required" :name="$t('new_survey.questions.left.question')" v-slot="{ field, errors }" class="w-100" v-model="welcome.title">
      <div class="d-flex flex-column align-items-center">
        <textarea :ref="'title_' + question.id" id="textarea-auto-resize" class="title custom-textarea" :class="{ 'missing-translation': hasMissingTranslations( 'title') }" @input="$emit('changeInput')" style="text-align: center;" :placeholder="placeholder('title')" v-bind="field"></textarea>
        <span class="mt-2 text-danger font-size-14" id="error">{{ errors[0] }}</span>
      </div>
    </Field>

    <textarea :ref="'instructions_' + question.id" id="textarea-auto-resize" class="description custom-textarea" :class="{ 'missing-translation': hasMissingTranslations('instructions') }" @input="$emit('changeInput')" v-model="welcome.instructions" style="text-align: center;" :placeholder="placeholder('instructions')"></textarea>

    <div class="nav-bar mt-1">
      <button type="button" class="btn btn-outline-primary btn-lg shadow-sm cursor-default"> {{ $t(`feedback-form.${ question.type == 'welcome' ? 'start' : 'submit' }`, settings.currentLanguage) }} </button>
    </div>
  </div>
</template>
