import axios from "axios";

export const state = { surveys: {}, surveysPromise: {} };

export const getters = {
  surveys: (state) => (contract_id) => state.surveys[contract_id]
};

export const mutations = {
  saveSurveys(state, { surveys, contractId }) {
    if (state.surveys[contractId]?.length) {
      state.surveys[contractId] = surveys;
      state.surveys[contractId] = [...new Map(state.surveys[contractId].map(d => [d.id, d])).values()];
    } else {
      state.surveys[contractId] = surveys;
    }
  },
  saveSurveysPromise(state, { promise, contractId }) {
    state.surveysPromise[contractId] = promise;
  },
};

export const actions = {
  async fetch({ commit }, params) {
    const contractId = params.contract_id || "all";

    if (state.surveys[contractId])        return state.surveys[contractId];
    if (state.surveysPromise[contractId]) return state.surveysPromise[contractId];

    let promise = axios.get("/v3/feedback/surveys", { params: { subscription_ids: params.contract_id } }).then(response => {
      const surveys = response.data.data;
      commit("saveSurveys", { surveys, contractId });
      return surveys;
    });

    commit("saveSurveysPromise", { promise, params: { contractId } });
    return promise;
  },
}
