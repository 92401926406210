import Vuex from 'vuex'

import modules from './modules'
import { state, getters, mutations, actions } from './token'

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

export default store
