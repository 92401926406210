
<script>
import Multiselect from "vue-multiselect";
import DataUtils from "@/js/data-utils";
import Utils from "@/js/utils";

/**
 * Nav-bar Component
 */
export default {
  data () {
    return {
      availableLocales: Utils.locales,
      show_save: false,
      message: null,
      icon: null,
      error: false,
      success: false
    }
  },
  props: ['user', 'no_shadow', 'statusMessage'],
  components: { Multiselect },
  watch: {
    statusMessage: function () {
      if (this.statusMessage?.status && this.statusMessage?.icon) this.saveMessage(this.statusMessage);
    }
  },
  async created () {
    this.$i18n.locale = await this.$store.dispatch('locale/fetch')
    if (!this.availableLocales.includes(this.$i18n.locale)) this.setLocale('en');
  },
  computed: {
    showContractSelector() {
      return this.user?.id && (this.contracts?.length);
    },
    contracts() {
      return this.$store.getters["contract/contracts"];
    },
    userLoaded() {
      return this.user && this.user.id;
    },
    contract: {
      get() {
        return this.$store.getters["contract/currentContract"];
      },
      async set(contract) {
        if (contract) this.$store.dispatch("contract/setContract", contract);
      }
    }
  },
  methods: {
    ...DataUtils,
    ...Utils.auth,
    saveMessage(ev) {
      this.error = false;
      this.success = false;
      this.message = this.$t(`new_survey.questions.edit.${ev.status}`);
      this.icon = ev.icon;
      this.show_save = true;
      if (ev.status == 'error') this.error = true;
      if (ev.status == 'saved') this.success = true;
      setTimeout(() => this.show_save = false, 4000);
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    setLocale (locale) {
      this.$store.commit('locale/saveLocale', locale)
      this.$i18n.locale = locale
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    showHelpButton() {
      return window.location.host.endsWith('olery.com') || window.location.host == 'localhost:5000'
    },
    redirectSettings() {
      this.$router.push({ name: "user_settings" });
    },
    home(ev) {
      ev.preventDefault();
      this.$router.push({ path: "/", query: { ...Utils.slice(this.$route.query, Utils.authParams) } });
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header" :class="{ 'shadow-none': no_shadow, 'justify-content-end': !userLoaded }">
      <div v-if="userLoaded" class="d-flex align-items-center left-navbar">
        <div class="navbar-brand-box" @click="home">
          <a href="/" class="logo logo-dark">
            <div class="logo-sm"></div>
            <div class="logo-lg"></div>
          </a>

          <a href="/" class="logo logo-light">
            <div class="logo-sm"> </div>
            <div class="logo-lg"> </div>
          </a>
        </div>
        <button
          type="button"
          class="btn btn-sm px-3 font-size-24 header-item"
          id="vertical-menu-btn"
          @click="toggleMenu"
          v-b-tooltip.hover :title="$t('general.toggle_sidebar')"
        >
          <i class="mdi mdi-menu"></i>
        </button>

        <template v-if="showContractSelector">
          <div class="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center" style="flex-grow: inherit;">
            <label class="mb-1 me-3 d-none d-sm-block text-nowrap text-header">{{ $t("general.select_subscription") }}</label>
            <multiselect :allow-empty="false" :multiple="false" v-model="contract" :options="contracts" :showLabels="false" :custom-label="c => c.name || c.id" :placeholder="$t('filter.select_option')" ></multiselect>
          </div>
        </template>

      </div>

      <div class="d-flex align-items-center">
        <!-- Saving/saved message -->
        <div class="font-size-15" :class="{ 'text-danger': error, 'text-success': success }" v-if="show_save">
          <i :class="icon"></i>
          <span class="ms-2"> {{ message }} </span>
        </div>

        <button class="btn btn-sm px-3 font-size-24 header-item d-none d-md-block" v-b-tooltip.hover data-bs-placement="bottom" :title="$t('general.print_help')" ><i class='ion ion-md-print' onclick="window.print()"></i></button>

        <b-dropdown variant="white" right class="ms-2" toggle-class="header-item">
          <template v-slot:button-content>
            <span v-b-tooltip.hover :title="$t('general.change_language')" >
              <div class="d-flex align-items-center">
                <span :class="`flag flag-${locales($i18n.locale, 'language').code}`" class="me-0 me-md-2 mt-1 mt-md-0"></span>
                <span class="d-none d-md-block">{{ $t(`locales.${$i18n.locale}`) }} <i class="mdi mdi-chevron-down"></i></span>
              </div>
            </span>
          </template>
          <b-dropdown-item class="notify-item" @click='setLocale(l)' v-for='l in availableLocales' :key='l'>
            <div class="d-flex align-items-center">
              <span :class="`flag flag-${locales(l, 'language').code}`" class="me-2"></span>
              <span class="align-middle">{{ $t(`locales.${l}`) }}</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>

        <div class="dropdown d-none d-lg-inline-block">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen" v-b-tooltip.hover :title="$t('general.go_fullscren')" >
            <i class="ion ion-md-qr-scanner"></i>
          </button>
        </div>

        <b-dropdown right variant="white" class="d-inline-block ms-2" toggle-class="header-item">
          <template v-slot:button-content>
            <i class="ion ion-md-person " style=" font-size: 28px; "></i>
          </template>

          <!-- username -->
          <b-dropdown-text class='alert-info' text-class="text-primary">
            <template #default>
              <span class="text-dark">{{ user.name }}</span>
            </template>
          </b-dropdown-text>

          <!-- settings -->
          <b-dropdown-item href='#' @click="redirectSettings">
            <i class="mdi mdi-cog font-size-17 align-middle me-1"></i>
            {{ $t("general.settings") }}
          </b-dropdown-item>

          <!-- help -->
          <b-dropdown-item href='mailto:support@olery.com' v-if="showHelpButton()">
            <i class="mdi mdi-help font-size-17 align-middle me-1"></i>
            {{ $t("general.help") }}
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>

          <a href='/users/sign_out' @click="notifyExtension('OLY_EXTENSION_AUTH_LOGOUT')" class='dropdown-item text-danger '>
            <i class="mdi mdi-logout-variant font-size-17 align-middle me-1"></i>
            {{ $t("general.logout") }}
          </a>
        </b-dropdown>

      </div>
    </div>
  </header>
</template>
