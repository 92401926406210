<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      tableData: [],
      cols: [
        { field: 'source',   label: 'Property Name', },
        { field: 'total',    label: 'Total',    },
        { field: 'positive', label: 'Positive', },
        { field: 'negative', label: 'Negative', },
        { field: 'neutral',  label: 'Neutral',  },
      ],
      rows: [],
    };
  },
  components: { ExportExcel },
  props: ["title", "company", "identifier", "source", "release_date", "prev_months"],
  created() {
    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      source:       this.source,
      release_date: this.release_date,
      compset: "primary",
      prev_months: 1,
      format: "hash",
      mtd: true
    }}).then(response => {
      this.tableData = response.data.data?.filter(d => d.period == "current") || [];
      this.tableData.forEach(row => {
        this.cols.slice(1).forEach(col => {
          row.responses[col.field].responses = row.responses[col.field].responses / row.responses[col.field].respondable;
          if (!isNaN(row.responses[col.field].responses) && row.responses[col.field].responses != null)
            row.responses[col.field].responses = Utils.round(100*row.responses[col.field].responses, 2) + "%";
          else row.responses[col.field].responses = "-";
        });
      });
    });
  }
}
</script>

<template>
  <div class="table-responsive mt-4">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th v-for="col in cols" :key="col.label" :colspan="col.colspan"> {{ col.label }} </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, indexRow) in tableData" :key="String(row.length) + indexRow">
            <td class="property-name-column">{{ row.name }}</td>
            <template v-for="col in cols.slice(1)" :key="col.label">
              <td>{{ row.responses[col.field].responses }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
