<script>
import {axios} from '@/js/reports'
import dayjs from 'dayjs'
import Utils from '@/js/utils'
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      tableData: {
        current:  [],
        previous: []
      },
      cols: [
        { field: "name", label: "Property Name" },
        { field: "reviews", label: "Number of Reviews MTD" },
        { field: "reviews_week", label: "Monthly Reviews Earned this Week" },
        { field: "average_rating", label: "Avg. Review Score MTD" },
        { field: "average_rating_change", label: "Change Since Last Week" },
        { field: "ranking", label: "TripAdvisor Rank" },
        { field: "ranking_change", label: "Change Since Last Week" }
      ]
    };
  },
  components: { ExportExcel },
  props: ["title", "company", "group", "identifier", "release_date"],
  created() {
    axios.get("/v3/query/db/_", { params: {
      ...(this.company) && { company_id: this.company.id },
      ...(this.group) && { group_id: this.group.id },
      identifier:   this.identifier,
      release_date: this.release_date,
      source:       ["trip_advisor"],
      compset: "primary",
      format:  "hash",
      mtd: true,
      prev_months: 1,
    }}).then(response => {
      this.tableData.current  = response.data.data.filter(d => d.period == "current")
      this.tableData.previous = response.data.data.filter(d => d.period == "previous")

      this.tableData.current.forEach((row, i) => {
        row.weeks      ||= []
        let allWeeks     = this.tableData.previous[i].weeks.concat(row.weeks)
        let currentWeek  = allWeeks.pop()
        // handle first day of month where week is still missing
        if (dayjs(currentWeek.week).month() != dayjs(this.release_date).month()) currentWeek = {}
        let previousWeek = allWeeks.at(-1)

        allWeeks.forEach(w => w.reviews ||= 0)

        row.weeks.splice(-1)
        let previousMtd = row.weeks.reduce((s,w) => s + w.average_rating * w.reviews, 0) /
          row.weeks.reduce((s,w) => s + w.reviews, 0)
        row.average_rating_change = row.average_rating - previousMtd
        if (!row.average_rating_change) row.average_rating_change = 0
        if (Math.abs(row.average_rating_change) < 0.01) row.average_rating_change = 0
        row.average_rating_change = this.round(row.average_rating_change)

        if (previousWeek.ranking && row.ranking) {
          row.ranking_change = previousWeek.ranking - row.ranking;
          if (row.ranking_change > 0) row.ranking_change = '+' + row.ranking_change
        } else
          row.ranking_change = 0

        if (row.ranking == null) row.ranking = "-"
        else row.ranking = "#" + row.ranking

        row.reviews_week = currentWeek?.reviews || 0;
      })

      this.tableData.current.forEach(row => {
        this.cols.forEach(col => {
          if (row[col.field] == null) row[col.field] = "-";
        });
      });
    });
  },
  methods: {
    round(value) {
      return value == "-" ? "-" : Utils.round(value, 2);
    }
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th class="property-name-column">{{ cols[0].label }}</th>
            <th v-for="col in cols.slice(1)" :key="col.field">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in tableData.current" :key="JSON.stringify(row)">
            <td class="property-name-column">{{ row.name }}</td>
            <template v-for="col in cols.slice(1)" :key="col.field">
              <td>{{ row[col.field] }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
