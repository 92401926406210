<template>
  <div>
    <h2>{{ $t('signup.signup_to_paid.add_payment_method') }}</h2>
    <div v-if="stripeLoaded" style="padding: 10px; margin: 50px 0; gap: 10px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <PaymentMethod ref="paymentMethod" :handlePaymentMethodSaved="setCard" />
    </div>
    <div v-else>
      {{ $t('signup.signup_to_paid.loading_stripe') }}
    </div>
  </div>
</template>

<script>
import PaymentMethod from '@/components/payment_method.vue'
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { ref, onBeforeMount, defineComponent } from 'vue';

export default defineComponent({
  name: 'BillingInfo',
  components: {
    PaymentMethod
  },
  props: {
    pre_signup_id: {
      required: true,
    },
    setStripeCustomerId: {
      type: Function,
      required: true,
    },
    handlePaymentMethodSaved: {
      type: Function,
      required: true,
    }
  },
  setup(props) {
    const stripeKey = ref(process.env.VUE_APP_STRIPE_KEY);
    const instanceOptions = ref({});
    const elementsOptions = ref({});
    const cardOptions = ref({});
    const stripeLoaded = ref(false);
    const paymentMethod = ref(null);
    const card = ref();
    const elms = ref();
    const errors = ref([]);

    const countdown = ref(0);
    const dismissCountDown = ref(5000)

    const country = ref('US');
    const zip = ref('');
    const agree = ref(false);

    onBeforeMount(() => {
      loadStripe(stripeKey.value).then((stripe) => {
        if (stripe) {
          stripeLoaded.value = true;
        }
      });
    });

    const triggerPaymentMethodSubmit = async () => {
      console.log('Triggering payment method submit', paymentMethod.value);
      await paymentMethod.value.handleSubmit();
    };

    const setCard = (cardData) => {
      card.value = cardData;
    };

    const handleSubmit = async () => {
      await triggerPaymentMethodSubmit();
      if (!card.value || !card.value.token) {
        errors.value = ['Please enter a valid card'];
        return;
      }
      
      try {
        const response = await axios.post('/v3/subscription_products/save_payment_method', {
          payment_method: {
            pre_signup_id: props.pre_signup_id,
            token: card.value.token,
            country: country.value,
            zip: zip.value,
          }
        });

        if (response.data.pre_signup.stripe_customer_id) {
          if (typeof props.setStripeCustomerId === 'function') {
            props.setStripeCustomerId(response.data.pre_signup.stripe_customer_id);
          }
          if (typeof props.handlePaymentMethodSaved === 'function') {
            props.handlePaymentMethodSaved();
          }
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          errors.value = [error.response.data.error];
          countdown.value = 5;
        } else {
          errors.value = ['An error occurred saving the payment method'];
        }
      }
    };

    return {
      stripeKey,
      stripeLoaded,
      instanceOptions,
      elementsOptions,
      cardOptions,
      card,
      elms,
      country,
      zip,
      agree,
      handleSubmit,
      errors,
      countdown,
      dismissCountDown,
      setCard,
      paymentMethod,
      triggerPaymentMethodSubmit,
    };
  },
});
</script>
