import axios from "axios";

export const state   = { groups: {}, groupsPromise: {} };
export const getters = {
  groups: (state) => (contract_id) => state.groups[contract_id]
};

export const mutations = {
  saveGroups(state, { groups, params }) {
    let contract_id = params.contract_id || "all";
    state.groups[contract_id] = groups;
  },
  saveGroupsPromise(state, { promise, params }) {
    let contract_id = params.contract_id || "all";
    state.groupsPromise[contract_id] = promise;
  },
};

export const actions = {
  async fetch({ commit }, params ) {
    if (params.contract_id && state.groupsPromise[params.contract_id]) return state.groupsPromise[params.contract_id];
    if (!params.contract_id && state.groupsPromise["all"]) return state.groupsPromise["all"];

    let url = params.contract_id ? `/v3/contracts/${params.contract_id}/groups` : `/v3/groups`;
    let promise = axios.get(url, { params: { company_ids: true, companies: true } }).then(response => {
      const groups = response.data.data.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: "base" }));
      commit("saveGroups", { groups, params });
      return groups;
    });

    commit("saveGroupsPromise", { promise, params });
    return promise;
  },
}
