<script>
import { Field } from 'vee-validate';

export default {
  props: ["question", "settings", "editing"],
  components: { Field },
  methods: {
    state(errors, valid) {
      return errors?.[0] ? false : (valid ? true : null);
    }
  },
  computed: {
    answerRules() {
      let rules = []
      if (this.question.required) rules.push('required')
      if (this.question.min) rules.push('min:'+this.question.min)
      if (this.question.max) rules.push('max:'+this.question.max)
      return rules.join('|')
    }
  }
};
</script>

<template>
  <div class="form-group text-root w-75">
    <div class="text w-100 align-self-center mt-2">
      <Field v-if="!editing" :rules="answerRules" :name="$t('feedback-form.answer')" v-slot="{ field, meta, errors }" v-model="question.answer">
        <b-form-group :name="$t('feedback-form.answer')" v-bind="field">
          <b-form-textarea
            v-if="question.type == 'open_answer_long'"
            class="form-control" rows="5"
            @input="$emit('answerChanged', question.answer)"
            :state="state(errors, meta.valid)"
          />

          <b-form-textarea
            v-if="question.type == 'open_answer'"
            class="form-control" rows="2"
            @input="$emit('answerChanged', question.answer)"
            :state="state(errors, meta.valid)"
          />

          <b-form-invalid-feedback v-for="e in errors" :key="e" id="inputLiveFeedback">
            {{ e }}
          </b-form-invalid-feedback>
        </b-form-group>
      </Field>

      <div v-else>
        <b-form-group label-for="answer" >
          <b-form-textarea class="form-control" rows="5"
            v-if="question.type == 'open_answer_long'" disabled />
          <b-form-textarea class="form-control" rows="2"
            v-if="question.type == 'open_answer'" disabled />
        </b-form-group>
      </div>
    </div>
  </div>
</template>
