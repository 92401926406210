<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import Filters from "@/components/filter";
import Kpis from "@/components/kpis";
import OverallChart from "@/components/overall-chart";
import RatingComparisson from "./rating-comparisson";
import DataUtils from "@/js/data-utils";
import Trends from "@/components/trend-tracker";

export default {
  page: {
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    PageHeader,
    Filters,
    OverallChart,
    Kpis,
    RatingComparisson,
    Trends
  },
  data() {
    return {
      type: 'numerical',
      filterCols: ['daterange', 'property_type', 'continent', 'country', 'regions', 'city', 'category', 'group', 'filters'],
      kpiCols:    ['rating', 'sentiment', 'tes', 'review_count'],
      chartCols:  ['reviews_count', 'numerical_rating', 'sentiment_rating', 'tes_score'],
      filterParams: {},
      user: null
    };
  },
  async mounted () {
    this.user = await this.$store.dispatch("user/fetch");
  },
  methods: {
    ...DataUtils
  }
};
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('dashboard.title')" />

    <Filters @filter:created="saveFilterParams" @filter:update="saveFilterParams" :cols='filterCols'/>

    <div class="row">
      <div class="col-lg-12">
        <OverallChart :cols='chartCols' :filterParams="filterParams" page='destination_dashboard'/>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-6 mb-4 col-lg-6" v-if="filterParams.data && user.admin">
        <div class="card h-100">
          <h6 class="text-uppercase mt-3 ms-2">{{ $t('daily_operations.dashboard.trends') }}</h6>
          <Trends :start_date='filterParams.data.start_date' :end_date='filterParams.data.end_date' :settings="{...filterParams, showAdviceButton: true}" dashboard_type="destination" v-if='filterParams.data' />
        </div>
      </div>

      <div class="col-xs-12" :class="{ 'col-sm-6': user?.admin }">
        <Kpis :kpiSize='user?.admin ? "md" : "lg"' class='col-xs-12' :cols='kpiCols' :filterParams="filterParams" countslug='number_reviews' dashboard_type="destination"/>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <RatingComparisson :filterParams="filterParams" />
      </div>
    </div>
  </div>
</template>
