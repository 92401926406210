<template v-slot:default>
  <div>
    <h3>{{ $t('signup.signup_to_paid.password') }}</h3>
    <b-form-input ref="inputRef" class="mb-2" v-model="formData.password" type="password"
      placeholder="Password"></b-form-input>
    <b-form-input
      class="mb-2"
      v-model="formData.confirmPassword"
      type="password"
      @keydown.enter="nextStep()"
      placeholder="Confirm Password"></b-form-input>
  </div>
</template>

<script>
export default {
  props: ['formData', 'nextStep', 'prevStep'],
  mounted() {
    this.$nextTick(() => {
      this.$refs.inputRef.focus();
    });
  },
};
</script>