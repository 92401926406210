<script>
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import Layout from "@/router/layouts/feedback-form";

import axios from "axios";
import { Carousel } from "bootstrap";

import DateField from "@/components/feedback-form-inputs/date";
import DropdownField from "@/components/feedback-form-inputs/dropdown";
import EnumField from "@/components/feedback-form-inputs/enum";
import EmailNameField from "@/components/feedback-form-inputs/email-name";
import MultipleChoiceField from "@/components/feedback-form-inputs/multiple-choice";
import RatingField from "@/components/feedback-form-inputs/rating";
import OpenAnswerField from "@/components/feedback-form-inputs/open_answer";
import TripadvisorField from "@/components/feedback-form-inputs/tripadvisor-iframe";
import YesNoField from "@/components/feedback-form-inputs/yes-no";
import TailSlide from "@/components/feedback-form-inputs/tail";

import { localize } from '@vee-validate/i18n';
import FormUtils from '@/js/form-utils';
import Utils from "@/js/utils";

export default {
  data() {
    return {
      loadingSurvey: true,
      loading: false,
      slide:    0,
      locate:   'de',
      info:     {},
      identifier: Math.random().toString(36).substring(5),
      settings: {
        bg:    null,
        color: null,
        logo:  null,
        defaultLanguage: "",
        currentLanguage: "en",
        availableLanguages: [],
        redirect_message: {}
      },
      form: { completed: false },
      questions:  [],
      conditions: [],
      hasError: false,

      valid(question) {
        return FormUtils.valid.all(question, question.answer)
      },

      questionHash: {
        "date": "DateField",
        "drop_down": "DropdownField",
        "enum": "EnumField",
        "location": "",
        "multiple_select": "MultipleChoiceField",
        "open_answer": "OpenAnswerField",
        "open_answer_long": "OpenAnswerField",
        "past_date": "DateField",
        "rating": "RatingField",
        "trip_advisor_iframe": "TripadvisorField",
        "yes_no": "YesNoField",
        "nps": "RatingField",
        "email-name": "EmailNameField",
        "follow_up": "OpenAnswerField"
      },
      languageHash: {
        "en": "English",
        "nl": "Nederlands",
        "de": "Deutsch",
        "fr": "Français",
        "it": "Italiano",
        "sp": "Español",
        "pt": "Português",
        "ar-ae": "العربية (الإمارات العربية المتحدة)",
        "ar": "العربية",
        "ru": "Русский",
        "sk": "Slovenský",
        "nb": "Norsk (Bokmål)",
        "pt-PT": "Português (Portugal)",
        "cs": "Čeština",
        "pl": "Polski",
        "el": "Ελληνικά",
        "zh-TW": "中文（台灣）",
        "zh-CN": "中文（中國）",
        "pt-BR": "Português (Brasil)",
        "fr_CA": "Français",
        "ja": "日本",
        "tr": "Türk",
        "es": "Español",
        "id": "Bahasa Indonesia",
        "da": "Dansk",
        "fi": "suomi",
        "sv": "Svenska"
      },
    };
  },

  components: {
    Multiselect,
    Layout,
    DateField,
    DropdownField,
    EnumField,
    EmailNameField,
    MultipleChoiceField,
    RatingField,
    OpenAnswerField,
    TripadvisorField,
    YesNoField,
    TailSlide
  },

  methods: {
    can(question) {
      if (question.type == "trip_advisor_iframe") return Object.keys(this.settings.ta_urls || {}).length > 0;
      return true;
    },
    last(question) {
      return question == this.filteredQuestions[this.filteredQuestions.length - 1];
    },
    currentQuestion () {
      return this.filteredQuestions[this.slide - 1]
    },
    async nextPage(questionIndex) {
      if (questionIndex == -1) {
        if (!this.form.id) {
          try {
            this.loading = true;
            let response = await axios.put('/v3/feedback/responses', {
              key:               this.$route.params.key,
              info:              { ...this.info, token: undefined },
              client_identifier: this.identifier,
              language:          this.settings.currentLanguage,
            });
            this.setResponse(response);
            this.loading = false;
          } catch (err) { this.error(err); }
        }
        this.nextSlide();
        return;
      } else {
        await this.saveAnswer(questionIndex);
      }

      const question = this.filteredQuestions[questionIndex];
      if (/rating|nps/.test(question.type) && question.enabled_followup && !question.showFollowup && question.answer) {
        question.showFollowup    = true;
        question.followupContext = true;
        await this.$nextTick();
        return;
      }
      question.followupContext = false;

      if (this.filteredQuestions.length == questionIndex + 1) this.submitForm();
      this.nextSlide();
    },
    nextSlide() {
      this.carousel.next();
      this.slide++;
    },
    isFollowUp(question) {
      return /rating|nps/.test(question.type) && question.enabled_followup && question.showFollowup && question.answer
    },
    async saveAnswer(questionIndex) {
      const question = this.filteredQuestions[questionIndex];

      // only update if the answer or the follow up has changed
      if (!question.showFollowup && question.answer == question.lastSavedAnswer) return;
      if (question.showFollowup && question.follow_up == question.lastSavedFollowup) return;

      let method, param = "";

      if (question.answer_id) {
        method = "post";
        param = `/${question.answer_id}`;
      } else method = "put";

      if (!FormUtils.valid.all(question, question.answer) || question.answer === "" || question.answer == null) return;
      if (!this.expectedAnswerType(question)) return this.alertTypesDoNotMatch(question);

      question.lastSavedAnswer = Utils.deepClone(question.answer);
      if (question.showFollowup) question.lastSavedFollowup = question.follow_up;
      try {
        this.loading = true;
        let response = await axios[method](`/v3/feedback/answers${param}`, {
          response_id:       this.form.id,
          question_id:       question.id,
          answer:            question.answer,
          client_identifier: this.identifier,
          ...question.showFollowup && { follow_up: question.follow_up },
        });
        question.answer_id = response.data.data.id;
        this.loading = false;
      } catch (err) { console.error(err); }
    },
    expectedAnswerType(question) {
      if (/date|past_date/.test(question.type))             return question.answer instanceof Date;
      if (/yes_no|trip_advisor_iframe/.test(question.type)) return /true|false/.test(question.answer);
      if (/rating|nps/.test(question.type))                 return typeof question.answer == "number";
      if (/multiple_select/.test(question.type))            return question.answer instanceof Array;
      return typeof question.answer == "string";
    },
    alertTypesDoNotMatch(question) {
      Swal.fire({ title: this.$t('feedback-form.error'), text: this.$t("feedback-form.incorrect_answer_type"), footer: '<br>', icon: 'error', showCancelButton: false, showConfirmButton: false, allowOutsideClick: false, backdrop: true, customClass: { container: "blurredBg" } });
      this.$rollbar.error("Answer type do not match question type.", { answer: question.answer, answer_type: typeof question.answer, question: question.id, question_type: question.type, survey: this.form.survey.id, })
    },
    previousPage(questionIndex) {
      let question = this.filteredQuestions[questionIndex];
      if (question?.followupContext) question.followupContext = false;
      this.carousel.prev();
      this.slide--;
    },
    submitForm() {
      this.loading = true;
      axios
        .post(`/v3/feedback/responses/${this.form.id}`, {
          completed: true,
          client_identifier: this.identifier,
        })
        .then(() => this.loading = false)
        .catch((err) => console.error(err));
      this.form.completed = true
    },
    setLocale(locale) {
      this.$store.commit("locale/saveLocale", this.settings.currentLanguage);
      this.$i18n.locale = locale || this.settings.currentLanguage;
      localize(this.$i18n.locale) // notify vee-validate of localization changes
    },
    answerChanged(answer, questionIndex) {
      let question    = this.filteredQuestions[questionIndex];
      question.answer = answer;
      let i = this.questions.findIndex(q => q.id == question.id)
      this.questions[i] = question;
    },

    forwardButtonLabel (question) {
      if (!/rating|nps/.test(question.type) && this.last(question)) return this.$t("feedback-form.submit")

      if (/rating|nps/.test(question.type) && question.enabled_followup) {
        if (question.showFollowup && this.last(question)) return this.$t("feedback-form.submit")
        if (!question.showFollowup && !question.answer && this.last(question)) return this.$t("feedback-form.submit")
        if (!question.showFollowup && question.answer) return this.$t("feedback-form.next")
      }

      if (!question.required && (question.answer === undefined || question.answer === "")) return this.$t("feedback-form.skip")

      return this.$t("feedback-form.next")
    },
    useWelcomeTranslation(field) {
      return this.form.survey.translations?.[this.settings.currentLanguage]?.[field] || this.form.survey.translations?.[this.settings.defaultLanguage]?.[field]
    },
    error(error) {
      this.hasError = true;
      console.error(error);

      if (error.response?.data?.error)
        return Swal.fire({ title: this.$t('feedback-form.error'), text: this.$t('contact_support') + JSON.stringify(error.response.data.error), footer: '<br>', icon: 'warning', showCancelButton: false, showConfirmButton: false, allowOutsideClick: false, backdrop: false });
      else {
        setTimeout(() => this.$router.replace({ path: this.$route.path, query: this.info }), 3000);
        return Swal.fire({ title: this.$t('feedback-form.error'), text: this.$t('internet_connection_error'), icon: 'error', showCancelButton: false, showConfirmButton: false, allowOutsideClick: false, backdrop: false });
      }
    },
    async setResponse(res) {
      this.form  = res.data.data;
      this.conditions = this.form.survey.conditions;

      this.questions.forEach(oldQ => {
        let question = this.form.survey.questions.find(q => q.id == oldQ.id)
        if (question) {
          question.answer    = oldQ.answer
          question.answer_id = oldQ.answer_id
        }
      });
      this.questions = this.form.survey.questions;

      this.settings.color = this.form.survey.color_hex;
      if (this.settings.color && !/#/.test(this.settings.color)) this.settings.color = "#" + this.settings.color;
      else this.settings.color ||= "";

      this.settings.bg    = this.form.survey.bg_url;
      this.settings.logo  = this.form.survey.logo_url;

      if (!this.form.survey.languages?.length) this.settings.availableLanguages.push(this.form.survey.default_locale);
      else this.settings.availableLanguages = this.form.survey.languages;

      this.settings.defaultLanguage = this.form.survey.default_locale;
      const language = this.form.language || this.form.survey.default_locale;
      if (this.settings.availableLanguages.includes(language)) this.settings.currentLanguage = language;
      else this.settings.currentLanguage = this.form.survey.default_locale;
      this.setLocale();

      this.settings.redirect_message = this.form.survey.translations?.[this.$i18n.locale]?.redirect_message || this.form.survey.translations?.[this.settings.defaultLanguage]?.redirect_message || this.form.survey.redirect_message;
      this.settings.redirect_url     = this.form.survey.redirect_urls?.[this.form.company?.id] || this.form.survey.redirect_url;

      this.settings.ta_urls = this.form.survey.trip_advisor_iframe_urls;
      this.settings.ta_company = this.form.company;

      this.settings.variables = this.form.additional_info;
      this.settings.variables["company_name"] ||= this.form.company.name;

      if (!Object.keys(this.languageHash).includes(this.$i18n.locale)) this.setLocale(this.settings.defaultLanguage)
    },
    replaceVariables(text) {
      return Utils.replaceVariablesWithValue(text, this.settings.variables);
    }
  },
  computed: {
    filteredQuestions () {
      if (this.questions.length == 0) return this.questions
      var target_q, source_q
      let questions = [...this.questions]
      let removeQuestion = (action, type, answer, value, length) => {
        let match        = isMatch(type, String(answer), String(value))
        let source_index = questions.findIndex(q => q.id == source_q.id)
        let target_index = questions.findIndex(q => q.id == target_q.id)

        if (action == 'jump') {
          if (match) {
            questions.splice(source_index + 1, target_index - source_index - 1)
            return
          }
          else return
        }
        // if the condition is TRUE, it should show, if action=hide, match==FALSE
        else if ((action == 'hide' && !match) || (action == 'show' && match)) return;

        questions.splice(target_index, length || 1)
      }
      let isMatch = (type, answer, value) => {
        if (!source_q.answer) return true;
        let res = false;
        if      (type == 'is_not_equal_to')  res = answer != value;
        else if (type == 'is_equal_to')      res = answer == value;
        else if (type == 'contains')         res = source_q.answer.includes(value);
        else if (type == 'doesnt_contain')   res = !source_q.answer.includes(value);
        else if (type == 'more_than')        res = answer > value;
        else if (type == 'less_than')        res = answer < value;
        else if (type == 'more_or_equal_to') res = answer >= value;
        else if (type == 'less_or_equal_to') res = answer <= value;
        else if (type == 'begins_with')      res = answer.startsWith(value);
        else if (type == 'ends_with')        res = answer.endsWith(value);
        return res;
      }
      this.conditions.forEach(c => {
        if (!c.published) return;

        source_q   = questions.find(q => q.id == c.source.id);
        let answer = Array.isArray(source_q?.answer) ? source_q?.answer?.join(',') : source_q?.answer;

        if (c.target.id == "thank_you" && isMatch(c.source.type, answer, c.source.value)) {
          let index = questions.findIndex(q => q.id == source_q.id);
          questions.splice(index + 1, questions.length - index);
          return questions;
        }

        if (c.level == 'question') {
          target_q = questions.find(q => q.id == c.target.id)
          if (!target_q || !source_q) return;
          removeQuestion(c.source.action, c.source.type, answer, c.source.value);
        }
        else if (c.level == 'group') {
          let target_g = this.form.survey.items.find(q => q.id == c.target.id);
          let length   = target_g?.object?.length;
          target_q     = target_g?.object?.[0];

          if (!target_q || !source_q) return;

          removeQuestion(c.source.action, c.source.type, answer, c.source.value, length);
        }
      })
      return questions
    },
    carousel() {
      const carouselElement = document.querySelector("#questions-carousel");
      return new Carousel(carouselElement, { ride: false, touch: false });
    }
  },
  async created() {
    this.info = Utils.deepClone(this.$route.query);
    const info = { ...this.info, token: undefined };

    this.$router.push({ path: this.$route.path });
    this.$route.query = {};

    axios
      .get('/v3/feedback/responses/new', { params: {
        key: this.$route.params.key,
        info: JSON.stringify(info)
      }})
      .then(this.setResponse)
      .then(this.loadingSurvey = false)
      .catch((err) => this.error(err));
  }
};
</script>

<template>
  <div>
    <Layout v-if="!loadingSurvey" :backgroundImage="settings.bg" :slide="slide" :questions="filteredQuestions" :bgColor="settings.color" :class="{ 'alternative-background': !form.survey && hasError, 'cursor-loading': loading }">
      <div class="carousel-item active" v-if="form.survey">
        <!-- HEAD SLIDE - language and welcome message -->
        <div class="container-fluid question-modal">
          <nav class="nav nav-pills nav-fill">
            <img class="cover-logo" :src="settings.logo" alt="Company Logo" v-if="settings.logo"/>

            <div v-if="settings.availableLanguages.length > 1" class=" language-selector mt-3 mt-sm-0 d-flex flex-column justify-content-center " >
              <label class="mb-2">{{ $t("feedback-form.languages.selector") }}</label>
              <multiselect :multiple="false" v-model="settings.currentLanguage" :options="settings.availableLanguages" :searchable="true"
                :showLabels="false" :customLabel="(option) => languageHash[option]" @select="setLocale" @remove="setLocale" :allow-empty="false" > </multiselect>
            </div>
          </nav>

          <div class="title"> {{ useWelcomeTranslation('title') }} </div>
          <div class="description" style='white-space: break-spaces'> {{ useWelcomeTranslation('instructions') }} </div>

          <div class="nav-bar mt-1">
            <button type="button"
              class="btn btn-outline-primary btn-lg shadow-sm text-capitalize"
              :class="{ disabled: loading }"
              @click="nextPage(-1)"
            >
              {{ $t("feedback-form.start") }}
            </button>
          </div>
        </div>
      </div>

      <!-- Questions -->
      <template v-for="(question, index) in filteredQuestions" :key="question.id">
        <div class="carousel-item py-3" v-if="can(question)">
          <!-- Question Modal -->
          <div class="container-fluid question-modal" style="max-height: calc(100vh - 20px);" :id="`question_modal_${question.id}`"
            :class="{
              'slide-up-from-middle': /rating|nps/.test(question.type) && question.enabled_followup &&  question.showFollowup && question.followupContext,
              'slide-down-from-up':   /rating|nps/.test(question.type) && question.enabled_followup && !question.showFollowup && question.followupContext
            }"
          >
            <img class="cover-logo" :src="settings.logo" alt="Company Logo" v-if="settings.logo"/>

            <div class="title" v-if="question.texts" v-html="replaceVariables(question.texts.question[settings.currentLanguage] || question.texts.question[settings.defaultLanguage])"> </div>

            <div class="description" v-if="question.texts" v-html="replaceVariables(question.texts.description[settings.currentLanguage] || question.texts.description[settings.defaultLanguage])" ></div>

            <div class="links text-left w-100 mb-3 mt-2" v-if='question.links && question.links.length'>
              <label>Links</label>
              <div class="" v-for='link in question.links' :key='(link[settings.currentLanguage] || link.default).name'>
                <a :href="(link[settings.currentLanguage] || link.default).url" target='_blank'> {{ (link[settings.currentLanguage] || link.default).title }} </a>
              </div>
            </div>

            <component :is="questionHash[question.type]" :question="question" :editing="false" :settings="settings" @answerChanged='answerChanged($event, index)' @nextPage="nextPage(index)" />

            <div class="nav-bar d-flex justify-content-center mt-1">
              <button type="button"
                class="btn btn-back btn-outline-secondary btn-lg shadow-sm me-2 text-capitalize"
                :class="{ disabled: loading }"
                @click="previousPage(index)"
              >
                {{ $t("feedback-form.back") }}
              </button>

              <div :class="{ 'cursor-not-allowed': !valid(question) }" >
                <button type="button"
                  class="btn btn-outline-primary btn-lg shadow-sm text-capitalize"
                  :class="{ disabled: !valid(question) || loading }"
                  @click="nextPage(index)"
                >
                  {{ forwardButtonLabel(question) }}
                </button>
              </div>
            </div>
          </div>

          <!-- Follow up -->
          <div
            class="container-fluid question-modal"
            :class="{
              'slide-up-from-down':     /rating|nps/.test(question.type) &&  question.showFollowup && question.followupContext,
              'slide-down-from-middle': /rating|nps/.test(question.type) && !question.showFollowup,
              'd-none': !/rating|nps/.test(question.type) || !question.enabled_followup
            }"
            :ref="`slider_follow_up_${question.id}`"
          >
            <img class="cover-logo" :src="settings.logo" alt="Company Logo" v-if="settings.logo"/>

            <div class="title"> {{ $t('feedback-form.follow_up', settings.currentLanguage) }} </div>

            <textarea :ref="`follow_up_${question.id}`" v-model="question.follow_up" class="form-control" rows="4"></textarea>

            <div class="nav-bar d-flex justify-content-center mt-1">
              <button type="button"
                class="btn btn-back btn-outline-secondary btn-lg shadow-sm me-2 text-capitalize"
                :class="{ disabled: loading }"
                @click="question.showFollowup = false; question.followupContext = true"
              >
                {{ $t("feedback-form.back") }}
              </button>

              <div :class="{ 'cursor-not-allowed': !valid(question) }" >
                <button type="button"
                  class="btn btn-outline-primary btn-lg shadow-sm text-capitalize"
                  :class="{ disabled: !valid(question) || loading }"
                  @click="nextPage(index)"
                >
                  {{ forwardButtonLabel(question) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="carousel-item" v-if="form.survey">
        <TailSlide :settings='settings' :completed="form.completed" />
      </div>
    </Layout>

    <div v-else class="loading-user-data fw-bold">
      <div class="spinner-border text-success me-3"></div>
      {{ $t("feedback-form.loading_form_data") }}
    </div>
  </div>
</template>
