<template>
  <div>
    <h3>{{ $t('signup.signup_to_paid.login') }}</h3>
    <span>{{ $t('signup.signup_to_paid.login_message') }}</span>
    <b-form-input
      ref="inputRef"
      v-model="localFormData.password"
      type="password"
      placeholder="Enter your password"
      @input="emitFormData"
    ></b-form-input>
  </div>
</template>

<script>
export default {
  props: ['formData', 'loginUser'],
  data() {
    return {
      localFormData: { ...this.formData },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.inputRef.focus();
    });
  },
  methods: {
    emitFormData() {
      this.$emit('update:formData', this.localFormData);
    },
  },
};
</script>