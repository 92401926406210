<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import dayjs from "dayjs"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      loading: false,
      // Chart
      labels: this.sentimentRatings,
      chartData: [],
      chartOptions: {
        chart: { toolbar: { show: false } },
        colors: ["#2f2a95", "#44469b", "#5962a0", "#e86c74", "#f184a8", "#f99cdc"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "70%",
            dataLabels: {
              position: 'top',
            }
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0, 1, 2, 3, 4, 5],
          offsetY: -22,
          offsetX: 0,
          style: {
            fontSize: "12px",
            colors: ["#313131"]
          },
        },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        grid: { borderColor: "#fff" },
        fill: { opacity: 1 },
        yaxis: {
          labels: {
            formatter: (value) => String(value),
            style: {
              colors: ["#000"],
              fontSize: '14px',
              fontWeight: 500,
            },
          }
        },
        xaxis: {
          labels: {
            style: {
              colors: ["#000"],
              fontSize: '14px',
              fontWeight: 500,
            },
          }
        },
        legend: {
          show: true,
          fontWeight: 500,
          fontSize: "12px",
          labels: { colors: ["#000"] }
        }
      },

      // Table
      tableData: {
        subscriber: { current: {}, previousMonth: {}, change: null },
        compset:    { current: {}, previousMonth: {}, change: null }
      },
      cols: [
        { field: "subscriber", label: "Subscriber", colspan: 3 },
        { field: "compset",    label: "Comp-Set",   colspan: 3 }
      ],
      subCols: [
        { field: "", label: "" }
      ],
      subColsFields: [
        { field: "current",  label: "This Month" },
        { field: "previousMonth", label: "Last Month" },
        { field: "change",   label: "% Change" },
      ],
      rowsLabels: [
        // { field: "", label: "Avg. Review Score" },
        { field: "positive", label: "Percent of Positive Reviews" },
        { field: "neutral",  label: "Percent of Neutral Reviews" },
        { field: "negative", label: "Percent of Negative Reviews" },
        { field: "total",    label: "Total Reviews" },
      ]
    };
  },
  components: { ExportExcel },
  props: ['company', 'identifier', 'release_date', 'sentimentRatings'],
  async created() {
    Utils.setLoading.bind(this)(true);
    this.cols.forEach(() => this.subCols = this.subCols.concat(this.subColsFields));

    // subscriber current month
    let responseSC = await axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      compset: false,
      format:  "hash"
    }});
    this.chartData.push({ ...responseSC.data.data[0], period: "Subscriber Current Month" });
    this.tableData.subscriber.current = responseSC.data.data[0] || {};

    // subscriber previous month
    let responseSP = await axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: dayjs(this.release_date).subtract(1, 'month').format("YYYY-MM-DD"),
      compset: false,
      format:  "hash"
    }});
    this.chartData.push({ ...responseSP.data.data[0], period: "Subscriber Previous Month" });
    this.tableData.subscriber.previousMonth = responseSP.data.data[0] || {};

    // subscriber same month last year
    let responseSPY = await axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: dayjs(this.release_date).subtract(1, 'year').format("YYYY-MM-DD"),
      compset: false,
      last_12m: true,
      format:  "hash"
    }});
    this.chartData.push({ ...responseSPY.data.data[0], period: "Subscriber Same Month Last Year" });

    // compset current month
    let responseCC = await axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      compset: "only",
      property: "merged",
      format:  "hash"
    }});
    this.chartData.push({ ...responseCC.data.data[0], period: "Comp-Set Current Month" });
    this.tableData.compset.current = responseCC.data.data[0] || {};

    // compset previous month
    let responseCP = await axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: dayjs(this.release_date).subtract(1, 'month').format("YYYY-MM-DD"),
      compset: "only",
      property: "merged",
      format:  "hash"
    }});
    this.chartData.push({ ...responseCP.data.data[0], period: "Comp-Set Previous Month" });
    this.tableData.compset.previousMonth = responseCP.data.data[0] || {};

    // compset same month last year
    let responseCPY = await axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: dayjs(this.release_date).subtract(1, 'year').format("YYYY-MM-DD"),
      last_12m: true,
      compset: "only",
      property: "merged",
      format:  "hash"
    }});
    this.chartData.push({ ...responseCPY.data.data[0], period: "Comp-Set Same Month Last Year" });

    // calculate change
    ["subscriber", "compset"].forEach(properties => {
      this.rowsLabels.forEach(row => {
        // calculate percent of negative and positive reviews
        ["current", "previousMonth"].forEach(period => {
          if (row.field != "total")
            this.tableData[properties][period][row.field] = Utils.round(100*this.tableData[properties][period][row.field] / this.tableData[properties][period]["total"], 2) + "%";
        });

        if (row.field != "total") {
          this.tableData[properties].change = { ...this.tableData[properties].change };
          this.tableData[properties].change[row.field] = Utils.round(parseFloat(this.tableData[properties].current?.[row.field]) - parseFloat(this.tableData[properties].previousMonth?.[row.field]), 2);
          if (row.field == "negative")
            this.tableData[properties].change[row.field] = -1*this.tableData[properties].change[row.field];
        } else {
          this.tableData[properties].change = { ...this.tableData[properties].change };
          this.tableData[properties].change[row.field] = Utils.round(100 * (this.tableData[properties].current?.[row.field] - this.tableData[properties].previousMonth?.[row.field]) / this.tableData[properties].previousMonth?.[row.field], 2);
        }
      });
    });

    if (this.$refs["column-chart"])
      this.$refs["column-chart"].updateOptions({ labels: this.labels.map(l => l.label) });

    Utils.setLoading.bind(this)(false);
  },
  computed: {
    series() {
      let res = [];
      this.chartData.forEach(data => {
        let series = res.find(e => e.name == data.name);
        if (!series) {
          let mountData = [];
          this.labels.forEach(label => {
            if (!data[label.field] && data[label.field] != 0) mountData.push(null);
            else mountData.push(Utils.round(data[label.field], 1));
          });
          res.push({ name: data.period, data: mountData });
        }
      });
      return res;
    }
  },
  methods: {
    compareTrend(value) {
      return Number.parseFloat(value) >= 0;
    },
    isNotNull(value) {
      return (value || value == 0) && value != "-";
    }
  }
}
</script>

<template>
  <div>
    <h3 class="title mt-3">Pulse Score By Property Detail - All Channels (Current Month, Previous Month & Same Month Last Year)</h3>
    <apexchart
      class=""
      height="350"
      type="bar"
      dir="ltr"
      :series="series"
      :options="chartOptions"
      ref="column-chart"
    ></apexchart>

    <div class="table-responsive mt-3" v-if="!loading">
      <ExportExcel title='Review Overview - All Reviews' >
        <table id="review_overview-all_reviews" class="table table-striped table-bordered table-nowrap mb-0 text-center">
          <thead>
            <tr>
              <th style="width: 350px;" :id="`th1_review_overview-all_reviews_empty`"></th>
              <th v-for="(col, index) in cols" :key="col.label" :colspan="col.colspan" :id="`th1_review_overview-all_reviews_${index}`"> {{ col.label }} </th>
            </tr>
            <tr>
              <th class="property-name-column" :id="`th2_review_overview-all_reviews_name`"></th>
              <th v-for="(subCol, index) in subCols.slice(1)" :key="subCol.field + index" :id="`th2_review_overview-all_reviews_${index}`">{{ subCol.label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, indexRow) in rowsLabels" :key="row.field + indexRow">
              <td class="property-name-column" :id="`td_review_overview-all_reviews_${indexRow}_name`">{{ row.label }}</td>
              <template v-for="(col, indexCol) in cols">
                <template v-for="(subCol, indexSubCol) in subColsFields" :key="col.label + subCol.field">
                  <td :id="`td_review_overview-all_reviews_${indexRow}_${indexCol}_${indexSubCol}`">
                    <div :class="{ 'd-flex align-items-center justify-content-center': subCol.field == 'change' }">
                      <template v-if="subCol.field == 'change' && isNotNull(tableData[col.field][subCol.field][row.field])">
                        <i class="arrow-trend" :class="{ 'mdi mdi-arrow-up text-success': compareTrend(tableData[col.field][subCol.field][row.field]), 'mdi mdi-arrow-down text-danger': !compareTrend(tableData[col.field][subCol.field][row.field]) }"></i>
                        <span>{{ tableData[col.field][subCol.field][row.field] + "%" }}</span>
                      </template>

                      <template v-else-if="!tableData[col.field][subCol.field][row.field] && tableData[col.field][subCol.field][row.field] != 0">
                        <span>-</span>
                      </template>

                      <template v-else>
                        <span>{{ tableData[col.field][subCol.field][row.field] }}</span>
                      </template>
                    </div>
                  </td>
                </template>
              </template>
            </tr>
          </tbody>
        </table>
      </ExportExcel>
    </div>
  </div>
</template>
