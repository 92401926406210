<script>
import ExportUtils from "@/js/export-utils"

export default {
  data() {
    return {
      worksheets: [
        {
          name:   'export',
          refRow: null,
          tables: [],
        },
      ],
      styles: [],

      borders: ['Left', 'Right', 'Top', 'Bottom'],

      elements: [
        'ss:Workbook', 'ss:Worksheet', 'ss:Table', 'ss:Column', 'ss:Row', 'ss:Cell', 'ss:Data',
        'ss:DocumentProperties', 'ss:Author', 'ss:Created',
        'ss:Styles', 'ss:Style', 'ss:NumberFormat',
        'ss:Font', 'ss:Alignment', 'ss:Interior', 'ss:Borders', 'ss:Border',
        //attributes
        'ss:ID', 'ss:Name', 'ss:Type', /ss:StyleID/gi,
        'ss:Index', 'ss:Span', 'ss:Width', 'ss:Height', 'ss:MergeAcross',
        'ss:Color', 'ss:Bold', 'ss:Size',
        'ss:Pattern', 'ss:Horizontal', 'ss:Vertical', 'ss:Position', 'ss:Weight', 'ss:LineStyle',
      ],
    }
  },

  methods: {
    ...ExportUtils,
    xlsDownload(filename) {
      let link  = document.createElement('a')
      link.download = `${filename}.xls`
      link.href = 'data:application/vnd.ms-excel;base64,' + this.base64(this.xls())
      link.click()
    },
    xls() {
      // Excel2003XML format:
      // - specs https://learn.microsoft.com/en-us/previous-versions/office/developer/office-xp/aa140066(v=office.10)
      // - read impl. at https://github.com/PHPOffice/PHPExcel/blob/master/Classes/PHPExcel/Reader/Excel2003XML.php#L400
      let xls = `<?xml version="1.0"?><?mso-application progid="Excel.Sheet"?>
${this.$refs.xls.innerHTML}`
      for (let e of this.elements) //HTML is case-insensitive, Excel not
        if (e instanceof RegExp) xls = xls.replace(e, e.source)
        else xls = xls.replaceAll(e.toLowerCase(), e)

      return xls
    },

    prepare() {
      this.generateKeys()
      this.generateStyles()
    },
    reset() {
      this.worksheets[0].tables = []
      this.styles = []
    },

    generateKeys() {
      let wi = 0
      for (let w of this.worksheets) {
        w.key    = `${w.name}-${wi++}`
        w.refRow = Array.from(w.tables.at(-1).element.rows).at(-1)
        for (let t of w.tables) {
          t.key = `${w.key}-${t.title}`
          for (let r of t.element.rows) {
            r.key = `${t.key}-${r.rowIndex}`
            for (let c of r.cells)
              c.key = `${r.key}-${c.cellIndex}`
          }
        }
      }
    },
    generateStyles() {
      let styles = {}
      for (let w of this.worksheets)
        for (let t of w.tables)
          for (let r of t.element.rows)
            for (let c of r.cells) {
              let s = this.elemStyle(c)
              let k = JSON.stringify(s)
              styles[k] ||= []
              styles[k].push({cell: c, style: s})
            }

      let i = 1
      this.styles = []
      for (let s in styles) {
        let cells = styles[s]
        let style = cells[0].style
        style.id  = `s${i++}`
        this.styles.push(style)
        for (let c of cells.map(c => c.cell))
          c.styleid = style.id
      }
    },

    elemStyle(c) {
      let css   = getComputedStyle(c)
      let style = {id: null}

      style.bold    = parseInt(css.fontWeight) > 400 ? 1 : 0
      style.size    = parseInt(css.fontSize)

      if (c.children.length == 1 && !/TH/.test(c.tagName)) {
        let childStyle = getComputedStyle(c.children[0])
        style.color    = this.rgb2hex(childStyle.color)
        style.bgcolor  = this.rgb2hex(childStyle.backgroundColor)
      } else {
        style.color    = this.rgb2hex(css.color)
        style.bgcolor  = this.rgb2hex(css.backgroundColor) || '#FFFFFF'
      }

      let h = css.textAlign
      style.horizontal = h.charAt(0).toUpperCase() + h.slice(1)
      let v = css.verticalAlign == 'middle' ? 'center' : css.verticalAlign
      style.vertical   = v.charAt(0).toUpperCase() + v.slice(1)

      style.bd = {}
      for (let b of this.borders) {
        style.bd[b] = {}
        let weight = parseInt(css[`border${b}Width`]) + 1
        if (!weight) continue
        style.bd[b].weight = weight
        style.bd[b].color  = this.rgb2hex(css[`border${b}Color`])
      }

      return style
    }
  },
}
</script>

<template>
    <div ref=xls style='display: none'>
      <ss:Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">
        <ss:DocumentProperties xmlns="urn:schemas-microsoft-com:office:office">
          <ss:Author>Olery Dashboard</ss:Author>
          <ss:Created>1671723347076</ss:Created>
        </ss:DocumentProperties>

        <ss:Styles>
          <ss:Style ss:ID=Currency ><ss:NumberFormat ss:Format="Currency"></ss:NumberFormat></ss:Style>
          <ss:Style ss:ID=Date ><ss:NumberFormat ss:Format="Medium Date"></ss:NumberFormat></ss:Style>
          <ss:Style ss:ID=theader >
            <ss:Font ss:Bold=1 ss:Size=16 />
          </ss:Style>

          <ss:Style v-for='s in styles' :key=s.id :ss:ID=s.id >
            <ss:Font v-if=s.color :ss:Color=s.color :ss:Bold=s.bold :ss:Size=s.size />
            <ss:Alignment :ss:Horizontal=s.horizontal :ss:Vertical=s.vertical />
            <ss:Interior v-if=s.bgcolor :ss:Color=s.bgcolor ss:Pattern=Solid />
            <ss:Borders>
              <template v-for='b in borders'>
                <ss:Border v-if=s.bd[b].weight :key='`${s.id}-${b}`' :ss:Position=b :ss:Color=s.bd[b].color :ss:Weight=s.bd[b].weight ss:LineStyle=Continuous />
              </template>
            </ss:Borders>
          </ss:Style>
        </ss:Styles>

        <ss:Worksheet v-for='w in worksheets' :key=w.key :ss:Name=w.name >
          <ss:Table>
            <template v-if=w.refRow >
              <ss:Column v-for='(c,i) in w.refRow.cells' :key='`col-${c.key}`' :ss:Index=i+1 :ss:Width=parseInt(c.offsetWidth) />
            </template>

            <template v-for='(t, ti) in w.tables'>
              <template v-if=t.title >
                <ss:Row v-if='ti > 0' :key='`e-${t.key}`' />
                <ss:Row :key=t.key >
                  <ss:Cell :ss:MergeAcross=w.refRow.cells.length-1 ss:StyleID=theader >
                    <ss:Data ss:Type=String > {{t.title}} </ss:Data>
                  </ss:Cell>
                </ss:Row>
              </template>

              <ss:Row v-for='r in t.element.rows' :key=r.key :ss:Height=r.offsetHeight >
                <ss:Cell v-for='c in r.cells' :key=c.key :ss:StyleID=c.styleid :ss:MergeAcross=c.colSpan-1 >
                  <ss:Data ss:Type=String >
                    {{c.innerText}}
                  </ss:Data>
                </ss:Cell>
              </ss:Row>
            </template>
          </ss:Table>
        </ss:Worksheet>

      </ss:Workbook>
    </div>
</template>
