<script>
export default {
  page: {
    title: "Reputation Dashboard",
  },
  data() {
    return {
      isDashboard: false,
    };
  },
};
</script>

<template>
  <router-view></router-view>
</template>
