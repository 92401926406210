import axios from 'axios'

export const state     = { userData: {} }
export const getters   = {
  user: (state) => () => state.userData
}
export const mutations = {
  saveUserData(state, userData) {
    state.userData = userData
  },
  saveUserDataPromise(state, promise) {
    state.userDataPromise = promise
  },
}
export const actions = {
  async fetch({ state, commit, dispatch }) {
    if (state.userDataPromise) return state.userDataPromise

    let promise = axios.get(`/v3/users/my`).then((response) => {
      const userData = response.data.data
      commit('saveUserData', userData)
      dispatch("contract/setContracts", userData, { root: true })
      return userData
    })
    commit('saveUserDataPromise', promise)
    return promise
  },
}
