<script>
import Reports   from "@/js/reports";

export default{
  data () {
    return {
      data: null
    }
  },
  props:      ['start_date', 'end_date', 'settings', 'company_id'],
  components: {},
  mounted() {
    this.load()
  },
  methods: {
    ...Reports.requests,
    async load () {
      this.loading   = true

      let r          = await this.getReports('most-reviews-day')
      this.data      = r?.slice(1)
      this.loading   = false
    }
  }
}
</script>

<template>
  <div style='background-color: var(--bs-primary); color: white; padding: 7px; text-align: center;' v-if='data && data[0]'>
    <span :style="`font-size: ${settings.fontsize || 14}px`" > {{ $t('reports.bar_most_reviews_day.text', { d: data[0] && data[0][0] }) }} </span>
  </div>
</template>
