<script>
import Utils     from "@/js/utils";
import DataUtils from "@/js/data-utils";

export default {
  data() {
    return {
      loading: true,
      data: [],
      travelCompositionIconClasses: Utils.travelCompositionIconClasses,
      minimumChartHeight: 0,
      maximumChartHeight: 10
    };
  },
  props: ["settings"],
  async created() {
    await this.load();
    this.data = this.data.sort((a, b) => this.topKpi(b).review_count - this.topKpi(a).review_count).slice(0, 3).reverse();
    const scores = this.data.map(d => Utils.round(this.topKpi(d).review_count, 2));
    const chartHeightOffset  = scores[0] * 0.8;
    this.minimumChartHeight = Utils.round(Number.parseFloat(scores[0]) - chartHeightOffset, 1);
    this.maximumChartHeight = Utils.round(Number.parseFloat(scores.at(-1)) + chartHeightOffset, 1);
  },
  methods: {
    ...Utils.kpis,
    ...DataUtils,
    async load() {
      Utils.setLoading.bind(this)(true);
      let response = await this.$store.dispatch("kpis/executive", { ...this.settings, sub_context: "data" });
      if (response[this.settings.segmentOption]) this.data = [this.saveData(response, this.settings)].flat();
      Utils.setLoading.bind(this)(false);
    },
    calculateHeight(kpi) {
      let value = Utils.round(this.topKpi(kpi).review_count, 1);
      return Utils.round(100*(value - this.minimumChartHeight)/(this.maximumChartHeight - this.minimumChartHeight), 2) + "%";
    },
    topLabel(kpi) {
      if (this.settings.segmentOption == "top_compositions") return this.$t(`executive_kpis.labels.${kpi.key}`);
      return kpi.key;
    },
    topKpi(kpi) {
      return kpi.current[this.settings.ratingType] || {}
    },
    formatNumber(val) {
      return Utils.formatNumber(val);
    }
  }
}
</script>

<template>
  <div class="executive-kpi d-flex flex-column" :class="{ loading }">
    <!-- Card with KPI title and tooltip -->
    <div class="title-indicator" @click="data.length && redirect(settings, data)">
			<div class="card text-center m-0 custom-shadow">
				<div class="card-body d-flex justify-content-center align-items-center" :title="$t('executive_kpis.see_in_more_detail')">
					<h5 class="card-title m-0">{{ $t(`executive_kpis.${settings.segmentOption}`) }}</h5>
					<span class="ms-2 mt-1" v-b-tooltip.hover :title="$t(`executive_kpis.help.${settings.segmentOption}`)">
						<i class="mdi mdi-information-outline"></i>
					</span>
				</div>
			</div>
		</div>

    <template v-if="!loading && data.length">
      <a href='#' :title="$t('executive_kpis.see_in_more_detail')" class='text-end mt-3 me-2' @click="redirect(settings, data)">
        <i class='font-size-20 fa fa-external-link-alt'></i>
      </a>

      <div class="visualization-bar">
        <template v-for="(kpi, index) in data" :key="kpi.key">
          <div class="single-bar">

            <div class="w-100">
              <div class="single-data">
                <p class="mb-0 me-2 rubik-font font-size-18"> {{ formatNumber(topKpi(kpi).review_count) }} </p>
                <!-- trend arrow -->
                <div class="trend d-flex flex-column justify-content-center align-items-center"
                :class="`text-${topKpi(kpi).trend >= 0 ? 'success' : 'danger'}`">
                  <i class="arrow-trend" :class="{ 'mdi mdi-arrow-up': topKpi(kpi).trend >= 0, 'mdi mdi-arrow-down': topKpi(kpi).trend < 0 }"></i>
                  <p class="m-0 rubik-font"> {{ formatNumber(topKpi(kpi).trend) + "%" }} </p>
                </div>
              </div>

              <!-- Divider -->
              <div class="divider-title">
                <div class="border-bottom border-dark" :style="`width: calc(100% - 20px);`"></div>
                <div class="name mt-2"> {{ topLabel(kpi) }} </div>
              </div>
            </div>

            <!-- Bar -->
            <div class="top-wrapper" :style="`height: ${calculateHeight(kpi)};`">
              <div class="podium-bar top d-flex justify-content-center align-items-end" :class="`top-${3 - index}`"></div>
              <div class="bigger">
                <span v-if="settings.segmentOption == 'top_nationalities'" class="flag-shadow flag w-100 h-100" :class="`flag-${locales(kpi.key.toLowerCase(), 'country').code}`"></span>
                <span v-else class="travel-composition-icon" :class="travelCompositionIconClasses[kpi.key]"></span>
              </div>
            </div>

          </div>
        </template>
      </div>
    </template>

    <div v-else class="d-flex align-items-center justify-content-center h-100 mt-5">
      <div class="alert alert-warning d-flex alert-box align-items-center px-4" role="alert">
        <div class="d-flex align-items-center">
          <i class="mdi mdi-magnify-remove-outline font-size-24 me-2"></i>
          <span class="font-size-16 mb-0">{{ $t("general.no_data") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
