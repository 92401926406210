<script>
export default{
  data() {
    return {
      show: false,
      company_id: this.$route.query.cid || this.$route.query.company_id,
      retries: 0
    }
  },
  props: ["filterParams"],
  watch: {
    filterParams: function () {
      this.loadListener(this.filterParams);
    }
  },
  mounted() {
    this.loader()
  },
  beforeUnmount() {
    if (window.ota_widget) {
      window.ota_widget.initted = false
      window.ota_widget.tag     = null
    }
  },
  methods: {
    loadListener(params) {
      this.company_id = params.data.company_id
      this.load()
    },
    load() {
      if (this.retries > 50) {
        console.error("Insights: maximum retry limit exceeded")
        return true
      }

      this.retries++
      if (!this.company_id || !window.ota_widget || !window.ota_widget.api) return;
      window.ota_widget.locale         = this.$store.state.locale.locale
      window.ota_widget.api.company_id = this.company_id
      window.ota_widget.load(this.$store.state.token, this.$store.state.ep, this.$store.state.code) // reload the tag to re-mount
      return true
    },
    loader() { // retry loading the widget until the scripts are loaded
      if (!this.load) setTimeout(this.loader, 200)
    }
  }
}
</script>

<template>
  <div>
    <ota-widget v-pre style="display: none" v-if='show'>
      <h1>
        <span>{w.data.name}</span>
      </h1>
      <div class="cached_at" show="{w.data.cached_at}">
        {t('review_date', {date: w.data.cached_at})}
      </div>
      <div class="mdc-image-list mdc-image-list--masonry masonry-image-list responsive-widget">
        <overall-rating v-pre></overall-rating><ratings v-pre></ratings><mentions v-pre></mentions><guests v-pre></guests>
      </div>
    </ota-widget>
  </div>
</template>