<script>
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import axios from "axios";
import Utils from "@/js/utils";
import DataUtils from "@/js/data-utils";
import Swal from "sweetalert2";
import Editor from '@tinymce/tinymce-vue';

export default {
  data() {
    return {
      user: {},
      loading: true,
      copied: false,
      template: {
        name: null,
        language: null,
        contract_id: null,
        subject: null,
        text_body: null,
        html_body: null,
        attachments: []
      },
      tinymceScriptLoaded: false,
      templatesCache: {},
      locale: null,
      defaultLocale: null,
      distributions: [],
      distribution: {},
      availableLocales: ["en", "nl", "de", "fr", "it", "ar", "ru", "sk", "nb", "pt", "cs", "pl","el", "zh-TW", "zh-CN", "ja", "tr", "es", "id", "da", "fi", "sv"],
      editingVariable: null,
      variables: {},
      curlBase: `curl 'https://agora.olery.com/v3/feedback/distributions/send_mail?auth_token=AUTH_TOKEN' \\
-H 'content-type: application/json' \\
--data-raw`,
      codeExampleModal: false,
      editorOptions: {
        auto_focus: "mce-editor",
        language: null,
        skin: false,
        content_css: false,
        promotion: false,
        branding: false,
        height: 850,
        plugins: "variables codeeditor advlist anchor codesample help image lists link media searchreplace table template visualblocks emoticons",
        toolbar1: "codeeditor insertVariable sendTest | undo redo | fontfamily fontsize lineheight blocks | bold italic underline strikethrough subscript superscript | backcolor forecolor removeformat | alignleft aligncenter alignright alignjustify | bullist numlist searchreplace | link anchor image table codesample emoticons",
        menubar: "file edit view insert format tools table help",
        // code editor
        codeeditor_wrap_mode: true,
        codeeditor_font_size: 14,
        // image upload
        images_upload_url: "/",
        images_upload_handler: (blobInfo, progress) => new Promise(function (resolve, failure) {
          let size = blobInfo.blob().size;
          if (size > 10**6) {
            failure(this.$t("general.image_too_large", { size: Utils.round(size / 10**6, 2), sizeLimit: 1 }));
            return;
          }
          let id = 0;
          if (this.template.attachments.length != 0) id = parseInt(this.template.attachments.map(a => a.ContentID).sort().at(-1).substr(19)) + 1;

          let a = this.template.attachments.find(b => b.Content == blobInfo.base64());
          if (!a) {
            this.template.attachments.push({
              Name:        blobInfo.filename(),
              ContentID:   `cid:embedded-image-${id}`,
              ContentType: blobInfo.blob().type,
              Content:     blobInfo.base64()
            });
          }
          resolve("data:" + blobInfo.blob().type + ";base64," + blobInfo.base64());
          progress(100);
        }.bind(this)),
        setup: function (editor) {
          editor.on("variableToHTML", function (e) {
            this.variables[e.id] = e.cleanValue;
          }.bind(this));

          editor.ui.registry.addButton("previewButton", {
            icon: "preview",
            text: this.$t("mail_template.edit.preview"),
            onAction: function () {
              editor.execCommand("mcePreview");
            }
          });

          // add/edit variables button
          editor.ui.registry.addToggleButton("insertVariable", {
            icon: "plus",
            text: this.$t("mail_template.edit.add_variable"),
            onAction: function () {
              editor.windowManager.open({
                title: this.$t("mail_template.edit.add_variable"),
                body: {
                  type: "panel",
                  items: [{
                    type: "input",
                    name: "variable_input", // identifier
                    inputMode: "text",
                    label: this.$t("mail_template.edit.variable_name"),
                    placeholder: this.$t("mail_template.edit.add_variable_help"),
                    disabled: false,
                    maximized: true
                  }],
                },
                buttons: [
                  { type: "cancel", text: this.$t("mail_template.list.cancel") },
                  { type: "submit", text: this.$t("mail_template.edit.save"), primary: true },
                ],
                initialData: {
                  variable_input: this.variables[this.editingVariable] ? this.variables[this.editingVariable] : ""
                },
                onSubmit: function (e) {
                  let value = e.getData().variable_input;
                  if (!this.editingVariable) editor.execCommand("mceInsertContent", false, `{{ ${value} }}`);
                  else {
                    let node = editor.dom.get(this.editingVariable);
                    node.outerHTML = `{{ ${value} }}`;
                    delete this.variables[this.editingVariable];
                    editor.fire("changeVariableName");
                    this.template.html_body = editor.getContent({ source_view: !0 });
                  }
                  this.editingVariable = null;
                  e.close();
                }.bind(this)
              });
            }.bind(this),
            onSetup: function (api) {
              const nodeChangeHandler = (ev) => {
                if (ev.isVariableClick) this.editingVariable = ev.id;
                else this.editingVariable = null;
                return api.setActive(ev.isVariableClick);
              }
              editor.on("variableClick", nodeChangeHandler);
            }.bind(this)
          });

          // send test button
          editor.ui.registry.addToggleButton("sendTest", {
            icon: "export",
            text: this.$t("mail_template.edit.send_test"),
            onAction: async function () {
              if (this.isDirty) {
                let response = await Swal.fire({
                  title: this.$t("general.are_you_sure"),
                  html:  this.$t("general.changes_not_saved"),
                  icon: "question",
                  showCancelButton:  true,
                  showConfirmButton: true,
                  allowOutsideClick: false
                });
                if (!response.isConfirmed) return;
              }

              editor.windowManager.open({
                title: this.$t("mail_template.edit.send_email_test_values"),
                body: {
                  type: "panel",
                  items: this.sendTestEmailFields
                },
                buttons: [
                  { type: "cancel", text: this.$t("mail_template.list.cancel") },
                  { type: "submit", text: this.$t("mail_template.edit.send_test"), primary: true },
                ],
                initialData: {
                  destination_email: this.user.email
                },
                onSubmit: function (e) {
                  let values = e.getData();
                  let email = values.destination_email;
                  delete values.destination_email;
                  this.sendEmail(values, email);
                  Swal.fire({
                    text: this.$t("mail_template.edit.send_test_info"),
                    icon: "info",
                    showCancelButton: false,
                    showConfirmButton: true,
                    allowOutsideClick: true,
                    backdrop: true
                  });
                  e.close();
                }.bind(this)
              });
            }.bind(this)
          });
        }.bind(this)
      }
    };
  },
  components: {
    PageHeader,
    Multiselect,
    'tinymceEditor': Editor
  },
  async created() {
    await this.loadTinyMCESCript();
    setTimeout(function () { this.tinymceScriptLoaded = true }.bind(this), 1000)

    this.user = await this.$store.dispatch("user/fetch");
    this.editorOptions.language = this.$i18n.locale;
  },
  mounted() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  methods: {
    ...DataUtils,
    async loadTinyMCESCript() {
      let url = "https://dashboard-assets.olery.com/js/tinymce/tinymce.min.js"
      if (process.env.NODE_ENV == "development") url = "/js/tinymce/tinymce.min.js"
      let script = document.createElement('script')
      if (document.querySelector(`script[src="${url}"]`)) return;

      script.src   = url
      script.type  = 'text/javascript'
      script.async = false;
      document.head.appendChild(script)
    },
    async loadDistributions(id) {
      if (!this.distributions.length) this.distributions = await this.$store.dispatch("distributions/fetch");
      this.distribution  = this.distributions.find(d => Object.values(d.mail_template_ids).includes(Number(id)));
      this.defaultLocale = this.distribution?.survey?.default_locale;
    },
    async setAvailableLocales() {
      let surveys       = Utils.deepClone(await this.$store.dispatch("surveys/fetch", { contract_id: this.contract.id }));
      surveys           = surveys.filter(s => s.status == "active").reduce((res, curr) => {
        res[curr.id] = curr;
        return res;
      }, {});

      let distributions = this.distributions.filter(d => Object.values(d.mail_template_ids).includes(this.template.id));
      let surveyIds     = [...new Set(distributions.map(d => d.survey_id))];
      let locales       = surveyIds.flatMap(sId => (surveys[sId]?.languages || []).map(l => l.code || l));

      this.availableLocales = [...new Set(locales)];
    },
    async checkDefaultLocale() {
      let availableTemplateLocales = Object.keys(this.distribution.mail_template_ids);
      // if the default locale != english and is not listed, create another template translated into the defLocale
      if (!availableTemplateLocales.includes(this.defaultLocale)) {
        this.loading = true;
        await this.createChild(this.defaultLocale);
        await this.autotranslate();
        this.locale = this.defaultLocale;
        this.loading = false;
      }
    },
    async loadTemplate(id) {
      let template;
      if (this.templatesCache[id]) template = Utils.deepClone(this.templatesCache[id]);
      else {
        let response = await axios.get(`/v3/mail_templates/${id}`, { params: { subscription_id: this.contract.id } });
        template = response.data.data;
        this.templatesCache[id] = Utils.deepClone(template);
        this.locale = template.language;
      }
      return template;
    },
    async setTemplate(id) {
      this.template = await this.loadTemplate(id);
      this.findSubjectVariables(); // find variables from the subject field
    },
    async updateTemplate() {
      Utils.setLoading.bind(this)(true);
      Utils.updatePageOpts({ statusMessage: { status: "saving", icon: "ion ion-md-sync" } });
      try {
        let diff = this.loadDiff(["html_body", "attachments"]); // only check html_body and attachments

        let response = await axios.post(`/v3/mail_templates/${this.template.id}`,
          { ...diff, subject: this.template.subject },
          { params: { subscription_id: this.contract.id }}
        );
        this.template = response.data.data;
        this.templatesCache[this.template.id] = Utils.deepClone(this.template);
        this.findSubjectVariables();
      } catch (err) { this.error(err); }
      Utils.updatePageOpts({ statusMessage: { status: "saved", icon: "mdi mdi-check-bold" } });
      Utils.setLoading.bind(this)(false);
    },
    loadDiff(fields) {
      this.computedTemplate = this.removeVariablesStyles(this.computedTemplate);
      return fields.reduce((res, key) => {
        let currentValue = this.template[key], originalValue = this.templatesCache[this.template.id][key];
        if (typeof this.template[key] != "string") {
          currentValue  = JSON.stringify(this.template[key]);
          originalValue = JSON.stringify(this.templatesCache[this.template.id][key]);
        }
        currentValue  = currentValue.replace(/(?:\r\n|\r|\n|&nbsp;|\s)/g, "");
        originalValue = originalValue.replace(/(?:\r\n|\r|\n|&nbsp;|\s)/g, "");
        if (currentValue != originalValue) return { ...res, [key]: this.template[key] };
        return res;
      }, {});
    },
    async createChild(locale) {
      let parentId       = this.distribution.mail_templates[0].id
      let parentTemplate = await this.loadTemplate(parentId);
      let payload        = {
        ...Utils.slice(parentTemplate, ["html_body", "name", "subject", "attachments"]),
        language: locale,
        parent_id: parentTemplate.id,
        subscription_id: parentTemplate.contract_id
      };

      // create the template
      let template = await axios.put("/v3/mail_templates", payload);
      template = template.data.data;

      // associate it to the distribution
      let distribution = await axios.post(`/v3/feedback/distributions/${this.distribution.id}`, { mail_template_ids: {
        ...this.distribution.mail_template_ids,
        [locale]: template.id
      }});
      distribution = distribution.data.data;

      this.template = template;
      this.distribution = distribution;
      this.templatesCache[this.template.id] = Utils.deepClone(this.template);
      this.findSubjectVariables();
    },
    async autotranslate() {
      this.loading = true;
      try {
        let payload = {
          data: {
            subject:   this.addEscapeTranslation(this.template.subject),
            html_body: this.addEscapeTranslation(this.template.html_body)
          },
          locale: this.template.language
        };
        let response = await axios.post("/v3/translate", payload);
        this.template.html_body = this.removeEscapeTranslation(response.data.data?.["html_body"]?.[0]);
        this.template.subject   = this.removeVariablesStyles(response.data.data?.["subject"]?.[0]);
        this.updateTemplate();
        this.loading = false;
      } catch (err) { this.error(err); }
    },
    removeVariablesStyles(content) {
      // remove the variable styles before update
      let nodeList = [];
      let aux = document.createElement("div");
      aux.innerHTML = `${content}`;

      const loopChildren = (node) => {
        if (node.children.length == 0) {
          let original = node.getAttribute("data-original-variable");
          if (original != null) nodeList.push({ node: node, value: original });
          return;
        }
        for (const child of node.children) {
          loopChildren(child);
        }
      };

      loopChildren(aux);
      nodeList.forEach(node => node.node.outerHTML = node.value);
      return aux.innerHTML;
    },
    findSubjectVariables() {
      let regex = new RegExp("{{([A-Za-z0-9. _]*)?}}", "g");
      let matches = this.template.subject.match(regex) || [];
      matches.forEach((match, index) => {
        let cleanValue = match.replace(/[^a-zA-Z0-9._]/g, "");
        this.variables[`subject_${cleanValue}_${index}`] = cleanValue;
      });
    },
    addEscapeTranslation(text) {
      let content = `${text}`;
      let regex = /{{/;
      let match = content.match(regex) || [];
      let newContent = "";

      while (match) {
        newContent += content.substring(0, match.index) + "<span translate='no' ";
        content = content.substring(match.index);
        match   = content.match(/}}/);

        newContent += `data-original-variable='${content.substring(0, match.index + 2)}'>${content.substring(2, match.index)}</span>`;
        content = content.substring(match.index + 2);
        match   = content.match(regex);
      }
      newContent += content;
      return newContent;
    },
    removeEscapeTranslation(text = "") {
      let content = `${text}`;
      let regex = /<span translate='no' data-original-variable='/; //45
      let match = content.match(regex) || [];
      let newContent = "";

      while (match) {
        newContent += content.substring(0, match.index);
        content = content.substring(match.index + 45);
        match   = content.match(/'>/);
        let variable = content.substring(0, match.index);

        match   = content.match("</span>") //7
        content = content.substring(match.index + 7);
        newContent += variable;
        match   = content.match(regex);
      }
      newContent += content;
      return newContent;
    },
    hashToImages() {
      let content = this.template.html_body;
      const regex = /src="cid:embedded-image-/;
      let newContent = "";
      let match = content.match(regex);

      while (match) {
        newContent += content.substring(0, match.index + 5);
        content = content.substring(match.index + 5);
        match = content.match(/"/);

        let data = this.template.attachments?.find(att => att.ContentID == content.substring(0, match.index));
        newContent += `data:${data?.ContentType};base64,${data?.Content}"`;
        content = content.substring(match.index + 1);
        match = content.match(regex);
      }
      newContent += content;
      return newContent;
    },
    imagesToHash(content) {
      const regex = /src="/;
      let newContent = "";
      let match = content.match(regex);

      while (match) {
        newContent += content.substring(0, match.index + 5);
        content = content.substring(match.index + 5);
        match = content.match(/"/);

        if (!content.substring(0, match.index).includes("http")) {
          let contentBase64 = content.substring(0, match.index).split("base64,")?.[1]
          let attachment = this.template.attachments?.find(att => att.Content == contentBase64);
          if (attachment) newContent += attachment.ContentID;
        }
        else newContent += content.substring(0, match.index);
        content = content.substring(match.index);
        match = content.match(regex);
      }
      newContent += content;
      return newContent;
    },
    async sendEmail(values, email) {
      Utils.setLoading.bind(this)(true);
      try {
        await axios.post("/v3/feedback/distributions/send_mail", {
          key: this.distribution.key,
          language: this.locale,
          to: email,
          data: values,
          subscription_id: this.contract.id
        });
      } catch (err) { this.error(err); }
      Utils.setLoading.bind(this)(false);
    },
    confirmStayInDirtyForm() {
      return !window.confirm(this.$t("new_survey.confirm_leave"));
    },
    beforeWindowUnload(event) {
      if (this.isDirty && this.confirmStayInDirtyForm()) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    humanizedLocale(locale) {
      let i18n = this.locales(locale).name;
      if (locale == this.defaultLocale) return this.$t("mail_template.edit.default_locale", { defaultLocale: i18n });
      return i18n;
    },
    copyTooltipText() {
      if (this.copied) return this.$t("new_survey.share.copied");
      else return this.$t("new_survey.share.copy_to_clipboard");
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.curl);
      this.copied = true;
    },
    error(error) {
      Swal.fire(this.$t("general.error"), this.$t("general.contact_support") + JSON.stringify(error), "error");
      Utils.setLoading.bind(this)(false);
    }
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    isDirty() {
      let diff = this.loadDiff(["html_body", "attachments", "subject"]);
      return Object.keys(diff).length > 0;
    },
    showAutotranslateButton() {
      return !this.loading && this.template.id && this.defaultLocale && this.template.language != this.defaultLocale;
    },
    computedTemplate: {
      set(value) {
        this.template.html_body = this.imagesToHash(value);
      },
      get() {
        if (this.template.html_body) return this.hashToImages();
        return "";
      }
    },
    curl() {
      let variables = Object.values(this.variables).filter(Utils.uniq);
      let payload   = {
        key: this.distribution.key,
        language: this.locale,
        to: this.user.email,
        data: variables.reduce((res, curr) => {
          return { ...res, [curr]: curr + "_Value" }
        }, {})
      };
      return `${this.curlBase} '${JSON.stringify(payload, null, 4)}'`;
    },
    sendTestEmailFields() {
      return [...Object.values(this.variables).filter(Utils.uniq).map(v => {
        return {
          type: "input",
          name: v,
          inputMode: "text",
          label: v,
          placeholder: this.$t("mail_template.edit.send_test_var_help", { var: v }),
          disabled: false,
          maximized: true
        };
      }),
      {
        type: "input",
        name: "destination_email",
        inputMode: "email",
        label: this.$t("mail_template.edit.destination_email"),
        placeholder: this.$t("mail_template.edit.destination_email_help"),
        disabled: false,
        maximized: true
      }];
    },
    details() {
      const distributions = this.distributions.filter(d => Object.values(d.mail_template_ids).includes(this.template.id));
      return {
        companies: [...new Set(distributions.map(d => d.company.name))].join(", "),
        surveys:   [...new Set(distributions.map(d => d.survey.name))].join(", ")
      };
    }
  },
  watch: {
    "locale": function () {
      if (this.distribution.mail_template_ids) {
        this.loading = true;
        let id = this.distribution.mail_template_ids[this.locale];
        this.variables = {};
        if (id) this.setTemplate(id);
        else this.createChild(this.locale);
        this.loading = false;
      }
    },
    contract: {
      handler: async function () {
        if (this.$route.params.id && this.contract) {
          this.loading = true;
          await this.setTemplate(this.$route.params.id);
          await this.loadDistributions(this.$route.params.id);
          await this.setAvailableLocales();
          await this.checkDefaultLocale();
          this.loading = false;
        }
      },
      immediate: true
    }
  }
};
</script>

<template>
  <div class="w-100">
    <div :class="{ 'loading': loading }">
      <PageHeader>
        <template v-slot:html>
          <div class="d-flex align-items-center">
            <span class="me-2">{{ $t("mail_template.edit.title") }}</span>
            <a class="chevron font-size-14 fw-normal d-flex align-items-center" data-bs-toggle="collapse" href="#more_info" aria-expanded="false" aria-controls="more_info">
              <i class="mdi mdi-chevron-down font-size-22 mt-1"></i>{{ $t("general.more_info") }}
            </a>
          </div>
          <div class="collapse table-responsive font-size-14 fw-normal mt-2 border rounded border-dark bg-light p-2" id="more_info" style="font-family: Roboto;">
            <div class="d-flex flex-column" style="gap: 10px;">
              <span>{{ $t("mail_template.edit.more_info") }}:</span>
              <span><label>{{ $t("general.companies") }}:&nbsp;</label>{{ details.companies }}</span>
              <span><label>{{ $t("general.surveys") }}:&nbsp;</label>{{ details.surveys }}</span>
            </div>
          </div>
        </template>
      </PageHeader>

      <div class="row">
        <!-- Locale Selector -->
        <div class="col-12 col-sm-3">
          <label class="form-label"> {{ $t("mail_template.edit.locale") }} </label>
          <multiselect v-model="locale" :multiple="false" :options="availableLocales" :customLabel="l => humanizedLocale(l)" :showLabels="false" :allow-empty="false" :placeholder="$t('mail_template.edit.locale_help')">
            <template v-slot:singleLabel="props">
              <div class="d-flex align-items-center">
                <span :class="`flag flag-${locales(props.option, 'language').code}`" class="me-2"></span>
                <span> {{ humanizedLocale(props.option) }} </span>
              </div>
            </template>

            <template v-slot:option="props">
              <div class="d-flex align-items-center">
                <span :class="`flag flag-${locales(props.option, 'language').code}`" class="me-2"></span>
                <span> {{ humanizedLocale(props.option) }} </span>
              </div>
            </template>
          </multiselect>
        </div>

        <!-- Save -->
        <div class="col-12 col-sm-2">
          <label class="form-label" style="visibility: hidden;">l</label>
          <button class="btn btn-primary w-100" @click="updateTemplate">{{ $t("mail_template.edit.save") }}</button>
        </div>
      </div>

      <div class="row mb-4 mt-3">
        <!-- Subject -->
        <div class="col-12" :class="{ 'col-sm-10': showAutotranslateButton }">
          <label class="form-label"> {{ $t("mail_template.edit.subject") }} </label>
          <input class="form-control" type="text" v-model="template.subject" :placeholder="$t('mail_template.list.subject_help')"/>
        </div>

        <div class="col-12 col-sm-2" v-if="showAutotranslateButton">
          <label class="form-label" style="visibility: hidden;">l</label>
          <button class="btn btn-primary w-100" @click="autotranslate">{{ $t("mail_template.edit.autotranslate", { defaultLocale: locales(defaultLocale).name }) }}</button>
        </div>
      </div>

      <div class="card">
        <div class="card-body row gy-2">
          <div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 d-flex align-items-center">
            <i18n-t scope="global" tag="div" keypath="mail_template.edit.postmark_documentation">
              <template v-slot:postmark>
                Postmark
              </template>
              <template v-slot:link>
                <a class="text-primary" href="https://postmarkapp.com/support/article/1077-template-syntax" target="_blank" rel="noopener">
                  {{ $t("mail_template.edit.postmark_documentation_link", { postmark: "Postmark" }) }}
                  <i class="font-size-14 fa fa-external-link-alt"></i>
                </a>
              </template>
            </i18n-t>
          </div>
          <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
            <button class="btn btn-outline-secondary w-100"
            @click="codeExampleModal = !codeExampleModal">{{ $t("mail_template.edit.code_example") }}</button>
          </div>
        </div>
      </div>

      <b-modal v-model="codeExampleModal" id="code-example" centered hide-footer header-class="border-0 ps-4 pb-0" body-class="p-0" size="lg">
        <template #modal-title>
          <div class="font-size-24">{{ $t("mail_template.edit.code_example") }}</div>
        </template>
        <div class="px-4 pb-4 pt-3 d-flex flex-column">
          <span>{{ $t("mail_template.edit.code_example_instructions") }}</span>

          <div class="mt-3 border rounded border-dark bg-light px-3 pt-3 pb-1 d-flex flex-column justify-content-between">
            <pre class="text-secondary mb-0">{{ curl }}</pre>
            <a class="align-self-end mb-1" href="#" @click="copyToClipboard()" v-b-tooltip.hover.html="copyTooltipText()" @mouseover="copied = false"><i class="mdi mdi-content-copy font-size-16"></i></a>
          </div>
        </div>
      </b-modal>

      <!-- Template Editor -->
      <div class="tinyMCE">
        <tinymceEditor id="mce-editor" v-if="editorOptions.language && tinymceScriptLoaded" ref="editor" v-model="computedTemplate" :init="editorOptions"></tinymceEditor>
      </div>

    </div>
  </div>
</template>
