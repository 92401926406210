<script>
export default {
  data() {
    return {
      answer: null,
      test: null
    }
  },
  props: ["question", "settings", "editing"],
  created() {
    if (!this.question["answer"]) this.question.answer = null;
    this.answer = this.question.answer;

    if (this.editing) this.answer = true;

    window.addEventListener("message", this.loadListener);
  },
  beforeUnmount () {
    window.removeEventListener("message", this.loadListener);
  },
  methods: {
    loadListener(msg) {
      let taHostRegex = /^http[s]?:\/\/(www|rcp-demo.ext)\.tripadvisor\.com$/;
      if (taHostRegex.test(msg.origin) && msg.data == "review_complete") {
        setTimeout(() => this.$emit("nextPage"), 1000);
      }
      else return;
    }
  },
  computed: {
    showIframe() {
      return this.editing || this.answer;
    },
    hasIframeUrls() {
      return Object.keys(this.settings.ta_urls || {}).length > 0;
    },
    iframeUrl() {
      if (this.hasIframeUrls) {
        if (this.editing) return this.settings.ta_urls[Object.keys(this.settings.ta_urls)[0]];
        return this.settings.ta_urls[this.settings.ta_company.id];
      }
      return null;
    }
  }
};
</script>

<template>
  <div class="w-100">
    <div v-if="editing && !hasIframeUrls" class="rounded alert alert-warning px-3 py-3">
      {{ $t("new_survey.questions.edit.trip_advisor_no_urls") }}
    </div>
    <template v-else>
      <div id="check_radio" data-type="radio" class="w-100 align-self-center d-flex flex-row text-left justify-content-around font-size-18" :class="{ 'pointer-events-none': editing }">
        <b-form-group v-slot="{ ariaDescribedby }" class="d-flex">
          <b-form-radio key='yes' v-model="answer" :aria-describedby="ariaDescribedby" name="question" :value="true" @change="$emit('answerChanged', answer)" size='lg'>{{ $t('feedback-form.yes') }}</b-form-radio>
          <b-form-radio key='no' v-model="answer" :aria-describedby="ariaDescribedby" name="question" :value="false" @change="$emit('answerChanged', answer)" size='lg'>{{ $t('feedback-form.no') }}</b-form-radio>
        </b-form-group>
      </div>
      <div id="iframe" class="w-100" :class="{ 'pointer-events-none': editing }" v-show="hasIframeUrls && showIframe">
        <iframe style="min-height: 1200px;" :src="iframeUrl" frameborder="0" width="100%" scrolling="no"></iframe>
      </div>
    </template>
  </div>
</template>
