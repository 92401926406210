<script>
export default {
  data() {
    return {
      localPageNumber: null,
    };
  },
  props: ["title", "data", "index"],
  created() {
    this.$parent.pageNumber++;
    this.localPageNumber = this.$parent.pageNumber;
  }
}
</script>

<template>
  <div class="page" :id="localPageNumber">
    <div class="content">
      <h2 class="title mt-0" v-if="index == 1 || index == null"> {{ title }} </h2>

      <slot />

      <div class="footer d-flex justify-content-between align-items-center py-2 mx-3">
        <img src="@/assets/images/reports/replab/footerlogo.png" style="height: 30px;">

        <span v-if="data.type == 'month_end'"> {{ `${data.company.name}_RepLab_MonthEnd_${data.month} ${data.year}` }} </span>
        <span v-if="data.type == 'weekly'"> {{ `${data.company.name} - Seven Day Period Ending ${data.month} ${data.day}, ${data.year}` }} </span>
        <span v-if="data.type == 'enterprise_monthly'"> {{ `${data.company.name} Enterprise_MonthEnd_${data.month} ${data.year}` }} </span>

        <div>
          <img src="@/assets/images/reports/replab/footerlogo.png" style="height: 30px; visibility: hidden;">
          <span> {{ localPageNumber }} </span>
        </div>
      </div>
    </div>
  </div>
</template>
