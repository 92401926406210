import { defineRule, configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import { required, min, max, min_value, max_value, alpha_dash, email, url } from '@vee-validate/rules';
import en from '@vee-validate/i18n/dist/locale/en.json';
import nl from '@vee-validate/i18n/dist/locale/nl.json';
import de from '@vee-validate/i18n/dist/locale/de.json';
import it from '@vee-validate/i18n/dist/locale/it.json';
import fr from '@vee-validate/i18n/dist/locale/fr.json';
import es from '@vee-validate/i18n/dist/locale/es.json';
import pt from '@vee-validate/i18n/dist/locale/pt_PT.json';
import fi from '@vee-validate/i18n/dist/locale/fi.json';
import sv from '@vee-validate/i18n/dist/locale/sv.json';
import no from '@vee-validate/i18n/dist/locale/nn_NO.json';
import { i18n } from './locales.js'

defineRule('required', required)
defineRule('min', min)
defineRule('max', max)
defineRule('min_value',  min_value)
defineRule('max_value',  max_value)
defineRule('alpha_dash', alpha_dash)
defineRule('email', email)
defineRule('url', url)

// Custom rules
defineRule('upper_case', (value, [limit], ctx) => {
  if (!value || !value.length) return true;
  let upperCaseCount = value.length - value.replace(/[A-Z]/g, '').length;
  if (upperCaseCount < limit) return i18n.global.t("validation.upper_case", { field: ctx.name, limit });
  return true;
})

defineRule('same', (value, [toMatch]) => {
  if (!value || !value.length) return true;
  if (value != toMatch) return i18n.global.t("validation.same");
  return true;
})

defineRule('different', (value, [toMatch]) => {
  if (!value || !value.length) return true;
  if (value == toMatch) return i18n.global.t("validation.different");
  return true;
})

// Install locales.
localize({ en, nl, de, it, fr, es, pt, fi, sv, no });

configure({
  generateMessage: localize({ en, nl, de, it, fr, es, pt, fi, sv, no }),
  validateOnInput: true
});