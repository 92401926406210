<script>
import axios from 'axios'
import Utils from "@/js/utils";

export default {
  data() {
    return {
      data: [
        { name: this.$t('dashboard.rating_comparison.numerical_rating'), key: 'numerical' },
        { name: this.$t('dashboard.rating_comparison.sentiment_rating'), key: 'sentiment' },
        { name: this.$t('dashboard.rating_comparison.tes_score'),        key: 'tes'       },
      ],
      keys: ['cleanliness','facilities', 'service', 'fnb', 'value','location', 'ambience'],
      numerical: null,
      sentiment: null,
      loading:   false
    };
  },
  props: ["filterParams"],
  watch: {
    filterParams: function () {
      this.loadListener(this.filterParams);
    }
  },
  methods: {
    loadListener(params) {
      var p = Object.assign({ group_by: 'ratings' }, { ...params.data })
      this.load(p)
    },
    async load(params) {
      Utils.setLoading.bind(this)(true)
      let p = {
        company_ids: params.company_ids,
        group_ids:   params.group_ids,
        start_date:  params.start_date,
        end_date:    params.end_date,
        group_by:    params.group_by
      }
      // numerical
      let numerical = await axios.get('/v3/ratings/kpi', { params: p });
      this.numerical = numerical.data.data;

      // sentiment
      let sentiment = await axios.get('/v3/sentiment/kpi', { params: p });
      this.sentiment = sentiment.data.data;

      // tes
      let tes = await axios.get('/v3/tes/kpi', { params: p });
      this.tes = tes.data.data;

      this.data.forEach(ratingType => {
        this.keys.forEach(key => {
          let value = this[ratingType.key]?.[key]?.current?.value;
          if (ratingType.key == 'numerical') value = value / 10;
          ratingType[key] = Utils.round(value);
        });
      });

      Utils.setLoading.bind(this)(false)
    },
    formatNumber(val) {
      return Utils.formatNumber(val);
    }
  }
};
</script>

<template>
  <div style='min-height: 200px;' class="card" :class="{'loading': loading}">
    <div class="card-body">
      <div class="d-flex">
        <h4 class="card-title mb-4">{{ $t('dashboard.rating_comparison.title') }}</h4>
        <span class='ms-2' v-b-tooltip.hover :title="$t('general.rating_comparison_help')">
          <i class='mdi mdi-help-circle'></i>
        </span>
      </div>
      <div class="table-responsive">
        <table class="table table-centered table-nowrap mb-0 text-center">
          <thead class='thead-light'>
            <tr>
              <th class='text-left'>Rating</th>
              <th v-for='k in keys' :key='k'>{{ $t(`ratings.${k}`) }}</th>
            </tr>
          </thead>
          <tbody v-if='!numerical || !sentiment'>
            <tr><td colspan=8 class='text-center pt-4'><strong>{{ $t('dashboard.rating_comparison.no_results') }}</strong></td></tr>
          </tbody>
          <tbody v-if='numerical && sentiment'>
            <tr v-for="d of data" :key="d.name">
              <td class='text-left'><strong>{{d.name}}</strong></td>
              <td v-for='k in keys' :key='k'>{{ formatNumber(d[k]) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
