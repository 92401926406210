<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import Filters from "@/components/filter";
import Widgets from "./widgets";
import DataUtils from "@/js/data-utils";

export default{
  page: {
    title: "Insights",
    meta: [{ name: "description", content: appConfig.description }]
  },
  data() {
    return {
      title: "Insights",
      filterCols: ["company", "country", "regions", "city"],
      filterParams: {},
      widgets: {
        "riot/tag": ["ota-widget", "overall-rating", "ratings", "mentions", "guests"],
        "text/javascript": [
          "https://cdn.jsdelivr.net/lodash/4.5.1/lodash.min.js",
          "https://cdn.jsdelivr.net/npm/riot@3.13/riot+compiler.js",
          "https://unpkg.com/@popperjs/core@2",
          "https://unpkg.com/tippy.js@6",
          "https://cdn.jsdelivr.net/npm/moment@2.24.0/moment.min.js",
          "https://cdn.jsdelivr.net/npm/qs@6.9.0/dist/qs.js",
          "https://olery.github.io/ota_widget/js/ota_widget.js",
          "https://olery.github.io/ota_widget/js/locales.js"
        ]
      }
    };
  },
  components: { PageHeader, Filters, Widgets },
  created() {
    this.loadScripts();
  },
  methods: {
    ...DataUtils,
    loadScripts() {
      Object.keys(this.widgets).forEach(t => {
        let links = this.widgets[t];

        links.forEach(w => {
          let script = document.createElement("script");
          if (t == "riot/tag") {
            let link = `https://olery.github.io/ota_widget/tags/${w}.html`;
            if (document.querySelector(`script[src="${link}"]`)) return;
            script.src = link;
          } else {
            if (document.querySelector(`script[src="${w}"]`)) return;
            script.src = w;
          }
          script.type = t;
          script.async = false;
          document.head.appendChild(script);
        });
      });
    }
  }
}
</script>

<template>
  <div class="w-100">
    <link href="https://cdn.jsdelivr.net/npm/material-components-web@0.36.0/dist/material-components-web.min.css" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
    <link href="https://olery.github.io/ota_widget/css/ota_widget.css" rel="stylesheet">

    <div>
      <PageHeader :title="$t('insights.title')" />

      <Filters @filter:created="saveFilterParams" @filter:update="saveFilterParams" :cols="filterCols"/>

      <Widgets :filterParams="filterParams" />
    </div>
  </div>
</template>
