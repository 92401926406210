<script>
export default {
  data() {
    return {};
  },
  props: ["data", "error"],
  computed: {
    message() {
      if (this.error) return this.$t("reports.load_error");
      if (!this.data?.length) return this.$t("reports.no_data");
      return this.$t("general.loading");
    }
  }
}
</script>

<template>
  <div class="border border-2 rounded fw-bold d-flex align-items-center justify-content-center" :class="{ 'border-danger': error, 'border-primary': !error }" style="min-height:80px;">
    {{ message }}
  </div>
</template>