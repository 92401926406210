import * as Sentry from "@sentry/vue";
const sentryLoader = (app, router) => {
  if (process.env.NODE_ENV == 'development') return
  if (!process.env.VUE_APP_SENTRY_TOKEN) {
    console.warn('Missing sentry token')
    return
  }
  Sentry.init({
    app,
    dsn: `https://${process.env.VUE_APP_SENTRY_TOKEN}@sentry.olery.com/4`,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/https:\/\/guest-experience\.com/,/https:\/\/dashboard\.olery\.com/],
    // Performance Monitoring
    tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  return Sentry
}
export default sentryLoader;
