import axios from "axios";

export const state = { surveys: {}, surveysPromises: {} };

export const getters = {
  surveys: (state) => (survey_id) => state.surveys[survey_id]
};

export const mutations = {
  saveSurvey(state, { survey, surveyId }) {
    state.surveys[surveyId] = survey;
  },
  saveSurveyPromise(state, { promise, surveyId }) {
    state.surveysPromises[surveyId] = promise;
  },
};

export const actions = {
  async fetch({ commit, rootState }, params) {
    const surveyId = params.survey_id;
    const contract = !rootState.contract.currentContract;

    if (state.surveys[surveyId])         return state.surveys[surveyId];
    if (state.surveysPromises[surveyId]) return state.surveysPromises[surveyId];

    let promise = axios.get(`/v3/feedback/surveys/${surveyId}`, { params: { subscription_ids: contract.id} }).then(response => {
      const survey = response.data.data;
      commit("saveSurvey", { survey, surveyId });
      return survey;
    });

    commit("saveSurveyPromise", { promise, params: { surveyId } });
    return promise;
  },
}
