<script>
import XLSX from 'xlsx-js-style';
import ExportUtils from "@/js/export-utils";

export default {
  data() {
    return {
      borders: ['Left', 'Right', 'Top', 'Bottom'],
      canvas: null
    }
  },

  props: ['title', 'titleClass', 'tooltip', 'btnClass', 'btnText', 'target', 'fileName', 'hasData'],
  methods: {
    ...ExportUtils,
    async saveContent() {
      this.$emit("exportXLSX", "init")
      await this.$nextTick()

      const wb = this.buildWorkbook()
      XLSX.writeFile(wb, `${this.fileName || this.title}.xlsx`, { type: 'xlsx', bookSST: true, bookType: 'xlsx', compression: true, cellStyles: true })

      await this.$nextTick()
      this.$emit("exportXLSX", "end")
    },
    elemStyle(c) {
      let css   = getComputedStyle(c)
      let style = {}

      style.font = {
        bold: parseInt(css.fontWeight) > 400,
        sz:   parseInt(css.fontSize)
      }

      if (c.children.length == 1 && !/TH/.test(c.tagName)) {
        let childStyle   = getComputedStyle(c.children[0])
        style.font.color = { rgb: this.rgb2hex(childStyle.color, false) }
        style.fill       = { fgColor: { rgb: this.rgb2hex(childStyle.backgroundColor, false) || 'FFFFFF' } }
      } else {
        style.font.color = { rgb: this.rgb2hex(css.color, false) }
        style.fill       = { fgColor: { rgb: this.rgb2hex(css.backgroundColor, false) || 'FFFFFF' } }
      }

      style.alignment = {
        horizontal: c.className.split(" ").filter(c => /^center$|^left$|^right$/.test(c))[0] ?? css.textAlign,
        vertical:   css.verticalAlign == 'middle' ? 'center' : css.verticalAlign
      }

      style.border = {}
      for (let b of this.borders) {
        let weight = parseFloat(css[`border${b}Width`])
        if (!weight) continue
        style.border[b.toLowerCase()] = { style: "thin", color: { rgb: this.rgb2hex(css[`border${b}Color`], false) } }
      }

      style.width = this.textWidth(css.font, c.innerText) + 30

      return style
    },
    buildWorkbook() {
      this.createCanvas();
      let wb = XLSX.utils.book_new(); // workbook
      let table = [], colsWidth = [], rowsHeight = [], rowIndex = 0;

      for (let r of this.table.rows) {
        let row = [], cellIndex = 0
        for (let c of r.cells) {
          let styles = this.elemStyle(c)
          row.push({ v: c.innerText, t: "s", s: styles })
          if (cellIndex == 0) rowsHeight.push({ hpx: styles.font.sz + 8 })
          if (rowIndex == 0)  colsWidth.push({ wpx: styles.width })
          else if (styles.width > colsWidth[cellIndex].wpx) colsWidth[cellIndex].wpx = styles.width
          cellIndex++
        }
        table.push(row)
        rowIndex++
      }

      let ws = XLSX.utils.aoa_to_sheet(table) // worksheet with table
      ws['!cols'] = colsWidth
      ws['!rows'] = rowsHeight
      XLSX.utils.book_append_sheet(wb, ws, (this.fileName || this.title).slice(0, 31).replace(/\?/g, " ")) // add worksheet to workbook
      return wb
    }
  },
  computed: {
    computedTitleClass() {
      if (!this.titleClass) return "mt-3"
      return this.titleClass
    },
    table() {
      if (this.target) return document.querySelector(`#${this.target} table`)
      return this.$refs[this.title || this.fileName]?.firstElementChild
    }
  }
}
</script>

<template>
  <div>
    <div class="d-flex justify-content-between align-items-center" id="export-excel">
      <div :class="tooltip && 'd-flex align-items-center'">
        <h3 class="title" :class="computedTitleClass"> {{ title }} </h3>
        <span v-if="tooltip" class="ms-2" v-b-tooltip.hover :title="tooltip">
          <i class="mdi mdi-help-circle"></i>
        </span>
      </div>
      <div class="d-flex align-items-center" v-if="hasData">
        <slot name="header"></slot>
        <a @click=saveContent() class="btn hide-print" :class="btnClass">{{ btnText ?? "Export Excel" }}</a>
      </div>
    </div>
    <div :ref="title || fileName"><slot></slot></div>
  </div>
</template>
