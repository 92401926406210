<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"

export default {
  data() {
    return {
      labels: [],
      tableData: {
        reviews: [],
        average_rating: []
      },
      chartOptions: {
        series: [],
        chart: { toolbar: { show: false }, type: "line" },
        dataLabels: { enabled: false },
        stroke: { width: [0, 4] },
        grid: { borderColor: "#fff" },
        fill: { opacity: 1 },
        markers: { size: 6, strokeWidth: 0, colors: "#f1a939" },
        yaxis: [
          {
            title: {
              text: "Number of Reviews",
              style: {
                colors: ["#000"],
                fontSize: '14px',
                fontWeight: 500,
              },
            },
            labels: { formatter: val => parseInt(val) },
          },
          {
            title: {
              text: "Average Rating",
              style: {
                colors: ["#000"],
                fontSize: '14px',
                fontWeight: 500,
              },
            },
            opposite: true,
            tickAmount: 9,
            forceNiceScale: false,
          }
        ],
        xaxis: {
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: true,
            hideOverlappingLabels: false,
            showDuplicates: true,
            trim: false,
            style: {
              colors: ["#000"],
              fontSize: '14px',
              fontWeight: 500,
            }
          }
        },
        legend: {
          show: true,
          position: "bottom",
          fontWeight: 500,
          offsetY: 5,
          offsetX: 0,
          fontSize: "12px",
          labels: { colors: ["#000"] },
          markers: { fillColors: ["#2f2a95"] },
        }
      }
    };
  },
  props: ["title", "company", "identifier", "source", "release_date", "colors", "wtd"],
  created() {
    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      months_ago: 1,
      period: "day",
      date_format: "Dy DD",
      compset: false,
      format:  "hash",
      wtd: this.wtd
    }}).then(response => {
      this.labels = response.data.data.map(d => d.date_label);

      this.tableData["reviews"] = response.data.data.map(e => parseInt(e["reviews"]));
      this.tableData["average_rating"] = response.data.data.map(e => {
        if (e["average_rating"] && e["average_rating"] != 0)
          return Utils.round(e["average_rating"], 1);
        return e["average_rating"];
      });

      if (this.$refs["column-chart"])
        this.$refs["column-chart"].updateOptions({
          labels: this.labels,
          colors: [function({ seriesIndex, dataPointIndex, w }) { return this.colorizeSeries(seriesIndex, dataPointIndex, w) }.bind(this)],
          series: [
            { name: "Number of Reviews", type: "column", data: this.tableData.reviews },
            { name: "Average Rating",    type: "line",   data: this.tableData.average_rating }
          ]
        });
    });
  },
  methods: {
    colorizeSeries(seriesIndex, dataPointIndex, w) {
      if(seriesIndex == 1) return "#f1a939";
      if (/Fri|Sat|Sun/.test(w.globals.categoryLabels[dataPointIndex])) return "#999999";
      return "#2f2a95";
    }
  }
}
</script>

<template>
  <div>
    <h3 class="title mt-3"> {{ title }} </h3>
    <apexchart
      class="mt-5"
      height="550"
      dir="ltr"
      :series="[]"
      :options="chartOptions"
      ref="column-chart"
    ></apexchart>
  </div>
</template>
