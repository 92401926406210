<script>
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import Utils from "@/js/utils";
import DataUtils from "@/js/data-utils";
import axios from "axios";
import Swal  from "sweetalert2";
import { Field } from "vee-validate";

export default {
  data() {
    return {
      loading: false,
      user: {},
      originalUser: {},
      fields: ["first_name", "surname_prefix", "surname", "email", "locale", "password", "confirm_password"],
      availableLocales: ["en", "ca", "nl", "it", "fi", "fr", "de", "no", "pt", "es", "sv"],
      oldPasswordIncorrectCode: 422,
      passwordVisibility: { old_password: false, password: false, confirm_password: false }
    };
  },
  components: { PageHeader, Multiselect, Field },
  async created() {
    let data = await this.$store.dispatch("user/fetch");
    this.user = Utils.deepClone(data)
    this.originalUser = Utils.deepClone(data);
  },
  mounted() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  methods: {
    ...DataUtils,
    async saveSettings() {
      let params = { ...this.loadDiff(), confirm_password: undefined };
      Utils.setLoading.bind(this)(true);
      let response = await axios.post("/v3/users", params);
      if (response.status == this.oldPasswordIncorrectCode) return this.alert("incorrect_password");
      this.originalUser = response.data.data;
      this.$store.commit("user/saveUserData", response.data.data);
      if (params.locale) {
        this.$store.commit("locale/saveLocale", params.locale);
        this.$i18n.locale = params.locale;
      }
      Utils.setLoading.bind(this)(false);
    },
    loadDiff() {
      let diff = this.fields.reduce((res, field) => {
        if (this.user[field] != this.originalUser[field]) res[field] = this.user[field];
        return res;
      }, {});
      return diff;
    },
    validateFields() {
      if (!this.user.old_password && (this.user.password || this.user.confirm_password)) return this.alert("old_password_empty");
      if (this.user.password != this.user.confirm_password) return this.alert("passwords_not_match");
      if (!(this.user.email && this.user.email.length)) return this.alert("email_empty");
      this.saveSettings();
    },
    beforeWindowUnload(event) {
      if (this.isDirty && this.confirmStayInDirtyForm()) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    confirmStayInDirtyForm() {
      return !window.confirm(this.$t("new_survey.confirm_leave"));
    },
    alert(message) {
      Swal.fire(this.$t("general.error"), this.$t(`user_management.user_settings.${message}`), "error");
      Utils.setLoading.bind(this)(false);
    },
    togglePasswordVisibility(field) {
      this.passwordVisibility[field] = !this.passwordVisibility[field];
    }
  },
  computed: {
    isDirty() {
      let diff = this.loadDiff();
      return Object.keys(diff).length > 0;
    },
    passwordType() {
      return Object.entries(this.passwordVisibility).reduce((res, [key, value]) => {
        res[key] = value ? "text" : "password";
        return res;
      }, {});
    },
    eyeIcon() {
      return Object.entries(this.passwordVisibility).reduce((res, [key, value]) => {
        res[key] = value ? "mdi-eye-off-outline" : "mdi-eye-outline";
        return res;
      }, {});
    },
    rules() {
      let res = { old_password: "", password: "", confirm_password: "" };

      res.password = `min:6|upper_case:1|different:${this.user.old_password}`;
      if (this.user.password) res.confirm_password = `required|same:${this.user.password}`;
      if (this.user.password || this.user.confirm_password) res.old_password = "required";

      return res;
    }
  }
}
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('user_management.user_settings.title')" />

    <!-- Personal -->
    <div class="card" :class="{ 'loading': loading }">
      <div class="card-body">
        <h4 class="font-size-20 mb-4">{{ $t("user_management.user_settings.personal") }}</h4>
        <!-- Name -->
        <Field rules="required" :name="$t('user_management.user_settings.first_name')" v-slot="{ field, errors }" v-model="user.first_name">
          <div class="form-group px-sm-5 mx-sm-5">
            <label class="form-label">{{ $t("user_management.user_settings.first_name") }}</label>
            <div class="position-relative">
              <input type="text" class="form-control" :placeholder="$t('user_management.user_settings.name_help')" v-model="user.first_name" autocomplete="given-name" v-bind="field" />
            </div>
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </div>
        </Field>

        <!-- Prefix Surname -->
        <div class="form-group px-sm-5 mx-sm-5">
          <label class="form-label">{{ $t("user_management.user_settings.prefix_surname") }}</label>
          <div class="position-relative">
            <input type="text" class="form-control" :placeholder="$t('user_management.user_settings.prefix_surname_help')" v-model="user.surname_prefix" autocomplete="additional-name" />
          </div>
        </div>

        <!-- Surname -->
        <div class="form-group px-sm-5 mx-sm-5">
          <label class="form-label">{{ $t("user_management.user_settings.surname") }}</label>
          <div class="position-relative">
            <input type="text" class="form-control" :placeholder="$t('user_management.user_settings.surname_help')" v-model="user.surname" autocomplete="family-name" />
          </div>
        </div>

        <!-- E-mail -->
        <Field rules="email" :name="$t('user_management.user_settings.email')" v-slot="{ field, errors }" v-model="user.email">
          <div class="form-group px-sm-5 mx-sm-5">
            <label class="form-label">{{ $t("user_management.user_settings.email") }}</label>
            <div class="position-relative">
              <input type="email" class="form-control" :placeholder="$t('user_management.users.add_email_placeholder')" autocomplete="email" name="email" v-bind="field" />
            </div>
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </div>
        </Field>
      </div>
    </div>

    <!-- Password -->
    <div class="card" :class="{ 'loading': loading }">
      <div class="card-body">
        <h4 class="font-size-20 mb-4">{{ $t("user_management.user_settings.change_password") }}</h4>
        <!-- Old password -->
        <Field :rules="rules.old_password" :name="$t('user_management.user_settings.old_password')" v-slot="{ field, errors }">
          <div class="form-group px-sm-5 mx-sm-5">
            <label class="form-label">{{ $t("user_management.user_settings.old_password") }}</label>
            <div class="d-flex align-items-center">
              <input :type="passwordType.old_password" class="form-control" :placeholder="$t('user_management.user_settings.old_password_help')" v-model="user.old_password" v-bind="field" autocomplete="current-password" />
              <i class="mdi font-size-22 cursor-pointer ms-2" :class="eyeIcon.old_password" @click="togglePasswordVisibility('old_password')" v-b-tooltip.hover :title="$t('user_management.user_settings.toggle_password_visibility')"></i>
            </div>
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </div>
        </Field>

        <!-- New password -->
        <Field :rules="rules.password" :name="$t('user_management.user_settings.new_password')" v-slot="{ field, errors }">
          <div class="form-group px-sm-5 mx-sm-5">
            <label class="form-label">{{ $t("user_management.user_settings.new_password") }}</label>
            <div class="d-flex align-items-center">
              <input :type="passwordType.password" class="form-control" :placeholder="$t('user_management.user_settings.new_password_help')" v-model="user.password" v-bind="field" autocomplete="new-password" />
              <i class="mdi font-size-22 cursor-pointer ms-2" :class="eyeIcon.password" @click="togglePasswordVisibility('password')" v-b-tooltip.hover :title="$t('user_management.user_settings.toggle_password_visibility')"></i>
            </div>
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </div>
        </Field>

        <!-- Confirm new password -->
        <Field :rules="rules.confirm_password" :name="$t('user_management.user_settings.new_password')" v-slot="{ field, errors }">
          <div class="form-group px-sm-5 mx-sm-5">
            <label class="form-label">{{ $t("user_management.user_settings.confirm_new_password") }}</label>
            <div class="d-flex align-items-center">
              <input :type="passwordType.confirm_password" class="form-control" :placeholder="$t('user_management.user_settings.confirm_new_password_help')" v-model="user.confirm_password" v-bind="field" autocomplete="new-password" />
              <i class="mdi font-size-22 cursor-pointer ms-2" :class="eyeIcon.confirm_password" @click="togglePasswordVisibility('confirm_password')" v-b-tooltip.hover :title="$t('user_management.user_settings.toggle_password_visibility')"></i>
            </div>
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </div>
        </Field>
      </div>
    </div>

    <!-- Application -->
    <div class="card" :class="{ 'loading': loading }">
      <div class="card-body">
        <h4 class="font-size-20 mb-4">{{ $t("user_management.user_settings.application") }}</h4>
        <!-- Locale -->
        <div class="form-group px-sm-5 mx-sm-5">
          <label class="form-label">{{ $t("user_management.user_settings.locale") }}</label>
          <div class="position-relative">
            <multiselect :multiple="false" v-model="user.locale" :options="availableLocales" :custom-label="l => locales(l).name" :showLabels="false" :placeholder="$t('filter.select_option')" autocomplete="language">
              <template v-slot:singleLabel="props">
                <div class="d-flex align-items-center">
                  <span :class="`flag flag-${locales(props.option, 'language').code}`" class="me-2"></span>
                  <span> {{ locales(props.option).name }} </span>
                </div>
              </template>
              <template v-slot:option="props">
                <div class="d-flex align-items-center">
                  <span :class="`flag flag-${locales(props.option, 'language').code}`" class="me-2"></span>
                  <span> {{ locales(props.option).name }} </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
    </div>

    <button class="btn btn-primary mb-4 px-5 py-2 float-end" @click="validateFields"> {{ $t("new_survey.settings.save") }} </button>
  </div>
</template>
