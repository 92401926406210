<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <span class="d-none d-sm-inline-block">
            Made with
            <i class="mdi mdi-heart text-danger"></i> by Olery B.V.
          </span>
          &nbsp;-&nbsp;&nbsp;© {{ new Date().getFullYear() }}
        </div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>
