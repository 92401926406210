<script>
import axios from 'axios';
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import PaymentMethod from "@/components/payment_method.vue";
import dayjs from 'dayjs';

export default {
  components: {
    PageHeader,
    PaymentMethod,
  },
  data() {
    return {
      paymentMethodSaved: false,
      errors: [],
      paymentMethod: null,
      subscription: null,
      paymentMethods: [],
      subscriptionDetails: {},
      paymentHistoryModal: false,
      loading: true,
      openPaymentMethodModal: false
    };
  },
  created() {
    let relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime);
  },
  mounted() {
    this.subscription = this.$store.getters["contract/currentContract"];
    this.getSubscriptionInfo();
    this.loadPaymentMethods();
  },
  computed: {
    api_token() {
      return this.subscriptionDetails.api_token;
    },
    paymentHistory() {
      const ph = this.subscriptionDetails.subscribed_product?.payment_history.map(payment => {
        const paymentDate = dayjs(payment.date);

        const formattedAmount = new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR'
        }).format(payment.amount / 100);

        return {
          date: paymentDate.fromNow(),
          fullDate: paymentDate.format('YYYY-MM-DD HH:mm:ss'),
          amount: formattedAmount,
          status: payment.error ? payment.error.message : 'Success',
        };
      });
      return ph;
    },
    currentMonth() {
      return this.subscriptionDetails.subscribed_product?.current_month;
    },
    discountList() {
      return this.subscriptionDetails.subscribed_product?.specs.discounts.list;
    },
    discountedPrices() {
      const discountList = this.discountList;
      if (!discountList) return [];
      
      return discountList.map(discount => {
        return {
          period: discount.period,
          percent: discount.percent,
          initialPrice: this.totalFeaturesAmount,
          finalPrice: this.totalFeaturesAmount * (1 - discount.percent / 100),
        };
      });
    },
    discountedPrice() {
      const currentDiscount = this.discountList.find(discount => 
        this.currentMonth >= discount.period.months_start && this.currentMonth <= discount.period.months_end
      );

      if (currentDiscount) {
        return (this.totalFeaturesAmount * (1 - currentDiscount.percent / 100)).toFixed(2);
      }
      
      return this.totalFeaturesAmount.toFixed(2);
    },
    hasPaymentError() {
      return this.subscriptionDetails.subscribed_product?.payment_history.some(payment => payment.error !== null);
    },
    selectedFeatures () {
      return this.subscriptionDetails.subscribed_product?.specs.features.list.filter(feature => this.subscriptionDetails.subscribed_product?.selection.features.includes(feature.slug));
    },
    totalFeaturesAmount () {
      return this.selectedFeatures.reduce((acc, feature) => acc + feature.price, 0);
    },
    subscriptionProducts() {
      return this.subscriptionDetails.subscribed_product?.specs.features.list.map((feature) => {
        return {
          name: feature.label,
          price: feature.price,
          description: feature.description,
          slug: feature.slug,
        };
      });
    },
    subscriptionDiscounts() {
      return this.subscriptionDetails.subscribed_product?.specs.discounts.list.map((discount) => {
        return {
          period: discount.period.label,
          percent: discount.percent,
        };
      });
    },
    nextPaymentDate() {
      const date = new Date(this.subscriptionDetails.next_payment_date).toLocaleDateString();
      return date;
    },
  },
  methods: {
    setPaymentHistoryModal() {  
      this.paymentHistoryModal = true;
    },
    async handlePaymentMethodSaved(payment_method) {
      this.paymentMethodSaved = true;
      this.paymentMethod = payment_method;
    },
    async getSubscriptionInfo() {
      try {
        const response = await axios.get('/v3/payments/subscription', { params: { subscription_id: this.subscription.id } });
        this.subscriptionDetails = response.data.data;
      } catch (error) {
        this.errors = error.response?.data?.errors || [error.message];
      }
    },
    async loadPaymentMethods() {
      try {
        const response = await axios.get('/v3/payments/list_payment_methods', { params: { subscription_id: this.subscription.id } });
        this.paymentMethods = response.data.data.data;
        this.loading = false;
      } catch (error) {
        this.errors = error.response?.data?.errors || [error.message];
        this.loading = false;
      }
    },
    async submit() {
      const paymentMethodComponent = this.$refs.paymentMethodComponent;

      if (paymentMethodComponent) {
        await paymentMethodComponent.handleSubmit();

        if (this.paymentMethod) {
          try {
            await axios.post('/v3/payments/update_payment_method', {
              subscription_id: this.subscription.id,
              payment_method: {
                token: this.paymentMethod.token,
              }
            });
            this.openPaymentMethodModal = false;
          } catch (error) {
            this.errors = error.response?.data?.errors || [error.message];
          }
        }
      } else {
        console.error('PaymentMethod component not found');
      }
    },
    goToEditPaymentMethod() {
      this.openPaymentMethodModal = true
    },
    async cancelSubscription() {
      try {
        this.loading = true;
        let confirmation = await Swal.fire({
          title: this.$t('accounts.cancel_subscription_confirmation'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('accounts.cancel_subscription_confirm'),
          cancelButtonText: this.$t('accounts.cancel_subscription_cancel'),
        });

        if (!confirmation.isConfirmed) return;
        await axios.post('/v3/payments/cancel_subscription', { subscription_id: this.subscription.id });
        this.loading = false;
        this.$router.push({ name: 'accounts' });
      } catch (error) {
        this.loading = false;
        this.errors = error.response?.data?.errors || [error.message];
      }
    },
    copyToClipboard(token) {
      const el = document.createElement('textarea');
      el.value = token;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      alert('API token copied to clipboard!');
    },
  }
};
</script>

<template>
  <div :class="{ 'loading': loading, 'container-fluid': true }" v-if="this.subscriptionDetails && this.paymentMethods">
    <BContainer>
      <PageHeader :title="$t('accounts.title')" :tooltip="$t('accounts.title_tooltip')" />

      <!-- Payment Error Alert -->
      <BAlert variant="warning" :model-value="true" v-if="hasPaymentError">
        {{ $t('accounts.payment_error') }}
      </BAlert>

      <!-- Billing Details -->
        <div v-if="subscriptionDetails.subscribed_product">
          <h5>{{ $t('accounts.billing_details.title') }}</h5>
          <BCard>
            <BRow>
              <BCol class="d-flex justify-content-flex-start flex-column" md="6" xs="12">
                <span v-if="currentMonth > discountList[discountList.length-1].end_month">
                  <p>{{ totalFeaturesAmount }} {{ $t('accounts.billing_details.euros_per_month') }}</p>
                </span>
                <span v-else>
                  <span class="initial-price">{{ totalFeaturesAmount }} {{ $t('accounts.billing_details.euros_per_month') }}</span>
                  <span class="final-price">&nbsp;{{ discountedPrice }} {{ $t('accounts.billing_details.euros_per_month') }}</span>
                </span>
                <div>{{ $t('accounts.billing_details.autopay_on', { next_date: nextPaymentDate }) }}</div>
                <a class="btn btn-link px-0 pt-4 text-start" :onClick="setPaymentHistoryModal">{{ $t('accounts.billing_details.payment_history') }}</a>
                <BModal scrollable centered v-model="paymentHistoryModal" :title="$t('accounts.billing_details.payment_history')" @ok="paymentHistoryModal = false" size="lg" only-ok>
                  <BTable striped hover :items="paymentHistory" :fields="['date', 'amount', 'status']">
                    <template #cell(date)="data">
                      <span :title="data.item.fullDate">{{ data.item.date }}</span>
                    </template>
                    <template #cell(amount)="data">
                      {{ data.item.amount }}
                    </template>
                    <template #cell(status)="data">
                      <span :class="{'text-success fw-bold': data.item.status === 'Success', 'text-danger fw-bold': data.item.status != 'Success'}">
                        {{ data.item.status }}
                      </span>
                    </template>
                  </BTable>
                  <template #footer>
                    <BButton variant="primary" @click="paymentHistoryModal = false">
                      {{ $t('general.ok') }}
                    </BButton>
                  </template>
                </BModal>
              </BCol>
              <BCol md="6" xs="12">
                <div>
                  {{
                    $t('accounts.billing_details.credit_card', { brand: subscriptionDetails.current_payment_method.card.brand, last4: subscriptionDetails.current_payment_method.card.last4 } )
                  }}
                </div>
                <div>
                  {{
                    $t('accounts.billing_details.expiring_card_date', { exp_month: subscriptionDetails.current_payment_method.card.exp_month, exp_year: subscriptionDetails.current_payment_method.card.exp_year })
                  }}
                </div>
                <a  class="btn btn-link px-0 pt-4" @click="goToEditPaymentMethod">{{ $t('accounts.billing_details.edit') }}</a>
              </BCol>
            </BRow>
          </BCard>
        </div>

      <!-- Subscription Details -->
        <h5>{{ $t('accounts.subscription_details.title') }}</h5>
        <BCard>
          <h6>{{ $t('accounts.subscription_details.products') }}</h6>
          <BRow>
            <BCol v-for="product in selectedFeatures" :key="product.slug">
              <p><strong>{{ product.label }}</strong></p>
              <p>{{ product.description }}</p>
              <p>{{ $t('accounts.subscription_details.full_price', { price: product.price }) }} €</p>
            </BCol>
          </BRow>
          <h6>{{ $t('accounts.subscription_details.discounts') }}</h6>
          <BRow>
            <div v-for="discount in discountedPrices" :key="discount.period.label">
              {{ discount.period.label }}: {{ discount.percent }}% off - 
              <span class="initial-price">{{ discount.initialPrice.toFixed(2) }}</span> 
              <span class="final-price">&nbsp;{{ discount.finalPrice.toFixed(2) }}&nbsp;</span>
              <span>€/month</span>
            </div>
          </BRow>
          <BRow v-if="api_token" style="margin-top: 20px;">
            <BCol>
              <p>{{ $t('signup.signup_to_paid.api_token') }}: <span @click="copyToClipboard(api_token)" title="Click to copy">{{ api_token }}</span></p>
            </BCol>
          </BRow>
        </BCard>

        <BButton variant="danger" @click="cancelSubscription">
          {{ $t('accounts.cancel_subscription') }}
        </BButton>

        <BModal v-model="openPaymentMethodModal" title="Edit Payment Method" centered size="sm" hide-footer>
          <PaymentMethod ref="paymentMethodComponent" :handlePaymentMethodSaved="handlePaymentMethodSaved" />
          <BButton variant="primary" @click="submit">
            {{ $t('general.save') }}
          </BButton>
        </BModal>
    </BContainer>
  </div>
  <div v-else style='min-height: 200px;' class="card" :class="{'loading': loading}"></div>
</template>

<style scoped>
.initial-price {
  text-decoration: line-through;
  color: red;
}
.final-price {
  font-weight: 500;
  font-size: 1.1em;
}
</style>
