<script>
import axios from "axios";
import Swal from "sweetalert2";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import NewTemplateModal from "@/components/new-template-modal";
import QRCode from 'qrcode'
import Utils from "@/js/utils";

export default {
  data() {
    return {
      loadingAction: false,
      cols: {
        "name": 200,
        "type": 200,
        "url": 230,
        "template": 100,
        "qrcode": 100,
        "status": 150
      },
      distributions: [],
      clickIndex: null,
      nextUpdate: null,
      delay: 700,
      status: false,
      newDeliveryTypeModal: false,
      newDeliveryType: { company: null, type: null },
      modal: { newTemplateModal: false },
      selectedDistribution: {}
    };
  },
  props: ["survey", "companies", "contract"],
  components: { PageHeader, Multiselect, NewTemplateModal },
  watch: {
    contract: {
      handler: function () {
        if (this.contract?.id) this.getDistributions();
      },
      immediate: true
    }
  },
  mounted() {
    if (this.survey.id) {
      this.status = this.survey.status == "active";
      if (this.survey.company_ids.length == 1) this.newDeliveryType.company = { id: this.survey.company_ids[0] };
    }
  },
  methods: {
    getDistributions() {
      axios.get(`/v3/feedback/distributions?survey_id=${this.survey.id}&subscription_id=${this.contract.id}`)
      .then(response => {
        this.distributions = response.data.data.filter(d => this.survey.company_ids.includes(d.company.id));
        this.orderDistributionsByCompanyName();
      }).catch(this.error);
    },
    async updateGeneralStatus() {
      try {
        this.loadingAction = true;
        Utils.updatePageOpts({ statusMessage: { status: "saving", icon: "ion ion-md-sync" } });

        let newStatus = this.status ? "inactive" : "active";
        let res = await axios.post(`/v3/feedback/surveys/${this.survey.id}`, {
          status: newStatus
        });
        this.survey.status = res.data.data.status;
        this.status = this.survey.status == "active";

        this.loadingAction = false;
        Utils.updatePageOpts({ statusMessage: { status: "saved", icon: "mdi mdi-check-bold" } });
      } catch (error) { this.error(error) }
    },
    updater(index) {
      this.nextUpdate = Date.now() + this.delay;
      this.$forceUpdate();
      setTimeout(() => this.updateStatus(index), this.delay);
    },
    async updateStatus(index) {
      if (this.nextUpdate > Date.now()) return;

      try {
        this.loadingAction = true;
        Utils.updatePageOpts({ statusMessage: { status: "saving", icon: "ion ion-md-sync" } });

        let distribution = this.distributions[index];

        await axios.post(`/v3/feedback/distributions/${distribution.id}`, { published: distribution.published });

        this.loadingAction = false;
        Utils.updatePageOpts({ statusMessage: { status: "saved", icon: "mdi mdi-check-bold" } });
      } catch (error) { this.error(error) }
    },
    async createDistribution() {
      try {
        this.loadingAction = true;
        Utils.updatePageOpts({ statusMessage: { status: "saving", icon: "ion ion-md-sync" } });

        let response = await axios.post(`/v3/feedback/distributions`, { type: this.newDeliveryType.type, company_id: this.newDeliveryType.company.id, survey_id: this.survey.id });

        this.distributions.unshift(response.data.data);
        this.orderDistributionsByCompanyName();
        this.loadingAction = false;
        Utils.updatePageOpts({ statusMessage: { status: "saved", icon: "mdi mdi-check-bold" } });
        this.newDeliveryTypeModal = false;
        this.$store.commit("distributions/saveDistributions", { distributions: this.distributions, contractId: this.contract.id })
      } catch (error) { this.error(error) }
    },
    orderDistributionsByCompanyName() {
      this.distributions.sort((a, b) => {
        if (a.company.name < b.company.name) return -1;
        if (a.company.name > b.company.name) return 1;
        return 0;
      });
    },
    usePublishStatus(status) {
      return status ? this.$t("new_survey.share.published") : this.$t("new_survey.share.unpublished");
    },
    useGeneralStatus() {
      return this.$t(`new_survey.share.${this.survey.status}`);
    },
    error (error) {
      return Swal.fire(this.$t('general.error'), this.$t('general.contact_support') + JSON.stringify(error.response.data), 'error');
    },
    copyToClipboard(index) {
      navigator.clipboard.writeText(this.distributions[index].url);
      this.clickIndex = index;
    },
    tooltipText(index) {
      if (this.clickIndex == index) return this.$t("new_survey.share.copied");
      else return this.$t("new_survey.share.copy_to_clipboard");
    },
    distributionType(distribution) {
      return distribution.type || this.$t("new_survey.share.general");
    },
    toggleNewDeliveryTypeModal() {
      this.newDeliveryTypeModal = !this.newDeliveryTypeModal;
    },
    resetModal() {
      this.newDeliveryType = { company: null, type: null };
      if (this.survey.company_ids.length == 1) this.newDeliveryType.company = { id: this.survey.company_ids[0] };
    },
    templateLabel(distribution) {
      if (!Object.keys(distribution.mail_template_ids).length) return this.$t("general.create");
      return this.$t("general.edit");
    },
    editOrCreateTemplate(distribution) {
      const defaultLocale = distribution.survey.default_locale;
      const templateId    = distribution.mail_template_ids[defaultLocale];

      if (templateId) this.$router.push({ name: "feedback_edit_template", params: { id: templateId }, query: this.$route.query }); // edit template
      else { //create template
        this.modal.newTemplateModal = true;
        this.selectedDistribution = distribution;
      }
    },
    downloadQRCode(distribution) {
      const options = { type: 'png', color: { dark: '#000', light: '#0000' }}
      QRCode.toDataURL(`${distribution.url}?source=QRCode`, options, (error, url) => {
        var a = document.createElement("a");
        a.href = url
        a.download = `QR Code - ${distribution.type || 'general'}.png`;
        a.click();
      })
    },
  },
  computed: {
    computedCompanies() {
      return this.survey.company_ids.map(cid => this.companies.find(c => c.id == cid))
    },
    validFields() {
      return this.newDeliveryType.company && this.newDeliveryType.type?.length;
    }
  }
};
</script>

<template>
  <div v-if="survey.id && contract" class="share-root card" :class="{ 'cursor-loading': loadingAction }">
    <div class="card-body">
      <PageHeader :title="$t('new_survey.share.subtitle')" />

      <b-collapse id="not-published-alert" :visible="survey.status != 'active'">
        <div class="w-100 d-flex justify-content-center mt-3">
          <div class="alert alert-warning d-flex alert-box align-items-center px-4" role="alert">
            <i class="mdi mdi-alert-outline font-size-24 me-2"></i>
            <span class="font-size-16 mb-0"> {{ $t("new_survey.share.unpublished_alert") }} </span>
          </div>
        </div>
      </b-collapse>

      <div class="w-100 d-flex justify-content-center align-items-center font-size-17 my-3">
        <label class="mb-1 form-label"> {{ $t("new_survey.share.general_status")+': ' }} </label>
        <div @click="updateGeneralStatus" class="ms-3 mb-0">
          <b-form-checkbox v-model="status" switch size="xl" class="mb-0"></b-form-checkbox>
        </div>
        <span class="ms-1 mb-1 d-none d-md-block"> {{ useGeneralStatus() }} </span>
      </div>

      <div class="w-100 d-flex align-items-center justify-content-center mt-5" v-if="distributions.length == 0">
        <button class="btn btn-primary" @click="toggleNewDeliveryTypeModal">{{ $t("new_survey.share.new_distribution_type") }}</button>
      </div>

      <div class="w-100 d-flex justify-content-center mt-4">
        <div v-if="distributions.length > 0" class="w-75 table-responsive" style="overflow-y: hidden;">
          <div class="d-flex justify-content-between align-items-center mb-3 mt-5">
            <h5 class="m-0">{{ $t("new_survey.share.data_by_company") }}</h5>
            <button class="btn btn-primary" @click="toggleNewDeliveryTypeModal">{{ $t("new_survey.share.new_distribution_type") }}</button>
          </div>
          <table class="table table-bordered table-centered table-nowrap mb-0 text-center" style="table-layout: fixed;">
            <thead class="thead-light">
              <tr>
                <th v-for="(width, v) in cols" :key="v" :style="`width: ${width}px;`">
                  {{ $t(`new_survey.share.table_cols.${v}`) }}
                  <span v-if="v == 'type'" v-b-tooltip.hover :title="$t('mail_template.list.distribution_tooltip')">
                    <i class="mdi mdi-help-circle"></i>
                  </span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(distribution, index) in distributions" :key="distribution.key">
                <!-- Name -->
                <td class="text-truncate" :title="distribution.company.name">{{ distribution.company.name }}</td>

                <!-- Type -->
                <td class="text-truncate" :title="distributionType(distribution)">{{ distributionType(distribution) }}</td>

                <!-- URL -->
                <td>
                  <a :href="distribution.url" target="_blank" rel="noopener noreferrer"> {{ distribution.url }} </a>
                  <a class="ms-2" href="javascript:;" @click="copyToClipboard(index)" v-b-tooltip.hover.html="tooltipText(index)" @mouseover="clickIndex = null"><i class="mdi mdi-content-copy font-size-16"></i></a>
                </td>

                <!-- Create/edit template -->
                <td>
                  <a href="#" class="text-primary" @click="editOrCreateTemplate(distribution)">{{ templateLabel(distribution) }}</a>
                </td>

                <!-- Download QR Code -->
                <td>
                  <a href="#" class="text-primary" @click="downloadQRCode(distribution)">{{ $t('new_survey.share.download') }}</a>
                </td>

                <!-- Status -->
                <td>
                  <div class="d-flex justify-content-center align-items-center">
                    <div @click="updater(index)">
                      <b-form-checkbox v-model="distribution.published" switch class="mb-3 pt-2" size="lg"></b-form-checkbox>
                    </div>
                    <span class="ms-2 d-none d-md-block"> {{ usePublishStatus(distribution.published) }} </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-else class="my-3 d-flex align-items-center justify-content-center">
          <span class="font-size-16"> {{ $t("new_survey.share.no_distribution") }} </span>
        </div>
      </div>

      <b-modal v-model="newDeliveryTypeModal" centered hide-footer hide-header size="lg" @hidden="resetModal">
        <div class="p-4 d-flex flex-column" v-if="newDeliveryTypeModal">
          <h3>{{ $t("new_survey.share.new_distribution_type") }}</h3>

          <div v-if="survey.company_ids.length > 1" class="d-flex flex-column form-group mt-2">
            <label class="mt-2 form-label">{{ $t("new_survey.share.company") }}</label>
            <multiselect :multiple="false" :allow-empty="false" v-model="newDeliveryType.company" :options="computedCompanies" :searchable="true" track-by="id" label="name" :showLabels="false" :placeholder="$t('filter.select_option')"></multiselect>
          </div>

          <div class="form-group mt-2 mb-3">
            <label class="form-label">{{ $t("new_survey.share.distribution_type") }}</label>
            <div class="position-relative">
              <input type="text" class="form-control" :placeholder="$t('new_survey.share.distribution_type_placeholder')" v-model="newDeliveryType.type" />
            </div>
          </div>

          <div class="footer-nav d-flex flex-column flex-sm-row justify-content-between mt-3" style="gap: 10px;">
            <button class="btn btn-outline-secondary me-2" @click="newDeliveryTypeModal = false;">
              {{ $t("surveys_list.cancel") }}
            </button>

            <button class="btn btn-primary" :disabled="!validFields" @click="createDistribution">
              {{ $t("surveys_list.submit") }}
            </button>
          </div>
        </div>
      </b-modal>
    </div>

    <b-modal v-model="modal.newTemplateModal" centered title-class="font-18" hide-footer hide-header size="lg">
      <NewTemplateModal v-if="modal.newTemplateModal" :modal="modal" :allDistributions="[selectedDistribution]" :distribution="selectedDistribution" :contract="contract"/>
    </b-modal>
  </div>
</template>
