<script>
import axios from 'axios'
import Utils from "@/js/utils";

export default {
  data() {
    return {
      names: [
        { name: this.$t('dashboard.rating_comparison.numerical_rating') },
        { name: this.$t('dashboard.rating_comparison.sentiment_rating') },
        { name: this.$t('dashboard.rating_comparison.tes_score') },
      ],
      data: [],
      keys: ['cleanliness','facilities', 'service', 'fnb', 'value','location', 'ambience'],
      ratings: null,
      sentiment: null,
      loading: false
    };
  },
  props: ["filterParams"],
  watch: {
    filterParams: function () {
      this.loadListener(this.filterParams);
    }
  },
  methods: {
    loadListener (params) {
      var p = Object.assign({group_by: 'ratings'}, params.data)
      this.load(p)
    },
    async load (params) {
      this.clearData()
      Utils.setLoading.bind(this)(true)
      this.ratings   = null
      this.sentiment = null
      let promises = []
      promises.push(axios.get('/v3/destination/ratings', { params }).then(response => {
        if (!response.data.data[0]) return;
        this.ratings = response.data.data[0]
        response.data.data[0].ratings.forEach(e => {
          this.data[0][e.topic] = Utils.round(e.value / 10)
        })
      }));
      promises.push(axios.get('/v3/destination/sentiment', { params }).then(response => {
        if (!response.data.data[0]) return;
        this.sentiment = response.data.data[0]
        response.data.data[0].sentiment.forEach(e => {
          if (e.topic == 'food') e.topic = 'fnb'
          this.data[1][e.topic] = Utils.round(e.sentiment_score)
        })
      }));

      await Promise.all(promises)
      this.generateTES()
      Utils.setLoading.bind(this)(false)
    },
    generateTES() {
      this.ratings?.ratings.forEach(r => {
        let s = this.sentiment.sentiment.find(ss => ss.topic == r.topic)
        if (s) {
          let v = Utils.calcTES(s.sentiment_score, s.review_count, r.value / 10, r.review_count)
          this.data[2][r.topic] = v
        }
      })
    },
    clearData() {
      ["ratings", "sentiment"].forEach(k => this[k] = null)
      this.data = Utils.deepClone(this.names)
    },
    formatNumber(val) {
      return Utils.formatNumber(val);
    }
  }
};
</script>

<template>
  <div style='min-height: 200px;' class="card" :class="{'loading': loading}">
    <div class="card-body">
      <div class="d-flex">
        <h4 class="card-title mb-4">{{ $t('dashboard.rating_comparison.title') }}</h4>
        <span class='ms-2' v-b-tooltip.hover :title="$t('general.rating_comparison_help')">
          <i class='mdi mdi-help-circle'></i>
        </span>
      </div>
      <div class="table-responsive">
        <table class="table table-centered table-nowrap mb-0 text-center">
          <thead class='thead-light'>
            <tr>
              <th class='text-start'>Rating</th>
              <th v-for='k in keys' :key='k'>{{ $t(`ratings.${k}`) }}</th>
            </tr>
          </thead>
          <tbody v-if='!ratings || !sentiment'>
            <tr><td colspan=8 class='text-center pt-4'><strong>{{ $t('dashboard.rating_comparison.no_results') }}</strong></td></tr>
          </tbody>
          <tbody v-if='ratings && sentiment'>
            <tr v-for="d of data" :key="d.name">
              <td class='text-start'><strong>{{d.name}}</strong></td>
              <td v-for='k in keys' :key='k'>{{ formatNumber(d[k]) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
