<script>
import Preview   from "../preview";
import Utils     from "@/js/utils";
import ReportsJS from "@/js/reports";
import axios     from "axios";

export default{
  data() {
    return {
      loading: false,
      template: null,
      componentsList: ReportsJS.templates.componentsList,
    }
  },
  async created () {
    this.$i18n.locale = await this.$store.dispatch('locale/fetch')
    this.user = await this.$store.dispatch("user/fetch");
  },
  components: { Preview },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    }
  },
  methods: {
    async loadTemplate(id) {
      Utils.setLoading.bind(this)(true);
      const response = await axios.get(`/v3/reports/templates/${id}`, { params: { subscription_id: this.contract.id } });
      this.template = response.data.data
      Utils.setLoading.bind(this)(false);
    },
  },
  watch: {
    contract: {
      handler: function () {
        const templateId = this.$route.params.id;
        if (templateId && this.contract?.id) {
          Utils.setDashboardStyles(this.contract);
          this.loadTemplate(templateId);
        }
      },
      immediate: true
    }
  }

}
</script>

<template>
  <Preview
    v-if="template && template.dashboard_template"
    :contract="contract"
    :template="template"
    :dt="template.dashboard_template"
    :componentsList="componentsList"
    selectorClass="w-50 mb-5"
  />
</template>
